import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSpaceMenu } from '@engage-web/utils';
import { EmptyCard } from '@engage-web/components/base';
import useSpaceCheck from '@engage-web/components/scenes/drawer/hooks/useSpaceCheck';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { DrawerCardContent } from '@engage-web/components/scenes/drawer/DrawerCardContent';
import { ReserveCard } from './Card/ReserveCard/ReserveCard';
import ReserveCardSkeleton from './Card/ReserveCardSkeleton';
import { useReserveQueries } from './hooks/useReserveQueries';
import { useReserveRouteData } from './hooks/useReserveRouteData';

export function ReserveSpaceContainer() {
  const { t } = useTranslation();
  const reserveRouteData = useReserveRouteData();

  const { id, isDesk, closeLinkPath, data: stateData } = reserveRouteData ?? {};
  const { isLoading, spaceItem, userData, bookingsData, spaceAttributes } = useReserveQueries(id);

  const menu = useSpaceMenu({
    spaceId: id,
    isDesk,
    isFavourite: spaceItem?.isFavorite,
    spaceAttributes,
  });

  // @ts-ignore TODO: revisit ParsedTeam and ParsedSpaceItem types
  const { reservee } = stateData ?? {};

  useSpaceCheck({ id, isDesk });

  if (isLoading) {
    return (
      <>
        <DrawerHeader closeLinkPath={closeLinkPath} isCloseIcon />
        <ReserveCardSkeleton />
      </>
    );
  }

  // we still want to show Reserve button as part of ReserveCard even when space is not bookable
  // requirement for this change can be found inside P2-1760 description
  if (!reserveRouteData || !userData || !spaceItem) {
    return (
      <>
        <DrawerHeader closeLinkPath={closeLinkPath} isCloseIcon />
        <EmptyCard iconName="warning">{t('common.networkError')}</EmptyCard>
      </>
    );
  }

  return (
    <>
      <DrawerHeader
        menu={menu}
        closeLinkPath={closeLinkPath}
        isCloseIcon
        closeIconA11yLabel={
          isDesk
            ? 'accessibilityLabels.closeLayout_deskReserve'
            : 'accessibilityLabels.closeLayout_spaceReserve'
        }
      />
      <DrawerCardContent>
        <ReserveCard
          spaceItem={spaceItem}
          reservee={reservee}
          user={userData}
          bookings={bookingsData}
        />
      </DrawerCardContent>
    </>
  );
}
