import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@engage-web/components/base';
import {
  getPersonOfInterestLabels,
  localizePersonPrimaryLocationStatus,
  getPersonConnectionStatus,
} from '@engage-shared/utils';
import { useSelector } from 'react-redux';
import { floorplanSelectors } from '@engage-web/store';
import { PATH_SEGMENT, PresenceStatus, SENSOR_STATUS } from '@engage-web/constants';
import { useSetPageTitle } from '@engage-web/utils/hooks';
import { useNavigate } from 'react-router-dom';
import { usePersonImage } from '@engage-web/api/queries';
import { Person } from '@engage-shared/api';
import PersonAvatar from './PersonAvatar';
import {
  Location,
  LocationWrapper,
  MoreLocations,
  Name,
  NameWrapper,
  Officer,
  OfficerIcon,
  OfficerRow,
  TeamText,
  Text,
  Wrapper,
} from './styled';
import { isDownloadPath, getPersonImageUrl } from './utils';

type SVLiveProps = {
  locationName: string | null;
  hoodName: string | null;
  sensorStatus: SENSOR_STATUS | null;
  presenceStatus: string | null;
};

type Detail = Pick<
  Person,
  | 'imageUrl'
  | 'jobTitle'
  | 'isFirstAidOfficer'
  | 'isFireWarden'
  | 'primaryLocation'
  | 'personName'
  | 'teamName'
  | 'hasMultipleLocations'
> &
  SVLiveProps;

type DetailsProps = {
  details: Detail;
};

interface PersonOfInterest {
  type: string;
  icon: 'officer-medical' | 'officer-fire';
  name: string;
}

const Details = ({ details }: DetailsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    imageUrl,
    jobTitle,
    isFirstAidOfficer,
    isFireWarden,
    locationName,
    sensorStatus,
    primaryLocation,
    presenceStatus,
    personName,
    teamName: defaultTeamName,
    hasMultipleLocations,
    hoodName,
  } = details;

  const isDownloadImage = isDownloadPath(imageUrl);
  const personImageQuery = usePersonImage({
    imageUrl: imageUrl ?? '',
    enabled: isDownloadImage && !!imageUrl,
  });

  const personImageUrl = getPersonImageUrl({
    imageUrl,
    isDownloadImage,
    isSuccess: personImageQuery.isSuccess,
    fileImageUrl: personImageQuery?.data ?? null,
  });

  const floorplanZoneId = useSelector(floorplanSelectors.getFloorplanZoneId);
  const personConnectionStatus =
    // @ts-ignore
    getPersonConnectionStatus(details, floorplanZoneId) || presenceStatus;

  const status = localizePersonPrimaryLocationStatus({
    // @ts-ignore
    status: primaryLocation.status,
    t,
  });

  const teamName = primaryLocation?.teamName || defaultTeamName;

  const personOfInterestLabels: PersonOfInterest[] = getPersonOfInterestLabels({
    isFireWarden,
    isFirstAidOfficer,
    t,
  });

  const onMoreLocationsClick = () => {
    navigate(PATH_SEGMENT.LOCATIONS, {
      state: {
        person: { ...details, name: personName },
      },
    });
  };

  const renderMoreLocations = () =>
    hasMultipleLocations ? (
      <MoreLocations data-testid="more-locations" onClick={onMoreLocationsClick}>
        {t('layout.peopleCard.more')}
      </MoreLocations>
    ) : null;

  useSetPageTitle(t('documentTitles.personCard', { name: personName }));

  return (
    <Wrapper>
      <PersonAvatar
        imageSource={personImageUrl}
        personName={personName}
        sensorStatus={sensorStatus}
        locationStatus={status}
        presenceStatus={personConnectionStatus as PresenceStatus | null}
      />
      <NameWrapper>
        <Name>{personName}</Name>
      </NameWrapper>
      {jobTitle && (
        <Text role="heading" aria-level={6}>
          {jobTitle}
        </Text>
      )}
      {teamName && (
        <TeamText role="heading" aria-level={6}>
          {teamName}
        </TeamText>
      )}
      <LocationWrapper>
        {locationName && (
          <Location role="heading" aria-level={6}>
            {locationName}
          </Location>
        )}
        {hoodName && (
          <Location role="heading" aria-level={6}>
            {hoodName}
          </Location>
        )}
        {renderMoreLocations()}
      </LocationWrapper>
      {personOfInterestLabels.map(({ type, icon, name }) => (
        <OfficerRow key={type}>
          <OfficerIcon>
            <Icon name={icon} size={10} />
          </OfficerIcon>
          <Officer>{name}</Officer>
        </OfficerRow>
      ))}
    </Wrapper>
  );
};

export default memo(Details);
