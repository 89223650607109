import React from 'react';
import { PropsWithChildren, useEffect } from 'react';
import OpenSeadragon from 'openseadragon';
import { useFESContext } from '@floorplan/fes';

type ClickableShapeProps = PropsWithChildren<{
  clickHandler: (event: OpenSeadragon.CanvasClickEvent) => void;
  elementId: string;
}>;

const ClickableShape = ({ clickHandler, elementId, children }: ClickableShapeProps) => {
  const fes = useFESContext();

  useEffect(() => {
    const viewer = fes.getViewer();

    const handleCanvasClick = (event: OpenSeadragon.CanvasClickEvent) => {
      if (event.originalTarget?.id === elementId && event.quick) {
        clickHandler(event);
      }
    };
    viewer?.addHandler('canvas-click', handleCanvasClick);

    return () => {
      viewer?.removeHandler('canvas-click', handleCanvasClick);
    };
  }, [elementId, fes, clickHandler]);

  return <>{children}</>;
};

export default ClickableShape;
