import {
  Location,
  Person,
  PersonData,
  PrimaryLocation,
} from '@engage-shared/api/people/interfaces';
import { Nullable } from '@engage-shared/utils/types';
import { DEFAULT_LOCATION_STATUS, LocationStatus } from '@engage-shared/constants/person';
import { TFunction as TranslateFunction } from 'i18next';
import { fetchSpace } from '@engage-shared/api/spaces';

type GetPrimaryLocationStatus = (params: {
  person: PersonData;
  primaryLocation: Nullable<Location>;
}) => LocationStatus;
/**
 * Retrieves person primary location status.
 * @param person
 * @param primaryLocation
 */
const getPrimaryLocationStatus: GetPrimaryLocationStatus = ({ person, primaryLocation }) => {
  let status = DEFAULT_LOCATION_STATUS;
  const { checkedIn, reserved, assigned } = person;

  if (checkedIn) {
    status = LocationStatus.CHECKED_IN;
  } else if (reserved) {
    status = LocationStatus.RESERVED;
  } else if (assigned && primaryLocation?.spaceIds.length === 1) {
    status = LocationStatus.ASSIGNED;
  } else if (!primaryLocation) {
    status = LocationStatus.REMOTE;
  }
  return status;
};

type GetPersonPrimaryLocation = (param: {
  person: PersonData;
  searchSpaceId?: number;
  currentFloorId?: number;
}) => Nullable<PrimaryLocation>;
/**
 * Gets the person primary location.
 * If currentSpaceId exists (returned from search API), return the location from the locations list.
 * Location priority:
 *  Reserved desk - person.reserved = true -> [reservedLocation] = location
 *  Assigned desk - person.assigned = true -> [assignedLocation] = location
 *  Assigned neighborhood - location.hoodId defined
 *  Working remotely (no location) - location = []
 */
const getPrimaryLocation: GetPersonPrimaryLocation = ({
  person,
  searchSpaceId,
  currentFloorId,
}) => {
  if (!person || !person?.location) {
    return null;
  }
  const { location } = person;
  // Default primaryLocation is the fist location defined in location list.
  let [primaryLocation] = location;
  if (searchSpaceId) {
    // Find location if searchSpaceId exists.
    const currentLocation = location.find(item => item.spaceId === searchSpaceId);
    if (currentLocation) {
      primaryLocation = currentLocation;
    }
  } else if (currentFloorId) {
    const currentLocation = location.find(item => item.floorId === currentFloorId);
    if (currentLocation) {
      primaryLocation = currentLocation;
    }
  } else if (location.length > 1) {
    // Person has multiple locations.
    // Reserved and assigned space has priority over neighborhood.
    // If spaceId equals teamId the spaceId identifies as a neighborhood and not as a desk.
    // Filter all spaces that are not neighborhood and get the fist one found.
    const assignedLocation = location.find(item => item.spaceId !== item.teamId);
    if (assignedLocation) {
      primaryLocation = assignedLocation;
    }
  }
  const status = getPrimaryLocationStatus({ person, primaryLocation });
  return {
    ...primaryLocation,
    status,
  };
};

type ParsePersonData = (params: {
  person: PersonData;
  searchSpaceId?: number;
  currentFloorId?: number;
}) => Person;
/**
 * Parse person api data and return with person primary location.
 * @param person api person data
 * @param searchSpaceId The spaceId returned by the search api and selected on the person list.
 * @param currentFloorId
 */
export const parsePersonData: ParsePersonData = ({ person, searchSpaceId, currentFloorId }) => {
  const primaryLocation = getPrimaryLocation({
    person,
    searchSpaceId,
    currentFloorId,
  });

  const { firstName, lastName, location } = person ?? {};

  const personName = `${firstName} ${lastName}`;
  const teamName = location ? location[0]?.teamName ?? null : null;
  const hasMultipleLocations = Array.isArray(location) && location.length > 1;
  const hasLocation = Array.isArray(location) && location.length > 0;

  return {
    ...person,
    personName,
    teamName,
    hasLocation,
    hasMultipleLocations,
    primaryLocation,
  };
};

type GetPersonLocationLabel = (param: {
  location: Location;
  translationFn: TranslateFunction;
}) => Promise<string>;
/**
 * Retrieves person location label.
 * @param data
 */
export const getPersonLocationLabel: GetPersonLocationLabel = async ({
  location,
  translationFn,
}) => {
  let label = '';
  const { spaceId, teamId, spaceIds } = location;
  const isFlexibleSeating = spaceIds?.length > 1;
  if (isFlexibleSeating) {
    label = translationFn('layout.peopleCard.flexibleSeating');
  } else if (spaceId !== teamId) {
    const space = await fetchSpace({ id: spaceId });
    label = space.name;
  }
  return label;
};
