const MENU_ACTION_TYPES = {
  DEVELOPMENT: 'development',
  BOOKINGS: 'bookings',
  BROWSER: 'browser',
  WAYFINDER: 'wayfinder',
  PERSON_OF_INTEREST: 'poi',
  FIRST_RESPONDERS: 'first_responders',
  DEMONSTRATION_CONFIG: 'demo',
  QR_SCAN: 'qrscan',
  YELLOWBOX: 'yellowbox',
  MAIL_TO: 'mail_to',
  KEYBOARD_DOCS: 'keyboard_accessibility',
  HEATMAP: 'heatmaps',
};

export { MENU_ACTION_TYPES };
