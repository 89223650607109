export enum PresenceEventSource {
  WIFI = 'wifi',
  WIRED = 'wired',
}

export enum PresenceStatus {
  ACTIVE = 'active',
  AWAY = 'away',
  REMOTE = 'remote',
  OFFLINE = 'offline',
  WIFI = 'wifi',
}

export enum LocationStatus {
  REMOTE = 'remote',
  RESERVED = 'reserved',
  ASSIGNED = 'assigned',
  FLEXIBLE = 'flexible',
  CHECKED_IN = 'checked-in',
}

type LocationStatusLocalized = {
  [key in LocationStatus]: string;
};

export const LOCATION_STATUS_LOCALIZED: LocationStatusLocalized = {
  [LocationStatus.REMOTE]: 'layout.peopleCard.remote',
  [LocationStatus.RESERVED]: 'layout.peopleCard.reservedDesk',
  [LocationStatus.CHECKED_IN]: 'layout.peopleCard.checkedIn',
  [LocationStatus.ASSIGNED]: 'layout.peopleCard.assignedDesk',
  [LocationStatus.FLEXIBLE]: 'layout.peopleCard.flexible',
};

type PresenceStatusLocalized = {
  [key in PresenceStatus]?: string;
};

export const PRESENCE_STATUS_LOCALIZED: PresenceStatusLocalized = {
  [PresenceStatus.ACTIVE]: 'layout.peopleCard.live',
  [PresenceStatus.REMOTE]: 'layout.peopleCard.remote',
  [PresenceStatus.AWAY]: 'layout.peopleCard.live',
  [PresenceStatus.WIFI]: 'layout.peopleCard.wifi',
};

export const DEFAULT_LOCATION_STATUS = LocationStatus.FLEXIBLE;
