import styled from 'styled-components';
import { COMMON_STYLES, Z_INDEX } from '@engage-web/constants';
import { Link } from 'react-router-dom';
import { Avatar } from '@engage-web/components/base';

export const Wrapper = styled.div<{
  $isKiosk?: boolean;
  $isDialogOpen?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${({ $isKiosk }) => ($isKiosk ? Z_INDEX.KIOSK_HEADER : Z_INDEX.HEADER)}; // after modal
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ $isKiosk, $isDialogOpen }) => {
    if ($isKiosk) {
      return $isDialogOpen ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.3)';
    }

    return 'transparent';
  }};
  width: 100%;
  padding: 10px ${COMMON_STYLES.SPACE}px;
  // force overlapping floorplan
  pointer-events: none;
  min-height: 54px;
  backdrop-filter: ${({ $isKiosk }) => ($isKiosk ? 'blur(60px)' : 'none')};

  > * {
    // Ensures that all direct children are equal width,
    // no matter how many you have or what the content is inside of them.
    flex-basis: 100%;

    @media (max-width: 640px) {
      flex-basis: auto;
    }
  }

  @media (max-width: 450px) {
    padding: 0 5px;
  }

  @media (min-width: 451px) and (max-width: 800px) {
    padding: 0 20px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TimeLinesWrapper = styled.div`
  pointer-events: auto;
  position: relative;
`;

export const UserWrapper = styled.button`
  pointer-events: auto;
  cursor: pointer;
  outline: none;
  margin-left: 10px;

  &.focus-visible {
    .avatar__initials,
    .avatar__image {
      outline: 1px auto Highlight;
      outline: 1px auto -webkit-focus-ring-color;
    }
  }

  @media (max-width: 400px) {
    margin-left: 0;

    .avatar__badge-wrapper {
      // because Avatar component uses style attribute
      width: 44px !important;
      height: 44px !important;
    }
    .avatar__initials {
      // because Avatar component uses style attribute
      width: 44px !important;
      height: 44px !important;
    }
  }
`;

export const MenuButtonWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color6};
  width: 42px;
  height: 42px;
  border-radius: 42px;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }

  @media (max-width: 400px) {
    width: 36px;
    height: 36px;
  }
`;

export const UserAvatar = styled(Avatar)`
  border: 4px solid white;
`;

export const TeamBookingStartDateWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const SecondLineWrapper = styled(Wrapper)`
  top: 50px;
  z-index: ${Z_INDEX.MODAL - 1};
`;

export const FloorplanViewsWrapper = styled.div`
  padding-right: 6px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
