export const isStringNumeric = (value: string | boolean): boolean => {
  if (typeof value == 'string' && value.trim() === '') {
    return false;
  }

  if (typeof value == 'string' && value[0] === '0') {
    return false;
  }

  const number = Number(value);
  if (number === Infinity || number === -Infinity) {
    return false;
  }
  return value === number.toString();
};

export const isBoolean = (value: string | boolean): boolean =>
  typeof value === 'boolean' || value === 'false' || value === 'true';

export const getFileNameFromUrl = (url: string): string =>
  url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'));

export const getFileExtension = (fileName: string): string =>
  fileName.split(/[#?]/)[0].split('.').pop() as string;

export const getClosestNumber = (num: number, arr: number[]): number =>
  arr.reduce((a, b) => (Math.abs(b - num) < Math.abs(a - num) ? b : a));

export const sortBySequenceAsc = (
  itemA: { sequence: number },
  itemB: { sequence: number },
): number => itemA.sequence - itemB.sequence;

export const sortByAlphaNum = (a: unknown, b: unknown): number => {
  if (typeof a !== 'string' || typeof b !== 'string') {
    return 1;
  }
  return a.localeCompare(b, undefined, { numeric: true });
};

/**
 * Allows to sequentially call multiple functions with the same dataset
 * Each function takes the output of the previous one
 * @example
 * const join = x => x.join(' ');
 * const toUpper = x => x.toUpperCase();
 * pipe(join, toUpper)(['hello', 'world']) // 'HELLO WORLD'
 */
export const pipe =
  <T>(...fns: ((arg: T) => T)[]) =>
  (value: T): T =>
    fns.reduce((acc, fn) => fn(acc), value);

/**
 * Basic string hash implementation copied from https://github.com/mstdokumaci/string-hash-64
 */
export const hashString = (str: string): string => {
  if (!str) return '';

  let i = str.length;
  let hash1 = 5381;
  let hash2 = 52711;

  while (i--) {
    const char = str.charCodeAt(i);
    hash1 = (hash1 * 33) ^ char;
    hash2 = (hash2 * 33) ^ char;
  }

  return `${(hash1 >>> 0) * 4096 + (hash2 >>> 0)}`;
};
