import { getActiveTenantId } from './token-manager/token';
import { Nullable } from '@engage-shared/utils/types';
import { TenantId } from '@engage-shared/api/tenant/interfaces';

const API_DOMAIN = 'serraview.com';
const API_URL = 'engage_api';
const API_AUTH_URL = 'phoenix/auth';

export const getApiUrl = (tenantId?: string, version: string = 'v1'): Nullable<string> => {
  const tenant = tenantId ?? getActiveTenantId();

  return tenant ? `https://${tenant}.${API_DOMAIN}/${API_URL}/${version}` : null;
};

export const getTenantUrl = (tenantId: TenantId): string =>
  `https://${tenantId}.${API_DOMAIN}/${API_URL}/v1`;

export const getHost = (tenantId: TenantId): string => `https://${tenantId}.${API_DOMAIN}`;

export const getAuthUrl = (tenantId: TenantId): string =>
  `https://${tenantId}.${API_DOMAIN}/api/v1/${API_AUTH_URL}`;
