import { createSlice } from '@reduxjs/toolkit';
import { USER_CONFIG_KEYS } from '@engage-web/constants';
import { browserLanguage } from '../../utils/i18n';
import { getUserConfigSelector, updateUserConfigState } from './utils';

/* User's configuration grouped by tenant.
   Each tenant stores information about all users that ever been logged in.
   Every user contains all necessary information related to app-wide configs, such as landing page, acknowledgement policy, safe booking, etc.
   Value of each flag can be anything, from boolean, object, array, etc.

   Example:
   {
     "tenant1": {
       "2030": {
         [USER_CONFIG_KEYS.SOCIAL_DISTANCING]: VISIBILITY.SHOW,
       }
     }
   }
 */

const UI_LANGUAGE_INITIAL_STATE = browserLanguage;

const initialState = {};

const userConfig = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    setShowSocialDistancingInfo(state, action) {
      return updateUserConfigState({
        key: USER_CONFIG_KEYS.SOCIAL_DISTANCING,
        payload: action.payload,
        state,
      });
    },
    setUiLanguage(state, action) {
      return updateUserConfigState({
        key: USER_CONFIG_KEYS.UI_LANGUAGE,
        payload: action.payload,
        state,
      });
    },
    setEnableFloorplanViews(state, action) {
      return updateUserConfigState({
        key: USER_CONFIG_KEYS.ENABLE_VIEWS,
        payload: action.payload,
        state,
      });
    },
    clear() {
      return initialState;
    },
  },
});

export const userConfigSelectors = {
  getShowSocialDistancingInfo: state =>
    getUserConfigSelector({
      key: USER_CONFIG_KEYS.SOCIAL_DISTANCING,
      defaultValue: null,
      state,
    }),
  getUiLanguage: state =>
    getUserConfigSelector({
      key: USER_CONFIG_KEYS.UI_LANGUAGE,
      defaultValue: UI_LANGUAGE_INITIAL_STATE,
      state,
    }),
  getEnableFloorplanViews: state =>
    getUserConfigSelector({
      key: USER_CONFIG_KEYS.ENABLE_VIEWS,
      defaultValue: false,
      state,
    }),
};

export default userConfig;
