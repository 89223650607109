import React, { useCallback, useContext } from 'react';
import { removeSegmentFromPath } from '@engage-web/router/utils';
import { teamReservationsActions } from '@engage-web/store';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppLocation } from '@engage-web/router/hooks';
import { TeamButton } from '../../TeamButton';
import { ReserveCardContext } from '../ReserveCardContext';

export const BookForTeamButton = () => {
  const { item } = useContext(ReserveCardContext);

  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useAppLocation();

  const navigateToTeamMembersList = useCallback(() => {
    // Explicitly clear team reservation store to prevent useEffect from TeamReserveButton to navigate back to ReserveCard
    reduxDispatch(teamReservationsActions.clear());
    reduxDispatch(teamReservationsActions.clearSelection());

    // Show team members list
    navigate(removeSegmentFromPath(PATH_SEGMENT.RESERVE, pathname));
  }, [navigate, pathname, reduxDispatch]);

  return <TeamButton team={item} isBottomButton={false} onPress={navigateToTeamMembersList} />;
};
