import React, { ComponentProps, MouseEvent, PropsWithChildren } from 'react';
import { Icon } from '../../Icons/Icon';
import {
  DateButtonDateClip,
  DateButtonDateClipWrapper,
  DateButtonDateTextWrapper,
  DateButtonIconWrapper,
  DateButtonWrapper,
} from './styled';

// omitting ref property because types are incompatible
// not sure why, but could be react types conflict
type ButtonPropsFixed = Omit<ComponentProps<'button'>, 'ref'>;

type DateButtonProps = PropsWithChildren<
  {
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    clearButtonProps?: ButtonPropsFixed;
    disableInteractions?: boolean;
    isOpen?: boolean;
    isNow?: boolean;
  } & ButtonPropsFixed
>;

export function DateButtonView({
  onClick,
  clearButtonProps = {},
  isNow = true,
  isOpen = false,
  disableInteractions = true,
  children,
  ...rest
}: DateButtonProps) {
  return (
    <>
      <DateButtonWrapper
        $disableInteractions={disableInteractions}
        $isOpen={isOpen}
        onClick={onClick}
        {...rest}
      >
        <DateButtonIconWrapper>
          <Icon name="clock" size={20} />
        </DateButtonIconWrapper>
        <DateButtonDateTextWrapper $isActive={!isNow}>
          <span className="xsm date-button__text" data-testid="dateButton-text">
            {children}
          </span>
        </DateButtonDateTextWrapper>
      </DateButtonWrapper>
      {!isNow ? (
        <DateButtonDateClipWrapper {...clearButtonProps}>
          <DateButtonDateClip $isOpen={isOpen} $isActive={!isNow} />
        </DateButtonDateClipWrapper>
      ) : (
        <DateButtonDateClip $isOpen={isOpen} $isActive={!isNow} />
      )}
    </>
  );
}
