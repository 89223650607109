import React, { memo, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import log from '@engage-web/utils/logger';
import { Icon } from '@engage-web/components/base';
import Analytics from '@engage-web/utils/analytics';
import { MEDIA_TYPE, MediaTypeValue } from '@engage-shared/constants';
import { collectAnalytics } from '@engage-shared/utils';
import { MediaLinkButton } from './styled';

const createMailtoLink = (email: string): string => `mailto:${email}`;

type GetContactPersonArialLabel = (contactType: string) => string;

const getContactPersonAriaLabel: GetContactPersonArialLabel = (contactType: string): string => {
  const labels = {
    [MEDIA_TYPE.EMAIL.value]: 'accessibilityLabels.contactPersonViaEmail',
    [MEDIA_TYPE.CHAT.value]: 'accessibilityLabels.contactPersonViaChatBox',
    [MEDIA_TYPE.SLACK.value]: 'accessibilityLabels.contactPersonViaSlack',
    [MEDIA_TYPE.TEAMS.value]: 'accessibilityLabels.contactPersonViaChat',
  };

  return labels[contactType] ?? 'accessibilityLabels.contactPersonViaEmail';
};

type ButtonProps = {
  type: MediaTypeValue;
  mediaString: string;
  onError: (error: string) => void;
};

const Button = ({ type, mediaString, onError }: ButtonProps) => {
  const { t } = useTranslation();

  const doSendMedia = async (event: SyntheticEvent) => {
    event.preventDefault();
    collectAnalytics(type, Analytics);
    try {
      if (type === MEDIA_TYPE.EMAIL) {
        // open new email
        window.location.href = createMailtoLink(mediaString);
      } else if (
        type === MEDIA_TYPE.CHAT ||
        type === MEDIA_TYPE.TEAMS ||
        type === MEDIA_TYPE.SLACK
      ) {
        // open chat
        window.open(mediaString);
      }
    } catch (error) {
      log.error(error)();
      onError(t(type.error));
    }
  };

  const label = t(getContactPersonAriaLabel(type.value));

  return (
    <MediaLinkButton aria-label={label} onClick={doSendMedia}>
      <Icon name={type.iconName} size={26} />
    </MediaLinkButton>
  );
};

export default memo(Button);
