import styled from 'styled-components';

interface DetailsTextProps {
  $fontSize: number;
  $opacity: number;
}

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const DetailsText = styled.div<DetailsTextProps>`
  font-size: ${p => p.$fontSize}px;
  font-weight: 600;
  color: ${({ theme }) => theme.typo2};
  opacity: ${p => p.$opacity};
`;

export { DetailsContainer, DetailsText };
