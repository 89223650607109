import React, { memo, useEffect } from 'react';
import { Duration, DurationSelector } from '@engage-web/components/base';

// default values for 'Other' selector
const INITIAL_DURATION_FOR_SPACES = 30;

type ReserveDurationProps = {
  spaceId: number;
  isDesk?: boolean;
  isTeam?: boolean;
  duration: number;
  occupied: boolean;
  onChangeDuration: (duration: number) => void;
  allDayDuration: boolean;
  onAllDayToggle: (allDay?: boolean) => void;
  allDayDisabled: boolean;
  onDurationOtherClick?: () => void;
};
const ReserveDuration = memo(
  ({
    spaceId,
    isDesk,
    isTeam,
    duration,
    occupied,
    onChangeDuration,
    allDayDuration,
    onAllDayToggle,
    allDayDisabled,
    onDurationOtherClick,
  }: ReserveDurationProps) => {
    useEffect(() => {
      if (isDesk || isTeam) {
        // by default button 'All Day' is selected for desks
        onAllDayToggle(true);
        onChangeDuration(0);
      } else {
        // initial duration for spaces is 30 minutes
        onChangeDuration(INITIAL_DURATION_FOR_SPACES);
      }
    }, [spaceId, isDesk, onAllDayToggle, onChangeDuration, isTeam]);

    if (isDesk || isTeam) {
      return (
        <Duration
          type="HOURS"
          value={duration}
          canDeselect={false}
          disabled={occupied}
          allDaySelected={allDayDuration}
          allDayDisabled={allDayDisabled}
          onChange={onChangeDuration}
          onAllDayToggle={onAllDayToggle}
        />
      );
    }

    const renderDurationSelector = () => <DurationSelector onChange={onChangeDuration} />;

    return (
      <Duration
        type="MINUTES"
        value={duration}
        canDeselect={false}
        disabled={occupied}
        onChange={onChangeDuration}
        onDurationOtherClick={onDurationOtherClick}
        renderOtherComponent={renderDurationSelector}
      />
    );
  },
);
export { ReserveDuration };
