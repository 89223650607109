import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { teamReservationsSelectors } from '@engage-web/store';
import { MODALS } from '@engage-web/constants';
import { useDialog, useEscapeListener, useModal } from '@engage-web/utils';
import { isElectron } from '@engage-web/utils/electron';
import { useConciergeMenuAllowed } from '@engage-web/components/scenes/ConciergeMenu/hooks/useConciergeMenuAllowed';
import Header, { HeaderProps } from './components/Header';
import Content from './components/Content';
import { useModalSearchQuery } from './hooks/useModalSearchQuery';

const MODALS_WITH_NO_HEADER = [
  MODALS.SOCIAL_DISTANCING,
  MODALS.BOOKING_LOCATION_UNAVAILABLE,
  MODALS.HEALTH_DECLARATION,
];

type ModalsProps = {
  /**
   * Value governing visibility of agenda button.
   * `true` by default.
   * This is used only in Browser component.
   */
  hasAgenda?: boolean;
  /**
   * Value governing visibility of date time button.
   * `true` by default.
   * This is used only in Browser component.
   */
  hasDateTime?: boolean;
  // enable kiosk mode here
  isKiosk?: boolean;
  // override header props
  headerProps?: Partial<HeaderProps>;
};

const Modals = ({
  hasAgenda = true,
  hasDateTime = true,
  isKiosk = isElectron(),
  headerProps = {},
}: ModalsProps) => {
  const { conciergeMenuAllowed } = useConciergeMenuAllowed();
  const { layout, content, closeModal } = useModal();
  const { isOpen: isDialogOpen } = useDialog();
  const isInDeskCheckInMode = useSelector(teamReservationsSelectors.getIsInDeskCheckInMode);

  const clearModal = useCallback(() => {
    // since dialog is always on top of everything
    // we don't want to clear the modal if it is opened
    if (isDialogOpen) return;
    closeModal();
  }, [closeModal, isDialogOpen]);

  useEscapeListener({ onEscapePress: clearModal });

  useModalSearchQuery();

  // we want to hide header if one of these modals are displayed
  const isHeaderVisible = !MODALS_WITH_NO_HEADER.includes(layout);

  return (
    <>
      {isHeaderVisible ? (
        <Header
          hasAgendaButton={hasAgenda && !isInDeskCheckInMode}
          hasUserButton={!isInDeskCheckInMode}
          hasDateTime={hasDateTime}
          conciergeMenuAllowed={conciergeMenuAllowed}
          isDeskCheckInOn={isInDeskCheckInMode}
          isKiosk={isKiosk}
          {...headerProps}
        />
      ) : null}
      <Content layout={layout} content={content} />
    </>
  );
};

export default memo(Modals);
