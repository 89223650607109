import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHasChineseCharacters } from '@engage-web/utils/hooks/useHasChineseCharacters';
import { DurationButtonInput, DurationButtonLabel } from './styled';

interface DurationButtonProps {
  text?: string;
  selected: boolean;
  disabled: boolean;
  onClick: (value: number) => void;
  value: number;
  isOther?: boolean;
}

const DurationButton = ({
  text = '',
  selected,
  onClick,
  value,
  disabled,
  isOther = false,
}: DurationButtonProps) => {
  const { t } = useTranslation();

  const hasChineseCharacters = useHasChineseCharacters();

  return (
    <DurationButtonLabel
      className={isOther && selected ? 'sr-only' : undefined}
      data-testid="duration-button-label"
      $selected={selected}
      $disabled={disabled}
      $hasChineseCharacters={hasChineseCharacters}
    >
      <DurationButtonInput
        disabled={disabled}
        onClick={() => onClick(value)}
        type="radio"
        name={t('accessibilityLabels.bookingDuration')}
      />
      <span>{text}</span>
    </DurationButtonLabel>
  );
};

export { DurationButton };
