import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetchBuilding } from '../fetch';
import { Building, FetchBuildingQuery } from '../interfaces';
import { BUILDING_QUERY_KEY } from '../constants';

const STALE_TIME = 30 * 60 * 1000;
const queryOptions = {
  cacheTime: Infinity,
  staleTime: STALE_TIME,
};

type UseBuildingParams = {
  buildingId: number;
  options?: UseQueryOptions<Building>;
};

export const useBuilding = ({
  buildingId,
  options = {},
}: UseBuildingParams): UseQueryResult<Building> =>
  useQuery<Building>([BUILDING_QUERY_KEY, buildingId], () => fetchBuilding(buildingId), {
    enabled: !!buildingId,
    ...queryOptions,
    ...options,
  });

type UseBuildingFetch = () => {
  fetchBuildingQuery: FetchBuildingQuery;
};

export const useBuildingFetch: UseBuildingFetch = () => {
  const queryClient = useQueryClient();

  const query = (id: number): Promise<Building> =>
    queryClient.fetchQuery([BUILDING_QUERY_KEY, id], () => fetchBuilding(id), {
      ...queryOptions,
    });

  const fetchBuildingQuery = useCallback(query, [queryClient]);

  return { fetchBuildingQuery };
};
