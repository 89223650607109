import React, { useEffect } from 'react';
import { useLogout } from '@engage-web/api/mutations/useLogout';
import { useUser } from '@engage-web/api/queries';
import { useTranslation } from 'react-i18next';
import { useConfig } from '@engage-web/utils/hooks';
import { userHasGeneralAccess } from '@engage-shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { tenantSelectors, uiActions } from '@engage-web/store';
import { ConfirmationDialog } from '@engage-web/components/base';

export const AppAccessRestrictedDialog = () => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t('disableGeneralAccessTitle')}
      message={t('disabledGeneralAccess')}
      key="app-access-disabled"
      hasCancelButton={false}
    />
  );
};

export function useAppAccess() {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const dispatch = useDispatch();
  const { data: user } = useUser();
  const { disableWebGeneralAccess } = useConfig();
  const { mutate: logout } = useLogout();

  useEffect(() => {
    if (
      !userHasGeneralAccess({
        disableGeneralAccess: disableWebGeneralAccess,
        // @ts-ignore
        user,
      })
    ) {
      logout({ tenantId });
      dispatch(uiActions.showAppAccessDialog());
    }
  }, [disableWebGeneralAccess, dispatch, logout, tenantId, user]);
}
