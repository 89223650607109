import { ParsedSpaceItem, SpaceItem } from '@engage-shared/api/spaces/interfaces';
import { showDurationComponent } from '@engage-shared/utils/bookings';
import { mapSpaceItemAttributes } from '@engage-shared/api/bookings/utils/mapSpaceItemAttributes';

type FormatSpaceItemParams = {
  data: SpaceItem;
  enforceAllDayDeskReservations: boolean;
  isUpdate: boolean;
};

export const formatSpaceItem = ({
  data: spaceItem,
  enforceAllDayDeskReservations,
  isUpdate,
}: FormatSpaceItemParams): ParsedSpaceItem => {
  const attributes = mapSpaceItemAttributes(spaceItem);

  const showComponentFlags = showDurationComponent({
    spaceItem,
    isUpdate,
    enforceAllDayDeskReservations,
  });

  return { ...spaceItem, ...attributes, ...showComponentFlags };
};
