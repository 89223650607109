import * as React from 'react';

import { Space } from '@floorplan/api';
import { Shape } from '../../../components';
import { getKey } from '../utils';
import { nonOccupantPointStyle, pointSizes } from '../styles';
import { ElementsProps, EventInfo } from '../../../types';

interface NonOccupantPointsProps extends ElementsProps {
  nonOccupantPoints: Space[];
}
export const NonOccupantPoints = ({
  nonOccupantPoints,
  scale,
  onShapeClicked,
}: NonOccupantPointsProps) => {
  const styleFunction = (nonOccupantPoint: Space) => nonOccupantPointStyle(nonOccupantPoint);

  const getPointSize = () => pointSizes('important');

  const onOccupantPointSelect = (event: EventInfo) =>
    event.space.allowInteraction && onShapeClicked && onShapeClicked(event);

  return (
    <>
      {nonOccupantPoints.map((nonOccupantPoint, index) => (
        <Shape
          isPoint
          space={nonOccupantPoint}
          shape={nonOccupantPoint.shapes[0]}
          scale={scale}
          styleFunction={styleFunction}
          getPointSize={getPointSize}
          onShapeClicked={onOccupantPointSelect}
          key={getKey(nonOccupantPoint.id, index)}
        />
      ))}
    </>
  );
};
