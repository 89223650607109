import { TeamBookingsFailedMessages } from '../interfaces';
import { formatLocalizedDate, DateFormat } from '../../../utils/dates';
import { AxiosError } from 'axios';
import { LanguageTypes } from '../../../constants/languages';

type AppendDays = (daysString: string, formattedDay: string) => string;
const appendDays: AppendDays = (daysString, formattedDay) => {
  daysString += `${daysString ? ', ' : ''}${formattedDay}`;
  return daysString;
};

type HandleFailedBookingMessagesParams = {
  error: AxiosError;
  selectedDay: Date;
  timeZone: string;
  bookingsFailedMessages: TeamBookingsFailedMessages;
  locale: LanguageTypes;
};
type HandleFailedBookingMessages = (params: HandleFailedBookingMessagesParams) => void;
/**
 * Handle failed booking messages.
 */
export const handleFailedBookingMessages: HandleFailedBookingMessages = ({
  selectedDay,
  timeZone,
  bookingsFailedMessages,
  locale,
}) => {
  const formattedDay = formatLocalizedDate(selectedDay, {
    timeZone,
    format: DateFormat.weekDayShort,
    locale,
  });

  const failedDaysString = bookingsFailedMessages.failedDaysMessage;
  bookingsFailedMessages.failedDaysMessage = appendDays(failedDaysString, formattedDay);
};
