import { FloorMarker } from '@engage-shared/api/floors/interfaces';
import { Api, getApiUrl } from '@engage-shared/api/instance';

type FetchFloorMarkers = (floorId: number) => Promise<FloorMarker[]>;

/**
 * Retrieves all markers for passed floor id
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | markers found |
 *
 * @param floorId - id of the floor
 */
export const fetchFloorMarkers: FetchFloorMarkers = async floorId => {
  const path = `floors/${floorId}/markers`;
  const url = new URL(`${getApiUrl()}/${path}`);
  const { data } = await Api.get(url.toString());
  return data.data;
};
