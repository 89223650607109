import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BackButton, DateCard, LocationCard } from '@engage-web/components/base';
import { useSetPageTitle } from '@engage-web/utils/hooks';
import { DOCUMENT_TITLE_LEVEL } from '@engage-web/constants';
import { BOOKING_TYPE } from '@engage-shared/constants';
import { SpaceBookingButtonsBar } from './SpaceBookingButtonsBar';
import { TeamBookingButtonsBar } from './TeamBookingButtonsBar/TeamBookingButtonsBar';
import AttendeesList from '../AttendeesList/AttendeesList';
import { CollapsibleDeskList } from '../DeskList/CollapsibleDeskList';
import {
  Content,
  Header,
  Section,
  SectionTitle,
  TitleWrapper,
  CardWrapper,
  Wrapper,
  Description,
  CardTitle,
} from './styled';
import { BookingDetails, PersonId, TeamBookingDetails } from '@engage-shared/api';
import { Theme } from '@engage-shared/theme';

type BookingCardProps = {
  booking: BookingDetails | TeamBookingDetails;
  attendeeId?: PersonId;
  onBack: () => void;
  onShowLocation?: () => void;
};

export const BookingCard = memo(
  ({ booking, attendeeId, onBack, onShowLocation }: BookingCardProps) => {
    const {
      title,
      startDate,
      endDate,
      allDay,
      description,
      localTimeZone,
      location,
      attendees,
      canUpdate,
      isInInactiveLocation,
      bookingType,
    } = booking;

    const teamBooking = booking as TeamBookingDetails;
    const spaceBooking = booking as BookingDetails;

    const { desk, meetingRoom, isDesk, bookedFor } = spaceBooking;
    const { deskBookings } = teamBooking;

    const { t } = useTranslation();
    const { color6 } = useTheme() as Theme;

    const hasLimitPer15Minutes = false;

    const showDateCard = !!startDate || !!endDate || allDay;
    const hasAttendees = !isDesk && !!attendees && attendees.length > 0;
    const hasReservee = bookedFor?.length > 0;

    useSetPageTitle(t('documentTitles.bookingDetails'), DOCUMENT_TITLE_LEVEL.MODAL);

    return (
      <Wrapper>
        <Header>
          <BackButton onClick={onBack} />
          <TitleWrapper>
            {!!title && <CardTitle>{title}</CardTitle>}
            {showDateCard && (
              <CardWrapper>
                <DateCard
                  dateStart={startDate}
                  dateEnd={endDate}
                  allDay={allDay}
                  localTimeZone={localTimeZone}
                  color={color6}
                  hasLimitPer15Minutes={hasLimitPer15Minutes}
                />
              </CardWrapper>
            )}
          </TitleWrapper>
        </Header>
        <Content>
          <Section>
            <LocationCard
              isDesk={isDesk}
              location={location}
              desk={desk}
              meetingRoom={meetingRoom}
              onShowLocation={onShowLocation}
              disableButton={isInInactiveLocation}
            />
            {canUpdate &&
              (bookingType === BOOKING_TYPE.TEAM ? (
                <TeamBookingButtonsBar
                  booking={teamBooking}
                  onBack={onBack}
                  attendeeId={attendeeId}
                  onShowLocation={onShowLocation}
                />
              ) : (
                <SpaceBookingButtonsBar booking={spaceBooking} />
              ))}
          </Section>

          <Section>
            <SectionTitle>
              {isDesk ? t('layout.bookingCard.deskType') : t('layout.bookingCard.meetingDetails')}
            </SectionTitle>
            {!!description && <Description>{description}</Description>}
          </Section>

          {(hasAttendees || hasReservee) && (
            <Section id="BookingCard_attendee_id">
              <SectionTitle>
                {hasAttendees
                  ? t('layout.bookingCard.attendees')
                  : t('layout.bookingCard.bookedFor')}
              </SectionTitle>
              <AttendeesList isDesk={isDesk} attendees={hasAttendees ? attendees : bookedFor} />
            </Section>
          )}

          {deskBookings ? <CollapsibleDeskList desks={deskBookings} /> : null}
        </Content>
      </Wrapper>
    );
  },
);
