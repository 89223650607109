import React, { HTMLProps } from 'react';
import { Icon } from '@engage-web/components/base';
import { AnimatedHeight } from '@engage-web/components/scenes/drawer/AnimatedHeight';
import { Section, SectionTitle } from '../Card/styled';
import { DeskList } from './DeskList';
import { Summary, Details } from './styled';
import { TeamBookingDetails } from '@engage-shared/api';

type CollapsibleDeskListProps = {
  desks: TeamBookingDetails['deskBookings'];
};

export function CollapsibleDeskList({ desks }: CollapsibleDeskListProps) {
  const detailsProps: HTMLProps<'details'> = {};

  if (desks.length <= 5) {
    detailsProps.open = true;
  }

  return (
    <Section>
      <AnimatedHeight duration={200}>
        {/* @ts-ignore ref prop is incompatible, looks like some react types issue */}
        <Details {...detailsProps}>
          <Summary>
            <SectionTitle>Desks</SectionTitle>
            <Icon name="chevron-right" size={10} />
          </Summary>
          <DeskList desks={desks} />
        </Details>
      </AnimatedHeight>
    </Section>
  );
}
