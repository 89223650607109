import React from 'react';
import { CurrentDateTime } from '@engage-web/components/base';
import { useSelector } from 'react-redux';
import { tenantSelectors, userConfigSelectors } from '@engage-web/store';
import { useNavigate } from 'react-router-dom';
import { KIOSK_ADMIN } from '@engage-web/constants';

type KioskCurrentDateTimeProps = {
  textColor?: string;
};
const KioskCurrentDateTime = ({ textColor }: KioskCurrentDateTimeProps) => {
  const navigate = useNavigate();
  const {
    userLocation: { building },
  } = useSelector(tenantSelectors.getKioskConfig);
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const handleAdminPress = () => {
    navigate(KIOSK_ADMIN);
  };

  const timeZone = building?.timeZone;

  return (
    <CurrentDateTime
      locale={locale}
      timeZone={timeZone}
      textColor={textColor}
      onAdminPress={handleAdminPress}
    />
  );
};

export default KioskCurrentDateTime;
