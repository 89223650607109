import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppLocation, useAppParams } from '@engage-web/router/hooks';
import { removeSegmentFromPath } from '@engage-web/router/utils';
import { A11yHiddenLabel } from '@engage-web/styles';
import { userConfigSelectors } from '@engage-web/store';
import { PATH_SEGMENT } from '@engage-web/constants';
import { FocusId, useFocus } from '@engage-web/utils';
import { EmptyCard, Text } from '@engage-web/components/base';
import { DrawerCardContent } from '@engage-web/components/scenes/drawer/DrawerCardContent';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { SpaceAttributesSkeleton } from './SpaceAttributesSkeleton';
import { ContentWrapper, ItemWrapper, TextWrapper } from './SpaceAttributes.style';
import { SpaceTypesData, useSpaceAttributesQuery } from '@engage-shared/api';

const SpaceAttributes = () => {
  const { t } = useTranslation();

  const { spaceId: spaceIdFromParams } = useAppParams();
  const { state, pathname } = useAppLocation();
  const spaceFromState = state?.space;
  const spaceAttributesFromState = state?.spaceAttributes;
  const spaceId = spaceIdFromParams ?? spaceFromState?.id;

  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const a11yTeamMembersList = t('layout.spaceAttributes.a11ySpaceAttributesList');
  const title = t('layout.drawer.menu.equipment');
  const subtitle = `${spaceId}`;

  useFocus({
    returnFocusTo: FocusId.SpaceAttributes,
    focusOn: FocusId.DrawerCloseButton,
  });

  const { isLoading, isError, data } = useSpaceAttributesQuery({
    spaceId: Number(spaceId),
    locale,
  });

  const spaceAttributes = (data ?? spaceAttributesFromState) as SpaceTypesData;

  const renderHeader = () => {
    const closeLinkPath = removeSegmentFromPath(PATH_SEGMENT.SPACE_ATTRIBUTES, pathname);
    return (
      <DrawerHeader
        title={title}
        subtitle={subtitle}
        isCloseIcon
        closeLinkPath={closeLinkPath}
        closeIconA11yLabel="accessibilityLabels.closeLayout_equipmentList"
      />
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <ContentWrapper data-testid="loading-skeleton">
          <SpaceAttributesSkeleton />
        </ContentWrapper>
      );
    }

    if (isError) {
      return <EmptyCard iconName="info">{t('common.networkError')}</EmptyCard>;
    }

    return (
      <DrawerCardContent>
        <ContentWrapper data-testid="locations">
          {spaceAttributes.map(({ id, name }) => (
            <ItemWrapper key={id}>
              <TextWrapper>
                <Text>{name}</Text>
              </TextWrapper>
            </ItemWrapper>
          ))}
        </ContentWrapper>
      </DrawerCardContent>
    );
  };

  return (
    <>
      <A11yHiddenLabel>{a11yTeamMembersList}</A11yHiddenLabel>
      {renderHeader()}
      {renderContent()}
    </>
  );
};

export default memo(SpaceAttributes);
