import { useSelector, useDispatch } from 'react-redux';
import { navigationSelectors, navigationActions } from '@engage-web/store';
import { MODALS } from '@engage-web/constants';
import { useBookingModal } from './useBookingModal';

type OpenModalOptions = {
  /**
   * If this is true, when a modal is opened team booking
   * query search will be cleared.
   *
   * Currently it is used when opening health declaration
   * modal to keep the booking card opened when accepting/declining.
   */
  shouldClearBookingQuery: boolean;
};

type OpenModal = (
  layout: (typeof MODALS)[keyof typeof MODALS],
  content?: Record<string, unknown>,
  options?: OpenModalOptions,
) => void;

const defaultOptions: OpenModalOptions = {
  shouldClearBookingQuery: true,
};

export function useModal() {
  const { layout, content } = useSelector(navigationSelectors.getModalLayout);
  const dispatch = useDispatch();
  const { close: closeBookingModal } = useBookingModal();

  const isBookingDetailsOpened =
    layout === MODALS.AGENDA_ITEM || layout === MODALS.TEAM_AGENDA_ITEM;

  const openModal: OpenModal = (nextLayout, nextContent, options = defaultOptions) => {
    if (layout === nextLayout) {
      dispatch(navigationActions.clear());
      return;
    }

    if (isBookingDetailsOpened && options.shouldClearBookingQuery) {
      closeBookingModal();
    }

    dispatch(
      navigationActions.setModalLayout({
        layout: nextLayout,
        content: nextContent,
      }),
    );
  };

  const closeModal = () => {
    if (isBookingDetailsOpened) {
      closeBookingModal();
    }

    dispatch(navigationActions.clearModal());
  };

  return {
    layout,
    content,
    openModal,
    closeModal,
  };
}
