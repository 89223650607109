import styled, { keyframes } from 'styled-components';

const bouncingAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

export const BouncingWrapper = styled.g`
  animation-name: ${bouncingAnimation};
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 5;
`;
