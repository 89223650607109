import {
  useTeamBookingDetails as useTeamBookingDetailsQuery,
  useRefreshTeamBooking as useRefreshTeamBookingHook,
  TeamBookingDetails,
} from '@engage-shared/api';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { Nullable } from '@engage-shared/utils/types';
import { useSelector } from 'react-redux';
import { useUser } from '@engage-web/api/queries/useUser';
import { tenantSelectors } from '@engage-web/store';

const QUERY_STALE_TIME = 1000 * 60 * 10; // 10 minutes

type UseTeamBookingDetails = (
  id: number,
  options?: UseQueryOptions<Nullable<TeamBookingDetails>>,
) => UseQueryResult<Nullable<TeamBookingDetails>>;

export const useTeamBookingDetails: UseTeamBookingDetails = (id, options = {}) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const user = useUser();
  const userData = user?.data || {};

  const opts = {
    ...options,
    enabled: (options.enabled ?? true) && !!id && user.isSuccess,
    staleTime: QUERY_STALE_TIME,
  };

  return useTeamBookingDetailsQuery({
    tenantId,
    id,
    // @ts-ignore
    userData,
    options: opts,
  });
};

export const useRefreshTeamBooking = () => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const userId = useSelector(tenantSelectors.getUserId);
  return useRefreshTeamBookingHook({ tenantId, userId });
};
