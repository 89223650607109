import { isValid, parseISO } from 'date-fns';
import { ISODateString } from '@engage-shared/utils/types';
import { getLogger } from '@engage-shared/utils/logger';
import { SpaceType } from '@engage-shared/api/spaces/interfaces';
import { EmployeeId } from '@engage-shared/api/people/interfaces';

export const getTimeQueryString = (
  startTime?: ISODateString | Date | string,
  endTime?: ISODateString | Date | string,
): string => {
  let queryString = '';

  if (startTime) {
    let start;

    if (startTime instanceof Date) {
      start = startTime.toISOString();
    } else if (isValid(parseISO(startTime))) {
      start = startTime;
    }

    if (start) {
      queryString = `?startTime=${start}`;
    } else {
      getLogger().error(
        'getTimeQueryString should be called with ISO date strings or date objects',
      );
    }
  }

  if (endTime) {
    const op = queryString ? `&` : `?`;

    let end;

    if (endTime instanceof Date) {
      end = endTime.toISOString();
    } else if (isValid(parseISO(endTime))) {
      end = endTime;
    }

    if (end) {
      queryString = `${queryString}${op}endTime=${end}`;
    } else {
      getLogger().error(
        'getTimeQueryString should be called with ISO date strings or date objects',
      );
    }
  }

  return queryString;
};

// spaceType is one of {'Desk', 'Space'}, while query expects one of {'Desk', 'Room'} and getPathWithSpaceType solves it
export const getSpaceTypeQueryString = (spaceType: SpaceType): string => {
  let queryString = '';
  if (spaceType && (spaceType === 'Desk' || spaceType === 'Space')) {
    queryString = `restrictTo=${spaceType === 'Desk' ? 'Desk' : 'Room'}`;
  } else {
    getLogger().warn(
      "getSpaceTypeQueryString should be called with one argument having value 'Desk' or 'Space'",
    );
  }
  return queryString;
};

type GetFetchEmployeeQueryString = ({
  startTime,
  endTime,
  employeeId,
}: {
  startTime?: Date;
  endTime?: Date;
  employeeId: EmployeeId;
}) => string;

export const getFetchEmployeeQueryString: GetFetchEmployeeQueryString = ({
  startTime,
  endTime,
  employeeId,
}) => {
  const timeQueryString = getTimeQueryString(startTime, endTime);
  if (!employeeId) return `${timeQueryString}`;
  return timeQueryString
    ? `${timeQueryString}&employee_number=${employeeId}`
    : `?employee_number=${employeeId}`;
};
