import React, { useCallback, useState } from 'react';
import { ParsedTeam } from '@engage-shared/api/teams';
import TeamMembersList from './TeamMembersList';
import { TeamMembersSearch } from './TeamMembersSearch';

type TeamMembersCardProps = {
  team: ParsedTeam;
};

export function TeamMembersCard(props: TeamMembersCardProps) {
  const [selectedTeamMembersIds, setSelectedTeamMembersIds] = useState<number[]>([]);

  const togglePersonSelectedIds = useCallback((personId: number) => {
    setSelectedTeamMembersIds(ids =>
      ids.includes(personId) ? ids.filter(id => id !== personId) : [...ids, personId],
    );
  }, []);

  return (
    <>
      <TeamMembersSearch />
      <TeamMembersList
        {...props}
        selectedIds={selectedTeamMembersIds}
        toggleSelectedId={togglePersonSelectedIds}
      />
    </>
  );
}
