import React, { HTMLAttributes } from 'react';
import { ModalStyledButton, ModalButtonWrapper, ModalButtonText } from './ModalButton.styled';

type ModalButtonProps = HTMLAttributes<HTMLDivElement> & {
  text: string;
};

const ModalButton = ({ text, ...rest }: ModalButtonProps) => (
  <ModalButtonWrapper {...rest}>
    <ModalStyledButton>
      <ModalButtonText>{text}</ModalButtonText>
    </ModalStyledButton>
  </ModalButtonWrapper>
);

export default ModalButton;
