import { Nullable } from '@engage-shared/utils/types';
import { MenuItem } from '@engage-web/components/scenes/drawer/header/DrawerMenu/DrawerMenu';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  FavouriteType,
  PERSON_QUERY_KEY,
  useAddToFavourite,
  useRemoveFromFavourite,
} from '@engage-shared/api';
import { inAppNotificationsActions } from '@engage-web/store';
import { SPACE_KEY } from '@engage-web/api/queries';
import { useQueryClient } from 'react-query';
import { SpaceTypes, useFavouritesMutationErrorHandler } from '@engage-web/utils';

type UseToggleFavouriteMenuItem = (props: {
  isFavourite: boolean;
  itemId?: string;
  itemType: FavouriteType;
  itemOriginalType: SpaceTypes;
}) => Nullable<MenuItem>;

export const useToggleFavouriteMenuItem: UseToggleFavouriteMenuItem = ({
  isFavourite,
  itemId,
  itemType,
  itemOriginalType,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mutate: addToFavourite } = useAddToFavourite();
  const { mutate: removeFromFavourite } = useRemoveFromFavourite();
  const { onError } = useFavouritesMutationErrorHandler(itemOriginalType);

  const sendSuccessNotification = (isAdd: boolean) => {
    const message = t(
      `layout.notifications.${isAdd ? 'addToFavouriteSuccess' : 'removeFromFavouriteSuccess'}`,
    );
    dispatch(
      inAppNotificationsActions.addSuccessNotification({
        message,
      }),
    );
  };

  const updateItemQueryData = () => {
    const itemQueryKey = itemType === 'person' ? [PERSON_QUERY_KEY, itemId] : [SPACE_KEY, itemId];
    queryClient.invalidateQueries(itemQueryKey);
  };

  const onSuccess = (isAdd: boolean) => {
    sendSuccessNotification(isAdd);
    updateItemQueryData();
  };

  if (!itemId) return null;

  const textTransId = isFavourite
    ? 'layout.drawer.menu.removeFromFavourites'
    : 'layout.drawer.menu.addToFavourites';

  const onPress = () => {
    if (isFavourite) {
      removeFromFavourite(
        { id: parseInt(itemId), type: itemType },
        {
          onError,
          onSuccess: () => onSuccess(false),
        },
      );
      return;
    }
    addToFavourite(
      { id: parseInt(itemId), type: itemType },
      {
        onError,
        onSuccess: () => onSuccess(true),
      },
    );
  };

  return {
    name: t(textTransId),
    onPress,
  };
};
