import React, { Component, PropsWithChildren, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import logger from '@engage-web/utils/logger';
import ModalButton from '@engage-web/components/base/ModalButton/ModalButton';
import { Container, AlertContainer, Title, Subtitle } from './ErrorBoundary.styled';

type ErrorBoundaryProps = PropsWithChildren<WithTranslation>;
type ErrorBoundaryState = {
  hasFatalError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasFatalError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error({
      message: error.message,
      info: errorInfo.componentStack,
    });

    this.setState({ hasFatalError: true });
  }

  onRestartClick = () => {
    window.location.href = '/';
    this.setState({ hasFatalError: false });
  };

  renderErrorAlert(): ReactNode {
    const { t } = this.props;

    return (
      <Container data-testid="errorBoundaryContainer">
        <AlertContainer>
          <Title>{t('components.errorBoundary.title')}</Title>
          <Subtitle>{t('components.errorBoundary.subtitle')}</Subtitle>
          <ModalButton
            data-testid="restart-button"
            text={t('components.errorBoundary.action')}
            onClick={this.onRestartClick}
          />
        </AlertContainer>
      </Container>
    );
  }

  render() {
    const { hasFatalError } = this.state;
    const { children } = this.props;
    return hasFatalError ? this.renderErrorAlert() : children;
  }
}

const ErrorBoundaryWithTranslation = withTranslation()(ErrorBoundary);
export { ErrorBoundaryWithTranslation as ErrorBoundary };
