import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parsePersonData, Person } from '@engage-shared/api/people';
import { Nullable } from '@engage-shared/utils/types';
import { inAppNotificationsActions } from '@engage-web/store';
import { ROOT_PATH } from '@engage-web/constants';
import logger from '@engage-web/utils/logger';
import useEmployeeCheck from '@engage-web/components/scenes/drawer/hooks/useEmployeeCheck';
import { useGenerateCurrentLocationPath } from '@engage-web/utils';
import { useAppParams } from '@engage-web/router/hooks';
import { Mask } from '@engage-web/components/base';

type EmployeeContainerProps = {
  /**
   * Render function which returns a component.
   */
  render: (person: Person) => ReactElement;
};

/**
 * Component which provides `person` data based on `employeeId`.
 * Used for rendering person/person locations cards for employee.
 */
export function EmployeeContainer({ render }: EmployeeContainerProps) {
  const { employeeId } = useAppParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { checkEmployeeQuery } = useEmployeeCheck();
  const currentLocationPath = useGenerateCurrentLocationPath();
  const [person, setPerson] = useState<Nullable<Person>>(null);

  useEffect(() => {
    if (employeeId) {
      checkEmployeeQuery(employeeId).then(({ data, errorMessage }) => {
        if (data) {
          setPerson(parsePersonData({ person: data }));
        } else if (errorMessage) {
          logger.error(t(errorMessage))();
          dispatch(
            inAppNotificationsActions.addWarningNotification({
              message: t(errorMessage),
            }),
          );
          // reset url
          navigate(currentLocationPath || ROOT_PATH);
        }
      });
    }
  }, [checkEmployeeQuery, currentLocationPath, dispatch, employeeId, navigate, t]);

  if (!person) {
    return <Mask isLoading />;
  }

  return render(person);
}
