import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { isDateInArray, useConfig, useReservationFilters } from '@engage-web/utils';
import { getDayStartTime } from '@engage-shared/utils';
import { format } from 'date-fns';
import { FilterProps } from '@engage-web/utils/hooks/useReservationFilters';
import { useSelector } from 'react-redux';
import { filtersSelectors } from '@engage-web/store';
import { DaysSelector as DaysSelectorComponent } from '@engage-web/components/base';
import { getDisabledDays } from '../../reserveCardHelpers';
import { DurationObj } from '../types';
import { ReserveCardContext } from '../ReserveCardContext';
import { DaysSelectorWrapper } from '../../styled';

const noDays = [] as Date[];

export const DaysSelector = () => {
  const { spaceItem, isTeam, timeZone, durationObj, setDurationObj, bookings, setReserveDisabled } =
    useContext(ReserveCardContext);

  const selectedDays = durationObj.selectedDays;

  const { id: spaceId, isDesk, buildingId, showDaysSelector } = spaceItem || {};
  const reservationFilter = useReservationFilters(isDesk);
  const { dateStart: filterDateStart } = reservationFilter;

  // Days from Reservation assist
  const { selectedDates }: FilterProps = useSelector(filtersSelectors.getFilterDates);

  const config = useConfig(buildingId);
  const { futureBookingsLimit } = config;

  const disabledDays = useMemo(
    () =>
      isTeam
        ? noDays
        : getDisabledDays({
            bookings,
            futureBookingsLimit,
            filterDateStart,
            timeZone,
          }),
    [bookings, filterDateStart, futureBookingsLimit, isTeam, timeZone],
  );

  const daysToPreselect = selectedDates.filter((date: Date) => !isDateInArray(date, disabledDays));
  const daysToPreselectString = JSON.stringify(daysToPreselect);
  const firstDay = useMemo(
    () =>
      getDayStartTime({
        date: filterDateStart || new Date(),
        timeZone,
      }),
    [filterDateStart, timeZone],
  );
  const isFirstDayEnabled = !isDateInArray(firstDay, disabledDays);

  useEffect(() => {
    if (selectedDays.length === 0) {
      let initialSelectedDays = daysToPreselect.length > 0 ? daysToPreselect : [];

      // only preselect first day if no days selected in Reservation assist or previously (state)
      if (isFirstDayEnabled && selectedDates.length === 0) {
        initialSelectedDays = [firstDay];
      }

      setDurationObj((state: DurationObj) => ({
        ...state,
        selectedDays: initialSelectedDays,
      }));
    }

    // we need to use daysToPreselect which is an array and can not be properly checked by useEffect
    // that's why we use string version of this array as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDay, daysToPreselectString, isFirstDayEnabled, spaceId]);

  const selectedWeekdaysString = selectedDays.map((day: Date) => format(day, 'EEE')).join(',');

  // if user changed days -> enable reserve button
  // if no days selected -> disable reserve button
  useEffect(() => {
    if (showDaysSelector) {
      // means "no selected days"
      if (!selectedWeekdaysString) {
        setReserveDisabled(true);
      }
      setReserveDisabled(false);
    }
  }, [selectedWeekdaysString, setReserveDisabled, showDaysSelector]);

  const onDaySelected = useCallback(
    (selectedDays: Date[]) => setDurationObj((state: DurationObj) => ({ ...state, selectedDays })),
    [setDurationObj],
  );

  const currentDateRef = useRef(new Date());

  return (
    <DaysSelectorWrapper>
      <DaysSelectorComponent
        onDaySelected={onDaySelected}
        selectedDays={selectedDays}
        disabledDays={disabledDays}
        dateStart={filterDateStart || currentDateRef.current}
        timeZone={timeZone}
        isMultiDayDeskReservation
      />
    </DaysSelectorWrapper>
  );
};
