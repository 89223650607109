import { getFilterEndTime, getFilterStartTime } from '../dates/filterHelpers';
import { addHours, addMinutes, isBefore } from 'date-fns';
import { SpaceType } from '@engage-shared/api/spaces/interfaces';
import { ISODateString } from '@engage-shared/utils/types';

// calculate start time and end time according to rules from
// https://serraview.atlassian.net/wiki/spaces/PC/pages/1911980729/Floorplan+time+calculations
export const useFilterTimes = ({
  dateStart,
  dateEnd,
  timeZone,
  enforceAllDayDeskReservations,
  restrictTo,
}: {
  dateStart: Date | null;
  dateEnd: Date | null;
  timeZone: string;
  enforceAllDayDeskReservations: boolean;
  restrictTo?: SpaceType.DESK | SpaceType.SPACE | SpaceType.PERSON;
}): {
  startTime: ISODateString;
  endTime: ISODateString;
  roomsStartTime: ISODateString;
  roomsEndTime: ISODateString;
} => {
  const allDay = restrictTo === SpaceType.DESK ? enforceAllDayDeskReservations : false;
  const start = getFilterStartTime(dateStart, timeZone, allDay);
  let end = getFilterEndTime(start, dateEnd, timeZone, allDay);

  // EEX-234: use 15 minutes intervals for rooms availability
  const roomsStart = getFilterStartTime(dateStart, timeZone, false);
  const roomsEnd = addMinutes(roomsStart, 15);

  // P2-1963: if (somehow) endDate is before startDate - add an hour to the endDate
  if (isBefore(end, start)) {
    end = addHours(end, 1);
  }

  return {
    startTime: start.toISOString() as ISODateString,
    endTime: end.toISOString() as ISODateString,
    roomsStartTime: roomsStart.toISOString() as ISODateString,
    roomsEndTime: roomsEnd.toISOString() as ISODateString,
  };
};
