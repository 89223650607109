import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color6};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media screen and (min-height: 1024px) {
    justify-content: flex-start;
  }
`;

export const LogoContainer = styled.div`
  margin: 30px 0 20px;

  @media only screen and (min-height: 800px) {
    margin-bottom: 50px;
    margin-top: 80px;
  }

  @media screen and (min-height: 1024px) {
    margin-bottom: 100px;
    margin-top: 150px;
  }

  @media screen and (max-width: 480px) and (max-height: 799px) {
    align-self: start;
    margin-left: 20px;
  }
`;

export const ButtonContainer = styled.div`
  flex-direction: row;
  margin-top: 55px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }

  @media screen and (max-height: 580px) {
    margin: 20px 0;
    flex-direction: row-reverse;
  }

  @media screen and (min-height: 768px) {
    margin: 30px 0;
  }
`;

export const CancelButton = styled.button`
  text-decoration: none;
  font-size: 17px;
  color: ${({ theme }) => theme.typo2};
  margin-right: 40px;
  padding: 10px 20px;

  @media screen and (min-height: 580px) {
    cursor: pointer;
    padding: 22px;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 20px;
    order: 2;
    margin-right: 0;
  }

  @media screen and (max-height: 580px) {
    margin-top: 0;
    margin-right: 20px;
  }

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const Title = styled.h3`
  margin-bottom: 40px;
  text-align: center;

  @media only screen and (max-width: 700px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

export const MessageContainer = styled.div`
  max-width: 790px;
  max-height: 300px;
  overflow: auto;
  text-align: left;
  scrollbar-color: ${({ theme }) => theme.color10} ${({ theme }) => theme.color3}; /* thumb and track color only for Firefox */
  scrollbar-width: thin; /* only for Firefox */
  min-height: 120px;

  @media (max-width: 1200px) and (min-width: 700px) {
    margin: 0 90px;
  }

  @media (max-width: 700px) and (min-width: 400px) {
    max-height: 360px;
  }

  @media only screen and (max-width: 400px) {
    max-height: 300px;
  }

  @media only screen and (max-width: 700px) {
    margin: 0 30px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    overflow-x: hidden;
    background: ${({ theme }) => theme.color3};
  }
  &::-webkit-scrollbar-track:horizontal {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.color10};
  }
`;

export const ConfirmButtonWrapper = styled.div`
  display: inline-block;
  border-radius: 99px;
  text-align: center;
  padding-left: 0;
  background-color: ${({ theme }) => theme.color10};
  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.3);
  :focus-within {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const ConfirmButton = styled.button`
  cursor: pointer;
  padding: 10px 20px;

  @media screen and (min-height: 580px) {
    cursor: pointer;
    padding: 22px 105px;
  }
`;

export const ConfirmButtonText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.typo1};
`;
