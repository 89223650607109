import 'swiper/swiper.min.css';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Keyboard, Mousewheel, Navigation } from 'swiper';

import { Icon } from '../../base';
import { isElectron } from '../../../utils/electron';
import { ConciergeMenuItem } from './ConciergeMenuItem';
import {
  CONCIERGE_MENU_ANIMATION_DURATION,
  CONCIERGE_MENU_BREAKPOINTS,
  CONCIERGE_MENU_NEXT_BUTTON_CLASSNAME,
  CONCIERGE_MENU_PREV_BUTTON_CLASSNAME,
} from './constants';
import { ConciergeMenuNavButton, SwiperWrapper } from './styled';
import { IMenuItem } from '../../../api/queries/menu/transformMenuItems';

SwiperCore.use([Navigation, Mousewheel, Keyboard, A11y]);

const theme = isElectron() ? 'theme-dark' : 'theme-light';

interface ConciergeMenuCarouselProps {
  menuItems: IMenuItem[];
}

export const ConciergeMenuCarousel = ({ menuItems = [] }: ConciergeMenuCarouselProps) => (
  <SwiperWrapper data-testid="concierge-menu-carousel" className={theme}>
    <Swiper
      a11y={{
        enabled: true,
      }}
      navigation={{
        nextEl: `.${CONCIERGE_MENU_NEXT_BUTTON_CLASSNAME}`,
        prevEl: `.${CONCIERGE_MENU_PREV_BUTTON_CLASSNAME}`,
      }}
      mousewheel={{
        invert: true,
      }}
      keyboard
      loop={menuItems.length >= 2}
      loopedSlides={menuItems.length}
      slidesPerView={menuItems.length >= 3 ? 3 : menuItems.length}
      slideToClickedSlide
      direction="vertical"
      speed={CONCIERGE_MENU_ANIMATION_DURATION}
      breakpoints={{
        [CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL]: {
          direction: 'horizontal',
        },
      }}
    >
      {menuItems.map(menuItem => (
        <SwiperSlide key={menuItem.id} data-testid={`concierge-menu-slide-${menuItem.id}`}>
          {({ isActive }) => <ConciergeMenuItem isActive={isActive} menuItem={menuItem} />}
        </SwiperSlide>
      ))}
      {menuItems.length > 1 && (
        <>
          <ConciergeMenuNavButton className={CONCIERGE_MENU_PREV_BUTTON_CLASSNAME}>
            <Icon name="arrow-left" size={40} />
          </ConciergeMenuNavButton>
          <ConciergeMenuNavButton className={CONCIERGE_MENU_NEXT_BUTTON_CLASSNAME}>
            <Icon name="arrow-right" size={40} />
          </ConciergeMenuNavButton>
        </>
      )}
    </Swiper>
  </SwiperWrapper>
);
