import React from 'react';
import { useDispatch } from 'react-redux';
import { useNotifications } from '@engage-web/api/queries/useNotificationEvents';
import { useReservationNotification } from './useReservationNotification';

export const useInitNotifications = () => {
  const dispatch = useDispatch();
  const eventsQuery = useNotifications({
    options: {
      refetchInterval: 15 * 60 * 1000, // refetch every 15 minutes
      refetchIntervalInBackground: true, // will continue to refetch while tab/window is in the background
    },
  });
  const { handleLocalNotification } = useReservationNotification();

  React.useEffect(() => {
    if (eventsQuery) {
      if (eventsQuery.isSuccess) {
        const events = eventsQuery.data;
        events?.map(event => handleLocalNotification({ event }));
      }
    }
  }, [dispatch, eventsQuery, handleLocalNotification]);
};
