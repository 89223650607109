import { useSelector } from 'react-redux';
import {
  TeamId,
  useMultiDayTeamBookingMutation as useSharedMultiDayTeamBookingMutation,
  BookingFilter,
} from '@engage-shared/api';
import { tenantSelectors, userConfigSelectors } from '../../../store';

type useMultiDayTeamBookingMutationProps = {
  teamId: TeamId;
  summary: string;
  filter: BookingFilter;
  timeZone: string;
  desks: number[];
  attendees: number[];
  selectedDays: Date[];
  onSuccess?: ({
    successfullyReserved,
    successfullyReservedDaysMessage,
    failedDaysMessage,
  }: {
    successfullyReserved: boolean;
    successfullyReservedDaysMessage: string;
    failedDaysMessage: string;
  }) => void;
  onError?: (e: Error) => void;
};
export const useMultiDayTeamBookingMutation = ({
  teamId,
  summary,
  timeZone,
  attendees,
  desks,
  filter,
  selectedDays,
  onSuccess,
  onError,
}: useMultiDayTeamBookingMutationProps) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const multiDayTeamBookingMutation = useSharedMultiDayTeamBookingMutation(
    {
      tenantId,
      teamId,
      summary,
      timeZone,
      attendees,
      desks,
      filter,
      selectedDays,
      successCallback: () => {
        // TODO: add analytics
      },
      errorCallback: () => {
        // TODO: add analytics
      },
      locale,
    },
    {
      onSuccess,
      onError,
    },
  );

  const { onBooking } = multiDayTeamBookingMutation;

  return { ...multiDayTeamBookingMutation, onBooking };
};
