import {
  addMinutes,
  addMonths,
  isBefore,
  isSameMonth,
  startOfMonth as fnsStartOfMonth,
  subMonths,
} from 'date-fns';
import { i18n } from '../../../locales';
import { checkOrParse, formatLocalizedTime, hasBookingFinished } from '@engage-shared/utils';
import { AgendaItems } from '@engage-shared/api';
import { AnyDate, DateISODate } from '@engage-shared/utils/types';
import { LanguageTypes } from '@engage-shared/constants';

export const DATE_NAVIGATION = {
  PREVIOUS: 'previous',
  NEXT: 'next',
};

const FROM_NOW = {
  PAST: 'past',
  ONGOING: 'now',
  FUTURE: 'future',
};

const sameMonth = (date1: DateISODate, date2: DateISODate): boolean => {
  const _date1 = checkOrParse(date1);
  const _date2 = checkOrParse(date2);
  return isSameMonth(_date1, _date2);
};

const startOfMonth = (date: DateISODate) => fnsStartOfMonth(checkOrParse(date));

const getTimeString = (date: AnyDate, timeZone: string, locale: LanguageTypes): string =>
  date ? formatLocalizedTime(date, { timeZone, locale }) : '';

const getTimeSeparator = (start: DateISODate, end: DateISODate) => (start && end ? '-' : '');

interface GetTimeTextParams {
  start: DateISODate;
  end: DateISODate;
  timeZone: string;
  isAllDayBooking: boolean;
  locale: LanguageTypes;
}
const getTimeText = ({
  start,
  end,
  timeZone,
  isAllDayBooking,
  locale,
}: GetTimeTextParams): string => {
  const _end = checkOrParse(end);
  const hasFinished = hasBookingFinished(_end);

  // if all day booking ended display the start and end date instead of the All Day label and start date(P2-3871)
  if (isAllDayBooking && !hasFinished) {
    return `${i18n.t('layout.agendaList.allDay')} - ${getTimeString(
      start,
      timeZone,
      locale,
    )} ${i18n.t('layout.agendaList.arrival')}`;
  }
  // we're adding 1 minute to end time because we subtract 1 minute from end time on booking creation phase
  // this was done in order to avoid 09:00 - 09:29 displaying

  // Convert DateISODate to Date
  const endDate = new Date(end);

  const endTime = addMinutes(endDate, 1);

  return `${getTimeString(start, timeZone, locale)}
  ${getTimeSeparator(start, end)} ${getTimeString(endTime, timeZone, locale)}`;
};

const getTimeFromNow = (start: DateISODate, end: DateISODate) => {
  const _start = checkOrParse(start);
  const _end = checkOrParse(end);
  const current = new Date();
  const startsBeforeCurrent = isBefore(_start, current);
  const hasFinished = hasBookingFinished(_end);

  if (isBefore(_end, _start)) {
    throw new RangeError('End date cannot start before start date');
  }
  if (hasFinished) {
    return FROM_NOW.PAST;
  }
  if (startsBeforeCurrent && !hasFinished) {
    return FROM_NOW.ONGOING;
  }
  return FROM_NOW.FUTURE;
};

const changeAgendaMonth = (navigation: string, selectedDate: Date): Date => {
  let newDate = new Date();
  if (navigation === DATE_NAVIGATION.PREVIOUS) {
    newDate = subMonths(selectedDate, 1);
  } else if (navigation === DATE_NAVIGATION.NEXT) {
    newDate = addMonths(selectedDate, 1);
  }
  return sameMonth(newDate, new Date()) ? new Date() : startOfMonth(newDate);
};

const getReservedDaysFromAgendaItems = (agendaItems: AgendaItems) =>
  Object.entries(agendaItems)
    .filter(([, events]) => events?.length > 0)
    .map(([date]) => date);

export {
  FROM_NOW,
  sameMonth,
  startOfMonth,
  getTimeText,
  getTimeFromNow,
  changeAgendaMonth,
  getReservedDaysFromAgendaItems,
};
