import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateFloorPath } from '@engage-web/router/utils';
import { CircularProgressBar } from '@engage-web/components/base';
import { Floor, FloorData } from '@engage-shared/api/floors';
import { Building } from '@engage-shared/api/buildings';
import { FloorName, FloorWrapper } from './styled';

export type FloorProps = {
  floor: FloorData;
  locationState: {
    activeBuilding: Building;
    activeFloor: Floor;
  };
};

const FloorItem = ({ floor, locationState }: FloorProps) => {
  const navigate = useNavigate();
  const { activeFloor } = locationState;
  const { id, name, available_count: availableCount = 0, bookable_count: bookableCount } = floor;

  const setCurrentLocation = () => {
    navigate(generateFloorPath(`${id}`));
  };

  return (
    <FloorWrapper onClick={setCurrentLocation}>
      <FloorName $isActive={activeFloor?.id === id} data-testid="floor-list-item">
        {name}
      </FloorName>
      <CircularProgressBar
        maxValue={bookableCount || 100}
        value={availableCount ?? 0}
        text={`${bookableCount ?? 0}`}
      />
    </FloorWrapper>
  );
};

export default FloorItem;
