import { boxesIntersect, calculateSelectionBox } from '../DragSelection';
import { Space } from '@floorplan/api';
import { DragDomRect, DragMoveEvent } from '../../types';

export type NonOccupantPoint = DragDomRect & {
  space: Space;
};

export const getNonOccupantDomPoints = (nonOccupantPoints: Space[]): NonOccupantPoint[] =>
  nonOccupantPoints
    .filter(space => space.isDesk)
    .map(space => {
      const domNode = document.querySelector(`[data-space-id='${space.id}']`);
      const { left = 0, top = 0, width = 0, height = 0 } = domNode?.getBoundingClientRect() ?? {};
      return { left, top, width, height, space };
    });

export const getDragSelectedDesks = (
  dragSelectionData: DragMoveEvent,
  selectableDeskBoxes: NonOccupantPoint[],
): number[] => {
  const { startX, startY, endX, endY } = dragSelectionData;

  const selectionBox = calculateSelectionBox({
    startPoint: { x: startX, y: startY },
    endPoint: { x: endX, y: endY },
  });

  return selectableDeskBoxes.reduce<number[]>(
    (accumulator, box) =>
      boxesIntersect(box, selectionBox) ? [...accumulator, box.space.id] : accumulator,
    [],
  );
};
