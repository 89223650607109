import { KioskRemoteConfig } from '@engage-web/api/queries';
import { createContext, useContext } from 'react';

const noop = () => {
  /* empty */
};

type AvailableKiosksContextProps = {
  onSelectConfig: (config: KioskRemoteConfig) => Promise<void>;
};

// TODO: noop here seems strange... investigate
export const AvailableKiosksContext = createContext<AvailableKiosksContextProps>({
  onSelectConfig: noop as any,
});

export const useAvailableKioskContext = () => useContext(AvailableKiosksContext);
