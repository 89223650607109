import * as React from 'react';

import { Shape as ShapeType, Space } from '@floorplan/api';
import { Shape } from '../../../components';
import { getKey } from '../utils';
import { temporaryOccupantPointStyle, pointSizes } from '../styles';
import { ElementsProps, EventInfo, Layers } from '../../../types';
import { defaultFloorplanOptions } from '../../../constants';

interface TemporaryOccupantPointsProps extends ElementsProps {
  temporaryOccupantPoints: Space[];
  layers: Layers;
  reservedAlwaysEnabled?: boolean;
  startTime: string;
}
export const TemporaryOccupantPoints = ({
  temporaryOccupantPoints,
  scale,
  onShapeClicked,
  layers,
  reservedAlwaysEnabled = defaultFloorplanOptions.reservedAlwaysEnabled,
  startTime,
}: TemporaryOccupantPointsProps) => {
  const { showOccupants, all } = layers;

  const styleFunction = (occupantPoint: Space) =>
    temporaryOccupantPointStyle({
      space: occupantPoint,
      startTime,
    });

  const getPointSize = (occupantPoint: Space) => {
    if ((showOccupants && !all && occupantPoint.isReserved) || reservedAlwaysEnabled) {
      return pointSizes('important');
    }
    return pointSizes('unImportant');
  };

  const onOccupantPointSelect = (event: EventInfo) =>
    event.space.allowInteraction && onShapeClicked && onShapeClicked(event);

  return (
    <>
      {temporaryOccupantPoints.map((temporaryOccupantPoint, index) => (
        <Shape
          isPoint
          space={temporaryOccupantPoint}
          shape={temporaryOccupantPoint.shape as ShapeType}
          scale={scale}
          styleFunction={styleFunction}
          getPointSize={getPointSize}
          onShapeClicked={onOccupantPointSelect}
          key={getKey(temporaryOccupantPoint.id, index)}
        />
      ))}
    </>
  );
};
