import styled from 'styled-components';
import { SPACE_ASSETS_FILTER } from '@engage-web/constants';
import { Icon } from '@engage-web/components/base';

export const FilterSelectorWrapper = styled.div`
  padding: 5px 0;
  background-color: ${({ theme }) => theme.color4};
  border-radius: 5px;
  margin: 30px 0 0;
  display: flex;
  justify-content: space-evenly;
  position: relative;
`;

export const FilterSelectorBackdrop = styled.span<{
  $selected: 'spaceAttributes' | 'spaceTypes' | null;
}>`
  display: block;
  position: absolute;
  background-color: ${({ $selected }) => ($selected ? '#fff' : 'transparent')};
  height: 30px;
  width: 48%;
  border-radius: 4px;
  z-index: 1;
  top: 5px;
  left: 2%;
  transform: translateX(
    ${({ $selected }) => ($selected === SPACE_ASSETS_FILTER.ATTRIBUTE ? '100%' : '0')}
  );
  transition: all 0.5s;
`;

export const FilterSelectorButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex: 0 0 50%;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  position: relative;
  z-index: 2;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const AutoRotateIcon = styled(Icon)<{ $active: boolean }>`
  margin-right: 8px;
  transform: ${({ $active }) => ($active ? 'rotate(90deg)' : 'rotate(0)')};
  transition: transform 0.3s;
`;
