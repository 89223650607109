import {
  CheckInBookingResponse,
  BookingUpdateResponse,
  FetchBookingParams,
  UpdateBookingParams,
} from '@engage-shared/api/bookings/interfaces';
import { Api, getApiUrl } from '@engage-shared/api/instance';
import { AxiosResponse } from 'axios';

type UpdateBooking = (params: UpdateBookingParams) => Promise<BookingUpdateResponse>;
/**
 * Update a booking.
 * Not used at the moment. Code exists on client but not enabled.
 * TODO: add tests for other statuses when the code will be used.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | booking moved to auto check-in window |
 * | 403 | booking owned by another user |
 * | 422 | desk booked at the chosen time |
 * @param tenantId
 * @param spaceId
 * @param meetingId
 * @param reservation
 */
export const updateBooking: UpdateBooking = async ({
  tenantId,
  spaceId,
  meetingId,
  reservation,
}) => {
  const url = new URL(`${getApiUrl(tenantId)}/spaces/${spaceId}/book/${meetingId}`);
  await Api.put(url.toString(), {
    ...reservation,
  });
  return { ...reservation, spaceId, meetingId };
};

type CancelBooking = (params: FetchBookingParams) => Promise<AxiosResponse>;
/**
 * Remove a booking.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | Meeting room - meeting cancelled mid-booking |
 * | 403 | Meeting room - only the organizer may cancel |
 * | 404 | space not found, mismatched space/booking - fake meeting |
 * | 422 | Meeting room - cannot cancel completed meeting |
 * @param tenantId
 * @param spaceId
 * @param meetingId
 * @param checkOut
 */
export const cancelBooking: CancelBooking = async ({ tenantId, spaceId, meetingId }) => {
  const url = new URL(`${getApiUrl(tenantId)}/spaces/${spaceId}/book/${meetingId}`);
  return await Api.delete(url.toString());
};

type CheckInBooking = (params: FetchBookingParams) => Promise<CheckInBookingResponse>;
/**
 * Check in a booking.
 *
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | check in successful |
 * | 403 | check in failed - booking owned by another user |
 * | 422 | check in failed - Check-in not within the check-in window |
 * @param tenantId
 * @param spaceId
 * @param meetingId
 */
export const checkInBooking: CheckInBooking = async ({ tenantId, spaceId, meetingId }) => {
  const url = new URL(`${getApiUrl(tenantId)}/spaces/${spaceId}/check_in/${meetingId}`);
  const response = await Api.put<{ data: CheckInBookingResponse }>(url.toString());
  return response.data?.data;
};
