import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Nullable } from '@engage-shared/utils/types';
import { navigationActions } from '@engage-web/store';
import { useBookingModal } from '@engage-web/utils/hooks';
import { ConfirmationDialog } from '@engage-web/components/base';
import { useDialog } from '@engage-web/utils';
import { TeamBookingDetails } from '@engage-shared/api';

type UseTeamBookingCallbacks = () => {
  onSuccess: (data: Nullable<TeamBookingDetails>) => void;
  onError: (error: unknown) => void;
};

export const useTeamBookingCallbacks: UseTeamBookingCallbacks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close } = useBookingModal();
  const { openDialog } = useDialog();

  const onBookingLoadError = () => {
    close();
    dispatch(navigationActions.clearModal());
    openDialog(
      <ConfirmationDialog
        title={t('layout.booking.errorMessages.cancelledTitle')}
        message={t('layout.booking.errorMessages.cancelledMessage')}
        key="booking-load-error"
        hasCancelButton={false}
      />,
    );
  };

  return {
    onSuccess: data => {
      if (!data) {
        onBookingLoadError();
      }
    },
    onError: onBookingLoadError,
  };
};
