import Api from '@engage-web/components/floorplan/services/api';
import { getSpaceSVLivePresenceEventOccupancy } from '@engage-shared/utils';
import { SPACE_TYPE_IDS } from '@engage-web/components/floorplan/constants/SpaceTypes';
import { getTimeQueryString } from '@engage-shared/api';
import { SENSOR_STATUS } from '@engage-shared/constants';

const isSpaceOccupiedBySensor = space => space?.sensorStatus === SENSOR_STATUS.OCCUPIED;

const createOrPush = (obj, key, item) => {
  if (Array.isArray(obj[key])) {
    obj[key].push(item);
  } else {
    // eslint-disable-next-line no-param-reassign
    obj[key] = [item];
  }
};

export const getUpdatedSpaces = (prevSpaces, newSpaces) => {
  const updatedSpaces = prevSpaces && prevSpaces.length ? [...prevSpaces] : [];

  const updatedSpacesMap = updatedSpaces.map(s => s.id);
  newSpaces.forEach(space => {
    const spaceIndex = updatedSpacesMap.indexOf(space.id);
    if (spaceIndex !== -1) {
      updatedSpaces[spaceIndex] = {
        ...updatedSpaces[spaceIndex],
        ...space,
      };
    }
  });

  return updatedSpaces;
};

export const getRefreshedSpaces = (prevSpaces, newSpaces) => {
  const updatedSpaces = prevSpaces && prevSpaces.length ? [...prevSpaces] : [];

  const updatedSpacesMap = updatedSpaces.map(s => s.id);
  newSpaces.forEach(space => {
    const spaceIndex = updatedSpacesMap.indexOf(space.id);

    if (space.isDesk === undefined) {
      // eslint-disable-next-line no-param-reassign
      space.isDesk = space.shapes && space.shapes[0] && !space.shapes[0].isPolygon;
    }

    // Remove this part of code because getSpaceAvailabilityData Api is not returning correct data
    // Refreshing spaces after reservation is created
    // eslint-disable-next-line no-param-reassign
    // delete space.available;
    // eslint-disable-next-line no-param-reassign
    // delete space.bookable;

    if (spaceIndex !== -1) {
      updatedSpaces[spaceIndex] = {
        ...updatedSpaces[spaceIndex],
        ...space,
      };
    } else {
      updatedSpaces.push(space);
    }
  });

  return updatedSpaces;
};

const filterRooms = (filteredSpaces, space) => {
  if (!space.isDesk) {
    // Check if a space is a room
    if (space.shapes[0] && space.spaceTypeId !== SPACE_TYPE_IDS.ZONE) {
      createOrPush(filteredSpaces, 'rooms', space);
    } // Check if a room is occupied
    else if (space.occupantPoint && space.homeLocations) {
      createOrPush(filteredSpaces, 'occupantRoomPoints', space);
    }
  }
};

const filterDesks = (filteredSpaces, space) => {
  if (space.isDesk) {
    const nonOccupant = space.available && space.bookable;
    // Check if space is an occupant point (meaning a person is there, room or desk)
    const { isOccupied: isSpaceOccupiedByPresenceStatus } =
      getSpaceSVLivePresenceEventOccupancy(space);
    if (
      !nonOccupant ||
      // fist check SVLive data
      isSpaceOccupiedByPresenceStatus ||
      // second check sensor data
      isSpaceOccupiedBySensor(space)
    ) {
      createOrPush(filteredSpaces, 'occupantPoints', space);
    }
    // Check if space is a non Occupant point - available desk
    else if (nonOccupant) {
      createOrPush(filteredSpaces, 'nonOccupantPoints', space);
    }
  }
};

export const getFilteredSpaces = spaces => {
  const filteredSpaces = {
    zones: [],
    occupantPoints: [],
    nonOccupantPoints: [],
    rooms: [],
    occupantRoomPoints: [],
    special: {
      [SPACE_TYPE_IDS.LIFTS]: [],
      [SPACE_TYPE_IDS.TOILET_WASHROOM]: [],
      [SPACE_TYPE_IDS.FIRE_STAIRS]: [],
    },
  };

  if (!spaces || !spaces.length) {
    return filteredSpaces;
  }

  spaces.forEach(space => {
    switch (space.spaceTypeId) {
      case SPACE_TYPE_IDS.LIFTS:
      case SPACE_TYPE_IDS.TOILET_WASHROOM:
      case SPACE_TYPE_IDS.FIRE_STAIRS:
        createOrPush(filteredSpaces.special, space.spaceTypeId, space);
        break;

      case SPACE_TYPE_IDS.ZONE:
        createOrPush(filteredSpaces, 'zones', space);
        break;
      default:
        break;
    }
    const hasSpaceShape = space.shapes && space.shapes[0] && space.shapes[0].isPolygon;
    // this is intentional to fix API mishandling the isDesk attribute
    if (space.isDesk === undefined) {
      // eslint-disable-next-line no-param-reassign
      space.isDesk = !hasSpaceShape;
    }

    // Assign occupant point to shape, so we can deduce coordinates later
    if (space.occupantPoint) {
      // eslint-disable-next-line no-param-reassign
      space.shape = space.occupantPoint[0] ? space.occupantPoint[0] : space.occupantPoint;
      // set the original shape if a space is an occupant point
      // and has a space shape that is a desk (spaceType = 'Office Individual')
      // because it contains the correct coordinates and isPolygon props.
      if (hasSpaceShape && space.isDesk) {
        // eslint-disable-next-line no-param-reassign
        space.shape = space.shapes[0];
      }
    }

    filterRooms(filteredSpaces, space);
    filterDesks(filteredSpaces, space);
  });

  return filteredSpaces;
};

const TEAM_BOOKING_NAME = 'Team Booking';

export const filterTeamBookingSpaces = async ({
  spaces,
  startTime,
  endTime,
  teamBookingId,
  floorId,
}) => {
  const bookings = await Api.fetch({
    method: 'GET',
    url: `/floors/${floorId}/bookings${getTimeQueryString(startTime, endTime)}`,
  });

  const teamBookings = bookings.data?.filter(
    // some bookings don't have teamBooking attribute
    booking => booking.teamBooking?.id === teamBookingId,
  );
  const teamBokingSpaceIds = teamBookings.map(({ spaceId }) => spaceId);

  if (teamBookings) {
    const sharedValues = {
      presenceEvents: [],
      sensorStatus: null,
      lastStatusUpdateTime: null,
    };

    return {
      ...spaces,
      data: spaces.data
        .filter(space => teamBokingSpaceIds.includes(space.id))
        .map(space => {
          if (teamBookings.length) {
            const teamBooking = teamBookings.find(({ spaceId }, index) => {
              const matches = spaceId === space.id;
              // for every match remove associated item from team bookings array,
              // so that there are n - 1 team bookings for each match
              if (matches) teamBookings.splice(index, 1);
              return matches;
            });

            if (teamBooking) {
              const hasOccupant = teamBooking.reservee.displayName !== TEAM_BOOKING_NAME;
              const bookable = space.bookable;

              return {
                ...space,
                ...sharedValues,
                available: !hasOccupant,
                bookable: bookable && !hasOccupant,
                isReserved: hasOccupant,
                allowInteraction: bookable,
              };
            }
          }

          return {
            ...space,
            ...sharedValues,
            available: false,
            bookable: false,
            isReserved: false,
            allowInteraction: false,
          };
        }),
    };
  }

  return spaces;
};

export const disableSpaces = spaces =>
  spaces.map(space => ({
    ...space,
    presenceEvents: [],
    sensorStatus: null,
    lastStatusUpdateTime: null,
    available: false,
    bookable: false,
    isReserved: false,
    allowInteraction: false,
  }));
