import { getDayStartTime, getTimeValues, getUtcIsoString } from '@engage-shared/utils/dates';
import { ISODateString } from '@engage-shared/utils/types';
import { addDays, parseISO, subDays } from 'date-fns';

type GetBookingTimeRangeParams = {
  dateStart: Date;
  dateEnd: Date;
  showDaysSelector?: boolean;
  bookingIntervalSize: number;
  allDay: boolean;
  timeZone: string;
};

type GetBookingTimeRangeResult = {
  startTime: ISODateString;
  endTime: ISODateString | null;
};

type GetBookingTimeRange = (params: GetBookingTimeRangeParams) => GetBookingTimeRangeResult;

export const getBookingTimeRange: GetBookingTimeRange = ({
  dateStart,
  dateEnd,
  showDaysSelector = false,
  bookingIntervalSize,
  allDay,
  timeZone,
}) => {
  let startTimeForBookings = dateStart;
  let endTimeForBookings = dateEnd;
  if (showDaysSelector) {
    startTimeForBookings = getDayStartTime({
      date: startTimeForBookings || new Date(),
      timeZone,
    });
    endTimeForBookings = addDays(startTimeForBookings, 7);
  }

  const { startTime: bookingStartTime, endTime: bookingEndTime } = getTimeValues({
    allDay,
    dateStart: startTimeForBookings,
    dateEnd: endTimeForBookings,
    bookingIntervalSize,
    timeZone,
  });

  // Adjust start time to ensure that all bookings are returned.
  const adjustedStartTime = getUtcIsoString(subDays(parseISO(bookingStartTime || ''), 7));

  return { startTime: adjustedStartTime, endTime: bookingEndTime };
};
