import { MEDIA_TYPE, MediaTypeValue } from '@engage-shared/constants/mediaTypes';
import { Nullable, ChatLink } from '@engage-shared/utils/types';

export const getChatProvider = (chatLink?: ChatLink): Nullable<MediaTypeValue> => {
  const chatType = chatLink?.chatType;
  if (chatType === 'Teams') {
    return MEDIA_TYPE.TEAMS;
  }
  if (chatType === 'Slack') {
    return MEDIA_TYPE.SLACK;
  }
  if (chatLink && !chatType) {
    return MEDIA_TYPE.CHAT;
  }
  return null;
};

export const getChatURL = (chatLink?: ChatLink): Nullable<string> => {
  const chatURL = chatLink?.chatUrl;
  if (chatURL) {
    return encodeURI(chatURL);
  }
  return null;
};

export const collectAnalytics = (
  mediaType: MediaTypeValue,
  Analytics: { track: (arg0: string) => void },
): void => {
  switch (mediaType) {
    case MEDIA_TYPE.CALL:
      Analytics.track('EVT-049');
      break;
    case MEDIA_TYPE.CHAT:
    case MEDIA_TYPE.TEAMS:
    case MEDIA_TYPE.SLACK:
      Analytics.track('EVT-050a');
      break;
    case MEDIA_TYPE.EMAIL:
      Analytics.track('EVT-050');
      break;
    default:
      break;
  }
};
