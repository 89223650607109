import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from '@engage-web/components/base';
import { HEIGHT as MEDIA_LINKS_HEIGHT } from './MediaLinks/styled';
import { Wrapper } from './styled';
import DetailsSkeleton from './Details/DetailsSkeleton';

const PersonCardSkeleton = () => (
  <SkeletonTheme style={{ width: '100%' }}>
    <Wrapper>
      {/* Details */}
      <DetailsSkeleton />
      {/* Media Links */}
      <Skeleton height={MEDIA_LINKS_HEIGHT} />
    </Wrapper>
  </SkeletonTheme>
);

export default PersonCardSkeleton;
