export const TIME_TYPES = {
  MINUTE: 'minute',
  HOUR: 'hour',
  PERIOD: 'period',
};

/*
export const PICKER_DEFAULT_HEIGHT = 90;
export const PICKER_DEFAULT_WIDTH = 100;
export const PICKER_DEFAULT_ITEM_HEIGHT = 30;
*/
