import React, {
  MouseEventHandler,
  TouchEventHandler,
  useEffect,
  useRef,
  useState,
  WheelEventHandler,
} from 'react';
import { Wrapped } from './styled';
import { generateImagePoint, generateWebPoint, handleWheel, isInDragThreshold } from './helpers';
import { Point, Viewer } from 'openseadragon';
import { DragMoveEvent, PointXY, TouchMouseEvent } from '../../types';
import { isNativeApp } from '../../utils';

const DEFAULT_DRAG_THRESHOLD = 100;

export type DragSelectionProps = {
  viewer: Viewer;
  active: boolean;
  onClick: (point: PointXY) => void;
  onDragMove: (event: DragMoveEvent) => void;
  onDragComplete: (event: DragMoveEvent) => void;
  dragThreshold?: number;
};

export const DragSelection = ({
  onDragComplete,
  onDragMove,
  onClick,
  viewer,
  active,
  dragThreshold = DEFAULT_DRAG_THRESHOLD,
}: DragSelectionProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);

  const element = elementRef.current;
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);
  const startPoint = useRef<Point>();
  const endPoint = useRef<Point>();

  useEffect(() => {
    // disable gestures when drag to select is active
    viewer.gestureSettingsByDeviceType('mouse').dragToPan = !active;
    viewer.gestureSettingsByDeviceType('mouse').pinchToZoom = !active;
    viewer.gestureSettingsByDeviceType('touch').dragToPan = !active;
    viewer.gestureSettingsByDeviceType('touch').pinchToZoom = !active;
  }, [active, viewer]);

  const handleStartDrag = (event: TouchMouseEvent) => {
    if (!element || isDraggingRef.current || !viewer) {
      return;
    }

    const webPoint = generateWebPoint({ event, element });
    const imagePoint = generateImagePoint({ webPoint, viewer });

    setStartX(webPoint.x);
    setStartY(webPoint.y);
    setEndX(webPoint.x);
    setEndY(webPoint.y);
    isDraggingRef.current = true;
    setIsDragging(true);

    startPoint.current = imagePoint;
    endPoint.current = imagePoint;
  };

  const handleEndDrag = (event: TouchMouseEvent) => {
    if (!element || !isDraggingRef.current) {
      return;
    }

    const webPoint = generateWebPoint({ event, element });
    const imagePoint = generateImagePoint({ webPoint, viewer });

    setEndX(webPoint.x);
    setEndY(webPoint.y);
    endPoint.current = imagePoint;
    isDraggingRef.current = false;
    setIsDragging(false);

    // check for dragged coordinates, if the difference between them is in drag threshold
    // it means user clicked and not dragged
    if (isInDragThreshold(endPoint.current, startPoint.current!, dragThreshold)) {
      return;
    }

    onDragComplete({
      isDragging: false,
      startX,
      startY,
      endX: webPoint.x,
      endY: webPoint.y,
      startPoint: startPoint.current,
      endPoint: endPoint.current,
    });
  };

  const handleMoveDrag = (event: TouchMouseEvent) => {
    if (!element || !isDraggingRef.current) {
      return;
    }

    const webPoint = generateWebPoint({ event, element });
    const imagePoint = generateImagePoint({ webPoint, viewer });

    setEndX(webPoint.x);
    setEndY(webPoint.y);
    endPoint.current = imagePoint;

    // check for dragged coordinates, if the difference between them is in drag threshold
    // it means user clicked and not dragged
    if (isInDragThreshold(endPoint.current, startPoint.current!, dragThreshold)) {
      return;
    }

    onDragMove({
      isDragging: isDraggingRef.current,
      startX,
      startY,
      endX: webPoint.x,
      endY: webPoint.y,
      startPoint: startPoint.current,
      endPoint: endPoint.current,
    });
  };

  const onEndTouchDrag: TouchEventHandler = event => {
    handleEndDrag(event.changedTouches[0]);
  };

  const onStartTouchDrag: TouchEventHandler = event => {
    handleStartDrag(event.touches[0]);
  };

  const onTouchMoveDrag: TouchEventHandler = event => {
    handleMoveDrag(event.touches[0]);
  };

  const onWheel: WheelEventHandler = event => {
    if (viewer && !isDragging && element) {
      const currentZoom = viewer.viewport.getZoom(true) || 1.0;
      const zoomByFactor = 0.25;
      const direction = event.deltaY > 0 ? -1 : 1;
      const webPoint = generateWebPoint({ event, element });
      const viewportPoint = viewer.viewport.pointFromPixel(webPoint);
      const factor = Math.max(currentZoom + zoomByFactor * direction, viewer.viewport.getMinZoom());
      viewer.viewport.zoomTo(factor, viewportPoint);
    }

    event.preventDefault();
    event.stopPropagation();
    if (event.nativeEvent) {
      event.nativeEvent.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  const onClickSvg: MouseEventHandler = event => {
    if (!element) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    onClick({ x: startX, y: startY });
  };

  useEffect(() => {
    if (isNativeApp) {
      return;
    }
    // Page scrolling must be prevented while zooming floorplan, since React uses SynteticEvent's which doesnt allow preventing
    // we added a workaround with passive attribute where we can access native event
    // and cancel further propagation and disable page scroll
    // this could cause problems in future versions of React when logic of Syntetic events will be changed
    if (element?.addEventListener) {
      element.addEventListener('wheel', handleWheel, {
        passive: false,
      });
    }

    return () => {
      element?.removeEventListener('wheel', handleWheel);
    };
    // we are only interested in onMount and onUnmount stages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('touchend', onEndTouchDrag);

    return () => {
      // @ts-ignore
      document.removeEventListener('touchend', onEndTouchDrag);
    };
    // we are only interested in onMount and onUnmount stages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _xMin = Math.min(startX, endX);
  const _yMin = Math.min(startY, endY);
  const _xMax = Math.max(startX, endX);
  const _yMax = Math.max(startY, endY);

  const svgProps = {
    onTouchEnd: onEndTouchDrag,
    onTouchStart: onStartTouchDrag,
    onTouchMove: onTouchMoveDrag,
    onMouseUp: handleEndDrag,
    onMouseDown: handleStartDrag,
    onMouseMove: handleMoveDrag,
    onWheel: onWheel,
  };

  return (
    // do not apply styled-component here! It will cause ref problems and component will stop working
    <div
      ref={elementRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: active ? 'auto' : 'none',
      }}
    >
      {active && (
        <Wrapped>
          <svg
            {...svgProps}
            onClick={onClickSvg}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {isDragging && (
              <rect
                id="drag_area"
                x={_xMin}
                y={_yMin}
                width={_xMax - _xMin}
                height={_yMax - _yMin}
                style={{
                  fill: 'rgba(0,0,0,.2)',
                  stroke: 'grey',
                  strokeWidth: '2px',
                  opacity: 0.5,
                }}
              />
            )}
          </svg>
        </Wrapped>
      )}
    </div>
  );
};
