import { TenantId } from '@engage-shared/api/tenant/interfaces';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Marker } from '@engage-shared/api/spaces/interfaces/markers.type';
import { fetchMarkers } from '@engage-shared/api/spaces/fetchMarkers';

type UseMarkersParams = {
  tenantId: TenantId;
  containerTypeId?: number;
  options?: UseQueryOptions<Marker[]>;
};

type UseMarkers = (params: UseMarkersParams) => UseQueryResult<Marker[]>;

export const useMarkers: UseMarkers = ({ tenantId, containerTypeId, options = {} }) => {
  return useQuery({
    queryKey: ['markers', tenantId, containerTypeId],
    queryFn: () => fetchMarkers(containerTypeId),
    ...options,
  });
};
