import { useSelector } from 'react-redux';
import { QueryOptions, UseQueryResult } from 'react-query';
import { useBookingDetailsQuery, useBookingFetch as useBookingFetchHook } from '@engage-shared/api';
import { tenantSelectors } from '@engage-web/store';
import { useUser } from '@engage-web/api/queries/useUser';
import { BookingDetails } from '@engage-shared/api/bookings';
import { Nullable } from '@engage-shared/utils/types';

export const useBookingDetails = (
  spaceId: number,
  meetingId: number,
  options: QueryOptions = {},
): UseQueryResult<Nullable<BookingDetails>> => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const user = useUser();
  const userData = user?.data ?? {};

  const opts = {
    ...options,
    enabled: user.isSuccess && !!spaceId && !!meetingId,
  };

  return useBookingDetailsQuery({
    tenantId,
    spaceId,
    meetingId,
    //@ts-ignore
    userData,
    //@ts-ignore
    options: opts,
  });
};

export const useBookingFetch = ({ calculateCheckInStatus = false }) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  return useBookingFetchHook({ tenantId, calculateCheckInStatus });
};
