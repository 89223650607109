import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';

const useGenerateCurrentLocationPath = () => {
  const { floor } = useSelector(tenantSelectors.getCurrentLocation);

  if (floor) {
    return `/floor/${floor.id}`;
  }

  return null;
};

export default useGenerateCurrentLocationPath;
