import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigationSelectors } from '@engage-web/store';
import { useConfig } from '@engage-web/utils';
import { UserConfig, useSetUserConfig } from '@engage-shared/api';
import { hashString } from '@engage-shared/utils';

type UseShowLandingPage = (userConfig?: UserConfig) => boolean;

/**
 * Returns whether the landing page should be shown or not based
 * on tenant and user configs.
 * If there is a deeplink in the store – landing page will be hidden.
 */
export const useShowLandingPage: UseShowLandingPage = userConfig => {
  const config = useConfig();
  const { setUserConfig } = useSetUserConfig();
  const deepLinkPath = useSelector(navigationSelectors.getDeepLinkPath);
  const deepLinkSearch = useSelector(navigationSelectors.getDeepLinkQueryString);

  const hasDeepLink = !!(deepLinkPath || deepLinkSearch);
  const landingPageContentHash = hashString(config.welcomeTitle + config.welcomeMessage);

  // means title and message are empty
  const hashIsEmpty = landingPageContentHash.length === 0;

  const showLandingPage = userConfig?.landingPageContentHash
    ? landingPageContentHash !== userConfig.landingPageContentHash
    : true;

  useEffect(() => {
    let setConfigTimeout: ReturnType<typeof setTimeout>;
    if (showLandingPage && hasDeepLink) {
      // postpone setting landing page content cache
      // because if user will initially load page with the deep link there will be user_config fetch
      // then user config set (here) and automatic refetch in other places
      setConfigTimeout = setTimeout(() => {
        setUserConfig({ landingPageContentHash });
      }, 2000);
    }
    return () => {
      if (setConfigTimeout) {
        clearTimeout(setConfigTimeout);
      }
    };
  }, [hasDeepLink, showLandingPage, landingPageContentHash, setUserConfig]);

  return !hasDeepLink && showLandingPage && !hashIsEmpty;
};
