import axios from 'axios';
import { getLogger } from '@engage-shared/utils/logger';
import { createError } from '@engage-shared/api/instance/errors';

const AuthApi = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

AuthApi.interceptors.request.use(
  async config => {
    getLogger().log(config);
    return config;
  },
  async error => {
    getLogger().error(error);
  },
);

AuthApi.interceptors.response.use(undefined, async error => {
  const status = error?.response?.status;
  const apiError = createError(error, status);
  return Promise.reject(apiError);
});

export default AuthApi;
