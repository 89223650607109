import { useEffect, useState } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { TEAM_RESERVATION_DESKS_SELECTION_MODE } from '@floorplan/fes';

export type UseTeamReservationEventsParams = {
  selectedDesks: number[];
  updateSelectedDesks: (desks: number[]) => void;
};

/**
 * Hook that subscribes on FES events regarding team reservation.
 * @param selectedDesks
 * @param updateSelectedDesks
 */
export const useTeamReservationEvents = ({
  selectedDesks,
  updateSelectedDesks,
}: UseTeamReservationEventsParams) => {
  const [mode, setMode] = useState<string>(TEAM_RESERVATION_DESKS_SELECTION_MODE.DISABLED);
  const [teamSize, setTeamSize] = useState(0);

  const fes = useFESContext();

  // desks selection mode
  useEffect(() => {
    fes.on(FES_EVENTS.SET_TEAM_RESERVATION_DESKS_SELECTION_MODE, setMode);
    return () => {
      fes.off(FES_EVENTS.SET_TEAM_RESERVATION_DESKS_SELECTION_MODE, setMode);
    };
  }, [fes]);

  // team size
  useEffect(() => {
    fes.on(FES_EVENTS.SET_TEAM_RESERVATION_TEAM_SIZE, setTeamSize);
    return () => {
      fes.off(FES_EVENTS.SET_TEAM_RESERVATION_TEAM_SIZE, setTeamSize);
    };
  }, [fes]);

  // clear selection event
  useEffect(() => {
    const clearDesks = () => {
      updateSelectedDesks([]);
    };

    fes.on(FES_EVENTS.CLEAR_TEAM_RESERVATION_DESKS_SELECTION, clearDesks);
    return () => {
      fes.off(FES_EVENTS.CLEAR_TEAM_RESERVATION_DESKS_SELECTION, clearDesks);
    };
  }, [fes, updateSelectedDesks]);

  // handle selected desks changes from outside
  useEffect(() => {
    const handleSelectedDesksChange = (desks: number[]) => {
      // check if arrays of desks are not the same to avoid additional render
      if (
        selectedDesks.length !== desks.length ||
        desks.some(deskId => !selectedDesks.includes(deskId))
      ) {
        updateSelectedDesks(desks);
      }
    };

    fes.on(FES_EVENTS.TEAM_RESERVATION_SELECTED_DESKS_CHANGED, handleSelectedDesksChange);
    return () => {
      fes.off(FES_EVENTS.TEAM_RESERVATION_SELECTED_DESKS_CHANGED, handleSelectedDesksChange);
    };
  }, [fes, selectedDesks, updateSelectedDesks]);

  const updateSelectedDesksStore = (desks: number[]) => {
    fes.trigger(FES_EVENTS.TEAM_RESERVATION_ADD_DESKS, { desks });
  };

  return { updateSelectedDesksStore, mode, teamSize };
};
