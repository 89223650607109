import styled from 'styled-components';
import { Modal } from '@engage-web/components/base';
import { COMMON_STYLES } from '@engage-web/constants';

export const CommonModal = styled(Modal)`
  left: auto;
  top: auto;
  right: ${COMMON_STYLES.SPACE}px;
  bottom: ${COMMON_STYLES.SPACE}px;
  min-width: 500px;
  height: calc(100vh - ${2.5 * COMMON_STYLES.SPACE}px);
  transition: width, height, min-width, min-height, bottom, right 350ms ease-in-out;
  @media (max-width: 520px) {
    width: 100%;
    bottom: 0;
    height: auto;
    left: 0;
    top: ${1.3 * COMMON_STYLES.SPACE}px;
    min-width: inherit;
  }
  @media (min-width: 521px) and (max-width: 800px) {
    bottom: 20px;
    right: 20px;
    height: auto;
    top: ${1.3 * COMMON_STYLES.SPACE - 20}px;
  }
`;

export const AgendaModal = styled(CommonModal)`
  padding: 20px;
  @media (max-width: 420px) {
    padding: 0;
  }
`;
