import { DateISODate, ISODateString, Nullable } from '@engage-shared/utils/types';
import { UserData } from '@engage-shared/api/users/interfaces';
import { PersonData, PersonId } from '@engage-shared/api/people/interfaces';
import { ATTENDEE_STATUS } from '@engage-shared/constants/attendee';
import { QueryOptions } from 'react-query/types/core/types';
import { TeamBooking, UserId } from '@engage-shared/api/types';

export enum SpaceBookingType {
  DESK = 'desk',
  SPACE = 'space',
}

export enum BookingType {
  DESK = 'desk',
  SPACE = 'space',
  TEAM = 'team',
}

export enum BookingEventStatus {
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
}

type FetchParams = {
  tenantId: string;
  spaceId: number;
};

export type FetchBookingParams = FetchParams & {
  meetingId: number;
};

export interface Reservation {
  start: ISODateString;
  end: ISODateString;
  startTimeZone: string;
  endTimeZone: string;
  isAllDayBooking: boolean;
  summary?: string;
  reservee?: PersonId; // reserveeId
  organizer?: string; // organizer email
  newLocationId?: number;
}

export type CreateBookingParams = FetchParams & {
  reservation: Reservation;
};

export type UpdateBookingParams = CreateBookingParams & {
  meetingId: number;
};

export type FetchBookingsParams = FetchParams & {
  startTime: DateISODate;
  endTime: Nullable<DateISODate>;
};

export type CheckInBookingResponse = {
  checkIn: true;
  checkInTime: string;
};

export interface Attendee {
  id: PersonId;
  name: string;
  description: string;
  imageUrl: string;
  isOrganiser: boolean;
  status: ATTENDEE_STATUS;
}

interface BookingPerson {
  email: string;
  displayName: string;
}

export interface BuildingFloorLocation {
  buildingId: number;
  buildingName: string;
  floorId: number;
  floorName: string;
}

export interface Organizer extends BookingPerson {
  organizerId: UserId;
}

export interface Reservee extends BookingPerson {
  reserveeId: PersonId;
}

export type OccupantData = Nullable<UserData | PersonData>;

export type BookingUpdateResponse = Reservation & {
  spaceId: number;
  meetingId: number;
};

interface Booking {
  spaceId: number;
  meetingId: number;
  summary: Nullable<string>;
  bookingType: SpaceBookingType;
}

interface BookingCommon extends Booking {
  start: ISODateString;
  end: ISODateString;
  startTimeZone: string;
  endTimeZone: string;
  isAllDayBooking: boolean;
}

export interface BookingsListResponse extends BookingCommon {
  organizer: Organizer;
  reservee: Reservee;
  attendees: BookingPerson[];
  eventStatus: BookingEventStatus;
  teamBooking?: TeamBooking;
}

export interface CreateBookingResponse extends BookingCommon {
  meetingId: number;
  checkIn: Nullable<string>;
  reservee: BookingPerson;
  organizer: BookingPerson;
  organizerId: string;
  localStart: ISODateString;
  localEnd: ISODateString;
}

export interface FetchBookingResponse extends Booking {
  spaceName: string;
  isDesk: boolean;
  isCheckedIn: boolean;
  isCheckInAvailable: boolean;
  canUpdate: boolean;
  isAllDayBooking: boolean;
  startTime: ISODateString;
  startTimeZone: string;
  endTime: ISODateString;
  description: string;
  location: BuildingFloorLocation;
  attendees: Attendee[] | 'N/A';
  reservee: Reservee;
  isInInvalidBuilding: boolean;
  isInInvalidFloor: boolean;
  isInInvalidSpace: boolean;
  type?: 'calendar';
  preferredName: string;
  summary: string;
}

export type MeetingParams = {
  spaceId: number;
  meetingId: number;
};

type MeetingQueryParams = MeetingParams & {
  options?: QueryOptions<FetchBookingResponse>;
};

export type FetchBookingQuery = (params: MeetingQueryParams) => Promise<FetchBookingResponse>;
