import React, { CSSProperties, memo, ReactNode } from 'react';
import { LabelContainer } from './styled';

type LabelProps = {
  color?: string;
  style?: CSSProperties;
  children?: ReactNode;
};
export const Label = memo(({ color, style, children }: LabelProps) => (
  <LabelContainer style={{ ...style, color }}>{children}</LabelContainer>
));
