import { Api, getApiUrl } from '@engage-shared/api/instance';
import { Building, BuildingAvailability, FetchAvailabilityParams } from './interfaces';
import { parseBuildingData } from './utils';
import { FloorData } from '@engage-shared/api/floors/interfaces';
import { sortFloors } from '@engage-shared/api/floors/utils';

type FetchBuilding = (id: number) => Promise<Building>;
/**
 * Fetch building and map timeZone to building response.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | building found |
 * | 404 | building not found |
 * @param buildingId
 */
export const fetchBuilding: FetchBuilding = async buildingId => {
  const url = new URL(`${getApiUrl()}/buildings/${buildingId}`);
  const response = await Api.get(url.toString());
  return parseBuildingData(response.data?.data);
};

type FetchBuildingFloors = (id: number) => Promise<FloorData[]>;
/**
 * Retrieves floors for building sorted by sequence.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | floors found |
 * | 404 | building not found |
 * @param buildingId
 */
export const fetchBuildingFloors: FetchBuildingFloors = async buildingId => {
  const url = new URL(`${getApiUrl()}/buildings/${buildingId}/floors`);
  const response = await Api.get<{ data: FloorData[] }>(url.toString());
  const floors = response.data?.data || [];
  return floors.sort(sortFloors);
};

type FetchAvailability = (param: FetchAvailabilityParams) => Promise<BuildingAvailability>;
/**
 * Retrieves reservations within the building in a 15 minutes time block.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | building found |
 * | 404 | building not found |
 * @param tenantId
 * @param buildingId
 * @param startTime Start time of the 15 minutes time block in UTC (defaults to Now)
 * @param startTimeZone IANA time zone identifier https://www.iana.org/time-zones (Optional - defaults to UTC)
 */
export const fetchAvailability: FetchAvailability = async ({
  buildingId,
  startTime,
  startTimeZone,
}) => {
  const reservationUrl = `/buildings/${buildingId}/reservations_in_15_minutes`;
  const url = new URL(`${getApiUrl()}${reservationUrl}`);
  if (startTime) {
    url.searchParams.append('startTime', startTime);
  }
  if (startTimeZone) {
    url.searchParams.append('startTimeZone', startTimeZone);
  }
  const response = await Api.get<BuildingAvailability>(decodeURIComponent(url.toString()));
  return response.data;
};
