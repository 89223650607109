import styled from 'styled-components';

export const SelectedDatesText = styled.span`
  font-size: 12px;
  margin-right: 5px;
`;

export const SelectedDatesWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 15px;
  color: ${({ theme }) => theme.color9};
`;
