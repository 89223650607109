import { SpaceTypeOrAttribute } from '@engage-shared/api/spaces/interfaces';

const sortAttributesBySequenceAndId = (
  a: SpaceTypeOrAttribute,
  b: SpaceTypeOrAttribute,
): number => {
  if (a.sequence === b.sequence) {
    return a.id - b.id;
  }
  return a.sequence - b.sequence;
};

export { sortAttributesBySequenceAndId };
