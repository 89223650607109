export const eventsToPlanhatMap = {
  'EVT-014': 'sv-Concierge Menu selected',
  'EVT-029': 'sv-Floorplan-Desk selected',
  'EVT-030': 'sv-Floorplan-Space selected',
  'EVT-034': 'sv-Space Card',
  'EVT-035': 'sv-Space Card-Duration change',
  'EVT-037': 'sv-Space Card-Reservation successful',
  'EVT-038': 'sv-Space Card-Unsuccessful reservation',
  'EVT-041': 'sv-Desk Card',
  'EVT-042': 'sv-Desk Card-Duration change',
  'EVT-044': 'sv-Desk Card-Reservation successful',
  'EVT-045': 'sv-Desk Card-Unsuccessful reservation',
  'EVT-048': 'sv-Person Card',
  'EVT-049': 'sv-Person Card-Phone button',
  'EVT-050': 'sv-Person Card-Email button',
  'EVT-054': 'sv-Person Locations-Location Selected',
  'EVT-062': 'sv-Location Selector-Floor',
  'EVT-077': 'sv-My Bookings',
  'EVT-078': 'sv-My Bookings-Agenda item selected',
  'EVT-079': 'sv-My Bookings-Location selected',
  'EVT-080': 'sv-My Bookings-Delete',
  'EVT-087': 'sv-QR Scanner',
  'EVT-091': 'sv-QR Scanner-Checks Success',
  'EVT-092': 'sv-QR Scanner-Checks Unsuccessful',
  'EVT-093': 'sv-QR Scanner-Desks Booking',
};

export const mapSegmentEventToPlanhat = event => {
  if (!eventsToPlanhatMap[event]) {
    return event;
  }
  return eventsToPlanhatMap[event];
};
