import React from 'react';
import { ViewableAvatar, Icon } from '@engage-web/components/base';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Nullable } from '@engage-shared/utils/types';
import LocationStatusLabel from '@engage-web/components/base/LocationStatusLabel/LocationStatusLabel';
import { SensorStatusIconWrapper, AvatarWrapper } from './styled';
import { PresenceStatus, SENSOR_STATUS } from '@engage-shared/constants';
import {
  getPresenceStatusIcon,
  getSensorStatusIcon,
  IconProps,
  localizePersonSVLivePresence,
} from '@engage-shared/utils';

type PersonAvatarProps = {
  personName: string;
  sensorStatus: Nullable<SENSOR_STATUS>;
  presenceStatus: Nullable<PresenceStatus>;
  locationStatus?: string;
  imageSource?: string | null;
};

const PersonAvatar = ({
  personName,
  sensorStatus,
  presenceStatus,
  locationStatus = '',
  imageSource = null,
}: PersonAvatarProps) => {
  const { t } = useTranslation();
  // @ts-ignore
  const { color1, color6 } = useTheme();

  const hasSVLiveData = !!presenceStatus;

  // if user doesn't have SVLive data, fallback to primary location handler
  const labelText = hasSVLiveData
    ? localizePersonSVLivePresence({ t, status: presenceStatus })
    : locationStatus;

  const renderIcon = (props: IconProps) => <Icon {...props} />;

  const renderStatusIcon = () => {
    // if user doesn't have SVLive data, fallback to sensors status handler
    const icon = hasSVLiveData
      ? getPresenceStatusIcon(presenceStatus, renderIcon)
      : getSensorStatusIcon(sensorStatus as SENSOR_STATUS, renderIcon);

    if (!icon) {
      return null;
    }

    return (
      <SensorStatusIconWrapper
        aria-label={locationStatus}
        data-testid="presence-sensor-status-icon"
      >
        {icon}
      </SensorStatusIconWrapper>
    );
  };

  const renderLocationStatusLabel = () => {
    if (!labelText) {
      return null;
    }

    return <LocationStatusLabel backgroundColor={color1} color={color6} text={labelText} />;
  };

  return (
    <AvatarWrapper>
      <ViewableAvatar
        source={imageSource}
        name={personName}
        size="giant"
        alt={t('accessibilityLabels.personImage')}
      />
      {renderStatusIcon()}
      {renderLocationStatusLabel()}
    </AvatarWrapper>
  );
};

export default PersonAvatar;
