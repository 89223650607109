import { useEffect } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { resetRotation } from '../utils';

export const useResetOrientation = () => {
  const fes = useFESContext();

  useEffect(() => {
    const handleResetOrientation = () => {
      const viewport = fes.getViewerViewport();
      if (viewport) {
        resetRotation(viewport);
      }
    };
    fes.on(FES_EVENTS.RESET_ORIENTATION, handleResetOrientation);
    return () => {
      fes.off(FES_EVENTS.RESET_ORIENTATION, handleResetOrientation);
    };
  }, [fes]);
};
