import { getUserFloorLocation } from '@engage-shared/utils';
import { UserLocation } from '@engage-shared/utils/location/interfaces';
import { useCallback } from 'react';

interface IExistingFloorLocation {
  building: {
    id?: number;
    name?: string;
  };
  floor: {
    id?: number;
    name?: string;
  };
}

const errorMessages = {
  INVALID_FLOOR: 'router.location.invalidFloor',
};

type CheckLocationResult = [error: string | null, userFloorLocation: UserLocation | null];
/**
 * Returns floor location.
 * If floor not found, returns error message that is displayed on UI.
 */
export const useFloorCheck = () => {
  const checkLocation = async (floorId: string | undefined): Promise<CheckLocationResult> => {
    if (!floorId) {
      return [errorMessages.INVALID_FLOOR, null];
    }

    const floorLocation = await getUserFloorLocation(parseInt(floorId, 10));
    if (floorLocation) {
      return [null, floorLocation];
    }
    return [errorMessages.INVALID_FLOOR, null];
  };

  const checkLocationQuery = useCallback(checkLocation, []);

  const sameLocation = (
    floorId: string | undefined,
    existingLocation: IExistingFloorLocation,
  ): boolean => floorId === `${existingLocation?.floor.id}`;

  return { checkLocationQuery, sameLocation };
};
