export type MediaIconName = 'phone' | 'email' | 'chat' | 'slack' | 'chat-box';
export type MediaTypeValue = {
  value: string;
  iconName: MediaIconName;
  error: string;
};
export type MediaTypes = 'CALL' | 'EMAIL' | 'TEAMS' | 'SLACK' | 'CHAT';

export const MEDIA_TYPE: Record<MediaTypes, MediaTypeValue> = {
  CALL: {
    value: 'call',
    iconName: 'phone',
    error: 'components.mediaLink.phoneErrorMessage',
  },
  EMAIL: {
    value: 'email',
    iconName: 'email',
    error: 'components.mediaLink.cannotSendEmails',
  },
  TEAMS: {
    value: 'teams',
    iconName: 'chat',
    error: 'components.mediaLink.cannotStartChat',
  },
  SLACK: {
    value: 'slack',
    iconName: 'slack',
    error: 'components.mediaLink.cannotStartChat',
  },
  CHAT: {
    value: 'chat',
    iconName: 'chat-box',
    error: 'components.mediaLink.cannotStartChat',
  },
};
