import styled from 'styled-components';

export const StyledSearchInput = styled.input`
  background-color: ${({ theme }) => theme.color4};
  color: ${({ theme }) => theme.typo2};
  border: none;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  padding: 0 29px;
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.typo2};
    opacity: 0.7;
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.typo2};
    opacity: 0.7;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  margin: 0 33px;
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`;

export const ClearIconWrapper = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
