import React, { MouseEvent } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import AvatarImage, { AvatarImageProps } from '../Avatar/AvatarImage';
import { Theme } from '@engage-shared/theme';

const WrapperButton = styled.button`
  cursor: pointer;
  padding: 0;
`;

type SelectableAvatarProps = AvatarImageProps & {
  isSelected: boolean;
  onClick?: () => void;
  a11LabelSuffix?: string;
};

const SELECTED_COLOR = 'rgba(0, 2, 154, 0.15)';

const SelectableAvatar = ({
  onClick,
  isSelected,
  icon,
  iconStyle,
  style,
  a11LabelSuffix,
  ...rest
}: SelectableAvatarProps) => {
  const { t } = useTranslation();
  const { color2 } = useTheme() as Theme;

  const a11Label = t(
    isSelected ? 'accessibilityLabels.unselectItem' : 'accessibilityLabels.selectItem',
    {
      item: a11LabelSuffix,
    },
  );

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <WrapperButton onClick={handleClick} aria-label={a11Label}>
      <AvatarImage
        {...rest}
        icon={isSelected ? 'tick' : icon}
        iconStyle={isSelected ? { color: color2 } : iconStyle}
        style={isSelected ? { backgroundColor: SELECTED_COLOR } : style}
      />
    </WrapperButton>
  );
};

export default SelectableAvatar;
