import { useCallback } from 'react';
import { getUtcIsoString, roundTimeToInterval } from '@engage-shared/utils/dates';
import { RoundingDirection } from '@engage-shared/constants/roundingDirection';
import { HasBuildingLimitPer15Minutes } from '../interfaces';
import { useBuildingFetch } from './useBuilding';
import { useAvailabilityFetch } from '@engage-shared/api/buildings/queries/useAvailability';

type UseBuildingReservation = () => {
  hasBuildingLimitPer15Minutes: HasBuildingLimitPer15Minutes;
};

export const useBuildingReservationLimit: UseBuildingReservation = () => {
  const { fetchBuildingQuery } = useBuildingFetch();
  const { fetchAvailabilityQuery } = useAvailabilityFetch();

  const query: HasBuildingLimitPer15Minutes = async ({ buildingId, dateStart }) => {
    if (!buildingId) return false;

    try {
      const building = await fetchBuildingQuery(buildingId);
      let allowedReservations = building?.allowedReservationPer15Minutes;

      // if value not available for building get it from availability
      if (typeof allowedReservations !== 'number') {
        const startTime = getUtcIsoString(
          dateStart || roundTimeToInterval(new Date(), 15, RoundingDirection.UP),
        );
        const availability = await fetchAvailabilityQuery({
          buildingId,
          startTime,
        });
        allowedReservations = availability?.allowedReservations;
      }
      return !!allowedReservations && allowedReservations > 0;
    } catch (_error) {
      return false;
    }
  };

  const hasBuildingLimitPer15Minutes = useCallback(query, [
    fetchAvailabilityQuery,
    fetchBuildingQuery,
  ]);

  return { hasBuildingLimitPer15Minutes };
};
