import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { floorplanActions } from '@engage-web/store';

/**
 * Focuses space on a floor plan view.
 * @param spaceId Space id or false if space shouldn't be focused.
 */
export function useFocusSpace(spaceId?: string | number | false) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (spaceId) {
      dispatch(floorplanActions.setFocusedSpace({ id: Number(spaceId) }));
    }
  }, [dispatch, spaceId]);
}
