import { createSelector, createSlice, nanoid } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { TOAST_STYLES } from '@engage-web/constants';

// time to leave - how long does notification stay on screen
// 0 will make notification stay until manually closed
const TTL = 5 * 1000;

const initialState = [];

const getEmptyNotification = ({
  type = toast.TYPE.INFO,
  message = '',
  ttl = TTL,
  style = TOAST_STYLES.RIGHT_BOX,
} = {}) => ({
  type,
  message,
  ttl,
  style,
});

const inAppNotifications = createSlice({
  name: 'inAppNotifications',
  initialState,
  reducers: {
    addNotification(state, action) {
      state.push({
        ...getEmptyNotification(),
        ...action.payload,
        id: nanoid(),
      });
      return state;
    },
    removeNotification(state, action) {
      const index = state.findIndex(n => n.id === action.payload);
      if (index !== -1) state.splice(index, 1);
      return state;
    },
    addInfoNotification(state, action) {
      state.push({
        ...getEmptyNotification(),
        ...action.payload,
        type: toast.TYPE.INFO,
        id: nanoid(),
      });
      return state;
    },
    addDarkNotification(state, action) {
      state.push({
        ...getEmptyNotification(),
        ...action.payload,
        type: toast.TYPE.DARK,
        id: nanoid(),
      });
      return state;
    },
    addWarningNotification(state, action) {
      state.push({
        ...getEmptyNotification(),
        ...action.payload,
        type: toast.TYPE.WARNING,
        id: nanoid(),
      });
      return state;
    },
    addErrorNotification(state, action) {
      state.push({
        ...getEmptyNotification(),
        ...action.payload,
        type: toast.TYPE.ERROR,
        id: nanoid(),
      });
      return state;
    },
    addSuccessNotification(state, action) {
      state.push({
        ...getEmptyNotification(),
        ...action.payload,
        type: toast.TYPE.SUCCESS,
        id: nanoid(),
      });
      return state;
    },
    clear() {
      return [...initialState];
    },
  },
});

const getInAppNotifications = state => state.inAppNotifications;
const getLastNotification = notifications => {
  if (notifications.length === 0) return null;
  return notifications[notifications.length - 1];
};

export const inAppNotificationsSelectors = {
  getInAppNotifications,
  getInAppNotification: createSelector(getInAppNotifications, getLastNotification),
};

export default inAppNotifications;
