import React from 'react';
import { createTransformSearchResponse, getSearchData } from '../util/searchData';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { filtersSelectors, tenantSelectors } from '@engage-web/store';
import { useSearchQuery } from '@engage-shared/api';
import { RestrictTo, useReservationFilters } from '@engage-web/utils';
import { globalSearchSelectors } from '@engage-web/store/globalSearch';
import { RESTRICT_TO_VALUES } from '@engage-web/constants';
import { useUser } from '@engage-web/api/queries/useUser';
import { useRestrictTo } from '@engage-web/utils/hooks';
import { API_TIMEOUT } from '@engage-shared/constants';

export const SEARCH_QUERY_KEY = 'search';

interface UseSearchParams {
  showUserFirst?: boolean;
  restrictTo: RestrictTo;
  disableSearchString?: boolean;
  enabled?: boolean;
}

export const useSearch = ({
  showUserFirst = false,
  restrictTo,
  disableSearchString = false,
  enabled = true,
}: UseSearchParams) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const { capacity, spaceTypes, spaceAttributes } = useSelector(filtersSelectors.getFilter);
  const { fireWarden: isFireWarden, firstAidOfficer: isFirstAidOfficer } = useSelector(
    filtersSelectors.getPersonFilters,
  );

  const { startTime, endTime, roomsStartTime, roomsEndTime, filterDates } = useReservationFilters(
    restrictTo === RESTRICT_TO_VALUES.DESK,
  );

  const { data: userData } = useUser();
  const user = userData;

  const searchString = useSelector(globalSearchSelectors.getGlobalSearchValue);

  let isRefetchEnabled = enabled;
  if (showUserFirst) {
    // wait for the user data to exist and after that transform the response
    isRefetchEnabled = !!user?.id;
  }

  // TODO: Removed pageParam
  const fetchData = getSearchData({
    tenantId,
    floorId,
    isFirstAidOfficer,
    isFireWarden,
    startTime,
    endTime,
    roomsStartTime,
    roomsEndTime,
    filterDates,
    capacity,
    restrictTo,
    searchString: disableSearchString ? '' : searchString,
    spaceTypes,
    spaceAttributes,
  });

  const queryKey: any[] = [
    floorId,
    isFirstAidOfficer,
    isFireWarden,
    startTime,
    endTime,
    capacity,
    restrictTo,
    filterDates,
    searchString,
    spaceTypes,
    spaceAttributes,
  ];

  const result = useSearchQuery(
    {
      queryKey,
      isRefetchEnabled,
      // @ts-ignore
      fetchData,
      timeout: API_TIMEOUT,
    },
    createTransformSearchResponse({
      showUserFirst,
      searchString,
      user,
      filterDates,
      restrictTo,
    }),
  );

  return { result, queryKey };
};

export const useRefreshSearch = () => {
  const queryClient = useQueryClient();
  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const restrictTo = useRestrictTo();
  const { startTime, endTime, filterDates } = useReservationFilters(
    restrictTo === RESTRICT_TO_VALUES.DESK,
  );

  const invalidateQuery = () =>
    queryClient.invalidateQueries([
      SEARCH_QUERY_KEY,
      floorId,
      startTime,
      endTime,
      restrictTo,
      filterDates,
    ]);

  const refresh = React.useCallback(invalidateQuery, [
    endTime,
    filterDates,
    floorId,
    queryClient,
    restrictTo,
    startTime,
  ]);

  return { refresh };
};
