import { useQuery, useQueryClient } from 'react-query';
import { BOOKING_DETAILS_QUERY_KEY } from '../constants';
import { ParsedUserData } from '@engage-shared/api/users/interfaces';
import { UseQueryOptions, UseQueryResult } from 'react-query/types';
import { fetchBookingDetails } from '@engage-shared/api/bookings/fetchBookingDetails';
import { BookingDetails, FetchBookingParams } from '@engage-shared/api/bookings/interfaces';
import { useBuildingFetch } from '@engage-shared/api/buildings';
import { Nullable } from '@engage-shared/utils/types';

type UseBookingDetailsParams = FetchBookingParams & {
  userData: ParsedUserData;
  options?: UseQueryOptions<Nullable<BookingDetails>>;
};
type UseBookingDetailsQuery = (
  params: UseBookingDetailsParams,
) => UseQueryResult<Nullable<BookingDetails>>;

export const useBookingDetailsQuery: UseBookingDetailsQuery = ({
  tenantId,
  spaceId,
  meetingId,
  userData,
  options = {},
}) => {
  const queryClient = useQueryClient();
  const { fetchBuildingQuery } = useBuildingFetch();

  return useQuery<Nullable<BookingDetails>>({
    queryKey: [BOOKING_DETAILS_QUERY_KEY, `${spaceId}`, `${meetingId}`],
    queryFn: () =>
      fetchBookingDetails({
        queryClient,
        tenantId,
        meetingId,
        spaceId,
        userData,
        fetchBuildingQuery,
      }),
    ...options,
  });
};
