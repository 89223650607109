import { useEffect } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { getSpaceForID, panToShape, panToShapeCollection } from '../utils';

export const useShowSpace = ({ removeShapeSelected, setSelectedSpace, spaces }: any) => {
  const fes = useFESContext();

  useEffect(() => {
    const handleShowSpace = (obj: any) => {
      const spaceId = obj?.id; // can be an array of ids
      const zoom = obj?.zoom;
      const select = obj?.select;

      const isCollection = spaceId instanceof Array;

      let spaceList;
      if (isCollection) {
        spaceList = spaceId.map(id => getSpaceForID(spaces, id)).filter(s => !!s);
      } else {
        spaceList = getSpaceForID(spaces, spaceId);
      }

      const viewport = fes.getViewerViewport();

      if (spaceList && viewport) {
        if (isCollection) {
          // @ts-ignore
          panToShapeCollection(viewport, spaceList, zoom, false);
        } else {
          // @ts-ignore
          panToShape({ viewport, space: spaceList, zoom });
        }

        if (select) {
          setSelectedSpace(spaceList);
        }
      }
    };
    const handleClearShowSpace = () => {
      if (fes.getViewer()?.getViewer()) {
        removeShapeSelected();
      }
    };

    fes.on(FES_EVENTS.SHOW_SPACE, handleShowSpace);
    fes.on(FES_EVENTS.CLEAR_SHOW_SPACE, handleClearShowSpace);
    return () => {
      fes.off(FES_EVENTS.SHOW_SPACE, handleShowSpace);
      fes.off(FES_EVENTS.CLEAR_SHOW_SPACE, handleClearShowSpace);
    };
  }, [fes, removeShapeSelected, setSelectedSpace, spaces]);
};
