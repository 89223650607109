export type PersonFilters = Record<string, boolean>;
/**
 * Calculation of applied person filters number
 * @param filters Object
 * @return {number} number of applied filters
 */
export const countAppliedPersonFilters = (filters: PersonFilters): number => {
  let appliedFiltersCount = 0;

  const { fireWarden, firstAidOfficer } = filters;

  if (fireWarden) {
    appliedFiltersCount++;
  }
  if (firstAidOfficer) {
    appliedFiltersCount++;
  }

  return appliedFiltersCount;
};
