import React, { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ThemeColors } from '@engage-shared/theme';

import { Icon } from '@engage-web/components/base';
import { FocusId } from '@engage-web/utils/hooks';
import { testProps } from '@engage-web/utils';
import { DurationSelectorWrapper, NumberInput, SelectorButton } from './styled';

type DurationInputProps = Omit<HTMLProps<HTMLInputElement>, 'onChange'> & {
  max: number;
  value: number;
  selectorType: 'm' | 'h';
  onChange: (value: number) => void;
};

export function DurationInput({
  min = 0,
  step = 1,
  max,
  onChange,
  value,
  selectorType,
}: DurationInputProps) {
  const { t } = useTranslation();
  const { gray5 } = useTheme() as ThemeColors;
  const upButtonId = selectorType === 'h' ? FocusId.DurationSelectorHourUpButton : undefined;

  const handleIncreaseClick = () => {
    const isMax = value === max;
    // if reached max value – reset to min (creates loop effect)
    onChange(isMax ? +min : value + +step);
  };

  const handleDecreaseClick = () => {
    const isMin = value === min;
    // if reached max value – reset to min (creates loop effect)
    onChange(isMin ? max : value - +step);
  };

  return (
    <DurationSelectorWrapper data-testid="DurationSelectorWrapper">
      <SelectorButton
        id={upButtonId}
        onClick={handleIncreaseClick}
        {...testProps(t, `accessibilityLabels.durationSelector_${selectorType}_upward`).props}
      >
        <Icon name="arrow-upward" size={6} style={{ color: gray5 }} />
      </SelectorButton>
      <NumberInput
        type="number"
        value={value.toString().padStart(2, '0')}
        min={min}
        max={max}
        step={step}
        readOnly
      />
      <SelectorButton
        onClick={handleDecreaseClick}
        {...testProps(t, `accessibilityLabels.durationSelector_${selectorType}_downward`).props}
      >
        <Icon name="arrow-downward" size={6} style={{ color: gray5 }} />
      </SelectorButton>
    </DurationSelectorWrapper>
  );
}
