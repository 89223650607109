import styled from 'styled-components';

export const IconWrapper = styled.g<{ fill: string }>`
  path {
    fill: ${props => props.fill}; // icon comes colored from serraview
  }

  circle {
    transform: translate(-16px, -16px);
  }
`;
