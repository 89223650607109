import React from 'react';
import { CoverVideo, Icon, LightHeader } from '@engage-web/components/base';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useConfig, useSetPageTitle } from '@engage-web/utils/hooks';
import { ROOT_PATH } from '@engage-web/constants';
import { useAppLocation } from '@engage-web/router/hooks';
import { isElectron } from '@engage-web/utils/electron';
import { Button, ButtonText, Content, Message, Title, Wrapper } from './styled';
import { useSetUserConfig } from '@engage-shared/api';
import { hashString } from '@engage-shared/utils';

const LandingPage = () => {
  const configs = useConfig();
  const { welcomeTitle, welcomeMessage, coverImage } = configs;
  const { t } = useTranslation();
  const { search } = useAppLocation();
  const navigate = useNavigate();
  const { setUserConfig } = useSetUserConfig({
    onSettled: () => {
      navigate({ pathname: ROOT_PATH, search });
    },
  });

  // enable kiosk mode here
  const isKiosk = isElectron();

  const handleButtonClick = () => {
    setUserConfig({
      landingPageContentHash: hashString(welcomeTitle + welcomeMessage),
    });
  };

  useSetPageTitle(t('documentTitles.landingPage'));

  return (
    <Wrapper bgImage={coverImage} data-testid="landing-page">
      <CoverVideo coverImage={coverImage} />
      <LightHeader />
      <Content isKiosk={isKiosk}>
        <Title>{welcomeTitle}</Title>
        <Message>{welcomeMessage}</Message>
        <Button onClick={handleButtonClick}>
          <ButtonText>{t('scenes.landing.getStarted')}</ButtonText>
          <Icon shouldRotateOnRtl name="chevron-right" size={14} />
        </Button>
      </Content>
    </Wrapper>
  );
};

export default LandingPage;
