import * as React from 'react';

import { Space } from '@floorplan/api';
import { PersonMarker } from './PersonMarker';
import { SpaceMarker } from './SpaceMarker';

interface MarkerProps {
  space: Space;
  person?: any;
}
export const Marker = ({ space, person }: MarkerProps) => {
  if (person) {
    return <PersonMarker space={space} person={person} />;
  }
  return <SpaceMarker space={space} />;
};
