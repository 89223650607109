import { useEffect } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { useFloorDrawableMarkers } from '@engage-shared/api';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';

export const useDrawableMarkers = ({ listenersInitialized }: any) => {
  const fes = useFESContext();
  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const tenantId = useSelector(tenantSelectors.getTenantId);

  const drawableMarkers = useFloorDrawableMarkers({ tenantId, floorId });

  useEffect(() => {
    if (Array.isArray(drawableMarkers) && listenersInitialized) {
      fes.trigger(FES_EVENTS.SET_FLOOR_MARKERS, drawableMarkers);
    }
  }, [drawableMarkers, fes, listenersInitialized]);
};
