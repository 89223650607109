import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '@engage-shared/utils';
import { tenantActions, tenantSelectors } from '@engage-web/store';
import { useQuery, useQueryClient } from 'react-query';
import { ParsedUserData } from '@engage-shared/api/users';
import { USER_CACHE_TIME, USER_QUERY_KEY, USER_STALE_TIME } from '@engage-web/constants';
import { UserId } from '@engage-shared/api/types';

type UseUserParams = {
  enabled?: boolean;
};

export const useUser = ({ enabled = true }: UseUserParams = {}) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const userId = useSelector(tenantSelectors.getUserId);

  return useQuery<ParsedUserData | null, Error, ParsedUserData>(
    userId,
    () => getUserData({ tenantId, userId }),
    {
      enabled: enabled && !!tenantId && !!userId,
      cacheTime: USER_CACHE_TIME,
      staleTime: USER_STALE_TIME,
    },
  );
};

export const useFetchUser = () => {
  const queryClient = useQueryClient();
  const tenantId = useSelector(tenantSelectors.getTenantId);

  const query = ({ userId }: { userId: number }) =>
    queryClient.fetchQuery<ParsedUserData | null>(
      [USER_QUERY_KEY, `${userId}`],
      () => getUserData({ tenantId, userId }),
      { cacheTime: USER_CACHE_TIME, staleTime: USER_STALE_TIME },
    );

  const fetchUserQuery = useCallback(query, [queryClient, tenantId]);

  return { fetchUserQuery };
};

export const useUserLocation = () => {
  const dispatch = useDispatch();
  const { fetchUserQuery } = useFetchUser();
  const setUserLocation = React.useCallback(
    async ({ userId }: { userId: UserId }) => {
      const userData = await fetchUserQuery({ userId });
      // @ts-ignore
      const { userLocation } = userData;
      if (userLocation) {
        dispatch(tenantActions.setCurrentLocation(userLocation));
      }
    },
    [dispatch, fetchUserQuery],
  );
  return { setUserLocation };
};
