import { useModal } from '@engage-web/utils/hooks/useModal';
import { useDispatch } from 'react-redux';
import { navigationActions } from '@engage-web/store';
import { MODALS } from '@engage-web/constants';

export function useImageViewer() {
  const { content, openModal } = useModal();
  const dispatch = useDispatch();

  const openImageViewer = _content => {
    openModal(MODALS.IMAGE_VIEWER, _content);
  };

  const closeImageViewer = event => {
    event.stopPropagation();
    dispatch(navigationActions.clearModal());
  };

  return {
    content,
    closeImageViewer,
    openImageViewer,
  };
}
