export enum ACKNOWLEDGEMENT {
  NEVER = 3607,
  DAILY = 3608,
  ONCHANGE = 3609,
}

export enum ACKNOWLEDGEMENT_URL {
  NONE = 3609,
}

export enum ACKNOWLEDGEMENT_MESSAGE {
  NONE = 3608,
}
