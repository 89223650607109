import * as React from 'react';

import { CommonMarker, MARKER } from './CommonMarker';
import { Space } from '@floorplan/api';
import { ThemeManager } from '../../../theme';

const IMAGE_SIZE = 2 * MARKER.RADIUS;
const IMAGE_URL_PATTERN_ID = `imageUrl`;

const base64DecodeUnicode = (base64Data: string) => {
  const buffer = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
  const blob = new Blob([buffer], { type: 'image/jpeg' });
  return URL.createObjectURL(blob);
};

interface PersonMarkerProps {
  space: Space;
  person: any;
}
export const PersonMarker = ({ space, person }: PersonMarkerProps) => {
  const { color9, typo2 } = ThemeManager.getGlobalTheme();

  let imageUrl = person?.imageUrl;
  if (person?.imageData) {
    imageUrl = base64DecodeUnicode(person?.imageData);
  }

  const displayName = person?.displayName;

  const textFillColor = person?.textColor || typo2;
  let circleFill = person?.backgroundColor || color9;
  if (imageUrl) {
    circleFill = `url(#${IMAGE_URL_PATTERN_ID})`;
  }
  return (
    <CommonMarker space={space} circleFill={circleFill}>
      {!imageUrl && (
        <text
          x={MARKER.CENTER}
          y={MARKER.CENTER}
          textAnchor="middle"
          fill={textFillColor}
          dy=".3em"
        >
          {displayName}
        </text>
      )}
      {imageUrl && (
        <defs id="pattern_def">
          <pattern id={IMAGE_URL_PATTERN_ID} width="100%" height="100%">
            <image
              id="person_image"
              href={imageUrl}
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
              preserveAspectRatio="none"
            />
          </pattern>
        </defs>
      )}
    </CommonMarker>
  );
};
