import React from 'react';
import Avatar from '@engage-web/components/base/Avatar/Avatar';
import { isElectron } from '@engage-web/utils/electron';
import { useTranslation } from 'react-i18next';
import { useUser } from '@engage-web/api/queries';
import { EngageIcons } from '@engage-web/components/base';
import {
  PersonButtonName,
  PersonButtonNameWrapper,
  PersonButtonTitle,
  PersonButtonWrapper,
} from './styled';
import { UserData } from '@engage-shared/api';

type PersonButtonProps = {
  title?: string;
  onClick?: () => void;
  name?: string;
  disabled?: boolean;
} & UserData;

function PersonButton(props: PersonButtonProps) {
  const { id, name, firstName, lastName, imageUrl = null, onClick, title, disabled } = props;
  const { data, isLoading } = useUser();
  const { t } = useTranslation();

  if (isLoading) {
    return null;
  }

  // for kiosks guest equals to current user id
  const isGuest = data?.id === id;

  let fullName;
  let image = imageUrl;
  let icon: EngageIcons | undefined;

  if (isElectron() && isGuest) {
    fullName = t('layout.reserve.guest');
    image = null;
    icon = 'personNew';
  } else {
    fullName = name || `${firstName} ${lastName}`;
  }

  const onPersonButtonClick = () => {
    if (onClick && typeof onClick === 'function') onClick();
  };

  return (
    <PersonButtonWrapper>
      <PersonButtonTitle>{title}</PersonButtonTitle>
      <PersonButtonNameWrapper disabled={disabled} onClick={onPersonButtonClick}>
        <Avatar icon={icon} source={image} name={fullName} size="tiny" />
        <PersonButtonName disabled={disabled}>{fullName}</PersonButtonName>
      </PersonButtonNameWrapper>
    </PersonButtonWrapper>
  );
}

export default PersonButton;
