import styled from 'styled-components';

export const Video = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.color2};
`;
