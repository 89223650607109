import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Nullable } from '@engage-shared/utils/types';
import { MenuProps } from '@engage-web/components/scenes/drawer/header/DrawerMenu/DrawerMenu';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useToggleFavouriteMenuItem } from '@engage-web/utils/hooks/useToggleFavouriteMenuItem';
import { useOpenBuildingInGoogleMaps } from './useOpenBuildingInGoogleMaps';
import { FavouriteType, SpaceType, SpaceTypesData } from '@engage-shared/api';

type UseSpaceMenu = (props: {
  spaceId?: string;
  spaceAttributes?: SpaceTypesData;
  isDesk?: boolean;
  isFavourite?: boolean;
}) => Nullable<MenuProps>;
export const useSpaceMenu: UseSpaceMenu = ({
  spaceId,
  isDesk,
  isFavourite = false,
  spaceAttributes,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const openBuildingInGoogleMaps = useOpenBuildingInGoogleMaps();
  const toggleFavouriteMenuItem = useToggleFavouriteMenuItem({
    isFavourite,
    itemId: spaceId,
    itemType: isDesk ? FavouriteType.desk : FavouriteType.space,
    itemOriginalType: isDesk ? SpaceType.DESK : SpaceType.SPACE,
  });

  if (!spaceId) {
    return null;
  }

  const menu: MenuProps = [
    {
      name: t('layout.drawer.menu.openInMapsButton'),
      onPress: openBuildingInGoogleMaps,
    },
  ];

  if (toggleFavouriteMenuItem) {
    // add this item on the first position of the menu
    menu.unshift(toggleFavouriteMenuItem);
  }
  if (spaceAttributes?.length) {
    menu.push({
      name: t('layout.drawer.menu.equipment'),
      onPress: () => {
        navigate(PATH_SEGMENT.SPACE_ATTRIBUTES, {
          state: {
            space: { id: spaceId },
            spaceAttributes,
          },
        });
      },
    });
  }

  return menu;
};
