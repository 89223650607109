import React, { memo } from 'react';
import { Avatar, EngageIcons, Icon } from '@engage-web/components/base';
import { ATTENDEE_STATUS, OPT_OUT_DISPLAY_NAME } from '@engage-web/constants';
import { BookingAttendee } from '@engage-shared/api/bookings';
import { useTheme } from 'styled-components';
import { Theme } from '@engage-shared/theme';
import { getAttendeeStatus } from './getAttendeeStatus';
import {
  BadgeWrapper,
  DetailsWrapper,
  OrganizerBadgeWrapper,
  Subtitle,
  SubtitleWrapper,
  Title,
  Wrapper,
} from './styled';
import { useTranslation } from 'react-i18next';

type AttendeeProps = {
  item: BookingAttendee;
};

const Attendee = ({ item }: AttendeeProps) => {
  const { name, imageUrl, description, status, isOrganiser } = item;

  const theme = useTheme() as Theme;

  const { iconName, iconColor, avatarStatus, iconBackgroundColor } = getAttendeeStatus({
    status,
    theme,
  });

  const { t } = useTranslation();
  const displayName =
    name === OPT_OUT_DISPLAY_NAME ? t('layout.teamMembersList.userHasOptedOut') : name;

  const renderBadge = () => {
    if (isOrganiser) {
      return (
        <OrganizerBadgeWrapper>
          <Icon name="crown" size={14} />
        </OrganizerBadgeWrapper>
      );
    }

    if (!status || status === ATTENDEE_STATUS.NONE || status === ATTENDEE_STATUS.INVITED) {
      return null;
    }

    return (
      <BadgeWrapper iconBackgroundColor={iconBackgroundColor} iconColor={iconColor}>
        <Icon name={iconName as EngageIcons} size={8} />
      </BadgeWrapper>
    );
  };

  return (
    <Wrapper>
      <Avatar source={imageUrl} size="small" badgeContent={renderBadge()} status={avatarStatus} />
      <DetailsWrapper>
        <Title data-testid="attendee-item-name">{displayName}</Title>
        <SubtitleWrapper>
          <Subtitle className="btn" data-testid="attendee-item-description">
            {description}
          </Subtitle>
        </SubtitleWrapper>
      </DetailsWrapper>
    </Wrapper>
  );
};

export default memo(Attendee);
