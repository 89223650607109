import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { A11yHiddenLabel } from '../../../../../styles';
import { Line, Oval, Wrapper } from './styled';

type SeparatorProps = { color: string };

const Separator = ({ color = '#000000' }: SeparatorProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper aria-label={t('accessibilityLabels.today')}>
      <Oval color={color} />
      <Line color={color} />
      <A11yHiddenLabel>{t('accessibilityLabels.today')}</A11yHiddenLabel>
    </Wrapper>
  );
};

export default memo(Separator);
