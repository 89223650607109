const testRegExp = (exp: string, value: string): boolean => RegExp(exp).test(value);

export const isValidEmail = (value: string): boolean =>
  testRegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', value);

export const isValidPhone = (value: string): boolean =>
  testRegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$', value);

export const isValidUrl = (value: string): boolean =>
  testRegExp('(^http[s]?:\\/{2})|(^www)|(^\\/{1,2})', value);

export const isValidTime = (value: string): boolean =>
  testRegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$', value);
