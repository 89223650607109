import { Api, getApiUrl } from '@engage-shared/api/instance';
import { FloorLocation } from './interfaces';

type FetchFloorLocation = (floorId: number) => Promise<FloorLocation[]>;
/**
 * Retrieve the region branch for a given floor by id.
 * The output is an array, with the first value always being the Root Node,
 * the last always being the building,
 * the values in between consist of the sub-regions
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | regions found |
 * | 404 | floor not found |
 * @param floorId
 */
export const fetchFloorLocation: FetchFloorLocation = async floorId => {
  const url = new URL(`${getApiUrl()}/floors/${floorId}/regions`);
  const response = await Api.get<{ data: FloorLocation[] }>(url.toString());
  return response.data?.data;
};

interface ZoomDescriptor {
  floorId: number;
  width: number;
  height: number;
  url: string;
  xml: string;
  scale: number;
}

export const fetchZoomDescriptor = async (floorId: number): Promise<ZoomDescriptor> => {
  const url = new URL(`${getApiUrl()}/floors/${floorId}/zoom_descriptor`);
  const response = await Api.get<ZoomDescriptor>(url.toString());
  return response.data;
};
