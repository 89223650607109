import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { DrawerCardContent } from '@engage-web/components/scenes/drawer/DrawerCardContent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloorplanViews } from './FloorplanViews';

export const FloorplanViewsContainer = () => {
  const { t } = useTranslation();
  const title = t('layout.floorplanViews.title');

  return (
    <>
      <DrawerHeader
        title={title}
        subtitle=""
        closeIconA11yLabel="layout.floorplanViews.closeViews"
        isCloseIcon
      />
      <DrawerCardContent>
        <FloorplanViews />
      </DrawerCardContent>
    </>
  );
};
