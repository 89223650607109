import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@engage-web/components/base';
import { getBadgeA11yLabel } from '@engage-web/components/layouts/GlobalSearch/utils';
import { badgeColor } from '@engage-web/components/base/Avatar/config';
import { DeskItem, List } from './styled';
import {
  TeamBookingDeskAvailability,
  TeamBookingDetails,
  TeamDeskBooking,
} from '@engage-shared/api';

type DeskListProps = {
  desks: TeamBookingDetails['deskBookings'];
  desksAvailabilities?: TeamBookingDeskAvailability[];
  onItemClick?: (item: TeamDeskBooking) => void;
};

export function DeskList({ desks, desksAvailabilities, onItemClick }: DeskListProps) {
  const { t } = useTranslation();

  const renderDesk = (desk: TeamDeskBooking) => {
    const { available, bookable } =
      desksAvailabilities?.find(deskAvailability => deskAvailability.id === desk.spaceId) ?? {};
    const showBadge = !!desksAvailabilities && bookable;

    return (
      <DeskItem key={desk.id} onClick={() => onItemClick?.(desk)} clickable={!!onItemClick}>
        <Avatar
          icon="desk"
          size="small"
          badgeVariant={showBadge ? 'dot' : 'standard'}
          badgeStyle={{
            backgroundColor: available ? badgeColor.success : badgeColor.warning,
          }}
          badgeA11yLabel={showBadge ? getBadgeA11yLabel({ t, available }) : ''}
          style={{ background: 'transparent' }}
        />
        {desk.spaceName}
      </DeskItem>
    );
  };

  return <List>{desks.map(renderDesk)}</List>;
}
