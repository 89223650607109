import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, filtersSelectors, tenantSelectors } from '../../../store';
import { useTranslation } from 'react-i18next';
import { RESTRICT_TO_VALUES } from '../../../constants';
import { NumberInputCounter, SelectButton, GlobalSearchClear } from '../../base';
import { useCloseFilters, useReservationFilters, useRestrictTo } from '../../../utils/hooks';
import DateInterval from '../ReservationAssist/DateInterval/DateInterval';
import { TypesAndAttributesFilter as AssetsAndTypesFilter } from './TypesAndAttributesFilter/TypesAndAttributesFilter';
import Analytics from '../../../utils/analytics';
import { focusElementById, FocusId } from '../../../utils';
import {
  SearchFilterHeader,
  SearchFilterInputGroup,
  SearchFilterInputsWrapper,
  SearchFilterWrapper,
  Wrapper,
} from './styled';
import { useFloorPermissions } from '@engage-shared/api';
import { countAppliedSpaceFilters } from '@engage-shared/utils';

const SearchFilter = () => {
  // indicator that specifies whether draft state was synchronized or not
  const [synchronized, setSynchronized] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeFilters = useCloseFilters();
  const restrictTo = useRestrictTo();
  const { enforceAllDayDeskReservations } = useReservationFilters(
    restrictTo === RESTRICT_TO_VALUES.DESK,
  );

  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const { data: permissions } = useFloorPermissions({
    floorId: floorId || 0,
    options: { enabled: !!floorId },
  });
  const { desksBookingPermission, spacesBookingPermission } = permissions || {};

  const draftFilters = useSelector(filtersSelectors.getDraftFilter);
  const { capacity } = draftFilters;
  const filtersChanged = countAppliedSpaceFilters(draftFilters) > 0;
  const isDesk = restrictTo === RESTRICT_TO_VALUES.DESK;
  const isSpace = restrictTo === RESTRICT_TO_VALUES.SPACE;

  // synchronize
  useEffect(() => {
    dispatch(filtersActions.synchronizeFiltersDraft());
    setSynchronized(true);
  }, [dispatch]);

  const setCapacity = (capacity: number) => {
    dispatch(filtersActions.setFilterValues({ capacity, isDraft: true }));
  };

  const getComponentVisibility = (enforceAllDayDeskReservations: boolean) => ({
    showCapacity: isSpace,
    showTypes: isSpace || isDesk,
    showAllDaySwitch: isSpace ? false : !enforceAllDayDeskReservations,
    showBookingControls: (isSpace && spacesBookingPermission) || (isDesk && desksBookingPermission),
  });

  const { showCapacity, showTypes, showBookingControls } = getComponentVisibility(
    enforceAllDayDeskReservations,
  );

  const onShowClick = () => {
    // TODO: Check filter values
    dispatch(filtersActions.saveFiltersDraft());
    closeFilters();
  };

  const buttonText = t('layout.searchFilter.apply');

  const onClearPress = useCallback(() => {
    dispatch(filtersActions.clearDraftFilterValues());
    focusElementById(FocusId.SearchFiltersApplyButton);
  }, [dispatch]);

  useEffect(() => {
    if (filtersChanged) {
      if (isDesk) {
        Analytics.track('EVT-069', draftFilters);
      } else if (isSpace) {
        Analytics.track('EVT-065', draftFilters);
      }
    }

    return () => {
      const analyticsCode = isDesk ? 'EVT-067' : 'EVT-063';
      Analytics.track(analyticsCode);
    };
  }, [filtersChanged, draftFilters, isDesk, isSpace]);

  // don't render content until draft state is synchronized with real one
  if (!synchronized) {
    return null;
  }

  return (
    <Wrapper data-testid="SearchFilterComponent">
      <SearchFilterWrapper>
        {showBookingControls && (
          <SearchFilterHeader>{t('layout.searchFilter.duration')}</SearchFilterHeader>
        )}
        <SearchFilterInputsWrapper>
          {showBookingControls && <DateInterval />}
          {showCapacity && (
            <SearchFilterInputGroup>
              <NumberInputCounter
                value={capacity}
                onChange={setCapacity}
                minValue={1}
                label={t('layout.searchFilter.capacity')}
              />
            </SearchFilterInputGroup>
          )}
          {showTypes && <AssetsAndTypesFilter isDraft />}
        </SearchFilterInputsWrapper>
      </SearchFilterWrapper>
      <GlobalSearchClear isFilterChanged={filtersChanged} onPress={onClearPress} />
      <SelectButton
        id={FocusId.SearchFiltersApplyButton}
        data-testid="showButton"
        onClick={onShowClick}
      >
        {buttonText}
      </SelectButton>
    </Wrapper>
  );
};

export default memo(SearchFilter);
