import React, { CSSProperties, memo, ReactNode } from 'react';
import { Nullable } from '@engage-shared/utils/types';
import { Overlay, Content, StyledFocusLock } from './styled';

export const ModalContext = React.createContext({});

type ModalProps = {
  handleClose: () => void;
  style?: CSSProperties;
  className?: string;
  children: Nullable<ReactNode>;
  customContent?: boolean;
};

const Modal = ({
  handleClose,
  style = {},
  className,
  children = null,
  customContent = false,
  ...overlayProps
}: ModalProps) => {
  const renderContent = () => {
    if (customContent) {
      return children;
    }

    return (
      <Content
        data-testid="modal-content"
        $style={style}
        className={className}
        onClick={(e: any) => e.stopPropagation()}
      >
        {children}
      </Content>
    );
  };

  return (
    <ModalContext.Provider value={{ handleClose }}>
      <Overlay
        role="dialog"
        aria-hidden="true"
        data-testid="modal-overlay"
        onClick={handleClose}
        {...overlayProps}
      >
        <StyledFocusLock>{renderContent()}</StyledFocusLock>
      </Overlay>
    </ModalContext.Provider>
  );
};

export default memo(Modal);
