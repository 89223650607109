import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { checkInBooking, useRefreshBooking } from '@engage-shared/api';
import { CustomError } from '@engage-shared/api/instance/errors';
import { BookingDetails } from '@engage-shared/api/bookings';
import {
  DeleteButton,
  LineWrapper,
  StatusMessage,
} from '@engage-web/components/layouts/Booking/Card/styled';
import { ConfirmationDialog, Icon } from '@engage-web/components/base';
import Button from '@engage-web/components/layouts/Booking/Button/Button';
import { inAppNotificationsActions, tenantSelectors } from '@engage-web/store';
import {
  bookingDetailsButtons as BUTTONS,
  bookingDetailsButtonStatuses as STATUSES,
  CHECK_IN_STATUS,
} from '@engage-web/constants';
import { useCancelBooking } from '@engage-web/api/mutations';
import { useCheckInStatus } from '@engage-web/utils';
import { FocusId, useConfig, useDialog, useStartCheckIn } from '@engage-web/utils/hooks';
import { Theme } from '@engage-shared/theme';
import { getBookingDetailsStatus } from '@engage-shared/utils';

type SpaceBookingButtonsBarProps = {
  booking: BookingDetails;
};

export const SpaceBookingButtonsBar = ({ booking }: SpaceBookingButtonsBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openDialog } = useDialog();

  const { color1, color3, color6 } = useTheme() as Theme;
  const { enableDeskCheckIn } = useConfig(booking.location.buildingId);
  const statusObj = useCheckInStatus({ booking });

  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { refresh: refreshBooking } = useRefreshBooking();

  const { spaceId, meetingId, isDesk, isInInactiveLocation, isBookedForOther } = booking;

  const [isCheckOut, setIsCheckOut] = useState(false);

  const { mutate: cancelBooking, status: deleteMutationStatus } = useCancelBooking(booking);

  const onDeleteReservation = async (message: string, checkOut: boolean) => {
    const confirmed = await openDialog(
      <ConfirmationDialog
        key="delete-dialog"
        triggerId={FocusId.BookingDeleteButton}
        title={message}
      />,
    );

    if (confirmed) {
      setIsCheckOut(checkOut);
      cancelBooking({ tenantId, spaceId, meetingId });
    }
  };

  // this state is hack for P2-1733, API will not update isCheckInAvailable on booking
  // when check-in window actually starts
  const [bookingRefreshedOnCheckInOpen, setBookingRefreshedOnCheckInOpen] = useState(false);

  // refresh when check in opens
  const onCheckInOpen = useCallback(() => {
    // if user has the details screen open while check-in window opens,
    // refetch booking details to get isCheckInAvailable: true and activate the Check-in button
    // check-in is only applicable to desk bookings
    if (
      booking.isDesk &&
      !booking?.isCheckInAvailable &&
      !booking?.isCheckedIn &&
      !bookingRefreshedOnCheckInOpen
    ) {
      refreshBooking(booking);
      setBookingRefreshedOnCheckInOpen(true);
    }
  }, [booking, bookingRefreshedOnCheckInOpen, refreshBooking]);

  const { mutate: onCheckInMutation, status: checkInMutationStatus } = useMutation(checkInBooking, {
    onSuccess: () => {
      openDialog(
        <ConfirmationDialog
          title={t('layout.bookingCard.checkInSuccessDialogTitle')}
          message={t('layout.bookingCard.checkInSuccessDialogMessage')}
          triggerId={FocusId.BookingCheckInButton}
          key="successful-check-in-dialog"
          hasCancelButton={false}
        />,
      );

      refreshBooking(booking);
    },

    onError: (error: CustomError) => {
      const errorMessage =
        // @ts-ignore
        CHECK_IN_MESSAGES[error.response?.data?.error] ||
        'layout.booking.errorMessages.genericCheckIn';
      dispatch(
        inAppNotificationsActions.addErrorNotification({
          message: t(errorMessage),
        }),
      );
    },
  });

  const startCheckIn = useStartCheckIn(() => onCheckInMutation({ tenantId, spaceId, meetingId }));

  const onUpdateReservation = () => {};

  let actionInProgress = '';
  if (deleteMutationStatus === 'loading' && !isCheckOut) actionInProgress = BUTTONS.DELETE;
  if (deleteMutationStatus === 'loading' && isCheckOut) actionInProgress = BUTTONS.CHECK_OUT;
  if (checkInMutationStatus === 'loading') actionInProgress = BUTTONS.CHECK_IN;

  useEffect(() => {
    if (statusObj.status === CHECK_IN_STATUS.CHECK_IN_OPEN) {
      onCheckInOpen();
    }
  }, [onCheckInOpen, statusObj.status]);

  const buttonStatuses = getBookingDetailsStatus({
    booking,
    statusObj,
    enableDeskCheckIn,
    actionInProgress,
  });
  const { DELETE, CHECK_IN, CHECK_OUT, UPDATE } = BUTTONS;
  const { DISPLAY, DISABLED, LOADING } = STATUSES;

  const showCheckInMessage =
    !!statusObj.statusMessage && isDesk && !isInInactiveLocation && !isBookedForOther;

  return (
    <>
      <LineWrapper>
        {buttonStatuses[DELETE][DISPLAY] && (
          <DeleteButton
            id={FocusId.BookingDeleteButton}
            onClick={() => onDeleteReservation(t('layout.bookingCard.deleteEvent'), false)}
            text={t('common.delete')}
            disabled={buttonStatuses[DELETE][DISABLED]}
            opacity={buttonStatuses[DELETE][DISABLED] ? 0.4 : 1}
            isLoading={buttonStatuses[DELETE][LOADING]}
            color={color1}
            backgroundColor="transparent"
          >
            <Icon name="bin" size={13} style={{ color: color1 }} />
          </DeleteButton>
        )}
        {buttonStatuses[UPDATE][DISPLAY] && (
          <Button
            onClick={onUpdateReservation}
            disabled={buttonStatuses[UPDATE][DISABLED]}
            opacity={buttonStatuses[UPDATE][DISABLED] ? 0.4 : 1}
            color={color1}
            backgroundColor={color3}
            text={t('common.updateReservation')}
          />
        )}
        {buttonStatuses[CHECK_IN][DISPLAY] && (
          <Button
            id={FocusId.BookingCheckInButton}
            onClick={startCheckIn}
            disabled={buttonStatuses[CHECK_IN][DISABLED]}
            opacity={buttonStatuses[CHECK_IN][DISABLED] ? 0.4 : 1}
            isLoading={buttonStatuses[CHECK_IN][LOADING]}
            text={t('common.checkIn')}
            color={color6}
            backgroundColor={color1}
          />
        )}
        {buttonStatuses[CHECK_OUT][DISPLAY] && (
          <Button
            onClick={() => onDeleteReservation(t('layout.bookingCard.checkOutReservation'), true)}
            disabled={buttonStatuses[CHECK_OUT][DISABLED]}
            opacity={buttonStatuses[CHECK_OUT][DISABLED] ? 0.4 : 1}
            isLoading={buttonStatuses[CHECK_OUT][LOADING]}
            text={t('common.checkOut')}
            color={color6}
            backgroundColor={color1}
          />
        )}
      </LineWrapper>

      {showCheckInMessage && (
        <LineWrapper data-testid="checkInMessage">
          <StatusMessage $isClosed={statusObj.status === CHECK_IN_STATUS.CHECK_IN_CLOSED}>
            {statusObj.statusMessage}
          </StatusMessage>
        </LineWrapper>
      )}
    </>
  );
};
