import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyCard } from '@engage-web/components/base';
import { teamReservationsActions, teamReservationsSelectors } from '@engage-web/store';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useNavigate } from 'react-router-dom';
import { PATH_SEGMENT } from '@engage-web/constants';
import { joinPaths } from '@engage-web/router/utils';
import { useGenerateCurrentLocationPath } from '@engage-web/utils';
import { useTeam } from '@engage-web/api/queries/useTeam';
import { ReserveCard } from './Card/ReserveCard/ReserveCard';
import ReserveCardSkeleton from './Card/ReserveCardSkeleton';
import { useReserveQueries } from './hooks/useReserveQueries';
import { useReserveRouteData } from './hooks/useReserveRouteData';

export function ReserveTeamContainer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLocationPath = useGenerateCurrentLocationPath();

  const teamReservationSelectedDesks = useSelector(
    teamReservationsSelectors.getTeamReservationSelectedDesks,
  );
  const isDeskSelectionCompleted = useSelector(teamReservationsSelectors.getIsSelectionComplete);

  // using first space item as a placeholder for now
  const firstSelectedDeskId = teamReservationSelectedDesks?.[0];

  const reserveRouteData = useReserveRouteData();
  const { id, closeLinkPath, data: stateData } = reserveRouteData ?? {};
  const { isLoading, spaceItem, userData, bookingsData } = useReserveQueries(firstSelectedDeskId);
  const teamQuery = useTeam({
    id,
    // @ts-ignore
    teamFromState: stateData,
  });
  const teamData = teamQuery?.data ?? stateData ?? undefined;

  useEffect(() => {
    // move back to desks selection since user de-selected some of the desks
    if (!isDeskSelectionCompleted) {
      navigate(joinPaths(currentLocationPath, PATH_SEGMENT.TEAMS, `${id}`), {
        state: {
          team: teamData,
        },
      });
    }
  }, [isDeskSelectionCompleted, navigate, id, currentLocationPath, teamData]);

  const resetTeamReservation = () => {
    dispatch(teamReservationsActions.clear());
    dispatch(teamReservationsActions.clearSelection());
  };

  if (isLoading || teamQuery.isLoading) {
    return (
      <>
        <DrawerHeader closeLinkPath={closeLinkPath} onPress={resetTeamReservation} isCloseIcon />
        <ReserveCardSkeleton />
      </>
    );
  }

  if (!reserveRouteData || !userData || !spaceItem || !teamData) {
    return (
      <>
        <DrawerHeader
          closeLinkPath={closeLinkPath}
          onPress={resetTeamReservation}
          isCloseIcon
          closeIconA11yLabel="accessibilityLabels.closeLayout_teamReserve"
        />
        <EmptyCard iconName="warning">{t('common.networkError')}</EmptyCard>
      </>
    );
  }

  return (
    <>
      <DrawerHeader
        closeLinkPath={closeLinkPath}
        isCloseIcon
        onPress={resetTeamReservation}
        closeIconA11yLabel="accessibilityLabels.closeLayout_teamReserve"
      />
      <ReserveCard
        spaceItem={spaceItem}
        // @ts-ignore
        item={teamData}
        user={userData}
        bookings={bookingsData}
      />
    </>
  );
}
