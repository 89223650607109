import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { globalSearchActions } from '@engage-web/store';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../SearchInput';

function GlobalSearchInput() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const placeholderText = t('layout.globalSearch.inputPlaceholder');

  const setSearchValue = (searchQuery: string) =>
    dispatch(globalSearchActions.setSearchValue(searchQuery));

  const clearSearchValue = () => dispatch(globalSearchActions.clearSearchValue());

  return (
    <SearchInput
      setSearchValue={setSearchValue}
      clearSearchValue={clearSearchValue}
      searchPlaceholderText={placeholderText}
    />
  );
}

export default memo(GlobalSearchInput);
