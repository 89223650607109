import Analytics from '@engage-web/utils/analytics';
import tenantSlice from '@engage-web/store/tenant';
import { setSentryUserId, setSentryTenantId } from '@engage-web/utils/analytics/sentry';
import userConfig from '@engage-web/store/userConfig';

const analyticsMiddleware = (/* { getState } */) => next => action => {
  const { type, payload = {} } = action; // payload = {} is an ugly hack to fix the tests, so far I haven't come up with anything better

  switch (type) {
    case 'persist/REHYDRATE': {
      if (payload.userId && payload.tenantId) {
        const { userId, tenantId } = payload;
        setSentryUserId(userId);
        setSentryTenantId(tenantId);
        Analytics.group(tenantId);
        Analytics.identify(userId);
      }
      break;
    }

    case /@@router.*$/i.test(action.type): {
      const { location, isFirstRendering } = action.payload;
      Analytics.page({ ...location, entryPoint: isFirstRendering });
      break;
    }

    case tenantSlice.actions.setUser.type:
      setSentryUserId(payload);
      Analytics.identify(payload);
      break;

    case tenantSlice.actions.setTenant.type:
      setSentryTenantId(payload.tenantId);
      Analytics.group(payload.tenantId);
      break;

    case userConfig.actions.setUiLanguage.type:
      Analytics.setLocale(payload);
      break;

    default:
      return next(action);
  }

  return next(action);
};

export default analyticsMiddleware;
