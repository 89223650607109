export const FES_EVENTS = {
  INIT: 'INIT',
  // used only on mobile side due to mobile Floorplan loading specific
  RE_INIT: 'RE_INIT',
  INITIAL_LOAD: 'INITIAL_LOAD',
  FLOORPLAN_CLICKED: 'FLOORPLAN_CLICKED',
  FLOORPLAN_CHANGED: 'FLOORPLAN_CHANGED',
  FLOORPLAN_READY: 'FLOORPLAN_READY',
  FLOORPLAN_LOADED: 'FLOORPLAN_LOADED',
  SHOW_SPACE: 'SHOW_SPACE',
  SET_VIEWER: 'SET_VIEWER',
  ROTATION_CHANGED: 'ROTATION_CHANGED',
  RESET_ORIENTATION: 'RESET_ORIENTATION',
  CLEAR_SHOW_SPACE: 'CLEAR_SHOW_SPACE',
  TOGGLE_VIEW: 'TOGGLE_VIEW',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  INIT_DATA: 'INIT_DATA',
  INIT_OPTIONS: 'INIT_OPTIONS',
  FETCH_TILES: 'FETCH_TILES',
  REFRESH_SPACE_DATA: 'REFRESH_SPACE_DATA',
  SET_AVAILABILITY_DATE: 'SET_AVAILABILITY_DATE',
  UNRECOVERABLE_ERROR: 'UNRECOVERABLE_ERROR',
  RECOVERABLE_ERROR: 'RECOVERABLE_ERROR',
  SET_DISTANCE_MARKER: 'SET_DISTANCE_MARKER',
  DISTANCE_MARKER_EXPIRED: 'DISTANCE_MARKER_EXPIRED',
  SET_FLOORPLAN_COMPONENT_SPACE_DATA: 'SET_FLOORPLAN_COMPONENT_SPACE_DATA',
  SHOW_SV_LIVE_PERSON_LOCATION: 'SHOW_SV_LIVE_PERSON_LOCATION',
  HIDE_SV_LIVE_PERSON_LOCATION: 'HIDE_SV_LIVE_PERSON_LOCATION',
  TEAM_RESERVATION_ADD_DESKS: 'TEAM_RESERVATION_ADD_DESKS',
  SET_TEAM_RESERVATION_DESKS_SELECTION_MODE: 'SET_TEAM_RESERVATION_DESKS_SELECTION_MODE',
  SET_TEAM_RESERVATION_TEAM_SIZE: 'SET_TEAM_RESERVATION_TEAM_SIZE',
  CLEAR_TEAM_RESERVATION_DESKS_SELECTION: 'CLEAR_TEAM_RESERVATION_DESKS_SELECTION',
  SHOW_LABELS: 'SHOW_LABELS',
  TEAM_RESERVATION_SELECTED_DESKS_CHANGED: 'TEAM_RESERVATION_SELECTED_DESKS_CHANGED',
  ENTER_TEAM_BOOKING_AVAILABILITY_MODE: 'ENTER_TEAM_BOOKING_AVAILABILITY_MODE',
  EXIT_TEAM_BOOKING_AVAILABILITY_MODE: 'EXIT_TEAM_BOOKING_AVAILABILITY_MODE',
  SET_FLOOR_MARKERS: 'SET_FLOOR_MARKERS',
  SET_SELECTED_PERSON: 'SET_SELECTED_PERSON',
  SET_FOCUSED_SPACE: 'SET_FOCUSED_SPACE',
  DEV_TOOLS: 'DEV_TOOLS',
  SHOW_HEATMAP: 'SHOW_HEATMAP',
  HIDE_HEATMAP: 'HIDE_HEATMAP',
  SHOW_HERE_MARKER: 'SHOW_HERE_MARKER',
  HIDE_HERE_MARKER: 'HIDE_HERE_MARKER',
  LOG_TO_MOBILE: 'LOG_TO_MOBILE',
  READY_FOR_INTERACTION: 'READY_FOR_INTERACTION',
  SET_ZOOM_DESCRIPTOR_LOADING_STATE: 'SET_ZOOM_DESCRIPTOR_LOADING_STATE',
} as const;

export const TEAM_RESERVATION_DESKS_SELECTION_MODE = {
  PAINT: 'paint',
  ERASE: 'erase',
  DISABLED: 'disabled',
} as const;

export type FesEvents = typeof FES_EVENTS;
export type FesEvent = keyof FesEvents;
