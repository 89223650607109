import { Region } from '@engage-shared/api/regions/interfaces';
import { sortByAlphaNum } from '@engage-shared/utils/helpers';

export const sortRegions = (regions?: Region[]): Region[] => {
  if (!regions) return [];

  return regions.sort((regionA, regionB) => {
    if (regionA.sequence === regionB.sequence) {
      return sortByAlphaNum(regionA.name, regionB.name);
    }

    const sequenceA = regionA.sequence || 0;
    const sequenceB = regionB.sequence || 0;

    return sequenceA - sequenceB;
  });
};
