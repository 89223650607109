import { useQuery, UseQueryResult } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { LanguageTypes } from '@engage-shared/constants/languages';
import { SPACE_ATTRIBUTES_QUERY_KEY } from '@engage-shared/api/spaces/constants';
import { SpaceTypesData } from '@engage-shared/api/spaces/interfaces';
import { fetchSpaceAttributes } from '@engage-shared/api/spaces/fetchSpaceAttributes';

type UseSpaceAttributesParams = {
  spaceId: number;
  locale?: LanguageTypes;
  options?: UseQueryOptions<SpaceTypesData>;
};

type UseSpaceTypeAttributesQuery = (
  params: UseSpaceAttributesParams,
) => UseQueryResult<SpaceTypesData>;

export const useSpaceAttributesQuery: UseSpaceTypeAttributesQuery = ({
  spaceId,
  locale,
  options = { enabled: true },
}) =>
  useQuery({
    queryKey: [SPACE_ATTRIBUTES_QUERY_KEY, spaceId, locale],
    queryFn: () => fetchSpaceAttributes({ id: spaceId, locale }),
    ...options,
    enabled: options?.enabled && !!spaceId,
  });
