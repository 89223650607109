import { useCallback } from 'react';
import { UseMutationResult } from 'react-query/types';
import {
  FavouriteMutationContext,
  FavouriteMutationParams,
} from '@engage-shared/api/favourites/interfaces';
import { UseMutationOptions } from 'react-query/types/react/types';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { addFavouriteItem } from '@engage-shared/api/favourites/addFavouriteItem';
import { QueryKey } from 'react-query/types/core/types';
import { mutateSearchResults } from '../utils';
import { SearchResponse } from '@engage-shared/api/search/interfaces';
import { deleteFavouriteItem } from '@engage-shared/api/favourites/deleteFavouriteItem';

export type UseToggleFavouriteItem = (
  searchQueryKey?: QueryKey,
  options?: UseMutationOptions<any, Error, FavouriteMutationParams, FavouriteMutationContext>,
) => UseMutationResult<any, Error, FavouriteMutationParams, FavouriteMutationContext>;

/**
 * Toggles isFavourite boolean parameter inside desk/person/space, makes optimistic
 * updates for search results
 * @param searchQueryKey
 * @param options
 */
export const useToggleFavouriteItem: UseToggleFavouriteItem = (searchQueryKey, options = {}) => {
  const queryClient = useQueryClient();

  const toggleFavoriteItem = useCallback(
    ({ isCurrentlyFavourite, ...body }: FavouriteMutationParams) => {
      const mutateFunction = isCurrentlyFavourite ? deleteFavouriteItem : addFavouriteItem;
      return mutateFunction(body);
    },
    [],
  );

  return useMutation<any, Error, FavouriteMutationParams, FavouriteMutationContext>(
    toggleFavoriteItem,
    {
      onMutate: async ({ id, isCurrentlyFavourite }) => {
        if (searchQueryKey) {
          await queryClient.cancelQueries(searchQueryKey);

          const searchResults =
            queryClient.getQueryData<InfiniteData<SearchResponse>>(searchQueryKey);

          mutateSearchResults(
            {
              queryClient,
              queryKey: searchQueryKey,
              itemId: id,
            },
            !isCurrentlyFavourite,
          );

          return { searchResults, searchQueryKey };
        }
      },
      ...options,
      onError: (error, variables, context = {}) => {
        const { searchResults, searchQueryKey: queryKey } = context;
        if (queryKey) {
          queryClient.setQueryData(queryKey, searchResults);
        }
        options.onError?.(error, variables, context);
      },
    },
  );
};
