import styled from 'styled-components';

export const ListWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 30px;
`;

export const SkeletonWrapper = styled.div`
  padding: 0 30px;
`;

export const SkeletonItemWrapper = styled.div`
  height: 56px;
  padding: 0 7px;
  border-bottom: 1px solid ${({ theme }) => theme.color4};
  display: flex;
  align-items: center;

  > span:first-of-type {
    margin-right: 10px;
  }
`;
