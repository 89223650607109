import React from 'react';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { TenantButton } from '@engage-web/components/scenes/Tenant/TenantButton/TenantButton';
import { Container } from './styled';

type PastTenantsProps = {
  onItemClick: (item: string) => void;
};
export const PastTenants = ({ onItemClick }: PastTenantsProps) => {
  const pastTenants = useSelector(tenantSelectors.getPastTenants);

  if (!pastTenants.length) {
    return null;
  }

  return (
    <Container data-testid="past-tenants">
      {pastTenants.map(tenant => (
        <TenantButton key={tenant.id} tenant={tenant} onClick={() => onItemClick(tenant.id)} />
      ))}
    </Container>
  );
};
