import { useTeamMembers as useTeamMembersQuery } from '@engage-shared/api';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { useFavouritePeople } from '@engage-web/api/queries/useFavouritePeople';
import { PATH_SEGMENT } from '@engage-web/constants';

export const useTeamMembers = ({
  teamId,
  searchString,
}: {
  teamId?: number | string;
  searchString: string;
}) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const isFavPeopleTeam = teamId === PATH_SEGMENT.FAV_PEOPLE_TEAM;

  const { data: teamMembers, isLoading: teamMembersQueryLoading } = useTeamMembersQuery({
    tenantId,
    // make id undefined and this query will be disabled
    // @ts-ignore
    id: isFavPeopleTeam ? undefined : teamId,
    searchString,
  });

  const { people, isLoading: favPeopleIsLoading } = useFavouritePeople({
    enabled: isFavPeopleTeam,
  });

  if (!isFavPeopleTeam) {
    return { data: teamMembers, isLoading: teamMembersQueryLoading };
  }

  let data =
    people?.map(person => ({
      ...person,
      displayName: person.name,
    })) || [];

  if (searchString) {
    data = data.filter(item => item.displayName.toLowerCase().includes(searchString.toLowerCase()));
  }

  return { data, isLoading: favPeopleIsLoading };
};
