import { Building } from '../../api/buildings/interfaces';

export const GOOGLE_MAP_LOCATION_PATH_PREFIX = 'https://www.google.com/maps/search/?api=1&query=';

/**
 * Returns Google Maps link if building has coordinates.
 * Otherwise, returns `null`.
 */
type GetBuildingGoogleMapsLink = (
  building: Partial<Pick<Building, 'latitude' | 'longitude'>>,
) => string | URL | undefined;

export const getBuildingGoogleMapsLink: GetBuildingGoogleMapsLink = building => {
  if (!(building?.latitude && building?.longitude)) return undefined;

  return `${GOOGLE_MAP_LOCATION_PATH_PREFIX}${building.latitude},${building.longitude}`;
};
