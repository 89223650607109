import { useBookingModal, useModal } from '@engage-web/utils';
import { isElectron } from '@engage-web/utils/electron';
import { Nullable } from '@engage-shared/utils/types';
import { BookingDetails } from '@engage-shared/api/bookings';
import { MODALS } from '@engage-web/constants';
import { useCallback } from 'react';
import { TeamBookingDetails } from '@engage-shared/api';

export type UseGoBackFromBookingParams = {
  booking?: Nullable<BookingDetails | TeamBookingDetails>;
};

export const useGoBackFromBooking = ({ booking }: UseGoBackFromBookingParams) => {
  const { close: closeBookingModal } = useBookingModal();
  const { openModal } = useModal();

  const goBack = useCallback(() => {
    closeBookingModal();
    if (isElectron()) {
      openModal(MODALS.USER_PROFILE);
    } else {
      openModal(MODALS.AGENDA, { startDate: booking?.startDate });
    }
  }, [booking?.startDate, closeBookingModal, openModal]);

  return { goBack, openModal };
};
