import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useTeamBookingDetails } from '@engage-web/api/queries';
import { teamReservationsSelectors, tenantSelectors, userConfigSelectors } from '@engage-web/store';
import { useCurrentTimeZone } from './useCurrentTimeZone';
import { useLocalTimeFormatH12 } from './useLocalTimeFormatH12';
import { TEAM_BOOKING_QUERY_KEY, TeamBookingDetails } from '@engage-shared/api';
import { DateFormat, formatLocalizedDate } from '@engage-shared/utils';

/**
 * Get team booking start date.
 *
 * If there is cached team booking date – it will be used.
 * Otherwise, team booking query will be executed.
 *
 * @returns Team booking `startDate`.
 */
export function useTeamBookingStartDate() {
  const queryClient = useQueryClient();
  const timeZone = useCurrentTimeZone();
  const isTimeFormatH12 = useLocalTimeFormatH12();

  const tenantId = useSelector(tenantSelectors.getTenantId);
  const teamBookingId = useSelector(teamReservationsSelectors.getTeamBookingId);
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const cachedTeamBookingDetails = queryClient.getQueryData<TeamBookingDetails>([
    TEAM_BOOKING_QUERY_KEY,
    tenantId,
    `${teamBookingId}`,
  ]);

  const { data: teamBookingDetails } = useTeamBookingDetails(teamBookingId, {
    enabled: !cachedTeamBookingDetails,
  });

  return formatLocalizedDate(teamBookingDetails?.startDate ?? new Date(), {
    timeZone,
    locale,
    format: DateFormat.weekdayShortWithMonthAndDayAndTime,
    hour12: isTimeFormatH12,
  });
}
