import { useMutation, useQueryClient, UseQueryOptions } from 'react-query';
import { USER_CONFIG_QUERY_KEY } from '../constants';
import { updateUserConfig } from '../fetch';
import { UserConfig, UserConfigPayload } from '../interfaces';

type UseSetUserConfig = (options?: UseQueryOptions) => {
  setUserConfig: (config: UserConfigPayload) => void;
};

export const useSetUserConfig: UseSetUserConfig = options => {
  const queryClient = useQueryClient();

  const { mutate: setUserConfig } = useMutation({
    mutationFn: updateUserConfig,
    onMutate: async (data: UserConfigPayload) => {
      await queryClient.cancelQueries(USER_CONFIG_QUERY_KEY);

      const prevUserConfig = queryClient.getQueryData<UserConfig>(USER_CONFIG_QUERY_KEY);

      queryClient.setQueryData<UserConfig>(USER_CONFIG_QUERY_KEY, currentData => ({
        ...(currentData ?? ({} as UserConfig)),
        ...data,
      }));

      return { prevUserConfig };
    },
    onError: (_error, _newConfig, context) => {
      options?.onError?.(_error);
      queryClient.setQueryData(USER_CONFIG_QUERY_KEY, context?.prevUserConfig);
    },
    onSettled: (data, error) => {
      options?.onSettled?.(data, error);
      queryClient.invalidateQueries(USER_CONFIG_QUERY_KEY);
    },
  });

  return {
    setUserConfig,
  };
};
