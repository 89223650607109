import { useCallback } from 'react';
import { fetchPersonCheck } from '@engage-shared/api';

const errorMessages = {
  INVALID_PERSON: 'router.location.invalidPerson',
};

const usePersonCheck = () => {
  const checkPersonQuery = useCallback(async id => {
    const isValidPerson = await fetchPersonCheck(id);
    return {
      errorMessage: isValidPerson ? null : errorMessages.INVALID_PERSON,
    };
  }, []);
  return { checkPersonQuery };
};

export default usePersonCheck;
