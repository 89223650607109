import React, { useMemo, useState } from 'react';
import { KioskRemoteConfig } from '@engage-web/api/queries';
import {
  groupConfigsByBuildingAndFloor,
  KioskLocationMetadata,
} from '@engage-web/components/kiosk/AvailableKiosks/utils';
import {
  LocationSelectorWrapper,
  RegionHeader,
  RegionWrapper,
} from '@engage-web/components/layouts/LocationSelector/components/styled';
import { Nullable } from '@engage-shared/utils/types';
import { AvailableKioskFloors } from '@engage-web/components/kiosk/AvailableKiosks/AvailableKioskFloors';
import { Icon } from '@engage-web/components/base';

type AvailableKioskBuildingsProps = {
  data: KioskRemoteConfig[];
};

const AvailableKioskBuildings = ({ data }: AvailableKioskBuildingsProps) => {
  const [openedId, setOpenedId] = useState<Nullable<number>>(null);

  const buildings = useMemo(
    () =>
      groupConfigsByBuildingAndFloor(data, ({ buildingName, buildingId }) => ({
        id: buildingId,
        name: buildingName,
      })),
    [data],
  );

  const renderBuilding = ({ id, name, kioskOriginalData }: KioskLocationMetadata) => {
    const isOpened = id === openedId;
    return (
      <RegionWrapper key={`${id}-${name}`}>
        <RegionHeader $isActive={isOpened} onClick={() => setOpenedId(isOpened ? null : id)}>
          <div className="region-name">{name}</div>
          <div className="region-icon">
            <Icon name="chevron-right" size={14} />
          </div>
        </RegionHeader>
        {isOpened && <AvailableKioskFloors data={kioskOriginalData} />}
      </RegionWrapper>
    );
  };

  return <LocationSelectorWrapper>{buildings.map(renderBuilding)}</LocationSelectorWrapper>;
};

export default AvailableKioskBuildings;
