import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color6};
  height: 180px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  flex: 1;
  padding: 0 30px 30px;
`;

export const FloorplanView = styled.button`
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
  padding-left: 0;
`;

export const PNGImage = styled.img<{ $isActive: boolean }>`
  width: 100%;
  border: ${props => props.theme.typo2} solid ${props => (props.$isActive ? 2 : 0)}px;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 0 #00000040;
  margin-bottom: 15px;
`;

export const FloorplanViewText = styled.div<{ $isActive: boolean }>`
  font-weight: ${props => (props.$isActive ? 'bold' : ' normal')};
  color: ${props => props.theme.typo2};
  font-size: 12px;
`;
