import { PATH_SEGMENT } from '@engage-web/constants';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  floorplanSelectors,
  teamReservationsActions,
  teamReservationsSelectors,
} from '@engage-web/store';
import { FlatList } from '@engage-web/components/base';
import DeskItem from '@engage-web/components/layouts/GlobalSearch/DeskItem';
import { SearchResponseData } from '@engage-shared/api/search';
import {
  GlobalSearchOuterWrapper,
  GlobalSearchResultsWrapper,
} from '@engage-web/components/layouts/GlobalSearch/styled';
import SelectableDeskItem from '@engage-web/components/layouts/Team/TeamMembers/SelectableDeskItem';
import { DesksSelectionCardContainer, EmptyList, SelectionHint } from './TeamMembers.style';
import { ParsedTeam } from '@engage-shared/api';

type DeskSelectionCardProps = {
  team: ParsedTeam;
};

export function DeskSelectionCard({ team }: DeskSelectionCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDeskSelectionCompleted = useSelector(teamReservationsSelectors.getIsSelectionComplete);
  const selectedDesks = useSelector(teamReservationsSelectors.getTeamReservationSelectedDesks);
  const teamSize = useSelector(teamReservationsSelectors.getTeamSize);

  const nonOccupantDesks = useSelector(floorplanSelectors.getNonOccupantDesks);

  useEffect(() => {
    if (isDeskSelectionCompleted) {
      navigate(PATH_SEGMENT.RESERVE, {
        state: {
          team,
        },
      });
    }
  }, [isDeskSelectionCompleted, team, dispatch, navigate]);

  const onDeskPress = useCallback(
    ({ id }: { id: number }) => {
      if (selectedDesks.length < teamSize) {
        const isSelected = selectedDesks.includes(id);
        const newSelectedDesks = isSelected
          ? selectedDesks.filter((deskId: number) => deskId !== id)
          : [...selectedDesks, id];

        dispatch(teamReservationsActions.addDesksToTeamReservation(newSelectedDesks));
      }
    },
    [dispatch, selectedDesks, teamSize],
  );

  const renderItem = ({
    item,
    index,
    style,
  }: {
    item: SearchResponseData;
    index: number;
    style: any;
  }) => {
    const isSelected = selectedDesks.includes(item.id);

    return (
      <SelectableDeskItem
        isSelected={isSelected}
        onPress={onDeskPress}
        item={item}
        as="div"
        showBadge
        index={index}
        key={item.id}
        style={style}
      />
    );
  };

  const renderEmptyList = () => (
    <EmptyList aria-live="polite" aria-atomic="true">
      <p>{t('layout.teamMembersList.emptyDesksList')}</p>
    </EmptyList>
  );

  return (
    <DesksSelectionCardContainer>
      <SelectionHint>{t('layout.reserve.team.deskSelectionHint')}</SelectionHint>
      <GlobalSearchOuterWrapper
        id="desk_selection_list_wrapper"
        aria-live="polite"
        aria-atomic="true"
      >
        {nonOccupantDesks && nonOccupantDesks.length > 0 ? (
          <GlobalSearchResultsWrapper>
            <FlatList
              itemCount={nonOccupantDesks.length}
              data={nonOccupantDesks}
              itemSize={DeskItem.getSize()}
              renderItem={renderItem}
            />
          </GlobalSearchResultsWrapper>
        ) : (
          renderEmptyList()
        )}
      </GlobalSearchOuterWrapper>
    </DesksSelectionCardContainer>
  );
}
