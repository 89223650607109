import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { getBuildingGoogleMapsLink } from '@engage-shared/utils';

/**
 * Opens building location into a new browser tab.
 */
export const useOpenBuildingInGoogleMaps = () => {
  const { building } = useSelector(tenantSelectors.getCurrentLocation);
  const buildingGoogleMapsLink = getBuildingGoogleMapsLink(building);

  return () => window.open(buildingGoogleMapsLink, '_blank');
};
