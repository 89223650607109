import * as React from 'react';

import { Space } from '@floorplan/api';
import { Shape } from '../../../components';
import { getKey } from '../utils';
import { spaceStyle } from '../styles';
import { ElementsProps, EventInfo } from '../../../types';

interface RoomsProps extends ElementsProps {
  rooms: Space[];
}
export const Rooms = ({ rooms, scale, onShapeClicked }: RoomsProps) => {
  const styleFunction = (room: Space) => spaceStyle(room);

  const onRoomSelect = (event: EventInfo) =>
    event.space.allowInteraction && onShapeClicked && onShapeClicked(event);

  return (
    <>
      {rooms.map((room, index) => (
        <Shape
          space={room}
          shape={room.shapes[0]}
          scale={scale}
          isPoint={false}
          onShapeClicked={onRoomSelect}
          styleFunction={styleFunction}
          key={getKey(room.id, index)}
        />
      ))}
    </>
  );
};
