import { AllLanguageTypes, LanguageTypes } from '@engage-shared/constants/languages';

type ParseLocale = (locale: string) => string[];

type IsLocaleSupported = (locale: string, supportedLocales: string[] | object) => boolean;

type IsRegionSupported = (region: string, supportedRegions: string[]) => boolean;

type GetSupportedLocale = (
  languageTag: string,
  configuredLocales: AllLanguageTypes[],
) => LanguageTypes;

type Accumulator = {
  [key: string]: string[];
};

type GetConfigSupportedLocales = (
  configuredLanguages: AllLanguageTypes[],
  region: string,
) => Accumulator;

/**
 * Parsing passed languageTag
 * @param {string} languageTag
 * @return {array} -  An array is returned of 2 or 3 elements, it depends if languageTag has included script code.
 */
const parseLocale: ParseLocale = languageTag => {
  const [locale, script, region] = languageTag.split(/[-_]/);
  // If script is present the third item is the region.
  return region ? [locale, region] : [locale, script];
};

/**
 * Checking if locale is supported
 * @param {string} locale
 * @param {array} supportedLocales
 * @return {boolean}
 */
const isLocaleSupported: IsLocaleSupported = (locale, supportedLocales) =>
  Object.prototype.hasOwnProperty.call(supportedLocales, locale);

/**
 * Checking if region is supported
 * @param {string} region
 * @param {array} supportedRegions
 * @return {boolean}
 */
const isRegionSupported: IsRegionSupported = (region, supportedRegions) =>
  supportedRegions.includes(region);

/**
 * Returned object with supported locales and regions
 * @param {string} region
 * @param {array} configuredLanguages Array of language code strings
 * @return {object} Configured object is returned with languages as keys and array of regions as a values
 *  {
      "en": [
        "AU",
        "GB",
        "US"
      ],
      "fr": [
        "CA",
        "FR"
      ],
      "zh": [
        "CN"
      ],
      "es": [
        "ES"
      ],
      "he": [],
      "ja": []
    }
 */
const getConfigSupportedLocales: GetConfigSupportedLocales = (configuredLanguages, region) => {
  return configuredLanguages.reduce<Accumulator>((acc, configLanguageTag) => {
    const [configLocale, configRegion] = parseLocale(configLanguageTag);
    if (acc[configLocale]) {
      region && acc[configLocale].push(configRegion);
    } else {
      acc[configLocale] = [];
    }
    return acc;
  }, {});
};

/**
 * Gets the LanguageTypes enum value from the enum string value.
 * @param {string} type the string value of the LanguageTypes enum
 * @return {LanguageTypes | undefined} the LanguageType if it exists
 */
const getLanguageType = (type: string): LanguageTypes => (LanguageTypes as any)[type];

/**
 * Returned best matched locale
 * @param {string} languageTag
 * @param {array} configuredLanguages Array of supported language code strings
 * @return {LanguageTypes} locale
 */
export const getSupportedLocale: GetSupportedLocale = (languageTag, configuredLanguages) => {
  const [locale, region] = parseLocale(languageTag);
  const supportedLocales = getConfigSupportedLocales(configuredLanguages, region);

  if (
    isLocaleSupported(locale, supportedLocales) &&
    isRegionSupported(region, supportedLocales[locale])
  ) {
    const type = `${locale}${region}`;
    const enumType = getLanguageType(type);
    if (enumType) return enumType;
  }
  if (isLocaleSupported(locale, supportedLocales)) {
    const enumType = getLanguageType(locale);
    if (enumType) return enumType;
  }
  return LanguageTypes.en;
};
