import styled from 'styled-components';
import { CHECK_IN_STATUS } from '@engage-web/constants';
import { PADDING_USER_PROFILE } from '@engage-web/components/layouts/UserProfile/styled';

export const LIST_ITEM_PADDING_VERTICAL = 10;
export const ACTIONS_VERTICAL_MARGIN = 12;
export const BUTTON_VERTICAL_PADDING = 12;

export const Wrapper = styled.div<{ $isRead?: boolean }>`
  display: flex;
  height: 100%;
  direction: ltr;
  background-color: ${({ theme, $isRead }) => ($isRead ? theme.color6 : `${theme.color9}1a`)};
  border-bottom: 0.5px solid ${({ theme }) => theme.color4};
`;

export const Content = styled.div`
  display: flex;
  padding: ${LIST_ITEM_PADDING_VERTICAL}px ${PADDING_USER_PROFILE}px;
`;

export const ContentWrapper = styled.div`
  text-align: left;
  margin: 0 22px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h6`
  color: ${({ theme }) => theme.color2};
`;

export const Message = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color2};
`;

export const Actions = styled.div`
  display: flex;
  margin-top: ${ACTIONS_VERTICAL_MARGIN}px;
`;

export const DetailsAction = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.color9};

  & button {
    cursor: pointer;
    &:first-child {
      padding: ${BUTTON_VERTICAL_PADDING}px 24px;
      font-size: 12px;
      color: ${({ theme }) => theme.typo1};
    }
  }
  &:focus-within {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const CheckInMessage = styled.div<{ $status?: string }>`
  margin-top: ${ACTIONS_VERTICAL_MARGIN}px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme, $status }) =>
    $status === CHECK_IN_STATUS.CHECK_IN_CLOSED ? theme.inactiveColor : theme.typo2};
`;
