import React from 'react';
import { SkeletonTheme } from '@engage-web/components/base';
import Skeleton from 'react-loading-skeleton';
import { InfoWrapper, Wrapper } from './styled';

const LocationCardSkeleton = () => (
  <SkeletonTheme style={{ width: '100%' }}>
    <Wrapper>
      <InfoWrapper>
        <h6>
          <Skeleton width={200} />
        </h6>
        <h6>
          <Skeleton width={200} />
        </h6>
      </InfoWrapper>
      <Skeleton circle height={40} width={40} />
    </Wrapper>
  </SkeletonTheme>
);

export default LocationCardSkeleton;
