import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import DeskItem from '@engage-web/components/layouts/GlobalSearch/DeskItem';
import { replaceLineFeedWithSpace } from '@engage-web/utils';
import { Avatar, EmptyCard } from '@engage-web/components/base';
import { useTranslation } from 'react-i18next';
import SearchItemSkeleton from '@engage-web/components/layouts/GlobalSearch/SearchItemSkeleton';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { usePerson } from '@engage-web/api/queries/usePerson';
import { DrawerCardContent } from '@engage-web/components/scenes/drawer/DrawerCardContent';
import { generatePersonPath, removeSegmentFromPath } from '@engage-web/router/utils';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useAppLocation, useAppParams } from '@engage-web/router/hooks';
import { ContentWrapper } from './styled';
import { usePersonLocationsQuery } from '@engage-shared/api';

type PersonLocationsProps = {
  personId?: number;
};

const getPersonId = (
  personIdFromProps: number | undefined,
  personIdFromParams: string | undefined,
  personIdFromState: number | undefined,
): number => {
  if (personIdFromProps) return personIdFromProps;
  if (personIdFromParams) return parseInt(personIdFromParams, 10);
  if (personIdFromState) return personIdFromState;
  return 0;
};

const PersonLocations = ({ personId: personIdFromProps }: PersonLocationsProps) => {
  const { personId: personIdFromParams } = useAppParams();
  const { state, pathname } = useAppLocation();
  const personFromState = state?.person;

  const personId = getPersonId(personIdFromProps, personIdFromParams, personFromState?.id);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const personQuery = usePerson({
    id: personId,
    options: { enabled: !!personId },
  });

  const personData = personQuery.data;

  const name = personFromState?.name ?? personData?.personName ?? undefined;

  const imageUrl = personFromState?.imageUrl ?? personData?.imageUrl;
  const personLocation = personFromState?.location ?? personData?.location ?? [];

  const personLocations = usePersonLocationsQuery({
    personId,
    personLocation,
    translationFn: t,
  });

  const data = personLocations.data ?? [];

  // TODO: desk Any type
  const onDeskSelect = (desk: any) => {
    const pathname = generatePersonPath({
      floorId: desk.floorId,
      personId: desk.personId,
    });

    navigate(pathname, {
      state: {
        isFinalLocation: true,
        person: {
          ...personData,
        },
        searchSpaceId: desk?.location?.spaceId,
      },
    });
  };

  const renderHeader = () => {
    const closeLinkPath = removeSegmentFromPath(PATH_SEGMENT.LOCATIONS, pathname);

    if (personLocations.isLoading || personLocations.isError) {
      return (
        <DrawerHeader
          title={!!personFromState && name}
          subtitle={false}
          icon={!!personFromState && <Avatar source={imageUrl} name={name} />}
          closeLinkPath={closeLinkPath}
          closeIconA11yLabel="accessibilityLabels.closeLayout_personLocations"
          isCloseIcon
        />
      );
    }

    return (
      <DrawerHeader
        title={name}
        subtitle={false}
        icon={<Avatar source={imageUrl} name={name} />}
        closeLinkPath={closeLinkPath}
        closeIconA11yLabel="accessibilityLabels.closeLayout_personLocations"
        isCloseIcon
      />
    );
  };

  const renderContent = () => {
    if (personLocations.isLoading) {
      return (
        <ContentWrapper data-testid="loading-skeleton">
          <SearchItemSkeleton />
          <SearchItemSkeleton />
          <SearchItemSkeleton />
          <SearchItemSkeleton />
        </ContentWrapper>
      );
    }

    if (personLocations.isError) {
      return <EmptyCard iconName="info">{t('common.networkError')}</EmptyCard>;
    }

    return (
      <DrawerCardContent>
        <ContentWrapper data-testid="locations">
          {data.map((location, index: number) => (
            <DeskItem
              key={`${location.building}-${location.label}`}
              onPress={onDeskSelect}
              item={location}
              overrideText={{
                title: location.building,
                subtitle: `${replaceLineFeedWithSpace(location.label)}`,
              }}
              index={index}
            />
          ))}
        </ContentWrapper>
      </DrawerCardContent>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};

export default memo(PersonLocations);
