import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Nullable } from '@engage-shared/utils/types';
import { Store } from 'redux';

import { Token, TokenManagerSubscribeTypes, Tokens } from '@engage-shared/api/instance/interfaces';

type SubscribeListener = (type: string, token: Token) => void;

interface Config {
  storage: Nullable<Storage>;
  reduxStore: Nullable<Store>;
}

interface Storage {
  setTokens: ({ token, refreshToken }: { token: string; refreshToken: string }) => Promise<void>;
  getTokens: () => Promise<Tokens>;
  removeTokens: () => Promise<void>;
}

interface UserToken extends JwtPayload {
  id: string;
}

const SET: string = TokenManagerSubscribeTypes.SET;
const REVOKE: string = TokenManagerSubscribeTypes.REVOKE;

let reduxStore: Nullable<Store> = null;
let storage: Nullable<Storage> = null;
const subscribers: SubscribeListener[] = [];

const config = (tokenConfig: Config): void => {
  storage = tokenConfig?.storage;
  reduxStore = tokenConfig?.reduxStore;
};

const pushChangeToSubscribers = (type: string, token: Token): void => {
  subscribers.forEach(handler => {
    handler(type, token);
  });
};

const getSubscribers = (): SubscribeListener[] => {
  return subscribers;
};

const addSubscriber = (listener: SubscribeListener): void => {
  if (typeof listener === 'function') {
    subscribers.push(listener);
  }
};

const setTokens = async ({
  token,
  refreshToken,
}: {
  token: string;
  refreshToken: string;
}): Promise<boolean> => {
  if (storage) {
    await storage.setTokens({ token, refreshToken });
    pushChangeToSubscribers(SET, token);
    return true;
  }
  return false;
};

const getTokens = async (): Promise<Tokens> => {
  let tokens: Tokens = {
    token: null,
    refreshToken: null,
  };
  if (storage) {
    tokens = await storage.getTokens();
  }
  return tokens;
};

const removeTokens = async (): Promise<boolean> => {
  if (storage) {
    await storage.removeTokens();
    pushChangeToSubscribers(REVOKE, null);
    return true;
  }
  return false;
};

const getActiveTenantId = (): Nullable<string> => {
  let activeTenantId = null;
  if (reduxStore) {
    const tenant = reduxStore.getState().tenant;
    activeTenantId = tenant.activeTenantId || tenant.tenantId;
  }

  return activeTenantId;
};

const isValidToken = (token: Token): boolean => {
  if (!token) {
    return false;
  }
  const currentTime = new Date().getTime();
  const { exp } = jwtDecode<JwtPayload>(token);
  return (exp && exp * 1000 > currentTime) as boolean;
};

const getUserIdFromToken = (token: Token): Nullable<string> => {
  if (!token) {
    return null;
  }
  const { id } = jwtDecode<UserToken>(token);
  return id ?? null;
};

const subscribeToTokenManager = (listener: SubscribeListener): void => {
  addSubscriber(listener);
};

export {
  config,
  getSubscribers,
  getUserIdFromToken,
  isValidToken,
  removeTokens,
  getTokens,
  setTokens,
  subscribeToTokenManager,
  getActiveTenantId,
  SET,
  REVOKE,
};
