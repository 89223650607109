import React, { memo, useCallback, useEffect } from 'react';
import BookingCardSkeleton from './Card/BookingCardSkeleton';
import { useTranslation } from 'react-i18next';
import { EmptyCard } from '../../base';
import { MODALS, SEARCH_QUERY } from '../../../constants';
import { useOnNavigateBack } from '../../../utils/hooks/useOnNavigateBack';
import { useBookingDetails } from '../../../api/queries/useBookingDetails';
import { BookingCard } from './Card/BookingCard';
import {
  useShowLocation,
  useGoBackFromBooking,
} from '@engage-web/components/layouts/Booking/hooks';

type BookingProps = {
  event: {
    spaceId: number;
    meetingId: number;
    locationUnavailableWasDisplayed?: boolean;
  };
};
const Booking = ({ event }: BookingProps) => {
  const { t } = useTranslation();

  const { spaceId, meetingId } = event || {};
  const {
    data: booking,
    isFetching,
    isFetched,
    isSuccess,
    isIdle,
    isError,
  } = useBookingDetails(spaceId, meetingId);

  const { goBack, openModal } = useGoBackFromBooking({ booking });
  useOnNavigateBack(goBack);

  const addUrlSuffix = useCallback(
    (location: string) => {
      const { spaceId, isDesk } = booking || {};
      return `${location}?${isDesk ? SEARCH_QUERY.DESK : SEARCH_QUERY.SPACE}=${spaceId}`;
    },
    [booking],
  );

  const { showLocation } = useShowLocation({ booking, addUrlSuffix });

  const bookingQueryIsLoading = isFetching || !isFetched;
  const querySuccess = isSuccess && isFetched;
  const queryError =
    isIdle || // This only happens if a query is initialized with enabled: false.
    isError;

  const showLocationUnavailable = useCallback(() => {
    openModal(MODALS.BOOKING_LOCATION_UNAVAILABLE, { event }, { shouldClearBookingQuery: false });
  }, [event, openModal]);

  useEffect(() => {
    if (querySuccess && booking?.isInInactiveLocation && !event.locationUnavailableWasDisplayed) {
      showLocationUnavailable();
    }
  }, [
    booking?.isInInactiveLocation,
    event.locationUnavailableWasDisplayed,
    querySuccess,
    showLocationUnavailable,
  ]);

  if (bookingQueryIsLoading) {
    return <BookingCardSkeleton />;
  }

  if (querySuccess && !!booking) {
    return <BookingCard booking={booking} onBack={goBack} onShowLocation={showLocation} />;
  }
  if (queryError || (querySuccess && !booking)) {
    const error = t('common.networkError');
    return <EmptyCard iconName="info">{error}</EmptyCard>;
  }
  return null;
};

export default memo(Booking);
