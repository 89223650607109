import React, { useContext } from 'react';
import { InnerWrapper, StyledSpaceCard } from '../../styled';
import { ReserveCardContext } from '../ReserveCardContext';

export const ImageAndTitle = (props: any) => {
  const { item, spaceItem, hasOccupant, spaceStatusInfo } = useContext(ReserveCardContext);
  return (
    <InnerWrapper {...props}>
      {/* @ts-ignore TODO: resolve this type hell :) */}
      <StyledSpaceCard
        {...(item ?? spaceItem)}
        downloadableImageUrl={spaceItem.downloadableImageUrl}
        imageUrl={spaceItem.imageUrl}
        isReserved={hasOccupant}
        status={spaceStatusInfo?.label}
      />
    </InnerWrapper>
  );
};
