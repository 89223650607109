import { RESTRICT_TO_VALUES } from '@engage-shared/constants';
import { ParsedTeam } from '@engage-shared/api/teams';
import { ParsedSpaceItem } from '@engage-shared/api/spaces';
import { Nullable } from '@engage-shared/utils/types';
import { useGenerateCurrentLocationPath, useRestrictTo } from '@engage-web/utils/hooks';
import { joinPaths, removeSegmentFromPath } from '@engage-web/router/utils';
import { useAppLocation, useAppParams } from '@engage-web/router/hooks';
import { PATH_SEGMENT } from '@engage-web/constants';

/**
 * Returns reserve data obtained from the current location.
 */
type UseReserveRouteData = () => Nullable<{
  /**
   * Desk, space or team id.
   */
  id?: string;
  /**
   * Desk/space/team object from `location.state`.
   */
  data?: ParsedTeam | ParsedSpaceItem;
  /**
   * Pathname to be used in the close card link.
   * Generated from the current location by adding static segments
   * based on the current value of `restrictTo` value.
   */
  closeLinkPath: string;
  /**
   * Accessibility label for the close icon.
   */
  closeLinkA11yLabel: string;
  /**
   * Pathname returned from `useGenerateCurrentLocationPath`.
   */
  currentLocationPath: Nullable<string>;
  /**
   * This will be `true` if the current reservation is for a team.
   * Based on the current `restrictTo` value.
   */
  isTeam: boolean;
  /**
   * This will be `true` if the booking is being made for a desk.
   * Based on the current `restrictTo` value.
   */
  isDesk: boolean;
}>;

export const useReserveRouteData: UseReserveRouteData = () => {
  const restrictTo = useRestrictTo();
  const { state, pathname } = useAppLocation();
  const { deskId, spaceId, teamId } = useAppParams();

  const currentLocationPath = useGenerateCurrentLocationPath();
  const isDesk = restrictTo === RESTRICT_TO_VALUES.DESK;
  const isTeam = restrictTo === RESTRICT_TO_VALUES.TEAM;

  switch (restrictTo) {
    case RESTRICT_TO_VALUES.DESK:
      return {
        id: deskId,
        data: state?.desk,
        closeLinkPath: joinPaths(currentLocationPath, PATH_SEGMENT.DESKS),
        closeLinkA11yLabel: 'accessibilityLabels.closeLayout_deskReserve',
        isDesk,
        isTeam,
        currentLocationPath,
      };
    case RESTRICT_TO_VALUES.SPACE:
      return {
        id: spaceId,
        data: state?.space,
        closeLinkPath: joinPaths(currentLocationPath, PATH_SEGMENT.SPACES),
        closeLinkA11yLabel: 'accessibilityLabels.closeLayout_spaceReserve',
        isDesk,
        isTeam,
        currentLocationPath,
      };
    case RESTRICT_TO_VALUES.TEAM:
      return {
        id: teamId,
        data: state?.team,
        closeLinkPath: removeSegmentFromPath('reserve', pathname),
        closeLinkA11yLabel: 'accessibilityLabels.closeLayout_teamReserve',
        isDesk,
        isTeam,
        currentLocationPath,
      };
    default:
      return null;
  }
};
