import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { Api } from '@engage-shared/api';
import { getApiUrl } from '@engage-shared/api';
import { KioskRemoteConfig } from './types';

export const getKioskConfigQueryKey = (id: number) => ['kioskConfig', id];

const fetchKioskConfig = async (id: number): Promise<KioskRemoteConfig> => {
  const url = new URL(`${getApiUrl()}/kiosks/${id}`);
  const result = await Api.get(url.toString());
  return result.data.data as KioskRemoteConfig;
};

export type UseKioskConfig = (params: {
  id: number;
  options?: UseQueryOptions<KioskRemoteConfig>;
}) => UseQueryResult<KioskRemoteConfig>;

export const useKioskConfig: UseKioskConfig = ({ id, options = {} }) =>
  useQuery<any>({
    queryKey: getKioskConfigQueryKey(id),
    queryFn: () => fetchKioskConfig(id),
    ...options,
  });
