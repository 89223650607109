import { userConfigActions, floorplanActions } from '@engage-web/store';
import { VISIBILITY } from '@engage-web/constants';
import { ParsedSpaceItem } from '@engage-shared/api';

interface HandleSocialDistancingParams {
  dispatch: any;
  spaceItem: ParsedSpaceItem;
  showSocialDistancingInfo: boolean;
}
const handleSocialDistancing = ({
  dispatch,
  spaceItem,
  showSocialDistancingInfo,
}: HandleSocialDistancingParams) => {
  dispatch(floorplanActions.setReservedSpace(spaceItem));

  if (!showSocialDistancingInfo) {
    dispatch(userConfigActions.setShowSocialDistancingInfo(VISIBILITY.SHOW));
  }
};

export { handleSocialDistancing };
