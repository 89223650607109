import { useQuery, UseQueryOptions } from 'react-query';
import { USER_CONFIG_QUERY_KEY } from '../constants';
import { fetchUserConfig } from '../fetch';
import { UserConfig } from '../interfaces';
import { UseQueryResult } from 'react-query/types';

type UseUserConfigParams = {
  options?: UseQueryOptions<UserConfig>;
};

export const useUserConfig = ({ options = {} }: UseUserConfigParams): UseQueryResult<UserConfig> =>
  useQuery<UserConfig>(
    USER_CONFIG_QUERY_KEY,
    fetchUserConfig,
    // fetch user config only once (ideally upon authentication)
    // to avoid extra loading states which may cause acknowledgement
    // screen to appear again after closing it
    { refetchOnMount: false, staleTime: 120000, ...options },
  );
