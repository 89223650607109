import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globalSearchSelectors, tenantSelectors } from '../../../store';
import { GlobalSearchResults } from './GlobalSearchResults';
import { GlobalSearchWrapper, Wrapper } from './styled';
import {
  GlobalSearchFavourites,
  GlobalSearchFilter,
  GlobalSearchInput,
  RestrictToSearchFilter,
} from '../../base';
import DrawerHeader from '../../scenes/drawer/header/DrawerHeader/DrawerHeader';
import { DrawerCardContent } from '../../scenes/drawer/DrawerCardContent';
import { useRestrictTo } from '../../../utils/hooks';
import { isElectron } from '../../../utils/electron';
import { RESTRICT_TO_VALUES } from '@engage-shared/constants';

export const GlobalSearch = () => {
  const [showToggleFavourite, setShowToggleFavourite] = useState(false);

  const { hidePeopleData } = useSelector(tenantSelectors.getKioskConfig);

  // hide Favorite button for kiosks
  const isKiosk = isElectron();

  const restrictTo = useRestrictTo();

  useEffect(() => {
    setShowToggleFavourite(false);
  }, [restrictTo]);

  const hasSearchValue = useSelector(globalSearchSelectors.getHasUserInput);

  const toggleShowFavourite = useCallback(() => {
    setShowToggleFavourite(prevShowToggleFavourite => !prevShowToggleFavourite);
  }, []);

  const showFilters = restrictTo && restrictTo !== RESTRICT_TO_VALUES.TEAM;
  const showResults = hasSearchValue || !!restrictTo;

  return (
    <>
      <DrawerHeader />
      <DrawerCardContent>
        <GlobalSearchWrapper $showResults={showResults}>
          {!hidePeopleData && <GlobalSearchInput />}
          <RestrictToSearchFilter />
          {showFilters && (
            <Wrapper>
              {!isKiosk && (
                <GlobalSearchFavourites
                  showToggleFavourite={showToggleFavourite}
                  toggleFavourites={toggleShowFavourite}
                />
              )}
              <GlobalSearchFilter />
            </Wrapper>
          )}
          {showResults && (
            <div aria-live="polite" aria-atomic="true">
              <GlobalSearchResults showToggleFavourite={showToggleFavourite} />
            </div>
          )}
        </GlobalSearchWrapper>
      </DrawerCardContent>
    </>
  );
};

export default GlobalSearch;
