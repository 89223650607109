import {
  BookingsFailedMessages,
  BookingsSuccessMessages,
} from '@engage-shared/api/bookings/interfaces';
import { DateFormat, formatLocalizedDate } from '@engage-shared/utils/dates';
import { AxiosError } from 'axios';
import { LanguageTypes } from '@engage-shared/constants/languages';

type AppendDays = (daysString: string, formattedDay: string) => string;
const appendDays: AppendDays = (daysString, formattedDay) => {
  daysString += `${daysString ? ', ' : ''}${formattedDay}`;
  return daysString;
};

type HandleSuccessBookingMessagesParams = {
  selectedDay: Date;
  timeZone: string;
  bookingsSuccessMessages: BookingsSuccessMessages;
  locale: LanguageTypes;
};
type HandleSuccessBookingMessages = (params: HandleSuccessBookingMessagesParams) => void;
/**
 * Handle success bookings messages.
 * @param selectedDay
 * @param timeZone
 * @param bookingsSuccessMessages
 * @param locale
 */
export const handleSuccessBookingMessages: HandleSuccessBookingMessages = ({
  selectedDay,
  timeZone,
  bookingsSuccessMessages,
  locale,
}) => {
  const formattedDay = formatLocalizedDate(selectedDay, {
    timeZone,
    format: DateFormat.weekDayShort,
    locale,
  });
  const successfullyReservedDaysString = bookingsSuccessMessages.successfullyReservedDaysMessage;
  bookingsSuccessMessages.successfullyReservedDaysMessage = appendDays(
    successfullyReservedDaysString,
    formattedDay,
  );
  bookingsSuccessMessages.successfullyReserved = true;
};

type HandleFailedBookingMessagesParams = {
  error: AxiosError;
  selectedDay: Date;
  timeZone: string;
  bookingsFailedMessages: BookingsFailedMessages;
  locale: LanguageTypes;
};
type HandleFailedBookingMessages = (params: HandleFailedBookingMessagesParams) => void;
/**
 * Handle failed booking messages.
 * @param error
 * @param selectedDay
 * @param timeZone
 * @param bookingsError
 * @locale locale
 */
export const handleFailedBookingMessages: HandleFailedBookingMessages = ({
  error,
  selectedDay,
  timeZone,
  bookingsFailedMessages,
  locale,
}) => {
  const formattedDay = formatLocalizedDate(selectedDay, {
    timeZone,
    format: DateFormat.weekDayShort,
    locale,
  });

  // @ts-ignore
  const errorData = error?.response?.data?.error;
  if (
    errorData?.length > 0 &&
    errorData[0] === 'Desk cannot be booked, concurrent booking limit reached.'
  ) {
    const concurrentDaysString = bookingsFailedMessages.concurrentDaysMessage;
    bookingsFailedMessages.concurrentDaysMessage = appendDays(concurrentDaysString, formattedDay);
  } else if (
    errorData?.length > 0 &&
    errorData[0] ===
      'Desk booking should not exceed the building desk bookings per 15 minutes limitation'
  ) {
    const limitPerIntervalString = bookingsFailedMessages.limitPerIntervalMessage;
    bookingsFailedMessages.limitPerIntervalMessage = appendDays(
      limitPerIntervalString,
      formattedDay,
    );
  } else if (
    errorData?.length > 0 &&
    errorData[0] === 'Desk booking should not exceed the building desk bookings per day limitation'
  ) {
    const limitPerBookingString = bookingsFailedMessages.limitPerBuildingMessage;
    bookingsFailedMessages.limitPerBuildingMessage = appendDays(
      limitPerBookingString,
      formattedDay,
    );
  } else {
    const failedDaysString = bookingsFailedMessages.failedDaysMessage;
    bookingsFailedMessages.failedDaysMessage = appendDays(failedDaysString, formattedDay);
  }
};
