import { TFunction as TranslateFunction } from 'i18next';
import { PRESENCE_STATUS_LOCALIZED, PresenceStatus } from '@engage-shared/constants/person';

type LocalizePersonSVLivePresence = {
  status: PresenceStatus;
  t: TranslateFunction;
};

/**
 * Localize SVLive presence status
 * @param t - translation function
 * @param status - presence status
 */
export const localizePersonSVLivePresence = ({
  t,
  status,
}: LocalizePersonSVLivePresence): string | null => {
  // ignore offline status
  if (status === PresenceStatus.OFFLINE) {
    return null;
  }

  return t(PRESENCE_STATUS_LOCALIZED[status]!);
};
