import React, { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDialog } from '@engage-web/utils';
import { KioskCountdownDialog } from '@engage-web/components/kiosk';
import { useConciergeMenuAllowed } from '@engage-web/components/scenes/ConciergeMenu/hooks/useConciergeMenuAllowed';
import { useNavigate, matchPath } from 'react-router-dom';
import { CONCIERGE_MENU_PATH, FLOOR_PATH, LANDING_PATH } from '@engage-web/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  filtersActions,
  globalSearchActions,
  tenantActions,
  tenantSelectors,
} from '@engage-web/store';
import { useAppLocation, useAppParams, useFloorCheck } from '@engage-web/router/hooks';

const DEFAULT_IDLE_TIMEOUT = 20;
const DEFAULT_DIALOGUE_SECONDS = 20;

const useIdleDialog = () => {
  const {
    idleDialogueShowTimeout,
    idleDialogueSeconds,
    userLocation: defaultKioskLocation,
  } = useSelector(tenantSelectors.getKioskConfig);

  const { openDialog, isOpen, closeDialog } = useDialog<void>();
  const { conciergeMenuAllowed } = useConciergeMenuAllowed();
  const { pathname } = useAppLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLocation = useAppParams();
  const { sameLocation } = useFloorCheck();
  const isFloorPath = matchPath(FLOOR_PATH, pathname) !== null;

  const isUserChangedLocation = !sameLocation(currentLocation.floorId, defaultKioskLocation);

  const onTimeIsOver = useCallback(() => {
    closeDialog();
    dispatch(globalSearchActions.clearSearchValue());
    // @ts-ignore
    dispatch(filtersActions.clearFilterValues());
    dispatch(tenantActions.loadCurrentLocationFromKioskConfig());
    const route = conciergeMenuAllowed ? CONCIERGE_MENU_PATH : LANDING_PATH;
    navigate(route);
  }, [closeDialog, conciergeMenuAllowed, dispatch, navigate]);

  const onIdle = useCallback(() => {
    if (isOpen) {
      return;
    }

    if (isFloorPath && !conciergeMenuAllowed && !isUserChangedLocation) {
      // do kiosk reset but without showing the dialog
      onTimeIsOver();
      return;
    }
    openDialog(
      <KioskCountdownDialog
        onContinuePress={closeDialog}
        onTimeIsOver={onTimeIsOver}
        onExitPress={onTimeIsOver}
        seconds={idleDialogueSeconds ?? DEFAULT_DIALOGUE_SECONDS}
      />,
    );
  }, [
    closeDialog,
    conciergeMenuAllowed,
    idleDialogueSeconds,
    isOpen,
    onTimeIsOver,
    openDialog,
    isFloorPath,
  ]);

  useIdleTimer({
    onIdle,
    // idleDialogueShowTimeout is configured in seconds, need to convert it to milliseconds
    timeout: idleDialogueShowTimeout * 1000 ?? DEFAULT_IDLE_TIMEOUT,
  });
};

export default useIdleDialog;
