import styled from 'styled-components';

interface ContainerProps {
  $hasFocus: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  height: 50px;
  border: 1px solid ${props => props.theme.color4};
  box-shadow: ${props => (props.$hasFocus ? '0 0 0 2px #005FFF' : 'none')};
  border-radius: 25px;
`;

const Domain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eff2f6;
  transform: rotateY(${({ theme }) => (theme.isLayoutRTL ? '180deg' : '0')});
  direction: ${({ theme }) => (theme.isLayoutRTL ? 'rtl' : 'ltr')};
  border-radius: 25px;
  height: 100%;
  padding: 0 16px;
`;

const WorkspaceInput = styled.input`
  width: 100%;
  height: 90%;
  margin: 0 6px 0 25px;
  font-size: 12pt;
  outline: none;
  border: none;
`;

export { Container, Domain, WorkspaceInput };
