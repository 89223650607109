import styled from 'styled-components';
import React from 'react';
import { DEFAULT_FLOORPLAN_SCALE } from '../../../constants';
import { RadarPing } from '../../../components';
import { useTheme } from '../../../theme/globalTheme';

const DoubleCircle = styled.circle<{ r: number }>`
  fill: ${({ theme }) => theme.blue1};
  stroke: white;
  stroke-width: ${({ r }) => r / 3}px;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.7));
`;

type FocusMarkerProps = {
  coordinate: { x: number; y: number };
  scale?: number;
};

export const FocusMarker = ({
  coordinate: { x, y },
  scale = DEFAULT_FLOORPLAN_SCALE,
}: FocusMarkerProps) => {
  const { blue1 } = useTheme();
  const radius = 35;

  return (
    <>
      <RadarPing coordinate={{ x, y }} pointSize={radius + 5} repeat="infinite" fill={blue1} />
      <DoubleCircle cx={x} cy={y} r={Math.ceil((radius * DEFAULT_FLOORPLAN_SCALE) / scale)} />
    </>
  );
};
