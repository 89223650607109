import { FloorPermissions } from '@engage-shared/api/floors/interfaces';
import { Api, getApiUrl } from '@engage-shared/api/instance';

type FetchFloorPermissions = (floorId: number) => Promise<FloorPermissions>;

/**
 * Retrieves all permissions for passed floor id
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | floor found |
 *
 * @param floorId - id of the floor
 */
export const fetchFloorPermissions: FetchFloorPermissions = async floorId => {
  const path = `floors/${floorId}/permissions`;
  const url = new URL(`${getApiUrl()}/${path}`);
  const { data } = await Api.get(url.toString());
  return data.data;
};
