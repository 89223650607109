import { AvailabilityBarWrapper } from '@engage-web/components/layouts/Reserve/Card/styled';
import { FixSizeAvailabilityBar } from '@engage-web/components/base';
import { ReserveDuration } from '@engage-web/components/layouts/Reserve/Card/ReserveDuration';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useReservationFilters from '@engage-web/utils/hooks/useReservationFilters';
import { getReservationStartDate } from '@engage-shared/utils';
import { useBuildingReservationLimit } from '@engage-shared/api';
import { trackReservationAnalytics } from '@engage-web/utils/analytics/useReserveAnalytics';
import { RESERVATION_ANALYTICS_TYPE as ANALYTICS_TYPE } from '@engage-web/constants';
import { getHasBookingsForTimeInterval } from '../../reserveCardHelpers';
import { DurationObj } from '../types';
import { ReserveCardContext } from '../ReserveCardContext';

const HALF_HOUR = 60 * 1000 * 30;

type DurationAndAvailabilityProps = { showButtons?: boolean };

export const DurationAndAvailability = ({ showButtons = true }: DurationAndAvailabilityProps) => {
  const {
    spaceItem,
    isTeam,
    timeZone,
    durationObj,
    setDurationObj,
    bookedIntervals,
    isOccupied,
    reserveButtonRef,
  } = useContext(ReserveCardContext);
  const { id: spaceId, isDesk } = spaceItem;
  const { duration, allDayDuration } = durationObj;

  const reservationFilter = useReservationFilters(isDesk);
  const { dateStart: filterDateStart, bookingIntervalSize } = reservationFilter;
  const startTime = filterDateStart ? filterDateStart.getTime() : Date.now();

  const shouldDisableDurationBtn = isTeam ? false : isOccupied;

  const [hasLimitPer15Minutes, setHasLimitPer15Minutes] = useState(false);

  const { hasBuildingLimitPer15Minutes } = useBuildingReservationLimit();
  useEffect(() => {
    if (isDesk) {
      const checkBuildingHasBookingLimit = async () => {
        const buildingHasBookingLimit = await hasBuildingLimitPer15Minutes({
          buildingId: spaceItem.buildingId,
          dateStart: filterDateStart,
        });
        setHasLimitPer15Minutes(buildingHasBookingLimit);
      };

      checkBuildingHasBookingLimit();
    }
  }, [filterDateStart, hasBuildingLimitPer15Minutes, isDesk, spaceItem.buildingId]);

  const allDayReserveStart = getReservationStartDate({
    dateStart: filterDateStart,
    selectedDay: null,
    bookingIntervalSize,
    hasLimitPer15Minutes,
    allDay: true,
    timeZone,
  });
  // for hourly reservations Entire Day start time can ignore filterDateStart value in some cases
  // and become start of the day
  // so we need to calculate this date-time value and check if there are any
  // bookings for this interval, if bookings exists - disable Entire Day button.
  const hasBookingsForAllDayReservation = getHasBookingsForTimeInterval({
    bookedIntervals,
    start: allDayReserveStart,
  });

  const shouldDisableAllDayBtn = isTeam ? false : hasBookingsForAllDayReservation;

  // to delay scrolling while the button is rendered
  const debouncedScrollToReserveButtonBottom = useDebouncedCallback(() => {
    // @ts-ignore
    reserveButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, 200);

  const onAllDayToggle = useCallback(() => {
    // only deselect all day button when other duration is selected
    const payload = { allDayDuration: true, duration: 0 };
    setDurationObj((state: DurationObj) => ({ ...state, ...payload }));

    trackReservationAnalytics({
      spaceItem,
      durationPayload: payload,
      analyticsType: ANALYTICS_TYPE.DURATION_CHANGED,
    });
  }, [setDurationObj, spaceItem]);

  const onChangeDuration = useCallback(
    (duration: number) => {
      const payload = {
        duration,
        allDayDuration: duration === 0,
      };
      trackReservationAnalytics({
        spaceItem,
        durationPayload: payload,
        analyticsType: ANALYTICS_TYPE.DURATION_CHANGED,
      });
      setDurationObj((state: DurationObj) => ({ ...state, ...payload }));
    },
    [setDurationObj, spaceItem],
  );

  return (
    <>
      {!isTeam && (
        <AvailabilityBarWrapper>
          <FixSizeAvailabilityBar
            bookingIntervalSize={bookingIntervalSize}
            barSize={300}
            start={startTime - HALF_HOUR}
            startAvailable={startTime}
            bookedIntervals={bookedIntervals}
            timeZone={timeZone}
          />
        </AvailabilityBarWrapper>
      )}

      {showButtons && (
        <ReserveDuration
          spaceId={spaceId}
          isDesk={isDesk}
          isTeam={isTeam}
          duration={duration}
          occupied={shouldDisableDurationBtn}
          allDayDuration={allDayDuration}
          allDayDisabled={shouldDisableAllDayBtn}
          onAllDayToggle={onAllDayToggle}
          onChangeDuration={onChangeDuration}
          onDurationOtherClick={debouncedScrollToReserveButtonBottom}
        />
      )}
    </>
  );
};
