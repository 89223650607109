import {
  DrawableFloorMarker,
  FloorData,
  FloorLocation,
  FloorMarker,
  LocationType,
} from '@engage-shared/api/floors/interfaces';
import { Marker } from '@engage-shared/api/spaces';

/**
 * Sort floors by sequence descendent.
 * @param a
 * @param b
 */
export const sortFloors = (a: FloorData, b: FloorData): number =>
  a.sequence > b.sequence ? -1 : 1;

/**
 * Returns parsed floor location
 * @param floorRegionsAndBuilding
 * @returns { regionIds[], building:FloorLocation }
 */
export const mapFloorRegionsAndBuilding = (
  floorRegionsAndBuilding: FloorLocation[],
): { regionIds: number[]; building: FloorLocation | undefined } => {
  return {
    regionIds: floorRegionsAndBuilding.filter(r => r.type === LocationType.Region).map(r => r.id),
    building: [...floorRegionsAndBuilding].pop(),
  };
};

/**
 * Recursive function that traverse markers tree looking for appropriate marker by passed id
 * @param typeId - marker type id
 * @param markers - markers sub-tree to make search in
 */
const findGlobalMarkerObject = (typeId: number, markers: Marker[]): Marker | undefined => {
  for (const marker of markers) {
    if (marker.id === typeId) {
      return marker;
    }

    const found = findGlobalMarkerObject(typeId, marker.children);
    if (found) {
      return found;
    }
  }

  return undefined;
};

/**
 * Maps floor marker to global marker, adding properties icon and isPointType of found global marker
 * @param floorMarkers - floor-based markers array
 * @param allMarkers - tree of all instance markers
 */
export const mapDrawableFloorMarkers = (
  floorMarkers: FloorMarker[],
  allMarkers: Marker[],
): DrawableFloorMarker[] => {
  // cache
  const markersCache = new Map<number, Marker>();

  return floorMarkers.map(floorMarker => {
    const globalMarker =
      markersCache.get(floorMarker.typeId) ||
      findGlobalMarkerObject(floorMarker.typeId, allMarkers);

    if (globalMarker) {
      markersCache.set(floorMarker.typeId, globalMarker);
      const { isPointType, icon } = globalMarker;

      return {
        ...floorMarker,
        isPointType,
        icon,
      };
    }

    return floorMarker;
  });
};
