import { config } from './config';
import { AuthApi, getApiUrl } from '@engage-shared/api/instance';
import {
  ServerData,
  TenantData,
  TenantId,
  TenantParsedData,
} from '@engage-shared/api/tenant/interfaces';
import { getDefaultTheme } from '@engage-shared/theme';
import { LanguageTypes } from '@engage-shared/constants/languages';
import { API_TIMEOUT } from '@engage-shared/constants';

const getConfigOption = (
  configId: number,
  serverConfigData: ServerData[],
): ServerData | undefined => Object.values(serverConfigData).find(({ id }) => id === configId);

const parseData = (data: ServerData[]): TenantParsedData =>
  Object.keys(config).reduce(
    (
      acc: {
        [index: string]: string;
      },
      key: string,
    ) => {
      const { configMethod, defaultValue, getValue } = config[key];
      const option = getConfigOption(Number(key), data);
      const value = option?.value;
      acc[configMethod] = value && getValue ? getValue(value) : defaultValue;
      return acc;
    },
    {},
  );

export const parseTenantData = (data: ServerData[]): TenantParsedData => {
  const parsedData = parseData(data);
  const brandTheme = getDefaultTheme(
    parsedData?.brandColor,
    parsedData?.floorPlanAvailableColor,
    parsedData?.useFloorPlanAvailableColor,
  );
  return {
    parsedData,
    brandTheme,
  };
};

/**
 * Fetch tenant config data and parse it to return config and theme.
 * @param tenantId
 * @param locale
 */
export const fetchTenant = async (
  tenantId: TenantId,
  locale?: LanguageTypes,
): Promise<TenantData> => {
  const url = new URL(`${getApiUrl(tenantId)}/tenant_config`);
  url.searchParams.set('locale', locale ?? LanguageTypes.enUS);
  const response = await AuthApi.get(url.toString(), { timeout: API_TIMEOUT });
  const { parsedData, brandTheme } = parseTenantData(response.data.data);

  return {
    tenantId,
    config: parsedData,
    theme: brandTheme,
  };
};
