import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { testProps } from '@engage-web/utils';
import { Icon } from '../Icons/Icon';
import { FavouritesButton } from './styled';

type GlobalSearchFavouritesProps = {
  toggleFavourites: () => void;
  showToggleFavourite: boolean;
};

const GlobalSearchFavourites = ({
  toggleFavourites,
  showToggleFavourite,
}: GlobalSearchFavouritesProps) => {
  const { t } = useTranslation();

  return (
    <FavouritesButton
      onClick={toggleFavourites}
      $favouritesActive={showToggleFavourite}
      {...testProps(t, 'layout.globalSearch.favourites').props}
      name={t('accessibilityLabels.selectGlobalSearchFavourites')}
    >
      <Icon name="star" size={18} />
    </FavouritesButton>
  );
};

export default memo(GlobalSearchFavourites);
