import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GetBookingsFetcherResponse } from '@engage-shared/api/bookings';
import { useBookings, useSpace, useUser } from '../../../../api/queries';
import { inAppNotificationsActions, userConfigSelectors } from '../../../../store';
import { useReserveAnalytics } from '../../../../utils/analytics/useReserveAnalytics';
import { FocusId, useFocus } from '../../../../utils/hooks';
import logger from '../../../../utils/logger';
import {
  ParsedSpaceItem,
  ParsedUserData,
  SpaceTypesData,
  useSpaceAttributesQuery,
} from '@engage-shared/api';

const SENSOR_REFETCH_INTERVAL = 1000 * 60; // one minute

type UseReserveReturnValue = {
  isLoading: boolean;
  spaceItem?: ParsedSpaceItem & { downloadableImageUrl: string };
  bookingsData?: GetBookingsFetcherResponse;
  spaceAttributes?: SpaceTypesData;
  userData?: ParsedUserData;
};

export function useReserveQueries(spaceId: string | undefined): UseReserveReturnValue {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  // TODO: what to do when spaceId is null?
  const id = spaceId ? parseInt(spaceId, 10) : 0;

  // space query
  const spaceQuery = useSpace({
    id,
    options: {
      enabled: !!spaceId,
      refetchInterval: SENSOR_REFETCH_INTERVAL,
    },
  });
  const { data: spaceItem } = spaceQuery;

  // bookings query
  const onAvailabilityError = (error: unknown) => {
    logger.error(error)();

    // TODO: Check if this is type Axios error
    // @ts-ignore
    const status = error?.response?.status;
    if (status === 422) {
      // do not display the availability error message when status is 422
      return;
    }

    const spaceType = spaceItem?.type;
    // @ts-ignore
    const errorMsg = error?.response?.data?.error;
    const uiMessageRef =
      // @ts-ignore
      status && AVAILABILITY_MESSAGES[spaceType][status]
        ? // @ts-ignore
          AVAILABILITY_MESSAGES[spaceType][status][errorMsg] ||
          // @ts-ignore
          AVAILABILITY_MESSAGES[spaceType][status].default
        : // @ts-ignore
          AVAILABILITY_MESSAGES[spaceType].default;

    dispatch(
      inAppNotificationsActions.addErrorNotification({
        message: t(uiMessageRef),
      }),
    );
  };

  const bookingsQuery = useBookings({
    spaceItem,
    options: {
      retry: (failureCount: number, error: unknown) => {
        if (error instanceof AxiosError) {
          const status = error?.response?.status;
          // do not retry fetching if status is 422
          return status !== 422;
        }
        return failureCount < 2;
      },
      enabled: !!spaceItem,
      onError: onAvailabilityError,
      refetchInterval: SENSOR_REFETCH_INTERVAL,
    },
  });
  const bookingsData = bookingsQuery.data;

  // space attributes query
  const spaceAttributesQuery = useSpaceAttributesQuery({
    spaceId: Number(spaceId),
    locale,
  });
  const spaceAttributes = spaceAttributesQuery.data;

  // user query
  const userQuery = useUser();
  const userData = userQuery.data;

  const isLoading =
    spaceQuery.isLoading ||
    bookingsQuery.isLoading ||
    userQuery.isLoading ||
    spaceAttributesQuery.isLoading;

  useReserveAnalytics(spaceItem);

  useFocus({ focusOn: FocusId.DrawerCloseButton });

  return {
    isLoading,
    bookingsData,
    spaceAttributes,
    userData,
    spaceItem,
  };
}
