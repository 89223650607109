import { QueryKey } from 'react-query/types/core/types';
import { InfiniteData } from 'react-query';
import { SearchResponse } from '@engage-shared/api/search/interfaces';

export enum FavouriteType {
  desk = 'desk',
  space = 'space',
  person = 'person',
  team = 'team',
}

export type FavouriteMutationBody = {
  id: number;
  type: FavouriteType;
  isCurrentlyFavourite?: boolean;
};

export type FavouriteMutationParams = FavouriteMutationBody & {
  isCurrentlyFavourite: boolean;
};

export type FavouriteMutationContext = {
  searchQueryKey?: QueryKey;
  searchResults?: InfiniteData<SearchResponse>;
};
