import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Title = styled.h3`
  font-size: 14px;
  padding: 20px 20px 0px 20px;
  font-weight: bold;
  line-height: 20px;
  color: ${({ theme }) => theme.typo2};
  opacity: 0.5;
`;

type TeamsSectionHeaderProps = {
  title: string;
  style?: CSSProperties;
};

const TeamsSectionHeader = ({ title, style }: TeamsSectionHeaderProps) => {
  const { t } = useTranslation();

  return <Title style={style}>{t(title)}</Title>;
};

TeamsSectionHeader.getSize = () => 50;

export default TeamsSectionHeader;
