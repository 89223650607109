import {
  countAppliedPersonFilters,
  PersonFilters,
} from '@engage-shared/utils/search/countAppliedPersonFilters';
import {
  countAppliedSpaceFilters,
  FilterValues,
} from '@engage-shared/utils/search/countAppliedSpaceFilters';

type SearchFilters = {
  filterValues: FilterValues;
  personFilters: PersonFilters;
};

type CountAppliedFilters = ({ searchFilters }: { searchFilters: SearchFilters }) => number;

/**
 * Calculation of all applied filters number
 * @param searchFilters Object
 * @return {number} number of applied filters
 */
export const countAppliedFilters: CountAppliedFilters = ({ searchFilters }) => {
  const { filterValues, personFilters } = searchFilters;

  const { fireWarden, firstAidOfficer } = personFilters;
  const isPersonFilter = fireWarden || firstAidOfficer;

  if (isPersonFilter) {
    return countAppliedPersonFilters(personFilters);
  }

  return countAppliedSpaceFilters(filterValues);
};
