import React, { useEffect, useMemo, useState } from 'react';
import { monkeyPatchOpenSeaDragon, FloorplanHandler as Floorplan } from '@floorplan/base';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { FilteredSpaces, useFloorplanSpacesData, useZoomDescriptorData } from '@floorplan/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  filtersSelectors,
  floorplanSelectors,
  teamReservationsSelectors,
  tenantSelectors,
} from '@engage-web/store';
import { useFesMessageHandler } from '@engage-web/components/new-floorplan/useFesMessageHandler';
import {
  useConfigApi,
  useDrawableMarkers,
  useOnClickOutsideHandlers,
  useSetFocusedSpace,
  useSvLivePersonLocation,
  useTeamReservationHandlers,
  useShowHeatmap,
  useShowKioskHereMarker,
  useFloorplanPersonMarker,
} from './hooks';
import { useFilterTimes } from '@engage-shared/utils';
import { useConfig, useCurrentTimeZone } from '@engage-web/utils';
import { OverlayFloorplan } from '@engage-web/components/new-floorplan/styles';
import { SpaceType } from '@engage-shared/api';
import { useTheme } from 'styled-components';
import { temporarySetSpaceData } from '@engage-web/components/floorplan/store/actions';
import ResetViewButton from './ResetViewButton/ResetViewButton';
import { Space } from '@floorplan/api';
import { LoadingButton } from './LoadingButton/LoadingButton';
import { useTranslation } from 'react-i18next';

monkeyPatchOpenSeaDragon();

interface ISpaces {
  spaces: Space[];
  filteredSpaces: FilteredSpaces;
  floorId: number;
}

export const FloorplanContainer = () => {
  const dispatch = useDispatch();
  const fes = useFESContext();
  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const teamBookingId = useSelector(teamReservationsSelectors.getTeamBookingId);
  const { hidePeopleData } = useSelector(tenantSelectors.getKioskConfig);
  const showOverlay = useSelector(floorplanSelectors.getShouldShowOverlay);
  const theme = useTheme();
  const { t } = useTranslation();

  const [floorplanSpaces, setFloorplanSpaces] = useState<ISpaces>();

  useConfigApi();

  const { dateStart, dateEnd } = useSelector(filtersSelectors.getFilterDates);
  const { enforceAllDayDeskReservations } = useConfig();
  const timeZone = useCurrentTimeZone();

  const { startTime, endTime, roomsStartTime, roomsEndTime } = useFilterTimes({
    dateStart,
    dateEnd,
    timeZone,
    enforceAllDayDeskReservations,
    // We need to hardcode SpaceType.DESK because times will be changed based on filters
    // and we don't want this behaviour. In this case time filters will only be controlled by
    // config.enforceAllDayReservations
    restrictTo: SpaceType.DESK,
  });

  const { data: zoomDescriptor, isLoading: zoomDescriptorLoading } = useZoomDescriptorData({
    floorId,
  });
  const {
    data: spacesData,
    refetch: refetchFloorplanSpacesData,
    isLoading: isSpacesLoading,
  } = useFloorplanSpacesData({
    floorId,
    teamBookingId,
    startTime,
    endTime,
    roomsStartTime,
    roomsEndTime,
  });

  useEffect(() => {
    refetchFloorplanSpacesData();
  }, [dateStart]);

  useEffect(() => {
    // TODO: this is e temporary fix, we will need to extract floorplan space data
    // from floorplan store and use it based on useFloorplanData cached query
    if (spacesData) {
      dispatch(temporarySetSpaceData(spacesData, floorId));
      setFloorplanSpaces(spacesData);
    }
  }, [spacesData]);

  const floorplanData = useMemo(
    () => ({ zoomDescriptor, ...(floorplanSpaces ?? {}), floorId }),
    [zoomDescriptor, floorplanSpaces, floorId],
  );

  const { onMessage, listenersInitialized } = useFesMessageHandler({
    floorplanData,
    startTime,
    endTime,
  });

  React.useEffect(() => {
    if (!listenersInitialized) return;
    fes.trigger(FES_EVENTS.SET_ZOOM_DESCRIPTOR_LOADING_STATE, zoomDescriptorLoading);
  }, [zoomDescriptorLoading, listenersInitialized]);

  const { useZoomTiles: zoomTilesShown, highlightReservedDesks: reservedAlwaysEnabled } =
    useConfig();

  React.useEffect(() => {
    if (!listenersInitialized) return;
    const options = {
      zoomTilesShown: zoomTilesShown,
      reservedAlwaysEnabled: reservedAlwaysEnabled,
      labelOptions: {
        showOccupantLabels: true,
        showSpaceNameLabels: true,
        showSpaceTypeLabels: false,
        labelFontSize: 10,
      },
    };
    // * set options
    fes.trigger(FES_EVENTS.INIT_OPTIONS, options);
    // * init data
    fes.trigger(FES_EVENTS.INIT_DATA, { ...floorplanData, theme });
    // * set available dates
    fes.trigger(FES_EVENTS.SET_AVAILABILITY_DATE, { startTime, endTime });
    // * toggle views
    fes.trigger(FES_EVENTS.TOGGLE_VIEW, {
      all: true,
      showRooms: false,
      showDesks: false,
      showOccupants: false,
    });

    fes.trigger(FES_EVENTS.FLOORPLAN_LOADED);
  }, [
    fes,
    listenersInitialized,
    floorId,
    startTime,
    endTime,
    floorplanData.zoomDescriptor,
    floorplanData.spaces,
    floorplanData.filteredSpaces,
  ]);

  const personMarker = useFloorplanPersonMarker();

  React.useEffect(() => {
    if (personMarker) {
      fes.trigger(FES_EVENTS.SET_SELECTED_PERSON, { person: personMarker });
      fes.trigger(FES_EVENTS.INIT_OPTIONS, {
        labelOptions: {
          showOccupantLabels: true,
          showSpaceNameLabels: true,
        },
      });
    } else {
      fes.trigger(FES_EVENTS.SET_SELECTED_PERSON, { person: null });
      fes.trigger(FES_EVENTS.INIT_OPTIONS, {
        labelOptions: {
          showOccupantLabels: true,
          showSpaceNameLabels: true,
        },
      });
    }
  }, [personMarker]);

  useDrawableMarkers({ listenersInitialized });
  useOnClickOutsideHandlers();
  useSetFocusedSpace();
  useSvLivePersonLocation();
  useTeamReservationHandlers();
  useShowHeatmap();
  useShowKioskHereMarker();

  fes.setMessageHandler(onMessage);

  const isLoading = zoomDescriptorLoading || isSpacesLoading;
  const loadingText = floorplanSpaces
    ? `${t('components.floorplan.refreshing')}...`
    : t('common.loading');
  return (
    <OverlayFloorplan $showOverlay={showOverlay}>
      <Floorplan hidePeopleData={hidePeopleData} />
      <ResetViewButton />
      <LoadingButton isLoading={isLoading} loadingText={loadingText} />
    </OverlayFloorplan>
  );
};
