import React from 'react';
// @ts-ignore
import { ReactComponent as SVGSprite } from '@engage-web/assets/sprite.svg';

export const IconsSprite = () => (
  <SVGSprite
    aria-hidden
    focusable={false}
    style={{
      position: 'absolute',
      width: 0,
      height: 0,
      overflow: 'hidden',
    }}
  />
);
