import * as React from 'react';

export const generateTransparentImage = () => {
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d')!;
  const imgData = ctx.createImageData(1, 1);

  ctx.putImageData(imgData, 0, 0);
  return (<img src={c.toDataURL('image/png')} alt="zoom tiles are not displayed" />).props.src;
};
