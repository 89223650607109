import React, { useCallback, useEffect, useState } from 'react';
import OpenSeadragon, { TileSourceOptions } from 'openseadragon';

import { ZoomDescriptor } from '@floorplan/api';
import { FES_EVENTS, useFESContext, Viewer } from '@floorplan/fes';
import { generateTransparentImage } from './utils';
import { DEFAULT_FLOORPLAN_SCALE, defaultOptions } from '../../constants';
import { ViewerHandler } from './handlers';
import { RotationContext } from './RotationContext';
import IconButton from '@engage-web/components/base/IconButton/IconButton';

import { styled } from 'styled-components';
import { isElectron } from '@engage-web/utils/electron';

const Wrapper = styled.div`
  position: absolute;
  right: 50px;
  bottom: 20px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
`;

type InitialiseViewerProps = {
  floorId: number;
  zoomDescriptor?: ZoomDescriptor;
  showZoomTiles?: boolean;
  viewerOptions?: OpenSeadragon.Options;
};
const initialiseViewer = ({
  floorId,
  zoomDescriptor,
  showZoomTiles = true,
  viewerOptions = {},
}: InitialiseViewerProps) => {
  const options = {
    id: `osd-viewer-${floorId}`,
    ...defaultOptions,
    ...viewerOptions,
    customOptions: zoomDescriptor,
  } as OpenSeadragon.Options;
  const transparentImage = generateTransparentImage();
  const width = zoomDescriptor?.width || 2063;
  const height = zoomDescriptor?.height || 1885;
  const showTiles = !!zoomDescriptor && zoomDescriptor?.url && showZoomTiles;
  options.tileSources = {
    width,
    height,
    minLevel: 1,
    tileSize: 256,
    tileOverlap: 1,
    getTileUrl: (zoom: number, x: number, y: number) =>
      showTiles
        ? zoomDescriptor.url.replace('{z}', `${zoom}`).replace('{x}', `${x}`).replace('{y}', `${y}`)
        : transparentImage,
  } as TileSourceOptions;
  options.loadTilesWithAjax = !!zoomDescriptor?.url;
  return new OpenSeadragon.Viewer(options);
};

type FloorplanZoomDescriptorProps = React.PropsWithChildren<{
  showZoomTiles?: boolean;
  viewerOptions?: OpenSeadragon.Options;
  zoomDescriptor?: ZoomDescriptor;
}>;

const isKiosk = isElectron();

export const ZoomDescriptorContainer = ({
  showZoomTiles = true,
  viewerOptions = {},
  zoomDescriptor,
  children,
}: FloorplanZoomDescriptorProps) => {
  const [rotation, setRotation] = useState(0);
  const [floorId, setFloorId] = useState<number | null>(null);
  const fes = useFESContext();

  useEffect(() => {
    if (zoomDescriptor?.floorId && zoomDescriptor.floorId !== floorId) {
      setFloorId(zoomDescriptor?.floorId);
      setRotation(0);
    }
  }, [zoomDescriptor?.floorId, floorId]);

  const [viewHandler, setViewHandler] = useState<ViewerHandler | null>(null);

  const handleZoomDescriptorLoaded = useCallback(
    (zoomDescriptor: ZoomDescriptor) => {
      const viewer = fes.getViewer();
      const floorId = zoomDescriptor?.floorId;

      if (!viewer) {
        const openSeadragonViewer = initialiseViewer({
          floorId,
          zoomDescriptor,
          showZoomTiles,
          viewerOptions,
        });

        const viewer = new Viewer({
          floorId,
          viewer: openSeadragonViewer,
          scale: zoomDescriptor?.scale || DEFAULT_FLOORPLAN_SCALE,
        });
        fes.setViewer(viewer);
        // TODO should we add the handlers directly to the fes module viewer?
        const vh = new ViewerHandler(fes, openSeadragonViewer, {
          onRotationChange: rotation => {
            const viewerDOM = viewer?.getViewer().canvas;
            const labels = viewerDOM.querySelectorAll<SVGElement>('.floorplan-labels g text');
            if (labels) {
              labels.forEach(label => {
                const offset = label.dataset.offset ? parseFloat(label.dataset.offset) : 0;
                const y = parseFloat(label.getAttribute('y') ?? '0');
                label.setAttribute(
                  'transform',
                  `rotate(${rotation} ${label.getAttribute('x')} ${y - offset})`,
                );
              });
            }
            const labelContainers = viewerDOM.querySelectorAll('.floorplan-labels g rect');
            if (labelContainers) {
              labelContainers.forEach(labelContainer => {
                const x = parseFloat(labelContainer.getAttribute('x') ?? '0');
                const y = parseFloat(labelContainer.getAttribute('y') ?? '0');
                const width = parseFloat(labelContainer.getAttribute('width') ?? '0');
                const height = parseFloat(labelContainer.getAttribute('height') ?? '0');
                const cx = x + width / 2;
                const cy = y + height / 2;
                labelContainer.setAttribute('transform', `rotate(${rotation} ${cx} ${cy})`);
              });
            }
            setRotation(rotation);
          },
        });
        vh.addHandlers();
        setViewHandler(vh);
        // !IMPORTANT: devTools depend on it
        fes.trigger(FES_EVENTS.SET_VIEWER, {
          viewer: zoomDescriptor,
          floorId,
        });
      }
    },
    [fes, showZoomTiles, viewerOptions],
  );

  React.useEffect(() => {
    if (zoomDescriptor) {
      const existingViewer = fes.getViewer();
      if (existingViewer && existingViewer.getFloorId() !== zoomDescriptor?.floorId) {
        existingViewer.getViewer().destroy();
        fes.setViewer(null);
      }

      handleZoomDescriptorLoaded(zoomDescriptor);
    }
  }, [fes, handleZoomDescriptorLoaded, zoomDescriptor]);

  const easeInOutCubic = (t: number, b: number, c: number, d: number): number => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
  };

  const animateRotation = (viewer: Viewer, targetRotation: number, duration: number) => {
    const viewport = viewer.getViewer().viewport;
    const startRotation = viewport.getRotation();
    const rotationChange = targetRotation - startRotation;
    let start: number | null = null;

    function step(timestamp: number) {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const fraction = easeInOutCubic(progress, 0, 1, duration);

      const newRotation = startRotation + rotationChange * fraction;
      viewport.setRotation(newRotation);

      if (progress < duration) {
        requestAnimationFrame(step);
      } else {
        viewport.setRotation(targetRotation);
      }
    }

    requestAnimationFrame(step);
  };

  const handleOnRotateClick = () => {
    const existingViewer = fes.getViewer();
    if (existingViewer) {
      const currentRotation = existingViewer.getViewer().viewport.getRotation();
      animateRotation(existingViewer, currentRotation + 90, 700);
    }
  };

  const styleProps = {
    style: {
      backgroundColor: `rgb(44, 62, 80)`,
      color: 'white',
    },
  };

  return (
    <div id={`osd-viewer-${zoomDescriptor?.floorId}`} className="floorplan">
      {viewHandler ? (
        <>
          {isKiosk ? (
            <Wrapper>
              <IconButton icon="rotate-floorplan" onPress={handleOnRotateClick} {...styleProps} />
            </Wrapper>
          ) : null}
          <RotationContext.Provider value={rotation}>{children}</RotationContext.Provider>
        </>
      ) : null}
    </div>
  );
};
