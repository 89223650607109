import { fontSizesPX, getDefaultTheme, headerSizesPX } from '@engage-web/styles';

export const variantDef = {
  circle: 0.5,
  rounded: 0.3,
  square: 0.0,
  side: 0.0,
};

export const sizeDef = {
  tiny: 28,
  small: 36,
  medium: 52,
  large: 72,
  giant: 112,
};

export const textSize = {
  tiny: fontSizesPX.btn,
  small: fontSizesPX.bdl,
  medium: headerSizesPX.h6,
  large: headerSizesPX.h3,
  giant: headerSizesPX.h1,
};

export enum STATUS {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

const { status1: primary, status2: success, status3: warning, status4: error } = getDefaultTheme();

export const badgeColor = {
  primary,
  success,
  warning,
  error,
};
