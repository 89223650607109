import axios from 'axios';
import { getApiUrl } from '@engage-shared/api/instance';

type BlacklistAPIUrls = { [path: string]: number[] };
type IgnoreLoggingErrorToSentry = (
  /**
   * Common Error or API Error as AxiosError
   */
  error: Error,
  /**
   * Object with ignored API paths.
   */
  blacklistAPIUrls: BlacklistAPIUrls,
) => boolean;

/**
 * Ignore logging error to sentry for some API path for some particular statuses.
 */
const ignoreLoggingErrorToSentry: IgnoreLoggingErrorToSentry = (error, blacklistAPIUrls) => {
  if (!axios.isAxiosError(error)) {
    return false;
  }

  const url = error?.config?.url;
  const status = error?.response?.status;
  const message = error?.message;
  // ignore all 401 errors, we don't need to log authorization errors because tokens expires from time to time
  if (status === 401) {
    return true;
  }

  if (['econnAborted', 'Network Error'].includes(message)) {
    return true;
  }

  return Object.keys(blacklistAPIUrls).some(path => {
    if (!url) return false;

    const regExKey = new RegExp(`${getApiUrl()}/${path}`);
    const urlFound = regExKey.test(url);
    const statuses = blacklistAPIUrls[path];
    const ignoreStatus = statuses.length === 0 || (status && statuses.includes(status));
    return urlFound && ignoreStatus;
  });
};

export { ignoreLoggingErrorToSentry };
