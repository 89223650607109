import { Nullable } from '@engage-shared/utils/types';
import { ParsedUserData } from '@engage-shared/api/users/interfaces';
import { FetchTeamBookingDetails, TeamBookingDetails } from '@engage-shared/api/teams/interfaces';
import { BOOKING_TYPE } from '@engage-shared/constants/bookingTypes';

type MapTeamBookingDetailsParams = {
  teamBookingDetailsData?: FetchTeamBookingDetails;
  userData: ParsedUserData;
};
type MapTeamBookingDetails = (params: MapTeamBookingDetailsParams) => Nullable<TeamBookingDetails>;
export const mapTeamBookingDetails: MapTeamBookingDetails = ({
  teamBookingDetailsData,
  userData,
}) => {
  if (!teamBookingDetailsData) {
    return null;
  }

  const {
    id,
    summary,
    teamName,
    start,
    end,
    startTimeZone,
    isAllDayBooking,
    location,
    organizer,
    attendees,
    deskBookings,
    isInInvalidBuilding,
    isInInvalidFloor,
    isInInvalidSpace,
  } = teamBookingDetailsData;

  const isInInactiveLocation = isInInvalidBuilding || isInInvalidFloor || isInInvalidSpace;

  const mappedAttendees = attendees.map(({ displayName, status, id: attendeeId, email }) => ({
    id: attendeeId,
    name: displayName,
    isOrganiser: email === organizer.email,
    status: status,
    description: '',
    imageUrl: '',
  }));

  //organizer has to be the first one
  mappedAttendees.sort(attendee1 => (attendee1.isOrganiser ? -1 : 0));

  return {
    deskBookings,
    location,
    organizer,
    isInInactiveLocation,
    meetingId: id,
    allDay: isAllDayBooking,
    title: teamName,
    description: summary,
    startDate: new Date(start),
    endDate: new Date(end),
    localTimeZone: startTimeZone,
    attendees: mappedAttendees,
    isOrganizer: `${organizer.id}` === `${userData.userId}`,
    bookingType: BOOKING_TYPE.TEAM,
    canUpdate: true, //TODO clarify this value
    isInInvalidBuilding,
    isInInvalidFloor,
    isInInvalidSpace,
  };
};
