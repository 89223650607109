import { FetchTeamBookingsParams } from '@engage-shared/api/teams/interfaces';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { TEAM_BOOKINGS_QUERY_KEY } from '@engage-shared/api/teams/constants';
import { fetchTeamBookings } from '../fetch';
import { TeamBooking } from '@engage-shared/api/types';

type UseTeamBookingsParams = FetchTeamBookingsParams & {
  options?: UseQueryOptions<TeamBooking[]>;
};
type UseTeamBookings = (params: UseTeamBookingsParams) => UseQueryResult<TeamBooking[]>;
export const useTeamBookings: UseTeamBookings = ({
  tenantId,
  id,
  startTime,
  endTime,
  options = {},
}) => {
  return useQuery<TeamBooking[]>({
    queryKey: [TEAM_BOOKINGS_QUERY_KEY, tenantId, id, startTime, endTime],
    queryFn: () => fetchTeamBookings({ tenantId, id, startTime, endTime }),
    enabled: !!tenantId && !!id,
    ...options,
  });
};
