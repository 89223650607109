import React from 'react';
import { AvailableKiosks } from '@engage-web/components/kiosk';
import { Modals } from '@engage-web/components/scenes';
import { useNavigate } from 'react-router-dom';
import { ROOT_PATH } from '@engage-web/constants';

const headerProps = {
  hasAgendaButton: false,
  hasDateTime: false,
};

const KioskConfiguration = () => {
  const navigate = useNavigate();

  const handleSuccessfulKioskSelection = () => {
    navigate(ROOT_PATH, { replace: true });
  };

  return (
    <>
      <Modals isKiosk={false} headerProps={headerProps} />
      <AvailableKiosks onSuccessfullySelectKiosk={handleSuccessfulKioskSelection} />
    </>
  );
};

export default KioskConfiguration;
