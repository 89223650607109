import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { FloorMarker } from '@engage-shared/api/floors/interfaces';
import { fetchFloorMarkers } from '@engage-shared/api/floors/fetchFloorMarkers';

type UseFloorMarkersParams = {
  floorId: number;
  options?: UseQueryOptions<FloorMarker[]>;
};

type UseFloorMarkers = (params: UseFloorMarkersParams) => UseQueryResult<FloorMarker[]>;

export const useFloorMarkers: UseFloorMarkers = ({ floorId, options = {} }) => {
  return useQuery({
    queryKey: ['floorMarkers', floorId],
    queryFn: () => fetchFloorMarkers(floorId),
    ...options,
  });
};
