import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  BrowserContainer,
  BrowserIFrame,
  BrowserInvalidURL,
  BrowserLoader,
} from '@engage-web/components/scenes/Browser/styled';
import { Spinner } from '@engage-web/components/base';
import { Modals } from '@engage-web/components/scenes/modals';
import { useTranslation } from 'react-i18next';
import { isBrowserUrl } from '@engage-shared/api';

export const Browser = () => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const url = search.get('url');
  const isValidURL = isBrowserUrl(url);

  const [isLoading, setIsLoading] = useState(isValidURL);
  const finishLoading = () => setIsLoading(false);

  return (
    <BrowserContainer>
      <Modals hasDateTime={false} hasAgenda={false} />
      {isLoading && (
        <BrowserLoader>
          <Spinner>{t('common.loading')}</Spinner>
        </BrowserLoader>
      )}
      {isValidURL ? (
        <BrowserIFrame src={url} onLoad={finishLoading} onError={finishLoading} />
      ) : (
        <BrowserInvalidURL>{t('components.browser.invalidURL')}</BrowserInvalidURL>
      )}
    </BrowserContainer>
  );
};
