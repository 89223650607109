export enum LanguageTypes {
  en = 'en',
  enAU = 'en-AU',
  enGB = 'en-GB',
  enUS = 'en-US',
  fr = 'fr',
  frCA = 'fr-CA',
  frFR = 'fr-FR',
  es = 'es',
  esES = 'es-ES',
  zh = 'zh',
  zhCN = 'zh-CN',
  he = 'he',
  ja = 'ja',
  jaJP = 'ja-JP',
  de = 'de',
  deDE = 'de-DE',
  nl = 'nl',
  nlNL = 'nl-NL',
  ko = 'ko',
  koKR = 'ko-KR',
}

export enum LanguageTypesRTL {
  ae = 'ae' /* Avestan */,
  ar = 'ar' /* 'العربية', Arabic */,
  arc = 'arc' /* Aramaic */,
  bcc = 'bcc' /* 'بلوچی مکرانی', Southern Balochi */,
  bqi = 'bqi' /* 'بختياري', Bakthiari */,
  ckb = 'ckb' /* 'Soranî / کوردی', Sorani */,
  dv = 'dv' /* Dhivehi */,
  fa = 'fa' /* 'فارسی', Persian */,
  glk = 'glk' /* 'گیلکی', Gilaki */,
  he = 'he' /* 'עברית', Hebrew */,
  ku = 'ku' /* 'Kurdî / كوردی', Kurdish */,
  mzn = 'mzn' /* 'مازِرونی', Mazanderani */,
  nqo = 'nqo' /* N'Ko */,
  pnb = 'pnb' /* 'پنجابی', Western Punjabi */,
  ps = 'ps' /* 'پښتو', Pashto, */,
  sd = 'sd' /* 'سنڌي', Sindhi */,
  ug = 'ug' /* 'Uyghurche / ئۇيغۇرچە', Uyghur */,
  ur = 'ur' /* 'اردو', Urdu */,
  yi = 'yi' /* 'ייִדיש', Yiddish */,
}

export interface Languages {
  [key: string]: string;
}

export type AllLanguageTypes = LanguageTypes | LanguageTypesRTL;

export const languages: Languages = {
  [LanguageTypes.enAU]: 'English (Australia)',
  [LanguageTypes.enGB]: 'English (United Kingdom)',
  [LanguageTypes.enUS]: 'English (United States)',
  [LanguageTypes.frCA]: 'French (Canada)',
  [LanguageTypes.frFR]: 'French (France)',
  [LanguageTypes.esES]: 'Spanish (Spain)',
  [LanguageTypes.zhCN]: 'Chinese (Simplified)',
  [LanguageTypes.he]: 'Hebrew (General)',
  [LanguageTypes.ja]: 'Japanese (General)',
  [LanguageTypes.jaJP]: 'Japanese (Japan)',
  [LanguageTypes.deDE]: 'German (General)',
  [LanguageTypes.nlNL]: 'Dutch (General)',
  [LanguageTypes.koKR]: 'Korean (South Korea)',
  [LanguageTypes.ko]: 'Korean (General)',
};
