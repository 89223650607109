export function noop(): undefined {
  return undefined;
}

type LogFunction = (...args: any[]) => void;

export interface Logger {
  log: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
}

let logger: Logger = console || {
  error: noop,
  warn: noop,
  info: noop,
  log: noop,
};

export function getLogger(): Logger {
  return logger;
}

export function setLogger(newLogger: Logger): void {
  logger = newLogger;
}
