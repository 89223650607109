import React from 'react';
import { getRotationString, getTransformString } from '../../utils';
import { Space } from '@floorplan/api';
import { SPACE_TYPE_IDS, SpaceType } from '../../constants/spaceTypes';

const BathroomIcon = () => {
  return (
    <>
      <circle cx="40" cy="40" r="40" fill="black" />
      <path
        d="M28.7934 20C25.1525 20 22.1904 22.9621 22.1904 26.603C22.1904 30.2439 25.1525 33.2059 28.7934 33.2059C32.4344 33.2059 35.3965 30.2438 35.3965 26.603C35.3965 22.9622 32.4344 20 28.7934 20Z"
        fill="white"
      />
      <path
        d="M38.7776 39.6238C38.5361 39.3154 38.1327 39.1304 37.7012 39.1304H20.299C19.8674 39.1304 19.464 39.3153 19.2224 39.6238C18.9809 39.9323 18.9328 40.3242 19.0942 40.6695L27.7953 59.2982C27.9933 59.7222 28.4701 59.9999 29 59.9999C29.53 59.9999 30.0068 59.7222 30.2048 59.2982L38.9058 40.6694C39.0672 40.3241 39.0191 39.9323 38.7776 39.6238Z"
        fill="white"
      />
      <path
        d="M41.8308 59.5065C42.0723 59.815 42.4757 60 42.9072 60L60.3094 60C60.741 60 61.1444 59.8151 61.386 59.5065C61.6275 59.1981 61.6756 58.8062 61.5142 58.4609L52.8131 39.8322C52.6151 39.4082 52.1383 39.1304 51.6084 39.1304C51.0784 39.1304 50.6016 39.4082 50.4036 39.8322L41.7026 58.461C41.5412 58.8063 41.5893 59.1981 41.8308 59.5065Z"
        fill="white"
      />
      <path
        d="M51.7787 20C48.1378 20 45.1758 22.9621 45.1758 26.603C45.1758 30.2439 48.1379 33.2059 51.7787 33.2059C55.4195 33.2059 58.3817 30.2438 58.3817 26.603C58.3816 22.9622 55.4196 20 51.7787 20Z"
        fill="white"
      />
    </>
  );
};
const ElevatorIcon = () => {
  return (
    <>
      <circle cx="40" cy="40" r="40" fill="black" />
      <path
        d="M49.7468 37.4685H30.224C29.3807 37.4685 28.6079 36.9976 28.2215 36.2479C27.8349 35.4986 27.8993 34.5957 28.3882 33.9087L37.3017 21.3841C37.9197 20.516 38.9197 20 39.9855 20C41.0512 20 42.0513 20.5158 42.6693 21.3841L51.5826 33.9087C52.0715 34.5959 52.1359 35.4986 51.7493 36.2479C51.3629 36.9976 50.5901 37.4685 49.7468 37.4685Z"
        fill="white"
      />
      <path
        d="M30.224 42.5312H49.7468C50.5901 42.5312 51.3629 43.0022 51.7493 43.7518C52.1359 44.5011 52.0715 45.404 51.5826 46.0911L42.6691 58.6157C42.0511 59.4839 41.0511 59.9998 39.9853 59.9998C38.9196 59.9998 37.9195 59.4839 37.3015 58.6157L28.388 46.0911C27.8991 45.4039 27.8347 44.5011 28.2213 43.7518C28.6079 43.0022 29.3807 42.5312 30.224 42.5312Z"
        fill="white"
      />
    </>
  );
};

const StairsIcon = () => {
  return (
    <>
      <circle cx="40" cy="40" r="40" fill="black" />
      <path
        d="M56.9453 22H48.4727C47.8902 22 47.418 22.4722 47.418 23.0547V30.4727H40C39.4175 30.4727 38.9453 30.9449 38.9453 31.5273V38.9453H31.5273C30.9449 38.9453 30.4727 39.4175 30.4727 40V47.418H23.0547C22.4722 47.418 22 47.8902 22 48.4727V56.9453C22 57.5278 22.4722 58 23.0547 58H56.9453C57.5278 58 58 57.5278 58 56.9453V23.0547C58 22.4722 57.5278 22 56.9453 22Z"
        fill="white"
      />
    </>
  );
};

interface IconProps {
  space: Space;
  type: SpaceType;
  rotation: number;
}
export const Icon = ({ space, type, rotation }: IconProps) => {
  let innerIcon;
  switch (type) {
    case SPACE_TYPE_IDS.TOILET_WASHROOM:
      innerIcon = <BathroomIcon />;
      break;
    case SPACE_TYPE_IDS.LIFTS:
      innerIcon = <ElevatorIcon />;
      break;
    case SPACE_TYPE_IDS.FIRE_STAIRS:
      innerIcon = <StairsIcon />;
      break;
    default:
      innerIcon = null;
  }

  return (
    <g
      style={getTransformString({
        space,
        width: 40,
      })}
      id={`special-icon-${space.id}`}
    >
      <g style={getRotationString({ width: 40, rotation })}>{innerIcon}</g>
    </g>
  );
};
