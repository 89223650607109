import moment, { Moment } from 'moment-timezone';
import { CHECK_IN_STATUS } from '@engage-shared/constants/checkInStatuses';
import { getDeskCheckInWindowDates, getUtcIsoString } from '@engage-shared/utils/dates';
import {
  BookingDetails,
  CreateBookingResponse,
  FetchBookingQuery,
  MeetingParams,
} from '@engage-shared/api/bookings/interfaces';
import { getLogger } from '@engage-shared/utils/logger';
import { Nullable } from '@engage-shared/utils/types';
import { BOOKING_TYPE } from '@engage-shared/constants/bookingTypes';
import { Event } from '@engage-shared/api/users';

type StatusObject = {
  status: CHECK_IN_STATUS;
  hours?: number;
  minutes?: number;
  date?: Nullable<Moment>;
};

type MapReservationFromBooking = (
  params: BookingDetails,
) => Pick<
  CreateBookingResponse,
  'spaceId' | 'meetingId' | 'start' | 'startTimeZone' | 'isAllDayBooking'
>;

export const mapReservationFromBooking: MapReservationFromBooking = ({
  startDate,
  localTimeZone,
  spaceId,
  meetingId,
  allDay,
}) => ({
  start: getUtcIsoString(startDate),
  startTimeZone: localTimeZone ?? '',
  timeZone: localTimeZone ?? '',
  spaceId,
  meetingId,
  isAllDayBooking: allDay,
});

type HandleBeforeCheckInWindow = (params: { now: Moment; checkInOpenDate: Moment }) => StatusObject;
export const handleBeforeCheckInWindow: HandleBeforeCheckInWindow = ({ now, checkInOpenDate }) => {
  const status = CHECK_IN_STATUS.BEFORE_CHECK_IN;
  const duration = moment.duration(checkInOpenDate.diff(now));

  return {
    status,
    hours: duration.hours(),
    minutes: duration.minutes(),
    date: checkInOpenDate,
  };
};

type HandleCheckedIn = (
  params: MeetingParams & {
    bookingIsCheckedIn?: boolean;
    fetchBookingQuery: FetchBookingQuery;
  },
) => Promise<Nullable<StatusObject>>;
const handleCheckedIn: HandleCheckedIn = async ({
  spaceId,
  meetingId,
  bookingIsCheckedIn,
  fetchBookingQuery,
}) => {
  let isCheckedIn;

  // booking from Booking Details has isCheckedIn value, reservation from notification doesn't have it
  if (typeof bookingIsCheckedIn === 'boolean') {
    isCheckedIn = bookingIsCheckedIn;
  } else {
    try {
      const meetingData = await fetchBookingQuery({ spaceId, meetingId });
      isCheckedIn = meetingData?.isCheckedIn;
    } catch (error) {
      getLogger().warn(error);
    }
  }

  // verify if reservation is already checked in
  if (isCheckedIn) {
    const status = CHECK_IN_STATUS.CHECKED_IN;
    return { status };
  }

  return null;
};

type HandleInCheckInWindow = (
  params: MeetingParams & {
    checkInCloseDate: Nullable<Moment>;
    isCheckedIn?: boolean;
    now: Moment;
    fetchBookingQuery: FetchBookingQuery;
  },
) => Promise<StatusObject>;

export const handleInCheckInWindow: HandleInCheckInWindow = async ({
  spaceId,
  meetingId,
  checkInCloseDate,
  isCheckedIn: bookingIsCheckedIn,
  now,
  fetchBookingQuery,
}) => {
  const checkedInResult = await handleCheckedIn({
    spaceId,
    meetingId,
    bookingIsCheckedIn,
    fetchBookingQuery,
  });
  if (checkedInResult) return { status: checkedInResult.status };

  // reservation is not checked in
  const status = CHECK_IN_STATUS.CHECK_IN_OPEN;
  const duration = moment.duration(checkInCloseDate?.diff(now));

  return {
    status,
    hours: duration.hours(),
    minutes: duration.minutes(),
    date: checkInCloseDate,
  };
};

type HandleAfterCheckInWindow = (
  params: MeetingParams & {
    isCheckedIn?: boolean;
    fetchBookingQuery: FetchBookingQuery;
  },
) => Promise<StatusObject>;
export const handleAfterCheckInWindow: HandleAfterCheckInWindow = async ({
  spaceId,
  meetingId,
  isCheckedIn: bookingIsCheckedIn,
  fetchBookingQuery,
}) => {
  // verify if reservation is already checked in
  const checkedInResult = await handleCheckedIn({
    spaceId,
    meetingId,
    bookingIsCheckedIn,
    fetchBookingQuery,
  });
  if (checkedInResult) return { status: checkedInResult.status };

  // reservation is not checked in and window is closed
  const status = CHECK_IN_STATUS.CHECK_IN_CLOSED;
  return { status };
};

export interface CheckInConfiguration {
  enableDeskCheckIn: boolean;
  enableSpaceCheckIn: boolean;
  deskCheckInOpenWindow: number;
  deskCheckInWindowDuration: number;
  allDayReservationCheckInStartTime: string;
}

/**
 * Check-in statuses:
 * 'none' : unknown or before listing (listing occurs 2 hours before check-in open) or check-in is no longer enabled (config enableDeskCheckIn false)
 * 'beforeCheckIn': check-in window is going to open within the next two hours
 * 'checkInOpen': check-in window is open
 * 'checkedIn': desk has been successfully checked-in
 * 'checkInClosed': desk check-in window has closed and the user did not check in
 */
type GetCheckInStatus = (params: {
  timeZone: string;
  event?: Event;
  booking?: BookingDetails;
  fetchBookingQuery: FetchBookingQuery;
  config: CheckInConfiguration;
}) => Promise<StatusObject>;

export const getCheckInStatus: GetCheckInStatus = async ({
  timeZone,
  event,
  booking,
  config,
  fetchBookingQuery,
}) => {
  const statusNone = {
    status: CHECK_IN_STATUS.NONE,
  };

  if (!config?.enableDeskCheckIn || (!event && !booking)) {
    return statusNone;
  }

  const isDesk = event?.bookingType || booking?.bookingType === BOOKING_TYPE.DESK;
  if ((isDesk && !config.enableDeskCheckIn) || (!isDesk && !config.enableSpaceCheckIn)) {
    return statusNone;
  }

  const eventObj = event ?? mapReservationFromBooking(booking!);

  const isCheckedIn = booking?.isCheckedIn;

  const { start, spaceId, meetingId, isAllDayBooking } = eventObj;

  const startDate = moment(start, moment.ISO_8601);
  if (!timeZone || !start || !startDate.tz(timeZone)?.isValid()) {
    return statusNone;
  }

  const { checkInOpenDate, checkInCloseDate } = getDeskCheckInWindowDates({
    tenantConfig: config,
    reservationStartDate: start,
    timeZone,
    isAllDayBooking: isAllDayBooking,
  });

  if (!checkInOpenDate) {
    return statusNone;
  }

  const now = moment();

  // check-in window is going to open
  if (now.isSameOrBefore(checkInOpenDate)) {
    return handleBeforeCheckInWindow({ now, checkInOpenDate });
  }

  // check-in window is open
  if (now.isSameOrAfter(checkInOpenDate) && now.isSameOrBefore(checkInCloseDate as Moment)) {
    return await handleInCheckInWindow({
      spaceId: +spaceId,
      meetingId,
      checkInCloseDate,
      isCheckedIn,
      now,
      fetchBookingQuery,
    });
  }

  // desk check-in window has closed, check if user checked-in or not
  if (now.isSameOrAfter(checkInCloseDate as Moment)) {
    // eslint-disable-next-line no-return-await
    return await handleAfterCheckInWindow({
      spaceId: +spaceId,
      meetingId,
      isCheckedIn,
      fetchBookingQuery,
    });
  }
  // default;
  return statusNone;
};
