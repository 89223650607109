/* Calendar util functions */
import {
  add,
  differenceInDays,
  formatISO,
  getDaysInMonth,
  getMonth as fnsGetMonth,
  getYear as fnsGetYear,
} from 'date-fns';
import { checkOrParse } from './dateHelpers';
import { DateISODate } from '../types';
import { formatISODate } from './formatters';
import {
  AgendaItem,
  AgendaItems,
  EmptyAgendaItems,
  Event,
} from '@engage-shared/api/users/interfaces';
import { getLogger } from '@engage-shared/utils/logger';

/**
 * Check if the period between event's start and end dates is greater than one day and return the number of days or 0.
 * @param event
 * @return 0 for invalid inputs.
 */
export const spansMultipleDays = (event: Event): number => {
  const numberOfDays = differenceInDays(checkOrParse(event?.end), checkOrParse(event?.start));
  return isNaN(numberOfDays) ? 0 : numberOfDays;
};

/**
 * Get date formatted as yyyy-mm-dd (e.g 2020-09-17).
 * @param date
 */
export const formatDate = (date: DateISODate): string =>
  formatISO(checkOrParse(date), { representation: 'date' });

/**
 * Get the month number of the given date, numbering starts at 0.
 * @param date
 * @return month's number or NaN for invalid inputs
 */
export const getMonth = (date: DateISODate): number => fnsGetMonth(checkOrParse(date));

/**
 * Get the year of the given date.
 * @param date
 * @return year's number or NaN for invalid inputs
 */
export const getYear = (date: DateISODate): number => fnsGetYear(checkOrParse(date));

type GenerateMonthDays = (year: number, month: number) => EmptyAgendaItems;

/**
 * Get an object containing a property for each day of the specified month.
 * @param year
 * @param month 0-indexed month number
 */
export const generateMonthDays: GenerateMonthDays = (year, month) => {
  const monthDays = {} as EmptyAgendaItems;
  const days = getDaysInMonth(new Date(year, month));
  for (let i = 1; i <= days; i++) {
    monthDays[formatDate(new Date(year, month, i))] = [];
  }
  return monthDays;
};

/**
 * Split multi day events in agenda items.
 * @param agendaItems
 * @param item
 * @param multiDay
 * @param userTimeZone
 */
const setMultiDayItems = (
  agendaItems: AgendaItems,
  item: AgendaItem,
  multiDay: number,
  userTimeZone: string,
): void => {
  let tempDay = new Date(checkOrParse(item.start).getTime());
  for (let i = 0; i <= multiDay; i++) {
    const key = formatISODate(tempDay, {
      timeZone: userTimeZone,
    });
    if (agendaItems[key]) {
      agendaItems[key].push({
        isMultiday: true,
        dayOfMultiday: i,
        ...item,
      });
    }
    tempDay = add(tempDay, { days: 1 });
  }
};

export type GetAgendaItems = (
  data: Event[],
  year: number,
  month: number,
  userTimeZone: string,
) => AgendaItems;
export const getAgendaItems: GetAgendaItems = (data, year, month, userTimeZone) => {
  let items = {} as AgendaItems;
  try {
    // generate whole month of empty arrays
    items = generateMonthDays(year, month);

    // fill each day array with existing meetings
    data.forEach(item => {
      const multiDay = spansMultipleDays(item);
      if (multiDay > 0) {
        setMultiDayItems(items, item, multiDay, userTimeZone);
      } else {
        const key = formatISODate(new Date(item.start), {
          timeZone: userTimeZone,
        });
        // do it only for the existing month
        if (items[key]) {
          items[key].push(item);
        }
      }
    });

    return items;
  } catch (error) {
    getLogger().error(error);
    if (year && month) {
      return generateMonthDays(year, month);
    }
    return items;
  }
};
