import React, { memo, PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';

interface SkeletonThemeProps {
  color?: string;
  children: React.ReactNode;
  highlightColor?: string;
  style?: React.CSSProperties;
}

interface StyleProps {
  $color: string;
  $highlightColor: string;
  style: React.CSSProperties;
}

const Wrapper = styled.div<StyleProps>`
  & .react-loading-skeleton {
    background-color: ${props => props.color};
    background-image: linear-gradient(
      90deg,
      ${props => props.$color},
      ${props => props.$highlightColor},
      ${props => props.$color}
    );
  }
`;

const SkeletonTheme = ({
  color,
  highlightColor,
  style = {},
  children,
}: PropsWithChildren<SkeletonThemeProps>) => {
  const { color3, color4 } = useTheme();
  const _color = color || color3;
  const _highlightColor = highlightColor || color4;
  return (
    <Wrapper $color={_color} $highlightColor={_highlightColor} style={style}>
      {children}
    </Wrapper>
  );
};

export default memo(SkeletonTheme);
