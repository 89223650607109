import Api from '../instance/api';
import { API_VERSIONS, API_TIMEOUT } from '@engage-shared/constants/api';
import { getApiUrl } from '@engage-shared/api/instance';
import { FetchSearchParams, SearchResponse } from './interfaces';
import { connectionAborted } from '@engage-shared/api/instance/errors';

export type TransformData<ResponseData> = (searchResponse: SearchResponse) => ResponseData;

/**
 * Allows you to search for a person, room or desk using the parameters explained below.
 *
 * Results are sorted based on the following criteria
 *
 * - matching searchString criteria (ie. name)
 *
 * - order by floor, then building, then other. Each group is ordered alphabetically.
 * People are always treated as 'available' for sorting purposes
 *
 * - Excludes UnknownPerson
 *
 * - Only spaces with the Container type = SpaceContainerType are returned
 *
 * - Excludes spaces marked as 'Do not allow occupants'
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | results found |
 * | 404 | Undocumented  |
 * @param tenantId
 * @param data
 * @param timeout
 * @param transform
 */
export const fetchSearch = async <RespData = SearchResponse>(
  { data: { tenantId, ...data }, timeout = API_TIMEOUT }: FetchSearchParams,
  transform: TransformData<RespData>,
): Promise<RespData> => {
  const apiUrl = getApiUrl(tenantId, API_VERSIONS.V2);
  const url = new URL(`${apiUrl}/search`);
  try {
    const response = await Api.post(url.toString(), data, {
      timeout,
    });

    return transform(response.data);
  } catch (error) {
    return Promise.reject(connectionAborted(error as Error));
  }
};
