import { Nullable } from '@engage-shared/utils/types';
import { BaseSVLivePresence } from '../../api/people/interfaces/presence.type';
import { subMinutes } from 'date-fns';

type GetLatestPresenceEvent = (events?: BaseSVLivePresence[]) => Nullable<BaseSVLivePresence>;

/**
 * Get presence event which `lastSeenTime` is most recent.
 * If `events` are empty or undefined – null is returned.
 * `events` could be undefined in situations when this function
 * is used inside component that fetches data from API
 * and no extra checks done. Event has to be less than an hour old.
 *
 * @param {BaseSVLivePresence[]} events Array of presence events.
 * @return {Nullable<BaseSVLivePresence>} Presence event or `null`.
 */
export const getLatestPresenceEvent: GetLatestPresenceEvent = events => {
  if (!events || events.length === 0) return null;

  const isEventInTheLastHour = (event: BaseSVLivePresence): boolean => {
    const now = new Date();

    return new Date(event.lastSeenTime).getTime() >= subMinutes(now, 60).getTime();
  };

  let latestEvent = events.reduce(
    (lastSavedItem: Nullable<BaseSVLivePresence>, currentItem: BaseSVLivePresence) => {
      if (!lastSavedItem) return currentItem;

      if (
        new Date(currentItem.lastSeenTime).getTime() >
        new Date(lastSavedItem?.lastSeenTime).getTime()
      ) {
        return currentItem;
      }

      return lastSavedItem;
    },
    null,
  );

  if (latestEvent && !isEventInTheLastHour(latestEvent)) {
    latestEvent = null;
  }

  return latestEvent;
};
