import { setOptOutStatus } from '@engage-shared/api/people/update';
import { useDebouncedCallback } from 'use-debounce';
import {
  OptOutMutationContext,
  SetOptOutMutationOptions,
  SetOptOutStatus,
  SetOptOutStatusApiParams,
  UseOptOutResult,
} from '@engage-shared/api/people/interfaces';
import { useMutation } from 'react-query';
import { TenantId } from '@engage-shared/api/tenant/interfaces';

type UseOptOutStatus = (
  params: SetOptOutMutationOptions & {
    tenantId: TenantId;
    isDebouncedApiCall?: boolean;
  },
) => UseOptOutResult;

export const useOptOutStatus: UseOptOutStatus = ({ tenantId, isDebouncedApiCall, ...options }) => {
  // to avoid multiple data sending on multiple clicking
  const debouncedSetOptOutStatus = useDebouncedCallback(setOptOutStatus, 500);

  const mutationFn = isDebouncedApiCall
    ? (debouncedSetOptOutStatus as SetOptOutStatus)
    : setOptOutStatus;

  const optOutStatusMutation = useMutation<
    void,
    string,
    SetOptOutStatusApiParams,
    OptOutMutationContext
  >(mutationFn, options);

  const mutateOptOutStatus: UseOptOutResult['mutateOptOutStatus'] = engageOptOut => {
    optOutStatusMutation.mutate({ engageOptOut, tenantId });
  };

  return { ...optOutStatusMutation, mutateOptOutStatus };
};
