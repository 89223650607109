import { logout } from '@engage-web/api';
import { useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import {
  tenantSelectors,
  navigationSelectors,
  tenantActions,
  notificationsActions,
} from '@engage-web/store';
import { useNavigate } from 'react-router-dom';
import { SSO_LOGOUT_PATH, WORKSPACE_PATH } from '@engage-web/constants';
import { TokenManager } from '@engage-shared/api';

interface UseLogoutParams {
  onSuccess?: (data: any, variables: any) => void;
  onError?: (error: any, variables: any) => void;
}

export const useLogout = (options?: UseLogoutParams) => {
  const { ssoEnabled } = useSelector(tenantSelectors.getConfig);
  const bypassSSO = useSelector(navigationSelectors.getBypassSSO);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useMutation(logout, {
    ...options,
    onSuccess: async (data, variables) => {
      await TokenManager.removeTokens();
      const logoutRoute = ssoEnabled && !bypassSSO ? SSO_LOGOUT_PATH : WORKSPACE_PATH;

      navigate(logoutRoute);

      if (!ssoEnabled || bypassSSO) {
        dispatch(tenantActions.clear());
      }

      dispatch(notificationsActions.clear());
      options?.onSuccess?.(data, variables);
    },
    onError: options?.onError,
  });
};
