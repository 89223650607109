import styled from 'styled-components';

interface WrapperProps {
  $backgroundColor: string;
  $opacity: number;
}
export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 30px;
  border-radius: 30px;
  opacity: ${props => props.$opacity};
  background-color: ${props => props.$backgroundColor};
  margin-right: 6px;
  :focus-within {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

interface ButtonProps {
  style: StyleSheet | object;
}
export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const ButtonText = styled.span`
  display: flex;
  align-items: center;
  padding: 0 25px;
  & .text {
    font-size: 12px;
    line-height: 16px;
    color: ${({ color, theme }) => color || theme.typo2};
  }
`;
