import styled from 'styled-components';
import { GlobalSearchOuterWrapper } from '@engage-web/components/layouts/GlobalSearch/styled';

export const TeamMembersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
`;

export const SearchInputWrapper = styled.div`
  width: 100%;
  margin: 15px 0;
`;

export const TeamMembersSearchOuterWrapper = styled(GlobalSearchOuterWrapper)`
  padding-bottom: 60px;
`;

export const TeamReservationButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ disabled, theme }) => (disabled ? '#cccccc' : theme.color9)};
  color: ${({ disabled, theme }) => (disabled ? '#000000' : theme.typo1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;
  font-size: 18px;
  height: 80px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
`;

export const TeamMembersListSkeleton = styled.ul`
  margin: 0;
  padding: 0 0 23px;
  list-style: none;
`;

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  font-size: 15px;
`;

export const DesksSelectionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
`;

export const SelectableItemWrapper = styled.div<{ $isSelected?: boolean }>`
  background-color: ${({ $isSelected }) => ($isSelected ? 'rgba(195,216,238,0.4)' : 'transparent')};
`;

export const SelectionHint = styled.p`
  padding: 0 30px 10px;
  margin: 0;
`;
