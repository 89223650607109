import { useEffect } from 'react';
import { RESERVATION_ANALYTICS_TYPE } from '@engage-web/constants';
import Analytics from '@engage-web/utils/analytics';
import { ParsedSpaceItem } from '@engage-shared/api/spaces';
import { Reservation } from '@engage-shared/api/bookings';

const useReserveAnalytics = (spaceItem?: ParsedSpaceItem) =>
  useEffect(() => {
    if (spaceItem) {
      if (spaceItem.isDesk) {
        // Desk card loaded
        Analytics.track('EVT-041');

        const code = spaceItem.available ? 'EVT-029' : 'EVT-031';
        Analytics.track(code, spaceItem);
      } else {
        // Space card loaded
        Analytics.track('EVT-034');

        // Floorplan - Space selected
        Analytics.track('EVT-030', spaceItem);
      }
    }

    return () => {
      if (spaceItem) {
        const code = spaceItem.isDesk ? 'EVT-046' : 'EVT-039';
        Analytics.track(code);
      }
    };
  }, [spaceItem]);

type TrackReservationAnalyticsParams = {
  spaceItem: ParsedSpaceItem;
  analyticsType: string;
  durationPayload?: { duration?: number; allDayDuration?: boolean };
  reservee?: number;
  reservation?: Reservation;
};
const trackReservationAnalytics = ({
  spaceItem,
  analyticsType,
  durationPayload,
  reservee,
  reservation,
}: TrackReservationAnalyticsParams) => {
  const { isDesk } = spaceItem;
  // eslint-disable-next-line default-case
  switch (analyticsType) {
    case RESERVATION_ANALYTICS_TYPE.START: {
      Analytics.track(isDesk ? 'EVT-043' : 'EVT-036', spaceItem);
      break;
    }
    case RESERVATION_ANALYTICS_TYPE.SUCCESS: {
      Analytics.track(isDesk ? 'EVT-044' : 'EVT-037', reservation);
      break;
    }
    case RESERVATION_ANALYTICS_TYPE.FAIL: {
      Analytics.track(isDesk ? 'EVT-045' : 'EVT-038', spaceItem);
      break;
    }
    case RESERVATION_ANALYTICS_TYPE.DURATION_CHANGED: {
      Analytics.track(isDesk ? 'EVT-042' : 'EVT-035', durationPayload);
      break;
    }
    case RESERVATION_ANALYTICS_TYPE.ADMIN_BUTTON_TAP: {
      Analytics.track('EVT-083', spaceItem);
      break;
    }
    case RESERVATION_ANALYTICS_TYPE.RESERVEE_SELECTED: {
      Analytics.track('EVT-084', { spaceItem, reservee });
      break;
    }
  }
};

export { RESERVATION_ANALYTICS_TYPE, trackReservationAnalytics, useReserveAnalytics };
