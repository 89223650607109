import { Nullable } from '@engage-shared/utils/types';
import size from 'lodash/size';
import trim from 'lodash/trim';
import isnumber from 'lodash.isnumber';
import { ACKNOWLEDGEMENT } from '../../constants';
import { isAfter } from 'date-fns';

interface AcknowledgementPolicyStoredData {
  organizationPolicyMessage: Nullable<string>; // Hashed organization policy message
  organizationPolicyExpirationTime: Nullable<string>;
}

interface AcknowledgementPolicy {
  acknowledgementPolicyStoredData: AcknowledgementPolicyStoredData;
  acknowledgementMessage: Nullable<string>;
  acknowledgementValue: Nullable<number>;
}

/**
 * Calculate is organization policy expired.
 * @param {AcknowledgementPolicyStoredData} acknowledgementPolicyStoredData - Stored acknowledgement policy data in persisted store
 * @return {boolean} -  True is returned if policy expired
 */
const isOrganizationPolicyExpired = (
  acknowledgementPolicyStoredData: AcknowledgementPolicyStoredData,
): boolean => {
  if (!acknowledgementPolicyStoredData?.organizationPolicyExpirationTime) {
    return true;
  }

  return isAfter(
    new Date(),
    new Date(acknowledgementPolicyStoredData?.organizationPolicyExpirationTime),
  );
};

/**
 * Check if acknowledgement policy is shown.
 * If selected value is never -> Never display the acknowledgement screen.
 * If selected value is daily -> Show the acknowledgement screen the first time a user opens Engage on that day.
 * If selected value is onChange -> Show the acknowledgement screen whenever the message has been modified.
 * @param {AcknowledgementPolicy} acknowledgementPolicy
 * @param {AcknowledgementPolicyStoredData} acknowledgementPolicy.acknowledgementPolicyStoredData - Acknowledgement policy data from persisted store
 * @param {string} acknowledgementPolicy.acknowledgementMessage - Hashed Acknowledgement message from configuration
 * @param {string} acknowledgementPolicy.acknowledgementValue - Config acknowledgement value
 * @return {boolean} - Boolean value is acknowledgement policy accepted
 */
export const showAcknowledgementPolicy = ({
  acknowledgementPolicyStoredData,
  acknowledgementMessage,
  acknowledgementValue,
}: AcknowledgementPolicy): boolean => {
  const { organizationPolicyMessage, organizationPolicyExpirationTime } =
    acknowledgementPolicyStoredData;

  if (acknowledgementValue === ACKNOWLEDGEMENT.NEVER) return false;

  if (!organizationPolicyMessage && !organizationPolicyExpirationTime) return true;

  if (acknowledgementValue === ACKNOWLEDGEMENT.ONCHANGE) {
    return acknowledgementMessage !== organizationPolicyMessage;
  }
  if (acknowledgementValue === ACKNOWLEDGEMENT.DAILY) {
    return isOrganizationPolicyExpired(acknowledgementPolicyStoredData);
  }

  return true;
};

/**
 * Check if acknowledgement url button is shown.
 * If acknowledgementUrl is not defined it has a default value of number 3609
 * @param {(string | number | null)} acknowledgementUrl
 * @return {boolean} - Is acknowledgement url button shown
 */
export const showAcknowledgementUrlButton = (
  acknowledgementUrl: string | number | null,
): boolean => {
  if (!acknowledgementUrl || isnumber(acknowledgementUrl)) {
    return false;
  }

  return size(trim(acknowledgementUrl)) > 0;
};
