import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useAppLocation } from '@engage-web/router/hooks';
import { removeSegmentFromPath } from '@engage-web/router/utils';

export function useCloseFilters() {
  const { pathname } = useAppLocation();
  const navigate = useNavigate();

  return useCallback(() => {
    navigate(removeSegmentFromPath(PATH_SEGMENT.FILTERS, pathname));
  }, [navigate, pathname]);
}
