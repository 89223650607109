import React from 'react';
import { useImageViewer } from '@engage-web/components/layouts/ImageViewer/hooks/useImageViewer';
import { AvatarImageProps } from '@engage-web/components/base/Avatar/AvatarImage';
import { AvatarWrapper } from './styled';
import Avatar from './Avatar';

export function ViewableAvatar(props: AvatarImageProps & { alt: string }) {
  const { alt, source, ...avatarProps } = props;
  const { openImageViewer } = useImageViewer();

  const viewAvatar = () => {
    openImageViewer({ imageSource: source, imageDescription: alt });
  };

  return (
    <AvatarWrapper>
      <Avatar {...avatarProps} source={source} onImageClick={viewAvatar} />
    </AvatarWrapper>
  );
}
