import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tenantSelectors } from '@engage-web/store';
import { Icon, Mask } from '@engage-web/components/base';
import { DOCUMENT_TITLE_LEVEL, MODALS } from '@engage-web/constants';
import { useLogout } from '@engage-web/api/mutations/useLogout';
import { useSetPageTitle, useModal, useFocus, FocusId } from '@engage-web/utils/hooks';
import Notifications from '../Notifications/Notifications';
import { Actions, Logout, NotificationsWrapper, Settings, Wrapper } from './styled';

const UserProfile = () => {
  const { t } = useTranslation();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { openModal } = useModal();

  const { mutate, isLoading } = useLogout();

  const onLogout = () => mutate({ tenantId });

  const logoutStr = t('layout.userProfile.logout');
  const settingsStr = t('layout.userProfile.settings');

  const handleSettingsClick = () => {
    openModal(MODALS.CONFIGURATION);
  };

  useFocus({ returnFocusTo: FocusId.UserProfileButton });

  useSetPageTitle(t('documentTitles.userProfile'), DOCUMENT_TITLE_LEVEL.MODAL);

  return (
    <Wrapper data-testid="user-profile">
      <Mask isLoading={isLoading} />
      <Actions>
        <Logout onClick={onLogout}>
          <Icon shouldRotateOnRtl name="logout" size={16} />
          <span className="text">{logoutStr}</span>
        </Logout>
        <Settings>
          <button id={FocusId.UserSettingsButton} onClick={handleSettingsClick}>
            {settingsStr}
          </button>
        </Settings>
      </Actions>
      <NotificationsWrapper>
        <Notifications />
      </NotificationsWrapper>
    </Wrapper>
  );
};

export default memo(UserProfile);
