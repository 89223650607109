import styled from 'styled-components';
import { Mask } from '@engage-web/components/base';
import { Z_INDEX } from '@engage-web/constants';

export const CheckingMask = styled(Mask)`
  position: fixed;
  z-index: ${Z_INDEX.MODALS};
  opacity: 1;
  background-color: rgb(178, 178, 178);
`;
