import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { ReserveDeskParams } from '../interfaces';
import { reserveDesk } from '../reserveDesk';

type UseTeamDeskReserveArgs = {
  options?: UseMutationOptions<void, Error, ReserveDeskParams>;
};

type UseTeamDeskReserve = (
  args: UseTeamDeskReserveArgs,
) => UseMutationResult<void, Error, ReserveDeskParams>;

export const useTeamDeskReserve: UseTeamDeskReserve = ({ options }) => {
  return useMutation(reserveDesk, options);
};
