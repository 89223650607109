import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarProps as RCalendarProps, FormatterCallback } from 'react-calendar';
import { isSameDay } from 'date-fns';
import { DateFormat, formatLocalizedDate } from '@engage-shared/utils';
import { firstLetterCapitalize } from '@engage-web/utils';
import { Icon } from '@engage-web/components/base';
import { LanguageTypes } from '@engage-web/constants';
import { CalendarStyled } from './styled';

type CalendarProps = {
  locale: LanguageTypes;
} & RCalendarProps;

// all available props can be found here https://github.com/wojtekmaj/react-calendar#user-guide
const Calendar = ({ locale = LanguageTypes.enUS, ...calendarProps }: CalendarProps) => {
  const { t } = useTranslation();

  const formatLongDate: FormatterCallback = (_, date) => {
    let formattedDate = formatLocalizedDate(date, {
      locale,
      format: DateFormat.yearWithShortMonthAndDay,
    });
    const calendarDateValue = calendarProps.value as Date;
    if (isSameDay(calendarDateValue, date)) {
      formattedDate += ` - ${t('accessibilityLabels.selectedDate')}`;
    }
    return formattedDate;
  };

  const formatDay = (locale: LanguageTypes, date: Date): string => {
    const formattedDay = formatLocalizedDate(date, {
      locale,
      format: DateFormat.dayNumeric,
    });
    // for Japanese and Chinese the formattedDay contains a character after the numeric value
    const matches = formattedDay.match(/(\d+)/);

    return matches && matches.length > 0 ? matches[0] : formattedDay;
  };

  return (
    <CalendarStyled
      expandedDayFormat={false}
      prevLabel={<Icon shouldRotateOnRtl name="arrow-left" size={32} />}
      prev2Label={null}
      nextLabel={<Icon shouldRotateOnRtl name="arrow-right" size={32} />}
      formatShortWeekday={(_, value) =>
        t(`calendar.${locale === LanguageTypes.esES ? 'dayNamesMin' : 'dayNamesShort'}`, {
          returnObjects: true,
        })[value.getDay()]
      }
      next2Label={null}
      navigationLabel={({ date, label, view }) =>
        view === 'month'
          ? firstLetterCapitalize(
              formatLocalizedDate(date, {
                locale,
                format: DateFormat.monthLong,
              }),
            )
          : label
      }
      formatLongDate={formatLongDate}
      locale={locale}
      //@ts-ignore
      formatDay={formatDay}
      {...calendarProps}
    />
  );
};

export default Calendar;
