import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { testProps } from '@engage-web/utils';
import { filtersActions, teamReservationsActions } from '@engage-web/store';
import { Icon } from '@engage-web/components/base';
import { MenuButtonWrapper } from './styled';
import { useInvalidateFloorplanSpaces } from '@floorplan/api';

export function ExitDeskCheckInButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { invalidateFloorplanSpaces } = useInvalidateFloorplanSpaces();

  const handleClick = () => {
    dispatch(teamReservationsActions.clearTeamBookingId());
    invalidateFloorplanSpaces();
    dispatch(filtersActions.clearDates({}));
  };

  return (
    // @ts-ignore
    <MenuButtonWrapper
      onClick={handleClick}
      as="button"
      {...testProps(t, 'accessibilityLabels.exitDeskCheckIn').props}
    >
      <Icon shouldRotateOnRtl name="arrow-left" />
    </MenuButtonWrapper>
  );
}
