import { useConfig } from '@engage-web/utils/hooks/useConfig';
import { useQuery } from 'react-query';
import axios from 'axios';
import { getParsedSVG } from './svgUtils';

export const CLIENT_LOGO_KEY = 'client_logo';

export const fetchClientLogo = async (logoURL: string) => {
  let validSvg;
  if (logoURL) {
    const response = await axios.get(logoURL, {
      responseType: 'blob',
    });
    const blob = response.data;
    // in the response we should have a xml text file which contains an SVG
    const text = await blob.text();
    validSvg = getParsedSVG(text);
  }

  return validSvg;
};

export function useClientLogo(logo: string, title: string, id: string) {
  const config = useConfig();
  const logoURL = logo || config.logo;
  const instanceTitle = title || config.instanceTitle;

  const { data } = useQuery([CLIENT_LOGO_KEY, id], () => fetchClientLogo(logoURL));

  return {
    data: {
      ...data,
      instanceTitle,
    },
  };
}
