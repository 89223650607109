import { FloorplanOptions } from '../types';
import { Reducer, useReducer } from 'react';

const initialState = {
  zoomTilesShown: false,
  reservedAlwaysEnabled: false,
  labelOptions: {
    showOccupantLabels: true,
    showSpaceNameLabels: true,
    showSpaceTypeLabels: false,
    labelFontSize: 10,
  },
} as FloorplanOptions;

const OptionsAction = {
  SET_OPTIONS: 'setOptions',
};
interface OptionAction {
  type: (typeof OptionsAction)[keyof typeof OptionsAction];
  payload: FloorplanOptions;
}

const reducer = (state: FloorplanOptions, action: OptionAction) => {
  switch (action.type) {
    case OptionsAction.SET_OPTIONS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const updateOptionState = (payload: FloorplanOptions) => ({
  type: OptionsAction.SET_OPTIONS,
  payload,
});

export const useFloorplanOptionsReducer = () =>
  useReducer<Reducer<FloorplanOptions, OptionAction>>(reducer, initialState);
