import { useMutation } from 'react-query';
import { createTeamBooking } from '../create';
import {
  CreateHourlyTeamBookingResult,
  CreateTeamBookingMutationOptions,
  CreateTeamBookingMutationParams,
  CreateTeamBookingParams,
  FetchTeamBookingDetails,
} from '../interfaces';
import { getReservationDatesFromFilter } from '@engage-shared/api/teams/utils/getReservationDatesFromFilter';

type UseHourlyTeamBookingMutation = (
  params: CreateTeamBookingMutationParams,
  options?: CreateTeamBookingMutationOptions,
) => CreateHourlyTeamBookingResult;

export const useHourlyTeamBookingMutation: UseHourlyTeamBookingMutation = (
  params,
  options = {},
) => {
  const {
    tenantId,
    teamId,
    summary,
    filter,
    timeZone,
    duration,
    allDayDuration,
    attendees,
    desks,
  } = params;
  const { reservationStartDate, reservationEndDate } = getReservationDatesFromFilter({
    filter,
    timeZone,
    duration,
    allDayDuration,
    showDaysSelector: false,
    showDurationSelection: true,
  });

  const reservation = {
    teamId,
    summary,
    start: reservationStartDate,
    end: reservationEndDate,
    startTimeZone: timeZone,
    endTimeZone: timeZone,
    isAllDayBooking: allDayDuration,
    attendees,
    desks,
  };

  const teamBookingMutation = useMutation<FetchTeamBookingDetails, Error, CreateTeamBookingParams>(
    createTeamBooking,
    options,
  );

  const { mutate, ...otherMutationProps } = teamBookingMutation;

  const onBooking: () => void = () => {
    return mutate({
      tenantId,
      // @ts-ignore
      reservation,
    });
  };

  return { ...otherMutationProps, onBooking };
};
