// @ts-nocheck
// https://openseadragon.github.io/docs/openseadragon.js.html#line14428
import OpenSeadragon from 'openseadragon';
import { Api } from '@floorplan/api';
import { isNativeAppWebView } from './reactNative';

export const calculateTransform = (viewer: OpenSeadragon.Viewer) => {
  if (!viewer) {
    return null;
  }

  const point = new OpenSeadragon.Point(0, 0);
  const zoom = viewer.viewport.getZoom(true);
  const origin = viewer.viewport.pixelFromPoint(point, true);
  const rotate = viewer.viewport.getRotation();
  const contentFactor = viewer.world.getContentFactor();
  const scale = contentFactor > 0 ? (viewer.viewport.containerSize.x * zoom) / contentFactor : 1;

  return {
    zoom,
    translateX: origin.x,
    translateY: origin.y,
    scale,
    rotate,
  };
};

const monkeyPatchOpenSeaDragon_native = () => {
  OpenSeadragon.makeAjaxRequest = async options => {
    const { success, error, headers, url } = options;

    headers.Accept = '*/*';

    try {
      const res = await window.tilesFetch({
        url,
        headers,
        method: 'GET',
        responseType: 'arraybuffer',
      });
      if (res.ok) {
        const buffer = await res.arrayBuffer();
        success({
          response: buffer,
          status: 200,
          statusText: 'OK',
        });
      }
    } catch (err) {
      if (typeof error === 'function') {
        error(err);
      }
      error({
        responseText: 'Error while fetching',
        status: res.status,
        statusText: 'Error',
      });
      return new ArrayBuffer(0);
    }
  };
};

const monkeyPatchOpenSeaDragon_web = () => {
  OpenSeadragon.makeAjaxRequest = async options => {
    const { success, error, headers, url } = options;
    headers.Accept = '*/*';

    try {
      const res = await Api.fetch({
        url,
        headers,
        method: 'GET',
        responseType: 'arraybuffer',
      });

      success({
        response: res,
        status: 200,
        statusText: 'OK',
      });

      return res;
    } catch (err) {
      if (typeof error === 'function') {
        error(err);
      }
      error({
        responseText: 'Error while fetching',
        status: res.status,
        statusText: 'Error',
      });
      return new ArrayBuffer(0);
    }
  };
};

export const monkeyPatchOpenSeaDragon = () => {
  if (isNativeAppWebView) {
    monkeyPatchOpenSeaDragon_native();
    return;
  }
  monkeyPatchOpenSeaDragon_web();
};
