import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchValue: '',
};

const teamMembersSearch = createSlice({
  name: 'teamMembersSearch',
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    clearSearchValue: () => initialState,
  },
});

export const teamMembersSearchSelectors = {
  // @ts-ignore
  getTeamMembersSearchValue: state => state.teamMembersSearch.searchValue,
};

export default teamMembersSearch;
