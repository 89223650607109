import React from 'react';
import { Wrapper, LoadingText } from './LoadingButton.style';

import { LoadingSpinner } from './Spinner';

type Props = {
  isLoading: boolean;
  loadingText: string;
};
export const LoadingButton = ({ isLoading, loadingText }: Props) => {
  return isLoading ? (
    <Wrapper>
      <LoadingSpinner />
      <LoadingText>{`${loadingText}...`}</LoadingText>
    </Wrapper>
  ) : null;
};
