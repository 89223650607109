import React, { memo, useEffect } from 'react';
import Analytics from '@engage-web/utils/analytics';
import { SENSOR_STATUS } from '@engage-web/constants';
import { PersonAndPresenceResponse } from '@engage-shared/api';
import { Nullable } from '@engage-shared/utils/types';
import { isElectron } from '@engage-web/utils/electron';
import MediaLinks from './MediaLinks/MediaLinks';
import Details from './Details/Details';
import { Wrapper } from './styled';

type PersonCardProps = {
  person: PersonAndPresenceResponse;
  locationName?: Nullable<string>;
  hoodName?: Nullable<string>;
  sensorStatus?: Nullable<SENSOR_STATUS>;
};

const PersonCard = ({
  person,
  locationName = null,
  hoodName = null,
  sensorStatus = null,
}: PersonCardProps) => {
  const details = { ...person, locationName, hoodName, sensorStatus };
  const { email, chatLink } = person;
  const isKiosk = isElectron();

  useEffect(() => {
    Analytics.track('EVT-048');

    return () => {
      Analytics.track('EVT-052');
    };
  }, [person]);

  return (
    <Wrapper>
      <Details details={details} />
      {!isKiosk && <MediaLinks email={email} chatLink={chatLink} />}
    </Wrapper>
  );
};

export default memo(PersonCard);
