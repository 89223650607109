import React, { memo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTimeText } from '../../utils';
import { getEventExtraHeight } from '../utils';
import { userConfigSelectors } from '../../../../../store';
import {
  AgendaItemBookedFor,
  AgendaItemTime,
  AgendaItemTitle,
  AgendaWrapperContent,
  AgendaWrapperItem,
  IconWrapper,
  LocalTime,
  useAgendaItemStyle,
} from '../../../Agenda/content/Items/styled';
import Analytics from '../../../../../utils/analytics';
import moment from 'moment-timezone';
import { DateFormat, formatLocalizedDate, isAllDayBookingLocalized } from '@engage-shared/utils';
import { AgendaItem, useBuilding } from '@engage-shared/api';
import { Icon } from '../../../../base';
import { OPT_OUT_DISPLAY_NAME } from '../../../../../constants';

type BookingType = 'Desk' | 'Space' | 'Team';
// TODO: remove added complexity...
const getIconName = (bookingType: BookingType, isDesk: boolean) => {
  switch (bookingType) {
    case 'Desk':
      return 'desk';
    case 'Space':
      return 'space';
    case 'Team':
      return 'teams';
    default:
      return isDesk ? 'desk' : 'space';
  }
};

const AgendaEvent = ({
  event,
  onSelectEvent,
}: {
  event: AgendaItem;
  onSelectEvent: ({ event }: { event: AgendaItem }) => void;
}) => {
  const {
    name,
    summary,
    start,
    end,
    timeZone,
    buildingId,
    isAllDayBooking,
    isBookedForOther,
    reserveeName,
    isDesk,
    bookingType,
    isInInactiveLocation,
  } = event;

  const { t } = useTranslation();
  const displayReserveeName =
    reserveeName === OPT_OUT_DISPLAY_NAME
      ? t('layout.teamMembersList.userHasOptedOut')
      : reserveeName;

  const styles = useAgendaItemStyle(start, end);
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const { data: building } = useBuilding({ buildingId });
  const buildingName = isInInactiveLocation ? null : building?.name;

  const localTimeStr = t('layout.agendaList.localTime');
  const bookedForStr = t('layout.agendaList.bookedFor');

  // get browser timezone; true means ignore cache
  const localTimeZoneString = moment.tz.guess(true);
  const formattedLocalTime = moment.tz(localTimeZoneString).format('z');

  const isLocalTimeSameAsBuilding = localTimeZoneString === timeZone;
  const allDay =
    isAllDayBooking ||
    isAllDayBookingLocalized({
      dateS: start,
      dateE: end,
      timeZone: localTimeZoneString,
    });

  const bookingStartDateTimeString = formatLocalizedDate(start, {
    timeZone: localTimeZoneString,
    locale,
    format: DateFormat.weekDayShortWithTime,
  });

  const buildingStartEndTimeString = getTimeText({
    start,
    end,
    isAllDayBooking: allDay,
    timeZone,
    locale,
  });

  const _onSelectEvent = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    Analytics.track('EVT-078');
    onSelectEvent({ event });
  };

  const extraHeight = getEventExtraHeight(event);

  return (
    <AgendaWrapperItem
      $style={styles.wrapper}
      onClick={_onSelectEvent}
      $extraHeight={extraHeight}
      data-testid="event-item"
      role="input"
      name={`${name || summary}-${buildingName}-${buildingStartEndTimeString}`}
    >
      <AgendaWrapperContent>
        <AgendaItemTitle $style={styles.title}>
          <span data-testid="event-summary">{name || summary}</span>
          {buildingName && (
            <>
              <span className="separator">&ndash;</span>
              <span className="building-name" data-testid="building-name">
                {' '}
                {buildingName}
              </span>
            </>
          )}
        </AgendaItemTitle>
        {isBookedForOther && (
          <AgendaItemBookedFor $style={styles.title}>
            {bookedForStr}: &nbsp; {displayReserveeName}
          </AgendaItemBookedFor>
        )}
        <AgendaItemTime $style={styles.time} data-testid="event-time-text">
          {buildingStartEndTimeString}
        </AgendaItemTime>
        {!isLocalTimeSameAsBuilding && (
          <LocalTime data-testid="local-time-text-wrapper">
            <AgendaItemTime $style={styles.time}>
              {localTimeStr} {bookingStartDateTimeString} ({formattedLocalTime})
            </AgendaItemTime>
          </LocalTime>
        )}
      </AgendaWrapperContent>
      <IconWrapper>
        <Icon name={getIconName(bookingType, isDesk)} size={20} />
      </IconWrapper>
    </AgendaWrapperItem>
  );
};

export default memo(AgendaEvent);
