import React, { memo } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { hasTokens } from '@engage-web/utils/config/tokenConfig';
import { useAppLocation } from '@engage-web/router/hooks';
import { getShouldRedirect, getRedirectPath } from '@engage-web/router/utils';

export const PublicRoute = memo(({ children }: { children: JSX.Element }) => {
  const { search, pathname } = useAppLocation();
  const tenantId = useSelector(tenantSelectors.getTenantId);

  const isAuthenticated = hasTokens();
  const hasTenantId = !!tenantId;
  const shouldRedirect = getShouldRedirect({
    isAuthenticated,
    hasTenantId,
    path: pathname,
  });

  // Prevent authenticated user from navigating to the login or tenant scenes
  return shouldRedirect ? (
    <Navigate
      replace
      to={{
        pathname: getRedirectPath({ isAuthenticated, hasTenantId }),
        search,
      }}
    />
  ) : (
    children
  );
});
