import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { tenantSelectors } from '../../../../../../store';
import {
  ATTENDEE_STATUS,
  bookingDetailsButtonStatuses as STATUSES,
} from '../../../../../../constants';
import { ConfirmationDialog } from '../../../../../base';
import { FocusId, useDialog } from '../../../../../../utils';
import { Button } from '../../../Button';
import { TeamButtonStatus } from '@engage-shared/utils';
import { Theme } from '@engage-shared/theme';
import { declineTeamBooking } from '@engage-shared/api';

type DeclineProps = {
  meetingId: number;
  status: TeamButtonStatus;
  setBookingStatus: (bookingStatus: ATTENDEE_STATUS) => void;
  onBack: () => void;
};
export function Decline({ status, meetingId, setBookingStatus, onBack }: DeclineProps) {
  const { DISPLAY, DISABLED } = STATUSES;
  const { [DISPLAY]: display, [DISABLED]: disabled } = status;

  const { t } = useTranslation();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { openDialog } = useDialog();

  const { color1, color6 } = useTheme() as Theme;

  const onDeclinePress = async () => {
    try {
      await declineTeamBooking({ tenantId, id: meetingId });

      setBookingStatus(ATTENDEE_STATUS.DECLINED);
      onBack();
    } catch (error) {
      openDialog(
        <ConfirmationDialog
          title={t('layout.floorplanError.errorOccurred')}
          message={t('layout.reserve.team.errorOccurred')}
          key="team-booking-decline-error-dialog"
          triggerId={FocusId.TeamBookingDecline}
          hasCancelButton={false}
        />,
      );
    }
  };

  return display ? (
    <Button
      onClick={onDeclinePress}
      disabled={disabled}
      opacity={disabled ? 0.4 : 1}
      color={color6}
      backgroundColor={color1}
      text={t('common.decline')}
    />
  ) : null;
}
