import React, { useCallback, useEffect, useRef } from 'react';
import { FES_EVENTS, MessageType, useFESContext } from '@floorplan/fes';
import { PATH_SEGMENT, RESTRICT_TO_TO_PATH_SEGMENT } from '@engage-web/constants';
import { joinPaths } from '@engage-web/router/utils';
import trackFloorplanActions from '@engage-web/components/new-floorplan/trackFloorplanActions';
import { useDispatch } from 'react-redux';
import { floorplanActions, teamReservationsActions } from '@engage-web/store';
import { useGenerateCurrentLocationPath, useRestrictTo } from '@engage-web/utils';
import { useAppParams } from '@engage-web/router/hooks';
import { useNavigate } from 'react-router-dom';
import { FloorplanData } from '@floorplan/api';

export type UseFesMessageHandler = {
  floorplanData?: FloorplanData;
  startTime: string;
  endTime: string;
};

export const useFesMessageHandler = ({
  floorplanData,
  startTime,
  endTime,
}: UseFesMessageHandler) => {
  const [listenersInitialized, setListenersInitialized] = React.useState(false);
  const dispatch = useDispatch();
  const fes = useFESContext();

  const navigate = useNavigate();
  const restrictTo = useRestrictTo();
  const currentLocationPath = useGenerateCurrentLocationPath();
  const { spaceId, deskId } = useAppParams();
  const lastActiveRestrictToPath = useRef(null);

  useEffect(() => {
    if (!!spaceId || !!deskId) {
      return;
    }

    // track if click on close button should open filters after closing desk/space card
    // @ts-ignore
    lastActiveRestrictToPath.current = RESTRICT_TO_TO_PATH_SEGMENT[restrictTo];
  }, [spaceId, deskId, restrictTo]);

  const openDrawerCard = useCallback(
    ({ path, state }: any) => {
      // remember previous path, so that restrict to filter
      // will not get selected by default (P2-3080), check if we should open filters
      const fromPath = lastActiveRestrictToPath.current
        ? joinPaths(currentLocationPath, lastActiveRestrictToPath.current)
        : currentLocationPath;

      navigate(joinPaths(currentLocationPath, path), {
        state: {
          ...state,
          fromPath,
        },
      });

      trackFloorplanActions(state);
    },
    [currentLocationPath, navigate],
  );

  const onFloorPlanClickHandler = useCallback(
    (data: any) => {
      if (!data) {
        return;
      }
      // Set card layout based on given params
      if (data.isDesk) {
        openDrawerCard({
          path: joinPaths(PATH_SEGMENT.DESKS, data.id),
          state: {
            desk: data,
          },
        });
      } else {
        openDrawerCard({
          path: joinPaths(PATH_SEGMENT.SPACES, data.id),
          state: {
            space: data,
          },
        });
      }
    },
    [openDrawerCard],
  );

  const onMessage = React.useCallback(
    (data: MessageType) => {
      // console.log('onMessage', data);
      switch (data.type) {
        // !first step to set the floorplan data and options
        case FES_EVENTS.INIT:
          setListenersInitialized(true);
          break;
        case FES_EVENTS.SET_VIEWER:
          break;
        case FES_EVENTS.FLOORPLAN_CLICKED: {
          onFloorPlanClickHandler(data.message.space);
          break;
        }
        case FES_EVENTS.READY_FOR_INTERACTION: {
          dispatch(floorplanActions.setIsLoaded(true));
          break;
        }
        case FES_EVENTS.TEAM_RESERVATION_ADD_DESKS: {
          const { desks } = data.message;
          dispatch(teamReservationsActions.addDesksToTeamReservation(desks));
          break;
        }
        default:
      }
    },
    [dispatch, endTime, fes, floorplanData, onFloorPlanClickHandler, startTime],
  );

  return { onMessage, listenersInitialized };
};
