import { Api, getApiUrl } from '@engage-shared/api/instance';
import { FetchConciergeMenuItemsData } from '@engage-shared/api/conciergeMenu/interfaces';
import { LanguageTypes } from '@engage-shared/constants/languages';
import { TenantId } from '@engage-shared/api/tenant/interfaces';
import { MENU_ITEM_TYPES } from '@engage-shared/constants/menuItemTypes';

type FetchMenuItemsParams = {
  tenantId: TenantId;
  locale?: LanguageTypes;
  buildingId?: number;
  type?: MENU_ITEM_TYPES;
};

export const fetchMenuItems = async ({
  tenantId,
  locale = LanguageTypes.enUS,
  buildingId,
  type,
}: FetchMenuItemsParams): Promise<FetchConciergeMenuItemsData> => {
  const url = new URL(`${getApiUrl(tenantId)}/concierge_menu_items`);
  url.searchParams.set('locale', locale);
  if (buildingId) {
    url.searchParams.append('location_id', `b${buildingId}`);
  }
  if (type) {
    url.searchParams.append('type', type);
  }

  const response = await Api.get<FetchConciergeMenuItemsData>(url.toString());
  return response.data;
};
