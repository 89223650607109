import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getAppliedFilterDates } from '@engage-shared/utils';
import { filtersActions } from '@engage-web/store';
import { useDatePicker } from '@engage-web/utils';
import { DATE_TIME_PICKER_VARIANT } from '@engage-web/constants';

type UseDateTimeDataParams = {
  variant: string;
};

export const useDTPConfig = ({ variant }: UseDateTimeDataParams) => {
  const dispatch = useDispatch();

  const isDraft = variant !== DATE_TIME_PICKER_VARIANT.TIMELINE;
  const {
    dateStart,
    defaultStartDate,
    timeZone,
    dateEnd,
    defaultEndDate,
    minDate,
    setTimelineDate,
    setDateStart,
    setDateEnd,
    onClear,
    maxDate,
  } = useDatePicker({ isDraft });

  const setFilterValues = useCallback(
    (values: Record<string, any>) =>
      dispatch(filtersActions.setFilterValues({ ...values, isDraft })),
    [dispatch, isDraft],
  );

  const { dateStartApplied, dateEndApplied } = getAppliedFilterDates({
    dateStart,
    dateEnd,
    setFilterValues,
    defaultStartDate,
    defaultEndDate,
  });

  const setDateStartEnhanced = useCallback(
    (date: Date) => {
      setDateStart(date);
    },
    [setDateStart],
  );

  const { selectedDate, onDateSelected } = {
    selectedDate: {
      [DATE_TIME_PICKER_VARIANT.TIMELINE]: dateStart || defaultStartDate,
      [DATE_TIME_PICKER_VARIANT.INTERVAL_START]: dateStartApplied,
      [DATE_TIME_PICKER_VARIANT.INTERVAL_END]: dateEndApplied,
    },
    onDateSelected: {
      [DATE_TIME_PICKER_VARIANT.TIMELINE]: setTimelineDate,
      [DATE_TIME_PICKER_VARIANT.INTERVAL_START]: setDateStartEnhanced,
      [DATE_TIME_PICKER_VARIANT.INTERVAL_END]: setDateEnd,
    },
  };

  return {
    selectedDate: selectedDate[variant],
    onDateSelected: onDateSelected[variant],
    onClear,
    timeZone,
    minDate,
    maxDate,
  };
};
