import { Reducer } from 'react';
import { ActionFn } from '@engage-web/utils';
import { ClearAction, DeepLinkingAction, DeepLinkingState, SetDataAction } from './types';

export const deepLinkingReducer: Reducer<DeepLinkingState, DeepLinkingAction> = (state, action) => {
  switch (action.type) {
    case 'setData': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'clear':
      return {
        to: null,
        isChecking: false,
      };
    default:
      return state;
  }
};

export const setDeepLinkData: ActionFn<SetDataAction> = payload => ({
  type: 'setData',
  payload,
});

export const clearData: ActionFn<ClearAction> = () => ({
  type: 'clear',
});
