import styled from 'styled-components';

export const MenuCard = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: -28px 40px 60px rgba(10, 38, 50, 0.25);
  border-radius: 6px;
  padding: 80px 24px 24px 45px;
  display: none;
  transition: all 300ms ease-in-out;

  &.drawer-menu-enter {
    display: flex;
    opacity: 0;
  }
  &.drawer-menu-enter-active {
    opacity: 1;
  }
  &.drawer-menu-enter-done {
    display: flex;
  }
  &.drawer-menu-exit {
    display: flex;
    opacity: 1;
  }
  &.drawer-menu-exit-active {
    display: flex;
    opacity: 0;
  }
  &.drawer-menu-exit-done {
    display: none;
  }
`;

export const MenuList = styled.ul`
  margin-top: 20px;
  list-style: none;
  text-align: right;
`;

export const MenuCardItem = styled.li`
  color: ${({ theme }) => theme.typo2};
  font-size: 16px;
  margin: 10px 0;

  a {
    padding: 0;
    color: inherit;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const MenuCardAction = styled.button`
  color: ${({ theme }) => theme.typo2};
  margin: 10px 0;
  cursor: pointer;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }

  :hover {
    text-decoration: underline;
  }
`;
