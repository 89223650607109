import { useEffect } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { isElectron } from '@engage-web/utils/electron';

export const useShowKioskHereMarker = () => {
  const fes = useFESContext();
  const hereMarkerVisible = isElectron();
  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const { kioskLocation, userLocation } = useSelector(tenantSelectors.getKioskConfig);

  const userIsOnKioskFloor = userLocation?.floor?.id === floorId;

  useEffect(() => {
    if (hereMarkerVisible && kioskLocation && userIsOnKioskFloor) {
      fes.trigger(FES_EVENTS.SHOW_HERE_MARKER, { kioskLocation });
    } else {
      fes.trigger(FES_EVENTS.HIDE_HERE_MARKER);
    }
  }, [userIsOnKioskFloor, hereMarkerVisible, kioskLocation, fes]);
};
