import React, { memo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DateFormat, isTimeNow } from '@engage-shared/utils';
import { formatLocalizedDate } from '@engage-shared/utils';
import { userConfigSelectors } from '@engage-web/store';
import { focusElementById, testProps } from '@engage-web/utils';
import { FocusId } from '@engage-web/utils/hooks';
import { DateButtonView } from './DateButtonView';

interface DateButtonProps {
  onDateClear: () => void;
  onClick: () => void;
  selectedDate: Date;
  timeZone: string;
  isOpen: boolean;
  disableInteractions: boolean;
  isTimeFormatH12: boolean;
}

const DateButton = ({
  onDateClear,
  isOpen,
  onClick,
  selectedDate,
  disableInteractions,
  timeZone,
  isTimeFormatH12,
}: DateButtonProps) => {
  const { t } = useTranslation();
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const isNow = isTimeNow(selectedDate);

  // If time format is 24h pass pattern param otherwise use default pattern for 12h time format
  const formattedDate = formatLocalizedDate(selectedDate, {
    timeZone,
    locale,
    format: DateFormat.weekdayShortWithMonthAndDayAndTime,
    hour12: isTimeFormatH12,
  });
  const { props: a11yAndTestProps } = testProps(
    t,
    ['accessibilityLabels.showingAvailabilityFor', 'scenes.wayfinding.now'],
    ([showingAvailabilityFor, now]) =>
      // TODO formatted date is still in user's language
      `${showingAvailabilityFor} ${isNow ? now : formattedDate}`,
  );

  const onDateButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick();
    e.stopPropagation();
  };

  const onDateClearButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDateClear();
    focusElementById(FocusId.TimelineButton);
  };

  return (
    <DateButtonView
      isNow={isNow}
      isOpen={isOpen}
      disableInteractions={disableInteractions}
      onClick={onDateButtonClick}
      id={FocusId.TimelineButton}
      {...a11yAndTestProps}
      clearButtonProps={{
        ...testProps(t, 'accessibilityLabels.now').props,
        onClick: onDateClearButtonClick,
      }}
    >
      {isNow ? t('scenes.wayfinding.now') : formattedDate}
    </DateButtonView>
  );
};

export default memo(DateButton);
