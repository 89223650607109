/**
 * Booking Details card displays these buttons: Delete, Update, Check In and Check Out.
 * 1. Delete:
 * - Display: if user is allowed to change the booking then this button is always displayed
 * - Disabled: booking ended or it is in loading state because of check-in or delete actions undergoing
 * - Loading: delete action started and didn't finish yet
 *
 * 2. Check In:
 * - Display: if check-in is enabled, is user's booking (not one booked for somebody else) and it is a desk booking, because at this moment space bookings check-in functionality is not yet implemented.
 * - Disabled: booking is not in the check-in window or it was already checked in, or it is in a loading state
 * - Loading: check-in action started and didn't finished yet
 *
 * 3. Check Out:
 * - Display: if booking was checked in and booking has started
 * - Disabled: booking finished or it is in a loading state
 * - Loading: check-out action started and didn't finish yet
 *
 * 4. Update: this functionality is currently hidden
 */
import {
  bookingDetailsButtons as BUTTONS,
  bookingDetailsButtonStatuses as STATUSES,
} from '@engage-shared/constants/bookingDetailsButtons';
import { isAfter } from 'date-fns';
import { BookingDetails } from '@engage-shared/api/bookings/interfaces/bookingDetails.type';
import { CHECK_IN_STATUS } from '@engage-shared/constants/checkInStatuses';
import { hasBookingFinished } from '@engage-shared/utils/dates';

export type ButtonStatus = Record<STATUSES, boolean>;
export type BookingDetailsButtons = Record<BUTTONS, ButtonStatus>;

export type CheckInStatusObject = {
  status: CHECK_IN_STATUS;
  hours?: number;
  minutes?: number;
  statusMessage: string;
};
type GetBookingDetailsStatusParams = {
  booking: BookingDetails;
  actionInProgress: string;
  enableDeskCheckIn: boolean;
  statusObj: CheckInStatusObject;
};
type GetBookingDetailsStatus = (params: GetBookingDetailsStatusParams) => BookingDetailsButtons;

export const getBookingDetailsStatus: GetBookingDetailsStatus = ({
  booking,
  actionInProgress,
  enableDeskCheckIn,
  statusObj,
}): BookingDetailsButtons => {
  const { DELETE, CHECK_IN, CHECK_OUT, UPDATE } = BUTTONS;
  const { DISPLAY, DISABLED, LOADING } = STATUSES;

  const {
    startDate,
    endDate,
    isDesk,
    isCheckInAvailable,
    isCheckedIn,
    canUpdate,
    isBookedForOther,
    isInInactiveLocation,
  } = booking;

  const now = new Date();
  const hasFinished = hasBookingFinished(endDate);
  const hasStarted = isAfter(now, startDate);

  const isCheckInFeatureActive = canUpdate && enableDeskCheckIn && isDesk && !isBookedForOther;

  const checkInClosed = statusObj.status === CHECK_IN_STATUS.CHECK_IN_CLOSED;

  return {
    [DELETE]: {
      [DISPLAY]: canUpdate,
      [DISABLED]: hasFinished || !!actionInProgress,
      [LOADING]: actionInProgress === DELETE,
    },
    [CHECK_IN]: {
      [DISPLAY]: isCheckInFeatureActive && (!isCheckedIn || !hasStarted),
      [DISABLED]:
        !isCheckInAvailable ||
        isCheckedIn ||
        hasFinished ||
        checkInClosed ||
        !!actionInProgress ||
        !!isInInactiveLocation,
      [LOADING]: actionInProgress === CHECK_IN,
    },
    [CHECK_OUT]: {
      [DISPLAY]: isCheckInFeatureActive && isCheckedIn && hasStarted,
      [DISABLED]: hasFinished || !!actionInProgress,
      [LOADING]: actionInProgress === CHECK_OUT,
    },
    [UPDATE]: {
      [DISPLAY]: false,
      [DISABLED]: true,
      [LOADING]: false,
    },
  };
};
