import { Api } from '../Api';
import { Booking, FilteredSpaces, Space } from '../types';
import { getFilteredSpaces, getMergedSpaceData } from './utils';
import { getTimeQueryString } from '@engage-shared/api';

const TEAM_BOOKING_NAME = 'Team Booking';

export const createDateQuery = (startTime?: string, endTime?: string) => {
  const query = new URLSearchParams();

  if (startTime || endTime) {
    if (startTime) query.append('startTime', startTime);
    if (endTime) query.append('endTime', endTime);

    return `?${query.toString()}`;
  }

  return query.toString();
};

type FetchSpacesData = (params: {
  floorId: number;
  teamBookingId?: number | null;
  startTime?: string;
  endTime?: string;
}) => Promise<{ data: Space[] }> | undefined;

type FetchBookingsData = (params: {
  floorId: number;
  startTime?: string;
  endTime?: string;
}) => Promise<{ data: Booking[] }> | undefined;

type FilterTeamBookingSpaces = (params: {
  spaces: { data: Space[] };
  startTime?: string;
  endTime?: string;
  teamBookingId: number;
  floorId: number;
}) => Promise<{ data: Space[] }>;

export const fetchBookingsData: FetchBookingsData = ({ floorId, startTime, endTime }) =>
  Api.fetch({
    method: 'GET',
    url: `/engage_api/v1/floors/${floorId}/bookings${getTimeQueryString(startTime, endTime)}`,
  });

export const filterTeamBookingSpaces: FilterTeamBookingSpaces = async ({
  spaces,
  startTime,
  endTime,
  teamBookingId,
  floorId,
}) => {
  const bookings = await fetchBookingsData({ floorId, startTime, endTime });

  // return available spaces data if we can't get bookings in order to not break down floorplan
  if (!bookings) {
    return spaces;
  }

  const teamBookings = bookings.data?.filter(
    // some bookings don't have teamBooking attribute
    booking => booking.teamBooking?.id === teamBookingId,
  );
  const teamBookingSpaceIds = teamBookings.map(({ spaceId }) => spaceId);

  if (teamBookings) {
    const sharedValues = {
      presenceEvents: [],
      sensorStatus: null,
      lastStatusUpdateTime: null,
    };

    return {
      ...spaces,
      data: spaces.data
        .filter(space => teamBookingSpaceIds.includes(space.id))
        .map(space => {
          if (teamBookings.length) {
            const teamBooking = teamBookings.find(({ spaceId }, index) => {
              const matches = spaceId === space.id;
              // for every match remove associated item from team bookings array,
              // so that there are n - 1 team bookings for each match
              if (matches) teamBookings.splice(index, 1);
              return matches;
            });

            if (teamBooking) {
              const hasOccupant = teamBooking.reservee.displayName !== TEAM_BOOKING_NAME;
              const bookable = space.bookable;

              return {
                ...space,
                ...sharedValues,
                available: !hasOccupant,
                bookable: bookable && !hasOccupant,
                isReserved: hasOccupant,
                allowInteraction: bookable,
              };
            }
          }

          return {
            ...space,
            ...sharedValues,
            available: false,
            bookable: false,
            isReserved: false,
            allowInteraction: false,
          };
        }),
    };
  }

  return spaces;
};

export const fetchSpacesData: FetchSpacesData = ({ floorId, startTime, endTime }) =>
  Api.fetch({
    method: 'GET',
    url: `/engage_api/v1/floors/${floorId}/spaces${createDateQuery(startTime, endTime)}`,
  });

export const fetchSpaceAvailabilityData: FetchSpacesData = ({ floorId, startTime, endTime }) =>
  Api.fetch({
    method: 'GET',
    url: `/engage_api/v1/floors/${floorId}/available${createDateQuery(startTime, endTime)}`,
  });

export const fetchDeskAvailabilityData: FetchSpacesData = async ({
  floorId,
  teamBookingId,
  startTime,
  endTime,
}) => {
  const spaces = await Api.fetch({
    method: 'GET',
    url: `/engage_api/v1/floors/${floorId}/available/desks${createDateQuery(startTime, endTime)}`,
  });

  if (teamBookingId) {
    return filterTeamBookingSpaces({
      spaces,
      startTime,
      endTime,
      teamBookingId,
      floorId,
    });
  }

  return spaces;
};

type FetchSpaces = (params: {
  floorId: number;
  teamBookingId?: number | null;
  startTime?: string;
  endTime?: string;
  roomsStartTime?: string;
  roomsEndTime?: string;
}) => Promise<{ spaces: Space[]; filteredSpaces: FilteredSpaces; floorId: number }> | undefined;

export const fetchSpaces: FetchSpaces = async ({
  floorId,
  teamBookingId,
  startTime,
  endTime,
  roomsStartTime,
  roomsEndTime,
}) => {
  const [spacesResponse, roomSpacesResponse, availableSpacesResponse, availableDeskResponse] =
    await Promise.all([
      fetchSpacesData({ floorId, startTime, endTime }),
      fetchSpacesData({ floorId, startTime: roomsStartTime, endTime: roomsEndTime }),
      fetchSpaceAvailabilityData({
        floorId,
        startTime: roomsStartTime,
        endTime: roomsEndTime,
      }),
      fetchDeskAvailabilityData({
        floorId,
        teamBookingId,
        startTime,
        endTime,
      }),
    ]);

  if (
    !spacesResponse ||
    !availableSpacesResponse ||
    !availableDeskResponse ||
    !roomSpacesResponse
  ) {
    return {
      floorId,
      spaces: [],
      filteredSpaces: {
        zones: [],
        occupantPoints: [],
        nonOccupantPoints: [],
        temporaryOccupantPoints: [],
        rooms: [],
        occupantRoomPoints: [],
        temporaryOccupantRooms: [],
        special: {},
      } as FilteredSpaces,
    };
  }

  const allSpaces = spacesResponse.data;
  const allSpacesForRooms = roomSpacesResponse.data;
  const availableSpaces = availableSpacesResponse.data;
  const availableDesks = availableDeskResponse.data;

  const spaces = getMergedSpaceData({
    allSpaces,
    allSpacesForRooms,
    availableSpaces,
    availableDesks,
  });
  const filteredSpaces = getFilteredSpaces(spaces, startTime);

  return {
    floorId,
    spaces,
    filteredSpaces,
  };
};
