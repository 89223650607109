import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_QUERY, MODALS } from '@engage-web/constants';
import { navigationSelectors } from '@engage-web/store';
import { useModal } from '@engage-web/utils';

/**
 * This hook opens/closes booking details modal (team and space)
 * based on search query parameters.

 * Previously this was a part of `useModalLayout`, but since that hook
 * was called in multiple places – duplicated actions were dispatched.
 */
export function useModalSearchQuery() {
  const { openModal, closeModal } = useModal();
  const { layout } = useSelector(navigationSelectors.getModalLayout);
  const [query] = useSearchParams();

  const bookingSpaceId = query.get(SEARCH_QUERY.BOOKING_SPACE);
  const bookingMeetingId = query.get(SEARCH_QUERY.BOOKING_MEETING);
  const teamBookingMeetingId = query.get(SEARCH_QUERY.TEAM_BOOKING_MEETING);
  const attendeeId = Number(query.get(SEARCH_QUERY.ATTENDEE_ID));

  const showBooking = (!!bookingSpaceId && !!bookingMeetingId) || !!teamBookingMeetingId;

  useEffect(() => {
    if (
      layout !== MODALS.AGENDA_ITEM &&
      layout !== MODALS.TEAM_AGENDA_ITEM &&
      showBooking &&
      layout !== MODALS.BOOKING_LOCATION_UNAVAILABLE &&
      layout !== MODALS.HEALTH_DECLARATION
    ) {
      if (teamBookingMeetingId) {
        openModal(MODALS.TEAM_AGENDA_ITEM, {
          event: {
            meetingId: teamBookingMeetingId,
            attendeeId,
          },
        });
      } else {
        openModal(MODALS.AGENDA_ITEM, {
          event: {
            spaceId: bookingSpaceId,
            meetingId: bookingMeetingId,
            teamBookingMeetingId,
          },
        });
      }
    }
  }, [
    bookingMeetingId,
    bookingSpaceId,
    teamBookingMeetingId,
    attendeeId,
    layout,
    showBooking,
    openModal,
  ]);

  useEffect(() => {
    if (layout && layout === MODALS.AGENDA_ITEM && !showBooking) {
      closeModal();
    }
  }, [closeModal, layout, showBooking]);
}
