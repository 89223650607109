import { isAfter, isBefore } from 'date-fns';
import { ISODateString, Nullable } from '@engage-shared/utils/types';
import {
  getDeskCheckInWindowDates,
  getReservationEndDate,
  isSameLocalizedDay,
} from '@engage-shared/utils/dates';
import { TenantParsedData } from '@engage-shared/api/tenant/interfaces';

type GetShouldShowHealthDeclarationOnBookingArgs = {
  tenantConfig: TenantParsedData;
  dateStart: Nullable<ISODateString>;
  dateEnd: Nullable<ISODateString>;
  isMultiDay: boolean;
  allDay: boolean;
  selectedDays: ISODateString[];
  showDaysSelector: boolean;
  showDurationSelection: boolean;
  timeZone: string;
  duration?: number;
  bookingIntervalSize?: number;
  selectedDay?: ISODateString;
};

type GetShouldShowHealthDeclarationOnBooking = (
  args: GetShouldShowHealthDeclarationOnBookingArgs,
) => boolean;

/**
 * Check if health declaration screen should be shown before making a reservation.
 * `true` should be returned if the current time is between check-in open window and booking end time,
 * `false` in any other case.
 *
 * 1. Single day reservation:
 *  a) If start date is not provided (null) that means booking is being made for now – return `true`.
 *  b) Otherwise – proceed with checking.
 *
 * 2. Multi day reservation:
 *  a) If today's day is not selected – return `false`, otherwise proceed with checking.
 *  b) If start date is not provided (null) that means that booking is being made for now – return `true`.
 *  c) Otherwise – proceed with checking.
 */
export const getShouldShowHealthDeclarationOnBooking: GetShouldShowHealthDeclarationOnBooking = ({
  tenantConfig,
  isMultiDay,
  dateStart,
  dateEnd,
  allDay,
  duration = 0,
  bookingIntervalSize = 15,
  selectedDay,
  selectedDays,
  showDaysSelector,
  showDurationSelection,
  timeZone,
}) => {
  if (isMultiDay) {
    const sortedSelectedDays = [...selectedDays].sort(
      // make sure selected days are sorted according to date-time
      (dayA, dayB) => new Date(dayA).getTime() - new Date(dayB).getTime(),
    );
    const isTodaySelected = isSameLocalizedDay(
      new Date(),
      new Date(sortedSelectedDays[0]),
      timeZone,
    );

    if (!isTodaySelected) return false;
  }

  // if dateStart is missing it means that now is selected on the timeline
  // in this case we should show the health declaration
  if (!dateStart) return true;

  const checkInConfig = {
    enableDeskCheckIn: tenantConfig.enableDeskCheckIn,
    enableSpaceCheckIn: tenantConfig.enableSpaceCheckIn,
    deskCheckInOpenWindow: tenantConfig.deskCheckInOpenWindow,
    deskCheckInWindowDuration: tenantConfig.deskCheckInWindowDuration,
    allDayReservationCheckInStartTime: tenantConfig.allDayReservationCheckInStartTime,
  };

  const { checkInOpenDate } = getDeskCheckInWindowDates({
    tenantConfig: checkInConfig,
    reservationStartDate: dateStart,
    timeZone,
    isAllDayBooking: allDay,
  });

  // not sure what would be right to do if checkInOpenDate is null
  // so, for now just return false
  if (!checkInOpenDate) return false;

  const now = new Date();
  const reservationEndDate = getReservationEndDate({
    dateStart: dateStart ? new Date(dateStart) : null,
    dateEnd: dateEnd ? new Date(dateEnd) : null,
    allDay,
    duration,
    bookingIntervalSize,
    selectedDay,
    showDaysSelector,
    showDurationSelection,
    timeZone,
  });

  return isAfter(now, checkInOpenDate.toDate()) && isBefore(now, reservationEndDate);
};
