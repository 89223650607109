interface IAzureTenants {
  [tenantName: string]: { queryParam: string };
}

export const AZURE_TENANTS: IAzureTenants = {
  uhg: {
    queryParam: 'spg_engage_token',
  },
  'uhg.uat': {
    queryParam: 'spg_engage_token',
  },
  'engage1.tst': {
    queryParam: 'idToken',
  },
};

export const getQueryParamForTenant = (tenantId: string): string =>
  AZURE_TENANTS[tenantId]?.queryParam ?? '';
