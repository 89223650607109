import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    flex-basis: 100%;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & .title {
    color: ${({ theme }) => theme.typo2};
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Options = styled.div`
  display: flex;
  flex: 1 2 auto;
  align-self: auto;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
`;

export const Option = styled.div`
  margin: 0 1em;
`;

export const Label = styled.label`
  color: ${props => props.theme.typo2};
  font-size: 16px;
  line-height: 22px;
`;

export const Footer = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: space-between;
  margin: 15px 0;
`;
