import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from '@engage-web/components/base';
import { SkeletonItemWrapper, SkeletonWrapper } from './styled';

const items = new Array(5).fill(null);

export function DeskListSkeleton() {
  const renderItem = (_: null, index: number) => (
    <SkeletonItemWrapper key={index}>
      <Skeleton circle height={34} width={34} />
      <Skeleton width={100} />
    </SkeletonItemWrapper>
  );

  return (
    <SkeletonTheme>
      <SkeletonWrapper>{items.map(renderItem)}</SkeletonWrapper>
    </SkeletonTheme>
  );
}
