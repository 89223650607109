import React, { ChangeEvent, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { testProps, isInputValid } from '@engage-web/utils';
import { Icon } from '../Icons/Icon';
import {
  IconHit,
  Icons,
  IconWrapper,
  Number,
  NumberInputCounterElement,
  NumberInputCounterWrapper,
  SearchFilterInputLabel,
  Splitter,
  Wrapper,
} from './styled';

interface NumberInputCounterProps {
  label?: string;
  value?: number;
  minValue?: number;
  maxValue?: number;
  onChange?: (value: number) => void;
}

const NumberInputCounter = ({
  label = '',
  value = 0,
  onChange,
  maxValue = 999,
  minValue = 0,
}: NumberInputCounterProps) => {
  const { t } = useTranslation();
  const inputNumberCounterRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputNumberCounterRef.current) {
      inputNumberCounterRef.current.value = `${value}`;
    }
  }, [value]);

  const validatedOnChange = (inputValue: number) => {
    if (isInputValid(inputValue, maxValue, minValue)) {
      onChange?.(inputValue);
    }
  };

  const onInputNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputNumberCounterRef.current) {
      inputNumberCounterRef.current.value = e.target.value;
      const number = parseInt(inputNumberCounterRef.current.value, 10);
      validatedOnChange(number);
    }
  };

  const onFocusOutInput = () => {
    if (inputNumberCounterRef.current) {
      const inputValue = inputNumberCounterRef.current.value
        ? inputNumberCounterRef.current.value
        : '1';
      validatedOnChange(parseInt(inputValue, 10));
    }
  };

  return (
    <Wrapper>
      <SearchFilterInputLabel htmlFor={label}>{label}</SearchFilterInputLabel>
      <NumberInputCounterElement>
        <NumberInputCounterWrapper>
          <Number
            data-testid="numberInputCounter"
            type="number"
            id={label}
            ref={inputNumberCounterRef}
            onChange={onInputNumber}
            onBlur={onFocusOutInput}
            min="1"
            value={value || 1}
            aria-label={`${value}`}
          />
          <Icons>
            <IconWrapper>
              <Icon name="arrow-upward" size={10} />
            </IconWrapper>
            <Splitter />
            <IconWrapper>
              <Icon name="arrow-downward" size={10} />
            </IconWrapper>
          </Icons>
        </NumberInputCounterWrapper>
        <IconHit
          style={{ top: 0 }}
          onClick={() => validatedOnChange(value + 1)}
          {...testProps(t, 'accessibilityLabels.counterIncrementValue').props}
        />
        <IconHit
          style={{ top: 20 }}
          onClick={() => validatedOnChange(value - 1)}
          {...testProps(t, 'accessibilityLabels.counterDecrementValue').props}
        />
      </NumberInputCounterElement>
    </Wrapper>
  );
};

export default memo(NumberInputCounter);
