import { BaseSVLivePresence } from '@engage-shared/api/people/interfaces';
import { PresenceStatus } from '@engage-shared/constants/person';
import { Nullable } from '@engage-shared/utils/types';

type GetPersonPresenceEventSource = (params: {
  presenceEvent: Nullable<BaseSVLivePresence>;
  floorplanZoneId: number;
}) => Nullable<PresenceStatus>;

/**
 * Returns person's presence status by presenceEvent
 * @param presenceEvent presence event object
 * @param floorplanZoneId
 * @returns {PresenceStatus}
 */
export const getPersonPresenceEventSource: GetPersonPresenceEventSource = ({
  presenceEvent,
  floorplanZoneId,
}) => {
  const containerId = presenceEvent?.containerId;
  const presenceStatus = presenceEvent?.presenceStatus;

  return containerId && presenceStatus
    ? containerId !== floorplanZoneId
      ? presenceStatus
      : PresenceStatus.WIFI
    : null;
};
