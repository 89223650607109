import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Wrapper } from '@engage-web/components/scenes/Main/styled';
import { tenantSelectors } from '@engage-web/store';
// import Floorplan from '@engage-web/components/floorplan';
import { FloorplanHandler as Floorplan } from '@engage-web/components/new-floorplan/FloorplanHandler';
import { NoLocationBackground } from '@engage-web/components/layouts';
import { Modals } from '@engage-web/components/scenes/modals';
import { useInitNotifications } from '@engage-web/utils';
import { useAppAccess } from '@engage-web/utils/hooks/useAppAccess';
import { CornerGradient } from '@engage-web/components/base';
import { useShowSocialDistancingInfo } from '@engage-web/components/scenes/Main/hooks';
import { useBookingsAllowedNotification } from '@engage-web/utils/hooks';
import useIdleDialog from '@engage-web/utils/hooks/useIdleDialog';

const Main = () => {
  const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
  // it's unlikely that there will be no location when this component is rendered,
  // but I'll leave it here for now as a precaution
  const hasLocation = !!(building && floor);

  useIdleDialog();

  // Initialise notifications
  useInitNotifications();
  // Check if user has access to the app
  useAppAccess();
  // Show social distancing info if needed
  useShowSocialDistancingInfo();
  // Notify user when bookings are not allowed for him
  useBookingsAllowedNotification({
    notificationAllowed: hasLocation,
  });

  return (
    <Wrapper data-testid="main-screen">
      <CornerGradient />
      <Modals />
      {hasLocation ? <Floorplan /> : <NoLocationBackground />}
      {/* Drawer is rendered in this outlet */}
      <Outlet />
    </Wrapper>
  );
};

export default Main;
