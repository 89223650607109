import styled from 'styled-components';

export const ModalButtonWrapper = styled.div`
  display: inline-block;
  border-radius: 99px;
  text-align: center;
  padding-left: 0;
  background-color: ${({ theme }) => theme.color10};
  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.3);
  :focus-within {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const ModalStyledButton = styled.button`
  cursor: pointer;
  padding: 22px 105px;
`;

export const ModalButtonText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.typo1};
`;
