import { Nullable } from '@engage-shared/utils/types';
import { createContext, ReactElement, useContext } from 'react';

type DialogContextType<Response> = {
  isOpen: boolean;
  openDialog: (dialog: ReactElement) => Promise<Response>;
  closeDialog: (response: Response) => void;
};

export const DialogContext = createContext<Nullable<DialogContextType<any>>>(null);

export function useDialog<ResponseType = boolean>() {
  const context = useContext(DialogContext);

  if (!context && __DEV__) {
    throw new Error('useDialog must be used inside of DialogContext');
  }

  return context as DialogContextType<ResponseType>;
}
