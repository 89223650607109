import { Nullable } from '@engage-shared/utils/types';

export type Token = Nullable<string>;

export type Tokens = {
  token: Token;
  refreshToken: Token;
};

export type Passcode = Nullable<{
  value: Nullable<string>;
  salt: Nullable<string>;
  sessionStartTime: Nullable<string>;
  noOfAttempts: number;
}>;

export type TokensAndPasscode = Tokens & {
  passcode?: Nullable<Passcode>;
};

export enum TokenManagerSubscribeTypes {
  SET = 'set',
  REVOKE = 'revoke',
}
