import React from 'react';
import { Space } from '@floorplan/api';
import { Shape } from '../../../components';
import { pointSizes } from '../styles';
import { getKey } from '../utils';
import { ThemeKey, ThemeManager } from '../../../theme';
import { ElementsProps, EventInfo } from '../../../types';
import { MarkerIcon } from './MarkerIcon';

interface SelectedSpacesProps extends ElementsProps {
  selectedSpaces: Space[];
  selectedPerson: any;
}
export const SelectedSpaces = ({
  selectedSpaces,
  selectedPerson,
  scale,
  onShapeClicked,
}: SelectedSpacesProps) => {
  const getPointSize = () => pointSizes('important');

  const styleFunction = () => {
    return ThemeManager.getStyle(ThemeKey.focusedSpace);
  };

  const onSelectedSpaceClick = (event: EventInfo) =>
    event.space.allowInteraction && onShapeClicked && onShapeClicked(event);

  return (
    <>
      {selectedSpaces.map(space => (
        <Shape
          shape={space.shapes[0] || space.shape}
          space={space}
          scale={scale}
          styleFunction={styleFunction}
          getPointSize={getPointSize}
          isSelected
          key={getKey(space.id, 1)}
          onShapeClicked={onSelectedSpaceClick}
        />
      ))}
      {selectedSpaces.length === 1 && !selectedSpaces[0].shapes[0].isPolygon && (
        <MarkerIcon space={selectedSpaces[0]} person={selectedPerson} scale={scale} />
      )}
    </>
  );
};
