import React, { HTMLProps, MouseEvent, KeyboardEvent } from 'react';

type ButtonProps = HTMLProps<HTMLDivElement>;

export const Button = ({ children, onClick, disabled, ...restProps }: ButtonProps) => {
  const onClickHandler = (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return;
    onClick?.(e as MouseEvent<HTMLDivElement>);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    const enterOrSpace =
      e.key === 'Enter' ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.which === 13 ||
      e.which === 32;
    if (enterOrSpace) {
      e.preventDefault();
      onClickHandler(e);
    }
  };

  return (
    <div
      tabIndex={0}
      role="button"
      aria-disabled={disabled}
      onClick={onClickHandler}
      onKeyPress={onKeyPress}
      {...restProps}
    >
      {children}
    </div>
  );
};
