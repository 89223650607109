import { getFileExtension, getFileNameFromUrl, isBoolean, isStringNumeric } from '../../utils';
import { languages, LanguageTypes } from '@engage-shared/constants/languages';
import { ACKNOWLEDGEMENT } from '../../constants';
import { getLogger } from '@engage-shared/utils/logger';
import { Nullable } from '@engage-shared/utils/types';
import { ConfigMethod, StringOrBoolean, TenantConfig } from '@engage-shared/api/tenant/interfaces';
import {
  isValidEmail,
  isValidPhone,
  isValidTime,
  isValidUrl,
} from '@engage-shared/utils/validators';
import { isValidHexColor } from '@engage-shared/theme';

const convertTruncatedBool = (value: StringOrBoolean): StringOrBoolean => {
  let convertedValue = value;
  if (value === 't') {
    convertedValue = 'true';
  }
  if (value === 'f') {
    convertedValue = 'false';
  }
  return convertedValue;
};

const parseValue: (value: StringOrBoolean) => Nullable<StringOrBoolean> = (
  value: StringOrBoolean,
) => {
  // Temporary fix to convert t/f to true/false
  const configValue = convertTruncatedBool(value);
  let parsedValue = configValue === '' ? null : configValue;
  if (isBoolean(configValue) || isStringNumeric(configValue)) {
    // Using try here so that an error will not prevent the configuration from loading
    try {
      if (typeof configValue === 'string') {
        parsedValue = JSON.parse(configValue);
      }
    } catch (ex) {
      getLogger().error(`Error parsing tenant config value ${configValue}`);
    }
  }
  return parsedValue;
};

const parseLanguages = (value: string): object => {
  return value.split(',').reduce<Record<string, string>>((acc, langKey) => {
    if (languages[langKey]) {
      acc[langKey] = languages[langKey];
    }
    return acc;
  }, {});
};

const fileHasExtension = (url: string, extensions: string[]): boolean => {
  const fileName = getFileNameFromUrl(url);
  const fileExt = getFileExtension(fileName);
  return extensions.includes(fileExt);
};

const getClampValue = (value: number, min: number, max: number): number =>
  Math.max(Math.min(value, Math.max(min, max)), Math.min(min, max));

const getStringValue = (value: string, max?: number): string => {
  const stringValue = max && value.length > max ? `${value.slice(0, max - 3)}...` : value;
  return stringValue.trim();
};

export const config: TenantConfig = {
  1523: {
    configMethod: ConfigMethod.isCleverSocialDistancingActive,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4871: {
    configMethod: ConfigMethod.enforceAllDayDeskReservations,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4875: {
    configMethod: ConfigMethod.allDayReservationCheckInStartTime,
    defaultValue: '00:00',
    getValue: (value: string) => (isValidTime(value) ? value : ''),
  },
  4876: {
    configMethod: ConfigMethod.concurrentDeskReservations,
    defaultValue: 10,
    getValue: (value: number) => getClampValue(value, 10, 100),
  },
  4877: {
    configMethod: ConfigMethod.usePasscode,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4881: {
    configMethod: ConfigMethod.highlightReservedDesks,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4886: {
    configMethod: ConfigMethod.disableWebGeneralAccess,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4887: {
    configMethod: ConfigMethod.disableAppGeneralAccess,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4888: {
    configMethod: ConfigMethod.hideContactInformation,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  5135: {
    configMethod: ConfigMethod.welcomeTitle,
    defaultValue: '',
    getValue: (value: string) => getStringValue(value, 30),
  },
  5136: {
    configMethod: ConfigMethod.welcomeMessage,
    defaultValue: '',
    getValue: (value: string) => getStringValue(value, 100),
  },
  5137: {
    configMethod: ConfigMethod.enableDeskCheckIn,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  5138: {
    configMethod: ConfigMethod.deskCheckInOpenWindow,
    defaultValue: 30,
    getValue: (value: number) => getClampValue(value, 0, 10080),
  },
  5146: {
    configMethod: ConfigMethod.deskCheckInWindowDuration,
    defaultValue: 120,
    getValue: (value: number) => getClampValue(value, 0, 10080),
  },
  5140: {
    configMethod: ConfigMethod.enableSpaceCheckIn,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  5141: {
    configMethod: ConfigMethod.spaceCheckInOpenWindow,
    defaultValue: 15,
    getValue: (value: number) => getClampValue(value, 0, 10080),
  },
  5142: {
    configMethod: ConfigMethod.spaceCheckInCloseWindow,
    defaultValue: 30,
    getValue: (value: number) => getClampValue(value, 0, 1440),
  },
  5143: {
    configMethod: ConfigMethod.futureBookingsLimit,
    defaultValue: 12,
    getValue: (value: number) => getClampValue(value, 1, 52),
  },
  6621: {
    configMethod: ConfigMethod.instanceTitle,
    defaultValue: '',
    getValue: (value: string) => getStringValue(value),
  },
  7591: {
    configMethod: ConfigMethod.poiFireWarden,
    defaultValue: '',
    getValue: (value: string) => value.split(','),
  },
  7592: {
    configMethod: ConfigMethod.poiFirstAidOfficer,
    defaultValue: '',
    getValue: (value: string) => value.split(','),
  },
  7858: {
    configMethod: ConfigMethod.sendConfirmationEmails,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  9255: {
    configMethod: ConfigMethod.deskBookingInterval,
    defaultValue: 15,
    getValue: (value: number) => getClampValue(value, 5, 60),
  },
  9256: {
    configMethod: ConfigMethod.meetingRoomBookingInterval,
    defaultValue: 15,
    getValue: (value: number) => getClampValue(value, 5, 60),
  },
  9259: {
    configMethod: ConfigMethod.logo,
    defaultValue: null,
    getValue: (value: string) => (value && fileHasExtension(value, ['svg']) ? value : null),
  },
  9260: {
    configMethod: ConfigMethod.coverImage,
    defaultValue: null,
    getValue: (value: string) =>
      value && fileHasExtension(value, ['jpg', 'png', 'mp4']) ? value : null,
  },
  9261: {
    configMethod: ConfigMethod.supportEmail,
    defaultValue: '',
    getValue: (value: string) => (isValidEmail(value) ? value : ''),
  },
  9262: {
    configMethod: ConfigMethod.supportPhoneNumber,
    defaultValue: '',
    getValue: (value: string) => (isValidPhone(value) ? value : ''),
  },
  9263: {
    configMethod: ConfigMethod.supportUrl,
    defaultValue: '',
    getValue: (value: string) => (isValidUrl(value) ? value : ''),
  },
  9264: {
    configMethod: ConfigMethod.brandColor,
    defaultValue: '#EA7723',
    getValue: (value: string) => (isValidHexColor(value) ? value : ''),
  },
  9269: {
    configMethod: ConfigMethod.ssoEnabled,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  9280: {
    configMethod: ConfigMethod.useZoomTiles,
    defaultValue: true,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  9932: {
    configMethod: ConfigMethod.useFloorPlanAvailableColor,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  9933: {
    configMethod: ConfigMethod.floorPlanAvailableColor,
    defaultValue: '#2CA94C',
    getValue: (value: string) => (isValidHexColor(value) ? value : ''),
  },
  3603: {
    configMethod: ConfigMethod.acknowledgementValue,
    defaultValue: ACKNOWLEDGEMENT.NEVER,
    getValue: (value: string) => parseValue(value),
  },
  3604: {
    configMethod: ConfigMethod.acknowledgementMessage,
    defaultValue: ACKNOWLEDGEMENT.DAILY,
    getValue: (value: string) => parseValue(value),
  },
  3605: {
    configMethod: ConfigMethod.acknowledgementUrl,
    defaultValue: ACKNOWLEDGEMENT.ONCHANGE,
    getValue: (value: string) => parseValue(value),
  },
  4334: {
    configMethod: ConfigMethod.enableHealthDeclaration,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4335: {
    configMethod: ConfigMethod.healthDeclaration,
    defaultValue: '',
    getValue: (value: string) => parseValue(value),
  },
  4337: {
    configMethod: ConfigMethod.availableLanguages,
    defaultValue: parseLanguages(LanguageTypes.enUS),
    getValue: (value: string) => parseLanguages(value),
  },
  4892: {
    configMethod: ConfigMethod.allowUsersToOptOut,
    defaultValue: true,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4345: {
    configMethod: ConfigMethod.acknowledgementTitle,
    defaultValue: 'Acknowledgement',
    getValue: (value: string) => parseValue(value),
  },
  4350: {
    configMethod: ConfigMethod.enforceBookingsAllowed,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  4351: {
    configMethod: ConfigMethod.bookingDeniedMessage,
    defaultValue: 'access to booking denied',
    getValue: (value: string) => parseValue(value),
  },
  6098: {
    configMethod: ConfigMethod.hideCalendarReservationControls,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
  6110: {
    configMethod: ConfigMethod.kioskRestrictBookingToCurrentDay,
    defaultValue: false,
    getValue: (value: StringOrBoolean) => parseValue(value),
  },
};
