import React, { useContext, useEffect } from 'react';
import useReservationFilters from '@engage-web/utils/hooks/useReservationFilters';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { inAppNotificationsActions } from '@engage-web/store';
import { useMakeReservation } from '@engage-web/components/layouts/Reserve/Card/hooks/useMakeReservation';
import { isElectron } from '@engage-web/utils/electron';
import { useDialog } from '@engage-web/utils';
import { KioskMakeReservationDialog } from '@engage-web/components/kiosk';
import { useTimer } from '@engage-web/utils/hooks/useTimer';
import { getReserveBtnText } from '../../reserveCardHelpers';
import { ReserveCardContext } from '../ReserveCardContext';
import { ReserveButton as StyledReserveButton } from '../../styled';

export const ReserveButton = () => {
  const {
    spaceItem,
    reserveButtonRef,
    isOccupied,
    durationObj,
    spaceStatusInfo,
    reservee,
    reserveDisabled,
    bookingsAllowed,
    bookingDeniedMessage,
  } = useContext(ReserveCardContext);

  const isKiosk = isElectron();
  const { isDesk, bookable } = spaceItem;
  const { duration, selectedDays } = durationObj;

  const reservationFilter = useReservationFilters(isDesk);
  const { enforceAllDayDeskReservations } = reservationFilter;

  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const { openDialog, closeDialog } = useDialog<void>();

  useEffect(() => {
    if (!reservee?.id) return;
    if (!bookingsAllowed) {
      reduxDispatch(
        inAppNotificationsActions.addWarningNotification({
          message: bookingDeniedMessage,
        }),
      );
    }
  }, [bookingDeniedMessage, bookingsAllowed, reduxDispatch, reservee]);

  const isMultiDayDeskReservation = isDesk && enforceAllDayDeskReservations;

  const daysAreNotSelected = !selectedDays.length && isMultiDayDeskReservation;

  const { makeReservation, isLoading } = useMakeReservation();

  const onTimeOver = () => {
    makeReservation();
    closeDialog();
  };

  const { activateTimer, cancelTimer } = useTimer({ seconds: 5, onTimeOver });

  const onClickHandler = () => {
    if (isKiosk) {
      activateTimer();
      openDialog(
        <KioskMakeReservationDialog
          spaceNames={[spaceItem.name]}
          onOkPress={() => {
            makeReservation();
            closeDialog();
            cancelTimer();
          }}
          onUndoPress={() => {
            closeDialog();
            cancelTimer();
          }}
        />,
      );
      return;
    }
    makeReservation();
  };

  const reserveBtnText = getReserveBtnText({ isOccupied, isLoading, t });

  const reserveButtonDisabled =
    (!isDesk && !duration) ||
    spaceStatusInfo?.disableReservation ||
    reserveDisabled ||
    !bookable ||
    isLoading ||
    isOccupied ||
    daysAreNotSelected ||
    !bookingsAllowed;

  return (
    <StyledReserveButton
      ref={reserveButtonRef}
      disabled={reserveButtonDisabled}
      onClick={onClickHandler}
      className="ReserveButton"
    >
      {reserveBtnText}
    </StyledReserveButton>
  );
};
