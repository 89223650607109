import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { addMinutes, isDate, isToday, toDate } from 'date-fns';
import {
  formatLocalizedTime,
  formatWeekDayLongWithMonthAndDay,
  getDayEndTime,
  getDayStartTime,
  isSameLocalizedDay,
} from '@engage-shared/utils';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from '@engage-web/store';
import { Icon } from '../../Icons/Icon';
import { DatesWrapper, IconWrapper, LineWrapper, Splitter, Wrapper } from './styled';

interface DateCardProps {
  dateStart: Date;
  dateEnd: Date;
  allDay?: boolean;
  color?: string;
  localTimeZone: string;
  hasLimitPer15Minutes?: boolean;
}

const DateCard = ({
  dateStart,
  dateEnd,
  allDay = false,
  localTimeZone,
  color,
  hasLimitPer15Minutes = false,
}: DateCardProps) => {
  const { t } = useTranslation();
  const { color1, typo2 } = useTheme();
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const textStyle = { color: color || typo2 };

  if (!dateStart && !dateEnd && !allDay) {
    return null;
  }

  let dateS = dateStart && toDate(dateStart);
  let dateE = dateEnd && toDate(dateEnd);

  if (allDay) {
    if (hasLimitPer15Minutes) {
      dateS = isDate(dateStart) ? dateStart : new Date();
    } else {
      dateS = getDayStartTime({
        date: isDate(dateStart) ? dateStart : new Date(),
        timeZone: localTimeZone,
      });
    }

    dateE = getDayEndTime({
      date: isDate(dateEnd) ? dateEnd : new Date(),
      timeZone: localTimeZone,
    });
  } else {
    // we're adding 1 minute to end time because we subtract 1 minute from end time on booking creation phase
    // this was done in order to avoid 09:00 - 09:29 displaying
    dateE = addMinutes(dateE, 1);
  }
  const oneLine = isSameLocalizedDay(dateS, dateE, localTimeZone);

  const todayText = t('components.dateCard.today');
  const atText = t('components.dateCard.at');
  const allDayText = t('components.dateCard.allDay');
  const from = t('components.dateCard.from');

  let dateText = isToday(dateS)
    ? todayText
    : formatWeekDayLongWithMonthAndDay(dateS, {
        timeZone: localTimeZone,
        locale,
      });
  let dateEndText = '';

  if (oneLine) {
    if (allDay) {
      dateText += `, ${allDayText}`;
      if (hasLimitPer15Minutes) {
        dateText += `, ${from} ${formatLocalizedTime(dateS, {
          timeZone: localTimeZone,
          locale,
        })}`;
      }
    } else {
      dateText += `, ${formatLocalizedTime(dateS, {
        timeZone: localTimeZone,
        locale,
      })} - ${formatLocalizedTime(dateE, {
        timeZone: localTimeZone,
        locale,
      })}`;
    }
  } else {
    if (allDay) {
      dateText += `, ${allDayText}`;
      if (hasLimitPer15Minutes) {
        dateText += `, ${from} ${formatLocalizedTime(dateS, {
          timeZone: localTimeZone,
          locale,
        })}`;
      }
    } else {
      dateText += ` ${atText} ${formatLocalizedTime(dateS, {
        timeZone: localTimeZone,
        locale,
      })}`;
    }

    if (dateE) {
      if (isToday(dateE)) {
        dateEndText = `${todayText}, ${allDayText}`;
      } else {
        dateEndText = formatWeekDayLongWithMonthAndDay(dateE, {
          timeZone: localTimeZone,
          locale,
        });
        dateEndText += allDay
          ? `, ${allDayText}`
          : ` ${atText} ${formatLocalizedTime(dateE, {
              timeZone: localTimeZone,
              locale,
            })}`;
      }
    }
  }

  const showFromTo = !!dateS && !!dateE && !oneLine;
  return (
    <Wrapper>
      <IconWrapper data-testid="dateCard-calendar-icon">
        <Icon name="calendar" size={18} style={{ color: color || color1 }} />
        {showFromTo && <Splitter />}
      </IconWrapper>
      <DatesWrapper>
        <LineWrapper>
          {showFromTo && (
            <div className="date-card-label" style={textStyle}>
              {`${t('common.from')}:`}
            </div>
          )}
          <div className="date-card-value" style={textStyle} data-testid="dateCard-from-date">
            {dateText}
          </div>
        </LineWrapper>
        {showFromTo && (
          <LineWrapper style={{ marginTop: 10 }}>
            <div className="date-card-label" style={textStyle}>
              {`${t('common.to')}:`}
            </div>
            <div className="date-card-value" style={textStyle} data-testid="dateCard-to-date">
              {dateEndText}
            </div>
          </LineWrapper>
        )}
      </DatesWrapper>
    </Wrapper>
  );
};

export default memo(DateCard);
