import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Wrapper, Title, CommonMessage, PrimaryButton } from './styled';

const Message = styled(CommonMessage)`
  line-height: 42px;
  margin-bottom: 28px;
  margin-left: 4px;
  margin-right: 4px;
`;

type AvailableKiosksErrorDialogProps = {
  title?: string;
  onRetryPress: () => void;
};

const AvailableKiosksErrorDialog = ({ title, onRetryPress }: AvailableKiosksErrorDialogProps) => {
  const { t } = useTranslation();

  const _title = title ?? t('components.configDialogError.title');

  return (
    <Wrapper data-testid="availableKiosksErrorWrapper">
      <Title>{_title}</Title>
      <Message>{t('components.configDialogError.message')}</Message>
      <PrimaryButton onClick={onRetryPress}>
        {t('components.configDialogError.button')}
      </PrimaryButton>
    </Wrapper>
  );
};

export default AvailableKiosksErrorDialog;
