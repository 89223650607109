import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { addMinutes, getMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { RoundingDirection } from '@engage-shared/constants';
import { A11yHiddenLabel } from '../../../styles';
import { isIntervalBooked } from './helpers';
import { Interval } from './Interval';
import { Label } from './Label';
import { GroupWrapper } from './styled';
import {
  DateFormat,
  formatLocalizedDate,
  formatLocalizedTime,
  roundToInterval,
} from '@engage-shared/utils';

const hourBlockMarginHorizontal = 1;
const intervalMarginHorizontal = 0;

type FixSizeAvailabilityBarProps = {
  /**
   * Total number of minutes displayed in the bar. 0 means bar has size adjustable by the window size.
   * @default 0
   */
  barSize?: number;
  /**
   * Number of minutes per interval, e.g. 5 minutes, 15 minutes, 30 minutes etc.
   */
  bookingIntervalSize: number;
  /**
   * Start time in number of milliseconds since the Unix Epoch.
   */
  start: number;
  /**
   * Start of available intervals.
   * @default current time
   */
  startAvailable?: number;
  /**
   * Width of the hour block.
   * @default 56
   */
  hourWidth?: number;
  /**
   * Array of booked intervals. Each array element is the start of the interval in number of milliseconds.
   * Interval start is rounded down to interval size.
   * @default []
   */
  bookedIntervals?: number[];
  timeZone: string;
};
export const FixSizeAvailabilityBar = memo(
  ({
    barSize = 0,
    bookingIntervalSize,
    start,
    startAvailable = new Date().getTime(),
    hourWidth = 56,
    bookedIntervals = [],
    timeZone,
    ...others
  }: FixSizeAvailabilityBarProps) => {
    // @ts-ignore
    const { color2, typo2 } = useTheme();
    const { t } = useTranslation();

    // display only 15 minutes intervals or larger
    const intervalSize = Math.max(15, bookingIntervalSize);

    const intervalWidth = (hourWidth * intervalSize) / 60;
    const intervalsPerHour = 60 / intervalSize;

    const startDate = roundToInterval(new Date(start), intervalSize, RoundingDirection.DOWN);
    const hasIncompleteHourInterval = startDate.getMinutes() !== 0;
    let hoursNumber = Math.floor(barSize / 60);
    if (hasIncompleteHourInterval) {
      hoursNumber--;
    }
    const startAvailableDate = roundToInterval(
      new Date(startAvailable),
      intervalSize,
      RoundingDirection.DOWN,
    );

    const styledLabelWidth =
      intervalWidth * intervalsPerHour + intervalsPerHour * 2 * intervalMarginHorizontal;

    const labelStyle = {
      minWidth: styledLabelWidth,
      borderColor: color2,
      opacity: 0.7,
    };

    const intervalStyle = {
      width: intervalWidth,
    };

    // style for intervals with rounded corners
    const leftIntervalStyle = {
      ...intervalStyle,
    };
    const rightIntervalStyle = {
      ...intervalStyle,
    };

    const hourBlockWidth =
      hourWidth + 2 * hourBlockMarginHorizontal + 2 * intervalsPerHour * intervalMarginHorizontal;

    const hourBlockStyle = {
      width: hourBlockWidth,
    };

    let currentDateInterval = startDate;
    const hourSegments = [];
    const incompleteIntervalMinutes = 60 - getMinutes(startDate);
    const leftIncompleteIntervalsNumber = incompleteIntervalMinutes / intervalSize;
    // add the incomplete hour intervals from on the left side of the bar
    if (hasIncompleteHourInterval) {
      const incompleteHourBlockStyle = {
        width:
          leftIncompleteIntervalsNumber * intervalWidth +
          2 * hourBlockMarginHorizontal +
          2 * leftIncompleteIntervalsNumber * intervalMarginHorizontal,
      };
      const currentHourTime = currentDateInterval.getTime();
      const barButtons = [];
      for (let i = 0; i < leftIncompleteIntervalsNumber; i++) {
        const currentIntervalTime = currentDateInterval.getTime();
        const isBooked = isIntervalBooked(startAvailableDate, bookedIntervals, currentDateInterval);
        barButtons.push(
          <Interval
            isBooked={isBooked}
            intervalStyle={i === 0 ? leftIntervalStyle : intervalStyle}
            key={`interval_${currentIntervalTime}`}
          />,
        );
        currentDateInterval = addMinutes(currentDateInterval, intervalSize);
      }
      hourSegments.push(
        <div
          className="fix-size-availability-bar__segment"
          style={{ ...hourBlockStyle, ...incompleteHourBlockStyle }}
          key={`hour_${currentHourTime}`}
        >
          <Label color={typo2} style={{ ...labelStyle, ...incompleteHourBlockStyle }} />
          <GroupWrapper>{barButtons}</GroupWrapper>
        </div>,
      );
    }

    const displayHour = (date: Date) =>
      formatLocalizedTime(date, { timeZone, format: DateFormat.hourNumeric });

    const barsPerHour = 60 / intervalSize;

    // add the complete hour intervals
    for (let i = 0; i < hoursNumber; i++) {
      const hourText = displayHour(currentDateInterval);
      const currentHourTime = currentDateInterval.getTime();
      const barButtons = [];
      for (let j = 0; j < barsPerHour; j++) {
        const currentIntervalTime = currentDateInterval.getTime();

        // add rounded corners for first and last interval
        let completeIntervalStyle = {};
        if (!hasIncompleteHourInterval && i === 0 && j === 0) {
          completeIntervalStyle = leftIntervalStyle;
        } else if (!hasIncompleteHourInterval && i === hoursNumber - 1 && j === barsPerHour - 1) {
          completeIntervalStyle = rightIntervalStyle;
        }

        const isBooked = isIntervalBooked(startAvailableDate, bookedIntervals, currentDateInterval);

        barButtons.push(
          <Interval
            isBooked={isBooked}
            intervalStyle={{ ...intervalStyle, ...completeIntervalStyle }}
            key={`interval_${currentIntervalTime}`}
          />,
        );
        currentDateInterval = addMinutes(currentDateInterval, intervalSize);
      }

      hourSegments.push(
        <div
          className="fix-size-availability-bar__segment"
          style={hourBlockStyle}
          key={`hour_${currentHourTime}`}
          data-testid="labelButton"
        >
          <Label color={typo2} style={labelStyle}>
            {hourText}
          </Label>
          <GroupWrapper>{barButtons}</GroupWrapper>
        </div>,
      );
    }

    // add the incomplete hour intervals from on the right side of the bar
    if (hasIncompleteHourInterval) {
      const rightIncompleteIntervalNumbers = intervalsPerHour - leftIncompleteIntervalsNumber;
      const incompleteHourBlockStyle = {
        width:
          rightIncompleteIntervalNumbers * intervalWidth +
          2 * hourBlockMarginHorizontal +
          2 * rightIncompleteIntervalNumbers * intervalMarginHorizontal,
      };
      const currentHourTime = currentDateInterval.getTime();
      const barButtons = [];
      for (let i = 0; i < rightIncompleteIntervalNumbers; i++) {
        const currentIntervalTime = currentDateInterval.getTime();
        const isBooked = isIntervalBooked(startAvailableDate, bookedIntervals, currentDateInterval);
        barButtons.push(
          <Interval
            isBooked={isBooked}
            intervalStyle={
              i === rightIncompleteIntervalNumbers - 1 ? rightIntervalStyle : intervalStyle
            }
            key={`interval_${currentIntervalTime}`}
          />,
        );
        currentDateInterval = addMinutes(currentDateInterval, intervalSize);
      }
      hourSegments.push(
        <div
          className="fix-size-availability-bar__segment"
          style={{ ...hourBlockStyle, ...incompleteHourBlockStyle }}
          key={`hour_${currentHourTime}`}
        >
          <Label color={typo2} style={{ ...labelStyle, ...incompleteHourBlockStyle }} />
          <GroupWrapper>{barButtons}</GroupWrapper>
        </div>,
      );
    }

    return (
      <GroupWrapper {...others} data-testid="barContainer">
        {hourSegments}
        <A11yHiddenLabel>
          {`${t('accessibilityLabels.availableFrom')} ${formatLocalizedDate(startAvailableDate, {
            timeZone,
          })}`}
        </A11yHiddenLabel>
      </GroupWrapper>
    );
  },
);
