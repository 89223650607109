import styled from 'styled-components';

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InitialsWrapper = styled.div<{ $textColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $textColor }) => $textColor || 'inherit'};
`;

export const BadgeWrapper = styled.div`
  position: relative;
`;

export const Badge = styled.div<{ $borderWidth?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: ${props => props.$borderWidth}px solid #ffffff;
`;

export const ImageButton = styled.button`
  display: block;
  padding: 0;
  cursor: pointer;
`;

export const AvatarWrapper = styled.div`
  :focus-within {
    .avatar__image {
      outline: 1px auto Highlight;
      outline: 1px auto -webkit-focus-ring-color;
    }
  }
`;
