import styled from 'styled-components';

export const ContentWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overlflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
`;
