import { sortByAlphaNum } from '@engage-shared/utils/helpers';
import { Building, BuildingData, FetchBuildingQuery, GlobalConfigValues } from '../interfaces';
import { ErrorType } from '@engage-shared/constants/api';
import { Nullable } from '@engage-shared/utils/types';
import { CustomError } from '@engage-shared/api/instance/errors';

/**
 * Sort by building name.
 * @param firstBuild
 * @param secondBuild
 */
export const sortByBuildingName = (firstBuild: BuildingData, secondBuild: BuildingData): number => {
  if (!firstBuild?.name || !secondBuild?.name) {
    return 1;
  }
  return sortByAlphaNum(firstBuild.name, secondBuild.name);
};
/**
 * Extract time as hh:mm, similar to tenant config value.
 * @param allDayStartTime string value in this format 2000-01-01T15:55:00.000Z
 * @returns parsed time value in format hh:mm
 */
const parseAllDayStartTime = (allDayStartTime: string): string => {
  const regex = /T(\d{1,2}:\d{1,2}):/g;

  const matches = regex.exec(allDayStartTime);

  return matches?.length === 2 ? matches[1] : allDayStartTime;
};

/**
 * Adds the timeZone property to the building response.
 * @param building
 * @returns {BuildingData /&0 {timeZone: string}}
 */
export const parseBuildingData = (building: BuildingData): Building => ({
  ...building,
  enableDeskCheckIn: building.enableDeskCheckIn ?? GlobalConfigValues.GLOBAL,
  bookSafe: building.bookSafe ?? GlobalConfigValues.GLOBAL,
  allDayDeskReservations: building.allDayDeskReservations ?? GlobalConfigValues.GLOBAL,
  allDayStartTime: parseAllDayStartTime(building.allDayStartTime),
  enableHealthDeclaration: building.enableHealthDeclaration ?? GlobalConfigValues.GLOBAL,
  enforceBookingsAllowed: building.enforceBookingsAllowed ?? GlobalConfigValues.GLOBAL,
  ...{ timeZone: building?.timezone },
});

type GetBuildingAndIsInactiveFlag = ({
  buildingId,
  fetchBuildingQuery,
}: {
  buildingId: Nullable<number>;
  fetchBuildingQuery: FetchBuildingQuery;
}) => Promise<{ building: Nullable<Building>; isInactive: boolean }>;
/**
 * Gets the building using fetchBuildingQuery or sets the isInactive to true if building is not found.
 * @param buildingId
 * @param fetchBuildingQuery
 */
export const getBuildingAndIsInactiveFlag: GetBuildingAndIsInactiveFlag = async ({
  buildingId,
  fetchBuildingQuery,
}) => {
  let building = null;
  let isInactive = false;

  if (!buildingId || !fetchBuildingQuery) {
    return { building, isInactive };
  }

  try {
    building = await fetchBuildingQuery(buildingId);
  } catch (error: unknown) {
    // if building is no longer displayed in Wayfinding set flag isInInactiveBuilding
    if ((error as CustomError)?.message === ErrorType.NOT_FOUND) {
      isInactive = true;
    }
  }
  return { building, isInactive };
};
