import styled from 'styled-components';

export const PADDING_USER_PROFILE = 30;

export const Wrapper = styled.div`
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${PADDING_USER_PROFILE}px;
`;

export const NotificationsWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: ${2}px 0;
  background-color: ${({ theme }) => theme.color4};
`;

export const Logout = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.typo2};
  opacity: 0.7;

  & .text {
    margin-left: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  text-align: start;
  &:focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
    border-radius: 50px;
  }
`;

export const Settings = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.color4};

  & button {
    cursor: pointer;
    &:first-child {
      padding: 12px 28px;
      font-size: 12px;
      line-height: 16px;
      color: ${({ theme }) => theme.typo2};
    }

    &:focus-visible {
      outline: 1px auto Highlight;
      outline: 1px auto -webkit-focus-ring-color;
      border-radius: 50px;
    }
  }
`;
