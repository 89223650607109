import React, { CSSProperties, memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ListItem } from './styled';

interface ItemSkeletonProps {
  index: number;
  style: CSSProperties;
}

const ItemSkeleton = ({ index, style }: ItemSkeletonProps) => (
  <ListItem key={index} style={style} data-testid="filter-item-skeleton">
    <Skeleton width={120} />
    <Skeleton width={40} height={24} style={{ borderRadius: 12 }} />
  </ListItem>
);

export default memo(ItemSkeleton);
