import { LOCATION_STATUS_LOCALIZED, LocationStatus } from '@engage-shared/constants/person';
import { TFunction as TranslateFunction } from 'i18next';

type LocalizePersonPrimaryLocationStatus = (params: {
  status: LocationStatus;
  t: TranslateFunction;
}) => string;
/**
 * Localize person primary location.
 * @param status location status
 * @param t translatable fn
 */
export const localizePersonPrimaryLocationStatus: LocalizePersonPrimaryLocationStatus = ({
  status,
  t,
}) => t(LOCATION_STATUS_LOCALIZED[status]);
