import { FavouriteMutationBody } from '@engage-shared/api/favourites/interfaces';
import { Api, getApiUrl } from '@engage-shared/api/instance';

export type DeleteFavouriteItem = (params: FavouriteMutationBody) => any;

/**
 * Deletes item to user favourites
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | created favourite successful |
 * | 404 | ID not found |
 * | 422 | Incorrect favourite type |
 * @param params
 */
export const deleteFavouriteItem: DeleteFavouriteItem = async ({ type, id }) => {
  const url = new URL(`${getApiUrl()}/favourites`);
  url.searchParams.append('type', type);
  url.searchParams.append('id', `${id}`);

  const response = await Api.delete(url.toString());
  return response?.data;
};
