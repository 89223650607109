import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '@engage-web/components/base';

const Wrapper = styled.div`
  padding: 15px 30px 22px 30px;
`;

const TitleFirstRowWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleFirstRowAndIconWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const TitleFirstRow = styled(Link)`
  font-weight: bold;
  font-size: 20px;
  margin-top: 2px;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.typo2};

  * + & {
    margin-left: 10px;
  }
`;

const TitleSecondRow = styled.span`
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 5px;
`;

const StyledSubtitleButton = styled.span`
  padding: 0;
  display: flex;
  width: fit-content;

  &:focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const StyledMenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 150px;
  height: 26px;
  width: 26px;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const StyledMenuIcon = styled(Icon)<{ $opened: boolean }>`
  transform: rotate(${({ $opened }) => ($opened ? 0 : 90)}deg);
  transition: all 300ms;
`;

const ButtonsWrapper = styled.span`
  position: relative;
  z-index: 2;
  display: flex;

  .button-wrapper + .button-wrapper {
    margin-left: 10px;
  }
`;

const StyledButton = styled(Link)<{ $withoutBackground: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 150px;
  height: 26px;
  width: 26px;
  transition: all 300ms ease-in-out;
  background-color: ${({ $withoutBackground }) => ($withoutBackground ? 'none' : '#eaeaea')};
  &:hover {
    background-color: ${({ $withoutBackground }) => ($withoutBackground ? 'none' : '#c1c1c1')};
  }
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export {
  Wrapper,
  TitleFirstRowWrapper,
  TitleFirstRowAndIconWrapper,
  TitleFirstRow,
  TitleSecondRow,
  StyledButton,
  StyledSubtitleButton,
  StyledMenuButton,
  ButtonsWrapper,
  StyledMenuIcon,
};
