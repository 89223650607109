import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DrawerCardContent } from '@engage-web/components/scenes/drawer/DrawerCardContent';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useTeamBookingDetails } from '@engage-web/api/queries';
import {
  filtersActions,
  teamReservationsActions,
  teamReservationsSelectors,
  tenantSelectors,
} from '@engage-web/store';
import { DeskList } from '../Booking/DeskList/DeskList';
import { ListWrapper } from './styled';
import { DeskListSkeleton } from './DeskListSkeleton';
import { useInvalidateFloorplanSpaces } from '@floorplan/api';
import { TeamDeskBooking, useTeamBookingDesksAvailabilities } from '@engage-shared/api';

export function TeamBookingDesks() {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const teamBookingId = useSelector(teamReservationsSelectors.getTeamBookingId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invalidateFloorplanSpaces } = useInvalidateFloorplanSpaces();
  const { data: teamBookingDetails, isLoading } = useTeamBookingDetails(teamBookingId);

  const desks = teamBookingDetails?.deskBookings ?? [];

  const { data: desksAvailabilities, isLoading: isLoadingAvailabilities } =
    useTeamBookingDesksAvailabilities({
      tenantId,
      teamBookingDetails,
      options: { enabled: !!teamBookingDetails },
    });

  const handleDeskClick = (desk: TeamDeskBooking) => {
    navigate(`${desk.spaceId}`);
  };

  const handleCloseClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch(teamReservationsActions.clearTeamBookingId());
    invalidateFloorplanSpaces();
    dispatch(filtersActions.clearDates({}));
  };

  const closeIconA11yLabel = 'accessibilityLabels.closeLayout_teamBookingDesks';

  return (
    <>
      <DrawerHeader
        isCloseIcon
        onPress={handleCloseClick}
        closeIconA11yLabel={closeIconA11yLabel}
      />
      <DrawerCardContent>
        {isLoading || isLoadingAvailabilities ? (
          <DeskListSkeleton />
        ) : (
          <ListWrapper>
            <DeskList
              desks={desks}
              desksAvailabilities={desksAvailabilities}
              onItemClick={handleDeskClick}
            />
          </ListWrapper>
        )}
      </DrawerCardContent>
    </>
  );
}
