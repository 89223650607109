import React from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import {
  useBookingsQuery,
  BOOKINGS_QUERY_KEY,
  GetBookingsFetcherResponse,
  ParsedSpaceItem,
} from '@engage-shared/api';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '../../store';
import { useCurrentTimeZone, useReservationFilters } from '../../utils/hooks';
import { UseSpaceResponse } from '../../api/spaces';

const useBookings = ({
  spaceItem,
  options,
}: {
  spaceItem: UseSpaceResponse | undefined;
  options?: UseQueryOptions<GetBookingsFetcherResponse>;
}) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const userId = useSelector(tenantSelectors.getUserId);

  const id = spaceItem?.id;
  const isDesk = !!spaceItem?.isDesk;
  const isSpaceItemBookable = spaceItem?.bookable;

  const timeZone = useCurrentTimeZone();
  const reservationFilters = useReservationFilters(isDesk);

  return useBookingsQuery({
    tenantId,
    spaceItem,
    timeZone,
    reservationFilters,
    options: {
      ...options,
      enabled: !!tenantId && !!userId && !!id && (isSpaceItemBookable || isDesk),
    },
  });
};

const useRefreshBookings = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = ({ spaceItem }: { spaceItem: ParsedSpaceItem }) =>
    queryClient.invalidateQueries([BOOKINGS_QUERY_KEY, spaceItem.id]);
  const refresh = React.useCallback(invalidateQuery, [queryClient]);
  return { refresh };
};

export { useBookings, useRefreshBookings, BOOKINGS_QUERY_KEY };
