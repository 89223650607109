/**
 * Team Booking Details card displays these buttons: Delete, Accept, Decline, Check In and Check Out.
 * 1. Delete:
 * - Display: if user is the organizer then this button is always displayed
 * - Disabled: booking ended
 *
 *  2. Accept:
 * - Display: if user is an attendee and haven't accept/decline or check in yet(status=invited), and booking didn't end
 * - Disabled: never
 *
 *  3. Decline:
 * - Display: if user is an attendee and haven't decline or check in yet, and booking didn't end
 * - Disabled: never
 *
 *  4. Check In:
 * - Display: if check-in is enabled, is user's booking (not one booked for somebody else) and is in check-in window.
 * - Disabled: booking is not in the check-in window, has finished or it is in an inactive building
 *
 */
import {
  teamBookingDetailsButtons as BUTTONS,
  teamBookingDetailsButtonStatuses as STATUSES,
} from '../../constants/bookingDetailsButtons';
import { getDeskCheckInWindowDates, hasBookingFinished } from '../..//utils/dates';
import { TeamBookingDetails } from '../../api/teams/interfaces/team.type';
import { ATTENDEE_STATUS } from '../../constants/attendee';
import { ISODateString } from '../../utils/types';
import moment, { Moment } from 'moment-timezone';

export type TeamButtonStatus = Record<STATUSES, boolean>;
export type TeamBookingDetailsButtons = Record<BUTTONS, TeamButtonStatus>;

interface TeamConfig {
  enableDeskCheckIn: boolean;
  enableSpaceCheckIn: boolean;
  deskCheckInOpenWindow: number;
  deskCheckInWindowDuration: number;
  allDayReservationCheckInStartTime: string;
}

type GetTeamBookingDetailsStatusParams = {
  booking: TeamBookingDetails;
  config: TeamConfig;
  isBookedForOther: boolean;
  canUpdateStatus: boolean;
  bookingStatus: ATTENDEE_STATUS;
};
type GetTeamBookingDetailsStatus = (
  params: GetTeamBookingDetailsStatusParams,
) => TeamBookingDetailsButtons;

export const getTeamBookingDetailsStatus: GetTeamBookingDetailsStatus = ({
  booking,
  config,
  isBookedForOther,
  canUpdateStatus,
  bookingStatus,
}): TeamBookingDetailsButtons => {
  const { DELETE, CHECK_IN, ACCEPT, DECLINE } = BUTTONS;
  const { DISPLAY, DISABLED } = STATUSES;

  const {
    isOrganizer,
    startDate,
    endDate,
    canUpdate,
    isInInactiveLocation,
    localTimeZone,
    allDay,
  } = booking;

  const hasFinished = hasBookingFinished(endDate);

  const { enableDeskCheckIn } = config;
  const isCheckInFeatureActive = canUpdate && enableDeskCheckIn && !isBookedForOther;

  const { checkInOpenDate, checkInCloseDate } = getDeskCheckInWindowDates({
    tenantConfig: config,
    reservationStartDate: startDate.toISOString() as ISODateString,
    timeZone: localTimeZone ?? undefined,
    isAllDayBooking: allDay,
  });

  const now = moment();
  const hasCheckInOpened =
    now.isSame(checkInOpenDate as Moment) || now.isAfter(checkInOpenDate as Moment);
  const hasCheckInClosed = now.isAfter(checkInCloseDate as Moment);
  const isCheckInAvailable = hasCheckInOpened && !hasCheckInClosed;

  return {
    [DELETE]: {
      [DISPLAY]: !!isOrganizer,
      [DISABLED]: hasFinished,
    },
    [CHECK_IN]: {
      [DISPLAY]: isCheckInFeatureActive && isCheckInAvailable,
      [DISABLED]: !isCheckInAvailable || hasFinished || !!isInInactiveLocation,
    },
    [ACCEPT]: {
      [DISPLAY]: canUpdateStatus && bookingStatus === ATTENDEE_STATUS.INVITED && !hasFinished,
      [DISABLED]: false,
    },
    [DECLINE]: {
      [DISPLAY]: canUpdateStatus && !hasFinished,
      [DISABLED]: false,
    },
  };
};
