import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { AvatarWrapper, NameWrapper, Wrapper } from './styled';

const DetailsSkeleton = () => (
  <Wrapper>
    <AvatarWrapper>
      <Skeleton circle height={112} width={112} />
    </AvatarWrapper>
    <NameWrapper>
      <h5>
        <Skeleton width={200} />
      </h5>
    </NameWrapper>
    <h5>
      <Skeleton width={250} />
    </h5>
    <h5>
      <Skeleton width={300} />
    </h5>
  </Wrapper>
);
export default DetailsSkeleton;
