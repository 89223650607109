import styled from 'styled-components';
import SelectButton from '@engage-web/components/base/SelectButton/SelectButton';

export const CalendarModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
  height: max-content;
  margin: 75px auto 0;
  background-color: #fff;
  border-radius: 6px;
  @media (max-width: 400px) {
    max-width: 320px;
    margin: 65px auto 0;
  }
  @media (max-height: 545px) {
    overflow: auto;
    max-height: calc(100vh - 65px);
  }
`;

export const TimePickerWrapper = styled.fieldset`
  display: flex;
  align-items: center;
  margin: 20px 0;
  border: 0;
  @media (max-width: 400px) {
    margin: 0;
  }
`;

export const TimePickerLabel = styled.legend`
  font-weight: bold;
  margin-right: 10px;
  max-width: 110px;
  text-align: center;
  float: left;
  @media (max-width: 400px) {
    float: none;
  }
`;

export const CalendarModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  @media (max-width: 400px) {
    padding-top: 10px;
  }
`;

export const DateTimePickerSelectButton = styled(SelectButton)`
  @media (max-width: 400px) {
    margin-top: 5px;
  }
`;
