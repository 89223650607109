import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  pointer-events: auto;
`;

interface TextProps {
  $textColor?: string;
}
const DateS = styled.h5<TextProps>`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme, $textColor }) => $textColor ?? theme.typo2};
`;

const TimeS = styled.h2<TextProps>`
  font-weight: 600;
  font-size: 32px;
  line-height: 25px;
  color: ${({ theme, $textColor }) => $textColor ?? theme.typo2};
`;

export { Wrapper, DateS, TimeS };
