import { Nullable } from '@engage-shared/utils/types';
import { BaseSVLivePresence, PersonAndPresenceResponse } from '../interfaces';
import { PresenceEventSource, PresenceStatus } from '@engage-shared/constants/person';
import { fetchSpace } from '@engage-shared/api/spaces';

/**
 * Returns true if the person is occupying the space as indicated by SV Live.
 * @param presenceEvent
 */
export const getIsOccupiedBySVLive = (presenceEvent: Nullable<BaseSVLivePresence>): boolean =>
  presenceEvent?.presenceStatus === PresenceStatus.ACTIVE ||
  presenceEvent?.presenceStatus === PresenceStatus.AWAY;

/**
 * Returns true if the person is indicated to be Remote by SV Live.
 * @param presenceEvent
 */
export const getIsRemoteBySVLive = (presenceEvent: Nullable<BaseSVLivePresence>): boolean =>
  presenceEvent?.presenceStatus === PresenceStatus.REMOTE;

export const getIsWiredConnection = (presenceEvent: Nullable<BaseSVLivePresence>): boolean =>
  presenceEvent?.eventSource === PresenceEventSource.WIRED;

export const getIsWifiConnection = (presenceEvent: Nullable<BaseSVLivePresence>): boolean =>
  presenceEvent?.eventSource === PresenceEventSource.WIFI;

interface GetPersonLocationReturn {
  spaceId?: number;
  spaceIds?: number[];
  floorId?: number;
  hoodName?: string;
  containerId?: number; // for SVLIve presence
}

/**
 * Gets the person location. If the person has a valid SV Live location that one is used. Otherwise, the
 * person location is used.
 * @param person
 */
export const getPersonLocation = (
  person: PersonAndPresenceResponse | undefined,
): GetPersonLocationReturn => {
  let location = {
    spaceId: person?.primaryLocation?.spaceId,
    spaceIds: person?.primaryLocation?.spaceIds,
    floorId: person?.primaryLocation?.floorId,
    hoodName: person?.primaryLocation?.hoodName,
  } as GetPersonLocationReturn;

  const isValidSvLiveEvent = getHasWiredPresenceData(person) || getHasWifiPresenceData(person);

  if (isValidSvLiveEvent && person?.latestPresenceEvent?.containerId) {
    location.containerId = person.latestPresenceEvent.containerId;
  }

  return location;
};

export const getSVLivePersonLocation = async (containerId: number) => {
  const spaceData = await fetchSpace({ id: containerId });
  return { spaceId: spaceData.id, floorId: spaceData.floorId, spaceName: spaceData.name };
};

export const getHasWiredPresenceData = (person: PersonAndPresenceResponse | undefined): boolean =>
  !!(person?.isOccupiedBySVLive && person?.isWiredConnection && person.presenceStatus);

export const getHasWifiPresenceData = (person: PersonAndPresenceResponse | undefined): boolean =>
  !!(person?.isOccupiedBySVLive && person?.isWifiConnection && person.presenceStatus);
