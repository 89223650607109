import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { useCallback, useEffect, useRef } from 'react';
import { HeatmapOverlay } from '../components/HeatmapOverlay';
import { Space } from '@floorplan/api';
import { HeatMapPoint } from '../types';

export type UseHeatmapParams = {
  occupantPoints: Space[];
  nonOccupantPoints: Space[];
};

const getDataFromSpaces = (
  spaces: Space[],
  config: Pick<HeatMapPoint, 'value' | 'radius'>,
): HeatMapPoint[] => {
  return spaces.reduce<HeatMapPoint[]>((accumulator, space) => {
    if (space.shape) {
      const { coordinates: [coordinate] = [] } = Array.isArray(space.shape)
        ? space.shape[0] ?? {}
        : space.shape;

      if (coordinate) {
        accumulator.push({
          ...coordinate,
          ...config,
        });
      }
    }

    return accumulator;
  }, []);
};

export const useHeatmap = ({ occupantPoints, nonOccupantPoints }: UseHeatmapParams) => {
  const fes = useFESContext();
  const heatmapRef = useRef<HeatmapOverlay>();

  const viewer = fes.getViewer()?.getViewer();

  const removeHeatmap = useCallback(() => {
    heatmapRef.current?.destroy();
    heatmapRef.current = undefined;
  }, []);

  const addHeatmap = useCallback(
    ({ data, max: _max }: { data: HeatMapPoint[]; max: number }) => {
      removeHeatmap();
      heatmapRef.current = new HeatmapOverlay(viewer);

      let max = _max;

      if (!max) {
        max = data.reduce((prev: number, next: HeatMapPoint) => Math.max(prev, next.value), 0);
      }

      heatmapRef.current.setData({
        max,
        data,
      });
    },
    [removeHeatmap, viewer],
  );

  const handleShowHeatmap = useCallback(() => {
    if (!viewer) {
      return;
    }

    const data = [
      ...getDataFromSpaces(occupantPoints, { value: 5, radius: 100 }),
      ...getDataFromSpaces(nonOccupantPoints, { value: 3, radius: 80 }),
    ];

    addHeatmap({ data, max: 5 });
  }, [addHeatmap, nonOccupantPoints, occupantPoints, viewer]);

  useEffect(() => {
    fes.on(FES_EVENTS.SHOW_HEATMAP, handleShowHeatmap);
    return () => {
      fes.off(FES_EVENTS.SHOW_HEATMAP, handleShowHeatmap);
    };
  }, [fes, handleShowHeatmap]);

  useEffect(() => {
    fes.on(FES_EVENTS.HIDE_HEATMAP, removeHeatmap);
    return () => {
      fes.off(FES_EVENTS.HIDE_HEATMAP, removeHeatmap);
    };
  }, [fes, removeHeatmap]);

  return { addHeatmap, removeHeatmap, heatmap: heatmapRef.current };
};
