import { useDispatch, useSelector } from 'react-redux';
import {
  BookingMutationData,
  useBookingMutation as useSharedBookingMutation,
} from '@engage-shared/api';
import { RESERVATION_ANALYTICS_TYPE } from '@engage-web/constants';
import { tenantSelectors, userConfigSelectors } from '@engage-web/store';
import { useConfig, useReservationNotification } from '@engage-web/utils/hooks';
import { trackReservationAnalytics } from '@engage-web/utils/analytics/useReserveAnalytics';
import { handleSocialDistancing } from '@engage-web/api/mutations/reserve/helpers';
import { getLocalTimeZone } from '@engage-web/utils';
import { useRefreshBookings, useRefreshSpace } from '@engage-web/api/queries';
import { ParsedSpaceItem } from '@engage-shared/api/spaces';
import { UserData } from '@engage-shared/api/users';
import { BookingFilter, Reservation } from '@engage-shared/api/bookings';
import { Nullable } from '@engage-shared/utils/types';
import { CustomError } from '@engage-shared/api/instance/errors';
import { useInvalidateFloorplanSpaces } from '@floorplan/api';

type UseBookingMutationProps = {
  spaceItem: ParsedSpaceItem;
  isDesk: boolean;
  user: UserData;
  filter: BookingFilter;
  reserveeId: Nullable<number>;
  duration: number;
  allDayDuration: boolean;
  isUpdate?: boolean;
  summaryText?: string;
  onSuccess?: (successfulReservation: BookingMutationData) => void;
  onError?: (e: CustomError) => void;
};
export const useBookingMutation = ({
  spaceItem,
  isDesk,
  user,
  filter,
  reserveeId,
  duration,
  allDayDuration,
  isUpdate,
  summaryText,
  onSuccess,
  onError,
}: UseBookingMutationProps) => {
  const dispatch = useDispatch();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { isCleverSocialDistancingActive } = useConfig(spaceItem.buildingId);
  const showSocialDistancingInfo = useSelector(userConfigSelectors.getShowSocialDistancingInfo);

  const { refresh: refreshSpace } = useRefreshSpace();
  const { refresh: refreshBookings } = useRefreshBookings();
  const { handleLocalNotification } = useReservationNotification();
  const { invalidateFloorplanSpaces } = useInvalidateFloorplanSpaces();

  const email = user?.email;

  const bookingMutation = useSharedBookingMutation(
    {
      tenantId,
      email,
      localTimeZone: getLocalTimeZone(),
      summaryText,
      filter,
      // @ts-ignore
      spaceItem,
      // @ts-ignore
      reserveeId,
      duration,
      allDayDuration,
      // @ts-ignore
      isUpdate,
      // @ts-ignore
      successCallback: (reservation: Reservation) => {
        trackReservationAnalytics({
          reservation,
          spaceItem,
          analyticsType: RESERVATION_ANALYTICS_TYPE.SUCCESS,
        });

        refreshSpace({ spaceItem });
        // @ts-ignore
        refreshBookings({ spaceItem });

        invalidateFloorplanSpaces();

        if (isCleverSocialDistancingActive && isDesk) {
          handleSocialDistancing({
            dispatch,
            spaceItem,
            showSocialDistancingInfo,
          });
        }

        if (isDesk) {
          // add/update scheduled local notification
          handleLocalNotification({
            event: {
              ...spaceItem,
              ...reservation,
              timeZone: reservation.startTimeZone,
            },
            isUpdate,
          });
        }
      },
      errorCallback: () => {
        trackReservationAnalytics({
          spaceItem,
          analyticsType: RESERVATION_ANALYTICS_TYPE.FAIL,
        });
      },
    },
    {
      onSuccess,
      onError,
    },
  );
  const { saveBooking } = bookingMutation;

  const onBooking = () => {
    trackReservationAnalytics({
      spaceItem,
      analyticsType: RESERVATION_ANALYTICS_TYPE.START,
    });
    saveBooking();
  };

  return { ...bookingMutation, onBooking };
};
