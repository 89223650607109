import { QueryOptions, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { fetchAndParseEvents, useBuildingFetch } from '@engage-shared/api';
import { ISODateString } from '@engage-shared/utils/types';
import { Event, User } from '@engage-shared/api/users';
import { EVENTS_STATUS } from '@engage-web/constants';
import { getDayEndTime, getDayStartTime, getUtcIsoString } from '@engage-shared/utils';
import { addDays, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getLocalTimeZone } from '@engage-web/utils';
import { useUser } from './useUser';
import { useCallback } from 'react';

export const NOTIFICATION_EVENTS_KEY = 'events';

type UseNotificationEventsParams = {
  startTime: ISODateString;
  endTime: ISODateString;
  options: QueryOptions;
  eventsFilter: EVENTS_STATUS;
};
export const useNotificationEvents = ({
  startTime,
  endTime,
  options = {},
  eventsFilter,
}: UseNotificationEventsParams): UseQueryResult<Event[]> => {
  const { fetchBuildingQuery } = useBuildingFetch();
  const { t } = useTranslation();
  const userTimeZone = getLocalTimeZone();

  const { data } = useUser();
  const { personId, userId } = (data || {}) as User;

  return useQuery(
    [NOTIFICATION_EVENTS_KEY, userId, startTime, endTime],
    () =>
      fetchAndParseEvents({
        userId,
        startTime,
        endTime,
        eventsFilter,
        fetchBuildingQuery,
        translationFn: t,
        userTimeZone,
        personId,
      }),
    {
      enabled: !!userId,
      ...options,
    },
  );
};

const STALE_TIME = 15 * 60 * 1000; // stale for 15 minutes
const queryOptions = {
  staleTime: STALE_TIME,
};

type UseNotifications = () => UseQueryResult<Event[]>;
export const useNotifications: UseNotifications = ({ options = {} } = {}) => {
  const today = new Date();
  const startTime = getUtcIsoString(getDayStartTime({ date: new Date(subDays(today, 7)) }));
  const endTime = getUtcIsoString(getDayEndTime({ date: new Date(addDays(today, 1)) }));

  return useNotificationEvents({
    startTime,
    endTime,
    options: {
      ...queryOptions,
      ...options,
    } as QueryOptions,
  } as UseNotificationEventsParams);
};

export const useFetchEvents = () => {
  const queryClient = useQueryClient();

  const { fetchBuildingQuery } = useBuildingFetch();
  const { t } = useTranslation();
  const userTimeZone = getLocalTimeZone();

  const query = (
    userId: number,
    startTime: ISODateString,
    endTime: ISODateString,
    personId?: number,
  ) =>
    queryClient.fetchQuery<Event[]>([NOTIFICATION_EVENTS_KEY, userId, startTime, endTime], () =>
      fetchAndParseEvents({
        userId,
        startTime,
        endTime,
        eventsFilter: EVENTS_STATUS.ALL,
        fetchBuildingQuery,
        translationFn: t,
        userTimeZone,
        personId,
      }),
    );
  const fetchEventsQuery = useCallback(query, [queryClient]);

  return { fetchEventsQuery };
};
