import React, { FC, memo, MemoExoticComponent } from 'react';
import PersonItem, {
  PersonItemProps,
} from '@engage-web/components/layouts/GlobalSearch/PersonItem/PersonItem';
import SelectableAvatar from '@engage-web/components/base/Avatar/SelectableAvatar';
import { SelectableItemWrapper } from '@engage-web/components/layouts/Team/TeamMembers/TeamMembers.style';
import { ParsedTeamMember } from '@engage-web/components/layouts/Team/TeamMembers/TeamMembersList';
import { AvatarImageProps } from '@engage-web/components/base/Avatar/AvatarImage';
import { TFunction, useTranslation } from 'react-i18next';
import { OPT_OUT_DISPLAY_NAME } from '@engage-web/constants';
import { TeamMember } from '@engage-shared/api';
import { getInitials } from '@engage-shared/utils';

type SelectablePersonItemProps = Omit<PersonItemProps, 'renderAvatar' | 'item'> & {
  isSelected: boolean;
  onSelect?: (id: number) => void;
  item: TeamMember;
};

const mapTeamMember = (item: TeamMember, t: TFunction): ParsedTeamMember => {
  const { id, displayName, email, jobTitle } = item;

  const name =
    displayName === OPT_OUT_DISPLAY_NAME
      ? t('layout.teamMembersList.userHasOptedOut')
      : displayName;

  const initials = getInitials(name);
  return {
    id,
    name,
    email,
    description: jobTitle,
    initials,
  };
};

const SelectablePersonItem = ({
  isSelected,
  onSelect,
  style,
  item,
  ...rest
}: SelectablePersonItemProps) => {
  const { t } = useTranslation();
  const parsedItem = mapTeamMember(item, t);

  const renderAvatar = (avatarProps: AvatarImageProps) => (
    <SelectableAvatar
      a11LabelSuffix={parsedItem.name}
      isSelected={isSelected}
      {...avatarProps}
      onClick={() => onSelect?.(item.id)}
    />
  );

  return (
    <SelectableItemWrapper style={style} $isSelected={isSelected}>
      <PersonItem item={parsedItem} {...rest} renderAvatar={renderAvatar} />
    </SelectableItemWrapper>
  );
};

const MemoSelectablePersonItem = memo(SelectablePersonItem) as MemoExoticComponent<
  FC<SelectablePersonItemProps>
> & {
  getSize: () => number;
};

MemoSelectablePersonItem.getSize = PersonItem.getSize;

export default MemoSelectablePersonItem;
