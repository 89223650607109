import styled from 'styled-components';

export const ToggleSwitchLabelStyled = styled.label`
  font-size: 15px;
  line-height: 22px;
  padding-left: 10px;
  transition: opacity 0.25s ease 0s;
`;

export const ToggleSwitchWrapperStyled = styled.div<{ disabled?: boolean }>`
  :focus-within {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }

  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    `
  label {
    cursor: default;
  }

  ${ToggleSwitchLabelStyled} {
      opacity: .5;
  }
  `};
`;
