import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGenerateCurrentLocationPath, useIsDeskSelection } from '@engage-web/utils';
import { joinPaths } from '@engage-web/router/utils';
import { PATH_SEGMENT } from '@engage-web/constants';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useDispatch, useSelector } from 'react-redux';
import { teamReservationsActions, teamReservationsSelectors } from '@engage-web/store';
import { useAppLocation } from '@engage-web/router/hooks';
import { Icon } from '@engage-web/components/base';
import { SubtitleWrapper, Subtitle } from './TeamMembersHeader.style';

type TeamMembersHeaderProps = {
  teamName?: string;
  membersCount?: number;
};

export function TeamMembersHeader({ teamName, membersCount }: TeamMembersHeaderProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useAppLocation();
  const currentLocationPath = useGenerateCurrentLocationPath();
  const isDeskSelection = useIsDeskSelection();

  const selectedDesks = useSelector(teamReservationsSelectors.getTeamReservationSelectedDesks);
  const teamSize = useSelector(teamReservationsSelectors.getTeamSize);

  const teamsPath = joinPaths(currentLocationPath, PATH_SEGMENT.TEAMS);
  const closeLinkPath = isDeskSelection ? pathname : teamsPath;

  const infoCardTitle = isDeskSelection ? t('layout.teamMembersList.selectDesks') : teamName;

  const infoCardSubtitle = isDeskSelection
    ? t('layout.teamMembersList.desksSelected', {
        selected: selectedDesks?.length ?? 0,
        total: teamSize ?? 0,
      })
    : !!(membersCount && membersCount > 0) &&
      t('layout.teamMembersList.members', {
        numberOfMembers: membersCount,
      });

  // if close button is clicked when team reservation desks selection is active,
  // clear selected desk from floorplan and clear team reservation store
  const handleCloseClick = () => {
    if (isDeskSelection) {
      dispatch(teamReservationsActions.clearSelection());
    }
  };

  const renderSubtitle = (subtitle: string) => (
    <SubtitleWrapper>
      <Icon name="desk" size={18} />
      <Subtitle>{subtitle}</Subtitle>
    </SubtitleWrapper>
  );

  return (
    <DrawerHeader
      title={infoCardTitle}
      subtitle={infoCardSubtitle}
      renderSubtitle={isDeskSelection ? renderSubtitle : undefined}
      closeLinkPath={closeLinkPath}
      onPress={handleCloseClick}
      closeIconA11yLabel={
        isDeskSelection
          ? 'accessibilityLabels.closeLayout_teamDesksList'
          : 'accessibilityLabels.closeLayout_teamMembersList'
      }
      isCloseIcon
    />
  );
}
