import { Moment } from 'moment-timezone';
import { useTimeOptions } from '../TimePicker/hooks';
import { getTimePickerValues } from '../TimePicker/utils';
import { useLocalTimeFormatH12 } from '@engage-web/utils';

type UsePickerTimeOptionsParams = {
  date: Date;
  timeZone: string;
  minTime: Moment;
};

export const usePickerTimeOptions = ({ date, timeZone, minTime }: UsePickerTimeOptionsParams) => {
  const isTimeFormatH12 = useLocalTimeFormatH12();
  const { roundedDate: roundedStartDate } = getTimePickerValues({
    date,
    timeZone,
    isTimeFormatH12,
  });

  return useTimeOptions({
    selectedTime: roundedStartDate,
    minTime,
    isTimeFormatH12,
  });
};
