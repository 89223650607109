import { InfiniteData, QueryClient } from 'react-query';
import { QueryKey } from 'react-query/types/core/types';
import { SearchResponse } from '@engage-shared/api/search/interfaces';

export type MutateSearchResults = (
  params: { queryClient: QueryClient; queryKey: QueryKey; itemId: number },
  isFavourite: boolean,
) => void;

/**
 * Updates search results by setting isFavourite flag to item with passed id
 * @param queryClient
 * @param queryKey
 * @param itemId
 * @param isFavourite
 */
export const mutateSearchResults: MutateSearchResults = (
  { queryClient, queryKey, itemId },
  isFavourite,
) => {
  queryClient.setQueryData<InfiniteData<SearchResponse>>(
    queryKey,
    prevData =>
      ({
        ...(prevData ?? {}),
        pages: prevData?.pages.map(page => ({
          ...page,
          data: page.data?.map(item => (item.id === itemId ? { ...item, isFavourite } : item)),
        })),
      } as InfiniteData<SearchResponse>),
  );
};
