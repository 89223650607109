import { isBefore, setSeconds, setMilliseconds } from 'date-fns';

export const isIntervalBooked = (
  startAvailable: Date,
  bookedIntervals: number[],
  currentDateInterval: Date,
) => {
  let current = setSeconds(currentDateInterval, 0);
  current = setMilliseconds(current, 0);

  let isBooked;
  if (bookedIntervals.length > 0) {
    isBooked = bookedIntervals.indexOf(current.getTime()) >= 0;
    if (!isBooked && startAvailable) {
      isBooked = isBefore(current, startAvailable);
    }
  } else {
    isBooked = isBefore(current, startAvailable);
  }
  return isBooked;
};
