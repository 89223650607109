import { BookingsListResponse } from '../../api/bookings/interfaces';
import { TeamBookingId } from '@engage-shared/api/types';

/**
 * Get availability of a space for a team booking.
 * @param teamBookingId Team Booking id.
 * @param spaceId Space id.
 * @param bookings List of bookings for the current space.
 */
export const getIsTeamBookingDeskAvailable = (
  teamBookingId: TeamBookingId,
  spaceId: number,
  bookings?: BookingsListResponse[],
): boolean => {
  const teamBooking = bookings?.find(
    booking => booking.spaceId === Number(spaceId) && booking.teamBooking?.id === teamBookingId,
  );

  if (!teamBooking) return true;

  return teamBooking.reservee.displayName === 'Team Booking';
};
