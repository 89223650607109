import * as React from 'react';

import { Space } from '@floorplan/api';
import { Icon } from '../../../components';
import { getSpaceForID } from '../../../utils';
import { SpaceType } from '../../../constants/spaceTypes';
import { useRotation } from '../../../hooks';

interface SpecialProps {
  spaces: Space[];
  special: { [key in SpaceType]: Space[] };
}
export const Special = ({ spaces, special }: SpecialProps) => {
  const rotation = useRotation();

  return (
    <>
      {Object.keys(special).map(spaceType => {
        const type = parseInt(spaceType, 10) as SpaceType;
        return special[type].map(space => {
          const iconSpace = getSpaceForID(spaces, space.id);
          if (!iconSpace) {
            return null;
          }
          return (
            <Icon space={iconSpace} type={type} key={`icon-${space.id}`} rotation={rotation} />
          );
        });
      })}
    </>
  );
};
