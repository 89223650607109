import { MODALS } from '@engage-web/constants';
import { useConfig } from './useConfig';
import { useModal } from './useModal';

/**
 * Get function to start check in flow.
 *
 * 1. Health declaration is enabled:
 *   a) User has accepted – continue with check in.
 *   b) User has declined – aborts check in.
 * 2. Health declaration is disabled – continue with check in.
 *
 * @param checkIn Check in function which will be invoked if requirements are met.
 * @returns Function to start check in flow.
 */
export function useStartCheckIn(checkIn: () => void) {
  const { enableHealthDeclaration } = useConfig();
  const { openModal } = useModal();

  return () => {
    if (enableHealthDeclaration) {
      openModal(
        MODALS.HEALTH_DECLARATION,
        { onConfirm: checkIn },
        { shouldClearBookingQuery: false },
      );
    } else {
      checkIn();
    }
  };
}
