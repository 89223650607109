import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { inAppNotificationsActions } from '@engage-web/store';
import { RESERVE_MESSAGES } from '@engage-web/constants';
import { useRefreshBookings } from '@engage-web/api/queries';
import { TFunction as TranslateFunction } from 'i18next';
import { ParsedSpaceItem } from '@engage-shared/api/spaces';
import { CustomError } from '@engage-shared/api/instance/errors';
import { useInvalidateFloorplanSpaces } from '@floorplan/api';
import { BookingMutationData } from '@engage-shared/api';

type GetMultiDayBookingSuccessMessageProps = {
  t: TranslateFunction;
  successfullyReservedDaysMessage: string;
};
export const getMultiDayBookingSuccessMessage = ({
  t,
  successfullyReservedDaysMessage,
}: GetMultiDayBookingSuccessMessageProps) =>
  t('components.reserveButton.successfulReservationForDays', {
    days: successfullyReservedDaysMessage,
  });

type GetMultiDayBookingFailedMessageProps = {
  t: TranslateFunction;
  failedDaysMessage: string;
  concurrentDaysMessage?: string;
  limitPerIntervalMessage?: string;
  limitPerBuildingMessage?: string;
};
export const getMultiDayBookingFailedMessage = ({
  t,
  failedDaysMessage,
  concurrentDaysMessage,
  limitPerIntervalMessage,
  limitPerBuildingMessage,
}: GetMultiDayBookingFailedMessageProps) => {
  if (concurrentDaysMessage) {
    return `${t('layout.reserve.desk.selectDays', {
      daysFailed: concurrentDaysMessage,
    })} ${t('layout.reserve.desk.concurrentLimit')}`;
  }
  if (limitPerIntervalMessage) {
    return `${t('layout.reserve.desk.selectDays', {
      daysFailed: limitPerIntervalMessage,
    })} ${t('layout.reserve.desk.intervalLimit')}`;
  }
  if (limitPerBuildingMessage) {
    return `${t('layout.reserve.desk.selectDays', {
      daysFailed: limitPerBuildingMessage,
    })} ${t('layout.reserve.desk.buildingLimit')}`;
  }

  return t('layout.reserve.desk.selectDays', {
    daysFailed: failedDaysMessage,
  });
};

type UseReserveCallbacksProps = { spaceItem: ParsedSpaceItem };
export const useReserveCallbacks = ({ spaceItem }: UseReserveCallbacksProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { refresh: refreshBookings } = useRefreshBookings();
  const { invalidateFloorplanSpaces } = useInvalidateFloorplanSpaces();

  const { type: spaceType } = spaceItem;

  const onSuccessBooking = () => {
    dispatch(
      inAppNotificationsActions.addSuccessNotification({
        message: t(`layout.reserve.${spaceType.toLowerCase()}.successful`),
      }),
    );
  };

  const onErrorBooking = (error: CustomError) => {
    // display UI notification
    const status = error?.response?.status;
    // @ts-ignore
    let errorMsg = error?.response?.data?.error;
    if (Array.isArray(errorMsg) && errorMsg.length > 0) {
      errorMsg = errorMsg[0];
    }

    const message =
      // @ts-ignore
      status && RESERVE_MESSAGES[spaceType][status]
        ? // @ts-ignore
          RESERVE_MESSAGES[spaceType][status][errorMsg] ||
          // @ts-ignore
          RESERVE_MESSAGES[spaceType][status].default
        : // @ts-ignore
          RESERVE_MESSAGES[spaceType].default;
    dispatch(
      inAppNotificationsActions.addErrorNotification({
        message: t(message),
      }),
    );
  };

  type OnSuccessMultiDayBookingProps = {
    successfullyReserved: boolean;
    successfullyReservedDaysMessage: string;
    failedDaysMessage: string;
    concurrentDaysMessage: string;
    limitPerIntervalMessage: string;
    limitPerBuildingMessage: string;
    bookedDays: BookingMutationData[];
  };
  // display success UI notification if at least one successfully reservation was made
  // and display error message with the list of days which couldn't be reserved
  const onSuccessMultiDayBooking = ({
    successfullyReserved,
    successfullyReservedDaysMessage,
    failedDaysMessage,
    concurrentDaysMessage,
    limitPerIntervalMessage,
    limitPerBuildingMessage,
  }: OnSuccessMultiDayBookingProps) => {
    // means there are at least one successful reservation
    if (successfullyReserved) {
      dispatch(
        inAppNotificationsActions.addSuccessNotification({
          message: getMultiDayBookingSuccessMessage({
            t,
            successfullyReservedDaysMessage,
          }),
        }),
      );
    }

    // means there are days failed to reserve
    if (
      failedDaysMessage ||
      concurrentDaysMessage ||
      limitPerIntervalMessage ||
      limitPerBuildingMessage
    ) {
      dispatch(
        inAppNotificationsActions.addErrorNotification({
          message: getMultiDayBookingFailedMessage({
            t,
            failedDaysMessage,
            concurrentDaysMessage,
            limitPerIntervalMessage,
            limitPerBuildingMessage,
          }),
        }),
      );
    }

    // refresh bookings
    refreshBookings({ spaceItem });
    // refresh floorplan space data
    invalidateFloorplanSpaces();
  };

  return {
    onSuccessBooking,
    onErrorBooking,
    onSuccessMultiDayBooking,
  };
};
