import { isRtlLanguage } from '@engage-shared/utils';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from '@engage-web/store';
import { applyLayoutDirection } from '../i18n';
import { useSetAppLanguage } from './useSetAppLanguage';

export const useInitLangFromLocalStore = () => {
  const { i18n } = useTranslation();
  const isInitRef = useRef(false);
  const currentLanguage = useSelector(userConfigSelectors.getUiLanguage);
  const setAppLanguage = useSetAppLanguage(i18n);
  const isCurrentLanguageRTL = isRtlLanguage(currentLanguage);
  applyLayoutDirection(isCurrentLanguageRTL);
  useEffect(() => {
    if (!isInitRef.current && i18n.language !== currentLanguage) {
      // Picked language from configuration have precedence over changing browser language
      // If we don't pick language from configuration just change browser language, new language is going to be applied
      setAppLanguage(currentLanguage);
      isInitRef.current = true;
    }
  }, [i18n, currentLanguage, setAppLanguage]);
};
