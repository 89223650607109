import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  color: ${({ theme }) => theme.color6};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 30px;
`;

export const Message = styled.div`
  font-size: 18px;
  margin-bottom: 30px;
`;

export const IconButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.typo1};
`;

export const CloseButton = styled.button`
  cursor: pointer;
  width: 26px;
  height: 26px;
  position: absolute;
  right: 30px;
  top: 20px;
  bottom: 0;
  color: ${({ theme }) => theme.typo1};
`;

export const Button = styled.button`
  font-size: 18px;
  font-weight: 600;
  border: 2px solid ${({ theme }) => theme.color6};
  border-radius: 10px;
  padding: 12px 26px;
  cursor: pointer;
`;
