import {
  BROWSER_PATH,
  FLOORPLAN_VIEWS,
  KEYBOARD_ACCESSIBILITY,
  MODALS,
  PATH_SEGMENT,
  PERSON_OF_INTEREST,
  WAYFINDER_PATH,
} from '@engage-web/constants';
import { filtersActions, navigationActions, uiActions } from '@engage-web/store';
import { generateFloorPath, joinPaths } from '@engage-web/router/utils';
import { getPersonOfInterestVisibility } from './getPersonOfInterestVisibility';

/**
 * Navigates user to main screen.
 * Changes app's location to `/floor/:currentFloorId` or to `/wayfinder` if `currentFloorId` is not provided.
 * @param {Function} navigate - Return value of useNavigate hook.
 * @param {number} [currentFloorId] - Current floor id from tenantSelectors.getTenantId.
 */
export const navigateToMainScreen = (navigate, currentFloorId, dispatch) => {
  dispatch(uiActions.setFloorplanView(FLOORPLAN_VIEWS.FLOORPLAN));
  navigate({
    pathname: currentFloorId ? generateFloorPath(currentFloorId) : WAYFINDER_PATH,
  });
};

/**
 * Clears modal state to make sure that nothing obstructs the view that is going to be displayed.
 * @param {Function} dispatch - Return value of useDispatch hook.
 */
export const clearModal = dispatch => {
  dispatch(navigationActions.clearModal());
};

/**
 * Navigates user to the keyboard documentation screen.
 * @param {Object} options
 * @param {Function} options.dispatch - Return value of useDispatch hook.
 * @param {Function} options.navigate - Return value of useNavigate hook.
 */
export const goToKeyboardDocs = ({ navigate, dispatch }) => {
  clearModal(dispatch);
  navigate(KEYBOARD_ACCESSIBILITY);
};

/**
 * Navigates user to the wayfinder screen.
 * @param {Object} options
 * @param {Function} options.dispatch - Return value of useDispatch hook.
 * @param {Function} options.navigate - Return value of useNavigate hook.
 * @param {number} [options.currentFloorId] - Current floor id from tenantSelectors.getTenantId.
 */
export const goToWayfinder = ({ navigate, dispatch, currentFloorId }) => {
  clearModal(dispatch);
  navigateToMainScreen(navigate, currentFloorId, dispatch);
};

/**
 * Navigates user to the wayfinder screen with heatmaps.
 * @param {Object} options
 * @param {Function} options.dispatch - Return value of useDispatch hook.
 * @param {Function} options.navigate - Return value of useNavigate hook.
 * @param {number} [options.currentFloorId] - Current floor id from tenantSelectors.getTenantId.
 */
export const goToHeatmaps = ({ navigate, dispatch, currentFloorId }) => {
  clearModal(dispatch);

  if (currentFloorId) {
    navigate(joinPaths(generateFloorPath(currentFloorId), PATH_SEGMENT.HEATMAP));
  } else {
    navigate(WAYFINDER_PATH);
  }
};

/**
 * Navigates user to the main screen and opens agenda list modal.
 * @param {Object} options
 * @param {Function} options.dispatch - Return value of useDispatch hook.
 * @param {Function} options.navigate - Return value of useNavigate hook.
 * @param {number} [options.currentFloorId] - Current floor id from tenantSelectors.getTenantId.
 */
export const goToAgendaList = ({ dispatch, navigate, currentFloorId }) => {
  dispatch(
    navigationActions.setModalLayout({
      layout: MODALS.AGENDA,
    }),
  );

  navigateToMainScreen(navigate, currentFloorId, dispatch);
};

/**
 * Navigates user to the main screen with people view opened in the drawer and person of interest filters applied.
 * If no floor selected – opens location selector first.
 * @param {Object} options
 * @param {Function} options.dispatch - Return value of useDispatch hook.
 * @param {Function} options.navigate - Return value of useNavigate hook.
 * @param {Object} options.action - Object returned by `getActionFromEngageUrl`.
 * @param {number} [options.currentFloorId] - Current floor id from tenantSelectors.getTenantId.
 */
export const goToPersonOfInterest = ({ dispatch, navigate, action, currentFloorId }) => {
  const { isFireWarden, isFirstAidOfficer } = getPersonOfInterestVisibility(action);

  clearModal(dispatch);

  dispatch(
    filtersActions.setPersonOfInterest({
      pofType: PERSON_OF_INTEREST.MEDICAL_OFFICER,
      value: isFirstAidOfficer,
    }),
  );

  dispatch(
    filtersActions.setPersonOfInterest({
      pofType: PERSON_OF_INTEREST.FIRE_OFFICER,
      value: isFireWarden,
    }),
  );

  if (currentFloorId) {
    navigate(joinPaths(generateFloorPath(currentFloorId), PATH_SEGMENT.PEOPLE));
  } else {
    navigate(WAYFINDER_PATH);
  }
};

/**
 * Opens external url in app using `<Browser />` component or in a new tab.
 * @param {string} url Link to an external website
 * @param {Function} navigate Return result of `useNavigate` hook
 * @param {boolean} openWithExternalBrowser If this is true – URL will be opened in a new tab.
 */
export const openExternalURLInApp = (url, navigate, openWithExternalBrowser) => {
  if (openWithExternalBrowser) {
    window.open(url, '_blank');
    return;
  }

  navigate({
    pathname: BROWSER_PATH,
    search: `?url=${url}`,
  });
};

/**
 * Opens mail agent
 * @param {string} url mailto Link
 */
export const openMailAgent = url => {
  window.location.href = url;
};
