import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CHECK_IN_STATUS } from '../../constants';
import { useBookingFetch } from '../../api/queries/useBookingDetails';
import { BookingDetails } from '@engage-shared/api/bookings';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from '../../store';
import { getLocalTimeZone, useConfig, useLocalTimeFormatH12 } from '../../utils';
import useInterval from './useInterval';
import { AgendaItem } from '@engage-shared/api';
import { getCheckInStatus, getStatusMessage } from '@engage-shared/utils';

type UseCheckInStatusProps = {
  event?: AgendaItem;
  booking?: BookingDetails;
};
export const useCheckInStatus = ({ event, booking }: UseCheckInStatusProps) => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const { t } = useTranslation();
  const isTimeFormatH12 = useLocalTimeFormatH12();

  const buildingId = booking?.location.buildingId ?? event?.buildingId;

  // TODO: buildingId is null for Teams?
  const config = useConfig(buildingId);

  const { fetchBookingQuery } = useBookingFetch({
    calculateCheckInStatus: true,
  });
  const [intervalCheckEnabled, setIntervalCheckEnabled] = useState(true);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [checkInStatus, setCheckInStatus] = useState<CHECK_IN_STATUS>(CHECK_IN_STATUS.NONE);

  // TODO: We need a time zone
  const timeZone = event?.timeZone ?? booking?.localTimeZone ?? getLocalTimeZone();

  // TODO: Fix tenant config types so we don't need this
  const checkInConfig = {
    enableDeskCheckIn: config.enableDeskCheckIn,
    enableSpaceCheckIn: config.enableSpaceCheckIn,
    deskCheckInOpenWindow: config.deskCheckInOpenWindow,
    deskCheckInWindowDuration: config.deskCheckInWindowDuration,
    allDayReservationCheckInStartTime: config.allDayReservationCheckInStartTime,
  };

  const updateStatus = async () => {
    const checkInStatus = await getCheckInStatus({
      timeZone,
      event,
      booking,
      config: checkInConfig,
      fetchBookingQuery,
    });

    const { hours, minutes, status, date } = checkInStatus;

    setCheckInStatus(status);

    const messageResponse = getStatusMessage({
      hours,
      minutes,
      date,
      status,
      locale,
      timeZone,
      isTimeFormatH12,
    });
    if (messageResponse) {
      const [messageLabel, options] = messageResponse;
      setStatusMessage(t(messageLabel, options));
    } else {
      setStatusMessage('');
    }
  };

  useInterval(updateStatus, intervalCheckEnabled ? 60 * 1000 : null);

  useEffect(() => {
    // will run once on component mount
    updateStatus();
    // will run only before component will be unmounted
    return () => {
      setIntervalCheckEnabled(false);
    };
    // we're intentionally breaking rules here, because we don't care about updateStatus function changes
    // we just want it to run once after mounting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { status: checkInStatus, statusMessage };
};
