import React, { useEffect } from 'react';
import { phoneMockup, googlePlay, appStore } from '../../../../assets/images';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { QRCode } from '../../../components/base/QRCode/QRCode';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import { useAppLocation } from '../../../router/hooks';
import { WORKSPACE_PATH_INSTANCE } from '../../../constants';
import {
  ButtonsWrapper,
  ContentWrapper,
  Description,
  AppLaunchContainer,
  PhoneImage,
  PhoneImageWrapper,
  StoreButtonImage,
  Title,
  QRCodeWrapper,
} from './styled';

const qrCodeOptions = {
  type: 'image/webp',
  quality: 1,
  level: 'H',
  margin: 1,
};

interface AppLaunchProps {
  inTune?: boolean;
}

const AppLaunch = ({ inTune = false }: AppLaunchProps) => {
  const { search } = useAppLocation();
  const { '*': tenant } = useParams();
  const { t } = useTranslation();
  const currentLocation = window.location.href;

  const openGooglePlay = () => {
    const url = inTune
      ? 'https://play.google.com/store/apps/details?id=com.spaceiq.engage.intune'
      : 'https://play.google.com/store/apps/details?id=com.serraview.engage';
    window.open(url);
  };

  const openAppStore = () => {
    const url = inTune
      ? 'https://apps.apple.com/us/app/engage-intune/id1571510012'
      : 'https://apps.apple.com/us/app/serraview-engage/id1494977395';
    window.open(url);
  };

  useEffect(() => {
    // this will make page scrollable (`overflow: hidden` by default)
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'auto';
    }
    return () => {
      const body = document.querySelector('body');
      if (body) {
        body.style.overflow = 'hidden';
      }
    };
  }, []);

  if (tenant) {
    return (
      <Navigate
        to={{
          pathname: generatePath(WORKSPACE_PATH_INSTANCE, { tenant }),
          search,
        }}
        replace
      />
    );
  }

  return (
    <AppLaunchContainer>
      <PhoneImageWrapper>
        <PhoneImage draggable={false} src={phoneMockup} alt={t('layout.appLaunch.phoneImage')} />
      </PhoneImageWrapper>
      <ContentWrapper>
        <Title>{t('layout.appLaunch.title')}</Title>
        <Description>
          <Trans i18nKey="layout.appLaunch.description" components={{ h3: <h3 />, p: <p /> }} />
        </Description>
        {!isMobile && (
          <QRCodeWrapper>
            <QRCode text={currentLocation} options={qrCodeOptions} />
          </QRCodeWrapper>
        )}
        <ButtonsWrapper>
          {(!isMobile || isAndroid) && (
            <button onClick={openGooglePlay} data-testid="googlePlayButton">
              <StoreButtonImage
                draggable={false}
                src={googlePlay}
                alt={t('layout.appLaunch.googlePlayButton')}
              />
            </button>
          )}
          {(!isMobile || isIOS) && (
            <button onClick={openAppStore} data-testid="appStoreButton">
              <StoreButtonImage
                draggable={false}
                src={appStore}
                alt={t('layout.appLaunch.appStoreButton')}
              />
            </button>
          )}
        </ButtonsWrapper>
      </ContentWrapper>
    </AppLaunchContainer>
  );
};

export default AppLaunch;
