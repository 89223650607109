import React from 'react';

type HerePinProps = {
  width: number;
  height: number;
};

export const HerePin = ({ width, height }: HerePinProps) => (
  <svg
    id="here-pin"
    width={width}
    height={height}
    viewBox="0 0 24 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 31.25C7.25 27.1875 0 17.5 0 12C0 5.375 5.3125 0 12 0C18.625 0 24 5.375 24 12C24 17.5 16.6875 27.1875 13.4375 31.25C12.6875 32.1875 11.25 32.1875 10.5 31.25ZM12 16C14.1875 16 16 14.25 16 12C16 9.8125 14.1875 8 12 8C9.75 8 8 9.8125 8 12C8 14.25 9.75 16 12 16Z"
      fill="#1B4BC5"
    />
  </svg>
);
