import { BackButtonStyled } from '@engage-web/components/base/BackButton/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const BackButtonContainer = styled.div`
  ${BackButtonStyled} {
    position: absolute;
  }
`;

export const LogonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
`;

export const InputWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color5};
  padding: 0 20px;
`;

export const Input = styled.input`
  height: 40px;
  width: 300px;
  padding: 4px;
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 400;
`;

export const StyledButton = styled.button`
  margin-top: 40px;
  background: ${props => props.theme.typo2};
  color: rgba(255, 255, 255, 0.5);
  height: 60px;
  border-radius: 30px;
  min-width: 200px;
  font-size: 18px;
  font-weight: 700;
  padding: 0 30px;
  border: none;
  line-height: 25px;
  outline: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    box-shadow: 0 0 0 2px #005fff;
  }
`;

export const IconContainer = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.typo1};
  opacity: 0.5;
`;

export const ButtonContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 360px;
  margin-top: 40px;
  text-align: center;
  & .title {
    font-weight: 700;
    color: ${props => props.theme.color1};
    font-size: 20px;
    padding: 0;
    margin: 0;
  }
  & .subtitle {
    color: ${props => props.theme.color2};
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    padding: 0;
    margin-top: 8px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & .field-error {
    padding: 10px 24px;
    color: ${({ theme }) => theme.color11};
    font-weight: 400;
    font-size: 13px;
  }
`;

export const SubmitError = styled.div`
  color: ${({ theme }) => theme.color11};
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
`;
