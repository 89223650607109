import React, { memo } from 'react';
import TimeSelect from '../../TimeSelect/TimeSelect';
import { TIME_TYPES } from './data';
import { TimePickerWrapper } from './styled';
import { TimeObject, TimeValue } from './utils';
import { TimeOptions } from './hooks/useTimeOptions';

interface TimePickerProps {
  value: TimeValue;
  isTimeFormatH12?: boolean;
  onChange: (value: TimeObject | null) => void;
  timeOptions: TimeOptions;
}

const TimePicker = ({ value, onChange, timeOptions, isTimeFormatH12 = false }: TimePickerProps) => (
  <TimePickerWrapper>
    <TimeSelect
      data={timeOptions.hours}
      isPickedTimeInPast={timeOptions.isPickedTimeInPast}
      onChange={onChange}
      selectedId={value.hour}
      type={TIME_TYPES.HOUR}
    />
    <TimeSelect
      data={timeOptions.minutes}
      isPickedTimeInPast={timeOptions.isPickedTimeInPast}
      onChange={onChange}
      selectedId={value.minute}
      type={TIME_TYPES.MINUTE}
    />
    {isTimeFormatH12 && value.period && (
      <TimeSelect
        data={timeOptions.periods ?? []}
        isPickedTimeInPast={timeOptions.isPickedTimeInPast}
        onChange={onChange}
        selectedId={value.period}
        type={TIME_TYPES.PERIOD}
        isTimeFormatH12
      />
    )}
  </TimePickerWrapper>
);

export default memo(TimePicker);
