import { createSelector, createSlice } from '@reduxjs/toolkit';
import tenant, { tenantSelectors } from './tenant';
import { sortByAlphaNum } from '@engage-shared/utils';

const initialState = {
  focusedSpace: null,
  distanceMarker: undefined,
  shouldRefreshSpace: false,
  shouldShowOverlay: false,
  isLoaded: false,
  isSpaceDataLoaded: false,
  svLivePersonLocation: null,
  showSpaceAvailabilityMessage: true,
};

const floorplan = createSlice({
  name: 'floorplan',
  initialState,
  reducers: {
    setFocusedSpace(state, action) {
      state.focusedSpace = action.payload;
      // TODO: Enable when social distancing feature is added.
      // if (action?.payload?.id) state.distanceMarker = action.payload.id;
      return state;
    },
    refreshSpace(state) {
      state.shouldRefreshSpace = true;
    },
    updateSpace(state) {
      state.shouldRefreshSpace = false;
    },
    setReservedSpace(state, action) {
      state.distanceMarker = action?.payload?.id;
      return state;
    },
    setIsLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    setIsSpaceDataLoaded(state, action) {
      state.isSpaceDataLoaded = action.payload;
    },
    setShowOverlay(state, action) {
      state.shouldShowOverlay = action.payload;
    },
    setSVLivePersonLocation(state, action) {
      state.svLivePersonLocation = action.payload;
    },
    setShowSpaceAvailabilityMessage(state, action) {
      state.showSpaceAvailabilityMessage = action.payload;
    },
    clear() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    // reset floorplan flags when the floorplan location changes
    builder.addCase(tenant.actions.setCurrentLocation.type, state => {
      state.isLoaded = false;
      state.isSpaceDataLoaded = false;
    });
  },
});

const getIsLoaded = state => state.floorplan.isLoaded;
const getFloorplanComponent = state => state.floorplanComponent;

const getFloorplan = createSelector(getIsLoaded, getFloorplanComponent, (isLoaded, floorplan) =>
  isLoaded ? floorplan : null,
);

const getFloorplanSpaces = createSelector(
  getFloorplan,
  tenantSelectors.getCurrentFloorId,
  (floorplan, currentFloorId) => (floorplan && currentFloorId ? floorplan[currentFloorId] : null),
);

const getNonOccupantDesks = createSelector(getFloorplanSpaces, spaces =>
  spaces?.filteredSpaces.nonOccupantPoints
    .filter(space => space.isDesk)
    ?.sort((leftDesk, rightDesk) => sortByAlphaNum(leftDesk.name, rightDesk.name)),
);

const getFloorplanZoneId = createSelector(getFloorplanSpaces, floorplanSpaces =>
  floorplanSpaces ? floorplanSpaces?.filteredSpaces?.zones[0]?.id : 0,
);

export const floorplanSelectors = {
  getFocusedSpace: state => {
    const { focusedSpace } = state.floorplan;
    return focusedSpace;
  },
  getShouldRefreshSpace: state => {
    const { shouldRefreshSpace } = state.floorplan;
    return shouldRefreshSpace;
  },
  getShouldShowOverlay: state => state.floorplan.shouldShowOverlay,
  getIsLoaded,
  getIsSpaceDataLoaded: state => state.floorplan.isSpaceDataLoaded,
  getSVLivePersonLocation: state => state.floorplan.svLivePersonLocation,
  getShowSpaceAvailabilityMessage: state => state.floorplan.showSpaceAvailabilityMessage,
  getFloorplanComponent,
  getFloorplan,
  getFloorplanSpaces,
  getFloorplanZoneId,
  getNonOccupantDesks,
};

export default floorplan;
