import React, { memo } from 'react';
import { SPACE_ASSETS_FILTER } from '@engage-web/constants';
import { useTranslation } from 'react-i18next';
import { FilterSelectorBackdrop, FilterSelectorWrapper } from './styled';
import FilterSelectorButton from './FilterSelectorButton';

interface FilterSelectorProps {
  selectedFilterType: 'spaceTypes' | 'spaceAttributes' | null;
  spaceType: 'Space' | 'Desk' | 'Team' | 'Person';
  setFilterType: (type: 'spaceTypes' | 'spaceAttributes' | null) => void;
}

const FilterSelector = ({ setFilterType, selectedFilterType, spaceType }: FilterSelectorProps) => {
  const { t } = useTranslation();
  const toggleType = (type: 'spaceTypes' | 'spaceAttributes' | null) => {
    setFilterType(selectedFilterType === type ? null : type);
  };

  return (
    <FilterSelectorWrapper data-testid="filter-selector">
      <FilterSelectorButton
        type={SPACE_ASSETS_FILTER.TYPE}
        selectedType={selectedFilterType}
        spaceType={spaceType}
        onClick={toggleType}
      >
        {t('components.toggle.type')}
      </FilterSelectorButton>
      <FilterSelectorButton
        type={SPACE_ASSETS_FILTER.ATTRIBUTE}
        selectedType={selectedFilterType}
        spaceType={spaceType}
        onClick={toggleType}
      >
        {t('components.toggle.asset')}
      </FilterSelectorButton>
      <FilterSelectorBackdrop
        $selected={selectedFilterType}
        data-testid="filter-selector-backdrop"
      />
    </FilterSelectorWrapper>
  );
};

export default memo(FilterSelector);
