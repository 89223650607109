import { useEffect } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { useSelector } from 'react-redux';
import { floorplanSelectors } from '@engage-web/store';

export const useSvLivePersonLocation = () => {
  const fes = useFESContext();
  const svLivePersonLocation = useSelector(floorplanSelectors.getSVLivePersonLocation);

  useEffect(() => {
    if (svLivePersonLocation) {
      fes.trigger(FES_EVENTS.SHOW_SV_LIVE_PERSON_LOCATION, svLivePersonLocation);
    } else {
      fes.trigger(FES_EVENTS.HIDE_SV_LIVE_PERSON_LOCATION);
    }
  }, [fes, svLivePersonLocation]);
};
