import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  BackButtonContainer,
  ButtonContents,
  Container,
  FieldWrapper,
  HeaderWrapper,
  IconContainer,
  Input,
  InputWrapper,
  LogonContainer,
  StyledButton,
  SubmitError,
  TitleContainer,
} from './styled';
import { useLogon } from '@engage-web/api/mutations/useLogon';
import { BackButton, Icon, Mask } from '@engage-web/components/base';
import {
  ROOT_PATH,
  WORKSPACE_PATH,
  MODAL_DEEP_LINK_PARAMS,
  DRAWER_DEEP_LINK_PARAMS,
  DATE_TIME_DEEP_LINK_PARAMS,
} from '@engage-web/constants';
import { tenantSelectors } from '@engage-web/store/tenant';
import { useOnNavigateBack } from '@engage-web/utils/hooks/useOnNavigateBack';
import { useBypassSSO, useSetPageTitle } from '@engage-web/utils';
import { i18n } from '@engage-web/locales';
import { useTranslation } from 'react-i18next';
import useForm from '@engage-web/utils/hooks/useForm';
import { useAppLocation } from '@engage-web/router/hooks';
import { hasValidParamInSearchQuery } from '@engage-web/router/utils';
import { navigationActions } from '@engage-web/store';
import { Logo } from '@engage-web/components/base/Logo';
import { Nullable } from '@engage-shared/utils/types';

const usernameValidator = (value: string): Nullable<string> => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // allow non-email accounts to login; at least one character followed by “:” than followed by username (char_char)
  // "sv:ts" or  "sv:tony.stark" or "cba:jon_clark"
  const userNameRegEx = /^([a-zA-Z])*:([a-zA-Z0-9._]{1,16})$/;
  if (!value) {
    return i18n.t('scenes.login.errorMessages.emailFieldRequired');
  }
  if (
    !emailRegex.test(String(value).toLowerCase().trim()) &&
    !userNameRegEx.test(String(value).toLowerCase().trim())
  ) {
    return i18n.t('scenes.login.errorMessages.emailFieldInvalid');
  }
  return null;
};

const passwordValidator = (value: string): Nullable<string> => {
  const hasSpacesRegex = /\s/;
  if (!value) {
    return i18n.t('scenes.login.errorMessages.passwordFieldRequired');
  }
  if (hasSpacesRegex.test(value.trim())) {
    return i18n.t('scenes.login.errorMessages.passwordFieldShouldNotContainSpaces');
  }
  return null;
};

const Login = () => {
  const [submitError, setSubmitError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useAppLocation();
  const { deactivateBypassSSO } = useBypassSSO();
  const { t } = useTranslation();
  // deactivate BypassSSO when a user goes back.
  useOnNavigateBack(deactivateBypassSSO);

  const {
    values: { username, password },
    errors,
    hasValidationErrors,
    updateValue,
  } = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validators: {
      username: usernameValidator,
      password: passwordValidator,
    },
    onUpdateValue: () => {
      setSubmitError(null);
    },
  });

  useSetPageTitle(t('documentTitles.userLogin'));

  const tenantId = useSelector(tenantSelectors.getTenantIdToLogin);

  const { mutate, isLoading } = useLogon({
    onSuccess: () => {
      const hasDeepLink = hasValidParamInSearchQuery(search, {
        ...DRAWER_DEEP_LINK_PARAMS,
        ...MODAL_DEEP_LINK_PARAMS,
        ...DATE_TIME_DEEP_LINK_PARAMS,
      });

      if (hasDeepLink) {
        dispatch(navigationActions.setDeepLinkQueryString(search));
      }

      navigate({
        pathname: ROOT_PATH,
        search,
      });
    },
    onError: (error: any) => {
      const message = error?.message || t('scenes.login.errorMessages.somethingWentWrong');
      setSubmitError(message);
    },
  });

  const onLogon = () => {
    const usernameClear = String(username).trim();
    const passwordClear = String(password).trim();
    mutate({ username: usernameClear, password: passwordClear, tenantId });
  };

  /**
   * Always navigate to workspace.
   * If the login page is opened directly with WORKSPACE_PATH_INSTANCE
   * we do not have history to go back to.
   */
  const goToWorkspace = () => {
    navigate({
      pathname: WORKSPACE_PATH,
    });
    deactivateBypassSSO();
  };

  return (
    <>
      <Mask isLoading={isLoading} />
      <BackButtonContainer>
        <BackButton onClick={goToWorkspace} />
      </BackButtonContainer>
      <Container>
        <LogonContainer>
          <HeaderWrapper>
            <Logo />
            <TitleContainer>
              <h2 className="title">{t('scenes.login.title')}</h2>
              <h3 className="subtitle">{t('scenes.login.subtitle')}</h3>
            </TitleContainer>
          </HeaderWrapper>
          <FieldWrapper>
            <InputWrapper>
              <Input
                data-testid="username-field"
                placeholder={t('scenes.login.usernameFieldPlaceholder')}
                name="username"
                value={username}
                onChange={updateValue}
              />
            </InputWrapper>
            {errors.username && (
              <div className="field-error" data-testid="field-error">
                {errors.username}
              </div>
            )}
          </FieldWrapper>

          <FieldWrapper>
            <InputWrapper>
              <Input
                data-testid="password-field"
                type="password"
                placeholder={t('scenes.login.passwordFieldPlaceholder')}
                name="password"
                value={password}
                onChange={updateValue}
              />
            </InputWrapper>
            {errors.password && <div className="field-error">{errors.password}</div>}
          </FieldWrapper>

          {submitError && <SubmitError data-testid="submit-error">{submitError}</SubmitError>}

          <StyledButton
            data-testid="submit-button"
            onClick={onLogon}
            disabled={hasValidationErrors}
          >
            <ButtonContents>
              {t('common.continue')}
              <IconContainer>
                <Icon shouldRotateOnRtl name="disclosure" size={10} />
              </IconContainer>
            </ButtonContents>
          </StyledButton>
        </LogonContainer>
      </Container>
    </>
  );
};

export default Login;
