import React from 'react';
import ReactMarkdown from 'react-markdown';

type MarkDownTextProps = {
  children: string;
  style: React.CSSProperties;
};

export const MarkDownText = ({ children, style = {} }: MarkDownTextProps) => (
  <ReactMarkdown
    components={{
      p: ({ node, ...props }) => <p style={{ textAlign: 'center', ...style }} {...props} />,
      li: ({ node, ...props }) => <li style={style} {...props} />,
    }}
  >
    {children}
  </ReactMarkdown>
);
