import { useEffect, useState } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';

export const useShowLabels = () => {
  const [showLabels, setShowLabels] = useState(false);
  const fes = useFESContext();

  useEffect(() => {
    const handleShowLabelsChange = (params: { showLabels: boolean }) => {
      setShowLabels(params.showLabels);
    };

    fes.on(FES_EVENTS.SHOW_LABELS, handleShowLabelsChange);
    return () => {
      fes.off(FES_EVENTS.SHOW_LABELS, handleShowLabelsChange);
    };
  }, [fes]);

  return { showLabels };
};
