import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 350px;
  margin-bottom: 40px;
  margin-top: 40px;
`;
export const Title = styled.h2`
  font-weight: 700;
  color: ${props => props.theme.color1};
  font-size: 20px;
  padding: 0;
  margin: 0;
`;

export const SubTitle = styled.h3`
  color: ${props => props.theme.color2};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  margin-top: 8px;
`;

export const ButtonContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled.button`
  background: ${props => props.theme.typo2};
  color: rgba(255, 255, 255, 0.5);
  height: 60px;
  border-radius: 30px;
  min-width: 200px;
  font-size: 18px;
  font-weight: 700;
  padding: 0 30px;
  border: none;
  line-height: 25px;
  outline: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    box-shadow: 0 0 0 2px #005fff;
  }
`;

export const IconButton = styled.button`
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 20px;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    box-shadow: 0 0 0 3px #005fff;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 60px;
  margin-top: 40px;
`;

export const Error = styled.div`
  height: 50px;
  width: 500px;
  text-align: center;
  color: red;
  margin-top: 25px;
`;

export const IconContainer = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.typo1};
  opacity: 0.5;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
