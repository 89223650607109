import { useMutation } from 'react-query';
import { useBuildingFetch, useBuildingReservationLimit } from '@engage-shared/api/buildings';
import { saveBooking as save } from '@engage-shared/api/bookings/mutations/utils';
import {
  BookingMutationData,
  BookingMutationParams,
  BookingResult,
  SaveBookingParams,
  SetBookingMutationOptions,
} from '@engage-shared/api/bookings/interfaces';

type BookingMutation = (
  params: BookingMutationParams,
  options: SetBookingMutationOptions,
) => BookingResult;
/**
 * Returns mutation for create/update booking.
 * @param tenantId
 * @param email
 * @param spaceItem
 * @param reserveeId
 * @param duration
 * @param allDayDuration
 * @param isUpdate
 * @param summaryText
 * @param filter
 * @param bookingInitialSpaceId
 * @param meetingId
 * @param localTimeZone
 * @param successCallback
 * @param errorCallback
 * @param options
 */
export const useBookingMutation: BookingMutation = (
  {
    tenantId,
    email,
    spaceItem,
    reserveeId,
    duration,
    allDayDuration,
    isUpdate,
    summaryText,
    filter,
    bookingInitialSpaceId,
    meetingId,
    localTimeZone,
    successCallback,
    errorCallback,
  },
  options = {},
) => {
  const { fetchBuildingQuery } = useBuildingFetch();
  const { hasBuildingLimitPer15Minutes } = useBuildingReservationLimit();

  const bookingMutation = useMutation<BookingMutationData, Error, SaveBookingParams>(save, options);

  const saveBooking: () => void = () => {
    return bookingMutation.mutate({
      tenantId,
      allDayDuration,
      duration,
      bookingInitialSpaceId,
      spaceItem,
      reserveeId,
      errorCallback,
      filter,
      email,
      isUpdate,
      summaryText,
      localTimeZone,
      successCallback,
      meetingId,
      fetchBuildingQuery,
      hasBuildingLimitPer15Minutes,
    });
  };

  return { ...bookingMutation, saveBooking };
};
