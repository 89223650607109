import { useSearchParams } from 'react-router-dom';
import { BOOKING_TYPE } from '@engage-shared/constants';
import { SEARCH_QUERY } from '../../constants';
import { AgendaItem } from '@engage-shared/api';

type BookingShowParams = Pick<
  AgendaItem,
  'spaceId' | 'meetingId' | 'bookingType' | 'isDesk' | 'reservee'
>;

export const useBookingModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const show = ({ spaceId, meetingId, bookingType, isDesk, reservee }: BookingShowParams) => {
    if (bookingType === BOOKING_TYPE.TEAM && !isDesk) {
      setSearchParams({
        [SEARCH_QUERY.TEAM_BOOKING_MEETING]: `${meetingId}`,
        [SEARCH_QUERY.ATTENDEE_ID]: `${reservee}`,
      });
    } else {
      setSearchParams({
        [SEARCH_QUERY.BOOKING_SPACE]: `${spaceId}`,
        [SEARCH_QUERY.BOOKING_MEETING]: `${meetingId}`,
      });
    }
  };

  const close = () => {
    searchParams.delete(SEARCH_QUERY.BOOKING_SPACE);
    searchParams.delete(SEARCH_QUERY.BOOKING_MEETING);
    searchParams.delete(SEARCH_QUERY.TEAM_BOOKING_MEETING);
    searchParams.delete(SEARCH_QUERY.ATTENDEE_ID);

    setSearchParams(searchParams);
  };

  return { show, close };
};
