import { useCallback, useEffect } from 'react';

type UseEscapeListenerParams = {
  onEscapePress: () => void;
};

export const ESC_KEY_CODE = 'Escape';

export const useEscapeListener = ({ onEscapePress }: UseEscapeListenerParams) => {
  const onKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === ESC_KEY_CODE) {
        onEscapePress();
      }
    },
    [onEscapePress],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress, false);

    return () => {
      document.removeEventListener('keydown', onKeyPress, false);
    };
  }, [onKeyPress]);
};
