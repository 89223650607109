import { useCallback, useEffect } from 'react';
import { KioskRemoteConfig, useKioskConfig } from '@engage-web/api/queries';
import logger from '@engage-web/utils/logger';
import { useLoadKioskConfigDetails } from '@engage-web/utils/hooks/useLoadKioskConfigDetails';
import { UseQueryOptions } from 'react-query';

type UseLoadKioskConfigParams = {
  options?: UseQueryOptions<KioskRemoteConfig>;
  spaceId?: number;
};

export const useLoadKioskConfig = ({ options, spaceId }: UseLoadKioskConfigParams = {}) => {
  const { data, isLoading, isError } = useKioskConfig({
    id: spaceId ?? 0,
    options,
  });

  const { loadConfigDetails } = useLoadKioskConfigDetails();

  const _loadConfigDetails = useCallback(
    async (data: KioskRemoteConfig) => {
      try {
        await loadConfigDetails(data);
      } catch (e) {
        logger.error(e)();
      }
    },
    [loadConfigDetails],
  );

  useEffect(() => {
    if (isError && !data) {
      return;
    }

    if (!data || isLoading) {
      return;
    }

    _loadConfigDetails(data);
  }, [data, isError, isLoading, _loadConfigDetails]);

  return { isLoading };
};
