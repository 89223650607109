import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store/tenant';
import {
  DRAWER_DEEP_LINK_PARAMS,
  LANDING_PATH,
  ORGANIZATION_POLICY_PATH,
  ROOT_PATH,
} from '@engage-web/constants';
import { navigationActions } from '@engage-web/store';
import { useShowAcknowledgementPolicy } from '@engage-web/router/hooks/useShowAcknowledgementPolicy';
import { useShowLandingPage } from '@engage-web/components/scenes/Main/hooks';
import { hasTokens } from '@engage-web/utils/config/tokenConfig';
import { useAppLocation } from '@engage-web/router/hooks';
import { getRedirectPath, hasValidParamInSearchQuery } from '@engage-web/router/utils';
import { Mask } from '@engage-web/components/base';
import { isElectron } from '@engage-web/utils/electron';
import { AvailableKiosks } from '@engage-web/components/kiosk';
import { useUserConfig } from '@engage-shared/api';

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const { search, pathname: path } = useAppLocation();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { isLoading: isUserConfigLoading, data: userConfig } = useUserConfig({
    options: { enabled: !!tenantId },
  });
  const showAcknowledgementPolicy = useShowAcknowledgementPolicy(userConfig);
  const showLandingPage = useShowLandingPage(userConfig);
  const { isLoaded: isKioskConfigLoaded } = useSelector(tenantSelectors.getKioskConfig);
  const isAuthenticated = hasTokens();

  const hasTenantId = !!tenantId;
  const redirectPath = getRedirectPath({ isAuthenticated, hasTenantId });

  // set search deeplink
  // When app is started with a valid deep link persist it to store.
  // The effect was added here and not on PublicRoute because
  // root path '/' redirects to PrivateRoute that will redirect to PublicRoute workspace or login
  // URLs: /?desk=1594 or /?employee=sv:rn
  useEffect(() => {
    if (hasValidParamInSearchQuery(search, DRAWER_DEEP_LINK_PARAMS)) {
      dispatch(navigationActions.setDeepLinkQueryString(search));
    }
  }, [dispatch, search]);

  // set deeplink (new)
  useEffect(() => {
    if (!hasTenantId && !isAuthenticated && path !== ROOT_PATH) {
      dispatch(navigationActions.setDeepLinkPath(path));
    }
  }, [isAuthenticated, path, dispatch, hasTenantId]);

  if (!isAuthenticated) {
    return <Navigate replace to={{ pathname: redirectPath, search }} />;
  }

  if (isUserConfigLoading) {
    return <Mask isLoading />;
  }

  if (!isKioskConfigLoaded && isElectron()) {
    return <AvailableKiosks />;
  }

  if (path !== ORGANIZATION_POLICY_PATH && path !== LANDING_PATH && showAcknowledgementPolicy) {
    return (
      <Navigate
        replace
        to={{
          pathname: ORGANIZATION_POLICY_PATH,
          search,
        }}
      />
    );
  }

  if (path !== LANDING_PATH && path !== ORGANIZATION_POLICY_PATH && showLandingPage) {
    return (
      <Navigate
        replace
        to={{
          pathname: LANDING_PATH,
          search,
        }}
      />
    );
  }

  if (path === LANDING_PATH && !showLandingPage) {
    return (
      <Navigate
        replace
        to={{
          pathname: ROOT_PATH,
          search,
        }}
      />
    );
  }
  return children;
};
