import { getShapeCoordinate } from './shapeUtils';
import { Coordinate, Shape, Space } from '@floorplan/api';

export const getCoordinateString = (shape: Shape) =>
  shape.coordinates.map(coordinate => `${coordinate.x},${coordinate.y}`).join(' ');

export const getPathString = (coordinates: Coordinate[]) =>
  `M ${coordinates.map(coordinate => `${coordinate.x} ${coordinate.y}`).join(' L ')}`;

type GetTransformForCoordinatesParams = {
  coordinate: Coordinate;
  width?: number;
  height?: number;
  scale?: number;
};
export const getTransformForCoordinate = ({
  coordinate,
  width = 0,
  height = 0,
  scale = 1,
}: GetTransformForCoordinatesParams) => ({
  transform: `translate(${coordinate.x - width}px, ${
    coordinate.y - (height || width)
  }px) scale(${scale})`,
});

type GetTransformStringProps = {
  space: Space;
  scale?: number;
  width?: number;
  height?: number;
};
export const getTransformString = ({ space, width, height, scale }: GetTransformStringProps) => {
  const coordinate = getShapeCoordinate(space);

  return getTransformForCoordinate({ width, height, scale, coordinate });
};

type GetRotationStringProps = {
  rotation: number;
  width?: number;
  height?: number;
};
export const getRotationString = ({ width, height, rotation }: GetRotationStringProps) => ({
  transformOrigin: `${width}px ${height || width}px`,
  transform: `rotate(${rotation}deg)`,
});
