import React, { memo } from 'react';
import { Wrapper } from './styled';

type LocationStatusLabelProps = {
  text: string;
  backgroundColor: string;
  color?: string;
};

function LocationStatusLabel({
  text,
  backgroundColor,
  color = '#fff',
  ...rest
}: LocationStatusLabelProps) {
  return (
    <Wrapper $backgroundColor={backgroundColor} $color={color} {...rest}>
      {text}
    </Wrapper>
  );
}

export default memo(LocationStatusLabel);
