import { getLogger, setLogger } from '@engage-shared/utils';
import { getConfig, TokenManager } from '@engage-shared/api';
import { APP_NAMES } from '@engage-shared/constants';

import { LOGIN_PATH } from '@engage-web/constants';
import logger from '@engage-web/utils/logger';

getConfig().config = {
  appName: APP_NAMES.ENGAGE_WEB,
  appVersion: __CONF__.version,
  api: {
    onResponseError: async error => {
      getLogger().log(error);
      await TokenManager.removeTokens();
      // @ts-ignore
      window.history.push(LOGIN_PATH);
    },
  },
};

setLogger({
  log: message => logger.debug(message)(),
  info: message => logger.info(message)(),
  warn: message => logger.warn(message)(),
  error: message => logger.error(message)(),
});
