import * as React from 'react';

import { Space } from '@floorplan/api';
import { Marker } from '../../../components';
import { FocusMarker } from './FocusMarker';

interface MarkerIconProps {
  space: Space;
  person?: any;
  scale?: number;
}

export const MarkerIcon = ({ space, person, scale }: MarkerIconProps) => {
  return (
    <>
      <FocusMarker coordinate={space?.shapes[0]?.coordinates[0]} scale={scale} />
      <Marker space={space} person={person} />
    </>
  );
};
