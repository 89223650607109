import React, { memo } from 'react';
import LocationStatusLabel from '@engage-web/components/base/LocationStatusLabel/LocationStatusLabel';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { testProps } from '@engage-web/utils';

type SpaceCardStatusProps = {
  status?: string;
};

const SpaceCardStatus = ({ status = '' }: SpaceCardStatusProps) => {
  const { t } = useTranslation();
  const { color1 } = useTheme() as any;

  if (status) {
    const {
      props: a11yAndTestProps,
      text: [statusText],
    } = testProps(t, status);

    return <LocationStatusLabel {...a11yAndTestProps} text={statusText} backgroundColor={color1} />;
  }

  return null;
};

export default memo(SpaceCardStatus);
