// @ts-nocheck

import { useFESContext } from '@floorplan/fes';
import React, { PropsWithChildren } from 'react';
import { calculateTransform } from '../../utils';

// TODO move to utils
const getTransformString = transform =>
  transform
    ? `translate(${transform.translateX}, ${transform.translateY}) ` +
      `scale(${transform.scale}) ` +
      `rotate(${transform.rotate})`
    : null;

type ShapesProps = PropsWithChildren<{
  show?: boolean;
  className?: string;
}>;
export const Shapes = ({ show = true, className, children }: ShapesProps) => {
  const fes = useFESContext();
  const viewer = fes.getViewer()?.getViewer();

  const parentRef = React.useRef(null);
  const svgRef = React.useRef(null);

  const [transform, setTransform] = React.useState(null);

  const handleAnimation = React.useCallback(() => {
    const calcTransform = calculateTransform(viewer);
    setTransform(calcTransform);
  }, [viewer]);

  const addViewerHandlers = React.useCallback(() => {
    if (viewer) {
      viewer.addHandler<'animation'>('animation', handleAnimation);
      viewer.addHandler<'resize'>('resize', handleAnimation);
      viewer.addHandler<'rotate'>('rotate', handleAnimation);
      viewer.canvas.appendChild(svgRef.current);
    }
  }, [handleAnimation, viewer]);

  const removeViewerHandlers = React.useCallback(() => {
    if (viewer) {
      viewer.removeHandler<'animation'>('animation', handleAnimation);
      viewer.removeHandler<'resize'>('resize', handleAnimation);
      viewer.removeHandler<'rotate'>('rotate', handleAnimation);
    }
  }, [handleAnimation, viewer]);

  React.useEffect(() => {
    if (viewer) {
      removeViewerHandlers();
      addViewerHandlers();
      handleAnimation();
    }
    return () => {
      removeViewerHandlers();
    };
  }, [addViewerHandlers, handleAnimation, removeViewerHandlers, viewer]);

  const transformString = getTransformString(transform);

  return (
    <div ref={parentRef}>
      <svg
        ref={svgRef}
        className={`floorplan-${className || 'shapes'}`}
        // aria-label={t('accessibilityLabels.floorplanLabels')}
      >
        {show && viewer && <g transform={transformString}>{children}</g>}
      </svg>
    </div>
  );
};
