export type LocalizedColor = {
  backgroundColor: string;
  textColor: string;
};

const colors: Record<string, LocalizedColor[]> = {
  default: [
    {
      backgroundColor: '#12708D;',
      textColor: 'white',
    },
    {
      backgroundColor: '#1C7E46',
      textColor: 'white',
    },
    {
      backgroundColor: '#15458E',
      textColor: 'white',
    },
    {
      backgroundColor: '#9b59b6',
      textColor: 'white',
    },
    {
      backgroundColor: '#34495e',
      textColor: 'white',
    },
    {
      backgroundColor: '#16a085',
      textColor: 'white',
    },
    {
      backgroundColor: '#426F55',
      textColor: 'white',
    },
    {
      backgroundColor: '#2980b9',
      textColor: 'white',
    },
    {
      backgroundColor: '#8e44ad',
      textColor: 'white',
    },

    {
      backgroundColor: '#2c3e50',
      textColor: 'white',
    },
    {
      backgroundColor: '#7B7353',
      textColor: 'white',
    },
    {
      backgroundColor: '#9D510D',
      textColor: 'white',
    },
    {
      backgroundColor: '#e74c3c',
      textColor: 'white',
    },
    {
      backgroundColor: '#007D76',
      textColor: 'black',
    },
    {
      backgroundColor: '#E00900',
      textColor: 'black',
    },
    {
      backgroundColor: '#757706',
      textColor: 'white',
    },
    {
      backgroundColor: '#d35400',
      textColor: 'white',
    },
    {
      backgroundColor: '#c0392b',
      textColor: 'white',
    },
    {
      backgroundColor: '#5552FF',
      textColor: 'black',
    },
    {
      backgroundColor: '#BA0A74',
      textColor: 'white',
    },
  ],
};

export default colors;
