// Extended wall style has the border in another color to indicate building walls
import { ThemeItem, ThemeKey, ThemeManager } from '../../theme';
import {
  Shape as ShapeType,
  Space,
  getSpaceOccupiedByPresenceStatus,
  isSpaceOccupiedBySensor,
  PRESENCE_STATUS,
} from '@floorplan/api';
import { Layers } from '../../types';

const extendedWallStyle = (zoomTilesShown: boolean) => {
  const bobj = ThemeManager.getThemeObject(ThemeKey.background) as ThemeItem;
  const wobj = ThemeManager.getThemeObject(ThemeKey.wall) as ThemeItem;

  if (zoomTilesShown) {
    return {
      fill: '#FFFFFF',
      stroke: '#FFFFFF',
      opacity: 0,
      strokeOpacity: 0,
      strokeWidth: 100,
    };
  }

  return {
    fill: bobj?.color,
    stroke: wobj?.color,
    strokeWidth: 100,
  };
};

const basicWallStyle = () => {
  const obj = ThemeManager.getThemeObject(ThemeKey.background) as ThemeItem;

  return {
    fill: obj.color,
    stroke: obj.color,
    strokeWidth: 0,
  };
};

// style of spaces (rooms, zones)
const spaceStyle = (space: Space) => {
  const { available, allowInteraction } = space;
  const isUnImportant = !allowInteraction;
  const hasOccupant = space.homeLocations;

  if (isUnImportant) {
    return ThemeManager.getStyle(ThemeKey.unImportantSpace);
  }

  if (hasOccupant || !available) {
    return ThemeManager.getStyle(ThemeKey.unAvailableSpace);
  }

  return ThemeManager.getStyle(ThemeKey.availableSpace);
};

// @ts-ignore
const pointSizes = size => ThemeManager.getThemeObject(ThemeKey.pointSizes)[size];

// occupant point is the point where someone is placed, it can be both for desks and spaces
type OccupantPointStyleProps = {
  space: Space;
  shape?: ShapeType;
  startTime?: string;
  layers?: Layers;
  reservedAlwaysEnabled?: boolean;
};
const occupantPointStyle = ({
  space,
  startTime,
  layers,
  reservedAlwaysEnabled,
}: OccupantPointStyleProps) => {
  if (
    (layers && layers.showOccupants && !layers.all && space.isReserved) ||
    (reservedAlwaysEnabled && space.isReserved)
  ) {
    return ThemeManager.getStyle(ThemeKey.focusedUnAvailableSpace);
  }

  // space occupied by SVLive presence
  const { isOccupied, presenceEvent } = getSpaceOccupiedByPresenceStatus(space, startTime);
  if (isOccupied) {
    if (presenceEvent?.presenceStatus === PRESENCE_STATUS.REMOTE) {
      return ThemeManager.getStyle(ThemeKey.unAvailableSpace);
    }
    return ThemeManager.getStyle(ThemeKey.focusedUnAvailableSpace);
  }

  // space occupied by sensor
  if (isSpaceOccupiedBySensor(space, startTime)) {
    return ThemeManager.getStyle(ThemeKey.focusedUnAvailableSpace);
  }

  return ThemeManager.getStyle(ThemeKey.unAvailableSpace);
};

// temporary occupied point is the point where someone is present, by sensor data, it can be both for desks and spaces
type TemporaryOccupantPointStyleProps = {
  space: Space;
  startTime?: string;
};
const temporaryOccupantPointStyle = ({ space, startTime }: TemporaryOccupantPointStyleProps) => {
  // space occupied by sensor
  if (isSpaceOccupiedBySensor(space, startTime)) {
    return ThemeManager.getStyle(ThemeKey.focusedTemporaryUnAvailableSpace);
  }

  return ThemeManager.getStyle(ThemeKey.unAvailableSpace);
};

// non occupant points are available desks (have isDesk on their space)
const nonOccupantPointStyle = (space: Space) => {
  const { available } = space;
  const isUnImportant = !space.allowInteraction;

  if (isUnImportant) {
    return ThemeManager.getStyle(ThemeKey.unImportantSpace);
  }

  if (!available) {
    return ThemeManager.getStyle(ThemeKey.unAvailableSpace);
  }

  return ThemeManager.getStyle(ThemeKey.availableSpace);
};

export {
  basicWallStyle,
  extendedWallStyle,
  spaceStyle,
  occupantPointStyle,
  temporaryOccupantPointStyle,
  nonOccupantPointStyle,
  pointSizes,
};
