import { ATTENDEE_STATUS } from '@engage-shared/constants/attendee';
import { fetchPerson } from '@engage-shared/api/people';
import { ParsedUserData } from '@engage-shared/api/users/interfaces';
import { TenantId } from '@engage-shared/api/tenant/interfaces';
import {
  BookingAttendee,
  BookingDetails,
} from '@engage-shared/api/bookings/interfaces/bookingDetails.type';
import { Nullable } from '@engage-shared/utils/types';
import { FetchBookingResponse } from '@engage-shared/api/bookings/interfaces';
import { SpaceItem } from '@engage-shared/api/spaces/interfaces';
import { isAllDayBookingLocalized } from '@engage-shared/utils/dates';
import { FetchBuildingQuery } from '@engage-shared/api/buildings/interfaces';
import { BOOKING_TYPE } from '@engage-shared/constants/bookingTypes';
import { PersonId } from '@engage-shared/api/people/interfaces';
import { UserId } from '@engage-shared/api/types';
import { getLogger } from '@engage-shared/utils/logger';

type GetBookedForParams = {
  tenantId: TenantId;
  userData: ParsedUserData;
  /**
   * person id of the reservee
   */
  reserveeId: PersonId;
  /**
   * organizerId user id of the organizer
   */
  organizerId: Nullable<number>;
};
type GetBookedForResult = {
  bookedFor: BookingAttendee[];
  isBookedForOther: boolean;
};
type GetBookedFor = (params: GetBookedForParams) => Promise<GetBookedForResult>;

const getBookedFor: GetBookedFor = async ({ userData, reserveeId, organizerId }) => {
  let bookedFor: BookingAttendee[] = [];
  let isBookedForOther = false;

  let reservee: Nullable<Partial<ParsedUserData>> = userData;

  if (reserveeId && userData && userData.personId !== reserveeId) {
    isBookedForOther = true;
    try {
      const personData = await fetchPerson({ id: reserveeId });
      if (personData) {
        const { id, jobTitle, personName } = personData;

        reservee = {
          id,
          jobTitle,
          name: personName,
        };
      } else {
        reservee = null;
      }
    } catch (error) {
      reservee = null;
    }
  }

  if (reservee && reservee?.name) {
    // reservee.userId is undefined for reservee other than current user because fetchPerson doesn't return userId
    const isOrganiser = organizerId ? organizerId === reservee?.userId : true;
    bookedFor = [
      {
        name: reservee.name,
        description: reservee?.jobTitle || '',
        imageUrl: reservee?.imageUrl || '',
        isOrganiser,
        status: ATTENDEE_STATUS.ACCEPTED,
      },
    ];
  }
  return { bookedFor, isBookedForOther };
};

type ParseSpaceDataResult = {
  summary: Nullable<string>;
  timeZone: string;
  isAllDayBooking: Nullable<boolean>;
  buildingId: Nullable<number>;
  organizerId: Nullable<UserId>;
};
type ParseSpaceData = (spaceData: Nullable<SpaceItem>, meetingId: number) => ParseSpaceDataResult;
//TODO: Fix this because along with team bookings changes, bookings array from space data was removed and this is not working anymore
const parseSpaceData: ParseSpaceData = (spaceData, meetingId) => {
  const parsedData: ParseSpaceDataResult = {
    summary: '',
    timeZone: '',
    isAllDayBooking: null,
    buildingId: null,
    organizerId: null,
  };

  if (!spaceData) return parsedData;

  const { isDesk, spaceType, buildingId, bookings } = spaceData;

  parsedData.buildingId = buildingId;
  if (isDesk) {
    parsedData.summary = spaceType;
  }

  bookings?.forEach(booking => {
    if (`${booking.id}` === `${meetingId}`) {
      parsedData.timeZone = booking.start_time_zone;
      parsedData.isAllDayBooking = booking.is_all_day_booking;
      parsedData.organizerId = booking.organizer_id;
      if (!isDesk) {
        parsedData.summary = booking.summary;
      }
    }
  });

  return parsedData;
};

type AssembleBookingDetailsParams = {
  tenantId: TenantId;
  bookingData: FetchBookingResponse;
  spaceData: Nullable<SpaceItem>;
  userData: ParsedUserData;
  fetchBuildingQuery: FetchBuildingQuery;
};
type AssembleBookingDetails = (
  params: AssembleBookingDetailsParams,
) => Promise<Nullable<BookingDetails>>;
export const assembleBookingDetails: AssembleBookingDetails = async ({
  tenantId,
  bookingData,
  spaceData,
  userData,
  fetchBuildingQuery,
}) => {
  if (!bookingData) {
    return null;
  }
  const booking = {} as BookingDetails;

  const {
    reservee,
    isDesk,
    attendees,
    startTime,
    endTime,
    location,
    spaceName,
    bookingType,
    isCheckedIn,
    isCheckInAvailable,
    canUpdate,
    spaceId,
    meetingId,
    preferredName,
    summary: meetingSummary,
    isAllDayBooking,
    isInInvalidFloor,
    isInInvalidBuilding,
    isInInvalidSpace,
    startTimeZone,
  } = bookingData;

  const {
    timeZone,
    buildingId: spaceBuildingId,
    organizerId,
    summary: eventSummary,
  } = parseSpaceData(spaceData, meetingId);

  const buildingId = location?.buildingId || spaceBuildingId;
  let building = null;

  if (!isInInvalidBuilding && buildingId) {
    try {
      building = await fetchBuildingQuery(buildingId);
    } catch (e) {
      getLogger().warn('Building cannot be fetched');
    }
  }

  const { bookedFor, isBookedForOther } = await getBookedFor({
    tenantId,
    userData,
    reserveeId: reservee?.reserveeId,
    organizerId,
  });
  booking.bookedFor = bookedFor;
  booking.isBookedForOther = isBookedForOther;

  if (!isDesk) {
    booking.attendees = attendees && attendees !== 'N/A' ? attendees : [];
  }

  booking.startDate = new Date(startTime);
  booking.endDate = new Date(endTime);
  booking.localTimeZone = timeZone ?? building?.timezone ?? ''; // TODO: Make this default to local time zone

  booking.allDay =
    isAllDayBooking ||
    isAllDayBookingLocalized({
      dateS: booking.startDate,
      dateE: booking.endDate,
      timeZone: booking.localTimeZone || undefined,
    });

  const name = preferredName || spaceName;
  if (isDesk) {
    booking.desk = name;
  } else {
    booking.meetingRoom = name;
  }
  booking.description = isDesk ? eventSummary : meetingSummary;
  booking.title = name;
  booking.spaceType = bookingType;

  booking.location = location;
  booking.isCheckedIn = isCheckedIn;
  booking.isCheckInAvailable = isCheckInAvailable;
  booking.canUpdate = canUpdate;
  booking.isDesk = isDesk;
  booking.spaceId = spaceId;
  booking.meetingId = meetingId;
  booking.isInInactiveLocation = isInInvalidBuilding || isInInvalidFloor || isInInvalidSpace;

  booking.bookingType = isDesk ? BOOKING_TYPE.DESK : BOOKING_TYPE.SPACE;
  booking.localTimeZone = startTimeZone;

  return booking;
};
