import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';

const useConfigLanguages = () => {
  const { availableLanguages } = useSelector(tenantSelectors.getConfig);
  return Object.keys(availableLanguages).map(key => ({
    label: availableLanguages[key],
    value: key,
  }));
};

export default useConfigLanguages;
