import { Api, getApiUrl } from '@engage-shared/api/instance';
import { DeskAvailability } from '@engage-shared/api/spaces';
import { FetchFloorDeskAvailabilitiesParams } from './interfaces';

type FetchFloorDeskAvailabilities = (
  param: FetchFloorDeskAvailabilitiesParams,
) => Promise<DeskAvailability[]>;
/**
 * Fetches desks availability information based on provided
 * floor id.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | spaces found |
 * | 404 | floor not found |
 */
export const fetchFloorDeskAvailabilities: FetchFloorDeskAvailabilities = async ({
  floorId,
  startTime,
  endTime,
}) => {
  const url = new URL(`${getApiUrl()}/floors/${floorId}/available/desks`);

  if (startTime) url.searchParams.append('startTime', startTime);
  if (endTime) url.searchParams.append('endTime', endTime);

  const response = await Api.get(url.toString());
  return response.data?.data;
};
