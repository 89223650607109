import { hashString, showAcknowledgementPolicy } from '@engage-shared/utils';
import { useConfig } from '@engage-web/utils/hooks';
import { ACKNOWLEDGEMENT } from '@engage-web/constants';
import { UserConfig } from '@engage-shared/api';

type UseShowAcknowledgementPolicy = (userConfig?: UserConfig) => boolean;

/**
 * Returns whether acknowledgement policy should be shown or not
 * based on tenant and user configs.
 */
export const useShowAcknowledgementPolicy: UseShowAcknowledgementPolicy = userConfig => {
  const { acknowledgementMessage, acknowledgementUrl, acknowledgementTitle, acknowledgementValue } =
    useConfig();

  const acknowledgementPolicyStoredData = {
    organizationPolicyExpirationTime: userConfig?.acknowledgementConfirmedAt || null,
    organizationPolicyMessage: userConfig?.acknowledgementContentHash || null,
  };

  return showAcknowledgementPolicy({
    acknowledgementPolicyStoredData,
    acknowledgementMessage: hashString(
      JSON.stringify({
        acknowledgementMessage,
        acknowledgementUrl,
        acknowledgementTitle,
      }),
    ),
    acknowledgementValue: acknowledgementValue || ACKNOWLEDGEMENT.NEVER,
  });
};
