import { Overlay } from '@engage-web/components/base/Modal/styled';
import React from 'react';
import Header from '@engage-web/components/scenes/modals/components/Header';
import { useTranslation } from 'react-i18next';
import {
  InstructionTitle,
  Wrapper,
  InstructionsContainer,
  Title,
} from './KeyboardAccessibility.style';

const KeyboardAccessibility = () => {
  const { t } = useTranslation();

  return (
    <Overlay>
      <Header
        hasAgendaButton={false}
        hasUserButton={false}
        hasDateTime={false}
        conciergeMenuAllowed
        isDeskCheckInOn={false}
        isKiosk={false}
      />
      <Wrapper>
        <Title>{t('keyboardAccessibility.floorplanTitle')}</Title>
        <p>{t('keyboardAccessibility.floorplanMessage1')}</p>
        <p>{t('keyboardAccessibility.floorplanMessage2')}</p>

        <InstructionsContainer>
          <InstructionTitle>[ w, up arrow ]</InstructionTitle> -{' '}
          {t('keyboardAccessibility.upArrow')}
          <br />
          <InstructionTitle>[ s, down arrow ]</InstructionTitle> -{' '}
          {t('keyboardAccessibility.downArrow')}
          <br />
          <InstructionTitle>[ a, left arrow ]</InstructionTitle> -{' '}
          {t('keyboardAccessibility.leftArrow')} <br />
          <InstructionTitle>[ d, right arrow ]</InstructionTitle> -{' '}
          {t('keyboardAccessibility.rightArrow')}
          <br />
          <InstructionTitle>[ 0 ]</InstructionTitle> - {t('keyboardAccessibility.zero')}
          <br />
          <InstructionTitle>[ - / _, shift+W, shift+up arrow ]</InstructionTitle> -{' '}
          {t('keyboardAccessibility.zoomOut')} <br />
          <InstructionTitle>[ = / +, shift+S, shift+down arrow ]</InstructionTitle> -{' '}
          {t('keyboardAccessibility.zoomIn')}
          <br />
          <InstructionTitle>[ r ]</InstructionTitle> - {t('keyboardAccessibility.rotateClockwise')}{' '}
          <br />
          <InstructionTitle>[ R ]</InstructionTitle> -{' '}
          {t('keyboardAccessibility.rotateCounterClockwise')}
          <br />
          <InstructionTitle>[ f ]</InstructionTitle> - {t('keyboardAccessibility.flipHorizontally')}{' '}
          <br />
          <InstructionTitle>[ cmd/ctrl hold + drag mouse horizontally ]</InstructionTitle> -{' '}
          {t('keyboardAccessibility.changeDraggingRotation')}
          <br />
        </InstructionsContainer>
      </Wrapper>
    </Overlay>
  );
};

export default KeyboardAccessibility;
