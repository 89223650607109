import { ParsedUserData } from '@engage-shared/api/users/interfaces';

type UserHasGeneralAccess = (params: {
  disableGeneralAccess?: boolean;
  user: ParsedUserData;
}) => boolean;

export const userHasGeneralAccess: UserHasGeneralAccess = ({
  disableGeneralAccess = false,
  user,
}) => (disableGeneralAccess && user ? user.canReserveForOthers : true);
