import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { TextWrapper, SkeletonWrap } from './styled';

const AvailabilityInfoSkeleton = () => (
  <SkeletonWrap data-testid="availability-info-skeleton">
    <Skeleton circle height={26} width={26} />
    <TextWrapper>
      <Skeleton width={226} />
    </TextWrapper>
  </SkeletonWrap>
);

export default AvailabilityInfoSkeleton;
