import { captureSentryError } from '@engage-web/utils/analytics/sentry';

const errorColor = '#b91818';
const warnColor = '#c78113';

const logLevel = __CONF__.logLevel;
const logLevelMap = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
};

const debug = (...args) => {
  if (logLevelMap.debug < logLevelMap[logLevel]) return () => {};
  const params = [console, '[DEBUG]:', ...args];
  return Function.prototype.bind.apply(console.log, params);
};

const info = (...args) => {
  if (logLevelMap.info < logLevelMap[logLevel]) return () => {};
  const params = [console, `[INFO]:`, ...args];
  return Function.prototype.bind.apply(console.info, params);
};

const warn = (...args) => {
  if (logLevelMap.warn < logLevelMap[logLevel]) return () => {};
  if (__DEV__) {
    const params = [console, '%c[WARN]', `color:${warnColor}`, ...args];
    return Function.prototype.bind.apply(console.log, params);
  }
  const params = [console, '[WARN]', ...args];
  return Function.prototype.bind.apply(console.warn, params);
};

const error = (...args) => {
  if (logLevelMap.error < logLevelMap[logLevel]) return () => {};
  if (__DEV__) {
    const params = [console, '%c[ERROR]', `color:${errorColor}`, ...args];
    return Function.prototype.bind.apply(console.log, params);
  }
  const params = [console, '[ERROR]', ...args];
  captureSentryError(args[0]); // always log the fist argument
  return Function.prototype.bind.apply(console.error, params);
};

export default {
  debug,
  info,
  warn,
  error,
};
