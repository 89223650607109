import { useDispatch } from 'react-redux';
import { navigationActions } from '@engage-web/store';

/**
 * A custom hook that returns wrapped setBypassSSO method to simplify
 * using of the method at the component level
 * @returns {{
 * activateBypassSSO: (function(): void),
 * deactivateBypassSSO: (function(): void)}}
 */

export function useBypassSSO() {
  const dispatch = useDispatch();

  const activateBypassSSO = () => dispatch(navigationActions.setBypassSSO(true));

  const deactivateBypassSSO = () => dispatch(navigationActions.setBypassSSO(false));

  return {
    activateBypassSSO,
    deactivateBypassSSO,
  };
}
