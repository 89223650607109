import { Api } from '../Api';
import { ZoomDescriptor } from '../types';

type FetchZoomDescriptor = (params: { floorId: number }) => Promise<ZoomDescriptor> | undefined;

export const fetchZoomDescriptor: FetchZoomDescriptor = ({ floorId }) => {
  return Api.fetch({
    method: 'GET',
    url: `/engage_api/v1/floors/${floorId}/zoom_descriptor`,
  });
};
