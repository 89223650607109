import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDayStartTime,
  getUtcIsoString,
  mapLocationBuildingData,
  mapLocationFloorData,
} from '@engage-shared/utils';
import logger from '../../utils/logger';
import { addDays, addWeeks, isAfter, isBefore, isToday, subWeeks } from 'date-fns';
import { useFetchEvents, useFetchUser, useSpaceFetch } from '../../api/queries';
import { useConfig } from '../../utils/hooks/useConfig';
import { tenantActions, tenantSelectors } from '../../store';
import { Building } from '@engage-shared/api/buildings';

type GetReservationsForTimeframe = (args: {
  events: any[];
  startDate: Date;
  endDate: Date;
}) => any[];

const getReservationsForTimeframe: GetReservationsForTimeframe = ({ events, startDate, endDate }) =>
  events.filter(event => {
    const { start } = event;
    const _start = getDayStartTime({ date: new Date(start) });
    return isAfter(_start, startDate) && isBefore(_start, endDate);
  });

type GetTodayReservations = (args: { events: any[] }) => any[];

const getTodayReservations: GetTodayReservations = ({ events }) =>
  events.filter(event => {
    const { start } = event;
    const _start = getDayStartTime({ date: new Date(start) });
    return isToday(_start);
  });

export function useSelectFloor() {
  const dispatch = useDispatch();

  const userId = useSelector(tenantSelectors.getUserId);

  const now = getDayStartTime({ date: new Date() });
  const { futureBookingsLimit } = useConfig();
  const eventsStartTimeFilter = subWeeks(now, futureBookingsLimit);
  const eventsEndTimeFilter = addWeeks(now, futureBookingsLimit);

  const { fetchUserQuery } = useFetchUser();
  const { fetchSpaceQuery } = useSpaceFetch();
  const { fetchEventsQuery } = useFetchEvents();

  const getEventLocation = useCallback(
    async (event: { spaceId: number; building: Building }) => {
      const { spaceId, building } = event;

      try {
        const { floorId, floorName } = await fetchSpaceQuery({ id: spaceId });
        return {
          floor: floorId ? mapLocationFloorData({ id: floorId, name: floorName }) : null,
          building: building ? mapLocationBuildingData(building) : null,
        };
      } catch (e) {
        logger.error(e)();
        return null;
      }
    },
    [fetchSpaceQuery],
  );

  return useCallback(async () => {
    try {
      const user = await fetchUserQuery({ userId });
      const events = await fetchEventsQuery(
        userId,
        getUtcIsoString(eventsStartTimeFilter),
        getUtcIsoString(eventsEndTimeFilter),
        user?.personId,
      );

      if (events?.length && user) {
        const todayReservations = getTodayReservations({ events });
        const next7DaysReservations = getReservationsForTimeframe({
          events,
          startDate: now,
          endDate: addDays(now, 7),
        });
        const nextFutureBookingsLimitDaysReservations = getReservationsForTimeframe({
          events,
          startDate: now,
          endDate: addWeeks(now, futureBookingsLimit),
        });
        const pastFutureBookingsLimitDaysReservations = getReservationsForTimeframe({
          events,
          startDate: subWeeks(now, futureBookingsLimit),
          endDate: now,
        });

        const hasReservationToday = !!todayReservations.length;
        const hasReservationWithinNext7Days = !!next7DaysReservations.length;
        const hasReservationWithinNext30Days = !!nextFutureBookingsLimitDaysReservations.length;
        const hasReservationWithinPast30Days = !!pastFutureBookingsLimitDaysReservations.length;

        const hasAssignedLocation = !!user?.location?.length;
        let eventLocation;

        if (hasReservationToday) {
          eventLocation = await getEventLocation(todayReservations[0]);
        } else if (hasReservationWithinNext7Days) {
          eventLocation = await getEventLocation(next7DaysReservations[0]);
        } else if (hasAssignedLocation) {
          eventLocation = user.userLocation;
        } else if (hasReservationWithinNext30Days) {
          eventLocation = await getEventLocation(nextFutureBookingsLimitDaysReservations[0]);
        } else if (hasReservationWithinPast30Days) {
          eventLocation = await getEventLocation(
            pastFutureBookingsLimitDaysReservations[
              pastFutureBookingsLimitDaysReservations.length - 1
            ],
          );
        }

        if (eventLocation) {
          dispatch(tenantActions.setCurrentLocation(eventLocation));
        }
      }
    } catch (e) {
      logger.error(e)();
      logger.error('Error setting location in ["useSelectFloor"]')();
      return;
    }
  }, [getEventLocation, now, futureBookingsLimit, dispatch, fetchUserQuery]);
}
