import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetchFloorLocation } from '../fetch';
import { FloorData, FloorLocation } from '../interfaces';
import { FLOOR_LOCATION_KEY } from '../constants';
import { useBuildingFloorsFetch } from '@engage-shared/api/buildings';
import { Nullable } from '@engage-shared/utils/types';

const STALE_TIME = 30 * 60 * 1000;
const queryOptions = {
  staleTime: STALE_TIME,
};

type UseFloorLocationQueryParams = {
  floorId: number;
  options?: UseQueryOptions;
};

type UseFloorLocationQuery = (params: UseFloorLocationQueryParams) => UseQueryResult;

export const useFloorLocation: UseFloorLocationQuery = ({ floorId, options = {} }) => {
  return useQuery({
    queryKey: [FLOOR_LOCATION_KEY, floorId],
    queryFn: () => fetchFloorLocation(floorId),
    enabled: !!floorId,
    ...queryOptions,
    ...options,
  });
};

type UseFloorLocationFetch = () => {
  fetchFloorLocationQuery: ({ id }: { id: number }) => Promise<FloorLocation[]>;
};

export const useFloorLocationFetch: UseFloorLocationFetch = () => {
  const queryClient = useQueryClient();

  const query = ({ id }: { id: number }): Promise<FloorLocation[]> =>
    queryClient.fetchQuery([FLOOR_LOCATION_KEY, id], () => fetchFloorLocation(id), {
      ...queryOptions,
    });

  const fetchFloorLocationQuery = useCallback(query, [queryClient]);

  return { fetchFloorLocationQuery };
};

type FetchFloor = (params: { buildingId: number; floorId: number }) => Promise<Nullable<FloorData>>;

type UseFloorFetch = () => {
  fetchFloor: FetchFloor;
};
export const useFloorFetch: UseFloorFetch = () => {
  const { fetchBuildingFloorsQuery } = useBuildingFloorsFetch();

  /**
   * Fetch building's floors and filter them to get the required one by floorId.
   */
  const fetchFloor: FetchFloor = async ({ buildingId, floorId }) => {
    const floors = await fetchBuildingFloorsQuery({ id: buildingId });
    return floors?.find(floor => floor.id === floorId) ?? null;
  };

  return { fetchFloor };
};
