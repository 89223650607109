import React, { memo, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { testProps } from '@engage-web/utils';
import { Nullable } from '@engage-shared/utils/types';
import {
  AvatarImageSize,
  AvatarImageVariant,
  AvatarStyle,
} from '@engage-web/components/base/Avatar/Avatar.types';
import { EngageIcons, Icon } from '../Icons/Icon';
import { ImageWrapper, ImageButton } from './styled';
import Initials from './Initials';
import { getImageStyle } from './utils';
import { textSize } from './config';
import { Theme } from '@engage-shared/theme';
import {
  getCountryColorByInitials,
  getCountryColorByNumber,
  getInitials,
} from '@engage-shared/utils';

const ICON_SIZE_FACTOR = 2;
const emptyObject = {};

export type AvatarImageProps = {
  source?: Nullable<string>;
  icon?: Nullable<EngageIcons | (() => ReactElement)>;
  iconStyle?: Nullable<AvatarStyle>;
  name?: string;
  initials?: string;
  country?: string;
  variant?: AvatarImageVariant;
  size?: AvatarImageSize;
  style?: Nullable<AvatarStyle>;
  errorStyle?: Nullable<AvatarStyle>;
  onImageClick?: () => void;
  id?: number;
  className?: string;
};

const AvatarImage = (props: AvatarImageProps) => {
  const {
    source,
    icon,
    iconStyle = emptyObject,
    name,
    initials,
    country,
    variant = 'circle',
    size = 'medium',
    style = emptyObject,
    errorStyle = emptyObject,
    onImageClick,
    id = 0,
    className = '',
  } = props;
  const [imageError, setImageError] = useState(false);
  const { t } = useTranslation();
  const { color3 } = useTheme() as Theme;
  const componentStyle = getImageStyle(variant, size, style, color3, imageError ? errorStyle : {});
  const imageWrapperStyle = {
    width: componentStyle.width,
    height: componentStyle.height,
  };
  const { height, backgroundColor } = componentStyle;
  const iconSize = height / ICON_SIZE_FACTOR;

  const placeholder = () => (
    <ImageWrapper
      className={className}
      key="placeholder"
      style={componentStyle}
      data-testid="avatarPlaceholder"
    >
      <Icon name="person" size={iconSize} style={{ color: color3 }} />
    </ImageWrapper>
  );

  const renderIcon = () => {
    let el;
    const _iconStyle = {
      color: backgroundColor,
      ...iconStyle,
    };

    const wrapperStyles = { ...componentStyle };

    if (id) {
      const { backgroundColor: countryBackgroundColor, textColor } = getCountryColorByNumber(
        id,
        '',
      );
      _iconStyle.color = textColor;
      wrapperStyles.backgroundColor = countryBackgroundColor;
    }

    if (typeof icon === 'function') {
      const iconElement = icon();
      el = React.cloneElement(iconElement, {
        size: iconSize,
        style: {
          ...iconElement.props.style,
          ..._iconStyle,
        },
      });
    } else if (icon) {
      el = <Icon name={icon} size={iconSize} style={_iconStyle} />;
    }

    return (
      <ImageWrapper
        key="icon"
        style={wrapperStyles}
        data-testid="avatarIcon"
        className={`${className} avatar__icon`}
      >
        {el}
      </ImageWrapper>
    );
  };
  // Old variant of renderInitials, I will leave it here in case someone will need it
  // or backend will not send us 'initials' with person
  const renderInitialsFromName = () => {
    const profanities = t('profanities', { returnObjects: true });
    const initials = getInitials(name || '');
    const { backgroundColor, textColor } = getCountryColorByInitials(initials, country || '');

    return (
      <Initials
        key="initials"
        style={{ ...componentStyle, background: backgroundColor }}
        textColor={textColor}
        textSize={textSize[size] - 3}
        className={className}
      >
        {profanities.indexOf(initials) === -1 ? initials : initials[0]}
      </Initials>
    );
  };

  const renderInitials = () => {
    const { backgroundColor, textColor } = getCountryColorByInitials(initials || '', country || '');

    return (
      <Initials
        key="initials"
        style={{ ...componentStyle, background: backgroundColor }}
        textColor={textColor}
        textSize={textSize[size] - 3}
        className={className}
      >
        {initials || ''}
      </Initials>
    );
  };

  const renderImage = () => {
    if (!imageError) {
      const imageElement = (
        <img
          className="avatar__image"
          key="image"
          alt="avatar" // TODO: think of a better alt text
          src={source || undefined}
          onError={() => setImageError(true)}
          style={{ ...componentStyle, pointerEvents: 'none' }} // disables ability to drag image using mouse
          data-testid="avatarImage"
        />
      );

      if (onImageClick) {
        return (
          <ImageButton
            {...testProps(t, 'accessibilityLabels.viewImage').props}
            onClick={onImageClick}
            style={imageWrapperStyle}
            key="image_button"
            className={className}
          >
            {imageElement}
          </ImageButton>
        );
      }

      return imageElement;
    }

    return [
      icon && renderIcon(),
      !icon && name && renderInitialsFromName(),
      !icon && !name && !initials && placeholder(),
    ];
  };

  // priority: source > icon > initials > name > placeholder
  const renderComponent = () => [
    source && renderImage(),
    !source && icon && renderIcon(),
    !source && !icon && initials && renderInitials(),
    !source && !icon && !initials && name && renderInitialsFromName(),
    !source && !icon && !initials && !name && placeholder(),
  ];

  return renderComponent();
};

// @ts-ignore
export default memo(AvatarImage);
