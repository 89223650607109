import styled from 'styled-components';

import {
  ListItemOuterWrapper,
  ListItemWrapper,
} from '@engage-web/components/layouts/GlobalSearch/styled';

export const ContentWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overlflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ItemWrapper = styled(ListItemOuterWrapper)`
  cursor: default;
`;

export const TextWrapper = styled(ListItemWrapper)``;
