import styled from 'styled-components';

export const LiOption = styled.li<{
  css: any;
}>`
  ${({ css }) => css}
`;

export const UlMenuList = styled.ul<{ css: any }>`
  border-radius: 6px;
  padding-left: 0;
  margin: 0;
  ${({ css }) => css}
`;

export const Wrapper = styled.span`
  width: 100%;

  &.keyDown:focus-within {
    div[class*='control'] {
      outline: 1px auto Highlight;
      outline: 1px auto -webkit-focus-ring-color;
    }
    outline: 0;
    box-shadow: none;
  }
`;
