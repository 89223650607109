import React, { useMemo } from 'react';
import ReactFocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Wrapper, Title, CommonMessage, PrimaryButton, SecondaryButton } from './styled';

const SpaceNamesMessage = styled(CommonMessage)`
  line-height: 42px;
  font-weight: 700;
`;

const Message = styled(CommonMessage)`
  line-height: 42px;
  margin-bottom: 28px;
  margin-left: 4px;
  margin-right: 4px;
`;

type KioskReservationSuccessDialogProps = {
  spaceNames: string[];
  onOkPress: () => void;
  onUndoPress?: () => void;
};

const KioskMakeReservationDialog = ({
  spaceNames,
  onOkPress,
  onUndoPress,
}: KioskReservationSuccessDialogProps) => {
  const { t } = useTranslation();
  const displayName = useMemo(() => spaceNames.join(', '), [spaceNames]);

  return (
    <ReactFocusLock>
      <Wrapper>
        <Title>{t('components.reservationSuccessDialog.title')}</Title>
        <Message>
          <Trans count={spaceNames.length} i18nKey="layout.reserve.kioskReservationSuccess">
            <SpaceNamesMessage>{displayName}</SpaceNamesMessage>
          </Trans>
        </Message>
        <PrimaryButton onClick={onOkPress}>{t('common.ok')}</PrimaryButton>
        <SecondaryButton onClick={onUndoPress}>{t('common.undo')}</SecondaryButton>
      </Wrapper>
    </ReactFocusLock>
  );
};

export default KioskMakeReservationDialog;
