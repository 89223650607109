import { Api, getApiUrl } from '@engage-shared/api/instance';
import { getSpaceTypeQueryString } from '@engage-shared/api/utils';
import { LanguageTypes } from '@engage-shared/constants/languages';
import {
  FetchSpaceTypeAttributesParams,
  SpaceAttributesData,
} from '@engage-shared/api/spaces/interfaces';
import { sortAttributesBySequenceAndId } from '@engage-shared/api/spaces/utils';

type FetchAllSpaceAttributes = (
  params: FetchSpaceTypeAttributesParams,
) => Promise<SpaceAttributesData>;

/**
 * Retrieves all space attributes.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | space attributes found |
 *
 * @param {Object} options
 * @param {'Desk' | 'Space'} options.spaceType while query expects one of {'Desk', 'Room'} but getSpaceTypeQueryString solves it
 * @param {string} options.locale
 */
export const fetchAllSpaceAttributes: FetchAllSpaceAttributes = async ({ spaceType, locale }) => {
  const queryString = getSpaceTypeQueryString(spaceType);
  const op = queryString ? `?` : '';
  const path = `space_attributes${op}${queryString}`;
  const url = new URL(`${getApiUrl()}/${path}`);
  url.searchParams.set('locale', locale || LanguageTypes.enUS);
  const response = await Api.get(url.toString());
  const attributes = response.data?.data;
  if (attributes) {
    attributes.sort(sortAttributesBySequenceAndId);
  }
  return attributes;
};
