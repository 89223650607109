// TODO export constants, used in Shape compoennts
import OpenSeadragon, { ViewerEventMap } from 'openseadragon';

const DEFAULT_FLOORPLAN_SCALE = 0.015;

interface ViewerConfig {
  floorId: number;
  viewer: OpenSeadragon.Viewer;
  scale?: number;
  rotation?: number;
}

export class Viewer {
  private readonly floorId: number;
  private readonly viewer: OpenSeadragon.Viewer;
  private scale: number;
  private rotation: number;

  constructor(config: ViewerConfig) {
    this.floorId = config.floorId;
    this.viewer = config.viewer;
    this.scale = config.scale || DEFAULT_FLOORPLAN_SCALE;
    this.rotation = config.rotation || 0;
  }

  getFloorId(): number {
    return this.floorId;
  }

  getViewer(): OpenSeadragon.Viewer {
    return this.viewer;
  }

  setScale(scale: number): void {
    this.scale = scale;
  }

  getScale(): number {
    return this.scale;
  }

  setRotation(rotation: number): void {
    this.rotation = rotation;
  }

  getRotation(): number {
    return this.rotation;
  }

  addHandler(event: keyof ViewerEventMap, handler: any) {
    this.viewer.addHandler(event, handler);
  }

  removeHandler(event: keyof ViewerEventMap, handler: any) {
    this.viewer.removeHandler(event, handler);
  }
}
