export interface Logger {
  log: LogFunction;
  warn: LogFunction;
  error: LogFunction;
}

export type LogFunction = (...args: any[]) => void;

export enum MobileLoggerTypes {
  LOG,
  WARN,
  ERROR,
}

export const defaultLogger: Logger = console;
