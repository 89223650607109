import { ToggleSwitch } from '@engage-web/components/base/ToggleSwitch';
import React, { CSSProperties, memo } from 'react';
import ItemSkeleton from './ItemSkeleton';
import { ListItem, ListItemText } from './styled';

export interface ItemDataItem {
  name: string;
  id: number;
  sequence: number;
}

interface ItemProps {
  index: number;
  style: CSSProperties;
  data: {
    items: ItemDataItem[];
    selected: number[];
    onToggle: ({ id, checked }: { id: number; checked: boolean }) => void;
  };
}

export const Item = memo(({ index, style, data }: ItemProps) => {
  const { items, selected, onToggle } = data;
  const item = items?.[index];
  const name = item?.name;

  if (!item) {
    return <ItemSkeleton index={index} style={style} />;
  }

  return (
    <ListItem key={index} style={style} title={name} data-testid="filter-item">
      <ListItemText htmlFor={name}>{name}</ListItemText>

      <ToggleSwitch
        onChange={checked => onToggle({ id: item.id, checked })}
        checked={selected.includes(item.id)}
        ariaLabel={name}
        id={name}
      />
    </ListItem>
  );
});
