import { useEffect } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';

export const useShowFocusedSpace = (focusedSpaceId: number | number[] | null) => {
  const fes = useFESContext();

  useEffect(() => {
    if (focusedSpaceId) {
      fes.trigger(FES_EVENTS.SHOW_SPACE, {
        id: focusedSpaceId,
        zoom: true,
        select: true,
      });
    }
  }, [fes, focusedSpaceId]);
};
