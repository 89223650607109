import { TFunction } from 'react-i18next';
import { LanguageTypes } from '@engage-shared/constants';

type TPropsReturnValue = {
  text: string[];
  props: {
    'aria-label': string;
    'data-testid': string;
  };
};

export const testProps = (
  t: TFunction,
  keys: string | string[],
  cb?: (args: string[]) => string,
  // normally this function either return an object or throw
  // but because throw statements are wrapped in __DEV__ flag check
  // eslint and TS complain about lack of return statement
  // @ts-ignore
  // eslint-disable-next-line consistent-return
): TPropsReturnValue => {
  if (typeof keys === 'string' && !!keys) {
    const translated = t(keys);

    return {
      text: [translated],
      props: {
        'aria-label': cb ? cb([translated]) : translated,
        'data-testid': cb
          ? cb([t(keys, { lng: LanguageTypes.en })])
          : t(keys, { lng: LanguageTypes.en }),
      },
    };
  }
  if (Array.isArray(keys)) {
    if (!cb && __DEV__) throw new Error('cb must be provided when keys is an array');

    const nonEmptyKeys = keys.filter(key => !!key);
    const translated = nonEmptyKeys.map(t) as string[];

    return {
      text: translated,
      props: {
        'aria-label': cb?.(translated) || '',
        'data-testid': cb?.(nonEmptyKeys.map(key => t(key, { lng: LanguageTypes.en }))) || '',
      },
    };
  }

  if (__DEV__) {
    throw new Error('keys should be string or an array of strings');
  }
};
