import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  floorplanMiddleware,
  reducer as floorplanComponent,
} from '@engage-web/components/floorplan/store';
import { spaceActions as floorplanComponentActions } from '@engage-web/components/floorplan/store/actions';
import globalSearch, { globalSearchSelectors } from '@engage-web/store/globalSearch';
import teamMembersSearch, { teamMembersSearchSelectors } from '@engage-web/store/teamMembersSearch';
import userConfig, { userConfigSelectors } from '@engage-web/store/userConfig';
import teamReservations, { teamReservationsSelectors } from '@engage-web/store/teamReservations';
import { ui, uiSelectors } from '@engage-web/store/ui';
import { sessionTimeout, sessionTimeoutSelectors } from '@engage-web/store/sessionTimeout';
import filters, { filtersSelectors } from './filters';
import { analyticsMiddleware, timeZoneMiddleware, userConfigMiddleware } from './middlewares';
import tenant, { tenantSelectors } from './tenant';
import floorplan, { floorplanSelectors } from './floorplan';
import inAppNotifications, { inAppNotificationsSelectors } from './inAppNotifications';
import navigation, { navigationSelectors } from './navigation';
import notifications, { notificationsSelectors } from './notifications';

const tenantPersistConfig = {
  key: 'engage_tenant',
  storage,
};

const navigationPersistConfig = {
  key: 'engage_navigation',
  storage,
  whitelist: ['deepLinkQueryString', 'deepLinkPath'],
};

const notificationsPersistConfig = {
  key: 'engage_notifications',
  storage,
};

const userConfigPersistConfig = {
  key: 'engage_user_config',
  storage,
};

const sessionTimeoutPersistConfig = {
  key: 'engage_session_timeout_config',
  storage,
  blacklist: ['affectedTenants'],
};

const floorplanPersistConfig = {
  key: 'engage_floorplan',
  storage,
  whitelist: ['showSpaceAvailabilityMessage'],
};

const uiReducerPersistConfig = {
  key: 'ui_state',
  storage,
  whitelist: ['appAccessDialogVisible'],
};

const reducer = combineReducers({
  tenant: persistReducer(tenantPersistConfig, tenant.reducer),
  filters: filters.reducer,
  globalSearch: globalSearch.reducer,
  teamMembersSearch: teamMembersSearch.reducer,
  floorplan: persistReducer(floorplanPersistConfig, floorplan.reducer),
  floorplanComponent,
  inAppNotifications: inAppNotifications.reducer,
  navigation: persistReducer(navigationPersistConfig, navigation.reducer),
  notifications: persistReducer(notificationsPersistConfig, notifications.reducer),
  userConfig: persistReducer(userConfigPersistConfig, userConfig.reducer),
  teamReservations: teamReservations.reducer,
  ui: persistReducer(uiReducerPersistConfig, ui.reducer),
  sessionTimeout: persistReducer(sessionTimeoutPersistConfig, sessionTimeout.reducer),
});

const store = configureStore({
  reducer,
  middleware: [
    analyticsMiddleware,
    timeZoneMiddleware,
    floorplanMiddleware,
    userConfigMiddleware,
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          ...Object.values(floorplanComponentActions),
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          filters.actions.setFilterValues.type,
        ],
        ignoredPaths: ['filters.filterValues.dateStart', 'filters.filterValues.dateEnd'],
      },
    }),
  ],
  devTools: process.env.NODE_ENV !== 'production',
});

const tenantActions = tenant.actions;
const filtersActions = filters.actions;
const globalSearchActions = globalSearch.actions;
const teamMembersSearchActions = teamMembersSearch.actions;
const floorplanActions = floorplan.actions;
const inAppNotificationsActions = inAppNotifications.actions;
const navigationActions = navigation.actions;
const notificationsActions = notifications.actions;
const userConfigActions = userConfig.actions;
const teamReservationsActions = teamReservations.actions;
const uiActions = ui.actions;
const sessionTimeoutActions = sessionTimeout.actions;

export {
  tenantActions,
  tenantSelectors,
  store,
  filtersSelectors,
  globalSearchActions,
  globalSearchSelectors,
  teamMembersSearchActions,
  teamMembersSearchSelectors,
  filtersActions,
  floorplanActions,
  floorplanSelectors,
  inAppNotificationsActions,
  teamReservationsActions,
  navigationActions,
  navigationSelectors,
  notificationsActions,
  notificationsSelectors,
  userConfigActions,
  userConfigSelectors,
  inAppNotificationsSelectors,
  teamReservationsSelectors,
  uiActions,
  uiSelectors,
  sessionTimeoutActions,
  sessionTimeoutSelectors,
};

export const persistor = persistStore(store);
export default store;
