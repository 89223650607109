import { API_VERSIONS_TYPE, APP_NAMES_TYPE } from './types';

// Global timeout applied to all api requests
export const API_TIMEOUT = 60000;

export const API_VERSIONS: API_VERSIONS_TYPE = {
  V1: 'v1',
  V2: 'v2',
};

export const APP_NAMES: APP_NAMES_TYPE = {
  ENGAGE_WEB: 'engage-web',
  ENGAGE_MOBILE: 'engage-mobile',
};

export enum ErrorType {
  UNAUTHORIZED = 'notAuthorized',
  FORBIDDEN = 'forbidden',
  NOT_FOUND = 'notFound',
  UNPROCESSABLE_ENTITY = 'unprocessableEntity',
  NOT_IMPLEMENTED = 'notImplemented',
  INTERNAL_SERVER_ERROR = 'serverError',
  CONNECTION_ABORTED = 'econnAborted',
}
