import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions, tenantSelectors } from '@engage-web/store';
import { getUserFloorLocation } from '@engage-shared/utils';
import { isElectron } from '@engage-web/utils/electron';

/**
 * If location changed sets new location else return the current location
 */
export const useFloorLocation = () => {
  const currentLocation = useSelector(tenantSelectors.getCurrentLocation);
  const isKiosk = isElectron();
  const dispatch = useDispatch();

  // Only update the floor if the floorId is different than the current floor
  const setFloorLocation = React.useCallback(
    async (floorId: number) => {
      let floorLocation = currentLocation;
      // Only set the current floor if the current floor id does not equal the new floorId
      if (!isKiosk && floorId !== currentLocation?.floor?.id) {
        floorLocation = await getUserFloorLocation(floorId);

        if (floorLocation) {
          dispatch(tenantActions.setCurrentLocation(floorLocation));
        }
      }
      return floorLocation;
    },
    [currentLocation, dispatch],
  );

  return { setFloorLocation };
};
