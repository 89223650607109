import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RestrictToSearchFilterWrapper = styled.div<{
  $hidePeopleData: boolean;
}>`
  display: flex;
  justify-content: ${({ $hidePeopleData }) => ($hidePeopleData ? 'space-around' : 'space-between')};
  flex-shrink: 0;
  padding: 16px 40px 8px 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color4};
`;

export const RestrictToItem = styled(Link)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  opacity: 0.7;
  color: ${({ theme }) => theme.typo2};
  text-decoration: none;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }

  &.active {
    color: #18224c;
    opacity: 1;

    &::before {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 8px;
      background-color: ${({ theme }) => theme.color9};
      top: 56px;
    }
  }
`;

export const RestrictToItemText = styled.span<{
  $useSmallFont: boolean;
}>`
  margin-top: 8px;
  font-size: ${({ $useSmallFont }) => ($useSmallFont ? '11px' : '14px')};
  font-weight: 600;
  text-align: center;
`;
