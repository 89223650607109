import styled from 'styled-components';
import { Z_INDEX } from '@engage-web/constants';

type SharedProps = {
  $isOpen: boolean;
  $isActive: boolean;
  $disableInteractions: boolean;
};

export const DateButtonWrapper = styled.button<
  Pick<SharedProps, '$isOpen' | '$disableInteractions'>
>`
  display: flex;
  border-radius: 25px;
  background-color: ${({ $isOpen }) => ($isOpen ? 'transparent' : '#fff')};
  padding: 2px 2px 2px 7px;
  align-items: center;
  font-weight: 700;
  position: relative;
  color: ${({ $isOpen, theme }) => ($isOpen ? '#fff' : theme.typo2)};
  z-index: ${({ $isOpen }) => ($isOpen ? Z_INDEX.MODALS : 0)};
  pointer-events: ${({ $disableInteractions }) => ($disableInteractions ? 'none' : 'unset')};
  cursor: pointer;

  :focus-within {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }

  @media (max-width: 355px) {
    padding-left: 2px;
  }
`;

export const DateButtonIconWrapper = styled.span`
  position: relative;
  line-height: 0;
  top: 1px;
  user-select: none;

  @media (max-width: 355px) {
    display: none;
  }
`;

export const DateButtonDateTextWrapper = styled.span<Pick<SharedProps, '$isActive'>>`
  border-radius: 25px;
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.color9 : theme.typo2)};
  padding: 6px 10px;
  color: #fff;
  margin-left: 10px;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  & .date-button__text {
    margin-right: 20px;
    font-weight: 700;
  }
  @media (max-width: 355px) {
    margin-left: 0;
    padding: 6px 8px;
    & .date-button__text {
      margin-right: 12px;
    }
  }
`;

export const DateButtonDateClipWrapper = styled.button`
  border-radius: 50%;
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const DateButtonDateClip = styled.div<Pick<SharedProps, '$isOpen' | '$isActive'>>`
  width: 6px;
  border-radius: 50px;
  background-color: #fff;
  transition: height 0.1s;
  height: ${({ $isOpen }) => ($isOpen ? '12px' : '6px')};
  position: relative;
  left: ${({ $isActive }) => ($isActive ? '-2px' : '-20px')};
  z-index: ${Z_INDEX.MODALS};
`;
