import styled from 'styled-components';

interface TextWrapperProps {
  $isReserved?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
`;

export const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: ${props => (props.$isReserved ? '0px' : '20px')};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

export const CapacityWrapper = styled.div`
  margin-left: 8px;
`;

export const StyledDescription = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.color2};
  opacity: 0.8;
`;

export const StyledTitle = styled.h3`
  font-size: 20px;
  margin-top: 3px;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.color1};
`;
