// @ts-ignore
import HeatmapAPI from 'heatmap.js/build/heatmap';
import { Viewer } from 'openseadragon';
import { HeatMapPoint } from '../../types';

const defaultConfig = {
  htmlId: 'heatmap-container',
  backgroundColor: 'rgba(0,0,0,0)',
  // the maximum opacity (the value with the highest intensity will have it)
  maxOpacity: 0.5,
  // minimum opacity. any value > 0 will produce no transparent gradient transition
  minOpacity: 0.05,
  useLocalExtrema: false,
};

export default class HeatmapOverlay {
  viewer: Viewer | undefined;
  cfg: any;
  container: HTMLDivElement | undefined;
  width = 0;
  height = 0;
  data: HeatMapPoint[] = [];
  max = 1;
  heatmap: any;

  constructor(viewer: Viewer | undefined, cfg: any = null) {
    if (!viewer) {
      console.error('Viewer is missing or not ready');
      return;
    }
    this.viewer = viewer;
    this.cfg = { ...defaultConfig, ...cfg };
    this.initialize(this.cfg);
  }

  initialize = (cfg: any) => {
    this.cfg = cfg;

    this.container = document.createElement('div');
    this.width = this.viewer?.container.clientWidth ?? 0;
    this.height = this.viewer?.container.clientHeight ?? 0;

    this.container.setAttribute('id', this.cfg.htmlId);
    this.container.style.cssText = `width:${this.width}px;height:${this.height}px;`;

    this.cfg.container = this.container;

    this.onAdd();
  };

  destroy = (htmlId: string = '') => {
    const overlay = document.getElementById(htmlId || this.cfg.htmlId);
    overlay?.remove();
  };

  setData = ({ data, max }: { data: HeatMapPoint[]; max: number }) => {
    this.max = max;

    // transform data to latlngs
    let len = data.length;
    const d = [];

    while (len--) {
      const entry = data[len];
      const dataObj = {} as any;
      dataObj.value = entry.value;
      dataObj.x = entry.x;
      dataObj.y = entry.y;
      if (entry.radius) {
        dataObj.radius = entry.radius;
      }
      d.push(dataObj);
    }
    this.data = d;
    this.update();
  };

  update = () => {
    const zoom = this.viewer?.viewport.getZoom(true) ?? 1;

    if (this.data.length === 0) {
      return;
    }

    const generatedData = { max: this.max, data: [] } as {
      max: number;
      data: HeatMapPoint[];
    };
    // iterate through data
    let len = this.data.length;
    let localMax = 0;

    while (len--) {
      const entry = this.data[len];
      const value = entry.value;

      if (value > localMax) {
        localMax = value;
      }

      const viewportPoint = this.viewer?.viewport.imageToViewportCoordinates(entry.x, entry.y);

      if (!viewportPoint) return;

      const imagePoint = this.viewer?.viewport.pixelFromPoint(viewportPoint, true) ?? {
        x: 0,
        y: 0,
      };

      const viewerContainerX = this.viewer?.viewport.getContainerSize().x ?? 0;
      const viewerContainerY = this.viewer?.viewport.getContainerSize().y ?? 0;

      // ignore outer point
      if (
        imagePoint.x <= 0 ||
        imagePoint.y <= 0 ||
        imagePoint.x >= viewerContainerX ||
        imagePoint.y >= viewerContainerY
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const point: HeatMapPoint = {
        x: Math.round(imagePoint.x),
        y: Math.round(imagePoint.y),
        value,
        radius: 0,
      };

      if (entry.radius) {
        point.radius = entry.radius * zoom;
      } else {
        point.radius = (this.cfg.radius || 20) * zoom;
      }
      generatedData.data.push(point);
    }

    if (this.cfg.useLocalExtrema) {
      generatedData.max = localMax;
    }

    this.heatmap.setData(generatedData);
  };

  onAdd = () => {
    this.viewer?.canvas.appendChild(this.container!);

    this.viewer?.addHandler(
      'update-viewport',
      arg => {
        // @ts-ignore
        arg.userData.draw.call(arg.userData);
      },
      this,
    );

    if (!this.heatmap) {
      this.heatmap = HeatmapAPI.create(this.cfg);
    }
    this.draw();
  };

  draw = () => {
    if (!this.viewer) {
      return;
    }

    this.update();
  };
}
