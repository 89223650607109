import { createContext, Dispatch, RefObject, SetStateAction } from 'react';
import { BookingsListResponse, OccupantData } from '@engage-shared/api/bookings';
import { DurationObj, SpaceItemWithImage, SpaceStatusInfoResult } from './types';
import { BaseSVLivePresence, ParsedTeam, UserData } from '@engage-shared/api';

const noop = () => {};

export const ReserveCardContext = createContext({
  item: {} as ParsedTeam,
  spaceItem: {} as SpaceItemWithImage,
  user: {} as UserData,
  reservee: {} as UserData,
  hasOccupant: false,
  isTeam: false,
  reserveDisabled: false,
  setReserveDisabled: noop as Dispatch<SetStateAction<boolean>>,
  reserveButtonRef: null as RefObject<HTMLButtonElement> | null,
  durationObj: {
    duration: 0,
    allDayDuration: false,
    selectedDays: [],
  } as DurationObj,
  setDurationObj: noop as Dispatch<SetStateAction<DurationObj>>,
  spaceStatusInfo: {} as SpaceStatusInfoResult,
  timeZone: '',
  bookings: [] as BookingsListResponse[] | undefined,
  bookedIntervals: [] as number[] | undefined,
  bookingsAllowed: true as boolean,
  bookingDeniedMessage: '' as string | undefined,
  isOccupied: false,
  occupant: {} as OccupantData,
  svLiveOccupant: {} as BaseSVLivePresence,
});
