import * as React from 'react';

import { Space } from '@floorplan/api';
import { Shape } from '../../../components';
import { ThemeManager } from '../../../theme';
import { ElementsProps } from '../../../types';
import { getKey } from '../utils';
import { pointSizes } from '../styles';
import { useTeamReservationSelectionState } from '../../../hooks';

type TeamReservationProps = ElementsProps & {
  spaces?: Space[];
};

export const TeamReservation = ({ scale, spaces }: TeamReservationProps) => {
  const { selectedDesks } = useTeamReservationSelectionState();
  const teamReservationSelectedSpaces = spaces?.filter(space => selectedDesks.includes(space.id));

  const getPointSize = () => pointSizes('important') * 1;

  return (
    <>
      {teamReservationSelectedSpaces?.map((space, index) => (
        <Shape
          space={space}
          shape={space.shapes[0]}
          scale={scale}
          isPoint
          getPointSize={getPointSize}
          style={ThemeManager.getStyle('teamReservationsSelected')}
          key={getKey(space.id, index)}
        />
      ))}
    </>
  );
};
