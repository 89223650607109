import React, { memo, useEffect, useState } from 'react';
import { FocusId, useFocus } from '@engage-web/utils/hooks';
import { DurationInput } from './DurationInput';
import { DurationSelectorContainer, Label } from './styled';

const DEFAULT_HOURS_FOR_OTHER = 1;
const DEFAULT_MINUTES_FOR_OTHER = 30;

type DurationSelectorProps = {
  onChange: (duration: number) => void;
};

const DurationSelector = memo(({ onChange }: DurationSelectorProps) => {
  const [hours, setHours] = useState(DEFAULT_HOURS_FOR_OTHER);
  const [minutes, setMinutes] = useState(DEFAULT_MINUTES_FOR_OTHER);

  useEffect(() => {
    onChange(hours * 60 + minutes);
  }, [hours, minutes, onChange]);

  useFocus({
    focusOn: FocusId.DurationSelectorHourUpButton,
  });

  return (
    <DurationSelectorContainer data-testid="DurationSelectorComponent">
      <DurationInput onChange={setHours} max={12} selectorType="h" value={hours} />
      <Label>:</Label>
      <DurationInput onChange={setMinutes} step={15} max={45} selectorType="m" value={minutes} />
    </DurationSelectorContainer>
  );
});

export default DurationSelector;
