import { Api, getApiUrl } from '@engage-shared/api/instance';
import { CreateTeamBookingParams, FetchTeamBookingDetails } from './interfaces';

export type CreateTeamBookingFn = (
  params: CreateTeamBookingParams,
) => Promise<FetchTeamBookingDetails>;
/**
 * Create a team booking.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 201 | team booking created |
 * | 403 | not authorized |
 * | 404 | not found |
 * | 500 | team booking failed |
 * @param spaceId
 * @param reservation
 * @param tenantId
 */
export const createTeamBooking: CreateTeamBookingFn = async ({ tenantId, reservation }) => {
  const url = new URL(`${getApiUrl(tenantId)}/team_bookings`);

  const response = await Api.post<{ data: FetchTeamBookingDetails }>(url.toString(), reservation);
  return response.data?.data;
};
