import { ParsedSpaceItem } from '@engage-shared/api/spaces';
import { Person } from '@engage-shared/api/people';
import { generateFloorPath, hasValidParamInSearchQuery, joinPaths } from '@engage-web/router/utils';
import {
  DRAWER_DEEP_LINK_PARAMS,
  PATH_SEGMENT,
  ROOT_PATH,
  SEARCH_QUERY,
} from '@engage-web/constants';
import { STRIP_SEARCH_PARAMS } from './constants';

export const getSpaceOrDeskPath = (
  space: ParsedSpaceItem,
  hasDeskOrSpaceId: boolean,
  isDesk = true,
) => {
  if (!space || !space.floorId) return ROOT_PATH;

  // in case if this is a booking deeplink we also isChecking
  // if search has booking or space id to display the related
  // drawer card, otherwise – just navigate to the floor path
  let result = generateFloorPath(`${space.floorId}`);

  if (hasDeskOrSpaceId) {
    result = joinPaths(
      generateFloorPath(`${space.floorId}`),
      isDesk ? PATH_SEGMENT.DESKS : PATH_SEGMENT.SPACES,
      `${space.id}`,
    );
  }

  return result;
};

export const getPersonPath = (person?: Person): string => {
  let result = ROOT_PATH;

  if (person) {
    result = joinPaths(ROOT_PATH, PATH_SEGMENT.PEOPLE, `${person.id}`);

    if (person.primaryLocation?.floorId) {
      result = joinPaths(generateFloorPath(`${person.primaryLocation.floorId}`), result);
    }
  }

  return result;
};

export const getEmployeePath = (employeeId: string) =>
  joinPaths(ROOT_PATH, PATH_SEGMENT.EMPLOYEE, employeeId);

export const getDeepLinkSearchParams = (searchString: string) => {
  const searchParams = new URLSearchParams(searchString);

  return {
    spaceId: searchParams.get(SEARCH_QUERY.SPACE),
    deskId: searchParams.get(SEARCH_QUERY.DESK),
    bookingSpaceId: searchParams.get(SEARCH_QUERY.BOOKING_SPACE),
    employeeId: searchParams.get(SEARCH_QUERY.EMPLOYEE),
    personId: searchParams.get(SEARCH_QUERY.PERSON),
    date: searchParams.get(SEARCH_QUERY.DATE),
    time: searchParams.get(SEARCH_QUERY.TIME),
    teamBookingId: searchParams.get(SEARCH_QUERY.TEAM_BOOKING_MEETING),
    floorId: searchParams.get(SEARCH_QUERY.FLOOR),
  };
};

export const stripDeepLinkSearchParams = (searchString?: string, paramsToStrip?: string[]) => {
  if (!searchString) return '';

  const searchParams = new URLSearchParams(searchString);

  const paramsList = paramsToStrip?.length ? paramsToStrip : STRIP_SEARCH_PARAMS;

  paramsList.forEach(searchParam => searchParams.delete(searchParam));

  return searchParams.toString();
};

export const getDeepLinkString = (path: string, search?: string) => {
  const cleanSearch = stripDeepLinkSearchParams(search);
  return cleanSearch ? `${path}?${cleanSearch}` : path;
};

export const getIsChecking = (deepLinkSearch: string, deepLinkPath: string, search: string) =>
  !!deepLinkPath || !!deepLinkSearch || hasValidParamInSearchQuery(search, DRAWER_DEEP_LINK_PARAMS);
