import { useQuery, UseQueryResult } from 'react-query';
import { SpaceType, SpaceTypesData } from '@engage-shared/api/spaces/interfaces';
import { SPACE_TYPES_QUERY_KEY } from '@engage-shared/api/spaces/constants';
import { LanguageTypes } from '@engage-shared/constants/languages';
import { UseQueryOptions } from 'react-query/types/react/types';
import { fetchSpaceTypes } from '@engage-shared/api/spaces/fetchSpaceTypes';

type UseSpaceTypesParams = {
  spaceType: SpaceType;
  locale?: LanguageTypes;
  options?: UseQueryOptions<SpaceTypesData>;
};

type UseSpaceTypesQuery = (params: UseSpaceTypesParams) => UseQueryResult<SpaceTypesData>;

export const useSpaceTypesQuery: UseSpaceTypesQuery = ({
  spaceType,
  locale,
  options = { enabled: true },
}) =>
  useQuery<SpaceTypesData>({
    queryKey: [SPACE_TYPES_QUERY_KEY, spaceType, locale],
    queryFn: () => fetchSpaceTypes({ spaceType, locale }),
    ...options,
    enabled: options?.enabled && !!spaceType,
  });
