import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { uiActions } from '@engage-web/store';
import { FocusId } from '@engage-web/utils/hooks/useFocus';
import { testProps } from '@engage-web/utils';
import { StyledMenuButton, StyledMenuIcon } from './styled';

type MenuButtonProps = {
  isMenuVisible: boolean;
};

export const MenuButton = memo(({ isMenuVisible }: MenuButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toggleMoreMenu = () => dispatch(uiActions.setDrawerMenuVisibility(!isMenuVisible));

  return (
    <StyledMenuButton
      id={FocusId.DrawerMenuButton}
      role="button"
      {...testProps(t, 'accessibilityLabels.drawerMenuButton').props}
      onClick={toggleMoreMenu}
    >
      <StyledMenuIcon $opened={isMenuVisible} name="three-dots" size={20} />
    </StyledMenuButton>
  );
});
