import styled from 'styled-components';

export const InfoTextWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 28px;
`;

export const InfoText = styled.span`
  margin-left: 10px;
  font-size: 12px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
`;

export const SkeletonWrap = styled.div`
  display: flex;
  width: 100%;
`;
