import React, { memo, useState } from 'react';
import { useSpaceTypeAttributes, useSpaceTypes } from '../../../../api/queries';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, filtersSelectors } from '../../../../store';
import { SPACE_ASSETS_FILTER } from '../../../../constants';
import { useRestrictTo } from '../../../../utils/hooks';
import ItemsList from './FilterList/FilterList';
import FilterSelector from './FilterSelector/FilterSelector';
import { getFilterDefaultType, toggleFilterItem } from './utils';

export const queryMap = {
  [SPACE_ASSETS_FILTER.TYPE]: useSpaceTypes,
  [SPACE_ASSETS_FILTER.ATTRIBUTE]: useSpaceTypeAttributes,
};

type TypesAndAttributesFilterProps = {
  isDraft?: boolean;
};

export interface GlobalFilters {
  dateStart: Date;
  dateEnd: Date;
  allDay: boolean;
  selectedDates: Date[];
  spaceTypes: number[];
  spaceAttributes: number[];
  capacity: number;
}

export const TypesAndAttributesFilter = memo(
  ({ isDraft = false }: TypesAndAttributesFilterProps) => {
    const restrictTo = useRestrictTo();

    // TODO: What is isDraft for?
    const filter = useSelector(
      isDraft ? filtersSelectors.getDraftFilter : filtersSelectors.getFilter,
    );
    const [filterType, setFilterType] = useState(getFilterDefaultType(filter));
    const dispatch = useDispatch();

    // @ts-ignore
    const activeFilter = filter[filterType];

    const toggleItem = ({ checked, id }: { checked: boolean; id: number }) => {
      dispatch(
        filtersActions.setFilterValues({
          isDraft,
          // @ts-ignore
          [filterType]: toggleFilterItem({
            id,
            checked,
            selected: activeFilter,
          }),
        }),
      );
    };

    if (!restrictTo) return null;

    return (
      <>
        <FilterSelector
          selectedFilterType={filterType}
          spaceType={restrictTo}
          setFilterType={setFilterType}
        />
        {filterType ? (
          <ItemsList
            query={queryMap[filterType]}
            selected={activeFilter}
            onToggle={toggleItem}
            spaceType={restrictTo}
            filterType={filterType}
          />
        ) : null}
      </>
    );
  },
);

export default memo(TypesAndAttributesFilter);
