import { useDispatch, useSelector } from 'react-redux';
import { tenantSelectors, userConfigSelectors } from '@engage-web/store';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@engage-web/api/queries/useUser';
import { ConciergeFetchMenuItem } from '@engage-shared/api/conciergeMenu/interfaces';
import { useQuery } from 'react-query';
import { Nullable } from '@engage-shared/utils/types';
import { CONCIERGE_MENU_QUERY_KEY, LanguageTypes } from '@engage-web/constants';
import { fetchMenuItems } from '@engage-shared/api';
import { isElectron } from '@engage-web/utils/electron';
import { MENU_ITEM_TYPES } from '@engage-shared/constants';
import { transformMenuItems } from './transformMenuItems';

const getBuildingIdKey = (buildingId: number | undefined): string =>
  buildingId ? `${buildingId}` : 'no-bl';

export const useMenuItems = () => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const isKiosk = isElectron();
  const { data: userData } = useUser();
  const currentLocation = useSelector(tenantSelectors.getCurrentLocation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buildingId = currentLocation?.building?.id;
  const { heatmapKiosk } = useSelector(tenantSelectors.getKioskConfig);

  type GetMenuItemFetchFnParams = {
    tenantId: Nullable<string>;
    locale: LanguageTypes;
    buildingId?: number;
  };

  const getMenuItemsFetchFn =
    ({ tenantId, locale, buildingId }: GetMenuItemFetchFnParams) =>
    () => {
      const type = isKiosk ? MENU_ITEM_TYPES.KIOSK : MENU_ITEM_TYPES.WEB;
      return typeof tenantId === 'undefined' || tenantId === null
        ? Promise.reject(new Error('Invalid tenantId'))
        : fetchMenuItems({ tenantId, locale, buildingId, type }).then(menuItemsReducer);
    };

  const menuItemsReducer = ({ data }: { data: ConciergeFetchMenuItem[] }) =>
    transformMenuItems({
      menuItems: data,
      navigate,
      dispatch,
      currentFloorId,
      email: userData?.email,
      heatmapKiosk,
    });

  return useQuery<ConciergeFetchMenuItem[]>(
    [CONCIERGE_MENU_QUERY_KEY, tenantId, locale, getBuildingIdKey(buildingId)],
    getMenuItemsFetchFn({
      tenantId,
      locale,
      buildingId,
    }),
    { enabled: !!tenantId && !!userData?.id },
  );
};
