import React, { useEffect } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { useSelectFloor } from '@engage-web/utils/hooks/useSelectFloor';
import { Loader, LogoAnimation, Wrapper } from '@engage-web/components/scenes/Main/styled';
import { ClientLogo, CornerGradient, Spinner } from '@engage-web/components/base';
import Modals from '@engage-web/components/scenes/modals/Modals';
import { NoLocationBackground } from '@engage-web/components/layouts';
import { SEARCH_QUERY } from '@engage-web/constants';
import { useSpaceLocation } from '@engage-web/api/queries';
import { generateFloorPath } from '@engage-web/router/utils';
import { useAppLocation } from '@engage-web/router/hooks';

export function Wayfinder() {
  const location = useAppLocation();
  const { search } = location;
  const [query] = useSearchParams();

  const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
  const userId = useSelector(tenantSelectors.getUserId);

  const spaceId = query.get(SEARCH_QUERY.SPACE);
  const deskId = query.get(SEARCH_QUERY.DESK);
  const bookingSpaceId = query.get(SEARCH_QUERY.BOOKING_SPACE);
  const bookingMeetingId = query.get(SEARCH_QUERY.BOOKING_MEETING);
  const spaceTypeId = spaceId ?? deskId;
  const hasLocation = !!(building && floor);

  const { setSpaceLocation } = useSpaceLocation();
  const checkForDefaultFloor = useSelectFloor();

  const [locationVerified, setLocationVerified] = React.useState(false);

  useEffect(() => {
    (async () => {
      if (!!bookingSpaceId && !!bookingMeetingId && !hasLocation) {
        await setSpaceLocation({ spaceId: +bookingSpaceId });
        setLocationVerified(true);
      } else if (!!userId && !hasLocation) {
        await checkForDefaultFloor();
        setLocationVerified(true);
      } else {
        setLocationVerified(true);
      }
    })();
  }, [
    hasLocation,
    bookingSpaceId,
    bookingMeetingId,
    userId,
    setSpaceLocation,
    checkForDefaultFloor,
    spaceTypeId,
  ]);

  if (!locationVerified) {
    return (
      <Loader>
        <LogoAnimation>
          <ClientLogo fallback={<Spinner />} />
        </LogoAnimation>
      </Loader>
    );
  }

  if (hasLocation) {
    return (
      <Navigate
        replace
        to={{
          pathname: generateFloorPath(floor.id),
          search,
        }}
      />
    );
  }

  return (
    <Wrapper data-testid="main-screen">
      <CornerGradient />
      <Modals />
      <NoLocationBackground />
      {/* Drawer is rendered in this outlet */}
      <Outlet />
    </Wrapper>
  );
}
