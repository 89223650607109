import { Nullable } from '@engage-shared/utils/types';
import { SENSOR_STATUS } from '@engage-shared/constants/space';
import { PresenceStatus } from '@engage-shared/constants/person';

export type IconName = 'away' | 'present';
export type IconProps = {
  name: IconName;
  size: number;
  style: { color: string };
};
export type RenderIcon = (props: IconProps) => Nullable<JSX.Element>;

/**
 * returns icon based on status and mapping passed
 * @param status
 * @param statusToIconMapping
 * @param renderIcon - render icon component (web or mobile versions)
 */
const getStatusIcon = <T extends string>(
  status: T,
  statusToIconMapping: { [P in T]: Nullable<IconName> },
  renderIcon: RenderIcon,
): Nullable<JSX.Element> =>
  statusToIconMapping[status]
    ? renderIcon({
        name: statusToIconMapping[status]!,
        size: 22,
        style: { color: '#fff' },
      })
    : null;

const SensorStatusToIcon: { [P in SENSOR_STATUS]: Nullable<IconName> } = {
  [SENSOR_STATUS.OFFLINE]: null,
  [SENSOR_STATUS.VACANT]: 'away',
  [SENSOR_STATUS.OCCUPIED]: 'present',
};

export const getSensorStatusIcon = (
  sensorStatus: SENSOR_STATUS,
  renderIcon: RenderIcon,
): Nullable<JSX.Element> => getStatusIcon(sensorStatus, SensorStatusToIcon, renderIcon);

const PresenceStatusToIcon: { [P in PresenceStatus]: Nullable<IconName> } = {
  [PresenceStatus.OFFLINE]: null,
  [PresenceStatus.ACTIVE]: 'present',
  [PresenceStatus.WIFI]: 'present',
  [PresenceStatus.AWAY]: 'away',
  [PresenceStatus.REMOTE]: 'away',
};

export const getPresenceStatusIcon = (
  presenceStatus: PresenceStatus,
  renderIcon: RenderIcon,
): Nullable<JSX.Element> => getStatusIcon(presenceStatus, PresenceStatusToIcon, renderIcon);
