import { useSelector } from 'react-redux';
import { teamReservationsSelectors } from '@engage-web/store';
import { TEAM_RESERVATION_DESKS_SELECTION_MODE } from '@engage-web/store/teamReservations';

/**
 * Check if team reservation desk selection mode is on.
 * @returns Flag indicating if desk selection mode is active.
 */
export function useIsDeskSelection() {
  const selectionMode = useSelector(teamReservationsSelectors.getTeamReservationDesksSelectionMode);

  return selectionMode === TEAM_RESERVATION_DESKS_SELECTION_MODE.PAINT;
}
