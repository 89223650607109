/**
 * Determine which duration component should be displayed: date card, multi-days selector or duration selector.
 * For enforced full day reservations the multi-day selector is displayed.
 * For Hourly reservations is displayed duration selector. And for updating reservations the date card is displayed.
 * @param spaceItem
 * @param isUpdate
 * @param enforceAllDayDeskReservations
 * @return {showDateCard, showDaysSelector, showDurationSelection}
 */
import { Nullable } from '../types';
import { getReservationEndDate, getReservationStartDate, getUtcIsoString } from '../dates';
import { ShowDurationFlags, SpaceItem } from '@engage-shared/api/spaces/interfaces';
import { Reservation } from '@engage-shared/api/bookings/interfaces';

export const showDurationComponent = ({
  spaceItem,
  isUpdate,
  enforceAllDayDeskReservations,
}: {
  spaceItem: SpaceItem;
  isUpdate: boolean;
  enforceAllDayDeskReservations: boolean;
}): ShowDurationFlags => {
  const { bookable, isDesk } = spaceItem;

  let showDateCard = false;
  let showDaysSelector = false;
  let showDurationSelection = false;

  // display reservation date selection only for bookable items
  if (bookable) {
    // display multi-day selection for desks when only all-day reservations are allowed
    if (isDesk && enforceAllDayDeskReservations && !isUpdate) {
      showDaysSelector = true;
      // on update the reservation dates are selected through filters
    } else if (isUpdate) {
      showDateCard = true;
    } else {
      // otherwise enable duration selection
      showDurationSelection = true;
    }
  }

  return { showDateCard, showDaysSelector, showDurationSelection };
};

export type AssembleReservationParams = {
  spaceId: number;
  isDesk: boolean;
  showDaysSelector: boolean;
  showDurationSelection: boolean;
  // enforceAllDayDeskReservations: boolean;
  allDay: boolean;
  duration: number;
  // allDayDuration: boolean;
  bookingIntervalSize: number;
  hasLimitPer15Minutes: boolean;
  timeZone: string;
  isUpdate: boolean;
  selectedDay?: Nullable<Date>;
  dateStart?: Nullable<Date>;
  dateEnd?: Nullable<Date>;
  email?: string;
  reserveeId?: number;
  summary?: string;
  bookingInitialSpaceId?: number;
};
type AssembleReservation = (params: AssembleReservationParams) => Reservation;
export const assembleReservation: AssembleReservation = ({
  spaceId,
  isDesk,
  showDaysSelector,
  showDurationSelection,
  allDay,
  duration,
  bookingIntervalSize,
  hasLimitPer15Minutes,
  timeZone,
  isUpdate,
  selectedDay,
  dateStart,
  dateEnd,
  email,
  reserveeId,
  summary,
  bookingInitialSpaceId,
}) => {
  const dateS = getReservationStartDate({
    selectedDay,
    dateStart,
    bookingIntervalSize,
    hasLimitPer15Minutes,
    allDay,
    timeZone,
  });

  const dateE = getReservationEndDate({
    selectedDay,
    dateStart: dateS,
    dateEnd,
    allDay,
    duration,
    bookingIntervalSize,
    showDaysSelector,
    showDurationSelection,
    timeZone,
  });

  const reservationStartDate = getUtcIsoString(dateS);
  const reservationEndDate = getUtcIsoString(dateE);

  const reservation: Reservation = {
    start: reservationStartDate,
    end: reservationEndDate,
    startTimeZone: timeZone,
    endTimeZone: timeZone,
    isAllDayBooking: allDay,
  };

  if (reserveeId) {
    reservation.reservee = reserveeId;
  }

  // The organizer parameter is used for Space reservations
  if (!isDesk && email) {
    reservation.organizer = email;
  }

  if (!isDesk) {
    reservation.summary = summary;
  }

  if (isUpdate && bookingInitialSpaceId !== spaceId) {
    reservation.newLocationId = spaceId;
  }

  return reservation;
};
