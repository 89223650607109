import { fetchOptOutStatus } from '@engage-shared/api/people/fetch';
import { PERSON_OPT_OUT_QUERY_KEY } from '@engage-shared/api/people/constants';
import {
  EngageOptOutData,
  OptOutQueryOptions,
  OptOutStatusResponse,
  PersonId,
} from '@engage-shared/api/people/interfaces';
import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { TenantId } from '@engage-shared/api/tenant/interfaces';

type UsePersonOptOutQueryParams = {
  tenantId: TenantId;
  personId: PersonId;
  options?: OptOutQueryOptions;
};

type UsePersonOptOutQuery = (
  params: UsePersonOptOutQueryParams,
) => UseQueryResult<EngageOptOutData>;

export const usePersonOptOutQuery: UsePersonOptOutQuery = ({ tenantId, personId, options = {} }) =>
  useQuery<OptOutStatusResponse, AxiosError, EngageOptOutData>(
    [PERSON_OPT_OUT_QUERY_KEY, tenantId],
    () => fetchOptOutStatus({ tenantId, personId }),
    options,
  );
