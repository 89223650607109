import { Token } from '@engage-shared/api/instance/interfaces';

export const getAuthorizationHeaderStringFromToken = (token: Token): string => `Bearer ${token}`;

export const getAuthorizationHeaderObjectFromToken = (token: Token): { Authorization: string } => ({
  Authorization: getAuthorizationHeaderStringFromToken(token),
});

export const getAuthorizationHeaderObjectFromString = (
  authString: string,
): { Authorization: string } => ({
  Authorization: authString,
});

export const getAuthorizationHeaderStringFromObject = (headerObject: any): string | undefined =>
  headerObject?.Authorization;
