import { Api, getApiUrl } from '@engage-shared/api/instance';
import { UserConfig, UserConfigPayload } from './interfaces';

type FetchUserConfig = () => Promise<UserConfig>;
/**
 * Retrieves user config.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | config retrieved |
 *
 */
export const fetchUserConfig: FetchUserConfig = async () => {
  const url = new URL(`${getApiUrl()}/user_config`);
  const response = await Api.get(url.toString());
  return response.data?.data;
};

type UpdateUserConfig = (config: UserConfigPayload) => Promise<UserConfig>;
/**
 * Updates user config.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 201 | config updated |
 *
 */
export const updateUserConfig: UpdateUserConfig = async config => {
  const url = new URL(`${getApiUrl()}/user_config`);
  const response = await Api.post(url.toString(), config);
  return response.data;
};
