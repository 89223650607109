import styled from 'styled-components';
import ReactCalendar from 'react-calendar';

type CalendarStyledProps = {
  expandedDayFormat: boolean;
};

export const CalendarStyled = styled(ReactCalendar)<CalendarStyledProps>`
  font-family: 'Eptura Flexible', 'Noto Sans', serif;
  border: none;

  @media (max-width: 400px) {
    width: 300px;
  }

  & .react-calendar__navigation__label {
    // we do not navigate by label
    pointer-events: none;
  }

  & .react-calendar__month-view__weekdays {
    margin-bottom: 5px;
  }

  & .react-calendar__month-view__weekdays__weekday {
    width: 44px;
    padding: 13px 0;
    color: ${({ theme }) => theme.color1};
    opacity: 0.7;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid ${({ theme }) => theme.color4};

    & abbr {
      text-decoration: none;
    }

    @media (max-width: 400px) {
      width: 38px;
    }
  }

  & .react-calendar__month-view__days__day {
    @media (max-width: 400px) {
      margin: 0;
      width: 40px;
      height: 42px;
    }
    button {
      & abbr {
        display: block;
        width: ${({ expandedDayFormat }) => (expandedDayFormat ? '50px' : '36px')};
        padding: ${({ expandedDayFormat }) => (expandedDayFormat ? '15px 10px' : '9px 10px;')};
        border-radius: 50%;
      }

      &:hover {
        background: none;

        & abbr {
          background: ${({ theme }) => theme.color4};
        }
      }
    }
    width: ${({ expandedDayFormat }) => (expandedDayFormat ? '50px' : '44px')};
    font-size: 14px;
    padding: 0;
    margin: 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &--weekend {
      button {
        color: #000000;
      }
    }
  }

  & .react-calendar__tile {
    background: none;

    &--now > button,
    &--now > button:hover {
      background: none;
      color: ${({ theme }) => theme.color9};
    }

    &--now&--active > button {
      color: #ffffff;
    }

    &--active > button,
    &--active > button:hover,
    &--active > button:focus,
    &--active > button:enabled:hover,
    &--active > button:enabled:focus {
      background: none;

      & abbr {
        background: ${({ theme }) => theme.color9};
        color: #ffffff;
      }
    }

    & > button {
      padding: 5px 6.6667px;
      &:disabled {
        color: ${({ theme }) => theme.gray4};
        background-color: transparent;
      }
    }
  }

  .react-calendar__navigation {
    align-items: center;

    @media (max-width: 400px) {
      height: 38px;
      margin: 0;
    }

    &__label {
      &:enabled:hover {
        background-color: transparent;
      }

      &:disabled {
        color: ${({ theme }) => theme.gray4};
        background-color: transparent;
      }

      &__labelText {
        font-size: 24px;
        font-weight: 700;
      }
    }

    &__next-button,
    &__prev-button {
      height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 30px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ecf1f8;
      color: ${({ theme }) => theme.color2};

      &[disabled] {
        opacity: 0.5;
        color: ${({ theme }) => theme.gray4};
      }
    }
  }
`;
