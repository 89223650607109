import { useEffect, useRef, useState } from 'react';

export type UseTimer = {
  seconds: number;
  onTimeOver: () => void;
  onTimerCancel?: () => void;
};

export const useTimer = ({ seconds, onTimeOver, onTimerCancel }: UseTimer) => {
  const [timerActive, setTimerActive] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(seconds);
  const intervalRef = useRef<any>();

  const activateTimer = () => {
    setTimerActive(true);
  };

  const cancelTimer = () => {
    clearInterval(intervalRef.current);
    setTimerActive(false);
    setSecondsLeft(seconds);
    onTimerCancel?.();
  };

  useEffect(() => {
    if (timerActive) {
      intervalRef.current = setInterval(() => setSecondsLeft(sec => sec - 1), 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [timerActive]);

  useEffect(() => {
    if (secondsLeft === 0) {
      onTimeOver();
      cancelTimer();
    }
  }, [cancelTimer, onTimeOver, secondsLeft]);

  return { activateTimer, cancelTimer, secondsLeft };
};
