import { Dispatch, SetStateAction } from 'react';
import { DurationObj } from './types';

type UpdateDurationObjFromState = ({
  state,
  dataKey,
  setDurationObj,
}: {
  state: any;
  dataKey: string;
  setDurationObj: Dispatch<SetStateAction<DurationObj>>;
}) => void;

export const updateDurationObjFromState: UpdateDurationObjFromState = ({
  state,
  dataKey,
  setDurationObj,
}) => {
  if (state && dataKey) {
    // @ts-ignore
    const stateData = state[dataKey] ?? {};
    const {
      duration: stateDuration,
      allDayDuration: stateAllDayDuration,
      selectedDays: stateSelectedDays,
    } = stateData;
    if (stateDuration > 0) {
      setDurationObj((state: DurationObj) => ({
        ...state,
        duration: stateDuration,
        allDayDuration: false,
      }));
    }
    if (stateAllDayDuration === true) {
      setDurationObj((state: DurationObj) => ({
        ...state,
        duration: 0,
        allDayDuration: true,
      }));
    }
    if (stateSelectedDays && stateSelectedDays.length != 0) {
      setDurationObj((state: DurationObj) => ({
        ...state,
        selectedDays: stateSelectedDays,
      }));
    }
  }
};
