import styled from 'styled-components';
import SpaceCard from './SpaceCard/SpaceCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  @media (max-height: 760px) {
    height: calc(100vh - 218px);
    overflow-y: auto;
  }
`;

const InnerWrapper = styled.div<{ $marginBottom?: number }>`
  display: flex;
  flex-direction: column;
  ${({ $marginBottom }) => $marginBottom && `margin-bottom: ${$marginBottom}px`};
`;

const StyledSpaceCard = styled(SpaceCard)`
  flex: 1;
`;

const ReserveButton = styled.button`
  width: 100%;
  padding: 24px 0;
  text-align: center;
  background-color: ${({ disabled, theme }) => (disabled ? '#cccccc' : theme.color9)};
  color: ${({ disabled, theme }) => (disabled ? '#000000' : theme.typo1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: 700;
  margin-top: 20px;
  &.focus-visible {
    outline: none;
    box-shadow: inset 0 0 3px 5px Highlight;
  }
`;

const AvailabilityBarWrapper = styled.div`
  margin-bottom: 30px;
`;

const PersonButtonWrapper = styled.div`
  margin: 20px 0 10px 0;
`;

const DaysSelectorWrapper = styled.div`
  width: 300px;
`;

export {
  Wrapper,
  InnerWrapper,
  StyledSpaceCard,
  ReserveButton,
  AvailabilityBarWrapper,
  PersonButtonWrapper,
  DaysSelectorWrapper,
};
