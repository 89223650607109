import { useMutation } from 'react-query';
import { logon } from '@engage-web/api';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions, tenantSelectors } from '@engage-web/store';
import { useConfig } from '@engage-web/utils/hooks';
import { TokenManager } from '@engage-shared/api';

interface UseLogonParams {
  onSuccess?: (data: any, variables: any) => void;
  onError?: (error: any, variables: any) => void;
}

const useLogon = (options: UseLogonParams) => {
  const dispatch = useDispatch();
  const { instanceTitle, logo } = useConfig();
  const tenantId = useSelector(tenantSelectors.getTenantId);

  return useMutation(logon, {
    onSuccess: async (data, variables) => {
      const { token, refreshToken } = data;
      dispatch(
        tenantActions.addTenant({
          id: tenantId,
          name: instanceTitle,
          logo,
        }),
      );
      await TokenManager.setTokens({
        token,
        refreshToken,
      });
      dispatch(tenantActions.setUser(TokenManager.getUserIdFromToken(token)));
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
    onError: options?.onError,
  });
};

export { useLogon };
