import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types';
import { UseQueryOptions } from 'react-query/types/react/types';
import { getAgendaItems } from '@engage-shared/utils/dates';
import { FetchBuildingQuery } from '@engage-shared/api/buildings/interfaces';
import {
  AgendaEventsMonthParams,
  AgendaEventsParams,
  AgendaEventsTimeParams,
  AgendaItems,
  Event,
} from '@engage-shared/api/users/interfaces';
import { useBuildingFetch } from '@engage-shared/api/buildings';
import { addTimeZoneToEvents, getMonthBoundaryDates, mapEventItemAttributes } from '../utils';
import { fetchEvents } from '../fetch';
import { AGENDA_EVENTS_KEY } from '../constants';

type FetchAndParseEventsParams = AgendaEventsParams &
  AgendaEventsTimeParams & {
    fetchBuildingQuery: FetchBuildingQuery;
  };
type FetchAndParseEvents = (params: FetchAndParseEventsParams) => Promise<Event[]>;

/**
 * Fetches user events and formats event data.
 */
export const fetchAndParseEvents: FetchAndParseEvents = async ({
  userId,
  startTime,
  endTime,
  eventsFilter,
  personId,
  fetchBuildingQuery,
  userTimeZone,
  translationFn,
}) => {
  const eventsData = await fetchEvents({
    userId,
    startTime,
    endTime,
    eventsFilter,
  });
  const events = mapEventItemAttributes(eventsData, personId);

  return addTimeZoneToEvents({
    events,
    fetchBuildingQuery,
    userTimeZone,
    translationFn,
  });
};

type FetchAndParseAgendaEventsParams = AgendaEventsParams &
  AgendaEventsMonthParams & {
    fetchBuildingQuery: FetchBuildingQuery;
  };

type FetchAndParseAgendaEvents = (params: FetchAndParseAgendaEventsParams) => Promise<AgendaItems>;
/**
 * Get events in the agenda required format (object with day of the month as key and array of day's events).
 * @param personId
 * @param eventsFilter
 * @param userId
 * @param month
 * @param year
 * @param fetchBuildingQuery
 * @param userTimeZone
 * @param translationFn
 */
export const fetchAndParseAgendaEvents: FetchAndParseAgendaEvents = async ({
  userId,
  month,
  year,
  eventsFilter,
  personId,
  fetchBuildingQuery,
  userTimeZone,
  translationFn,
}) => {
  const { startTime, endTime } = getMonthBoundaryDates({ year, month });

  const events = await fetchAndParseEvents({
    userId,
    startTime,
    endTime,
    eventsFilter,
    personId,
    fetchBuildingQuery,
    userTimeZone,
    translationFn,
  });
  return getAgendaItems(events, year, month, userTimeZone);
};

type UseAgendaEventsParams = AgendaEventsParams &
  AgendaEventsMonthParams & {
    options: UseQueryOptions<AgendaItems>;
  };
type UseAgendaEvents = (params: UseAgendaEventsParams) => UseQueryResult<AgendaItems>;

export const useAgendaEvents: UseAgendaEvents = ({
  month,
  year,
  eventsFilter,
  personId,
  userId,
  userTimeZone,
  translationFn,
  options = {},
}) => {
  const { fetchBuildingQuery } = useBuildingFetch();

  return useQuery<AgendaItems>(
    [AGENDA_EVENTS_KEY, userId, month, year, eventsFilter],
    () =>
      fetchAndParseAgendaEvents({
        personId,
        eventsFilter,
        userId,
        month,
        year,
        fetchBuildingQuery,
        userTimeZone,
        translationFn,
      }),
    {
      enabled: !!userId,
      ...options,
    },
  );
};
