import { fetchBooking } from '@engage-shared/api/bookings/fetch';
import { QueryClient } from 'react-query';
import { ParsedUserData } from '@engage-shared/api/users/interfaces';
import { FetchBookingParams } from '@engage-shared/api/bookings/interfaces';
import { BookingDetails } from '@engage-shared/api/bookings/interfaces/bookingDetails.type';
import { assembleBookingDetails } from '@engage-shared/api/bookings/utils/assembleBookingDetails';
import { fetchSpace } from '@engage-shared/api/spaces';
import { FetchBuildingQuery } from '@engage-shared/api/buildings/interfaces';
import { Nullable } from '@engage-shared/utils/types';
import { BOOKING_KEY } from '@engage-shared/api/bookings/constants';

type FetchBookingDetailsParams = FetchBookingParams & {
  queryClient: QueryClient;
  userData: ParsedUserData;
  fetchBuildingQuery: FetchBuildingQuery;
};
type FetchBookingDetails = (params: FetchBookingDetailsParams) => Promise<Nullable<BookingDetails>>;

export const fetchBookingDetails: FetchBookingDetails = async ({
  queryClient,
  tenantId,
  spaceId,
  meetingId,
  userData,
  fetchBuildingQuery,
}) => {
  if (!spaceId) {
    throw new Error('Space not defined');
  }

  if (!meetingId) {
    throw new Error('Booking does not exist');
  }

  const bookingData = await queryClient.fetchQuery(
    [BOOKING_KEY, tenantId, `${spaceId}`, `${meetingId}`],
    () => fetchBooking({ tenantId, spaceId, meetingId: `${meetingId}` }),
  );

  let spaceData;
  try {
    spaceData = await fetchSpace({
      id: spaceId,
      startTime: bookingData?.startTime,
    });
  } catch (error) {
    spaceData = null;
  }

  return assembleBookingDetails({
    tenantId,
    spaceData,
    bookingData,
    userData,
    fetchBuildingQuery,
  });
};
