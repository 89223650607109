import { CONCIERGE_MENU_PATH } from '@engage-web/constants';
import { useAppLocation } from '@engage-web/router/hooks';
import { useMenuItems } from '@engage-web/api/queries/menu/useMenuItems';
import { isElectron } from '@engage-web/utils/electron';

export const useConciergeMenuAllowed = () => {
  const { data: menuItems } = useMenuItems();
  const { pathname } = useAppLocation();
  const isKiosk = isElectron();

  const conciergeMenuItemsMin = isKiosk ? 1 : 3;

  const numberOfMenuItems = menuItems?.length ?? 0;
  const conciergeMenuAllowed =
    pathname !== CONCIERGE_MENU_PATH && numberOfMenuItems >= conciergeMenuItemsMin;

  return {
    conciergeMenuAllowed,
  };
};
