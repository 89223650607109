import { useQuery, UseQueryResult } from 'react-query';
import { Location, PersonId, PersonLocation } from '@engage-shared/api/people/interfaces';
import { PERSON_LOCATIONS_QUERY_KEY } from '@engage-shared/api/people/constants';
import { getPersonLocationLabel } from '@engage-shared/api/people/utils';
import { TFunction as TranslateFunction } from 'i18next';
import { QueryKey } from 'react-query/types';

type GetPersonLocations = (params: {
  location: Location[];
  personId: PersonId;
  translationFn: TranslateFunction;
}) => Promise<PersonLocation[]>;

type UsePersonLocationsQueryParams = {
  personId: number;
  personLocation: Location[];
  translationFn: TranslateFunction;
};

type UsePersonLocationsQuery = (
  params: UsePersonLocationsQueryParams,
) => UseQueryResult<PersonLocation[]>;

/**
 * Retrieves person locations based on person api location.
 * @param personId
 * @param location
 * @param translationFn
 */
const getPersonLocations: GetPersonLocations = ({ personId, location, translationFn }) =>
  Promise.all(
    location.map(async data => {
      const label = await getPersonLocationLabel({
        location: data,
        translationFn,
      });
      const { spaceId, level } = data;
      return {
        ...data,
        personId,
        location: {
          level,
          spaceId,
        },
        label,
      };
    }),
  );

export const usePersonLocationsQuery: UsePersonLocationsQuery = ({
  personId,
  personLocation,
  translationFn,
}) =>
  useQuery<PersonLocation[], unknown, PersonLocation[], [QueryKey, number]>(
    [PERSON_LOCATIONS_QUERY_KEY, personId],
    () => getPersonLocations({ location: personLocation, personId, translationFn }),
  );
