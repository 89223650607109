import {
  AVAILABILITY_MESSAGES_TYPES,
  CHECK_IN_MESSAGES_TYPES,
  RESERVE_MESSAGES_TYPES,
  UPDATE_MESSAGES_TYPES,
} from './types';

export const AVAILABILITY_MESSAGES: AVAILABILITY_MESSAGES_TYPES = {
  Desk: {
    422: {
      default: 'layout.reserve.desk.availabilityError',
    },
    default: 'layout.reserve.desk.errorOccurred',
  },
  Space: {
    422: {
      default: 'layout.reserve.space.availabilityError',
    },
    default: 'layout.reserve.space.errorOccurred',
  },
};

export const RESERVE_MESSAGES: RESERVE_MESSAGES_TYPES = {
  Desk: {
    400: {
      default: 'layout.reserve.desk.badRequest',
    },
    403: {
      default: 'layout.reserve.desk.forbidden',
    },
    404: {
      default: 'layout.reserve.desk.spaceNotFound',
    },
    422: {
      'Desk cannot be booked at this time.': 'layout.reserve.desk.occupied',
      'Desk cannot be booked, booking rule violation.': 'layout.reserve.desk.forbidden',
      'Unable to complete booking.': 'layout.reserve.desk.errorOccurred',
      'Desk cannot be booked, concurrent booking limit reached.':
        'layout.reserve.desk.concurrentLimit',
      'Desk booking should not exceed the building desk bookings per 15 minutes limitation':
        'layout.reserve.desk.intervalLimit',
      'Desk booking should not exceed the building desk bookings per day limitation':
        'layout.reserve.desk.buildingLimit',
      'The building is not open for reservations at this time.': 'layout.reserve.desk.bookingHours',
      default: 'layout.reserve.desk.errorOccurred',
    },
    default: 'layout.reserve.desk.errorOccurred',
  },
  Space: {
    400: {
      default: 'layout.reserve.space.badRequest',
    },
    403: {
      default: 'layout.reserve.space.forbidden',
    },
    404: {
      default: 'layout.reserve.space.spaceNotFound',
    },
    422: {
      // The message it is supposed to be with Desk even though it is a room, cannot test right now because RBS is down
      'Desk cannot be booked at this time.': 'layout.reserve.space.occupied',
      'Unable to complete booking.': 'layout.reserve.space.errorOccurred',
      'The building is not open for reservations at this time.':
        'layout.reserve.space.bookingHours',
      default: 'layout.reserve.space.errorOccurred',
    },
    default: 'layout.reserve.space.errorOccurred',
  },
};

export const CHECK_IN_MESSAGES: CHECK_IN_MESSAGES_TYPES = {
  'Engage desk check in is disabled.': 'layout.booking.errorMessages.checkInDisabled',
  'booking owned by another user.': 'layout.booking.errorMessages.bookingOwnedByOtherUser',
  'Check-in can only occur within the check-in window.':
    'layout.booking.errorMessages.checkInWindow',
  'Unable to Desk check in.': 'layout.booking.errorMessages.genericCheckIn',
};

export const UPDATE_MESSAGES: UPDATE_MESSAGES_TYPES = {
  Desk: {
    403: {
      default: 'layout.reserve.desk.forbidden',
    },
    422: {
      'Cannot change desk booking location once booking has commenced':
        'layout.reserve.desk.deskBookingCommenced',
      'Cannot book this desk': 'layout.reserve.desk.deskUnavailable',
      'Desk booking should not exceed the building desk bookings per 15 minutes limitation':
        'layout.reserve.desk.intervalLimit',
      default: 'layout.reserve.desk.genericUpdate',
    },
    default: 'layout.reserve.desk.errorOccurred',
  },
  Space: {
    403: {
      default: 'layout.reserve.space.forbidden',
    },
    422: {
      'Cannot change desk booking location once booking has commenced':
        'layout.reserve.space.spaceBookingCommenced',
      default: 'layout.reserve.space.occupied',
    },
    default: 'layout.reserve.space.errorOccurred',
  },
};
