import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from '@engage-web/components/base';
import SpaceCardSkeleton from './SpaceCard/SpaceCardSkeleton';
import { InnerWrapper, Wrapper } from './styled';

const DaysSelector = styled.div`
  width: 80%;
`;

const Button = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const ReserveCardSkeleton = () => (
  <SkeletonTheme style={{ width: '100%' }}>
    <Wrapper data-testid="reserve-card-skeleton">
      <InnerWrapper>
        <SpaceCardSkeleton />
      </InnerWrapper>
      {/* Days Selection */}
      <DaysSelector>
        <Skeleton height={30} />
      </DaysSelector>
      {/* Reserve Button */}
      <Button>
        <Skeleton height={60} />
      </Button>
    </Wrapper>
  </SkeletonTheme>
);

export default ReserveCardSkeleton;
