import React from 'react';
import { useQueryClient } from 'react-query';
import { TenantData, TenantId } from '@engage-shared/api/tenant/interfaces';
import { fetchTenant } from '@engage-shared/api/tenant/fetch';
import { TENANT_QUERY_KEY } from '@engage-shared/api/tenant/constants';
import { LanguageTypes } from '@engage-shared/constants/languages';

const STALE_TIME: number = 5 * 60 * 1000; // 5 minutes

type UseTenantFetch = {
  fetchTenantQuery: (tenantId: TenantId, locale: LanguageTypes) => Promise<TenantData>;
};

export const useTenantFetch = (): UseTenantFetch => {
  const queryClient = useQueryClient();

  const query = (tenantId: TenantId, locale: LanguageTypes): Promise<TenantData> =>
    queryClient.fetchQuery([TENANT_QUERY_KEY, tenantId], () => fetchTenant(tenantId, locale), {
      staleTime: STALE_TIME,
    });

  const fetchTenantQuery = React.useCallback(query, [queryClient]);

  return { fetchTenantQuery };
};
