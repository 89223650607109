import OpenSeadragon from 'openseadragon';
import { getShapeBoundingBox, getShapeCoordinate } from './shapeUtils';
import { Space } from '@floorplan/api';
import { isNativeApp } from './reactNative';

type PanAndZoomOptions = {
  viewport: OpenSeadragon.Viewport;
  point: OpenSeadragon.Point;
  zoomTo: number;
  isTablet: boolean;
};
// TODO refactor and simply https://codepen.io/iangilman/pen/poeqovO
// OpenSeadragon Recipes https://codepen.io/collection/APoyjJ?cursor=ZD0wJm89MCZwPTEmdj00
export const panAndZoom = ({ viewport, point, zoomTo, isTablet }: PanAndZoomOptions) => {
  // This is a weird and hacky way to get the correct bounds of the viewport, so that we can pan correctly
  // I couldn't find anything better in openSeaDragon to do this mathematically
  const lastPan = viewport.getCenter();
  const lastZoom = viewport.getZoom();
  viewport.panTo(point, true);
  viewport.zoomTo(zoomTo, undefined, true);
  // This is the bounds we are after, that we can use to correctly position the pan point to the upper part of the screen
  if (isNativeApp) {
    const bounds = viewport.getBounds();
    viewport.panTo(lastPan, true);
    viewport.zoomTo(lastZoom, undefined, true);
    const offsetTablet = new OpenSeadragon.Point(-Math.max(bounds.height, bounds.width) / 5, 0);
    const offsetMobile = new OpenSeadragon.Point(0, Math.max(bounds.height, bounds.width) / 3.5);
    const offset = isTablet ? offsetTablet : offsetMobile;
    const newPoint = point.plus(offset.rotate(-viewport.getRotation()));
    viewport.panTo(newPoint);
    viewport.zoomTo(zoomTo);
  } else {
    const offset = viewport.deltaPointsFromPixels(new OpenSeadragon.Point(-200, 0));

    viewport.panTo(lastPan, true);
    viewport.zoomTo(lastZoom, undefined, true);
    const newPoint = point.plus(offset.rotate(-viewport.getRotation()));
    viewport.panTo(newPoint);
    viewport.zoomTo(zoomTo);
  }
};

export const panToShapeCollection = (
  viewport: OpenSeadragon.Viewport,
  fullShapeList: Space[],
  zoom = false,
  isTablet: boolean,
) => {
  const firstBound = getShapeBoundingBox(fullShapeList[0]);
  const shapeBounds = fullShapeList.reduce(
    // @ts-ignore
    (b, fullShape) => b.union(getShapeBoundingBox(fullShape)),
    firstBound,
  );

  if (shapeBounds) {
    const newZoom =
      1 / (viewport.imageToViewportCoordinates(shapeBounds.width, shapeBounds.height).x + 0.6);

    const zoomTo = zoom ? newZoom : viewport.getZoom();
    const point = viewport.imageToViewportCoordinates(shapeBounds.getCenter());

    panAndZoom({ viewport, point, zoomTo, isTablet });
  }
};

type PanToShapeOptions = {
  viewport: OpenSeadragon.Viewport;
  space: Space;
  zoom?: boolean;
  isTablet?: boolean;
};
export const panToShape = ({ viewport, space, zoom, isTablet = false }: PanToShapeOptions) => {
  const coords = getShapeCoordinate(space);

  if (!coords) {
    return;
  }

  const point = viewport.imageToViewportCoordinates(coords.x, coords.y);

  let newZoom = 1;
  if (zoom) {
    const boundingBox = getShapeBoundingBox(space);
    if (boundingBox) {
      newZoom =
        1 /
        (viewport.imageToViewportCoordinates(boundingBox.width, boundingBox.height).x + isNativeApp
          ? 0.3
          : 0.6);
    }
  }

  const zoomTo = zoom ? newZoom : viewport.getZoom();

  panAndZoom({ viewport, point, zoomTo, isTablet });
};

// bezier ease in out
const ease = (t: number) => {
  return t * t * (3 - 2 * t);
};

export const resetRotation = (viewport?: OpenSeadragon.Viewport) => {
  if (!viewport) {
    return;
  }

  const startRotation = viewport.getRotation() % 360;
  // Choose the smallest possible rotation total
  const endRotation = startRotation > 180 ? 360 : 0;

  if (Math.abs(endRotation - startRotation) < 2) {
    viewport.setRotation(0);
    return;
  }

  const startTime = Date.now();
  const animationTime = (Math.abs(endRotation - startRotation) / 180) * 1000;

  const animateRotation = () => {
    if (!viewport) return;
    const time = Date.now();
    const t = (time - startTime) / animationTime;

    if (t < 1) {
      viewport.setRotation(ease(t) * (endRotation - startRotation) + startRotation);
      requestAnimationFrame(animateRotation);
    } else {
      viewport.setRotation(0);
    }
  };

  requestAnimationFrame(animateRotation);
};
