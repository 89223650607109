import React from 'react';
import { useTranslation } from 'react-i18next';
import { testProps } from '@engage-web/utils/testProps';
import { Icon } from '../Icons/Icon';
import { Wrapper, ClearButton, FiltersStatusIconWrapper, FiltersStatusText } from './styled';

type GlobalSearchClearProps = {
  isFilterChanged: boolean;
  onPress: () => void;
};

export const GlobalSearchClear = ({ isFilterChanged = false, onPress }: GlobalSearchClearProps) => {
  const { t } = useTranslation();

  const {
    props: a11yAndTestProps,
    text: [filtersAccentText, filtersText],
  } = testProps(
    t,
    ['layout.globalSearch.filtersAccent', 'layout.globalSearch.filters'],
    ([filtersAccentText, filtersText]) => `${filtersAccentText} ${filtersText}`,
  );

  return (
    <Wrapper>
      {isFilterChanged && (
        <ClearButton
          $clickable={isFilterChanged}
          tabIndex={isFilterChanged ? 0 : -1}
          onClick={onPress}
          {...a11yAndTestProps}
        >
          <FiltersStatusIconWrapper>
            <Icon name="close" size={12} />
          </FiltersStatusIconWrapper>
          <FiltersStatusText $bold>{filtersAccentText}</FiltersStatusText>
          <FiltersStatusText>{filtersText}</FiltersStatusText>
        </ClearButton>
      )}
    </Wrapper>
  );
};
