import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchValue: '',
};

const globalSearch = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    clearSearchValue: state => {
      state.searchValue = '';
    },
  },
});

export const globalSearchSelectors = {
  getGlobalSearchValue: state => state.globalSearch.searchValue,
  getHasUserInput: ({ globalSearch }) => !!globalSearch.searchValue,
};

export default globalSearch;
