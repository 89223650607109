import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppLocation, useAppParams } from '../../../../router/hooks';
import {
  floorplanActions,
  inAppNotificationsActions,
  teamReservationsSelectors,
  tenantActions,
  tenantSelectors,
} from '@engage-web/store';
import { DrawerCardContent } from '../../../scenes/drawer/DrawerCardContent';
import { TEAM_RESERVATION_DESKS_SELECTION_MODE } from '../../../../store/teamReservations';
import { useTeam } from '../../../../api/queries/useTeam';
import { useTranslation } from 'react-i18next';
import { generateTeamPath } from '../../../../router/utils';
import { useNavigate } from 'react-router-dom';
import { useReservationFilters } from '../../../../utils';
import { TeamMembersError } from './TeamMembersError';
import { TeamMembersCard } from './TeamMembersCard';
import { TeamMembersSkeleton } from './TeamMembersSkeleton';
import { TeamMembersHeader } from './TeamMembersHeader';
import { DeskSelectionCard } from './DeskSelectionCard';
import { useTeamBookingsInCurrentInterval } from '@engage-shared/api';

export function TeamMembersContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { teamId } = useAppParams();
  const { state } = useAppLocation();
  const selectionMode = useSelector(teamReservationsSelectors.getTeamReservationDesksSelectionMode);
  const currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);

  const teamFromState = state?.team;
  const { data, isLoading, isError } = useTeam({
    id: teamId,
    teamFromState,
  });
  const team = data ?? teamFromState;

  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { dateStart: filterDateStart } = useReservationFilters(true);
  const teamBookings = useTeamBookingsInCurrentInterval({
    filterDateStart,
    teamId: team?.id,
    tenantId,
    options: {
      enabled: team && !team.favPeopleTeam,
    },
  });

  useEffect(() => {
    if (!team?.location) {
      return;
    }
    // display team's floorplan if team is on a different floor than the currently displayed floor
    if (currentFloorId !== team.location.floorId) {
      const pathname = generateTeamPath({
        floorId: team.location.floorId,
        teamId: team.id,
      });

      navigate(pathname, {
        replace: true,
        state: {
          ...state,
          isFinalLocation: true,
          team,
        },
      });

      dispatch(tenantActions.setCurrentLocation(team.location));
    }
  }, [currentFloorId, dispatch, navigate, state, team]);

  useEffect(() => {
    if (!team?.location) {
      return;
    }

    // highlight team booking's desks
    if (teamBookings?.length > 0) {
      dispatch(
        floorplanActions.setFocusedSpace({
          // @ts-ignore TODO: find out why we doesn't have spaceIds on TeamBooking type
          id: teamBookings[0].spaceIds,
        }),
      );
    } else {
      const shouldFocusSpaces = team.location.spaceIds?.length > 0;

      // highlight team's desks
      if (shouldFocusSpaces && team.location.floorId === currentFloorId) {
        dispatch(
          floorplanActions.setFocusedSpace({
            id: team.location.spaceIds,
          }),
        );
      }
    }
  }, [dispatch, t, team, currentFloorId, teamBookings]);

  useEffect(() => {
    if (!team?.location) {
      return;
    }

    // show a notification if team doesn't have desks
    if (team.location.spaceIds?.length === 0) {
      let message;
      if (team.name) {
        message = t('layout.teamMembersList.noSpacesTeam', {
          teamName: team.name,
        });
      } else {
        message = t('layout.teamMembersList.genericNoSpacesTeam');
      }

      dispatch(
        inAppNotificationsActions.addWarningNotification({
          message,
        }),
      );
    }
  }, [dispatch, t, team]);

  if (isLoading) {
    return <TeamMembersSkeleton />;
  }

  if (isError) {
    return <TeamMembersError />;
  }

  const isDeskSelection = selectionMode !== TEAM_RESERVATION_DESKS_SELECTION_MODE.DISABLED;

  return (
    <>
      <TeamMembersHeader teamName={team.name} membersCount={team.people} />
      <DrawerCardContent>
        {isDeskSelection ? <DeskSelectionCard team={team} /> : <TeamMembersCard team={team} />}
      </DrawerCardContent>
    </>
  );
}
