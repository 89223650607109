import i18n from 'i18next';
import { browserLanguage } from '@engage-web/utils';
import { initReactI18next } from 'react-i18next';
import en from '../../assets/translations/en/en.json';
import fr from '../../assets/translations/fr/fr.json';
import frCA from '../../assets/translations/fr/fr-CA.json';
import enAU from '../../assets/translations/en/en-AU.json';
import enGB from '../../assets/translations/en/en-GB.json';
import es from '../../assets/translations/es/es.json';
import zh from '../../assets/translations/zh/zh.json';
import ja from '../../assets/translations/ja/ja.json';
import he from '../../assets/translations/he/he.json';
import de from '../../assets/translations/de/de.json';
import nl from '../../assets/translations/nl/nl.json';
import ko from '../../assets/translations/ko/ko.json';

const EN = { en };
const EN_AU = { 'en-AU': enAU };
const EN_GB = { 'en-GB': enGB };
const FR = { fr };
const FR_CA = { 'fr-CA': frCA };
const ES = { es };
const ZH_CN = { 'zh-CN': zh };
const ZH = { zh };
const JA = { ja };
const HE = { he };
const DE = { de };
const NL = { nl };
const KO = { ko };

i18n.use(initReactI18next).init({
  resources: {
    ...EN,
    ...EN_AU,
    ...EN_GB,
    ...FR,
    ...FR_CA,
    ...ES,
    ...ZH_CN,
    ...ZH,
    ...JA,
    ...HE,
    ...DE,
    ...NL,
    ...KO,
  },
  fallbackLng: 'en',
  lng: browserLanguage,
  debug: __DEV__,
});

document.documentElement.lang = browserLanguage;

export default i18n;
