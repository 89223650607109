import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDialog } from '@engage-web/utils/hooks';
import { Overlay } from './styled';

export function Dialog() {
  const { isOpen, closeDialog } = useDialog();

  return (
    <>
      <div id="dialog-root" />
      <CSSTransition unmountOnExit in={isOpen} timeout={200} classNames="overlay">
        <Overlay onClick={() => closeDialog(false)} />
      </CSSTransition>
    </>
  );
}
