import React, { memo, MouseEvent, ReactElement, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tenantSelectors, uiActions, uiSelectors } from '../../../../../store';
import { getInfoCardTitle, useSetPageTitle } from '../../../../../utils/hooks';
import { getBuildingAndFloorNames } from '../../../../../utils/helpers';
import { DrawerMenu, MenuProps } from '../DrawerMenu/DrawerMenu';
import { testProps } from '../../../../../utils';
import { useDrawerContext } from '../../../../scenes';
import { isElectron } from '../../../../../utils/electron';
import { CloseButton } from './CloseButton';
import { MenuButton } from './MenuButton';
import { Title } from './Title';
import {
  ButtonsWrapper,
  StyledSubtitleButton,
  TitleFirstRowAndIconWrapper,
  TitleFirstRowWrapper,
  TitleSecondRow,
  Wrapper,
} from './styled';

export type DrawerHeaderProps = {
  /**
   * Title string to display or false if we need to hide it.
   * Do not set this to `true` because it won't make sense.
   */
  title?: string | boolean;
  /**
   * Subtitle string to display or false if we need to hide it.
   * Do not set this to `true` because it won't make sense.
   */
  subtitle?: string | boolean;
  /**
   * Icon which will be displayed on the left side of the card.
   */
  icon?: ReactNode;
  /**
   * If set to true – we show cross icon, otherwise – chevron-right.
   */
  isCloseIcon?: boolean;
  /**
   * Label to be used as an `aria-label` when `isCloseIcon` is true.
   */
  closeIconA11yLabel?: string;
  /**
   * Callback to invoke on the close button click.
   */
  onPress?: (event: MouseEvent<HTMLAnchorElement>) => void;
  /**
   * Path which will be passed to the close button link.
   */
  closeLinkPath?: string;
  /**
   * If this is true – button on the right side will be hidden.
   */
  hasButton?: boolean;
  /**
   * If this is true – display menu.
   */
  menu?: MenuProps;
  renderSubtitle?: (subtitle: string) => ReactElement;
};

// TODO show person location if it's a team member
export const DrawerHeader = memo(
  ({
    title = true,
    subtitle = true,
    icon = null,
    isCloseIcon,
    closeLinkPath,
    onPress,
    hasButton = true,
    menu,
    closeIconA11yLabel,
    renderSubtitle,
  }: DrawerHeaderProps) => {
    // hide More menu button for kiosks
    const isKiosk = isElectron();
    const isMoreMenuVisible = useSelector(uiSelectors.getDrawerMenuVisibility) && !isKiosk;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { setMinimized, minimized } = useDrawerContext();

    const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
    const { buildingName: defaultTitle, floorName: defaultSubtitle } = getBuildingAndFloorNames(
      building,
      floor,
      t,
    );

    const _title = typeof title === 'string' ? title : defaultTitle;
    const _subtitle = typeof subtitle === 'string' ? subtitle : defaultSubtitle;

    const onCloseButtonClick = (e: MouseEvent<HTMLAnchorElement>) => {
      if (isMoreMenuVisible) {
        e.preventDefault();
        dispatch(uiActions.setDrawerMenuVisibility(false));
        return;
      }

      if (minimized) {
        setMinimized(false);
      }

      onPress?.(e);
    };

    const getMenuButton = () => {
      return menu && !isKiosk ? <MenuButton isMenuVisible={isMoreMenuVisible} /> : null;
    };

    const getCloseButton = () => (
      <CloseButton
        isCloseIcon={isCloseIcon}
        closeLinkPath={closeLinkPath}
        onPress={onCloseButtonClick}
        isMoreMenuVisible={isMoreMenuVisible}
        closeIconA11yLabel={closeIconA11yLabel}
      />
    );

    useSetPageTitle(getInfoCardTitle({ title: _title, subtitle: _subtitle }));

    return (
      <Wrapper>
        <h2>
          <TitleFirstRowWrapper>
            <TitleFirstRowAndIconWrapper data-testid="info-card-title">
              {icon}
              <Title>{!!title && _title}</Title>
            </TitleFirstRowAndIconWrapper>
            <ButtonsWrapper>
              {menu && <span className="button-wrapper">{getMenuButton()}</span>}
              {hasButton && <span className="button-wrapper">{getCloseButton()}</span>}
            </ButtonsWrapper>
          </TitleFirstRowWrapper>

          <StyledSubtitleButton {...testProps(t, 'accessibilityLabels.buildingGeoLocation').props}>
            {renderSubtitle ? (
              renderSubtitle(_subtitle)
            ) : (
              <TitleSecondRow>{!!subtitle && _subtitle}</TitleSecondRow>
            )}
          </StyledSubtitleButton>
        </h2>
        {menu && <DrawerMenu menu={menu} />}
      </Wrapper>
    );
  },
);

export default DrawerHeader;
