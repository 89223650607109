import React, { memo } from 'react';
import { useConfig, useSetPageTitle } from '@engage-web/utils/hooks';
import { useTranslation } from 'react-i18next';
import { showAcknowledgementUrlButton } from '@engage-shared/utils';
import { MarkDownText } from '@engage-web/components/base';
import { useTheme } from 'styled-components';
import ModalButton from '@engage-web/components/base/ModalButton/ModalButton';
import {
  AcknowledgementButtonContainer,
  AcknowledgementContainer,
  AcknowledgementLearnMoreButton,
  AcknowledgementMessageContainer,
  AcknowledgementTitle,
} from './styled';

interface OnAcceptParams {
  acknowledgementUrl: string;
  acknowledgementMessage: string;
  acknowledgementTitle: string;
}

interface AcknowledgementProps {
  onAccept: (params: OnAcceptParams) => void;
}

const Acknowledgement = ({ onAccept }: AcknowledgementProps) => {
  const { t } = useTranslation();

  const { acknowledgementMessage, acknowledgementUrl, acknowledgementTitle } = useConfig();
  const showLearnMoreButton = showAcknowledgementUrlButton(acknowledgementUrl);
  const onAcceptOrganizationPolicy = () => {
    onAccept({
      acknowledgementMessage,
      acknowledgementUrl,
      acknowledgementTitle,
    });
  };

  const title = acknowledgementTitle || t('layout.acknowledgement.title');

  useSetPageTitle(t('documentTitles.acknowledgement'));

  const { typo2 } = useTheme();
  const messageStyle = {
    fontSize: '16px',
    color: typo2,
    paddingRight: '20px',
  };

  return (
    <AcknowledgementContainer data-testid="acknowledgement-container">
      <AcknowledgementTitle>{title}</AcknowledgementTitle>
      <AcknowledgementMessageContainer>
        <MarkDownText style={messageStyle}>{acknowledgementMessage}</MarkDownText>
      </AcknowledgementMessageContainer>
      <AcknowledgementButtonContainer>
        {showLearnMoreButton && (
          <AcknowledgementLearnMoreButton
            data-testid="acknowledgement-learn-more"
            target="_blank"
            rel="noreferrer"
            href={acknowledgementUrl}
          >
            {t('layout.acknowledgement.learnMore')}
          </AcknowledgementLearnMoreButton>
        )}
        <ModalButton
          text={t('layout.acknowledgement.agree')}
          data-testid="acknowledgement-button"
          onClick={() => onAcceptOrganizationPolicy()}
        />
      </AcknowledgementButtonContainer>
    </AcknowledgementContainer>
  );
};

export default memo(Acknowledgement);
