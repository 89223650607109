import React, { useState } from 'react';
import {
  getKioskConfigQueryKey,
  KioskRemoteConfig,
  useAvailableKiosks,
} from '@engage-web/api/queries';
import { useQueryClient } from 'react-query';
import { Mask } from '@engage-web/components/base';
import { useLoadKioskConfigDetails } from '@engage-web/utils/hooks/useLoadKioskConfigDetails';
import logger from '@engage-web/utils/logger';
import { inAppNotificationsActions } from '@engage-web/store';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AvailableKiosksErrorDialog } from '@engage-web/components/kiosk';
import { useResetKioskConfig } from '@engage-web/utils/hooks/useResetKioskConfig';
import { Wrapper, ListWrapper, Header } from './AvailableKiosks.styled';
import { AvailableKioskBuildings } from './AvailableKioskBuildings';
import { AvailableKiosksContext } from './AvailableKiosksContext';

type AvailableKiosksProps = {
  onSuccessfullySelectKiosk?: () => void;
};

export const AvailableKiosks = ({ onSuccessfullySelectKiosk }: AvailableKiosksProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDetailsLoading, setDetailsLoading] = useState(false);
  const { data, isLoading, isError, refetch } = useAvailableKiosks();
  const { loadConfigDetails } = useLoadKioskConfigDetails();
  const { resetKioskConfig } = useResetKioskConfig();

  const showErrorNotification = () => {
    dispatch(
      inAppNotificationsActions.addErrorNotification({
        message: t('layout.notifications.wrongKioskConfig'),
      }),
    );
  };

  const onSelectConfig = async (config: KioskRemoteConfig) => {
    try {
      setDetailsLoading(true);
      await resetKioskConfig(false);
      queryClient.setQueryData(getKioskConfigQueryKey(config.id), config);
      await loadConfigDetails(config);
      onSuccessfullySelectKiosk?.();
    } catch (e) {
      logger.error(e)();
      showErrorNotification();
    } finally {
      setDetailsLoading(false);
    }
  };

  if (isLoading || isDetailsLoading) {
    return <Mask isLoading />;
  }

  if (isError) {
    return <AvailableKiosksErrorDialog onRetryPress={refetch} />;
  }

  if (!data) {
    return (
      <AvailableKiosksErrorDialog
        onRetryPress={refetch}
        title={t('components.availableKiosks.noKioskFound')}
      />
    );
  }

  return (
    <AvailableKiosksContext.Provider value={{ onSelectConfig }}>
      <Wrapper>
        <Header>{t('components.availableKiosks.header')}</Header>
        <ListWrapper>
          <AvailableKioskBuildings data={data} />
        </ListWrapper>
      </Wrapper>
    </AvailableKiosksContext.Provider>
  );
};
