const convertHexToRGBArray = (hex: string): number[] =>
  hex.match(/\w\w/g)!.map((x: string) => parseInt(x, 16));

const convertToHex = (num: number): string => num.toString(16).padStart(2, '0');

const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;

const generateInactiveColor = (activeColor: string, alpha = 1): string => {
  const [r, g, b] = convertHexToRGBArray(activeColor);

  // The background is black
  const [r2, g2, b2] = [0, 0, 0];

  const ria = r2 + (r - r2) * alpha;
  const gia = g2 + (g - g2) * alpha;
  const bia = b2 + (b - b2) * alpha;

  const hexValue = [ria, gia, bia].map(Math.ceil).map(convertToHex).join('').toUpperCase();

  return `#${hexValue}`;
};

const isValidHexColor = (color: string | undefined): boolean => {
  if (typeof color === 'string') {
    return colorRegex.test(color);
  }
  return false;
};

/**
 * Get the contrasting color for any hex color.
 * @param bgColor A hexColor color value
 * @param lightColor A light color value
 * @param darkColor A dark color value
 * @return {String} The contrasting color (darkColor or lightColor)
 */
const getContrastingColor = (bgColor: string, lightColor: string, darkColor: string): string => {
  let hexColor = bgColor;
  // If a leading # is provided, remove it
  if (hexColor.slice(0, 1) === '#') {
    hexColor = hexColor.slice(1);
  }

  // If a three-character hexCode, make six-character
  if (hexColor.length === 3) {
    hexColor = [...hexColor].map(x => `${x}${x}`).join('');
  }

  // Convert to RGB value
  const [r, g, b] = convertHexToRGBArray(hexColor);

  // Get YIQ (color space) ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? darkColor : lightColor;
};

export { generateInactiveColor, isValidHexColor, getContrastingColor };
