import styled from 'styled-components';
import { Button } from '@engage-web/components/base';

type ActivableElement = {
  $isActive?: boolean;
};

export const LocationSelectorWrapper = styled.div`
  max-height: calc(100vh - 400px);
  width: 100%;
  overflow-y: scroll;
  padding: 30px 20px 0 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.typo2};
  border-top: 1px solid ${({ theme }) => theme.color4};
  @media (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const RegionHeader = styled(Button)<ActivableElement>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  border-radius: 6px;
  background: ${({ $isActive, theme }) => ($isActive ? theme.typo2 : 'white')};
  cursor: pointer;
  color: ${({ $isActive, theme }) => ($isActive ? theme.typo1 : theme.typo2)};
  & .region-name {
    font-weight: 600;
    font-size: 17px;
    font-style: normal;
    line-height: 22px;
  }
  & .region-icon {
    transform-origin: ${({ $isActive }) => ($isActive ? '5px center' : '2px center')};
    transition: transform 200ms;
    transform: rotate(${({ $isActive }) => ($isActive ? '-90deg' : '90deg')});
  }
  & + div {
    margin-top: 10px;
  }
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const BuildingsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  border-radius: 0 0 6px 6px;
  border-top: none;
  margin-top: 0 !important;
`;

export const RegionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color4};
  border-radius: 6px;
  flex-shrink: 0;
  margin: 0 10px 10px 10px;
  // Fix for border that appears on top of region wrapper's skeleton
  & > span {
    line-height: 0;
  }
`;

export const BuildingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 15px 0;
`;

export const BuildingName = styled.button<ActivableElement>`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme, $isActive }) => ($isActive ? theme.typo2 : theme.gray4)};
  padding-left: 0;
  text-align: start;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const FloorsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelFloorAvailability = styled.span`
  font-size: 8px;
  text-transform: uppercase;
  opacity: 0.4;
`;

export const FloorWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin: 0 -20px;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color4};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FloorName = styled.button<ActivableElement>`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme, $isActive }) => ($isActive ? theme.typo2 : theme.gray4)};
  padding-left: 0;
  text-align: start;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
