import { isSameDay, isValid, isWithinInterval } from 'date-fns';
import moment, { Moment } from 'moment-timezone';
import { checkOrParse, roundTimeToInterval } from './dateHelpers';
import { AnyDate, DateISODate, DateTimeZone, Nullable, TimeString } from '../types';
import { RoundingDirection } from '../../constants';

export const getLocalizedDate = ({ date, timeZone }: DateTimeZone<AnyDate>): Moment => {
  const parsedDate = moment(date, moment.ISO_8601);
  if (!parsedDate.isValid()) return moment();

  if (timeZone) {
    return parsedDate.tz(timeZone);
  }

  return parsedDate;
};

export const setLocalizedHoursAndMinutes = ({
  date,
  timeZone,
  time,
}: DateTimeZone & {
  time: TimeString;
}): Nullable<Moment> => {
  const localizedDate = getLocalizedDate({ date, timeZone });
  const timeRegex = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
  if (!localizedDate || !time || !time.match(timeRegex)) return null;

  // time value is in format 'HH:mm'
  const [hours, minutes]: [string, string] = time.split(':') as [string, string];
  return localizedDate.hours(parseInt(hours, 10)).minutes(parseInt(minutes, 10)).seconds(0);
};

/**
 * Apply hours, minutes, seconds, milliseconds to moment or date object.
 * @param date moment object
 * @param hours
 * @param minutes
 * @param seconds
 * @param milliseconds
 */
const setHours = (
  date: Moment,
  hours: number,
  minutes?: number,
  seconds?: number,
  milliseconds?: number,
): void => {
  if (moment.isMoment(date)) {
    if (Number.isInteger(hours)) {
      date.hours(hours);
    }

    if (minutes != null && Number.isInteger(minutes)) {
      date.minutes(minutes);
    }

    if (seconds != null && Number.isInteger(seconds)) {
      date.seconds(seconds);
    }

    if (milliseconds != null && Number.isInteger(milliseconds)) {
      date.milliseconds(milliseconds);
    }
  }
};

/**
 * For current date all day selection starts from current time rounded up,
 * while for other dates is starts at 00:00 for selected timeZone.
 * Not passing bookingIntervalSize makes it return localize 00:00 for current day as well,
 * behaviour used by multi day selector.
 * @param date date object or ISO date string
 * @param bookingIntervalSize
 * @param timeZone
 * @param roundingDirection 'up' or 'down'
 * @param noRounding: when true, no rounding is applied for today
 * @returns date object, it uses current date if no date provided
 */
export const getDayStartTime = ({
  date,
  bookingIntervalSize,
  timeZone,
  roundingDirection,
  noRounding,
}: {
  date: DateISODate;
  bookingIntervalSize?: number;
  timeZone?: string;
  roundingDirection?: RoundingDirection;
  noRounding?: boolean;
}): Date => {
  let parsedDate = checkOrParse(date);
  if (!isValid(parsedDate)) {
    parsedDate = new Date();
  }

  // default direction is 'up', used also for invalid argument value
  const direction =
    roundingDirection === RoundingDirection.DOWN ? RoundingDirection.DOWN : RoundingDirection.UP;

  let dateS: Date | null = null;

  if (isToday(parsedDate, timeZone) && bookingIntervalSize) {
    dateS = noRounding
      ? new Date()
      : roundTimeToInterval(new Date(), bookingIntervalSize, direction, timeZone);
  } else {
    const localizedDate: Moment | null = getLocalizedDate({
      date: parsedDate,
      timeZone,
    });
    if (localizedDate) {
      setHours(localizedDate, 0, 0, 0, 0);
      dateS = localizedDate.toDate();
    }
  }

  if (dateS === null) {
    dateS = noRounding
      ? new Date()
      : roundTimeToInterval(new Date(), bookingIntervalSize, direction, timeZone);
  }

  return dateS;
};

// Changed return from null to current day for invalid date input, for consistency with getDayStartTime
/**
 * For all day selection day ends at 23:59, selected timeZone or user's timezone if none is set.
 * @param date date object or ISO date string
 * @param timeZone
 * @returns date object, it uses current date if no date provided
 */
export const getDayEndTime = ({ date, timeZone }: DateTimeZone): Date => {
  let parsedDate = checkOrParse(date);
  if (!isValid(parsedDate)) {
    parsedDate = new Date();
  }

  const localizedDate: Moment | null = getLocalizedDate({
    date: parsedDate,
    timeZone,
  })!;

  setHours(localizedDate, 23, 59, 59, 0);
  return localizedDate.toDate();
};

/**
 * Check if argument dates are start and end of the same day.
 * @param dateS Date object, Moment object or ISO string
 * @param dateE Date object, Moment object or ISO string
 * @param timeZone
 */
export const isAllDayBookingLocalized = ({
  dateS,
  dateE,
  timeZone,
}: {
  dateS: AnyDate;
  dateE: AnyDate;
  timeZone?: string;
}): boolean => {
  const dateStart = getLocalizedDate({ date: dateS, timeZone });
  const dateEnd = getLocalizedDate({ date: dateE, timeZone });
  if (!dateStart || !dateEnd || !dateStart.isSame(dateEnd, 'day')) {
    return false;
  }

  return (
    dateStart.isSame(dateStart.clone().startOf('day'), 'minute') &&
    dateEnd.isSame(dateEnd.clone().endOf('day'), 'minute')
  );
};

/**
 * Check if argument dates are in the same day.
 * @param date1 Date object, Moment object or ISO string
 * @param date2 Date object, Moment object or ISO string
 * @param timeZone
 */
export const isSameLocalizedDay = (
  date1: DateISODate,
  date2: DateISODate,
  timeZone?: string,
): boolean => {
  if (!date1 || !date2) return false;

  const parsedDate1: Date = checkOrParse(date1);
  const parsedDate2: Date = checkOrParse(date2);

  if (!timeZone) {
    return isSameDay(parsedDate1, parsedDate2);
  }

  if (!moment(parsedDate1).isValid() || !moment(parsedDate2).isValid()) {
    return false;
  }

  return moment.tz(date1, timeZone).isSame(moment.tz(date2, timeZone), 'day');
};

/**
 * Get time from date after applying prevTimeZone and return a date with the same time in the newTimeZone.
 * @param date
 * @param prevTimeZone
 * @param newTimeZone
 * @return the date argument if something goes wrong, or the calculated new date
 */
export const copyLocalizedTime = ({
  date,
  prevTimeZone,
  newTimeZone,
}: {
  date: AnyDate;
  prevTimeZone: string;
  newTimeZone: string;
}): AnyDate => {
  if (!prevTimeZone || !newTimeZone) return date;

  const localizedDate = getLocalizedDate({ date, timeZone: prevTimeZone });

  if (localizedDate) {
    // On passing a second parameter as true, only the timeZone (and offset) is updated, keeping the local time same
    localizedDate.tz(newTimeZone, true);
    return localizedDate.toDate();
  }
  return date;
};

/** Check if a date is future time for current date. Used for timeline date to check if it selected, and it is later today.
 * When timeline is cleared, startDate is null, but when use selects the current interval, then Now is displayed but startDate has value.
 **/
export const isLaterToday = (date?: Nullable<Date>, timeZone?: string): boolean => {
  // timeline date not selected
  if (!date || !isValid(date)) return false;

  const isNow = isWithinInterval(date, {
    start: roundTimeToInterval(new Date(), 15, RoundingDirection.DOWN),
    end: roundTimeToInterval(new Date(), 15, RoundingDirection.UP),
  });

  // selected timeline date is Now, is current interval
  if (isNow) return false;

  const localizedDateS = getLocalizedDate({ date: date, timeZone });
  const localizedToday = getLocalizedDate({ date: new Date(), timeZone });

  // return true if selected timeline date is today (timezone applied)
  return !!localizedDateS && localizedDateS.isSame(localizedToday, 'day');
};

export const isToday = (date?: Nullable<Date>, timeZone?: string): boolean => {
  // timeline date not selected
  if (!date || !isValid(date)) return false;

  const localizedDateS = getLocalizedDate({ date: date, timeZone });
  const localizedToday = getLocalizedDate({ date: new Date(), timeZone });

  return !!localizedDateS && localizedDateS.isSame(localizedToday, 'day');
};
