import { useEffect } from 'react';
import { floorplanActions, floorplanSelectors, uiSelectors } from '@engage-web/store';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { useDispatch, useSelector } from 'react-redux';
import { FLOORPLAN_VIEWS } from '@engage-web/constants';

export const useShowHeatmap = () => {
  const fes = useFESContext();
  const dispatch = useDispatch();
  const floorplanIsLoaded = useSelector(floorplanSelectors.getIsLoaded);
  const heatmapVisible = useSelector(uiSelectors.getFloorplanView) === FLOORPLAN_VIEWS.HEATMAP;

  useEffect(() => {
    if (floorplanIsLoaded) {
      if (heatmapVisible) {
        dispatch(floorplanActions.setFocusedSpace(null));
        setTimeout(() => fes.trigger(FES_EVENTS.SHOW_HEATMAP), 500);
      } else {
        fes.trigger(FES_EVENTS.HIDE_HEATMAP);
      }
    }
  }, [heatmapVisible, fes, floorplanIsLoaded, dispatch]);
};
