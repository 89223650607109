import * as React from 'react';
import OpenSeadragon from 'openseadragon';

import { getCoordinateString, getPathString } from '../../utils';
import { DEFAULT_FLOORPLAN_SCALE, doubleClickTime, DEFAULT_CIRCLE_SIZE } from '../../constants';
import { calculateStyle } from './utils';
import { EventInfo } from '../../types';
import ClickableShape from './ClickableShape';
import { ShapeProps } from './types';

export const Shape = ({
  space,
  shape,
  isPoint = false,
  getPointSize,
  style,
  scale,
  styleFunction,
  onShapeClicked,
  isSelected = false,
}: ShapeProps) => {
  const waitIfDoubleTimeoutRef = React.useRef(-1);
  const selectedString = isSelected ? '-selected' : '';

  const shapeStyle = calculateStyle<React.CSSProperties>({
    space,
    shape,
    isPoint,
    style,
    styleFunction,
  });

  const clickHandler = React.useCallback(
    (event: OpenSeadragon.CanvasClickEvent) => {
      if (waitIfDoubleTimeoutRef.current !== -1) {
        clearTimeout(waitIfDoubleTimeoutRef.current);
        waitIfDoubleTimeoutRef.current = -1;
        return;
      }

      waitIfDoubleTimeoutRef.current = setTimeout(() => {
        const eventInfo = {
          originalEvent: event.originalEvent,
          space,
          shape,
        } as EventInfo;
        if (onShapeClicked) {
          onShapeClicked(eventInfo);
        }
        waitIfDoubleTimeoutRef.current = -1;
      }, doubleClickTime) as unknown as number;
    },
    [onShapeClicked, shape, space],
  );

  let elementId = `space-${space.id}-desk${selectedString}`;
  if (shape?.holes) {
    elementId = `space-${space.id}-holes`;
  } else if (shape?.isPolygon) {
    elementId = `space-${space.id}-room${selectedString}`;
  }

  const renderElement = () => {
    if (shape.holes) {
      return (
        <path
          id={elementId}
          d={`${getPathString(shape.coordinates)} ${shape.holes
            .map(hole => getPathString(hole))
            .join(' ')} z`}
          style={shapeStyle}
        />
      );
    }

    if (shape.isPolygon) {
      return <polygon id={elementId} points={getCoordinateString(shape)} style={shapeStyle} />;
    }

    const pointSize = getPointSize?.(space) ?? DEFAULT_CIRCLE_SIZE;

    return (
      <circle
        id={elementId}
        data-space-id={`${space.id}`}
        cx={shape.coordinates[0].x}
        cy={shape.coordinates[0].y}
        // This is calculated here instead of up there since floating point errors can occur at these sizes
        r={Math.ceil((pointSize * DEFAULT_FLOORPLAN_SCALE) / scale)}
        style={shapeStyle}
      />
    );
  };

  if (!shape) {
    return null;
  }

  return (
    <ClickableShape clickHandler={clickHandler} elementId={elementId}>
      {renderElement()}
    </ClickableShape>
  );
};
