import { getApiUrl } from '@engage-shared/api';
import { Api } from '@engage-shared/api';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { KioskRemoteConfig } from './types';

const fetchAvailableKiosks = async (): Promise<KioskRemoteConfig[]> => {
  const url = new URL(`${getApiUrl()}/kiosks`);
  const result = await Api.get(url.toString());
  return result.data.data as KioskRemoteConfig[];
};

export type UseAvailableKiosks = (params?: {
  options?: UseQueryOptions<KioskRemoteConfig[]>;
}) => UseQueryResult<KioskRemoteConfig[]>;

export const useAvailableKiosks: UseAvailableKiosks = ({ options } = {}) =>
  useQuery<KioskRemoteConfig[]>(['availableKiosks'], fetchAvailableKiosks, options);
