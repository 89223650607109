import React from 'react';
import { floorplan, heatmap } from '@engage-web/assets/images';
import { useTranslation } from 'react-i18next';
import { tenantSelectors, uiActions, uiSelectors } from '@engage-web/store';
import { useDispatch, useSelector } from 'react-redux';
import { generateFloorPath, joinPaths } from '@engage-web/router/utils';
import { useNavigate } from 'react-router-dom';
import { FLOORPLAN_VIEWS, PATH_SEGMENT } from '@engage-web/constants';
import { ButtonsWrapper, FloorplanView, FloorplanViewText, PNGImage, Wrapper } from './styled';

export const FloorplanViews = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);
  const activeView = useSelector(uiSelectors.getFloorplanView);

  const floorplanLabel = t('layout.floorplanViews.floorplan');
  const heatmapLabel = t('layout.floorplanViews.heatmap');
  const floorplanImageAlt = t('layout.floorplanViews.floorplanImage');
  const heatmapImageAlt = t('layout.floorplanViews.heatmapImage');

  const onSelectFloorplan = () => {
    dispatch(uiActions.setFloorplanView(FLOORPLAN_VIEWS.FLOORPLAN));
    navigate(
      {
        pathname: generateFloorPath(currentFloorId),
      },
      { replace: true },
    );
  };

  const onSelectHeatmap = () => {
    dispatch(uiActions.setFloorplanView(FLOORPLAN_VIEWS.HEATMAP));
    navigate(joinPaths(generateFloorPath(currentFloorId), PATH_SEGMENT.HEATMAP), { replace: true });
  };

  return (
    <Wrapper data-testid="floorplan-views-modal">
      <ButtonsWrapper>
        <FloorplanView onClick={onSelectFloorplan}>
          <PNGImage
            draggable={false}
            src={floorplan}
            alt={floorplanImageAlt}
            $isActive={activeView === FLOORPLAN_VIEWS.FLOORPLAN}
          />
          <FloorplanViewText $isActive={activeView === FLOORPLAN_VIEWS.FLOORPLAN}>
            {floorplanLabel}
          </FloorplanViewText>
        </FloorplanView>

        <FloorplanView onClick={onSelectHeatmap}>
          <PNGImage
            draggable={false}
            src={heatmap}
            alt={heatmapImageAlt}
            $isActive={activeView === FLOORPLAN_VIEWS.HEATMAP}
          />
          <FloorplanViewText $isActive={activeView === FLOORPLAN_VIEWS.HEATMAP}>
            {heatmapLabel}
          </FloorplanViewText>
        </FloorplanView>
      </ButtonsWrapper>
    </Wrapper>
  );
};
