import React from 'react';
import { testProps } from '@engage-web/utils';
import { useTranslation } from 'react-i18next';
import { Container, WorkspaceInput, Domain } from './styled';

type TenantInputProps = {
  onChange: (evt: React.FormEvent<HTMLInputElement>) => void;
  value: string;
};

const TenantInput = ({ onChange, value }: TenantInputProps) => {
  const [hasFocus, setHasFocus] = React.useState(false);
  const onFocus = () => setHasFocus(true);
  const onBlur = () => setHasFocus(false);
  const { t } = useTranslation();
  const {
    props: a11yAndTestProps,
    text: [placeholder],
  } = testProps(t, 'scenes.tenant.workspaceUrlPlaceholder');

  return (
    <Container $hasFocus={hasFocus}>
      <WorkspaceInput
        {...a11yAndTestProps}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
        spellCheck={false}
      />
      <Domain>.serraview.com</Domain>
    </Container>
  );
};

export { TenantInput };
