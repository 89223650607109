// there is a conflict between prettier and eslint formatting
/* eslint-disable react/jsx-wrap-multilines */
import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { teamReservationsSelectors } from '@engage-web/store';
import {
  APP_LAUNCH_INTUNE_PATH,
  APP_LAUNCH_PATH,
  BROWSER_PATH,
  CALLBACK_PATH,
  CONCIERGE_MENU_PATH,
  DEMO_PATH,
  FLOOR_PATH,
  KEYBOARD_ACCESSIBILITY,
  KIOSK_ADMIN,
  LANDING_PATH,
  LOGIN_PATH,
  ORGANIZATION_POLICY_PATH,
  PATH_PARAM,
  PATH_SEGMENT,
  ROOT_PATH,
  SSO_LOGOUT_PATH,
  WAYFINDER_PATH,
  WORKSPACE_PATH,
  WORKSPACE_PATH_INSTANCE,
} from '@engage-web/constants';
import {
  AppLaunch,
  DeskCheckInContainer,
  Employee,
  FirstResponders,
  FloorplanViewsContainer,
  GlobalSearch,
  LocationSelector,
  Person,
  PersonLocations,
  ReservationAssist,
  ReserveeList,
  ReserveSpaceContainer,
  ReserveTeamContainer,
  SpaceAttributes,
  TeamBookingDesks,
  TeamMembersContainer,
} from '@engage-web/components/layouts';
import {
  Browser,
  ConciergeMenu,
  Drawer,
  KeyboardAccessibility,
  KioskConfiguration,
  LandingPage,
  Tenant,
  TenantWorkspace,
  Wayfinder,
} from '@engage-web/components/scenes';
import { isElectron } from '@engage-web/utils/electron';
import {
  AcknowledgementRouter,
  CallbackRouter,
  Demo,
  DrawerRedirect,
  FloorCheckRouter,
  LoginRouter,
  MainRouter,
  PrivateRoute,
  PublicRoute,
  SsoLogoutRouter,
} from './components';

const isKiosk = isElectron();

const {
  SPACES,
  DESKS,
  PEOPLE,
  TEAMS,
  EMPLOYEE,
  FILTERS,
  RESERVEE,
  LOCATIONS,
  LOCATION_SWITCHER,
  FLOORPLAN_VIEWS,
  RESERVE,
  SPACE_ATTRIBUTES,
  HEATMAP,
} = PATH_SEGMENT;
const { SPACE_ID, DESK_ID, PERSON_ID, TEAM_ID, EMPLOYEE_ID } = PATH_PARAM;

/**
 * Main app routing component.
 */
export const Routing = () => {
  const isInDeskCheckInMode = useSelector(teamReservationsSelectors.getIsInDeskCheckInMode);

  const drawerRoutes = useMemo(
    () =>
      isInDeskCheckInMode ? (
        <Route path={`${FLOOR_PATH}/*`}>
          <Route path={`${DESKS}/*`} element={<Drawer />}>
            <Route path={DESK_ID} element={<DeskCheckInContainer />} />
            <Route path={`${DESK_ID}/${SPACE_ATTRIBUTES}`} element={<SpaceAttributes />} />
            <Route index element={<TeamBookingDesks />} />
            <Route path="*" element={<DrawerRedirect path={DESKS} />} />
          </Route>
          <Route path="*" element={<DrawerRedirect path={DESKS} />} />
        </Route>
      ) : (
        <>
          {/* Person without location routes */}
          <Route path={`${PEOPLE}/*`} element={<Drawer />}>
            <Route path={PERSON_ID} element={<Person />} />
            <Route path={`${PERSON_ID}/${LOCATIONS}`} element={<PersonLocations />} />
            <Route path="*" element={<DrawerRedirect />} />
          </Route>

          {/* Floorplan routes */}
          <Route path={`${FLOOR_PATH}/*`}>
            {/* Space routes */}
            <Route path={`${SPACES}/*`} element={<Drawer />}>
              <Route path={FILTERS} element={<ReservationAssist />} />
              <Route path={SPACE_ID} element={<ReserveSpaceContainer />} />
              <Route path={`${SPACE_ID}/${RESERVEE}`} element={<ReserveeList />} />
              <Route path={`${SPACE_ID}/${SPACE_ATTRIBUTES}`} element={<SpaceAttributes />} />
              <Route index element={<GlobalSearch />} />
              <Route path="*" element={<DrawerRedirect path={SPACES} />} />
            </Route>

            {/* Desk routes */}
            <Route path={`${DESKS}/*`} element={<Drawer />}>
              <Route path={FILTERS} element={<ReservationAssist />} />
              <Route path={DESK_ID} element={<ReserveSpaceContainer />} />
              <Route path={`${DESK_ID}/${RESERVEE}`} element={<ReserveeList />} />
              <Route path={`${DESK_ID}/${SPACE_ATTRIBUTES}`} element={<SpaceAttributes />} />
              <Route index element={<GlobalSearch />} />
              <Route path="*" element={<DrawerRedirect path={DESKS} />} />
            </Route>

            {/* People routes */}
            <Route path={`${PEOPLE}/*`} element={<Drawer />}>
              <Route path={FILTERS} element={<FirstResponders />} />
              <Route path={PERSON_ID} element={<Person />} />
              <Route path={`${PERSON_ID}/${LOCATIONS}`} element={<PersonLocations />} />
              <Route index element={<GlobalSearch />} />
              <Route path="*" element={<DrawerRedirect path={PEOPLE} />} />
            </Route>

            {/* Team routes */}
            <Route path={`${TEAMS}/*`} element={<Drawer />}>
              <Route path={TEAM_ID} element={<TeamMembersContainer />} />
              <Route path={`${TEAM_ID}/${RESERVE}`} element={<ReserveTeamContainer />} />
              <Route index element={<GlobalSearch />} />
              <Route path="*" element={<DrawerRedirect path={TEAMS} />} />
            </Route>

            {/* Location selector routes */}
            <Route path={`${LOCATION_SWITCHER}/*`} element={<Drawer />}>
              <Route index element={<LocationSelector />} />
              <Route path="*" element={<DrawerRedirect path={LOCATION_SWITCHER} />} />
            </Route>

            {/* Floorplan views routes */}
            <Route path={`${FLOORPLAN_VIEWS}/*`} element={<Drawer />}>
              <Route index element={<FloorplanViewsContainer />} />
              <Route path="*" element={<DrawerRedirect path={FLOORPLAN_VIEWS} />} />
            </Route>

            {/* Heatmap route */}
            <Route path={`${HEATMAP}/*`} />

            {/* Index route rendering collapsed global search */}
            <Route path="*" element={<Drawer />}>
              <Route index element={<GlobalSearch />} />
            </Route>
          </Route>
        </>
      ),
    [isInDeskCheckInMode],
  );

  const publicRoutes = (
    <>
      <Route
        path={LOGIN_PATH}
        element={
          <PublicRoute>
            <LoginRouter />
          </PublicRoute>
        }
      />

      <Route
        path={SSO_LOGOUT_PATH}
        element={
          <PublicRoute>
            <SsoLogoutRouter />
          </PublicRoute>
        }
      />

      <Route
        path={CALLBACK_PATH}
        element={
          <PublicRoute>
            <CallbackRouter />
          </PublicRoute>
        }
      />

      <Route
        path={WORKSPACE_PATH}
        element={
          <PublicRoute>
            <Tenant />
          </PublicRoute>
        }
      />

      <Route
        path={WORKSPACE_PATH_INSTANCE}
        element={
          <PublicRoute>
            <TenantWorkspace />
          </PublicRoute>
        }
      />

      <Route
        path={`${APP_LAUNCH_PATH}/*`}
        element={
          <PublicRoute>
            <AppLaunch />
          </PublicRoute>
        }
      />

      <Route
        path={`${APP_LAUNCH_INTUNE_PATH}/*`}
        element={
          <PublicRoute>
            <AppLaunch inTune />
          </PublicRoute>
        }
      />
    </>
  );

  const privateRoutes = (
    <>
      <Route
        element={
          <PrivateRoute>
            {/* Returns Main after the floor is checked */}
            <FloorCheckRouter />
          </PrivateRoute>
        }
      >
        {drawerRoutes}
      </Route>

      {/* Kiosk admin routes */}
      {isKiosk && (
        <Route
          path={KIOSK_ADMIN}
          element={
            <PrivateRoute>
              <KioskConfiguration />
            </PrivateRoute>
          }
        />
      )}

      {/* Wayfinder routes */}
      <Route
        path={`${WAYFINDER_PATH}/*`}
        element={
          <PrivateRoute>
            <Wayfinder />
          </PrivateRoute>
        }
      >
        <Route element={<Drawer />}>
          <Route index element={<LocationSelector />} />
          <Route path="*" element={<Navigate to={WAYFINDER_PATH} />} />
        </Route>
      </Route>

      {/* Employee route which redirects to the person with or without location route */}
      <Route
        path={`${EMPLOYEE}/${EMPLOYEE_ID}`}
        element={
          <PrivateRoute>
            <Employee />
          </PrivateRoute>
        }
      />

      <Route
        path={DEMO_PATH}
        element={
          <PrivateRoute>
            <Demo />
          </PrivateRoute>
        }
      />

      <Route
        path={ORGANIZATION_POLICY_PATH}
        element={
          <PrivateRoute>
            <AcknowledgementRouter />
          </PrivateRoute>
        }
      />

      <Route
        path={LANDING_PATH}
        element={
          <PrivateRoute>
            <LandingPage />
          </PrivateRoute>
        }
      />

      <Route
        path={CONCIERGE_MENU_PATH}
        element={
          <PrivateRoute>
            <ConciergeMenu />
          </PrivateRoute>
        }
      />

      <Route
        path={BROWSER_PATH}
        element={
          <PrivateRoute>
            <Browser />
          </PrivateRoute>
        }
      />

      {/* Redirects link to floor path or returns Main */}
      <Route
        path={ROOT_PATH}
        element={
          <PrivateRoute>
            <MainRouter />
          </PrivateRoute>
        }
      />

      {/* Keyboard accessibility documentation scene */}
      <Route
        path={KEYBOARD_ACCESSIBILITY}
        element={
          <PrivateRoute>
            <KeyboardAccessibility />
          </PrivateRoute>
        }
      />
    </>
  );

  return (
    <Routes>
      {publicRoutes}
      {privateRoutes}
      <Route path="*" element={<Navigate to={ROOT_PATH} />} />
    </Routes>
  );
};
