import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { getBookingsFetcher, GetBookingsFetcherResponse, getBookingTimeRange } from './utils';
import { BOOKINGS_QUERY_KEY } from '@engage-shared/api/bookings/constants';
import { ParsedSpaceItem } from '@engage-shared/api/spaces/interfaces';
import { TenantId } from '@engage-shared/api/tenant/interfaces';
import { UseQueryResult } from 'react-query/types';

type ReservationFilters = {
  dateStart: Date;
  dateEnd: Date;
  allDay: boolean;
  bookingIntervalSize: number;
  enforceAllDayDeskReservations: boolean;
};

type UseBookingsQueryParams = {
  timeZone: string;
  reservationFilters: ReservationFilters;
  tenantId: TenantId;
  spaceItem: ParsedSpaceItem | undefined;
  options?: UseQueryOptions<GetBookingsFetcherResponse>;
};

type UseBookingsQuery = (
  params: UseBookingsQueryParams,
) => UseQueryResult<GetBookingsFetcherResponse>;

export const useBookingsQuery: UseBookingsQuery = ({
  tenantId,
  spaceItem,
  timeZone,
  reservationFilters,
  options = {},
}: UseBookingsQueryParams) => {
  const { dateStart, dateEnd, allDay, bookingIntervalSize, enforceAllDayDeskReservations } =
    reservationFilters;

  const { startTime, endTime } = getBookingTimeRange({
    dateStart,
    dateEnd,
    showDaysSelector: spaceItem?.showDaysSelector ?? false,
    bookingIntervalSize,
    allDay,
    timeZone,
  });

  return useQuery({
    queryKey: [BOOKINGS_QUERY_KEY, spaceItem?.id ?? '', startTime, endTime],
    queryFn: () =>
      getBookingsFetcher({
        tenantId,
        spaceItem,
        startTime,
        endTime,
        dateStart,
        bookingIntervalSize,
        enforceAllDayDeskReservations,
      }),
    ...options,
  });
};
