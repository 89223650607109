import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetchRegions, fetchRegionsBuildings, fetchRegionsChildren } from '../fetch';
import {
  REGIONS_BUILDINGS_QUERY_KEY,
  REGIONS_CHILDREN_QUERY_KEY,
  REGIONS_QUERY_KEY,
} from '../constants';
import { FetchBuildingsParams, FetchParams, Region } from '../interfaces';
import { Nullable } from '@engage-shared/utils/types';

const STALE_TIME = 30 * 60 * 1000; // Refetch after 30 minutes
const queryOptions = {
  cacheTime: Infinity,
  staleTime: STALE_TIME,
};

type UseRegionsQueryParams = FetchParams & {
  options?: UseQueryOptions;
};

type UseRegionsBuildingsParams = FetchBuildingsParams & {
  options?: UseQueryOptions;
};

type UseRegionsQuery = (params: UseRegionsQueryParams) => UseQueryResult;

export const useRegions: UseRegionsQuery = ({ tenantId, options, shouldSortRegions }) => {
  return useQuery({
    queryKey: [REGIONS_QUERY_KEY, tenantId],
    queryFn: () => fetchRegions({ tenantId, shouldSortRegions }),
    enabled: !!tenantId,
    ...queryOptions,
    ...options,
  });
};

type UseRegionsFetch = (params: FetchParams) => {
  fetchRegionsQuery: () => Promise<Region[]>;
};

export const useRegionsFetch: UseRegionsFetch = ({ tenantId, shouldSortRegions }) => {
  const queryClient = useQueryClient();

  const query = (): Promise<Region[]> =>
    queryClient.fetchQuery(
      [REGIONS_QUERY_KEY, tenantId],
      () => fetchRegions({ tenantId, shouldSortRegions }),
      {
        ...queryOptions,
      },
    );
  const fetchRegionsQuery = useCallback(query, [queryClient, tenantId]);
  return { fetchRegionsQuery };
};

interface UseRegionsChildrenParams {
  tenantId: string;
  regionId: number;
  shouldSortRegions: boolean;
  options?: UseQueryOptions<Region[] | null, unknown, Region[] | null>;
}

export const useRegionsChildren = ({
  tenantId,
  regionId,
  shouldSortRegions,
  options = {},
}: UseRegionsChildrenParams) => {
  return useQuery<Region[] | null, unknown, Region[] | null>(
    [REGIONS_CHILDREN_QUERY_KEY, tenantId, `${regionId}`],
    () => fetchRegionsChildren({ tenantId, regionId, shouldSortRegions }),
    {
      enabled: !!tenantId && !!regionId,
      ...queryOptions,
      ...options,
    },
  );
};

export const useRegionsChildrenFetch = ({
  tenantId,
  shouldSortRegions,
}: {
  tenantId: Nullable<string>;
  shouldSortRegions: boolean;
}) => {
  const queryClient = useQueryClient();

  const query = ({ id }: { id: number }): Promise<Region[]> =>
    queryClient.fetchQuery(
      [REGIONS_CHILDREN_QUERY_KEY, tenantId, id],
      () => fetchRegionsChildren({ tenantId, regionId: id, shouldSortRegions }),
      {
        ...queryOptions,
      },
    );
  const fetchRegionsChildrenQuery = useCallback(query, [queryClient, shouldSortRegions, tenantId]);
  return { fetchRegionsChildrenQuery };
};

type UseRegionsBuildingsQuery = (params: UseRegionsBuildingsParams) => UseQueryResult;

export const useRegionsBuildings: UseRegionsBuildingsQuery = ({
  tenantId,
  regionId,
  sortBuildings = true,
  options = {},
}) => {
  return useQuery({
    queryKey: [REGIONS_BUILDINGS_QUERY_KEY, tenantId, regionId],
    queryFn: () => fetchRegionsBuildings({ regionId, sortBuildings }),
    enabled: !!tenantId && !!regionId,
    ...queryOptions,
    ...options,
  });
};

export const useRegionsBuildingsFetch = ({ tenantId }: { tenantId: Nullable<string> }) => {
  const queryClient = useQueryClient();

  const query = ({ id }: { id: number }): Promise<Region[]> =>
    queryClient.fetchQuery(
      [REGIONS_BUILDINGS_QUERY_KEY, tenantId, id],
      () => fetchRegionsBuildings({ regionId: id }),
      {
        ...queryOptions,
      },
    );
  const fetchRegionsBuildingsQuery = useCallback(query, [queryClient, tenantId]);
  return { fetchRegionsBuildingsQuery };
};
