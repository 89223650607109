import { useTheme } from 'styled-components';

export const useSelectedStylesTimeSelect = (isTimeFormatH12: boolean) => {
  const { typo2, color3, color4, gray4, color5, color6 } = useTheme();
  return {
    container: (base: any) => ({
      ...base,
      width: '100%',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 19,
      color: typo2,
      paddingTop: 10,
      paddingBottom: 5,
    }),
    valueContainer: (base: any) => ({
      ...base,
      justifyContent: 'center',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      color: typo2,
    }),
    control: (base: any) => ({
      ...base,
      direction: 'ltr',
      marginLeft: 8,
      borderWidth: 1,
      borderColor: color5,
      borderRadius: 6,
      paddingRight: 8,
      backgroundColor: isTimeFormatH12 ? color5 : color6,
      width: 80,
      // Removes weird border around container
      boxShadow: null,
      outline: 0,
      '&:focus-within': {
        borderColor: gray4,
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: 0,
      color: typo2,
      alignItems: 'center',
      justifyContent: 'center',
      width: 16,
      height: 16,
    }),
    option: (
      base: any,
      {
        isFocused,
        isSelected,
        isDisabled,
      }: { isFocused: boolean; isSelected: boolean; isDisabled: boolean },
    ) => ({
      ...base,
      background: isFocused ? color3 : color4,
      color: isDisabled ? gray4 : typo2,
      fontWeight: isSelected ? 'bold' : 'normal',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: 5,
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 103,
      background: color4,
      marginTop: 0,
      borderRadius: 6,
      marginLeft: 8,
      width: 80,
    }),
  };
};
