import React from 'react';
import { getRotationString, getTransformForCoordinate } from '../../../utils';
import { RotationContext } from '../../../containers/ZoomDescriptor/RotationContext';
import { BouncingWrapper } from './styles';
import { HerePin } from './HerePin';

type Props = {
  coordinate: {
    x: number;
    y: number;
  };
  width?: number;
  height?: number;
};
// default width and height are the best for marker icon, please remain them in the same aspect ratio
export const HereMarker = ({ coordinate, width = 114, height = 123 }: Props) => {
  const getHereMarkerStyles = (rotation: number) => {
    const coordsStyles = getTransformForCoordinate({
      width,
      height,
      scale: 1,
      coordinate,
    });

    const rotationStyles = getRotationString({
      width,
      height,
      rotation,
    });

    return {
      width,
      transform: `${coordsStyles.transform} ${rotationStyles.transform}`,
      transformOrigin: `${width}px ${height}px`,
    };
  };

  return (
    <RotationContext.Consumer>
      {rotation => (
        <g style={getHereMarkerStyles(rotation)}>
          <BouncingWrapper>
            <HerePin width={width} height={height} />
          </BouncingWrapper>
        </g>
      )}
    </RotationContext.Consumer>
  );
};
