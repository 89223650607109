import log from '@engage-web/utils/logger';

const spacesRegExp = /\s+/;

export interface ViewBoxParams {
  viewBox: [number, number, number, number] | string;
}

const extractViewBox = ({ viewBox }: ViewBoxParams) => {
  if (!viewBox) {
    log.error('[extractViewBox] No viewBox defined')();
    return null;
  }

  const params = (Array.isArray(viewBox) ? viewBox : viewBox.trim().split(spacesRegExp)).map(
    Number,
  );
  if (params.length !== 4 || params.some(Number.isNaN)) {
    log.error(`[extractViewBox] Invalid \`viewBox\` prop:${viewBox}`)();
    return null;
  }
  return {
    minX: params[0],
    minY: params[1],
    vbWidth: params[2],
    vbHeight: params[3],
  };
};

/**
 * Calculate svg aspect ratio based on svg viewBox.
 * @param props of svg
 * @returns {{width: string, height: string, aspectRatio: number}}
 */
export const calculateAspectRatio = ({ viewBox }: ViewBoxParams) => {
  let width = 100;
  const height = 100;
  let aspectRatio = 1;

  const size = extractViewBox({ viewBox });
  if (size) {
    const { vbHeight, vbWidth } = size;
    aspectRatio = vbWidth / vbHeight;
    if (aspectRatio <= 2) {
      // tends to square; set aspect ratio to 2, so it fits into the container
      aspectRatio = 2;
      // adjust the width based omn aspectRatio, so it is contained from left
      width /= aspectRatio;
    }
  }
  return {
    width: `${width}%`,
    height: `${height}%`,
    aspectRatio,
  };
};

/**
 * Check that all elements are svg elements and have a tagName defined (e.g. path, g, etc.), prevents wrong svg definition.
 * @param parsedXML
 * @returns {boolean}
 */
const isValidSVG = (parsedXML: XMLDocument) =>
  parsedXML &&
  parsedXML.documentElement?.nodeName.toLowerCase() === 'svg' &&
  Array.from(parsedXML.documentElement?.children).filter(c => {
    const isSvgElement = c instanceof SVGElement;
    return !isSvgElement && !c.tagName;
  }).length === 0;

const getParseDom = (xml: string): XMLDocument => {
  const parser = new DOMParser();
  return parser.parseFromString(xml, 'image/svg+xml');
};

interface IValidSvg {
  xml: string | null;
  parsedXML: XMLDocument | null;
  props: { [key: string]: string };
}
export const getParsedSVG = (svgString: string) => {
  const validSvg: IValidSvg = {
    xml: null,
    parsedXML: null,
    props: {},
  };
  const parsedXML = getParseDom(svgString);
  if (isValidSVG(parsedXML)) {
    const svgEl = parsedXML.querySelector('svg');
    const props: { [key: string]: string } = {};
    if (svgEl) {
      svgEl.getAttributeNames().forEach(name => {
        const attribute = svgEl.getAttribute(name);
        if (attribute) {
          props[name] = attribute;
        }
      });
      validSvg.xml = parsedXML.documentElement.outerHTML;
      validSvg.parsedXML = parsedXML;
      validSvg.props = props;
    }
  } else {
    log.error('[getParsedSVG] SVG element is not valid')();
  }
  return validSvg;
};
