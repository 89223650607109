import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { FavouriteMutationBody } from '@engage-shared/api/favourites/interfaces';
import { addFavouriteItem } from '@engage-shared/api/favourites/addFavouriteItem';
import { SEARCH_QUERY_KEY } from '@engage-shared/api/search';
import { UseMutationOptions } from 'react-query/types/react/types';

type UseAddToFavourite = (
  options?: UseMutationOptions<any, Error, FavouriteMutationBody>,
) => UseMutationResult<any, Error, FavouriteMutationBody>;

export const useAddToFavourite: UseAddToFavourite = options => {
  const queryClient = useQueryClient();

  return useMutation(addFavouriteItem, {
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries(SEARCH_QUERY_KEY);
    },
  });
};
