import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { useCallback } from 'react';
import { TenantId } from '@engage-shared/api/tenant/interfaces';
import { fetchAndParseTeam } from '../fetch';
import { TEAM_QUERY_KEY } from '@engage-shared/api/teams/constants';
import { ParsedTeam } from '@engage-shared/api/teams/interfaces';
import { TeamId } from '@engage-shared/api/types';

const STALE_TIME = 30 * 60 * 1000;
const queryOptions = {
  cacheTime: Infinity,
  staleTime: STALE_TIME,
};

type UseTeamQueryParams = {
  tenantId: TenantId;
  id: TeamId;
  options?: UseQueryOptions<ParsedTeam>;
};

type UseTeam = (params: UseTeamQueryParams) => UseQueryResult<ParsedTeam>;

export const useTeam: UseTeam = ({ tenantId, id, options = {} }) => {
  return useQuery({
    queryKey: [TEAM_QUERY_KEY, tenantId, id],
    queryFn: () => fetchAndParseTeam({ tenantId, id }),
    enabled: !!tenantId && !!id,
    ...queryOptions,
    ...options,
  });
};

type FetchTeamQuery = ({ id }: { id: TeamId }) => Promise<ParsedTeam>;
type UseTeamFetch = ({ tenantId }: { tenantId: TenantId }) => {
  fetchTeamQuery: FetchTeamQuery;
};

export const useTeamFetch: UseTeamFetch = ({ tenantId }) => {
  const queryClient = useQueryClient();

  const query: FetchTeamQuery = ({ id }) =>
    queryClient.fetchQuery(
      [TEAM_QUERY_KEY, tenantId, id],
      () => fetchAndParseTeam({ tenantId, id }),
      {
        ...queryOptions,
      },
    );

  const fetchTeamQuery = useCallback(query, [queryClient, tenantId]);

  return { fetchTeamQuery };
};
