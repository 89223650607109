import React, { memo } from 'react';
import AvatarBadge, { AvatarBadgeProps } from './AvatarBadge';
import AvatarImage, { AvatarImageProps } from './AvatarImage';

export type AvatarProps = AvatarImageProps & {
  badgeContent?: AvatarBadgeProps['badgeContent'];
  badgeMax?: AvatarBadgeProps['max'];
  badgeVariant?: AvatarBadgeProps['variant'];
  badgeAnchor?: AvatarBadgeProps['anchor'];
  badgeStyle?: AvatarBadgeProps['style'];
  badgeA11yLabel?: AvatarBadgeProps['badgeA11yLabel'];
  status?: AvatarBadgeProps['color'];
};

export const Avatar = (props: AvatarProps) => {
  const {
    source,
    icon,
    iconStyle,
    name,
    initials,
    country,
    size = 'medium',
    variant = 'circle',
    style,
    status,
    badgeContent,
    badgeMax,
    badgeVariant,
    badgeAnchor,
    badgeStyle,
    badgeA11yLabel,
    errorStyle,
    onImageClick,
    className,
  } = props;

  return (
    <AvatarBadge
      color={status}
      badgeContent={badgeContent}
      max={badgeMax}
      size={size}
      maxSize={26}
      variant={badgeVariant}
      anchor={badgeAnchor}
      overlap={variant}
      style={badgeStyle}
      badgeA11yLabel={badgeA11yLabel}
    >
      <AvatarImage
        source={source}
        icon={icon}
        iconStyle={iconStyle}
        name={name}
        initials={initials}
        country={country}
        size={size}
        variant={variant}
        style={style}
        errorStyle={errorStyle}
        onImageClick={onImageClick}
        className={className}
      />
    </AvatarBadge>
  );
};

export default memo(Avatar);
