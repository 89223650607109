import React, { memo } from 'react';
import { getChatProvider, getChatURL } from '@engage-shared/utils';
import { MEDIA_TYPE } from '@engage-web/constants';
import { ChatLink } from '@engage-shared/utils/types';
import Button from './Button';
import { Wrapper } from './styled';

// eslint-disable-next-line no-alert
const onError = (errorMessage: string) => alert(`Error: ${errorMessage}`);

type MediaLinksProps = {
  email?: string;
  chatLink?: ChatLink;
};

const MediaLinks = ({ email, chatLink }: MediaLinksProps) => {
  const chatProvider = getChatProvider(chatLink);
  const chatURL = getChatURL(chatLink);

  if (!email && !chatProvider) {
    return null;
  }

  return (
    <Wrapper data-testid="person-media-links">
      {email ? <Button type={MEDIA_TYPE.EMAIL} mediaString={email} onError={onError} /> : null}
      {chatProvider && chatURL ? (
        <Button type={chatProvider} mediaString={chatURL} onError={onError} />
      ) : null}
    </Wrapper>
  );
};

export default memo(MediaLinks);
