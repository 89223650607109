import { createGlobalStyle } from 'styled-components';
import floorplanStyles from './styles';

export const GlobalStyles = createGlobalStyle`
body {
  overflow: hidden; // prevents showing redundant scrollbars in Firefox
  height: 100vh;
}

@media print {
  body {
    display: none;
  }
}


${floorplanStyles}
`;
