import React from 'react';
import { KioskRemoteConfig } from '@engage-web/api/queries';
import {
  FloorName,
  FloorsListWrapper,
  FloorWrapper,
  LabelFloorAvailability,
  LabelsContainer,
} from '@engage-web/components/layouts/LocationSelector/components/styled';
import { useTranslation } from 'react-i18next';
import { useAvailableKioskContext } from '@engage-web/components/kiosk/AvailableKiosks/AvailableKiosksContext';

type AvailableKiosksItemsProps = {
  data: KioskRemoteConfig[];
};

const AvailableKioskItems = ({ data }: AvailableKiosksItemsProps) => {
  const { t } = useTranslation();
  const { onSelectConfig } = useAvailableKioskContext();

  const renderKioskItem = (config: KioskRemoteConfig) => {
    const { id, name } = config;
    return (
      <FloorWrapper onClick={() => onSelectConfig(config)} key={`${id}-${name}`}>
        <FloorName data-testid="kiosk-list-item">{name}</FloorName>
      </FloorWrapper>
    );
  };

  return (
    <FloorsListWrapper>
      <LabelsContainer>
        <LabelFloorAvailability>{t('components.availableKiosks.kioskName')}</LabelFloorAvailability>
      </LabelsContainer>
      {data.map(renderKioskItem)}
    </FloorsListWrapper>
  );
};

export default AvailableKioskItems;
