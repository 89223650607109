import { useSelector } from 'react-redux';
import { tenantSelectors } from '../../../store';
import {
  useHourlyTeamBookingMutation as useHourlyTeamBookingMutationHook,
  TeamBookingId,
  BookingFilter,
} from '@engage-shared/api';

type UseHourlyTeamBookingMutationProps = {
  teamId: TeamBookingId;
  summary: string;
  filter: BookingFilter;
  timeZone: string;
  duration: number;
  allDayDuration: boolean;
  desks: number[];
  attendees: number[];
  onSuccess: () => void;
  onError?: (e: Error) => void;
};
export const useHourlyTeamBookingMutation = ({
  teamId,
  summary,
  filter,
  timeZone,
  duration,
  allDayDuration,
  desks,
  attendees,
  onSuccess,
  onError,
}: UseHourlyTeamBookingMutationProps) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);

  return useHourlyTeamBookingMutationHook(
    {
      tenantId,
      teamId,
      summary,
      filter,
      timeZone,
      duration,
      allDayDuration,
      desks,
      attendees,
    },
    {
      onSuccess,
      onError,
    },
  );
};
