import React, { memo } from 'react';
import { SkeletonTheme } from '@engage-web/components/base';
import Skeleton from 'react-loading-skeleton';
import {
  Actions,
  CheckInMessage,
  Content,
  ContentWrapper,
  Details,
  Message,
  Title,
  Wrapper,
} from './styled';

const NotificationSkeleton = () => (
  <SkeletonTheme style={{ width: '100%' }}>
    <Wrapper>
      <Content data-testid="notification-list-skeleton">
        <Skeleton circle height={36} width={36} />
        <ContentWrapper>
          <Details>
            <Title>
              <Skeleton width={200} />
            </Title>
            <Message>
              <Skeleton width={100} />
            </Message>
            <Message>
              <Skeleton width={150} />
            </Message>
          </Details>
          <Actions>
            <Skeleton width={100} />
          </Actions>
          <CheckInMessage>
            <Skeleton width={200} />
          </CheckInMessage>
        </ContentWrapper>
      </Content>
    </Wrapper>
  </SkeletonTheme>
);

export default memo(NotificationSkeleton);
