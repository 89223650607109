import { Api, getApiUrl } from '@engage-shared/api/instance';
import { getTimeQueryString } from '../utils';
import { BookingsListResponse, FetchBookingResponse } from './interfaces';
import { API_VERSIONS } from '@engage-shared/constants';
import { DateISODate, Nullable } from '@engage-shared/utils/types';

// type FetchBookings = (param: FetchBookingsParams) => Promise<BookingsListResponse[]>;

interface FetchBookingsParams {
  tenantId: string;
  spaceId: number | undefined;
  startTime: DateISODate;
  endTime: Nullable<DateISODate>;
}

interface FetchBookingParams {
  tenantId: string;
  spaceId: number | undefined;
  meetingId: string;
}
/**
 * Retrieves space information for a desk or room.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | space found |
 * | 404 | space not found |
 * @param tenantId
 * @param spaceId
 * @param startTime
 * @param endTime
 */
export const fetchBookings = async ({
  tenantId,
  spaceId,
  startTime,
  endTime,
}: FetchBookingsParams): Promise<BookingsListResponse[]> => {
  const queryString = getTimeQueryString(startTime, endTime || undefined);
  const url = new URL(
    `${getApiUrl(tenantId, API_VERSIONS.V2)}/spaces/${spaceId}/bookings${queryString}`,
  );
  const response = await Api.get<{ data: BookingsListResponse[] }>(url.toString());
  return response.data?.data;
};

/**
 * Retrieves space information for a desk or room.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | desk-booking found |
 * | 404 | Can’t find the booking |
 * | 501 | Currently doesn't support getting a space reservation by meeting id |
 * @param tenantId
 * @param spaceId
 * @param meetingId
 */
export const fetchBooking = async ({
  tenantId,
  spaceId,
  meetingId,
}: FetchBookingParams): Promise<FetchBookingResponse> => {
  const url = new URL(
    `${getApiUrl(tenantId, API_VERSIONS.V2)}/spaces/${spaceId}/bookings/${meetingId}`,
  );
  const response = await Api.get<{ data: FetchBookingResponse }>(url.toString());
  return response.data?.data;
};
