/**
 * Retrieve details about a team booking.
 * Endpoint GET /engage_api/v1/team_bookings/{id}
 */
import React from 'react';
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { TeamBookingDetails, TeamBookingParams } from '../interfaces';
import { TEAM_BOOKING_QUERY_KEY } from '@engage-shared/api/teams/constants';
import { fetchTeamBooking } from '../fetch';
import { mapTeamBookingDetails } from '@engage-shared/api/teams/utils/mapTeamBookingDetails';
import { Nullable } from '@engage-shared/utils/types';
import { ParsedUserData } from '@engage-shared/api/users/interfaces';
import { TenantId } from '@engage-shared/api/tenant/interfaces';
import { TeamBookingId, UserId } from '@engage-shared/api/types';

type FetchTeamBookingDetails = (
  params: TeamBookingParams & {
    userData: ParsedUserData;
  },
) => Promise<Nullable<TeamBookingDetails>>;
/**
 * Fetch team booking details and map value to fields expected by BookingDetails components.
 */
const fetchTeamBookingDetails: FetchTeamBookingDetails = async ({ tenantId, id, userData }) => {
  const teamBookingDetailsData = await fetchTeamBooking({ tenantId, id });

  return mapTeamBookingDetails({
    teamBookingDetailsData: teamBookingDetailsData,
    userData,
  });
};

type UseTeamBookingDetailsParams = TeamBookingParams & {
  userData: ParsedUserData;
  options?: UseQueryOptions<Nullable<TeamBookingDetails>>;
};
type UseTeamBookingDetails = (
  params: UseTeamBookingDetailsParams,
) => UseQueryResult<Nullable<TeamBookingDetails>>;

export const useTeamBookingDetails: UseTeamBookingDetails = ({
  tenantId,
  id,
  userData,
  options = {},
}) => {
  return useQuery<Nullable<TeamBookingDetails>>({
    queryKey: [TEAM_BOOKING_QUERY_KEY, tenantId, id, `user_${userData.userId}`],
    queryFn: () => fetchTeamBookingDetails({ tenantId, id, userData }),
    enabled: !!tenantId && !!id,
    ...options,
  });
};

type MeetingParams = { id: TeamBookingId };
type UseRefreshBookingParam = { tenantId: TenantId; userId: UserId };
type InvalidateQuery = (params: MeetingParams) => Promise<void>;
type UseRefreshBooking = ({ tenantId, userId }: UseRefreshBookingParam) => {
  refresh: (params: MeetingParams) => Promise<void>;
};
export const useRefreshTeamBooking: UseRefreshBooking = ({ tenantId, userId }) => {
  const queryClient = useQueryClient();

  const invalidateQuery: InvalidateQuery = ({ id }) =>
    queryClient.invalidateQueries([TEAM_BOOKING_QUERY_KEY, tenantId, `${id}`, `user_${userId}`]);

  const refresh = React.useCallback(invalidateQuery, [queryClient, tenantId, userId]);

  return { refresh };
};
