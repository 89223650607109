import React, { PropsWithChildren } from 'react';
import { useGenerateCurrentLocationPath } from '@engage-web/utils';
import { useAppLocation } from '@engage-web/router/hooks';
import { PATH_SEGMENT } from '@engage-web/constants';
import { joinPaths } from '@engage-web/router/utils';
import { TitleFirstRow } from './styled';

type TitleProps = PropsWithChildren<object>;

export function Title({ children }: TitleProps) {
  const { pathname } = useAppLocation();
  const currentLocationPath = useGenerateCurrentLocationPath();

  return (
    <TitleFirstRow
      to={joinPaths(currentLocationPath, PATH_SEGMENT.LOCATION_SWITCHER)}
      state={{ fromPath: pathname }}
    >
      {children}
    </TitleFirstRow>
  );
}
