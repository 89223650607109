import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 38px;
`;

export const ClearButton = styled.button<{ $clickable: boolean }>`
  pointer-events: ${({ $clickable }) => ($clickable ? 'inherit' : 'none')};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'inherit')};
  height: 38px;
  margin-right: 5px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color9};
  ${({ $clickable }) =>
    $clickable &&
    `
    &.focus-visible {
      outline: 1px auto Highlight;
      outline: 1px auto -webkit-focus-ring-color;
    }
  `}
`;

export const FiltersStatusText = styled.span<{ $bold?: boolean }>`
  margin-right: 5px;
  font-size: 14px;
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
`;

export const FiltersStatusIconWrapper = styled.div`
  margin-right: 5px;
`;
