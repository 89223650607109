import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { TextWrapper, Wrapper } from './styled';

const SpaceCardSkeleton = () => (
  <Wrapper>
    <Skeleton circle height={112} width={112} />
    <TextWrapper>
      <h5>
        <Skeleton width={100} />
      </h5>
      <span className="btn">
        <Skeleton width={200} />
      </span>
    </TextWrapper>
  </Wrapper>
);

export default SpaceCardSkeleton;
