import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { useAvailability as useAvailabilityHook } from '@engage-shared/api';
import { ISODateString } from '@engage-shared/utils/types';
import { UseQueryOptions, UseQueryResult } from 'react-query';

type UseAvailabilityParams = {
  buildingId?: number;
  startTime: ISODateString;
  options?: UseQueryOptions;
};

export const useAvailability = ({ startTime, buildingId, options = {} }: UseAvailabilityParams) => {
  const currentLocation = useSelector(tenantSelectors.getCurrentLocation);
  const blId = buildingId || currentLocation?.building?.id;

  return useAvailabilityHook({
    buildingId: blId,
    startTime,
    options,
  }) as UseQueryResult<{
    existingReservations: number;
    allowedReservations: number;
  }>;
};
