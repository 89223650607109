import React, { useState } from 'react';
import { Calendar, Icon } from '@engage-web/components/base';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userConfigSelectors } from '@engage-web/store';
import { firstLetterCapitalize, testProps } from '@engage-web/utils';
import { DateFormat, formatDate, formatLocalizedDate } from '@engage-shared/utils';
import { A11yHiddenLabel } from '@engage-web/styles';
import {
  AgendaCalendarWrapper,
  CalendarToggler,
  Month,
  MonthSelector,
  MonthSelectorActionsWrapper,
  MonthSelectorWrapper,
  MonthWrapper,
  ReservedMark,
} from './styled';
import { changeAgendaMonth, DATE_NAVIGATION, getReservedDaysFromAgendaItems } from './utils';
import { LanguageTypes } from '@engage-shared/constants';
import { AgendaItems } from '@engage-shared/api';

export const ANIMATION_DURATION = 250;
export const AGENDA_CALENDAR_ID = 'id_agenda_calendar';

interface AgendaCalendarProps {
  agendaItems: AgendaItems | undefined;
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  initiallyOpened?: boolean;
}

const AgendaCalendar = ({
  agendaItems,
  selectedDate,
  onDateChange,
  initiallyOpened = false,
}: AgendaCalendarProps) => {
  const { t } = useTranslation();
  const [calendarOpen, setCalendarOpen] = useState(initiallyOpened);
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const month = firstLetterCapitalize(
    formatLocalizedDate(selectedDate, {
      locale,
      format: DateFormat.monthLong,
    }),
  );

  const items = agendaItems ?? {};
  const reservedDays = getReservedDaysFromAgendaItems(items);

  const goToPrevMonth = () =>
    onDateChange(changeAgendaMonth(DATE_NAVIGATION.PREVIOUS, selectedDate));

  const goToNextMonth = () => onDateChange(changeAgendaMonth(DATE_NAVIGATION.NEXT, selectedDate));

  const markReservedTiles = ({ date }: { date: Date }) => {
    if (!reservedDays.includes(formatDate(date))) {
      return null;
    }
    return (
      <ReservedMark data-testid="reserved-calendar-mark">
        <A11yHiddenLabel>{t('accessibilityLabels.dayBooked')}</A11yHiddenLabel>
      </ReservedMark>
    );
  };

  const calendarNavigationAriaLabel = formatLocalizedDate(selectedDate, {
    format: DateFormat.monthLong,
    locale,
  });

  return (
    <>
      <MonthSelectorWrapper>
        <MonthWrapper>
          <Month data-testid="current-month">{month}</Month>
          <CalendarToggler
            $open={calendarOpen}
            onClick={() => setCalendarOpen(!calendarOpen)}
            aria-expanded={calendarOpen}
            aria-pressed={calendarOpen}
            aria-controls={AGENDA_CALENDAR_ID}
            aria-label={calendarNavigationAriaLabel}
            role="button"
            name={t('accessibilityLabels.toggleAgendaCalendar')}
            data-testid={formatLocalizedDate(selectedDate, {
              locale: LanguageTypes.en,
              format: DateFormat.monthLong,
            })}
          >
            <Icon name="chevron-right" size={12} />
          </CalendarToggler>
        </MonthWrapper>
        <MonthSelectorActionsWrapper>
          <MonthSelector
            onClick={goToPrevMonth}
            {...testProps(t, 'accessibilityLabels.agendaCalendarNavPrevMonth').props}
          >
            <Icon shouldRotateOnRtl name="chevron-left" size={10} />
          </MonthSelector>
          <MonthSelector
            onClick={goToNextMonth}
            {...testProps(t, 'accessibilityLabels.agendaCalendarNavNextMonth').props}
          >
            <Icon shouldRotateOnRtl name="chevron-right" size={10} />
          </MonthSelector>
        </MonthSelectorActionsWrapper>
      </MonthSelectorWrapper>
      <AnimateHeight height={calendarOpen ? 295 : 0} duration={ANIMATION_DURATION}>
        <AgendaCalendarWrapper data-testid="agenda-calendar-wrapper" id={AGENDA_CALENDAR_ID}>
          <Calendar
            locale={locale}
            value={selectedDate}
            activeStartDate={selectedDate}
            onChange={onDateChange}
            minDetail="month"
            showNeighboringMonth={false}
            showNavigation={false}
            tileContent={markReservedTiles}
          />
        </AgendaCalendarWrapper>
      </AnimateHeight>
    </>
  );
};

export default AgendaCalendar;
