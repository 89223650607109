import OpenSeadragon from 'openseadragon';
import { CustomGestureSettings } from '../types';
import { isNativeApp } from '../utils';

export const doubleClickTime = 200;

export const defaultOptions: OpenSeadragon.Options = {
  zoomPerClick: 1.8, // default: 2
  dblClickTimeThreshold: doubleClickTime, // default: 300
  visibilityRatio: isNativeApp ? 0.5 : 0.25, // default: 0.5
  maxZoomPixelRatio: 2, // default 1.1
  zoomPerScroll: 1.4, // default: 1.2
  springStiffness: 5.5, // default: 6.5
  minZoomImageRatio: 0.8, // default: 0.9
  clickDistThreshold: 0, // default: 5
  minPixelRatio: 0.5, // default: 0.5
  animationTime: 1.2, // default: 1.2
  preserveImageSizeOnResize: true, // requires autoResize=true
  autoResize: true,
  showNavigator: false,
  showNavigationControl: false,
  autoHideControls: false,
  constrainDuringPan: false,
  immediateRender: true,
  debugMode: false,
  defaultZoomLevel: isNativeApp ? 1 : 0,
  gestureSettingsTouch: {
    flickEnabled: true,
    flickMinSpeed: 60,
    flickMomentum: 0.02,
    pinchToZoom: false,
    pinchRotate: false,
  } as CustomGestureSettings,
  gestureSettingsMouse: {
    clickToZoom: false,
    dblClickToZoom: true,
    pinchToZoom: false,
    pinchRotate: false,
  } as CustomGestureSettings,
};
