import React from 'react';
import { useTranslation } from 'react-i18next';
import * as styleComponents from './styled';
import { ParsedTeam } from '@engage-shared/api';

const mapStyledComponents = (isBottomButton: boolean) => {
  const prefix = isBottomButton ? 'Booked' : 'BookFor';
  return {
    Wrapper: styleComponents[`${prefix}Wrapper`],
    Title: styleComponents[`${prefix}Title`],
    TeamWrapper: styleComponents[`${prefix}TeamWrapper`],
    TeamName: styleComponents[`${prefix}TeamName`],
    TeamNumberWrapper: styleComponents[`${prefix}TeamNumberWrapper`],
  };
};

type TeamButtonProps = {
  team: ParsedTeam;
  isBottomButton: boolean;
  onPress?: () => void;
};
const TeamButton = ({ team, isBottomButton, onPress }: TeamButtonProps) => {
  const { t } = useTranslation();
  const title = isBottomButton ? t('layout.reserve.booked') : t('layout.reserve.desk.bookFor');

  const { Wrapper, Title, TeamWrapper, TeamName, TeamNumberWrapper } =
    mapStyledComponents(isBottomButton);
  const TeamNumber = styleComponents.TeamNumber;

  return (
    <Wrapper>
      <Title>{`${title}: `}</Title>
      <TeamWrapper data-testid="team-button" onClick={onPress}>
        <TeamName>{team.name}</TeamName>
        <TeamNumberWrapper>
          <TeamNumber>{team.people}</TeamNumber>
        </TeamNumberWrapper>
      </TeamWrapper>
    </Wrapper>
  );
};

export default TeamButton;
