import moment from 'moment-timezone';
import { getDeskCheckInWindowDates } from './reservationHelpers';
import { ISODateString, Nullable } from '../types';
import { addDays, addMinutes, addSeconds } from 'date-fns';
import { TenantParsedData } from '@engage-shared/api/tenant/interfaces';
import { CheckInConfiguration } from '@engage-shared/utils/bookings/getCheckInStatus';

type NotificationDates = {
  notificationDate: Nullable<Date>;
  checkInStartDate: string;
  checkInCloseDate: string;
  expirationDate: Nullable<string>;
};

export const getNotificationDates = ({
  reservationStartDate,
  timeZone,
  tenantConfig,
  isAllDayBooking,
}: {
  reservationStartDate: ISODateString;
  timeZone: string;
  tenantConfig: CheckInConfiguration;
  isAllDayBooking: boolean;
}): NotificationDates => {
  const { checkInOpenDate, checkInCloseDate } = getDeskCheckInWindowDates({
    tenantConfig,
    reservationStartDate,
    timeZone,
    isAllDayBooking,
  });

  const expirationDate = checkInOpenDate
    ? checkInOpenDate.clone().add(7, 'days').toISOString()
    : '';
  const notificationDate = checkInOpenDate ? checkInOpenDate.toDate() : null;
  const checkInStartDateStr = checkInOpenDate ? checkInOpenDate.toISOString() : '';
  const checkInCloseDateStr = checkInCloseDate ? checkInCloseDate.toISOString() : '';

  return {
    notificationDate,
    checkInStartDate: checkInStartDateStr,
    checkInCloseDate: checkInCloseDateStr,
    expirationDate,
  };
};

/**
 * Schedule a notification if check in is enabled, space is a Desk, and the start time is not within the
 * checkin window.
 */
export const shouldScheduleNotification = ({
  isDesk,
  reservationStartDate,
  timeZone,
  tenantConfig,
  isAllDayBooking,
}: {
  isDesk: boolean;
  reservationStartDate: ISODateString;
  timeZone: string;
  tenantConfig: CheckInConfiguration;
  isAllDayBooking: boolean;
}): boolean => {
  const { enableDeskCheckIn } = tenantConfig;

  if (!isDesk || !enableDeskCheckIn) return false;

  const { checkInOpenDate } = getDeskCheckInWindowDates({
    tenantConfig,
    reservationStartDate,
    timeZone,
    isAllDayBooking,
  });

  return moment().isBefore(checkInOpenDate as any);
};

/**
 * List a notification if check in is enabled, space is a Desk, and the reservation is not older than a week
 * and not in the future more than 2 hours before check-in.
 */
export const shouldListNotification = ({
  isDesk,
  reservationStartDate,
  timeZone,
  tenantConfig,
  isAllDayBooking,
}: {
  isDesk: boolean;
  reservationStartDate: ISODateString;
  timeZone: string;
  tenantConfig: CheckInConfiguration;
  isAllDayBooking: boolean;
}): Nullable<boolean> => {
  const { enableDeskCheckIn } = tenantConfig;

  if (!isDesk || !enableDeskCheckIn) return false;

  const { checkInOpenDate } = getDeskCheckInWindowDates({
    tenantConfig,
    reservationStartDate,
    timeZone,
    isAllDayBooking,
  });

  const now = moment();
  const next7Days = now.clone().add(7, 'days'); // not older than 7 days
  const startListing = checkInOpenDate?.clone().subtract(2, 'hours'); // not in the future more than 2 hours before checkin
  return checkInOpenDate && checkInOpenDate.isBefore(next7Days) && now.isAfter(startListing);
};

export const getDemoNotificationDates = ({
  demoNotificationDelay,
  tenantConfig,
}: {
  demoNotificationDelay: number;
  tenantConfig: TenantParsedData;
}): NotificationDates => {
  const checkInOpenDate = addSeconds(new Date(), demoNotificationDelay);

  const { deskCheckInWindowDuration } = tenantConfig;
  const checkInCloseDate = addMinutes(checkInOpenDate, deskCheckInWindowDuration);

  const expirationDate = addDays(checkInOpenDate, 7).toISOString();

  return {
    notificationDate: checkInOpenDate,
    checkInStartDate: checkInOpenDate.toISOString(),
    checkInCloseDate: checkInCloseDate.toISOString(),
    expirationDate,
  };
};
