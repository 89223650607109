import { CreateBookingResponse, CreateBookingParams } from '@engage-shared/api/bookings/interfaces';
import { Api, getApiUrl } from '@engage-shared/api/instance';

type CreateBooking = (params: CreateBookingParams) => Promise<CreateBookingResponse>;
/**
 * Book a space.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | results found |
 * | 422 | booking failed |
 * @param spaceId
 * @param reservation
 * @param tenantId
 */
export const createBooking: CreateBooking = async ({ tenantId, spaceId, reservation }) => {
  const url = new URL(`${getApiUrl(tenantId)}/spaces/${spaceId}/book`);

  const response = await Api.post<{ data: CreateBookingResponse }>(url.toString(), {
    ...reservation,
  });
  return response.data?.data;
};
