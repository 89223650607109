import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-left: -16px;
`;

const Oval = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  border: 2px solid ${props => props.color};
  background-color: ${({ theme }) => theme.color6};
  left: 0;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  border-radius: 0.5px;
  border: 0.5px solid ${props => props.color};
`;

export { Wrapper, Oval, Line };
