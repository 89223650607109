import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

type NavigateToDeepLinkProps = {
  to: string;
  clearDeepLink: () => void;
};

export function NavigateToDeepLink({ to, clearDeepLink }: NavigateToDeepLinkProps) {
  useEffect(() => {
    clearDeepLink();
  }, [clearDeepLink]);

  return <Navigate replace to={to} />;
}
