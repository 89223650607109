import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetchPersonAndPresence } from '@engage-shared/api/people/fetch';
import { useCallback } from 'react';
import { PersonAndPresenceResponse } from '../interfaces';
import { DateISODate, Nullable } from '@engage-shared/utils/types';

export const PERSON_QUERY_KEY = 'person';

type UsePersonFetchParams = {
  dateStart: Date;
  dateEnd: Date;
  searchSpaceId?: number;
  currentFloorId?: number;
  spaceStartTime?: DateISODate;
  spaceEndTime?: Nullable<DateISODate>;
};

type QueryKey = string[];

type UsePersonQueryParams = UsePersonFetchParams & {
  id: number;
  options?: UseQueryOptions<
    PersonAndPresenceResponse | undefined,
    unknown,
    PersonAndPresenceResponse,
    QueryKey
  >;
};

type UsePersonQuery = (params: UsePersonQueryParams) => UseQueryResult<PersonAndPresenceResponse>;

const generateQueryKey = (
  id: number,
  dateStart: Date,
  dateEnd: Date,
  searchSpaceId?: number,
  currentFloorId?: number,
) => {
  const queryKey = [
    PERSON_QUERY_KEY,
    `${id}`,
    dateStart?.toISOString() ?? '',
    dateEnd?.toString() ?? '',
  ];
  if (searchSpaceId) {
    queryKey.push(`${searchSpaceId}`);
  }
  if (currentFloorId) {
    queryKey.push(`${currentFloorId}`);
  }
  return queryKey;
};

export const usePersonQuery: UsePersonQuery = ({
  id,
  dateStart,
  dateEnd,
  searchSpaceId,
  spaceStartTime,
  spaceEndTime,
  currentFloorId,
  options = {},
}) => {
  const queryKey = generateQueryKey(id, dateStart, dateEnd, searchSpaceId, currentFloorId);
  return useQuery<
    PersonAndPresenceResponse | undefined,
    unknown,
    PersonAndPresenceResponse,
    QueryKey
  >(
    queryKey,
    () =>
      fetchPersonAndPresence({
        id,
        dateStart,
        dateEnd,
        searchSpaceId,
        currentFloorId,
        spaceStartTime,
        spaceEndTime,
      }),
    {
      enabled: !!id,
      staleTime: 5 * 60 * 1000,
      ...options,
    },
  );
};

type UsePersonFetch = (params: UsePersonFetchParams) => {
  fetchPersonQuery: (id: number) => Promise<PersonAndPresenceResponse>;
};

export const usePersonFetch: UsePersonFetch = ({ dateStart, dateEnd, searchSpaceId }) => {
  const queryClient = useQueryClient();

  const query = (id: number): Promise<PersonAndPresenceResponse> =>
    queryClient.fetchQuery([PERSON_QUERY_KEY, id, dateStart, dateEnd], () =>
      fetchPersonAndPresence({ id, dateStart, dateEnd, searchSpaceId }),
    );

  const fetchPersonQuery = useCallback(query, [queryClient, dateStart, dateEnd, searchSpaceId]);

  return { fetchPersonQuery };
};
