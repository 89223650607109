import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addDays, isDate, isSameDay } from 'date-fns';
import {
  DateFormat,
  formatConsecutiveSelectedDatesString,
  formatLocalizedDate,
  getDayStartTime,
  getUtcIsoString,
} from '@engage-shared/utils';
import { FocusId, isDateInArray, testProps, useConfig, useModal } from '@engage-web/utils';
import {
  DATE_TIME_PICKER_VARIANT,
  DAYS_SELECTOR_NUMBER_OF_DAYS,
  LanguageTypes,
  MODALS,
} from '@engage-web/constants';
import { inAppNotificationsActions, userConfigSelectors } from '@engage-web/store';
import { isElectron } from '@engage-web/utils/electron';
import {
  DaysLegend,
  DaysSelectorContainer,
  DaysWrapper,
  SelectedDates,
  SelectedDatesWrapper,
} from './styled';
import { Day, PressDayFn } from './Day';

interface GetDisplayedDaysArgs {
  dateStart: Date;
  selectedDays: Date[];
  disabledDays: Date[];
  numberOfDays: number;
  timeZone: string;
  locale?: LanguageTypes;
}

const getDisplayedDays = ({
  dateStart,
  selectedDays,
  disabledDays,
  numberOfDays,
  timeZone,
  locale,
}: GetDisplayedDaysArgs) => {
  let nextDay = isDate(dateStart) ? dateStart : new Date();
  nextDay = getDayStartTime({ date: nextDay, timeZone });

  const newDays = [];
  for (let i = 0; i < numberOfDays; i++) {
    const isDisabled = isDateInArray(nextDay, disabledDays);
    const isSelected = isDateInArray(nextDay, selectedDays);
    const label = formatLocalizedDate(nextDay, {
      timeZone,
      locale,
      format: DateFormat.weekDayNarrow,
    })
      .substr(0, 1)
      .toUpperCase();
    newDays[i] = {
      date: nextDay,
      label,
      isCurrentDate: i === 0,
      isSelected: isSelected && !isDisabled,
      isDisabled,
    };
    nextDay = addDays(nextDay, 1);
  }

  return {
    disabledDays,
    displayedDays: newDays,
  };
};

interface DaysSelectorProps {
  selectedDays: Date[];
  onDaySelected?: (dates: Date[]) => void;
  isMultiDayDeskReservation?: boolean;
  disabledDays?: Date[];
  numberOfDays?: number;
  dateStart?: Date;
  timeZone: string;
  currentDayAlwaysSelected?: boolean;
}
const DaysSelector = ({
  onDaySelected,
  selectedDays = [],
  disabledDays = [],
  dateStart = new Date(),
  numberOfDays = DAYS_SELECTOR_NUMBER_OF_DAYS,
  timeZone,
  currentDayAlwaysSelected = false,
  // true when is from ReserveCard, false when is from ReservationAssist
  isMultiDayDeskReservation = false,
}: DaysSelectorProps) => {
  const dispatch = useDispatch();
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const isKiosk = isElectron();
  const { t } = useTranslation();
  const { kioskRestrictBookingToCurrentDay } = useConfig();
  const { openModal } = useModal();

  const shouldDisplaySelectedDates = isMultiDayDeskReservation && selectedDays.length;
  const { displayedDays } = getDisplayedDays({
    dateStart,
    selectedDays,
    disabledDays,
    numberOfDays,
    timeZone,
    locale,
  });

  const openTimelineDatePicker = () => {
    if (isKiosk && kioskRestrictBookingToCurrentDay) {
      dispatch(
        inAppNotificationsActions.addWarningNotification({
          message: t('layout.notifications.kioskCurrentDayBookingOnly'),
        }),
      );
      return;
    }
    openModal(MODALS.DATE_TIME_PICKER, {
      variant: DATE_TIME_PICKER_VARIANT.TIMELINE,
      returnFocusTo: FocusId.ReserveSelectedDatesButton,
    });
  };

  const onPressDay: PressDayFn = ({ date, isSelected, isCurrentDate }) => {
    if (isCurrentDate && currentDayAlwaysSelected) {
      return;
    }

    if (isKiosk && kioskRestrictBookingToCurrentDay) {
      dispatch(
        inAppNotificationsActions.addWarningNotification({
          message: t('layout.notifications.kioskCurrentDayBookingOnly'),
        }),
      );
      return;
    }

    if (isSelected) {
      onDaySelected?.(selectedDays.filter(selectedDate => !isSameDay(selectedDate, date)));
    } else {
      onDaySelected?.([...selectedDays, date]);
    }
  };

  return (
    <DaysSelectorContainer>
      <DaysWrapper>
        <DaysLegend>{t('accessibilityLabels.daysSelector')}</DaysLegend>
        {displayedDays.map(day => (
          <Day day={day} key={getUtcIsoString(day.date)} locale={locale} onPressDay={onPressDay} />
        ))}
      </DaysWrapper>
      {shouldDisplaySelectedDates ? (
        <SelectedDatesWrapper>
          <SelectedDates
            id={FocusId.ReserveSelectedDatesButton}
            as="button"
            onClick={openTimelineDatePicker}
            {...testProps(t, 'accessibilityLabels.selectedDates').props}
          >
            {formatConsecutiveSelectedDatesString(selectedDays, timeZone, locale)}
          </SelectedDates>
        </SelectedDatesWrapper>
      ) : null}
    </DaysSelectorContainer>
  );
};

export default DaysSelector;
