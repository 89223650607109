import { useSelector } from 'react-redux';
import { userConfigSelectors } from '../../store';
import { SpaceType, useSpaceTypeAttributesQuery, useSpaceTypesQuery } from '@engage-shared/api';

interface UseSpaceParams {
  spaceType: SpaceType;
  options: any;
}
export const useSpaceTypes = ({ spaceType, options = {} }: UseSpaceParams) => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  return useSpaceTypesQuery({ spaceType, locale, options });
};

export const useSpaceTypeAttributes = ({ spaceType, options = {} }: UseSpaceParams) => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  return useSpaceTypeAttributesQuery({ spaceType, locale, options });
};
