import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@engage-web/components/base';
import { Wrapper, ButtonText } from './styled';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';

const ResetViewButton = () => {
  const fes = useFESContext();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const onRotationChangeEvent = ({ rotation }: { rotation: number }) => {
    // Rotation will have the current degrees of rotation for the floorplan
    // We want to allow for some small amount of rotation due to small
    // movements or floating point precision
    const absRotation = Math.abs(rotation);
    const shouldShowButton = absRotation >= 2 && absRotation <= 358;
    setVisible(shouldShowButton);
  };

  useEffect(() => {
    fes.on(FES_EVENTS.ROTATION_CHANGED, onRotationChangeEvent);
    return () => {
      fes.off(FES_EVENTS.ROTATION_CHANGED, onRotationChangeEvent);
    };
  }, [fes]);

  const handleClick = () => {
    fes.trigger(FES_EVENTS.RESET_ORIENTATION);
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <Wrapper onClick={handleClick}>
      <ButtonText>{t('layout.floorplanViewSelector.resetView')}</ButtonText>
      <Icon name="rotate-floorplan" size={14} />
    </Wrapper>
  );
};

export default ResetViewButton;
