import React from 'react';
import { TimelineSelector } from '@engage-web/components/base';
import { MODALS, DATE_TIME_PICKER_VARIANT } from '@engage-web/constants';
import { useDatePicker, useModal } from '@engage-web/utils/hooks';
import { useConfig } from '@engage-web/utils';
import { isElectron } from '@engage-web/utils/electron';
import { useDispatch } from 'react-redux';
import { inAppNotificationsActions } from '@engage-web/store';
import { useTranslation } from 'react-i18next';
import { TimeLinesWrapper } from './styled';

export function DateTimeSelector() {
  const { dateStart, defaultStartDate, onClear, timeZone } = useDatePicker();
  const { layout, content, openModal } = useModal();
  const { kioskRestrictBookingToCurrentDay } = useConfig();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isKiosk = isElectron();

  const isDatePickerOpened = layout === MODALS.DATE_TIME_PICKER;
  const disableInteractions =
    isDatePickerOpened && content.variant !== DATE_TIME_PICKER_VARIANT.TIMELINE;
  const dateStartApplied = dateStart || defaultStartDate;

  const toggleDateTimeSelector = () => {
    if (isKiosk && kioskRestrictBookingToCurrentDay) {
      dispatch(
        inAppNotificationsActions.addWarningNotification({
          message: t('layout.notifications.kioskCurrentDayBookingOnly'),
        }),
      );
      return;
    }

    openModal(MODALS.DATE_TIME_PICKER, {
      variant: DATE_TIME_PICKER_VARIANT.TIMELINE,
    });
  };

  return (
    <TimeLinesWrapper>
      <TimelineSelector
        onDateClick={toggleDateTimeSelector}
        onDateClear={onClear}
        selectedDate={dateStartApplied}
        timeZone={timeZone}
        isDatePickerOpened={isDatePickerOpened}
        disableInteractions={disableInteractions}
      />
    </TimeLinesWrapper>
  );
}
