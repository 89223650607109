import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { SkeletonTheme } from '@engage-web/components/base';
import { ItemWrapper, TextWrapper } from './SpaceAttributes.style';

const items = new Array(3).fill(null);

export const SpaceAttributesSkeleton = () => {
  const renderItem = (_: null, index: number) => (
    <ItemWrapper key={index}>
      <TextWrapper>
        <Skeleton width={200} />
      </TextWrapper>
    </ItemWrapper>
  );

  return <SkeletonTheme style={{ width: '100%' }}>{items.map(renderItem)}</SkeletonTheme>;
};
