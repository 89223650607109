import React, { memo } from 'react';
import { Mask } from '../../../base';
import { ReactElement } from 'react-markdown';
import { Button as StyledButton, ButtonText, Wrapper } from './styled';

type ButtonProps = {
  children?: ReactElement;
  text?: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  opacity?: number;
  color?: string;
  style?: StyleSheet | object;
  className?: string;
  id?: string;
};

export const Button = memo(
  ({
    children,
    text,
    onClick,
    isLoading,
    disabled,
    backgroundColor = '#FFF',
    opacity = 1,
    color = '#000',
    style = {},
    className,
    id,
  }: ButtonProps) => {
    const renderComponent = () => {
      if (children && React.isValidElement(children)) {
        return children;
      }

      if (text) {
        return (
          <ButtonText color={color}>
            <span className="text">{text}</span>
          </ButtonText>
        );
      }

      return null;
    };

    return (
      <Wrapper $backgroundColor={backgroundColor} $opacity={opacity} className={className}>
        <Mask isLoading={!!isLoading} />
        <StyledButton
          id={id}
          onClick={onClick}
          disabled={disabled}
          style={style}
          aria-label={text}
          aria-disabled={disabled}
          className="btn"
        >
          {renderComponent()}
        </StyledButton>
      </Wrapper>
    );
  },
);

export default Button;
