import React from 'react';
import { useSelector } from 'react-redux';
import { useUser } from '@engage-web/api/queries/useUser';
import { useModal } from '@engage-web/utils/hooks/useModal';
import { notificationsSelectors } from '@engage-web/store';
import { MODALS } from '@engage-web/constants';
import { FocusId } from '@engage-web/utils';
import User from './User';
import { UserData } from '@engage-shared/api';

export function UserContainer() {
  const { isLoading, isIdle, isError, data: user } = useUser();
  const { openModal } = useModal();
  const hasUnreadNotifications = useSelector(notificationsSelectors.getHasUnread);

  const toggleUserModal = () => {
    openModal(MODALS.USER_PROFILE);
  };

  return (
    <User
      id={FocusId.UserProfileButton}
      user={user as UserData}
      onClick={toggleUserModal}
      hasUnreadNotifications={hasUnreadNotifications}
      isLoading={isLoading || isIdle}
      isError={isError}
    />
  );
}
