import { format, isAfter, isSameDay, setMilliseconds, setSeconds } from 'date-fns';
import { TFunction as TranslateFunction } from 'i18next';
import { getDisabledDaysFromBookingLimit, getDisabledDaysFromBookings } from '@engage-shared/utils';
import { BookingsListResponse } from '@engage-shared/api/bookings';

type GetHasBookingsProps = { bookedIntervals?: number[]; start: Date };
export const getHasBookingsForTimeInterval = ({ bookedIntervals, start }: GetHasBookingsProps) => {
  if (!Array.isArray(bookedIntervals)) {
    return false;
  }
  return bookedIntervals.some(interval => {
    const intervalStartDate = new Date(interval);
    const startDateZeroed = setMilliseconds(setSeconds(start, 0), 0);
    if (!isSameDay(intervalStartDate, startDateZeroed)) return false;
    return isAfter(intervalStartDate, startDateZeroed);
  });
};

export type GetReserveBtnTextProps = {
  isOccupied?: boolean;
  isLoading: boolean;
  t: TranslateFunction;
};
export const getReserveBtnText = ({ isOccupied, isLoading, t }: GetReserveBtnTextProps) => {
  if (isOccupied) {
    return t('components.reserveButton.reserveSuccess');
  }
  if (isLoading) {
    return t('components.reserveButton.reserveProcessing');
  }
  return t('components.reserveButton.reserve');
};

export const getFailedDaysFromSelected = (selectedDays: Date[], failedDaysMessage: string) => {
  const failedDaysArray = failedDaysMessage.split(', ');
  const selectedDaysFormatted = selectedDays.map(day => ({
    original: day,
    formatted: format(day, 'EEE'),
  }));
  // we need to leave failed days in selected array and remove another
  const filteredSelectedDays = selectedDaysFormatted.filter(day =>
    failedDaysArray.includes(day.formatted),
  );
  // we need to return original day values which DaysSelector can use
  return filteredSelectedDays.map(day => day.original);
};

type GetDisabledDaysProps = {
  bookings?: BookingsListResponse[];
  futureBookingsLimit: number;
  filterDateStart: Date;
  timeZone: string;
};
export const getDisabledDays = ({
  bookings,
  futureBookingsLimit,
  filterDateStart,
  timeZone,
}: GetDisabledDaysProps) => {
  const disabledDaysFromBookings = bookings
    ? getDisabledDaysFromBookings(bookings, filterDateStart)
    : [];
  const disabledDaysFromLimit = getDisabledDaysFromBookingLimit({
    filterDateStart: filterDateStart || new Date(),
    futureBookingsLimit,
    timeZone,
  });
  return [...disabledDaysFromBookings, ...disabledDaysFromLimit];
};
