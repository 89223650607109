import React from 'react';
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce';
import { getDragSelectedDesks, getNonOccupantDomPoints } from './utils';
import { DragSelection, DragSelectionProps, isPointInsideBox } from '../DragSelection';
import { PointXY } from '../../types';
import { useTeamReservationSelectionState } from '../../hooks';
import { isTeamReservationsEnabled } from '../../utils';
import { TEAM_RESERVATION_DESKS_SELECTION_MODE } from '@floorplan/fes';

const EVENT_THROTTLE_TIME = 150;
const EVENT_DEBOUNCE_TIME = 200;
const { ERASE } = TEAM_RESERVATION_DESKS_SELECTION_MODE;

type TeamReservationDragSelectionProps = Partial<Pick<DragSelectionProps, 'viewer'>> & {
  nonOccupantPoints: any[];
};

export const TeamReservationDragSelection = ({
  nonOccupantPoints,
  viewer,
  ...rest
}: TeamReservationDragSelectionProps) => {
  const {
    selectedDesks,
    selectedDesksSync,
    isTeamSizeReached,
    updateSelectedDesks,
    updateSelectedDesksStore,
    eraseDesks,
    addDesks,
    teamReservationSelectionMode,
  } = useTeamReservationSelectionState();

  const teamReservationEnabled = isTeamReservationsEnabled(teamReservationSelectionMode);

  const onClick: DragSelectionProps['onClick'] = (point: PointXY) => {
    const selectableDeskBoxes = getNonOccupantDomPoints(nonOccupantPoints);

    const desk = selectableDeskBoxes.find(deskBox => isPointInsideBox(point, deskBox));

    if (!desk?.space) {
      return;
    }
    const {
      space: { id: clickedDeskId },
    } = desk;

    const isAlreadySelected = selectedDesks.includes(clickedDeskId);

    if (isTeamSizeReached && !isAlreadySelected) {
      return;
    }

    // if desk is already selected we should deselect it (remove from teamReservationSelectedDesks array)
    // otherwise we should add it to array
    const newTemReservationSelectedDesks = isAlreadySelected
      ? selectedDesks.filter(deskId => deskId !== clickedDeskId)
      : [...selectedDesks, desk.space.id];
    updateSelectedDesks(newTemReservationSelectedDesks);
    updateSelectedDesksStore(newTemReservationSelectedDesks);
  };

  const onDragMove = useThrottledCallback<DragSelectionProps['onDragMove']>(
    dragSelectionData => {
      if (!dragSelectionData.isDragging) {
        return;
      }

      // if we reached team reservation desks limit we are only allowed to erase desks
      if (isTeamSizeReached && teamReservationSelectionMode !== ERASE) {
        return;
      }

      const selectableDeskBoxes = getNonOccupantDomPoints(nonOccupantPoints);
      const selectedDesks = getDragSelectedDesks(dragSelectionData, selectableDeskBoxes);

      if (teamReservationSelectionMode === ERASE) {
        eraseDesks(selectedDesks);
      } else {
        addDesks(selectedDesks);
      }
    },
    EVENT_THROTTLE_TIME,
    {
      leading: false,
    },
  );

  const onDragComplete = useDebouncedCallback<DragSelectionProps['onDragComplete']>(() => {
    updateSelectedDesks([...selectedDesksSync]);
    updateSelectedDesksStore([...selectedDesksSync]);
  }, EVENT_DEBOUNCE_TIME);

  if (!viewer) return null;

  return (
    <DragSelection
      onClick={onClick}
      onDragMove={onDragMove}
      onDragComplete={onDragComplete}
      active={teamReservationEnabled}
      viewer={viewer}
      {...rest}
    />
  );
};
