import {
  getAuthorizationHeaderObjectFromString,
  getAuthorizationHeaderObjectFromToken,
  getAuthorizationHeaderStringFromObject,
  getAuthorizationHeaderStringFromToken,
} from './headers';
import { AxiosRequestConfig } from 'axios';
import { subscribeToTokenManager, removeTokens, setTokens, getTokens } from './token-manager/token';
import { refresh } from './refresh';
import { Token, TokenManagerSubscribeTypes } from './interfaces';

type AuthorizationState = {
  isValid: boolean;
  header: string;
};

const authorizationState: AuthorizationState = {
  isValid: false,
  header: '',
};

type AuthorizationStateActions = {
  [key in TokenManagerSubscribeTypes]: (token: Token) => void;
};

const validateAuthorizationState = (token: Token): void => {
  authorizationState.isValid = true;
  authorizationState.header = getAuthorizationHeaderStringFromToken(token);
};

const invalidateAuthorizationState = (): void => {
  authorizationState.isValid = false;
  authorizationState.header = '';
};

const authorizationStateActions: AuthorizationStateActions = {
  [TokenManagerSubscribeTypes.SET]: validateAuthorizationState,
  [TokenManagerSubscribeTypes.REVOKE]: invalidateAuthorizationState,
};

subscribeToTokenManager((type, token) => {
  authorizationStateActions[type as TokenManagerSubscribeTypes]?.(token);
});

/**
 * Function to call when receiving a 401 error.
 * This will check if this is the current token, and if it is set the state to invalid.
 * If it is not the current token, or we already invalidated,
 * this is not the first 401 for this token and ignore it.
 * @param config
 */
export const tryInvalidateConfigHeader = (config: AxiosRequestConfig): void => {
  if (
    authorizationState.isValid &&
    getAuthorizationHeaderStringFromObject(config?.headers) === authorizationState.header
  ) {
    authorizationState.isValid = false;
  }
};

let refreshTaskToken: Token | null;
/**
 * Function to call to get the correct header object.
 * Will wait for any refresh task to complete before returning.
 * If there isn't any task running it will instantly resolve with the correct header object.
 */
export const getValidatedAuthorizationHeaderObject = async (): Promise<{
  Authorization: string;
}> => {
  if (authorizationState.isValid) {
    return getAuthorizationHeaderObjectFromString(authorizationState.header);
  }
  if (refreshTaskToken) {
    return getAuthorizationHeaderObjectFromToken(refreshTaskToken);
  }

  try {
    const { refreshToken } = await getTokens();

    const { token: newToken, refreshToken: newRefreshToken } = await refresh({
      refreshToken,
    });

    // Is not in a finally block since this could still be in a promise chain
    refreshTaskToken = null;
    if (newToken && newRefreshToken) {
      await setTokens({
        token: newToken,
        refreshToken: newRefreshToken,
      });

      return getAuthorizationHeaderObjectFromToken(newToken);
    }
    return { Authorization: '' };
  } catch (error) {
    // it's not in a finally block since this could still be in a promise chain
    refreshTaskToken = null;
    // remove tokens
    await removeTokens();
    // continue error cycle
    return Promise.reject(error);
  }
};
