import styled from 'styled-components';
import { Button } from '../Button';

export const Wrapper = styled.div`
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  min-height: 255px;
  padding-left: 30px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background: ${({ theme }) => theme.color9};
`;

export const CardWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const CardTitle = styled.h2`
  padding-bottom: 10px;
  color: ${({ theme }) => theme.color6};
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  font-style: normal;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Content = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

export const TitleWrapper = styled.div``;

export const Section = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color2};
  max-width: 350px;
`;

export const SectionTitle = styled.h2`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.typo2};
  margin-top: 0;
`;

export const LineWrapper = styled.div`
  display: flex;
  margin-top: 22px;
`;

export const DeleteButton = styled(Button)`
  .btn {
    border: 2px solid ${({ theme }) => theme.typo2};
    border-radius: 30px;
    padding-top: 5px;
    flex: 0 0 30px;
  }
`;

interface StatusMessageProps {
  readonly $isClosed: boolean;
}
export const StatusMessage = styled.div<StatusMessageProps>`
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme, $isClosed }) => ($isClosed ? theme.color11 : theme.typo2)};
`;
