import {
  Api,
  getAuthorizationHeaderObjectFromToken,
  fetchSpace,
  formatSpaceItem as formatSpace,
  TokenManager,
  getHost,
  SpaceItemParams,
  SpaceItem,
  ParsedSpaceItem,
} from '@engage-shared/api';

interface FormatSpaceItemParams {
  data: SpaceItem;
  downloadableImageUrl: string;
  enforceAllDayDeskReservations: boolean;
  isUpdate: boolean;
}

export interface FetchAndFormatSpaceParams extends SpaceItemParams {
  isUpdate: boolean;
  enforceAllDayDeskReservations: boolean;
}

export type UseSpaceResponse = ParsedSpaceItem & {
  downloadableImageUrl: string;
};

/**
 * Downloads a file using the provided Serraview image link. The images are
 * hosted on AWS S3. The provided image path URL redirects to the S3 URL.
 * The request fails with code 400 because S3 does not allow the Authorization
 * header.
 * This solution is a hack. We catch the initial error then make another
 * request to S3 without including the Authorization header.
 * @param {String | undefined } downloadUrl The image download URL.
 * @param {String } tenantId The active tenant.
 * @returns {Promise<String> | Promise<null>} Returns the base64 encoded file
 * or null if the request fails.
 */
export const downloadImage = async (
  downloadUrl: string,
  tenantId: string,
): Promise<string | null> => {
  if (!downloadUrl) return null;
  const url = new URL(`${getHost(tenantId)}${downloadUrl}`);
  const { token } = await TokenManager.getTokens();
  const headers = getAuthorizationHeaderObjectFromToken(token);
  let imageBlob = null;
  // we can not fully trust API, so in case there is something wrong with image just return null
  try {
    const response = await Api({
      url: url.toString(),
      method: 'get',
      headers,
      responseType: 'blob',
    });
    imageBlob = response.data;
  } catch (e) {
    console.error(e);
    return null;
  }

  return imageBlob ? URL.createObjectURL(imageBlob) : null;
};

export const formatSpaceItem = ({
  data: spaceItem,
  downloadableImageUrl,
  enforceAllDayDeskReservations,
  isUpdate,
}: FormatSpaceItemParams): UseSpaceResponse => {
  const formattedItem = formatSpace({
    data: spaceItem,
    enforceAllDayDeskReservations,
    isUpdate,
  });

  return {
    ...formattedItem,
    imageUrl: spaceItem.imageUrl,
    downloadableImageUrl,
  };
};

type FetchAndFormatSpace = (params: FetchAndFormatSpaceParams) => Promise<UseSpaceResponse>;

export const fetchAndFormatSpace: FetchAndFormatSpace = async ({
  id,
  isUpdate,
  enforceAllDayDeskReservations,
  startTime,
  endTime,
}) => {
  const data = await fetchSpace({ id, startTime, endTime });

  return formatSpaceItem({
    data,
    downloadableImageUrl: data?.spaceImage?.path || '',
    enforceAllDayDeskReservations,
    isUpdate,
  });
};
