import { useState, ChangeEvent } from 'react';
import { Nullable } from '@engage-shared/utils/types';

const emptyValidator = () => null;

interface UseFormParams {
  initialValues: {
    username: string;
    password: string;
  };
  validators: {
    username: (value: string) => Nullable<string>;
    password: (value: string) => Nullable<string>;
  };
  onUpdateValue?: (evt: ChangeEvent<HTMLInputElement>) => void;
}

export default function useForm({ initialValues, validators, onUpdateValue }: UseFormParams) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({ username: null, password: null });

  const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as keyof UseFormParams['validators'];
    const fieldValue = e.target.value;
    const validator = validators[fieldName] || emptyValidator;
    const errorMessage = validator(fieldValue);

    if (errorMessage) {
      setErrors(oldErrors => ({ ...oldErrors, [fieldName]: errorMessage }));
    } else {
      setErrors(oldErrors => ({ ...oldErrors, [fieldName]: null }));
    }

    setValues(oldValues => ({ ...oldValues, [fieldName]: fieldValue }));
    onUpdateValue?.(e);
  };

  const hasValidationErrors = Object.values(errors).filter(Boolean).length !== 0;

  return { values, updateValue, errors, hasValidationErrors };
}
