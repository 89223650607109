import styled from 'styled-components';

export const DurationButtonFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

export const DurationButtonInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;

  &.focus-visible + span {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

interface DurationButtonLabelStyleProps {
  $hasChineseCharacters: boolean;
  $selected: boolean;
  $disabled: boolean;
}

export const DurationButtonLabel = styled.label<DurationButtonLabelStyleProps>`
  span {
    color: ${({ theme }) => theme.color1};
    font-size: ${({ theme, $hasChineseCharacters }) =>
      theme.isLayoutRTL || $hasChineseCharacters ? '12px' : '14px'};
    font-weight: ${({ $selected, $disabled }) => (!$disabled && $selected ? 'bold' : 'regular')};
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    position: relative;
    user-select: none;
    padding: 5px;
    height: 38px;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 38px;
    border: 2px solid
      ${({ $selected, $disabled, theme }) =>
        $selected && !$disabled ? theme.color9 : theme.color4};
    background-color: ${({ theme }) => theme.color4};
    margin-right: 10px;
  }
`;

export const OrLabel = styled.span`
  color: ${({ theme }) => theme.color2};
  margin-right: 10px;
`;

export const DurationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.legend`
  color: ${({ theme }) => theme.color1};
  font-size: 14px;
  margin-bottom: 18px;
`;
