import * as React from 'react';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { Region, useFloorLocationFetch } from '@engage-shared/api';
import { WAYFINDER_PATH } from '@engage-web/constants';
import { useAppLocation } from '@engage-web/router/hooks';
import logger from '@engage-web/utils/logger';
import { Dispatch } from 'react';

interface ActiveBuilding {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  timeZone: string;
}

export interface LocationState {
  regionPath: number[];
  activeBuilding: ActiveBuilding | null;
  activeFloor: {
    id: number;
    name: string;
  } | null;
}

export const initialState: LocationState = {
  regionPath: [],
  activeBuilding: null,
  activeFloor: null,
};

export const ADD_REGION_TO_PATH = 'addRegionToPath';
export const REMOVE_REGION_FROM_PATH = 'removeRegionFromPath';
export const SET_ACTIVE_BUILDING = 'setActiveBuilding';
export const INITIALIZE_FROM_CURRENT_LOCATION = 'initializeFromCurrentLocation';

export type LocationAction =
  | { type: 'addRegionToPath'; payload: { region: Region; parentId?: number } }
  | { type: 'removeRegionFromPath'; payload: Region }
  | { type: 'setActiveBuilding'; payload: ActiveBuilding }
  | { type: 'initializeFromCurrentLocation'; payload: LocationState };

const reducer = (state: LocationState, action: LocationAction) => {
  switch (action.type) {
    case ADD_REGION_TO_PATH: {
      const {
        payload: { region, parentId },
      } = action;
      const { id } = region;
      const parentIndex = state.regionPath.findIndex(regionId => regionId === parentId);
      let _regionPath = [...state.regionPath];
      if (parentIndex !== -1) {
        _regionPath.splice(parentIndex + 1, 1, id);
      } else {
        _regionPath = [id];
      }
      return {
        ...state,
        regionPath: _regionPath,
      };
    }
    case REMOVE_REGION_FROM_PATH: {
      const { payload: region } = action;
      const regionIndex = state.regionPath.findIndex(regionId => regionId === region.id);
      let _regionPath = [...state.regionPath];
      if (regionIndex !== -1) {
        _regionPath = state.regionPath.slice(0, regionIndex);
      }
      return {
        ...state,
        regionPath: _regionPath,
      };
    }
    case SET_ACTIVE_BUILDING: {
      return {
        ...state,
        activeBuilding: action.payload,
      };
    }
    case INITIALIZE_FROM_CURRENT_LOCATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const init = (initialState: LocationState) => ({
  regionPath: initialState.regionPath,
  activeBuilding: initialState.activeBuilding,
  activeFloor: initialState.activeFloor,
});

export default function useLocationSelector(): [
  state: LocationState,
  dispatch: Dispatch<LocationAction>,
] {
  const { pathname } = useAppLocation();
  const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
  const [state, dispatch] = React.useReducer(reducer, initialState, init);
  const { fetchFloorLocationQuery } = useFloorLocationFetch();

  const isLocationSelectorPath = pathname.endsWith(WAYFINDER_PATH);

  React.useEffect(() => {
    if (isLocationSelectorPath && building && floor) {
      const doFetchFloorRegions = async () => {
        try {
          const floorLocationData = await fetchFloorLocationQuery({
            id: floor.id,
          });
          // filter regions by type Region and return a list of region ids
          const regionPath = floorLocationData.filter(r => r.type === 'Region').map(r => r.id);

          dispatch({
            type: 'initializeFromCurrentLocation',
            payload: {
              regionPath,
              activeBuilding: building,
              activeFloor: floor,
            },
          });
        } catch (e) {
          logger.error(e)();
        }
      };
      doFetchFloorRegions();
    }
  }, [building, floor, fetchFloorLocationQuery, isLocationSelectorPath]);

  return [state, dispatch];
}
