import * as React from 'react';
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { useReservationFilters } from '@engage-web/utils/hooks';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { fetchAndFormatSpace, UseSpaceResponse } from '../spaces';
import { fetchSpace, SpaceItem } from '@engage-shared/api';
import { useFloorLocation } from '@engage-web/api/queries/useFloor';

interface UseSpaceParams {
  id: number;
  isDesk?: boolean;
  isUpdate?: boolean;
  options?: UseQueryOptions<
    UseSpaceResponse,
    unknown,
    UseSpaceResponse,
    [string, string, string, string]
  >;
}

export const SPACE_KEY = 'space';
const STALE_TIME = 10 * 1000;

const useSpaceTime = ({ isDesk }: { isDesk: boolean }) => {
  const { startTime, endTime, enforceAllDayDeskReservations } = useReservationFilters(isDesk);

  return {
    startTime,
    endTime,
    enforceAllDayDeskReservations,
  };
};

export const useSpace = ({
  id,
  isDesk = true,
  isUpdate = false,
  options = {},
}: UseSpaceParams): UseQueryResult<UseSpaceResponse> => {
  const { startTime, endTime, enforceAllDayDeskReservations } = useSpaceTime({
    isDesk,
  });

  return useQuery<UseSpaceResponse, unknown, UseSpaceResponse, [string, string, string, string]>(
    [SPACE_KEY, `${id}`, startTime, endTime],
    () =>
      fetchAndFormatSpace({
        id,
        isUpdate,
        enforceAllDayDeskReservations,
        startTime,
        endTime,
      }),
    {
      staleTime: STALE_TIME,
      ...options,
    },
  );
};

export const useSpaceFetch = ({ isDesk = true, isUpdate = false } = {}) => {
  const queryClient = useQueryClient();
  const { startTime, endTime, enforceAllDayDeskReservations } = useSpaceTime({
    isDesk,
  });

  const query = ({ id }: { id: number }) =>
    queryClient.fetchQuery(
      [SPACE_KEY, `${id}`, startTime, endTime],
      () =>
        fetchAndFormatSpace({
          id,
          isUpdate,
          enforceAllDayDeskReservations,
          startTime,
          endTime,
        }),
      { staleTime: STALE_TIME },
    );

  const fetchSpaceQuery = React.useCallback(query, [
    endTime,
    enforceAllDayDeskReservations,
    isUpdate,
    queryClient,
    startTime,
  ]);

  return { fetchSpaceQuery };
};

export const useRefreshSpace = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = ({ spaceItem }: { spaceItem: SpaceItem }) =>
    queryClient.invalidateQueries([SPACE_KEY, spaceItem.id]);
  const refresh = React.useCallback(invalidateQuery, [queryClient]);
  return { refresh };
};

export const useSpaceLocation = () => {
  const currentLocation = useSelector(tenantSelectors.getCurrentLocation);
  const { setFloorLocation } = useFloorLocation();

  const setSpaceLocation = React.useCallback(
    async ({ spaceId }: { spaceId: number }) => {
      let floorLocation = currentLocation;
      if (spaceId) {
        const data = await fetchSpace({ id: spaceId });
        if (data?.floorId && data?.floorName) {
          floorLocation = await setFloorLocation(data.floorId);
        }
      }
      return floorLocation;
    },
    [currentLocation, setFloorLocation],
  );
  return { setSpaceLocation };
};
