import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Icon } from '../Icons/Icon';
import {
  ClearIconWrapper,
  SearchIconWrapper,
  StyledSearchInput,
  SearchWrapper,
} from './SearchInput.style';

type SearchInputProps = {
  defaultValue?: string;
  setSearchValue?: (text: string) => void;
  clearSearchValue?: () => void;
  searchPlaceholderText?: string;
};

const SearchInput = ({
  defaultValue = '',
  setSearchValue,
  clearSearchValue,
  searchPlaceholderText,
}: SearchInputProps) => {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState(defaultValue);
  const hasSearchValue = searchQuery.trim() !== '';

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onSearchClear = () => {
    setSearchQuery('');
  };

  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (debouncedSearchQuery) {
      if (typeof setSearchValue === 'function') {
        setSearchValue(debouncedSearchQuery);
      }
    } else if (typeof clearSearchValue === 'function') {
      clearSearchValue();
    }
  }, [setSearchValue, clearSearchValue, debouncedSearchQuery, dispatch]);

  return (
    <SearchWrapper>
      <SearchIconWrapper>
        <Icon name="search" size={12} />
      </SearchIconWrapper>
      <StyledSearchInput
        type="text"
        aria-label={searchPlaceholderText}
        placeholder={searchPlaceholderText}
        onChange={onSearchChange}
        value={searchQuery}
        data-testid="search-input"
      />
      {hasSearchValue && (
        <ClearIconWrapper onClick={onSearchClear} data-testid="clear-button">
          <Icon name="close" size={12} />
        </ClearIconWrapper>
      )}
    </SearchWrapper>
  );
};

export default memo(SearchInput);
