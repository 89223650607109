import React from 'react';
import { createContext, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { useTeamReservationEvents } from '../hooks/useTeamReservationEvents';

export type TeamReservationSelectionStateContextValue = {
  isTeamSizeReached: boolean;
  updateSelectedDesksStore: (desks: number[]) => void;
  updateSelectedDesks: (desks: number[]) => void;
  eraseDesks: (desks: number[]) => void;
  addDesks: (desks: number[]) => void;
  selectedDesks: number[];
  selectedDesksSync: number[];
  teamReservationSelectionMode: string;
};

const NOOP = () => {};

export const TeamReservationSelectionStateContext =
  createContext<TeamReservationSelectionStateContextValue>({
    isTeamSizeReached: true,
    updateSelectedDesksStore: NOOP,
    updateSelectedDesks: NOOP,
    eraseDesks: NOOP,
    addDesks: NOOP,
    selectedDesks: [],
    selectedDesksSync: [],
    teamReservationSelectionMode: '',
  });

export const TeamReservationSelectionStateProvider = ({ children }: PropsWithChildren) => {
  const [selectedDesks, setSelectedDesks] = useState<number[]>([]);
  const selectedDesksSync = useRef<number[]>([]);

  const updateSelectedDesks = useCallback((desks: number[]) => {
    selectedDesksSync.current = desks;
    setSelectedDesks(desks);
  }, []);

  const { mode, teamSize, ...rest } = useTeamReservationEvents({
    selectedDesks,
    updateSelectedDesks,
  });

  const eraseDesks = (desks: number[]) => {
    updateSelectedDesks(selectedDesksSync.current.filter(d => !desks.includes(d)));
  };

  const addDesks = (desks: number[]) => {
    let newDesks = Array.from(new Set(selectedDesksSync.current.concat(desks)));
    // sometimes calculations is not as fast as mouse move,
    // so we need to make sure we are not fall over limits
    if (newDesks.length > teamSize) {
      newDesks = newDesks.splice(0, teamSize);
    }
    updateSelectedDesks(newDesks);
  };

  const isTeamSizeReached = selectedDesks.length >= teamSize;

  return (
    <div>
      <TeamReservationSelectionStateContext.Provider
        value={{
          isTeamSizeReached,
          updateSelectedDesks,
          eraseDesks,
          addDesks,
          selectedDesks,
          selectedDesksSync: selectedDesksSync.current,
          teamReservationSelectionMode: mode,
          ...rest,
        }}
      >
        {children}
      </TeamReservationSelectionStateContext.Provider>
    </div>
  );
};
