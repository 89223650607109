import React from 'react';
import { Navigate } from 'react-router-dom';
import { Person } from '@engage-shared/api/people';
import { useAppLocation } from '@engage-web/router/hooks';
import { generateFloorPath, joinPaths } from '@engage-web/router/utils';
import { PATH_SEGMENT, ROOT_PATH } from '@engage-web/constants';
import { EmployeeContainer } from './EmployeeContainer';

export function Employee() {
  const { search } = useAppLocation();

  const renderPersonRedirect = (person: Person) => {
    const { id, primaryLocation } = person;
    let path = joinPaths(ROOT_PATH, PATH_SEGMENT.PEOPLE, `${id}`);

    if (primaryLocation?.floorId) {
      path = joinPaths(
        generateFloorPath(`${primaryLocation?.floorId}`),
        PATH_SEGMENT.PEOPLE,
        `${id}`,
      );
    }

    return <Navigate replace to={{ pathname: path, search }} state={{ person }} />;
  };

  return <EmployeeContainer render={renderPersonRedirect} />;
}
