import { fetchUser } from '@engage-shared/api/users';
import { ParsedUserData, UserData } from '@engage-shared/api/users/interfaces';
import { getUserDefaultLocation } from '@engage-shared/utils/location';
import { UserLocation } from '@engage-shared/utils/location/interfaces';
import { Nullable } from '@engage-shared/utils/types';
import { getLogger } from '@engage-shared/utils/logger';
import { TenantId } from '@engage-shared/api/tenant/interfaces';

type ParsedUserDataParams = {
  userId: number;
  user: UserData;
  userLocation: Nullable<UserLocation>;
};

type ParsedUserDataFn = (param: ParsedUserDataParams) => ParsedUserData;

const parsedUserData: ParsedUserDataFn = ({ userId, user, userLocation }) => {
  const { id, firstName, lastName } = user;
  const userData: ParsedUserData = {
    ...user,
    userId,
    personId: id,
    name: `${firstName} ${lastName}`.trim(),
  };
  if (userLocation?.floor) {
    userData.userLocation = userLocation;
  }
  return userData;
};

export type GetUserDataParams = {
  tenantId: TenantId;
  userId: number;
};

type GetUserData = (param: GetUserDataParams) => Promise<ParsedUserData | null>;

export const getUserData: GetUserData = async ({ userId }) => {
  try {
    const user = await fetchUser({ userId });
    if (user) {
      const { location } = user;
      const userLocation = await getUserDefaultLocation({
        location,
      });
      return parsedUserData({ userId, user, userLocation });
    }
  } catch (e) {
    // the error is already log by API
    getLogger().warn('Cannot fetch user data');
  }
  return null;
};
