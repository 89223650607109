import React from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren as ProgressBar,
} from 'react-circular-progressbar';
import { CircularProgressbarProps as BaseCircularProgressbarProps } from 'react-circular-progressbar/dist/types';
import 'react-circular-progressbar/dist/styles.css';
import { useTheme } from 'styled-components';
import { Theme } from '@engage-shared/theme';
import { DEFAULT_SIZE, ProgressText, Wrapper } from './CircularProgressBar.style';

export type CircularProgressbarProps = Partial<BaseCircularProgressbarProps> & {
  value: number;
  size?: number;
};

export const CircularProgressBar = ({ size = DEFAULT_SIZE, ...rest }: CircularProgressbarProps) => {
  const { color5, typo2 } = useTheme() as Theme;

  return (
    <Wrapper size={size}>
      <ProgressBar
        strokeWidth={16}
        styles={buildStyles({
          strokeLinecap: 'round',
          pathTransitionDuration: 0.5,
          pathColor: typo2,
          trailColor: color5,
          textSize: 0,
        })}
        {...rest}
      >
        <ProgressText color={typo2}>{rest.text}</ProgressText>
      </ProgressBar>
    </Wrapper>
  );
};
