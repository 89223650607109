import * as React from 'react';
import { Space } from '@floorplan/api';
import { ThemeManager } from '../../../theme';
import { getContrastingColor } from '../../../utils';
import { CommonMarker, MARKER } from './CommonMarker';

const ICON_SIZE = 26;

interface IconProps {
  color: string;
}
export const SpaceIcon = ({ color }: IconProps) => {
  return (
    <svg
      x={MARKER.CENTER - ICON_SIZE / 2}
      y={MARKER.CENTER - ICON_SIZE / 2}
      width={ICON_SIZE}
      height={ICON_SIZE}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M8.39891 16.1644C8.39891 16.0233 8.39891 15.8854 8.39891 15.7629C8.39891 14.6273 7.06473 13.6722 5.25709 13.3998C5.76109 13.1482 6.10909 12.6285 6.10909 12.029V10.4974C6.10909 9.65496 5.42182 8.96573 4.58182 8.96573H3.81818C2.97818 8.96573 2.29091 9.65496 2.29091 10.4974V12.029C2.29091 12.6274 2.63891 13.1471 3.14182 13.3987C1.33418 13.6722 0 14.6273 0 15.7629C0 15.8865 0 16.0233 0 16.1644C0.0130909 16.5025 0.156 16.8241 0.400364 17.055C0.649091 17.288 0.970909 17.4061 1.30909 17.3886H7.08982C7.11273 17.3908 7.13564 17.3908 7.15854 17.3908C7.47164 17.3908 7.76727 17.2738 7.99854 17.056C8.24291 16.8241 8.38691 16.5025 8.39891 16.1644Z"
      />
      <path
        fill={color}
        d="M16.2 19.7736C16.2 19.6324 16.2 19.4946 16.2 19.3721C16.2 18.2365 14.8659 17.2814 13.0582 17.009C13.5611 16.7573 13.9091 16.2377 13.9091 15.6392V14.1076C13.9091 13.2652 13.2219 12.576 12.3819 12.576H11.6182C10.7782 12.576 10.091 13.2652 10.091 14.1076V15.6392C10.091 16.2377 10.439 16.7584 10.9419 17.009C9.13423 17.2825 7.80005 18.2365 7.80005 19.3721C7.80005 19.4957 7.80005 19.6324 7.80005 19.7736C7.81314 20.1116 7.95605 20.4333 8.20041 20.6641C8.44914 20.8971 8.77096 21.0153 9.10914 20.9978H14.8899C14.9128 21 14.9357 21 14.9586 21C15.2706 21 15.5673 20.8829 15.7986 20.6652C16.044 20.4333 16.188 20.1116 16.2 19.7736Z"
      />
      <path
        fill={color}
        d="M24.0001 16.1645C24.0001 16.0234 24.0001 15.8855 24.0001 15.763C24.0001 14.6274 22.6659 13.6723 20.8583 13.3999C21.3612 13.1483 21.7092 12.6286 21.7092 12.0302V10.4985C21.7092 9.65612 21.0219 8.96689 20.1819 8.96689H19.4183C18.5783 8.96689 17.891 9.65612 17.891 10.4985V12.0302C17.891 12.6286 18.239 13.1483 18.7419 13.3999C16.9343 13.6734 15.6001 14.6285 15.6001 15.7641C15.6001 15.8877 15.6001 16.0244 15.6001 16.1656C15.6132 16.5036 15.7561 16.8253 16.0005 17.0561C16.2492 17.2891 16.571 17.4073 16.9092 17.3898H22.6899C22.7128 17.392 22.7357 17.392 22.7586 17.392C23.0706 17.392 23.3674 17.2749 23.5986 17.0572C23.8441 16.8242 23.987 16.5025 24.0001 16.1645Z"
      />
      <path
        fill={color}
        d="M19.5272 3H5.07268C4.59268 3 4.19995 3.39385 4.19995 3.87522V4.3358V4.79639V7.87279H4.58068C6.02395 7.87279 7.19886 9.05105 7.19886 10.4984V12.0301C7.19886 12.2883 7.16177 12.5388 7.09195 12.7773C7.93631 13.1329 8.58759 13.6449 8.99995 14.251V14.1076C8.99995 12.6602 10.1749 11.482 11.6181 11.482H12.3818C13.8261 11.482 14.9999 12.6602 14.9999 14.1076V14.2761C15.409 13.6613 16.0538 13.1372 16.9069 12.7773C16.837 12.5377 16.7999 12.2872 16.7999 12.029V10.4974C16.7999 9.04996 17.9749 7.8717 19.4181 7.8717H20.1818C20.257 7.8717 20.3269 7.88811 20.3999 7.89358V4.7953V4.3358V3.87522C20.3989 3.39385 20.0072 3 19.5272 3Z"
      />
    </svg>
  );
};

export const DeskIcon = ({ color }: IconProps) => {
  return (
    <svg
      x={MARKER.CENTER - ICON_SIZE / 2}
      y={MARKER.CENTER - ICON_SIZE / 2}
      width={ICON_SIZE}
      height={ICON_SIZE}
      viewBox="0 0 18 18"
    >
      <path
        fill={color}
        d="M15.3008 9.5089V6.58616C15.3008 6.36983 15.3959 6.16287 15.5645 6.01022C15.7331 5.85758 15.9627 5.77142 16.2005 5.77142H17.1003C17.5977 5.77142 18 6.13665 18 6.58616C18 7.0366 17.5967 7.40089 17.1003 7.40089V9.84603C17.1003 9.95279 17.0775 10.0586 17.032 10.1579C16.9865 10.2571 16.9203 10.347 16.8366 10.422L16.1561 11.0382C16.0413 10.4688 15.7072 9.95466 15.2129 9.58663L15.3008 9.5089Z"
      />
      <path
        fill={color}
        d="M1.80052 5.77142C2.03942 5.77142 2.26797 5.85758 2.43654 6.01022C2.60511 6.16287 2.70026 6.37077 2.70026 6.58616V9.5089L2.78713 9.58756C2.29279 9.95466 1.95875 10.4688 1.84395 11.0391L1.16346 10.4229C1.07969 10.347 1.0135 10.2571 0.967999 10.1588C0.922494 10.0595 0.89974 9.95372 0.89974 9.84697V7.40089C0.402297 7.40089 0 7.03567 0 6.58616C0 6.13571 0.403331 5.77142 0.89974 5.77142H1.80052Z"
      />
      <path
        fill={color}
        d="M4.50077 9.84619H13.5013C13.978 9.84713 14.4362 10.0185 14.7733 10.3238C15.1105 10.6291 15.3008 11.0439 15.3008 11.4757V12.2904C15.3008 12.5067 15.2056 12.7137 15.0371 12.8663C14.8685 13.019 14.6389 13.1051 14.401 13.1051H9.90025V15.5549H14.401C14.6399 15.5549 14.8685 15.6411 15.0371 15.7937C15.2056 15.9464 15.3008 16.1543 15.3008 16.3697V18.0001H13.5003V17.1854H9.90025V18.0001H8.09974V17.1854H4.49974V18.0001H2.69922V16.3697C2.69922 16.1534 2.79436 15.9464 2.96294 15.7937C3.13151 15.6411 3.3611 15.5549 3.59896 15.5549H8.09974V13.1051H3.59896C3.36006 13.1051 3.13151 13.019 2.96294 12.8663C2.79436 12.7137 2.69922 12.5058 2.69922 12.2904V11.4757C2.70025 11.043 2.88951 10.6291 3.22665 10.3238C3.56586 10.0185 4.02298 9.84713 4.50077 9.84619Z"
      />
      <path
        fill={color}
        d="M4.50081 9.03137C4.19366 9.03137 3.88961 9.07819 3.60107 9.17184V2.44514C3.60211 1.7971 3.88651 1.17528 4.39326 0.71734C4.89898 0.259404 5.58568 0.000936476 6.30133 0H11.7019C12.4175 0.000936476 13.1042 0.258467 13.6099 0.71734C14.1156 1.17528 14.4011 1.7971 14.4021 2.44514V9.17091C14.1136 9.0782 13.8085 9.03044 13.5024 9.03044H4.50081V9.03137Z"
      />
    </svg>
  );
};

interface SpaceMarkerProps {
  space: Space;
}
export const SpaceMarker = ({ space }: SpaceMarkerProps) => {
  const { color6, color9, typo2 } = ThemeManager.getGlobalTheme();
  const Icon = space.isDesk ? DeskIcon : SpaceIcon;
  const iconColor = getContrastingColor(color9, color6, typo2);
  return (
    <CommonMarker space={space}>
      <Icon color={iconColor} />
    </CommonMarker>
  );
};
