import * as React from 'react';
import { DateFormat, formatLocalizedDate, getUtcIsoString } from '@engage-shared/utils';
import { DayWrapper } from './styled';
import { LanguageTypes } from '@engage-shared/constants';

export type PressDayFn = (day: IDay) => void;

interface IDay {
  date: Date;
  label: string;
  isCurrentDate?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
}
interface DayProps {
  day: IDay;
  onPressDay?: PressDayFn;
  locale?: LanguageTypes;
}

export const Day = React.memo(({ onPressDay, day, locale }: DayProps) => {
  const { date, label, isCurrentDate, isSelected, isDisabled } = day;
  const id = getUtcIsoString(date);
  const name = formatLocalizedDate(date, {
    locale,
    format: DateFormat.weekDayLong,
  });

  const handleClick = () => {
    if (isDisabled) return;
    onPressDay?.(day);
  };

  const isDayDisabled = isDisabled ?? false;
  const isDaySelected = isSelected ?? false;
  const isDayCurrentDate = isCurrentDate ?? false;

  return (
    <DayWrapper
      key={id}
      $isDisabled={isDayDisabled}
      $isSelected={isDaySelected}
      $isCurrentDate={isDayCurrentDate}
      data-testid="day_btn"
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        onClick={handleClick}
        aria-label={name}
        data-testid={formatLocalizedDate(date, {
          locale: LanguageTypes.en,
          format: DateFormat.weekDayLong,
        })}
        aria-checked={isSelected}
        aria-disabled={isDisabled}
      />
      <label htmlFor={id}>{label}</label>
    </DayWrapper>
  );
});
