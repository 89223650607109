import { generateFloorPath } from '@engage-web/router/utils';
import { Nullable } from '@engage-shared/utils/types';
import { useFloorLocation } from '@engage-web/api/queries';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Analytics from '@engage-web/utils/analytics';
import { navigationActions } from '@engage-web/store';
import { BookingDetails, TeamBookingDetails } from '@engage-shared/api';

type UseShowLocationParams = {
  booking?: Nullable<BookingDetails | TeamBookingDetails>;
  addUrlSuffix: (location: string) => string;
};

export const useShowLocation = ({ booking, addUrlSuffix }: UseShowLocationParams) => {
  const { setFloorLocation } = useFloorLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateLocation = useCallback(async () => {
    if (booking?.location) {
      const { floor } = await setFloorLocation(booking.location.floorId);
      const floorLocationUrl = generateFloorPath(floor.id);
      const url = addUrlSuffix(floorLocationUrl);

      navigate(url);
    }
  }, [addUrlSuffix, booking, navigate, setFloorLocation]);

  const showLocation = useCallback(async () => {
    await updateLocation();
    dispatch(navigationActions.clearModal());
    Analytics.track('EVT-079');
  }, [dispatch, updateLocation]);

  return {
    showLocation,
  };
};
