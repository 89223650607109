import { Nullable } from '@engage-shared/utils/types';
import { isElectron } from '@engage-web/utils/electron';
import { getActionFromEngageUrl, isBrowserUrl } from '@engage-shared/api';
import { MENU_ACTION_TYPES } from '@engage-shared/constants';

type IsMenuItemHidden = (
  url: Nullable<string>,
  hiddenForWeb: boolean,
  hiddenForKiosk: boolean,
  env: string | undefined,
) => boolean;

const HIDDEN_KIOSK_URLS: Array<Nullable<string>> = [
  'engage://bookings',
  'engage://keyboard_accessibility',
];

/**
 * Get menu item visibility. Returns false for non-engage urls.
 * The hidden menu items are removed by the server. The hidden property will always be false unless we hide the development features.
 * @param {string} url - Concierge menu item url
 * @param {boolean} hiddenForWeb - true if the item should be hidden for the web platform.
 * @param {boolean} hiddenForKiosk - true if the item should be hidden for kiosks.
 * @param {string} env - process.env.NODE_ENV it's passed as argument to make testing easier.
 * @returns {boolean}
 */
export const getIsMenuItemHidden: IsMenuItemHidden = (url, hiddenForWeb, hiddenForKiosk, env) => {
  const isKiosk = isElectron();

  if (!isKiosk && hiddenForWeb) return true;
  if (isKiosk && (hiddenForKiosk || HIDDEN_KIOSK_URLS.includes(url))) return true;

  if (env === 'development' || isBrowserUrl(url)) return false;

  const { action } = getActionFromEngageUrl(url);

  return (
    action === MENU_ACTION_TYPES.DEVELOPMENT ||
    action === MENU_ACTION_TYPES.DEMONSTRATION_CONFIG ||
    action === MENU_ACTION_TYPES.QR_SCAN
  );
};
