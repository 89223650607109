import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetchEmployee } from '@engage-shared/api/people/fetch';
import { PersonData } from '@engage-shared/api/people/interfaces';
import { useCallback } from 'react';

const EMPLOYEE_KEY = 'employee';

type UseEmployeeQueryParams = {
  id: string;
  dateStart: Date;
  dateEnd: Date;
  options?: UseQueryOptions<PersonData[], unknown, PersonData[], [string, string, Date, Date]>;
};

type UseEmployeeQuery = (params: UseEmployeeQueryParams) => UseQueryResult<PersonData[]>;

export const useEmployeeQuery: UseEmployeeQuery = ({ id, dateStart, dateEnd, options }) => {
  return useQuery<PersonData[], unknown, PersonData[], [string, string, Date, Date]>(
    [EMPLOYEE_KEY, id, dateStart, dateEnd],
    () => fetchEmployee({ id, dateStart, dateEnd }),
    {
      enabled: !!id,
      ...options,
    },
  );
};

type UseEmployeeFetchParams = {
  dateStart: Date;
  dateEnd: Date;
};

type UseEmployeeFetch = (params: UseEmployeeFetchParams) => {
  fetchEmployeeQuery: (id: string) => Promise<PersonData>;
};

export const useEmployeeFetch: UseEmployeeFetch = ({ dateStart, dateEnd }) => {
  const queryClient = useQueryClient();

  const query = (id: string): Promise<PersonData> =>
    queryClient.fetchQuery([EMPLOYEE_KEY, id, dateStart, dateEnd], () =>
      fetchEmployee({ id, dateStart, dateEnd }),
    ) as Promise<PersonData>;

  const fetchEmployeeQuery = useCallback(query, [queryClient, dateStart, dateEnd]);

  return { fetchEmployeeQuery };
};
