import { useMemo } from 'react';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useAppLocation } from '@engage-web/router/hooks';

export type RestrictTo = 'Space' | 'Desk' | 'Person' | 'Team' | null;

export const useRestrictTo = (): RestrictTo => {
  const { pathname } = useAppLocation();

  return useMemo(() => {
    if (pathname.includes(`/${PATH_SEGMENT.SPACES}`)) return 'Space';
    if (pathname.includes(`/${PATH_SEGMENT.DESKS}`)) return 'Desk';
    if (pathname.includes(`/${PATH_SEGMENT.PEOPLE}`)) return 'Person';
    if (pathname.includes(`/${PATH_SEGMENT.TEAMS}`)) return 'Team';
    return null;
  }, [pathname]);
};
