import { useTranslation } from 'react-i18next';
import { useAppParams } from '@engage-web/router/hooks';
import { usePerson, usePersonImage } from '@engage-web/api/queries';
import {
  getPersonImageUrl,
  isDownloadPath,
} from '@engage-web/components/layouts/Person/Card/Details/utils';
import { getCountryColorByInitials, getInitials } from '@engage-shared/utils';

export const useFloorplanPersonMarker = () => {
  const { t } = useTranslation();
  const { personId } = useAppParams();
  const id = personId ? parseInt(personId, 10) : 0;

  const personQuery = usePerson({
    id,
    options: {
      enabled: !!personId,
    },
  });

  const person = personQuery.data;
  const imageUrl = person?.imageUrl;

  const isDownloadImage = isDownloadPath(imageUrl);
  const personImageQuery = usePersonImage({
    imageUrl: imageUrl ?? '',
    enabled: isDownloadImage && !!imageUrl,
  });
  const personImageUrl = getPersonImageUrl({
    imageUrl,
    isDownloadImage,
    isSuccess: personImageQuery.isSuccess,
    fileImageUrl: personImageQuery?.data ?? null,
  });

  if (!person) {
    return null;
  }

  const personName = person?.personName;
  const profanities = t('profanities', { returnObjects: true });
  const initials = getInitials(personName);
  const { backgroundColor, textColor } = getCountryColorByInitials(initials);
  const displayName = profanities.indexOf(initials) === -1 ? initials : initials[0];

  return {
    id: person?.id,
    imageUrl: personImageUrl,
    displayName,
    backgroundColor,
    textColor,
  };
};
