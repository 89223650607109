import React, { memo, ReactElement, ReactNode } from 'react';
import { A11yHiddenLabel } from '@engage-web/styles';
import { AvatarImageSize, AvatarImageVariant, AvatarStyle } from './Avatar.types';
import { Nullable } from '@engage-shared/utils/types';
import { badgeColor, sizeDef, STATUS } from './config';
import { Text } from '../Text/Text';
import { Badge, BadgeWrapper } from './styled';

const MAX_SIZE = 24;
const DIMENSION_FACTOR = 2.5;
const BORDER_WIDTH = 3;
const emptyObject = {};

export type BadgeAnchor = {
  horizontal: 'left' | 'right' | 'center';
  vertical: 'top' | 'bottom';
};
export type BadgeSize = AvatarImageSize | number;
export type BadgeVariant = 'standard' | 'dot';
const DEFAULT_ANCHOR: BadgeAnchor = {
  horizontal: 'left',
  vertical: 'top',
};

const getBadgeColor = (color: string | STATUS) => {
  if (!color) return badgeColor.primary;
  // @ts-ignore
  return badgeColor[color] || color;
};

const getBadgePosition = (
  badgeSize: BadgeSize,
  anchor: BadgeAnchor,
  overlap: AvatarImageVariant,
  dimensions: number,
) => {
  const { horizontal, vertical } = anchor;
  let xy = -badgeSize / 2; // rectangle, rounded
  if (overlap === 'circle') {
    xy += dimensions / (BORDER_WIDTH * DIMENSION_FACTOR);
  }
  if (overlap === 'side') {
    xy += (dimensions * 1.9) / (BORDER_WIDTH * DIMENSION_FACTOR);
  }
  return {
    [horizontal]: xy,
    [vertical]: xy,
  };
};

const getBadgeStyle = (
  size: BadgeSize,
  maxSize: number,
  color: string | STATUS,
  anchor: BadgeAnchor,
  overlap: AvatarImageVariant,
) => {
  let dimensions;
  if (typeof size === 'number') {
    dimensions = size;
  } else {
    dimensions = sizeDef[size] || sizeDef.medium;
  }
  const backgroundColor = getBadgeColor(color);
  let badgeSize = dimensions / DIMENSION_FACTOR;
  if (badgeSize >= maxSize) {
    badgeSize = maxSize;
  }
  const position = getBadgePosition(badgeSize, anchor, overlap, dimensions);
  return {
    width: badgeSize,
    height: badgeSize,
    borderRadius: badgeSize,
    backgroundColor,
    position,
  };
};

export type AvatarBadgeProps = {
  color?: string | STATUS;
  badgeContent?: Nullable<ReactElement<any>>;
  badgeA11yLabel?: string;
  max?: number;
  size?: BadgeSize;
  maxSize?: number;
  variant?: BadgeVariant;
  anchor?: BadgeAnchor;
  overlap?: AvatarImageVariant;
  style?: AvatarStyle;
  children: ReactNode;
};

const AvatarBadge = ({
  color = 'primary',
  badgeContent,
  badgeA11yLabel,
  max = 99,
  size = 'medium',
  maxSize = MAX_SIZE,
  variant = 'standard',
  anchor = DEFAULT_ANCHOR,
  overlap = 'circle',
  style = emptyObject,
  children,
}: AvatarBadgeProps) => {
  const { height, width, borderRadius, backgroundColor, position } = getBadgeStyle(
    size,
    maxSize,
    color,
    anchor,
    overlap,
  );
  // @ts-ignore
  const wrapperSize = sizeDef[size] || size.medium;

  const textStyle: any = {
    whiteSpace: 'nowrap',
  };

  const renderComponent = () => {
    if (!badgeContent) {
      return null;
    }

    const { style, size } = badgeContent.props;
    if (style) {
      style.color = style.color || backgroundColor;
    }
    const sizeProp = size || height;
    // Icon or Text
    const inner = React.cloneElement(badgeContent, {
      ...badgeContent.props,
      style,
      size: sizeProp,
    });
    return (
      <Badge key="component" style={{ ...position }} data-testid="badgeComponent">
        <Text>{inner}</Text>
      </Badge>
    );
  };

  const getText = () => {
    // @ts-ignore
    const displayValue = (badgeContent || 0) > max ? `${max}+` : badgeContent;

    textStyle.fontSize = height / 2;
    textStyle.lineHeight = `${height}px`;
    textStyle.paddingLeft = 8;
    textStyle.paddingRight = 8;

    return (
      <Text
        style={{
          ...textStyle,
          paddingHorizontal: 3,
        }}
        data-testid="badgeText"
      >
        {displayValue}
      </Text>
    );
  };

  // priority: badgeContent > status
  const renderContent = () => {
    if (React.isValidElement(badgeContent)) {
      return renderComponent();
    }
    if (badgeContent || variant === 'dot') {
      let content = null;
      if (variant === 'standard') {
        content = getText();
      }
      return (
        <Badge
          key="text"
          style={{
            height,
            minWidth: width,
            backgroundColor,
            borderRadius,
            ...position,
            ...style,
          }}
          data-testid="badge"
          $borderWidth={BORDER_WIDTH}
        >
          {badgeA11yLabel && <A11yHiddenLabel>{badgeA11yLabel}</A11yHiddenLabel>}
          {content}
        </Badge>
      );
    }
    return null;
  };

  return (
    <BadgeWrapper
      className="avatar__badge-wrapper"
      style={{ width: wrapperSize, height: wrapperSize }}
    >
      {children}
      {renderContent()}
    </BadgeWrapper>
  );
};

export default memo(AvatarBadge);
