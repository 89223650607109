function getRgbColor(strColor: string) {
  const s = new Option().style;
  s.color = strColor;
  return s.color;
}

function checkedAlphaValue(alpha: number) {
  const a = Number(alpha);
  // eslint-disable-next-line
  if (isNaN(a)) throw new Error('Invalid alpha - not a number');
  if (a > 1 || a < 0) throw new Error('Invalid alpha - should be 0>= alpha <=1');
  return a;
}

function getRGBAObject(color: string) {
  const rgb = getRgbColor(color);

  if (!rgb) throw new Error('Not a valid color');
  const [r, g, b, a] = rgb
    .slice(rgb.indexOf('(') + 1, color.indexOf(')'))
    .split(',')
    .map(c => parseInt(c, 10));
  return {
    r,
    g,
    b,
    a: a || a === 0 ? a : 1,
  };
}

export function setAlpha(color: string, alpha: number) {
  try {
    const { r, g, b } = getRGBAObject(color);
    const checkedAlpha = checkedAlphaValue(alpha);
    return `rgba(${r},${g},${b},${checkedAlpha})`;
  } catch (error) {
    // not sure should this rethrow
    // we might break app over smaller detail
    return 'rgba(0,0,0,0)';
    // TODO: reporting
  }
}

export function getRGBA(color: string) {
  const { r, g, b, a } = getRGBAObject(color);
  return `rgba(${r},${g},${b},${a || 1})`;
}

export const convertHexToRGBArray = (hex: string) =>
  // @ts-ignore
  hex.match(/\w\w/g).map(x => parseInt(x, 16));

/**
 * Get the contrasting color for any hex color.
 * @param bgColor A hexColor color value
 * @param lightColor A light color value
 * @param darkColor A dark color value
 * @return {String} The contrasting color (darkColor or lightColor)
 */
export const getContrastingColor = (bgColor: string, lightColor: string, darkColor: string) => {
  let hexColor = bgColor;
  // If a leading # is provided, remove it
  if (hexColor.slice(0, 1) === '#') {
    hexColor = hexColor.slice(1);
  }

  // If a three-character hexCode, make six-character
  if (hexColor.length === 3) {
    hexColor = hexColor
      .split('')
      .map(hex => hex + hex)
      .join('');
  }

  // Convert to RGB value
  const [r, g, b] = convertHexToRGBArray(hexColor);

  // Get YIQ (color space) ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? darkColor : lightColor;
};
