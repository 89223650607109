import styled from 'styled-components';
import { Z_INDEX } from '@engage-web/constants';

export const Wrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.typo2};
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: ${Z_INDEX.DIALOG};
  background-color: #fff;
  border-radius: 8px;
  padding: 36px;
  box-shadow: 0px 40px 60px 0px #00000026;
  max-width: 440px;
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
`;

export const CommonMessage = styled.span<{ fontSize?: number }>`
  font-size: ${({ fontSize = 18 }) => fontSize}px;
  text-align: center;
`;

export const PrimaryButton = styled.button`
  display: inline-flex;
  width: 176px;
  height: 60px;
  align-items: center;
  background-color: ${({ theme }) => theme.color9};
  color: ${({ theme }) => theme.typo1};
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  cursor: pointer;
  padding: 18px 18px 18px 17px;
  gap: 10px;
  box-shadow: 0px 20px 20px rgba(84, 96, 111, 0.2);
  border-radius: 200px;

  &:active {
    opacity: 0.85;
  }
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.typo2};
  gap: 10px;
  border-radius: 200px;
  box-shadow: none;
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
