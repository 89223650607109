type GetIsBookingsAllowed = (args: {
  config: {
    enforceBookingsAllowed: boolean;
    bookingDeniedMessage: string;
  };
  userData: {
    bookingsAllowed: boolean;
  };
}) => {
  bookingsAllowed: boolean;
  bookingDeniedMessage?: string;
};

export const getIsBookingsAllowed: GetIsBookingsAllowed = ({ config, userData }) => {
  const { enforceBookingsAllowed, bookingDeniedMessage } = config;
  const bookingsAllowed = userData?.bookingsAllowed;

  if (!enforceBookingsAllowed) {
    return { bookingsAllowed: true };
  }

  if (!bookingsAllowed) {
    return {
      bookingsAllowed: false,
      bookingDeniedMessage,
    };
  }

  return { bookingsAllowed: true };
};
