// moment format
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_TIME_FORMAT = 'HH:mm';
export const MOMENT_TIME_12H_FORMAT = 'hh:mm A';

//date-fns format
// export const DATE_FORMAT = 'yyyy-MM-dd';

export const QUARTER_HOUR_VALUES: number[] = [0, 15, 30, 45];
// HOURS_12_VALUES are prepend with value of 12 in order to avoid concating in business logic
export const HOURS_12_VALUES: number[] = [12].concat(Array.from({ length: 11 }, (_, k) => k + 1)); // [12,1..11]
export const HOURS_24_VALUES: number[] = Array.from({ length: 24 }, (_, k) => k); // [0..23]

export const PERIOD_AM = 'am';
export const PERIOD_PM = 'pm';
export const HOURS_12_PERIODS: string[] = [PERIOD_AM, PERIOD_PM];

// INTL
export enum DateFormatters {
  DayNumeric = 'dayNumeric',
  HourNumeric = 'hourNumeric',
  MonthLong = 'monthLong',
  MonthShort = 'monthShort',
  MonthShortWithDay = 'monthShortWithDay',
  Time2Digit = 'time2Digit',
  WeekDayLong = 'weekDayLong',
  WeekDayLongWithMonthAndDay = 'weekDayLongWithMonthAndDay',
  WeekDayNarrow = 'weekDayNarrow',
  WeekDayShort = 'weekDayShort',
  WeekdayShortWithMonthAndDay = 'weekdayShortWithMonthAndDay',
  WeekdayShortWithMonthAndDayAndTime = 'weekdayShortWithMonthAndDayAndTime',
  WeekDayShortWithTime = 'weekDayShortWithTime',
  YearISO = 'YearISO',
  YearWithShortMonthAndDay = 'yearWithShortMonthAndDay',
}

type DateFormatTypes = {
  [key in DateFormatters]: Intl.DateTimeFormatOptions;
};

export const DateFormat: DateFormatTypes = {
  [DateFormatters.DayNumeric]: {
    day: 'numeric',
  },
  [DateFormatters.HourNumeric]: {
    hour: 'numeric',
  },
  [DateFormatters.MonthLong]: {
    month: 'long',
  },
  [DateFormatters.MonthShort]: {
    month: 'short',
  },
  [DateFormatters.MonthShortWithDay]: {
    month: 'short',
    day: 'numeric',
  },
  [DateFormatters.Time2Digit]: {
    hour: '2-digit',
    minute: '2-digit',
  },
  [DateFormatters.WeekDayLong]: {
    weekday: 'long',
  },
  [DateFormatters.WeekDayLongWithMonthAndDay]: {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  },
  [DateFormatters.WeekDayNarrow]: {
    weekday: 'narrow',
  },
  [DateFormatters.WeekDayShort]: {
    weekday: 'short',
  },
  [DateFormatters.WeekdayShortWithMonthAndDay]: {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  },
  [DateFormatters.WeekdayShortWithMonthAndDayAndTime]: {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateFormatters.WeekDayShortWithTime]: {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateFormatters.YearISO]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  [DateFormatters.YearWithShortMonthAndDay]: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
};
