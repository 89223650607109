import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { filtersSelectors } from '../../../../../store';
import { useTranslation } from 'react-i18next';
import {
  PERSON_OF_INTEREST,
  PERSON_OF_INTEREST_TRANSLATION_MAPPING,
} from '../../../../../constants';
import { Subtitle, SubtitlePersonOfInterest } from './styled';
import { PersonItemItem } from '../../PersonItem/PersonItem';

interface PersonDescriptionProps {
  item: PersonItemItem;
}

export const PersonDescription = memo(({ item }: PersonDescriptionProps) => {
  const { description, isFireWarden, isFirstAidOfficer } = item;
  const personFilters = useSelector(filtersSelectors.getPersonFilters);
  const isPoiFilter = personFilters.fireWarden || personFilters.firstAidOfficer;
  const { t } = useTranslation();
  const getNormalDescription = () => <Subtitle className="btn">{description}</Subtitle>;

  const getPOIDescription = () => {
    const firstAidText = t(
      PERSON_OF_INTEREST_TRANSLATION_MAPPING[PERSON_OF_INTEREST.MEDICAL_OFFICER],
    );
    const firstAidShortText = t('layout.personFilters.firstAid');
    const fireWardenText = t(
      PERSON_OF_INTEREST_TRANSLATION_MAPPING[PERSON_OF_INTEREST.FIRE_OFFICER],
    );

    let subtitle;
    if (isFireWarden && isFirstAidOfficer) {
      subtitle = `${firstAidShortText} / ${fireWardenText}`;
    } else {
      subtitle = isFirstAidOfficer ? firstAidText : fireWardenText;
    }
    return <SubtitlePersonOfInterest>{subtitle}</SubtitlePersonOfInterest>;
  };

  return (
    <>
      {isPoiFilter && (isFirstAidOfficer || isFireWarden)
        ? getPOIDescription()
        : getNormalDescription()}
    </>
  );
});

export default PersonDescription;
