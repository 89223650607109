import { DateISODate, ISODateString, Nullable } from '@engage-shared/utils/types';
import { LanguageTypes } from '@engage-shared/constants/languages';
import { SENSOR_STATUS } from '@engage-shared/constants/space';
import { SpaceSVLivePresence } from '@engage-shared/api/people/interfaces';

export enum SpaceType {
  DESK = 'Desk',
  ROOM = 'Room',
  SPACE = 'Space',
  PERSON = 'Person',
  TEAM = 'Team',
}

export type SpaceImage = {
  id: number;
  name: string;
  path: string;
};

export type SpaceItemBooking = {
  id: number;
  start_time: ISODateString;
  start_time_zone: string;
  end_time: ISODateString;
  end_time_zone: string;
  is_all_day_booking: boolean;
  organizer_id: number;
  reservee_id: number;
  summary: Nullable<string>;
};

interface SpaceTeam {
  id: number;
  name: string;
  desks: number;
  people: number;
}

export interface SpaceItem {
  id: number;
  name: string;
  address: string;
  floorId: number;
  floorName: string;
  bookings?: SpaceItemBooking[]; // TODO: check if bookings still exist on space type
  seats: number;
  availability: boolean;
  spaceType: string;
  bookable: boolean;
  imageUrl: Nullable<string>;
  spaceImage: Nullable<SpaceImage>;
  isDesk: boolean;
  email: Nullable<string>;
  spaceTypeId: number;
  spaceTypeSetId: number;
  buildingId: number;
  buildingName: string;
  sensorStatus: Nullable<SENSOR_STATUS>;
  lastStatusUpdateTime: Nullable<string>;
  presenceEvents?: SpaceSVLivePresence[];
  isFavorite: boolean;
  team: Nullable<SpaceTeam>;
}

export type DeskAvailability = Pick<
  SpaceItem,
  'id' | 'floorId' | 'bookable' | 'sensorStatus' | 'lastStatusUpdateTime' | 'presenceEvents'
> & {
  available: boolean;
  isReserved: boolean;
};

export type SpaceItemAttributes = {
  description: SpaceItem['spaceType'];
  type: 'Desk' | 'Space';
  capacity: SpaceItem['seats'];
  level: SpaceItem['floorName'];
  building: SpaceItem['buildingName'];
  available: SpaceItem['availability'];
};

export type SpaceItemParams = {
  id: number;
  startTime?: DateISODate | string;
  endTime?: Nullable<DateISODate | string>;
};

export interface FetchSpaceTypesParams {
  spaceType: SpaceType;
  locale?: LanguageTypes;
}

export interface FetchSpaceAttributesParams {
  id: number;
  locale?: LanguageTypes;
}

export interface SpaceTypeOrAttribute {
  id: number;
  name: string;
  sequence: number;
}

export type SpaceTypesData = SpaceTypeOrAttribute[];

export type FetchSpaceTypeAttributesParams = FetchSpaceTypesParams;

export type SpaceAttributesData = SpaceTypeOrAttribute[];

export interface ParsedSpaceItem extends SpaceItem {
  description: string; //spaceItem.spaceType
  type: 'Desk' | 'Space'; //based on spaceItem.isDesk
  capacity: number; //spaceItem.seats
  level: string; //spaceItem.floorName
  building: string; //spaceItem.buildingName
  available: boolean; //spaceItem.availability
  imageSource?: { uri?: string }; // TODO: not used in web probably used in mobile
  showDateCard: boolean;
  showDaysSelector: boolean;
  showDurationSelection: boolean;
  isFavorite: boolean;
}

export type ShowDurationFlags = {
  showDateCard: boolean;
  showDaysSelector: boolean;
  showDurationSelection: boolean;
};

//TODO: check why not using ParsedSpaceItem instead
export type FormattedSpaceItem = SpaceItem & SpaceItemAttributes & ShowDurationFlags;

export type FormattedSpaceItemWithImage = FormattedSpaceItem & {
  imageSource: { uri: Nullable<string> };
};
