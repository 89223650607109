import React from 'react';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useTranslation } from 'react-i18next';
import { DrawerCardContent } from '@engage-web/components/scenes/drawer/DrawerCardContent';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useAppLocation } from '@engage-web/router/hooks';
import { removeSegmentFromPath } from '@engage-web/router/utils';
import { Wrapper } from './styled';
import PersonFilter from './PersonFilter';

const FirstResponders = () => {
  const { pathname } = useAppLocation();
  const { t } = useTranslation();
  const title = t('layout.firstResponders.title');
  const subtitle = t('layout.firstResponders.subtitle');
  const closeLinkPath = removeSegmentFromPath(PATH_SEGMENT.FILTERS, pathname);

  return (
    <>
      <DrawerHeader
        title={title}
        subtitle={subtitle}
        closeLinkPath={closeLinkPath}
        closeIconA11yLabel="accessibilityLabels.closeLayout_firstResponders"
        isCloseIcon
      />
      <DrawerCardContent>
        <Wrapper data-testid="FirstResponders-wrapper">
          <PersonFilter />
        </Wrapper>
      </DrawerCardContent>
    </>
  );
};

export default FirstResponders;
