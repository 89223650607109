import { useUser } from '@engage-web/api/queries';
import LanguageSelect from '@engage-web/components/layouts/LanguageSelect/LanguageSelect';
import React from 'react';
import { ToggleSwitch } from '@engage-web/components/base/ToggleSwitch';
import { AppVersion, BackButton } from '@engage-web/components/base';
import { useOptOut } from '@engage-shared/api';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tenantSelectors, userConfigActions, userConfigSelectors } from '@engage-web/store';
import useConfigLanguages from '@engage-web/utils/hooks/useConfigLanguages';
import { DOCUMENT_TITLE_LEVEL, MODALS } from '@engage-web/constants';
import { FocusId, useConfig, useFocus, useModal, useSetPageTitle } from '@engage-web/utils/hooks';
import { ParsedUserData } from '@engage-shared/api/users';
import {
  Center,
  Footer,
  Header,
  Label,
  Left,
  Option,
  Options,
  Right,
  Row,
  Wrapper,
} from './styled';

const Configuration = () => {
  const { t } = useTranslation();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const enableFloorplanViews = useSelector(userConfigSelectors.getEnableFloorplanViews);
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { allowUsersToOptOut } = useConfig();
  const { data } = useUser();
  const person = data || ({} as ParsedUserData);
  const { setOptOutChange, isPersonOptOut, isPersonOptOutLoading } = useOptOut({
    tenantId,
    personId: person?.personId,
  });

  const setEnableFloorplanViews = (value: boolean) => {
    dispatch(userConfigActions.setEnableFloorplanViews(value));
  };

  const { instanceTitle } = useSelector(tenantSelectors.getConfig);
  const configurationLanguages = useConfigLanguages();
  const isDefaultOptionOnly = configurationLanguages?.length === 1;
  const titleStr = t('layout.configuration.title');
  const organizationStr = t('layout.configuration.organization');
  const outFromSearchStr = t('layout.configuration.outFromSearch');
  const languageLabel = t('layout.configuration.languageLabel');
  const enableFloorplanViewsStr = t('layout.configuration.enableViews');

  const handleBackButtonClick = () => {
    openModal(MODALS.USER_PROFILE);
  };

  useFocus({ returnFocusTo: FocusId.UserSettingsButton });

  useSetPageTitle(t('documentTitles.configuration'), DOCUMENT_TITLE_LEVEL.MODAL);

  return (
    <Wrapper>
      <Header>
        <Left>
          <BackButton onClick={handleBackButtonClick} />
        </Left>
        <Center>
          <h2 className="title">{titleStr}</h2>
        </Center>
        <Right />
      </Header>
      <Options>
        <Row>
          <Option>
            <Label>{organizationStr}</Label>
          </Option>
          <Option>{instanceTitle}</Option>
        </Row>
        {allowUsersToOptOut && (
          <Row data-testid="opt-out-from-search-switch">
            <Option>
              <Label htmlFor="outFromSearch">{outFromSearchStr}</Label>
            </Option>
            <Option>
              <ToggleSwitch
                id="outFromSearch"
                disabled={isPersonOptOutLoading}
                checked={!!isPersonOptOut}
                onChange={setOptOutChange}
                ariaLabel={outFromSearchStr}
              />
            </Option>
          </Row>
        )}
        {!isDefaultOptionOnly && (
          <Row>
            <Option>
              <Label>{languageLabel}:</Label>
            </Option>
            <Option>
              <LanguageSelect configurationLanguages={configurationLanguages} />
            </Option>
          </Row>
        )}
        <Row data-testid="enable-views-switch">
          <Option>
            <Label htmlFor="enableFloorplanViews">{enableFloorplanViewsStr}</Label>
          </Option>
          <Option>
            <ToggleSwitch
              id="enableFloorplanViews"
              data-testid="enable-views-toggle"
              checked={enableFloorplanViews}
              onChange={setEnableFloorplanViews}
              ariaLabel={enableFloorplanViewsStr}
            />
          </Option>
        </Row>
      </Options>
      <Footer>
        <AppVersion />
      </Footer>
    </Wrapper>
  );
};

export default Configuration;
