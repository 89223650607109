import { Api, getApiUrl } from '@engage-shared/api/instance';
import { API_VERSIONS } from '@engage-shared/constants/api';
import { Building } from '@engage-shared/api/buildings/interfaces';
import { parseBuildingData, sortByBuildingName } from '@engage-shared/api/buildings/utils';
import { REGIONS_CONST } from '@engage-shared/constants/regions';
import { getLogger } from '@engage-shared/utils/logger';
import { FetchParams, Region } from './interfaces';
import { sortRegions } from './utils';
import { Nullable } from '@engage-shared/utils/types';

type FetchRegions = (param: FetchParams) => Promise<Region[]>;
/**
 * Retrieves top level regions.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | regions found |
 * @param tenantId
 * @param regionId
 * @param shouldSortRegions boolean that identifies need of region sorting
 */
export const fetchRegions: FetchRegions = async ({ tenantId, shouldSortRegions }) => {
  const url = new URL(`${getApiUrl(tenantId, API_VERSIONS.V2)}/regions`);
  const response = await Api.get<{ data: Region[] }>(url.toString());

  if (shouldSortRegions && response.data?.data) {
    return sortRegions(response.data?.data);
  }
  return response.data?.data;
};

// type FetchRegionsChildren = (param: FetchRegionsParams) => Promise<Region[]>;

interface FetchRegionChildrenParams {
  tenantId: Nullable<string>;
  regionId: number;
  shouldSortRegions: boolean;
}
/**
 * Retrieves regions by parent region.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | regions found |
 * | 404 | parent region not found |
 * @param tenantId
 * @param regionId Id of the parent region
 * @param shouldSortRegions boolean that identifies need of region sorting
 */
export const fetchRegionsChildren = async ({
  tenantId,
  regionId,
  shouldSortRegions = false,
}: FetchRegionChildrenParams): Promise<Region[] | null> => {
  if (!tenantId) return null;

  const url = new URL(
    `${getApiUrl(tenantId, API_VERSIONS.V2)}/${
      regionId === REGIONS_CONST.ROOT_REGION_ID ? 'regions' : `regions/${regionId}/children`
    }`,
  );
  const response = await Api.get<{ data: Region[] }>(url.toString());

  if (shouldSortRegions && response.data?.data) {
    return sortRegions(response.data?.data);
  }
  return response.data?.data;
};

interface FetchRegionsBuildingsParams {
  regionId: number;
  sortBuildings?: boolean;
}
export const fetchRegionsBuildings = async ({
  regionId,
  sortBuildings = true,
}: FetchRegionsBuildingsParams) => {
  const fetchStartTime = Date.now();
  const url = new URL(`${getApiUrl(undefined, API_VERSIONS.V2)}/regions/${regionId}/buildings`);
  const response = await Api.get<{ data: Building[] }>(url.toString(), {
    timeout: 30000,
  });
  const elapsedTime = Date.now() - fetchStartTime;
  getLogger().info(`Elapsed time for building fetch with regionId: ${regionId} ${elapsedTime} ms`);
  // Log message to Sentry if the request takes longer than 15 seconds.
  if (elapsedTime > 15000) {
    getLogger().error(
      `Building fetch response for region ${regionId} is very slow. Elapsed time ${elapsedTime} `,
    );
  }

  const buildings = response.data?.data;
  if (sortBuildings && Array.isArray(buildings)) {
    buildings.sort(sortByBuildingName);
  }
  return buildings.map(parseBuildingData);
};
