import React from 'react';
import { useTranslation } from 'react-i18next';
import { FocusId, testProps, useModal } from '@engage-web/utils';
import IconButton from '@engage-web/components/base/IconButton/IconButton';
import { MODALS } from '@engage-web/constants';

export function AgendaButton() {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const toggleAgendaModal = () => {
    openModal(MODALS.AGENDA);
  };

  return (
    <IconButton
      id={FocusId.AgendaButton}
      icon="calendar-list"
      onPress={toggleAgendaModal}
      {...testProps(t, 'accessibilityLabels.viewAgenda').props}
    />
  );
}
