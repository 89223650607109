import React, { memo, ReactElement } from 'react';
import { DOCUMENT_TITLE_LEVEL } from '@engage-web/constants';
import { useSetPageTitle } from '@engage-web/utils/hooks';
import { useTranslation } from 'react-i18next';
import { testProps } from '@engage-web/utils';
import { EngageIcons, Icon } from '../Icons/Icon';
import { Wrapper, Message, Title, Button, IconButton, CloseButton } from './styled';

type FullScreenInfoProps = {
  title: string;
  message?: string;
  onClose?: () => void;
  iconName?: EngageIcons;
  iconButtonAriaLabelKey?: string;
  iconComponent?: ReactElement;
  buttonTitle?: string;
  onPressButton?: () => void;
  pageTitle?: string;
};

const FullScreenInfo = ({
  title,
  message,
  onClose,
  iconName,
  iconButtonAriaLabelKey,
  buttonTitle,
  onPressButton,
  iconComponent,
  pageTitle = '',
}: FullScreenInfoProps) => {
  const { t } = useTranslation();

  useSetPageTitle(pageTitle, DOCUMENT_TITLE_LEVEL.MODAL);

  const a11yAndTestProps = iconButtonAriaLabelKey ? testProps(t, iconButtonAriaLabelKey).props : {};

  return (
    <Wrapper onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <CloseButton onClick={onClose}>
        <Icon name="close" size={14} />
      </CloseButton>
      <IconButton
        onClick={onClose}
        // data-testid="full-screen-info-icon-button"
        {...a11yAndTestProps}
      >
        {!!iconName && <Icon name={iconName} size={120} />}
        {iconComponent}
      </IconButton>
      <Title data-testid="full-screen-info-title">{title}</Title>
      {!!message && <Message data-testid="full-screen-info-message">{message}</Message>}
      {!!buttonTitle && (
        <Button onClick={onPressButton} data-testid="full-screen-info-button">
          {buttonTitle}
        </Button>
      )}
    </Wrapper>
  );
};

FullScreenInfo.defaultProps = {
  message: '',
  iconName: '',
  onClose: null,
  iconButtonAriaLabelKey: '',
  iconComponent: null,
  buttonTitle: '',
  onPressButton: null,
  pageTitle: '',
};

export default memo(FullScreenInfo);
