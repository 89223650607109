import { useFetchImageURL } from '@engage-web/api/queries/useFetchImageURL';

interface UsePersonImageParams {
  imageUrl: string;
  enabled: boolean;
}
export const usePersonImage = ({ imageUrl, enabled }: UsePersonImageParams) =>
  useFetchImageURL({
    url: imageUrl,
    queryKey: ['person-image', imageUrl ?? '', 'id'],
    options: {
      enabled,
    },
  });
