import { PersonData } from '@engage-shared/api/people/interfaces';
import { Nullable } from '@engage-shared/utils/types';
import { PresenceStatus } from '@engage-shared/constants/person';
import { getPersonPresenceEventSource } from './getPersonPresenceEventSource';
import { getPersonSVLivePresenceEventOccupancy } from './getSVLivePresenceEventOccupancy';

/**
 * Returns person's presence status by presenceEvent
 * @param person person data
 * @param floorplanZoneId
 * @returns {PresenceStatus}
 */

export const getPersonConnectionStatus = (
  person: PersonData,
  floorplanZoneId: number,
): Nullable<PresenceStatus> => {
  const { isOccupied, presenceEvent } = getPersonSVLivePresenceEventOccupancy(person);

  return isOccupied
    ? getPersonPresenceEventSource({
        floorplanZoneId,
        presenceEvent,
      })
    : null;
};
