export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: number | null = null;

  return function executedFunction(...args: any[]) {
    const later = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = null;
      func(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    // @ts-ignore
    timeout = setTimeout(later, wait);
  };
};

export const throttle = (func: (...args: any[]) => void, stepMs: number) => {
  let timer: any | null = null;

  return function (...args: any[]) {
    if (timer) {
      return;
    }

    timer = setTimeout(function () {
      func(...args);
      clearTimeout(timer);
      timer = null;
    }, stepMs);
  };
};
