import React, { useEffect, useRef, useState } from 'react';
import { GetBookingsFetcherResponse } from '@engage-shared/api/bookings';
import { useAppLocation } from '@engage-web/router/hooks';
import {
  getLocalTimeZone,
  useConfig,
  useCurrentTimeZone,
  useFocusSpace,
  useRestrictTo,
} from '@engage-web/utils';
import {
  getIsBookingsAllowed,
  getLatestPresenceEvent,
  getSpaceStatusInfo,
  getSpaceSVLivePresenceEventOccupancy,
  getSVLivePresenceStatus,
  isTimeNow,
  pipe,
} from '@engage-shared/utils';
import useReservationFilters, { FilterProps } from '@engage-web/utils/hooks/useReservationFilters';
import { useSelector } from 'react-redux';
import { filtersSelectors, tenantSelectors } from '@engage-web/store';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../styled';
import {
  BookForPersonButton,
  BookForTeamButton,
  DaysSelector,
  DurationAndAvailability,
  ImageAndTitle,
  OccupantButton,
  ReserveButton,
} from './Parts';
import { ReserveCardContext } from './ReserveCardContext';
import { SpaceItemWithImage } from './types';
import { updateDurationObjFromState } from './updateDurationObjFromState';
import { ParsedSpaceItem, ParsedTeam, SpaceType, UserData } from '@engage-shared/api';

type ReserveCardProps = {
  item?: ParsedTeam | ParsedSpaceItem;
  spaceItem: SpaceItemWithImage;
  user: UserData;
  reservee?: UserData;
  bookings?: GetBookingsFetcherResponse;
};

const defaultReservee = {} as UserData;

export const ReserveCard = ({
  item,
  spaceItem,
  user,
  reservee,
  bookings: propBookings,
}: ReserveCardProps) => {
  const [durationObj, setDurationObj] = useState({
    duration: 0,
    allDayDuration: false,
    selectedDays: [] as Date[],
  });

  const [reserveDisabled, setReserveDisabled] = useState(false);
  const reserveButtonRef = useRef(null);

  const { hidePeopleData } = useSelector(tenantSelectors.getKioskConfig);

  // use current building's timezone or user's timezone if that is not available
  const timeZone = useCurrentTimeZone() ?? getLocalTimeZone();

  const {
    id: spaceId,
    buildingId,
    isDesk,
    sensorStatus,
    presenceEvents,
    showDaysSelector,
    bookable,
    email,
  } = spaceItem;
  const isTeam = item?.type === SpaceType.TEAM;
  const { occupant, bookings, isOccupied, bookedIntervals } = propBookings || {};

  const config = useConfig(buildingId);
  const { hideCalendarReservationControls } = config;
  const isCalendarReservationHidden = hideCalendarReservationControls && !isDesk;

  const showBookFor = user?.canReserveForOthers;

  const reservationFilter = useReservationFilters(isDesk);
  const { dateStart: filterDateStart } = reservationFilter;

  const location = useAppLocation();
  const restrictTo = useRestrictTo();
  const dataKey = restrictTo?.toLowerCase() ?? '';

  const [state] = useState(location.state || {});
  const navigate = useNavigate();

  useEffect(() => {
    // preserve selected days when returning from Reservee List
    // @ts-ignore
    if (state.fromReserveeList) {
      updateDurationObjFromState({
        state,
        dataKey,
        setDurationObj,
      });

      // reset flag
      navigate('.', {
        replace: true,
        state: { ...state, fromReserveeList: false },
      });
    }
    // update duration object from state only on mount
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useFocusSpace(!isTeam && spaceId);
  // TODO: fix types
  const latestPresenceEventStatus = pipe(
    // @ts-ignore
    getLatestPresenceEvent,
    getSVLivePresenceStatus,
    // @ts-ignore
  )(presenceEvents);

  // Days from Reservation assist
  const { dateStart }: FilterProps = useSelector(filtersSelectors.getFilterDates);

  const { isOccupied: isOccupiedBySvLive } = getSpaceSVLivePresenceEventOccupancy(spaceItem);

  const latestPresenceEvent = getLatestPresenceEvent(spaceItem?.presenceEvents);
  const isNow = isTimeNow(filterDateStart);

  // display SVLive occupant only if timeline indicates 'Now'
  const svLiveOccupant = isOccupiedBySvLive && isNow ? latestPresenceEvent : null;

  const hasOccupant = !!occupant || !!svLiveOccupant;

  const spaceStatusInfo = getSpaceStatusInfo({
    filterDateStart: dateStart,
    // @ts-ignore
    svLiveStatus: latestPresenceEventStatus,
    sensorStatus,
    isReserved: hasOccupant,
    isOccupied: !!isOccupied,
    isDesk: isDesk,
  });

  const { bookingsAllowed, bookingDeniedMessage } = getIsBookingsAllowed({
    // @ts-ignore
    config,
    userData: reservee || user,
  });

  const renderCard = () => {
    if (hasOccupant) {
      return (
        <>
          <ImageAndTitle />
          <OccupantButton shouldShowGuest={hidePeopleData} />
        </>
      );
    }

    const shouldHideReservationControls = isCalendarReservationHidden && !!email;
    if (
      !bookable ||
      !bookingsAllowed ||
      spaceStatusInfo?.disableReservation ||
      shouldHideReservationControls
    ) {
      return (
        <>
          <ImageAndTitle $marginBottom={30} />
          {!showDaysSelector && shouldHideReservationControls && (
            <DurationAndAvailability showButtons={false} />
          )}
        </>
      );
    }

    return isTeam ? (
      <>
        <ImageAndTitle />
        <BookForTeamButton />
        {showDaysSelector ? <DaysSelector /> : <DurationAndAvailability />}
        <ReserveButton />
      </>
    ) : (
      <>
        <ImageAndTitle />
        {showDaysSelector ? <DaysSelector /> : <DurationAndAvailability />}
        {showBookFor && <BookForPersonButton disabled={hidePeopleData} />}
        <ReserveButton />
      </>
    );
  };

  const reserveeValue = reservee ?? defaultReservee;

  const contextValue = {
    spaceItem,
    item,
    reservee: reserveeValue,
    user,
    isTeam,
    reserveDisabled,
    setReserveDisabled,
    reserveButtonRef,
    durationObj,
    setDurationObj,
    spaceStatusInfo,
    timeZone,
    hasOccupant,
    bookings,
    bookedIntervals,
    bookingsAllowed,
    bookingDeniedMessage,
    isOccupied: !!isOccupied,
    occupant,
    svLiveOccupant,
  };
  return (
    <Wrapper>
      {/* @ts-ignore */}
      <ReserveCardContext.Provider value={contextValue}>{renderCard()}</ReserveCardContext.Provider>
    </Wrapper>
  );
};
