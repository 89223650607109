import React, { memo } from 'react';
import { BookingAttendee } from '@engage-shared/api/bookings';
import Attendee from './Attendee/Attendee';
import { AttendeeItemWrapper, AttendeeList } from './styled';

type AttendeesListProps = { attendees: BookingAttendee[]; isDesk: boolean };

const AttendeesList = ({ attendees = [], isDesk }: AttendeesListProps) => {
  if (isDesk) {
    return <Attendee item={attendees[0]} />;
  }

  const renderItem = (item: BookingAttendee, index: number) => (
    <AttendeeItemWrapper data-testid={`AttendeesList-item-${index}`} key={`${item.name}_${index}`}>
      <Attendee item={item} />
    </AttendeeItemWrapper>
  );

  return (
    <AttendeeList data-testid="AttendeesList-wrapper">{attendees.map(renderItem)}</AttendeeList>
  );
};

export default memo(AttendeesList);
