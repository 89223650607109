import {
  addDays,
  addHours,
  addMinutes,
  addWeeks,
  isAfter,
  isBefore,
  isEqual,
  isWithinInterval,
  parseISO,
  subDays,
} from 'date-fns';
import { roundTimeToInterval } from './dateHelpers';
import { getDayEndTime, getDayStartTime, isLaterToday } from './timezoneHelpers';
import { RoundingDirection } from '../../constants';
import { DAYS_SELECTOR_NUMBER_OF_DAYS } from '@engage-shared/constants/daysSelector';
import { BookingEventStatus, BookingsListResponse } from '@engage-shared/api/bookings/interfaces';
import { ParsedSpaceItem } from '@engage-shared/api/spaces/interfaces';

/**
 * Get array of dates (in milliseconds) for booked intervals ( rounded down start time, rounded up end time).
 * Used by availability bar.
 */
export const getSpaceAvailabilityFromBookings = (
  bookings: BookingsListResponse[],
  bookingIntervalSize: number,
): number[] => {
  const bookedIntervals: number[] = [];
  if (!bookings) {
    return bookedIntervals;
  }

  for (const element of bookings) {
    const start = roundTimeToInterval(
      new Date(element.start),
      bookingIntervalSize,
      RoundingDirection.DOWN,
    );
    const end = roundTimeToInterval(
      new Date(element.end),
      bookingIntervalSize,
      RoundingDirection.UP,
    );
    bookedIntervals.push(start.getTime());
    let current = addMinutes(start, bookingIntervalSize);

    while (current < end) {
      bookedIntervals.push(current.getTime());
      current = addMinutes(current, bookingIntervalSize);
    }
  }
  return bookedIntervals;
};

/**
 * Get array with start of days (localized 00:00) for start dates of bookings.
 * @param bookings
 * @param filterDateStart
 * @return Date[]
 */
export const getDisabledDaysFromBookings = (
  bookings: BookingsListResponse[],
  filterDateStart?: Date,
): Date[] => {
  const reservedDates: Date[] = [];
  if (!bookings) {
    return reservedDates;
  }
  const activeBookings = bookings.filter(item => {
    const { end, eventStatus } = item;
    const bookingEndDate = new Date(end);
    const now = new Date();

    const isPastBooking = isBefore(bookingEndDate, filterDateStart ?? now);

    const isCancelled = eventStatus === BookingEventStatus.CANCELLED;

    return !isCancelled && !isPastBooking;
  });

  for (let i = 0; i < activeBookings.length; i++) {
    const bookingStartDate = new Date(activeBookings[i].start);

    const beginningOfStartDate = getDayStartTime({
      date: bookingStartDate,
      timeZone: activeBookings[i].startTimeZone,
    });
    reservedDates.push(beginningOfStartDate);
  }

  return reservedDates;
};

/**
 * Get array of days which should be disabled due to futureBookingsLimit
 * @param filterDateStart
 * @param futureBookingsLimit
 * @param timeZone
 *
 * @returns Date[] days to disable
 */
export const getDisabledDaysFromBookingLimit = ({
  filterDateStart,
  futureBookingsLimit,
  timeZone,
}: {
  filterDateStart: Date;
  futureBookingsLimit: number;
  timeZone: string;
}): Date[] => {
  const today = new Date();
  const maxBookableDay = getDayEndTime({
    // we should exclude last day because API is getting the start of the last day as last possible booking time
    // might be changed later
    date: subDays(addWeeks(today, futureBookingsLimit), 1),
    timeZone,
  });
  const daysOfWeek = Array.from(
    {
      length: DAYS_SELECTOR_NUMBER_OF_DAYS,
    },
    (_, k) => k,
  ).map(daysToAdd =>
    getDayStartTime({
      date: addDays(filterDateStart, daysToAdd),
      timeZone,
    }),
  );
  return daysOfWeek.filter(date => isAfter(date, maxBookableDay));
};

/**
 * Get the array of bookings active in the specified interval.
 * @param bookings
 * @param intervalStart
 * @param intervalEnd
 * @returns array of filtered bookings
 */
export const getActiveBookingsInInterval = ({
  bookings,
  intervalStart,
  intervalEnd,
}: {
  bookings: BookingsListResponse[];
  intervalStart: Date;
  intervalEnd: Date;
}): BookingsListResponse[] => {
  if (!bookings) return [];
  return bookings.filter(booking => {
    const bookingStart = parseISO(booking.start);
    const bookingEnd = parseISO(booking.end);
    const bookingEnded = isBefore(bookingEnd, new Date()); // booking ended before current time, or was cancelled.

    const intervalStartsDuringBooking = isWithinInterval(intervalStart, {
      start: bookingStart,
      end: bookingEnd,
    });

    const intervalEndsDuringBooking = isWithinInterval(intervalEnd, {
      start: bookingStart,
      end: bookingEnd,
    });

    const wholeBookingIsWithingInterval =
      isBefore(intervalStart, bookingStart) && isAfter(intervalEnd, bookingEnd);

    return (
      (intervalStartsDuringBooking || intervalEndsDuringBooking || wholeBookingIsWithingInterval) &&
      !bookingEnded
    );
  });
};

/**
 * Check if the space is booked at the selected time. If filterDateStart is not set, the time checked is the current time rounded down.
 * SpaceItem contains the show duration component flags: showDaysSelector, showDurationSelection, showDateCard.
 *
 * When showDaysSelector is true it means we're operating with full day reservations and if all 7 days displayed (the week starting with the selected day),
 * then return true to mark the space as occupied since no other reservation can be made for this week.
 *
 * When showDurationSelection is true it means we're operating with hourly reservations and
 * is occupied should be false only if the minimum reservable interval is not available (1 hr for desks, 15 min for rooms)
 *
 * When showDateCard is true it means is an update and space item data already contains the availability value as we need (clear start and end time set)
 * and is occupied result is based on that value.
 *
 * @param spaceItem space item data parsed and with duration component flags
 * @param bookings space's bookings for a larger interval in order to receive also bookings which already started, so filtering is needed
 * @param filterDateStart date selected from filter, timeline or it defaults to current time rounded down
 * @param bookingIntervalSize config value
 */
export const calculateIsOccupied = ({
  spaceItem,
  bookings,
  filterDateStart,
  bookingIntervalSize,
}: {
  spaceItem: ParsedSpaceItem | undefined;
  bookings: BookingsListResponse[];
  filterDateStart?: Date;
  bookingIntervalSize: number;
}): boolean => {
  const showDaysSelector = spaceItem?.showDaysSelector;
  const showDurationSelection = spaceItem?.showDurationSelection;
  const showDateCard = spaceItem?.showDateCard;
  const isDesk = spaceItem?.type === 'Desk';
  const available = spaceItem?.available;

  const intervalStart =
    filterDateStart || roundTimeToInterval(new Date(), bookingIntervalSize, RoundingDirection.DOWN);

  if (showDaysSelector) {
    // received bookings are from one week before current selected time until one week after the selected time
    const lastDayDisplayed = addDays(intervalStart, 6);
    const filteredBookings = bookings.filter(booking => {
      const isLater = isLaterToday(intervalStart, booking.startTimeZone);
      const startTime = isLater
        ? intervalStart
        : getDayStartTime({
            date: intervalStart,
            timeZone: booking.startTimeZone,
          });

      const isEqualOrAfterStartTime =
        isAfter(parseISO(booking.start), startTime) || isEqual(parseISO(booking.start), startTime);

      const endTime = getDayEndTime({
        date: lastDayDisplayed,
        timeZone: booking.startTimeZone,
      });

      const isEqualOrBeforeEndTime =
        isBefore(parseISO(booking.start), endTime) || isEqual(parseISO(booking.start), endTime);

      return isEqualOrAfterStartTime && isEqualOrBeforeEndTime;
    });

    const disabledDays = getDisabledDaysFromBookings(filteredBookings, filterDateStart);
    return disabledDays.length >= 7;
  }

  if (showDurationSelection) {
    if (isDesk) {
      // available in the next hour (minimum selectable duration)
      const intervalEnd = addHours(intervalStart, 1);

      const activeBookings = getActiveBookingsInInterval({
        bookings,
        intervalStart,
        intervalEnd,
      });
      if (activeBookings && activeBookings.length > 0) {
        return true;
      }
    } else {
      // available in the next 15 minutes (minimum selectable duration)
      const intervalEnd = addMinutes(intervalStart, 15);

      const activeBookings = getActiveBookingsInInterval({
        bookings,
        intervalStart,
        intervalEnd,
      });
      if (activeBookings && activeBookings.length > 0) {
        return true;
      }
    }
  }

  if (showDateCard) return !available;

  return false;
};

export const hasBookingFinished = (endDate: Date): boolean => {
  const now = new Date();
  /* The meeting end time is set a bit in the future when cancelling the meeting. Add a 2 minute buffer so that the
     Delete button is disabled immediately.
   */
  const bufferedEndTime = addMinutes(now, 2);
  return isAfter(bufferedEndTime, endDate);
};
