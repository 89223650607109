import { tenantSelectors } from '@engage-web/store';
import { useSelector } from 'react-redux';
import { getModifiedTenantConfig, useBuilding } from '@engage-shared/api';

/**
 * Returns building or global configuration
 * @param buildingId - if passed, building configuration will be based on passed buildingId
 *                     and not on globally set buildingId
 */
export const useConfig = (buildingId?: number) => {
  const tenantConfig = useSelector(tenantSelectors.getConfig);
  const currentLocation = useSelector(tenantSelectors.getCurrentLocation);
  const configBuildingId = buildingId || currentLocation?.building?.id;

  const { data: buildingConfig } = useBuilding({
    buildingId: configBuildingId,
  });

  return getModifiedTenantConfig({
    buildingConfig,
    tenantConfig,
  });
};
