import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { tenantSelectors } from '@engage-web/store';
import { DeleteButton } from '@engage-web/components/layouts/Booking/Card/styled';
import { ConfirmationDialog, Icon } from '@engage-web/components/base';
import { bookingDetailsButtonStatuses as STATUSES } from '@engage-web/constants';
import { FocusId, useDialog } from '@engage-web/utils';
import { TeamButtonStatus } from '@engage-shared/utils';
import { Theme } from '@engage-shared/theme';
import { cancelTeamBooking } from '@engage-shared/api';

type DeleteProps = {
  meetingId: number;
  status: TeamButtonStatus;
  onBack: () => void;
};
export function Delete({ status, meetingId, onBack }: DeleteProps) {
  const { DISPLAY, DISABLED } = STATUSES;
  const { [DISPLAY]: display, [DISABLED]: disabled } = status;

  const { t } = useTranslation();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { openDialog } = useDialog();

  const { color1 } = useTheme() as Theme;

  const onDeleteReservation = async () => {
    const handleErrorOccurred = () =>
      openDialog(
        <ConfirmationDialog
          title={t('layout.floorplanError.errorOccurred')}
          message={t('layout.reserve.team.errorOccurred')}
          key="team-booking-delete-error-dialog"
          triggerId={FocusId.TeamBookingDelete}
          hasCancelButton={false}
        />,
      );

    const confirmed = await openDialog(
      <ConfirmationDialog
        title=""
        message={t('layout.bookingCard.deleteEvent')}
        key="team-booking-delete-confirmation-dialog"
        triggerId={FocusId.TeamBookingDelete}
        hasCancelButton
      />,
    );

    if (confirmed) {
      try {
        await cancelTeamBooking({ tenantId, id: meetingId });
        onBack();
      } catch (error) {
        handleErrorOccurred();
      }
    }
  };

  return display ? (
    <DeleteButton
      onClick={onDeleteReservation}
      text={t('common.delete')}
      disabled={disabled}
      opacity={disabled ? 0.4 : 1}
      color={color1}
      backgroundColor="transparent"
    >
      <Icon name="bin" size={13} style={{ color: color1 }} />
    </DeleteButton>
  ) : null;
}
