import { Api, getApiUrl } from '@engage-shared/api/instance';
import { FloorBooking, FetchFloorBookingsParams } from './interfaces';

type FetchFloorBookings = (param: FetchFloorBookingsParams) => Promise<FloorBooking[]>;
/**
 * Retrieves bookings for a given floor by id, optionally filtered by spaceIds, startTime and endTime.
 * The output is an array of bookings (team bookings, desk bookings or space bookings.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | floor bookings found |
 * | 404 | floor not found |
 */
export const fetchFloorBookings: FetchFloorBookings = async ({
  tenantId,
  floorId,
  spaces,
  startTime,
  endTime,
}) => {
  if (!floorId) return [];

  const url = new URL(`${getApiUrl(tenantId)}/floors/${floorId}/bookings`);
  if (startTime) {
    url.searchParams.append('startTime', startTime);
  }
  if (endTime) {
    url.searchParams.append('endTime', endTime);
  }
  if (spaces) {
    url.searchParams.append('spaces', spaces.toString());
  }

  const response = await Api.get<{ data: FloorBooking[] }>(decodeURIComponent(url.toString()));
  return response.data?.data;
};
