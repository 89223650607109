import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePerson } from '@engage-web/api/queries';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_SEGMENT, ROOT_PATH, SENSOR_STATUS, WAYFINDER_PATH } from '@engage-web/constants';
import {
  filtersSelectors,
  floorplanActions,
  floorplanSelectors,
  inAppNotificationsActions,
  tenantActions,
  tenantSelectors,
} from '@engage-web/store';
import { useGenerateCurrentLocationPath } from '@engage-web/utils';
import {
  getPresenceSensorStatusForPerson,
  getUserFloorLocation,
  isTimeNow,
} from '@engage-shared/utils';
import { EmptyCard } from '@engage-web/components/base';
import { FocusId, useFocus } from '@engage-web/utils/hooks';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import usePersonCheck from '@engage-web/components/scenes/drawer/hooks/usePersonCheck';
import { DrawerCardContent } from '@engage-web/components/scenes/drawer/DrawerCardContent';
import { generatePersonPath, joinPaths } from '@engage-web/router/utils';
import { useAppLocation, useAppParams } from '@engage-web/router/hooks';
import { usePersonMenu } from '@engage-web/utils/hooks/usePersonMenu';
import PersonCard from './Card/PersonCard';
import PersonCardSkeleton from './Card/PersonCardSkeleton';
import { Nullable } from '@engage-shared/utils/types';
import {
  getHasWifiPresenceData,
  getHasWiredPresenceData,
  getPersonLocation,
  getSVLivePersonLocation,
} from '@engage-shared/api';

type PersonProps = {
  personId?: number;
};

const SHOW_FLOORPLAN_OVERLAY_DELAY = 500;

const Person = ({ personId: personIdFromProps }: PersonProps) => {
  const { personId: personIdFromParams } = useAppParams();
  const personId = personIdFromProps ?? parseInt(personIdFromParams ?? '0', 10);
  const { state } = useAppLocation();
  const searchSpaceId = state?.searchSpaceId;
  const isFinalLocation = state?.isFinalLocation ?? false;
  const currentLocationPath = useGenerateCurrentLocationPath();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { t } = useTranslation();

  const currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);
  const dateStart = useSelector(filtersSelectors.getDatePickerStartDate);
  const isDateStartEqualToNow = isTimeNow(dateStart);
  const demoSensorStatus = queryParams.get('demoSensorStatus');
  const closeLinkPath = currentLocationPath
    ? joinPaths(currentLocationPath, PATH_SEGMENT.PEOPLE)
    : WAYFINDER_PATH;

  // person check flow
  const { checkPersonQuery } = usePersonCheck();

  useEffect(() => {
    checkPersonQuery(personId).then(({ errorMessage }) => {
      if (errorMessage) {
        dispatch(
          inAppNotificationsActions.addWarningNotification({
            message: t(errorMessage),
          }),
        );
        navigate(currentLocationPath || ROOT_PATH);
      }
    });
  }, [checkPersonQuery, currentLocationPath, dispatch, navigate, personId, t]);

  const sensorStatusRef = useRef<Nullable<string>>(null);
  const personQueryOptions = sensorStatusRef.current
    ? { refetchOnMount: true, refetchInterval: 60 * 1000 }
    : {};

  const personQuery = usePerson({
    id: personId,
    searchSpaceId,
    currentFloorId,
    options: personQueryOptions,
  });

  const person = personQuery.data;

  const primaryLocation = person?.primaryLocation ?? {
    spaceId: null,
    spaceIds: null,
    floorId: null,
    hoodName: null,
  };

  const { spaceId, spaceIds, floorId, hoodName } = primaryLocation;
  const isDeskReserved = !!person?.reserved;
  const floorplanIsLoaded = useSelector(floorplanSelectors.getIsLoaded);

  // display overlay while checking person's location. If person has a location – hide overlay
  // otherwise – do nothing
  // also clear overlay when the card is unmounted
  useEffect(() => {
    const overlayTimeout = window.setTimeout(
      () => dispatch(floorplanActions.setShowOverlay(true)),
      SHOW_FLOORPLAN_OVERLAY_DELAY,
    );
    if (primaryLocation?.spaceId || person?.presenceStatus) {
      window.clearTimeout(overlayTimeout);
      dispatch(floorplanActions.setShowOverlay(false));
    }

    return () => {
      window.clearTimeout(overlayTimeout);
      dispatch(floorplanActions.setShowOverlay(false));
    };
  }, [primaryLocation?.spaceId, person?.presenceStatus, dispatch]);

  const menu = usePersonMenu({
    personId: `${personId}`,
    isFavourite: person?.isFavorite,
  });

  const [locationName, setLocationName] = useState(person?.spaceName);
  useEffect(() => {
    setLocationName(person?.spaceName);
  }, [person?.spaceName]);

  const sensorStatus = getPresenceSensorStatusForPerson({
    sensorStatus: person?.sensorStatus ?? null,
    isTimeFilterSetToNow: isDateStartEqualToNow,
    isDeskReserved,
    demoSensorStatus: demoSensorStatus ? (demoSensorStatus as SENSOR_STATUS) : null,
  });

  sensorStatusRef.current = sensorStatus;

  const hasWiredPresenceData = getHasWiredPresenceData(person);
  const hasWifiPresenceData = getHasWifiPresenceData(person);

  const queryIsLoading = personQuery.isFetching;
  const isDefaultLocationNotInCurrent =
    floorId && floorId !== currentFloorId && !hasWiredPresenceData;

  const querySuccess = personQuery.isSuccess;
  const queryError = personQuery.isError;

  const isSkeletonVisible = queryIsLoading || !floorplanIsLoaded;

  let personLocation = getPersonLocation(person);

  const updateLocation = useCallback(async () => {
    if (hasWiredPresenceData && !!personLocation.containerId) {
      const svliveLocation = await getSVLivePersonLocation(personLocation.containerId);
      personLocation = { ...personLocation, ...svliveLocation };
    }
    const floorId = personLocation.floorId;
    let selectedLocation;

    if (floorId) {
      selectedLocation = await getUserFloorLocation(floorId);
    }

    if (selectedLocation) {
      const personId = person?.id ? `${person.id}` : '';
      const pathname = generatePersonPath({
        floorId: `${selectedLocation.floor.id}`,
        personId,
      });

      navigate(pathname, {
        replace: true,
        state: {
          ...state,
          isFinalLocation: true,
          person: {
            ...(person ?? {}),
          },
          searchSpaceId: spaceId,
        },
      });

      dispatch(tenantActions.setCurrentLocation(selectedLocation));
    }
  }, [person, navigate, state, spaceId, dispatch, personLocation.floorId]);

  /**
   * Location should be updated if the person query has succeeded and:
   * 1. Person's default location differs from the current one.
   * 2. This is not the final location (obtained from location state and will be true if location was replaced or
   * if the person card was opened from person locations list).
   * 3. Person id from props is undefined – because this case correct only when we're
   * showing an employee.
   */
  const shouldUpdateLocation =
    querySuccess &&
    (!!isDefaultLocationNotInCurrent || personLocation.floorId !== currentFloorId) &&
    !isFinalLocation &&
    !personIdFromProps;

  useEffect(() => {
    if (shouldUpdateLocation) {
      updateLocation();
    }
  }, [shouldUpdateLocation, updateLocation]);

  useEffect(() => {
    // if person has flexible workplace (spaceIds.length > 1) -> highlight all of them
    // if person has only one workplace -> highlight it
    const spacesToFocus = spaceIds && spaceIds.length > 1 ? spaceIds : spaceId;
    // If SVLive presence data exists and person has wired connection,
    // wired connection location has precedence over any other person locations.
    const getSvLocation = async () => {
      if (!personLocation.containerId) return;
      const svliveLocation = await getSVLivePersonLocation(personLocation.containerId);
      if (querySuccess && !!svliveLocation?.spaceId) {
        setLocationName(svliveLocation.spaceName);
        dispatch(
          floorplanActions.setFocusedSpace({
            id: svliveLocation.spaceId,
          }),
        );
        if (svliveLocation.floorId != currentFloorId) {
          updateLocation();
        }
      }
    };
    if (hasWiredPresenceData) {
      getSvLocation();
    }
    // If SVLive Wi-Fi presence data exists and desk is reserved,
    // Wi-Fi location has precedence over reserved desk.
    const shouldFocusSpaces =
      querySuccess && spacesToFocus && !hasWifiPresenceData && !hasWiredPresenceData;

    if (shouldFocusSpaces) {
      dispatch(
        floorplanActions.setFocusedSpace({
          id: spacesToFocus,
        }),
      );
    }
  }, [
    dispatch,
    hasWifiPresenceData,
    hasWiredPresenceData,
    personLocation.spaceId,
    querySuccess,
    spaceId,
    spaceIds,
  ]);

  useEffect(() => {
    // If we have svLivePresenceLocation and person has Wi-Fi connection, draw marker on the floorplan
    if (hasWifiPresenceData && person?.latestPresenceEvent?.location) {
      dispatch(floorplanActions.setSVLivePersonLocation(person.latestPresenceEvent.location));
    }

    return () => {
      // once component updated and there are no svLivePresenceLocation - remove it from floorplan
      if (hasWifiPresenceData) {
        dispatch(floorplanActions.setSVLivePersonLocation(null));
      }
    };
  }, [dispatch, hasWifiPresenceData, person?.latestPresenceEvent?.location]);

  useFocus({ focusOn: FocusId.DrawerCloseButton });

  if (isSkeletonVisible) {
    return (
      <>
        <DrawerHeader
          title={false}
          subtitle={false}
          isCloseIcon
          closeLinkPath={closeLinkPath}
          closeIconA11yLabel="accessibilityLabels.closeLayout_personDetails"
        />
        <PersonCardSkeleton />
      </>
    );
  }

  if (queryError) {
    return <EmptyCard iconName="info">{t('common.networkError')}</EmptyCard>;
  }

  return (
    <>
      <DrawerHeader
        menu={menu}
        title={!!spaceId}
        subtitle={!!spaceId}
        isCloseIcon
        closeLinkPath={closeLinkPath}
        closeIconA11yLabel="accessibilityLabels.closeLayout_personDetails"
      />
      <DrawerCardContent>
        {person ? (
          <PersonCard
            person={person}
            locationName={locationName}
            hoodName={hoodName}
            sensorStatus={sensorStatus}
          />
        ) : null}
      </DrawerCardContent>
    </>
  );
};

export default memo(Person);
