import { RESTRICT_TO_VALUES } from '@engage-shared/constants';

export const PATH_SEGMENT = {
  PEOPLE: 'people',
  DESKS: 'desks',
  SPACES: 'spaces',
  TEAMS: 'teams',
  FAV_PEOPLE_TEAM: 'favourite-people',
  EMPLOYEE: 'employee',
  FILTERS: 'filters',
  RESERVEE: 'reservee',
  LOCATIONS: 'locations',
  LOCATION_SWITCHER: 'wayfinder',
  FLOORPLAN_VIEWS: 'views',
  RESERVE: 'reserve',
  SPACE_ATTRIBUTES: 'space-attributes',
  HEATMAP: 'heatmap',
};

export const PATH_PARAM = {
  DESK_ID: ':deskId',
  SPACE_ID: ':spaceId',
  PERSON_ID: ':personId',
  EMPLOYEE_ID: ':employeeId',
  TEAM_ID: ':teamId',
  TENANT: ':tenant',
};

export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const SSO_LOGOUT_PATH = '/ssoLogout';
export const CALLBACK_PATH = '/callback';
export const WORKSPACE_PATH = '/workspace';
export const WORKSPACE_PATH_INSTANCE = `${WORKSPACE_PATH}/${PATH_PARAM.TENANT}`;
export const APP_LAUNCH_PATH = '/launch';
export const APP_LAUNCH_INTUNE_PATH = '/intune/launch';
export const DEMO_PATH = '/demo';
export const FLOOR_PATH = '/floor/:floorId';
export const PERSON_PATH = `/${PATH_SEGMENT.PEOPLE}/${PATH_PARAM.PERSON_ID}`;
export const TEAM_PATH = `/${PATH_SEGMENT.TEAMS}/${PATH_PARAM.TEAM_ID}`;
export const PEOPLE_PATH = `/${PATH_SEGMENT.PEOPLE}`;
export const ORGANIZATION_POLICY_PATH = '/acknowledgement';
export const CONCIERGE_MENU_PATH = '/menu';
export const BROWSER_PATH = '/browser';
export const KIOSK_ADMIN = '/kiosk_admin';
export const WAYFINDER_PATH = `/${PATH_SEGMENT.LOCATION_SWITCHER}`;
export const LANDING_PATH = '/landing';
export const KEYBOARD_ACCESSIBILITY = '/keyboard_accessibility';

export const SEARCH_QUERY = {
  SPACE: 'space',
  DESK: 'desk',
  PERSON: 'person',
  TEAM: 'team',
  EMPLOYEE: 'employee',
  FLOOR: 'floor',
  BOOKING_SPACE: 'bsid',
  BOOKING_MEETING: 'bmid',
  TEAM_BOOKING_MEETING: 'tbmid',
  ATTENDEE_ID: 'attid',
  DATE: 'date',
  TIME: 'time',
  WORKSPACE: 'workspace',
};

export const DATE_TIME_DEEP_LINK_PARAMS = {
  DATE: SEARCH_QUERY.DATE,
  TIME: SEARCH_QUERY.TIME,
};

export const MODAL_DEEP_LINK_PARAMS = {
  BOOKING_SPACE: SEARCH_QUERY.BOOKING_SPACE,
  BOOKING_MEETING: SEARCH_QUERY.BOOKING_MEETING,
  TEAM_BOOKING_MEETING: SEARCH_QUERY.TEAM_BOOKING_MEETING,
  ATTENDEE_ID: SEARCH_QUERY.ATTENDEE_ID,
};

export const DRAWER_DEEP_LINK_PARAMS = {
  SPACE: SEARCH_QUERY.SPACE,
  DESK: SEARCH_QUERY.DESK,
  PERSON: SEARCH_QUERY.PERSON,
  EMPLOYEE: SEARCH_QUERY.EMPLOYEE,
  FLOOR: SEARCH_QUERY.FLOOR,
};

export const RESTRICT_TO_TO_PATH_SEGMENT = {
  [RESTRICT_TO_VALUES.SPACE]: PATH_SEGMENT.SPACES,
  [RESTRICT_TO_VALUES.DESK]: PATH_SEGMENT.DESKS,
  [RESTRICT_TO_VALUES.PERSON]: PATH_SEGMENT.PEOPLE,
  [RESTRICT_TO_VALUES.TEAM]: PATH_SEGMENT.TEAMS,
};
