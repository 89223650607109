import React, { CSSProperties } from 'react';
import { Avatar, Capacity } from '@engage-web/components/base';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { humanizeNumber, replaceLineFeedWithSpace } from '@engage-web/utils';
import {
  IconWrapper,
  ListItemOuterWrapper,
  ListItemWrapper,
  Subtitle,
  SubtitleWrapper,
  TextWrapper,
  Title,
} from '@engage-web/components/layouts/GlobalSearch/styled';
import SizeRecognitionWrapper from '@engage-web/components/layouts/GlobalSearch/SizeRecognitionWrapper';
import { SearchResponseData } from '@engage-shared/api/search';
import { Theme } from '@engage-shared/theme';
import { getBadgeA11yLabel } from './utils';

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;

type SpaceItemProps = {
  item: SearchResponseData;
  onPress?: (item: SearchResponseData) => void;
  showBadge?: boolean;
  style?: CSSProperties;
  onSizeFound?: (size: DOMRect, index: number) => void;
  index: number;
  as?: 'div';
};

const SpaceItem = ({
  item,
  showBadge = true,
  style,
  onPress,
  onSizeFound,
  index,
  as,
}: SpaceItemProps) => {
  const { status2, status3 } = useTheme() as Theme;
  const { t } = useTranslation();
  const { name, description, location, capacity, available } = item;
  const { level } = location;

  const floorName = `${humanizeNumber(level, t)} ${t('common.floor')}`;
  const spaceName = replaceLineFeedWithSpace(name);
  const onItemPress = () => onPress?.(item);
  const badgeVariant = showBadge ? 'dot' : 'standard';
  const badgeStyle = { backgroundColor: available ? status2 : status3 };
  const badgeA11yLabel = showBadge ? getBadgeA11yLabel({ t, available }) : '';

  return (
    <ListItemOuterWrapper {...(!!as && { as })} $style={style}>
      <SizeRecognitionWrapper onSizeFound={onSizeFound} index={index}>
        <ListItemWrapper
          aria-label={`${t('accessibilityLabels.viewSpaceItem')} ${spaceName}`}
          onClick={onItemPress}
        >
          <Avatar
            icon="space"
            size="small"
            badgeVariant={badgeVariant}
            badgeStyle={badgeStyle}
            badgeA11yLabel={badgeA11yLabel}
            style={{ background: 'transparent' }}
          />
          <TextWrapper>
            <TitleWrapper>
              <Title>{spaceName}</Title>
            </TitleWrapper>
            <SubtitleWrapper>
              <Subtitle className="btn Subtitle">
                {floorName}
                <span>{description}</span>
              </Subtitle>
            </SubtitleWrapper>
          </TextWrapper>
          <IconWrapper>{capacity ? <Capacity capacity={capacity} /> : null}</IconWrapper>
        </ListItemWrapper>
      </SizeRecognitionWrapper>
    </ListItemOuterWrapper>
  );
};

SpaceItem.getSize = () => 75;

export default SpaceItem;
