import OpenSeadragon from 'openseadragon';
import { Coordinate, Shape, Space } from '@floorplan/api';

export const getShapeCoordinate = (space: Space): Coordinate => {
  if (!space) {
    return { x: 0, y: 0 };
  }
  if (space.shape) {
    const shape = space.shape as Shape;
    return shape.labelPoint ? shape.labelPoint : shape.coordinates[0];
  }
  return space.shapes[0].labelPoint ? space.shapes[0].labelPoint : space.shapes[0].coordinates[0];
};

export const getShapeBoundingBox = (space: Space) => {
  if (!space) return null;

  const coords = space.shapes[0].coordinates;

  let bounds = new OpenSeadragon.Rect(coords[0].x, coords[0].y);

  if (space.shapes[0].isPolygon) {
    coords.forEach(point => {
      bounds = bounds.union(new OpenSeadragon.Rect(point.x, point.y));
    });
  } else {
    bounds = bounds.union(new OpenSeadragon.Rect(coords[0].x - 100, coords[0].y - 100));
    bounds = bounds.union(new OpenSeadragon.Rect(coords[0].x + 100, coords[0].y + 100));
  }

  return bounds;
};

export const svLivePersonLocationToShape = (personLocation: Coordinate) => {
  if (!personLocation) return null;

  return {
    shape: { isPolygon: false, coordinates: [personLocation] },
    shapes: [{ coordinates: [personLocation] }],
  };
};

export const getSpaceForID = (spaces: Space[], shapeId: number) => {
  if (!spaces) {
    return null;
  }
  const spaceShape = spaces.filter(space => space.id === shapeId);

  if (!spaceShape[0]) {
    return null;
  }

  return spaceShape[0];
};
