import styled from 'styled-components';

export const HEIGHT = 100;

const Wrapper = styled.div`
  height: ${HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.color9};
`;

const MediaLinkButton = styled.button`
  margin: 0 60px;
  border-radius: 45px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  color: ${({ theme }) => theme.color6};

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export { Wrapper, MediaLinkButton };
