import React, { memo } from 'react';
import { EVENTS_STATUS } from '@engage-web/constants';
import { useSelectedStyles } from '@engage-web/utils/hooks';
import { useTranslation } from 'react-i18next';
import {
  Label,
  Picker,
  Wrapper,
} from '@engage-web/components/layouts/Agenda/content/Filter/styled';
import { Select } from '@engage-web/components/base';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, filtersSelectors } from '@engage-web/store';

const Filter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useSelectedStyles();
  const agendaFilterType = useSelector(filtersSelectors.getAgendaEventsType);

  const myBookingsStr = t('layout.agendaList.myBookings');
  const allBookingsStr = t('layout.agendaList.allBookings');
  const onlyShowStr = t('layout.agendaList.onlyShow');

  const options = [
    { value: EVENTS_STATUS.ONLY_MINE, label: myBookingsStr, selected: true },
    { value: EVENTS_STATUS.ALL, label: allBookingsStr },
  ];
  const defaultValue = options.find(({ value }) => value === agendaFilterType);

  const setEventsFilter = value => {
    dispatch(filtersActions.setAgendaEventsType(value));
  };

  return (
    <Wrapper>
      <Label>{onlyShowStr}</Label>
      <Picker data-testid="agenda-view-filter">
        <Select
          options={options}
          styles={styles}
          isSearchable={false}
          defaultValue={defaultValue}
          onChange={({ value }) => setEventsFilter(value)}
          focusLabel={onlyShowStr}
          id="filter-bookings-selector"
          classNamePrefix="filter-bookings-selector"
          name={t('accessibilityLabels.selectEventsFilter')}
        />
      </Picker>
    </Wrapper>
  );
};

export default memo(Filter);
