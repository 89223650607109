import React, { memo } from 'react';
import DateButton from '@engage-web/components/base/DateTime/DateButton/DateButton';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { useLocalTimeFormatH12 } from '@engage-web/utils';
import useDeepLinkDateTime from '@engage-web/router/hooks/useDeepLinkDateTime';
import { TimeLinesWrapper } from './styled';
import { useFloorPermissions } from '@engage-shared/api';

interface TimelineSelectorProps {
  onDateClear: () => void;
  onDateClick: () => void;
  disableInteractions: boolean;
  selectedDate: Date;
  timeZone: string;
  isDatePickerOpened: boolean;
}

const TimelineSelector = ({
  onDateClick,
  onDateClear,
  timeZone,
  selectedDate,
  disableInteractions,
  isDatePickerOpened,
}: TimelineSelectorProps) => {
  const isFloorSelected = useSelector(tenantSelectors.getCurrentFloorId);
  const isTimeFormatH12 = useLocalTimeFormatH12();

  useDeepLinkDateTime();

  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const { data: permissions } = useFloorPermissions({
    floorId: floorId || 0,
    options: { enabled: !!floorId },
  });
  const { desksBookingPermission, spacesBookingPermission, teamBookingPermission } =
    permissions || {};

  // P2-3711: If all 3 return false, hide the main ‘Date/Time’ selector
  if (!desksBookingPermission && !spacesBookingPermission && !teamBookingPermission) {
    return null;
  }

  if (!isFloorSelected) {
    return null;
  }

  return (
    <TimeLinesWrapper data-testid="timelines-wrapper">
      <DateButton
        disableInteractions={disableInteractions}
        isTimeFormatH12={isTimeFormatH12}
        selectedDate={selectedDate}
        isOpen={isDatePickerOpened}
        onClick={onDateClick}
        timeZone={timeZone}
        onDateClear={onDateClear}
      />
    </TimeLinesWrapper>
  );
};

export default memo(TimelineSelector);
