import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { testProps } from '@engage-web/utils';
import { Icon } from '../Icons/Icon';
import { BackButtonStyled } from './styled';

interface BackButtonProps {
  onClick: () => void;
  iconSize?: number;
  iconColor?: string;
}

const BackButton = ({ onClick, iconSize = 32, iconColor = '' }: BackButtonProps) => {
  const { t } = useTranslation();
  const { typo2, isLayoutRTL } = useTheme();

  return (
    <BackButtonStyled onClick={onClick} {...testProps(t, 'accessibilityLabels.goBack').props}>
      <Icon
        name={isLayoutRTL ? 'arrow-right' : 'arrow-left'}
        size={iconSize}
        style={{
          color: iconColor ? iconColor : typo2,
        }}
      />
    </BackButtonStyled>
  );
};

export default BackButton;
