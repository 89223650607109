import styled from 'styled-components';
import { Z_INDEX } from '@engage-web/constants';

export const Overlay = styled.div`
  position: fixed;
  z-index: ${Z_INDEX.DIALOG_OVERLAY};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(3, 13, 48, 0.7);
  backdrop-filter: blur(5px);

  &.overlay-enter {
    opacity: 0;
  }
  &.overlay-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.overlay-exit {
    opacity: 1;
  }
  &.overlay-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;
