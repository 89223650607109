import styled from 'styled-components';

export const PersonButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PersonButtonTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.typo2};
`;

export const PersonButtonNameWrapper = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, disabled }) => (disabled ? '#cccccc' : theme.color9)};
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 5px;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const PersonButtonName = styled.div<{ disabled?: boolean }>`
  margin-left: 5px;
  font-size: 14px;
  color: ${({ theme, disabled }) => (disabled ? '#000000' : theme.typo1)};
`;
