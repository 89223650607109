import React from 'react';
import { useTheme } from 'styled-components';
import { Theme } from '@engage-shared/theme';
import ClientLogo from '../ClientLogo/ClientLogo';
import { isElectron } from '../../../utils/electron';
import KioskCurrentDateTime from '../../kiosk/KioskCurrentDateTime';
import { HeaderWrapper } from './LightHeader.style';

type LightHeaderProps = { useLogoColors?: boolean };
const LightHeader = ({ useLogoColors = false }: LightHeaderProps) => {
  const { typo1 } = useTheme() as Theme;

  // enable kiosk mode here
  const isKiosk = isElectron();

  return (
    <HeaderWrapper>
      <ClientLogo textColor={useLogoColors ? undefined : typo1} />
      {isKiosk && <KioskCurrentDateTime textColor={typo1} />}
    </HeaderWrapper>
  );
};

export default LightHeader;
