import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { tenantSelectors } from '@engage-web/store';
import { useRefreshTeamBooking } from '@engage-web/api/queries';
import { ATTENDEE_STATUS, bookingDetailsButtonStatuses as STATUSES } from '@engage-web/constants';
import { FocusId, useDialog } from '@engage-web/utils';
import { ConfirmationDialog } from '@engage-web/components/base';
import { Button } from '../../../Button';
import { TeamButtonStatus } from '@engage-shared/utils';
import { Theme } from '@engage-shared/theme';
import { acceptTeamBooking } from '@engage-shared/api';

type AcceptProps = {
  meetingId: number;
  status: TeamButtonStatus;
  setBookingStatus: (bookingStatus: ATTENDEE_STATUS) => void;
};
export function Accept({ status, meetingId, setBookingStatus }: AcceptProps) {
  const { DISPLAY, DISABLED } = STATUSES;
  const { [DISPLAY]: display, [DISABLED]: disabled } = status;

  const { t } = useTranslation();
  const tenantId = useSelector(tenantSelectors.getTenantId);

  const { color1, color6 } = useTheme() as Theme;
  const { refresh: refreshTeamBooking } = useRefreshTeamBooking();
  const { openDialog } = useDialog();

  const onAcceptPress = async () => {
    try {
      await acceptTeamBooking({ tenantId, id: meetingId });

      setBookingStatus(ATTENDEE_STATUS.ACCEPTED);
      refreshTeamBooking({ id: meetingId });
    } catch (error) {
      openDialog(
        <ConfirmationDialog
          title={t('layout.floorplanError.errorOccurred')}
          message={t('layout.reserve.team.errorOccurred')}
          key="team-booking-accept-error-dialog"
          triggerId={FocusId.TeamBookingAccept}
          hasCancelButton={false}
        />,
      );
    }
  };

  return display ? (
    <Button
      onClick={onAcceptPress}
      disabled={disabled}
      opacity={disabled ? 0.4 : 1}
      color={color6}
      backgroundColor={color1}
      text={t('common.accept')}
    />
  ) : null;
}
