import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { ISODateString } from '@engage-shared/utils/types';
import { fetchAvailability } from '../fetch';
import { BuildingAvailability, FetchBuildingParams } from '../interfaces';
import { BUILDING_AVAILABILITY_QUERY_KEY } from '../constants';

type UseAvailabilityQueryParams = FetchBuildingParams & {
  startTime?: ISODateString;
  startTimeZone?: string;
  options?: UseQueryOptions;
};

type UseAvailabilityQuery = (params: UseAvailabilityQueryParams) => UseQueryResult;

export const useAvailability: UseAvailabilityQuery = ({
  buildingId,
  startTime,
  startTimeZone,
  options = {},
}) => {
  return useQuery({
    queryKey: [BUILDING_AVAILABILITY_QUERY_KEY, buildingId, startTime],
    queryFn: () => fetchAvailability({ buildingId, startTime, startTimeZone }),
    enabled: !!buildingId,
    ...options,
  });
};

type UseAvailabilityFetchParams = {
  buildingId: number;
  startTime?: ISODateString;
  startTimeZone?: string;
};

type UseAvailabilityFetch = () => {
  fetchAvailabilityQuery: ({
    buildingId,
    startTime,
    startTimeZone,
  }: UseAvailabilityFetchParams) => Promise<BuildingAvailability>;
};

export const useAvailabilityFetch: UseAvailabilityFetch = () => {
  const queryClient = useQueryClient();

  const query = ({
    buildingId,
    startTime,
    startTimeZone,
  }: UseAvailabilityFetchParams): Promise<BuildingAvailability> =>
    queryClient.fetchQuery([BUILDING_AVAILABILITY_QUERY_KEY, buildingId, startTime], () =>
      fetchAvailability({ buildingId, startTime, startTimeZone }),
    );

  const fetchAvailabilityQuery = useCallback(query, [queryClient]);

  return { fetchAvailabilityQuery };
};
