import styled from 'styled-components';

export const baseStyle = {
  fillOpacity: 1,
  stroke: 'white',
  strokeWidth: '2',
};

export const RadarStyle = styled.svg`
  @keyframes radarAnimation {
    0% {
      r: 0%;
      opacity: 1;
    }
    85% {
      r: 50%;
      opacity: 0.1;
    }
    100% {
      r: 50%;
      opacity: 0;
    }
  }

  .innerCircle {
    animation-name: radarAnimation;
    animation-duration: 8s;
    animation-timing-function: ease-out;
    animation-delay: 1s;
  }
  .innerCircle2 {
    animation-name: radarAnimation;
    animation-duration: 8s;
    animation-timing-function: ease-out;
    animation-delay: 3s;
  }
  .innerCircle3 {
    animation-name: radarAnimation;
    animation-duration: 8s;
    animation-timing-function: ease-out;
    animation-delay: 5s;
  }
`;
