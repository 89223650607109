import { getApiUrl, ParsedUserData, SpaceType } from '@engage-shared/api';
import logger from '@engage-web/utils/logger';
import { API_VERSIONS, RESTRICT_TO_VALUES } from '@engage-web/constants';
import { RestrictTo } from '@engage-web/utils';
import { SearchResponse } from '@engage-shared/api/search/interfaces';
import { ISODateString } from '@engage-shared/utils/types';

const getUserData = (user: ParsedUserData) => ({
  id: user.id,
  name: user.name,
  description: user.jobTitle,
  imageUrl: user.imageUrl,
  location: {}, // value not needed, want to display it first, only to avoid error on processing sections
});

interface GetSearchDataParams {
  tenantId: string;
  floorId: number;
  isFirstAidOfficer: boolean;
  isFireWarden: boolean;
  startTime: ISODateString;
  endTime: ISODateString;
  roomsStartTime: ISODateString;
  roomsEndTime: ISODateString;
  capacity: number;
  filterDates: string[] | null;
  restrictTo: RestrictTo;
  searchString: string;
  spaceTypes: number[];
  spaceAttributes: number[];
}

export const getSearchData = ({
  tenantId,
  floorId,
  isFirstAidOfficer,
  isFireWarden,
  startTime,
  endTime,
  roomsStartTime,
  roomsEndTime,
  capacity,
  filterDates,
  restrictTo,
  searchString,
  spaceTypes,
  spaceAttributes,
}: GetSearchDataParams) => {
  const data = {
    tenantId,
    floorId,
    startTime,
    endTime,
    searchString,
    restrictTo: [restrictTo],
    page: 0, // TODO: page does not seem to be used but is required in fetch type
    perPage: 40,
    capacity: 0,
    selectedDates: [] as string[],
    meetingRoomAttributes: [] as number[],
    meetingRoomTypeId: [] as number[],
    isFirstAidOfficer: false,
    isFireWarden: false,
    showFloorOnly: false,
  };

  if (filterDates) {
    data.selectedDates = filterDates;
  }

  if (restrictTo === RESTRICT_TO_VALUES.SPACE) {
    data.capacity = capacity === 1 ? 0 : capacity;
    data.startTime = roomsStartTime;
    data.endTime = roomsEndTime;
  }

  if (restrictTo === RESTRICT_TO_VALUES.SPACE || restrictTo === RESTRICT_TO_VALUES.DESK) {
    if (spaceTypes?.length > 0) {
      data.meetingRoomTypeId = spaceTypes;
    }

    if (spaceAttributes?.length > 0) {
      data.meetingRoomAttributes = spaceAttributes;
    }
  }

  if (restrictTo === RESTRICT_TO_VALUES.PERSON) {
    data.isFirstAidOfficer = isFirstAidOfficer;
    data.isFireWarden = isFireWarden;
  }

  if (
    !searchString &&
    (restrictTo === RESTRICT_TO_VALUES.PERSON || restrictTo === RESTRICT_TO_VALUES.TEAM)
  ) {
    data.showFloorOnly = true;
  }

  logger.debug(`${getApiUrl(tenantId, API_VERSIONS.V2)}/search`)();

  return data;
};

interface CreateTransformSearchResponseParams {
  showUserFirst: boolean;
  searchString: string;
  user: ParsedUserData | undefined;
  filterDates: string[] | null;
  restrictTo: RestrictTo;
}

export const createTransformSearchResponse =
  ({ showUserFirst, searchString, user }: CreateTransformSearchResponseParams) =>
  (response: SearchResponse) => {
    let responseData = response.data;

    if (responseData.some(item => item.type === 'Person')) {
      if (user?.id && showUserFirst && !searchString) {
        const userData = getUserData(user);
        // Convert userData to SearchResponse format
        // TODO: fix these types. This is only used for the Reservable list to place the user at the top of the list
        const responseUserData = {
          ...userData,
          initials: '',
          isFavourite: false,
          type: 'Person' as SpaceType,
          grouping: 'none',
          location: {
            building: '',
            buildingId: 0,
            floorId: 0,
            level: '',
            spaceId: 0,
          },
        };

        responseData = [responseUserData, ...responseData];
      }

      responseData = responseData.filter(
        (item, index, items) => items.findIndex(t => t.id === item.id) === index,
      );
    }

    return { data: responseData, meta: response.meta };
  };
