import { useConfig } from './useConfig';
import { useCallback } from 'react';
import { exchange } from '../../api/auth';
import { useDispatch } from 'react-redux';
import { TokenManager } from '@engage-shared/api';
import { tenantActions } from '../../store';

export const useAzureAuth = () => {
  const { instanceTitle, logo } = useConfig();
  const dispatch = useDispatch();

  const azureAuth = useCallback(
    async ({
      tenantId,
      idToken,
    }: {
      tenantId: string | undefined;
      idToken: string | undefined;
    }): Promise<[boolean, string | null]> => {
      try {
        let result = false;
        if (!tenantId || !idToken) return [false, null];

        const authResult = await exchange({
          tenantId,
          idToken,
        });
        const { token, refreshToken, error } = authResult;
        if (error) return [false, error];

        dispatch(
          tenantActions.addTenant({
            id: tenantId,
            name: instanceTitle,
            logo,
          }),
        );
        if (token && refreshToken) {
          result = true;
          await TokenManager.setTokens({
            token,
            refreshToken,
          });
          dispatch(tenantActions.setUser(TokenManager.getUserIdFromToken(token)));
        }

        return [result, null];
      } catch (error) {
        console.error(error);
        return [false, 'exception'];
      }
    },
    [dispatch, instanceTitle, logo],
  );

  return { azureAuth };
};
