import React from 'react';
import ReactFocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import { FocusId, useDialog, useFocus, useEscapeListener } from '@engage-web/utils/hooks';
import { Wrapper, Actions, Message, Title, Button, PrimaryButton } from './styled';

type ConfirmationDialogProps = {
  /**
   * Title which will be displayed in bold text.
   */
  title: string;
  /**
   * `id` of the button element which triggers the dialog.
   * Required to return focus back to the trigger element when dialog is closed.
   */
  triggerId?: FocusId;
  /**
   * Message which will be displayed below the title if provided.
   */
  message?: string;
  /**
   * If this is false – "Cancel" button will not be displayed.
   * This way the dialog will act just like an alert.
   */
  hasCancelButton?: boolean;
};

export function ConfirmationDialog({
  title,
  triggerId,
  message,
  hasCancelButton = true,
}: ConfirmationDialogProps) {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();

  const confirm = () => closeDialog(true);
  const cancel = () => closeDialog(false);

  useEscapeListener({ onEscapePress: cancel });
  useFocus({ returnFocusTo: triggerId });

  const a11yProps: Record<string, string> = {
    'aria-labelledby': 'dialog-title',
  };

  if (message) {
    a11yProps['aria-describedby'] = 'dialog-message';
  }

  return (
    <ReactFocusLock>
      <Wrapper role="dialog" {...a11yProps}>
        <Title id="dialog-title">{title}</Title>
        {message ? <Message id="dialog-message">{message}</Message> : null}
        <Actions hasCancelButton={hasCancelButton}>
          <PrimaryButton onClick={confirm}>{t('common.ok')}</PrimaryButton>
          {hasCancelButton ? <Button onClick={cancel}>{t('common.cancel')}</Button> : null}
        </Actions>
      </Wrapper>
    </ReactFocusLock>
  );
}
