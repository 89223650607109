import { useSelector } from 'react-redux';
import { userConfigSelectors } from '@engage-web/store';
import { LanguageTypes } from '@engage-shared/constants';

export const useHasChineseCharacters = () => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const languagesWithChineseChars = [
    LanguageTypes.ja,
    LanguageTypes.jaJP,
    LanguageTypes.ko,
    LanguageTypes.koKR,
    LanguageTypes.zh,
    LanguageTypes.zhCN,
  ];

  return !!languagesWithChineseChars.find(lang => lang === locale);
};
