import { Nullable } from '@engage-shared/utils/types';

const downloadPathRegex = /engage_api\/v1\/stored_files\/[0-9A-z]*\/download/i;

export const isDownloadPath = (path: string | undefined): boolean =>
  path ? downloadPathRegex.test(path) : false;

type GetPersonImageUrl = ({
  imageUrl,
  isDownloadImage,
  isSuccess,
  fileImageUrl,
}: {
  imageUrl: string | undefined;
  isDownloadImage: boolean;
  isSuccess: boolean;
  fileImageUrl: Nullable<string>;
}) => Nullable<string>;

export const getPersonImageUrl: GetPersonImageUrl = ({
  imageUrl,
  isDownloadImage,
  isSuccess,
  fileImageUrl,
}) => {
  if (!imageUrl) return null;
  if (!isDownloadImage) return imageUrl ?? null;
  if (isDownloadImage && isSuccess) return fileImageUrl;

  return null;
};
