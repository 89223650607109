import { OptionalArguments } from '@engage-shared/utils/types';

/**
 * Check that the provided callback is a function and then invoke it with provided arguments.
 * @param {Function} cb Callback to be invoked.
 * @param args Callback's arguments.
 */
export const safelyInvokeCallback = <Callback>(
  cb: Callback,
  ...args: OptionalArguments<Callback>
): void => {
  if (cb && typeof cb === 'function') {
    cb(...args);
  }
};
