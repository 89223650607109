import axios, { InternalAxiosRequestConfig } from 'axios';
import { API_TIMEOUT } from '@engage-shared/constants/api';
import { getLogger } from '@engage-shared/utils/logger';
import { getTokens } from './token-manager/token';
import { getAuthorizationHeaderObjectFromToken } from '@engage-shared/api/instance/headers';
import {
  getValidatedAuthorizationHeaderObject,
  tryInvalidateConfigHeader,
} from '@engage-shared/api/instance/authHandler';
import { getConfig } from '@engage-shared/api/instance/config';
import { HttpStatus, TFunction } from '@engage-shared/utils/types';
import { createError } from '@engage-shared/api/instance/errors';

const Api = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: API_TIMEOUT,
});
const CancelToken = axios.CancelToken;
const invalidTokenMessage = 'Request cancelled: invalid tenantId or token';

Api.interceptors.request.use(
  async (request: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    // get tenantId and token
    const { token } = await getTokens();
    // set the cancelToken
    const source = CancelToken.source();
    request.cancelToken = source.token;
    // Cancel the request if we don't have a valid token.
    if (!token || !getConfig().callApiRequestValid(request)) {
      source.cancel(invalidTokenMessage);
    }
    // get authHeader
    const authHeader = getAuthorizationHeaderObjectFromToken(token);

    request.headers.set(authHeader);

    // TODO: Check header
    return request;
  },
  error => {
    getLogger().error(error);
  },
);

let isRefreshing = false;
type Queue = {
  resolve: TFunction<any>;
  reject: TFunction<any>;
};
let refreshQueue: Queue[] = [];
const retries = 1;

Api.interceptors.response.use(undefined, async error => {
  if (axios.isCancel(error)) {
    await getConfig().callApiErrorResponse(error);
  }
  const orgConfig = error.config;
  const status = error?.response?.status;

  if (status !== HttpStatus.UNAUTHORIZED) {
    const apiError = createError(error, status);
    getLogger().error(apiError);
    return Promise.reject(apiError);
  }
  orgConfig._retry = typeof orgConfig._retry === 'undefined' ? 0 : ++orgConfig._retry;

  if (orgConfig._retry === retries) {
    const apiError = createError(error, status);
    getLogger().error(apiError);
    return Promise.reject(apiError);
  }
  if (!isRefreshing) {
    isRefreshing = true;

    // signal that the current config header is invalid
    tryInvalidateConfigHeader(orgConfig);
    // validated authorized header
    getValidatedAuthorizationHeaderObject()
      .then(authorizedHeader => {
        // resolve every request in queue
        refreshQueue.forEach(request => request.resolve(authorizedHeader));
        refreshQueue = [];
      })
      .catch(err => {
        // reject every request in queue
        refreshQueue.forEach(request => request.reject(err));
        refreshQueue = [];
        getConfig().callApiRefreshError(error);
      })
      .finally(() => {
        isRefreshing = false;
      });
  }

  return new Promise((resolve, reject) => {
    refreshQueue.push({
      resolve: res => {
        const config = {
          ...orgConfig,
          headers: {
            ...orgConfig.headers,
            ...res,
          },
        };
        resolve(Api.request(config));
      },
      reject: err => {
        getLogger().error(error);
        reject(err);
      },
    });
  });
});

export default Api;
