import {
  BookingMutationData,
  useMultiDayBookingMutation as useSharedMultiDayBookingMutation,
} from '@engage-shared/api';
import { trackReservationAnalytics } from '@engage-web/utils/analytics/useReserveAnalytics';
import { RESERVATION_ANALYTICS_TYPE } from '@engage-web/constants';
import { getLocalTimeZone, useReservationNotification } from '@engage-web/utils';
import { useDispatch, useSelector } from 'react-redux';
import { tenantSelectors, userConfigSelectors } from '@engage-web/store';
import { useConfig } from '@engage-web/utils/hooks';
import { handleSocialDistancing } from '@engage-web/api/mutations/reserve/helpers';
import { ParsedSpaceItem } from '@engage-shared/api/spaces';
import { UserData } from '@engage-shared/api/users';
import { BookingFilter, Reservation } from '@engage-shared/api/bookings';
import { Nullable } from '@engage-shared/utils/types';
import { CustomError } from '@engage-shared/api/instance/errors';

type UseMultiDayBookingMutationProps = {
  spaceItem: ParsedSpaceItem;
  isDesk: boolean;
  user: UserData;
  filter: BookingFilter;
  reserveeId: Nullable<number>;
  duration: number;
  allDayDuration: boolean;
  isUpdate?: boolean;
  summaryText?: string;
  selectedDays: Date[];
  onSuccess?: ({
    successfullyReserved,
    successfullyReservedDaysMessage,
    failedDaysMessage,
    concurrentDaysMessage,
    limitPerIntervalMessage,
    limitPerBuildingMessage,
    bookedDays,
  }: {
    successfullyReserved: boolean;
    successfullyReservedDaysMessage: string;
    failedDaysMessage: string;
    concurrentDaysMessage: string;
    limitPerIntervalMessage: string;
    limitPerBuildingMessage: string;
    bookedDays: BookingMutationData[];
  }) => void;
  onError?: (e: CustomError) => void;
};
export const useMultiDayBookingMutation = ({
  spaceItem,
  isDesk,
  user,
  filter,
  reserveeId,
  duration,
  allDayDuration,
  isUpdate,
  summaryText,
  selectedDays,
  onSuccess,
  onError,
}: UseMultiDayBookingMutationProps) => {
  const dispatch = useDispatch();
  const tenantId = useSelector(tenantSelectors.getTenantId);

  const { handleLocalNotification } = useReservationNotification();
  const showSocialDistancingInfo = useSelector(userConfigSelectors.getShowSocialDistancingInfo);
  const { isCleverSocialDistancingActive } = useConfig(spaceItem.buildingId);

  const email = user?.email;
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const multiDayBookingMutation = useSharedMultiDayBookingMutation(
    {
      tenantId,
      email,
      localTimeZone: getLocalTimeZone(),
      summaryText,
      filter,
      // @ts-ignore
      spaceItem,
      // @ts-ignore
      reserveeId,
      duration,
      allDayDuration,
      // @ts-ignore
      isUpdate,
      selectedDays,
      locale,
      // @ts-ignore
      successCallback: (reservation: Reservation) => {
        trackReservationAnalytics({
          reservation,
          spaceItem,
          analyticsType: RESERVATION_ANALYTICS_TYPE.SUCCESS,
        });
        if (isCleverSocialDistancingActive && isDesk) {
          handleSocialDistancing({
            dispatch,
            spaceItem,
            showSocialDistancingInfo,
          });
        }

        if (isDesk) {
          handleLocalNotification({
            event: {
              ...spaceItem,
              ...reservation,
              timeZone: reservation.startTimeZone,
            },
            isUpdate: false,
          });
        }
      },
      errorCallback: () => {
        trackReservationAnalytics({
          spaceItem,
          analyticsType: RESERVATION_ANALYTICS_TYPE.FAIL,
        });
      },
    },
    {
      onSuccess,
      onError,
    },
  );

  const { saveMultiDayBooking } = multiDayBookingMutation;

  const onBooking = () => {
    trackReservationAnalytics({
      spaceItem,
      analyticsType: RESERVATION_ANALYTICS_TYPE.START,
    });
    saveMultiDayBooking();
  };

  return { ...multiDayBookingMutation, onBooking };
};
