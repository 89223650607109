import { Api, getApiUrl } from '@engage-shared/api/instance';
import { SetOptOutStatus } from '@engage-shared/api/people/interfaces';
import { getLogger } from '@engage-shared/utils/logger';

/**
 * Set person Opt-out status of Engage
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | update a person successful |
 * | 422 | Unable to update “Engage Opt-out” when “Allow users to opt-out” is false |
 * @param tenantId
 * @param engageOptOut
 */
export const setOptOutStatus: SetOptOutStatus = async ({ tenantId, engageOptOut }) => {
  const path = new URL(
    `${getApiUrl(tenantId)}/people/set_opt_out_status?engage_opt_out=${engageOptOut}`,
  );
  try {
    await Api.put(path.toString());
  } catch (error) {
    getLogger().error(error);
  }
};
