import { ATTENDEE_STATUS } from '@engage-web/constants';
import { badgeColor, STATUS } from '@engage-web/components/base/Avatar/config';
import { Theme } from '@engage-shared/theme';

type GetAttendeeStatusProps = { status: ATTENDEE_STATUS; theme: Theme };

export const getAttendeeStatus = ({ status, theme }: GetAttendeeStatusProps) => {
  const { typo1, typo2 } = theme;

  let iconName;
  let iconBackgroundColor = badgeColor.primary;
  let iconColor = typo1;
  let avatarStatus = STATUS.PRIMARY;
  switch (status) {
    case ATTENDEE_STATUS.ACCEPTED: {
      iconName = 'check';
      avatarStatus = STATUS.SUCCESS;
      iconBackgroundColor = badgeColor.success;
      break;
    }
    case ATTENDEE_STATUS.DECLINED: {
      iconName = 'times';
      avatarStatus = STATUS.ERROR;
      iconBackgroundColor = badgeColor.error;
      break;
    }
    case ATTENDEE_STATUS.TENTATIVE: {
      iconName = 'question-solid';
      iconBackgroundColor = badgeColor.primary;
      avatarStatus = STATUS.WARNING;
      iconColor = typo2;
      break;
    }
    default: {
      iconName = 'check';
    }
  }
  return {
    iconName,
    iconColor,
    iconBackgroundColor,
    avatarStatus,
  };
};
