import { KioskRemoteConfig } from '@engage-web/api/queries';

export type KioskLocationMetadata = {
  id: number;
  name: string;
  kioskOriginalData: KioskRemoteConfig[];
};

type GroupConfigsByBuildingAndFloor = (
  data: KioskRemoteConfig[],
  getMetadata: (obj: KioskRemoteConfig) => Pick<KioskLocationMetadata, 'id' | 'name'>,
) => KioskLocationMetadata[];

/**
 * Transforms KioskRemoteConfig array to object that
 * is easy applicable to LocationSelector-like usage
 * @param data - kiosk remote configurations
 * @param getMetadata - data selector - whether we want to transform data based on floor or building id and name
 */
export const groupConfigsByBuildingAndFloor: GroupConfigsByBuildingAndFloor = (
  data,
  getMetadata,
) => {
  const { result } = data.reduce<{
    keyToIndex: Map<number, number>;
    result: KioskLocationMetadata[];
  }>(
    (previousValue, currentValue) => {
      const { keyToIndex, result } = previousValue;

      const { id, name } = getMetadata(currentValue);
      const index = keyToIndex.get(id);

      if (index !== undefined && index >= 0) {
        result[index]?.kioskOriginalData.push(currentValue);
      } else {
        const length = result.push({
          id,
          name,
          kioskOriginalData: [currentValue],
        });
        keyToIndex.set(id, length - 1);
      }

      return previousValue;
    },
    {
      keyToIndex: new Map(),
      result: [],
    },
  );

  return result;
};
