import { TenantId } from '@engage-shared/api/tenant/interfaces';
import { BookingEventStatus, BookingType } from '../../bookings/interfaces';
import { ISODateString, Nullable } from '@engage-shared/utils/types';
import { TeamBookingOrganizer, TeamBookingId, TeamId } from '@engage-shared/api/types';
import { PersonId } from '@engage-shared/api/people';

export type FetchParams = {
  tenantId: TenantId;
};

export type FetchFloorDeskAvailabilitiesParams = {
  floorId: number;
  startTime?: ISODateString;
  endTime?: ISODateString;
};

export enum LocationType {
  Building = 'Building',
  Region = 'Region',
  Floor = 'Floor',
}

export interface FloorLocation {
  id: number;
  name: string;
  type: LocationType;
}

export interface Floor {
  id: number;
  name: string;
}

export interface FloorData extends Floor {
  sequence: number;
  available_count: Nullable<number>;
  available_percentage: Nullable<number>;
  bookable_count: Nullable<number>;
}

// summary of a team booking
type TeamBooking = {
  id: TeamBookingId;
  teamId: TeamId;
  teamName: string;
  desks: number;
  attendees: number;
};

// different from Reservee in api/bookings because that one has reserveeId instead of id
type Reservee = {
  id: PersonId;
  displayName: string;
  email: string;
};

export interface FloorBooking {
  meetingId: number;
  summary: Nullable<string>;
  spaceId: number;
  eventStatus: BookingEventStatus;
  start: ISODateString;
  end: ISODateString;
  startTimeZone: string;
  endTimeZone: string;
  isAllDayBooking: boolean;
  bookingType: BookingType;
  teamBooking: Nullable<TeamBooking>;
  organizer: TeamBookingOrganizer;
  reservee: Reservee;
}

export type FetchFloorBookingsParams = FetchParams & {
  floorId?: number;
  // Optional - comma separated list of space ids to retrieve bookings for
  spaces?: number[];
  // Optional - defaults to Now
  startTime?: ISODateString;
  // Optional - defaults to Start Time + 1 Hours
  endTime?: ISODateString;
};
