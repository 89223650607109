import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from '@engage-web/components/base';
import { DetailsWrapper, IconWrapper, Wrapper } from './styled';

const AttendeeSkeleton = () => (
  <SkeletonTheme style={{ width: '100%' }}>
    <Wrapper>
      <Skeleton circle height={36} width={36} />
      <DetailsWrapper>
        <h4>
          <Skeleton width={200} />
        </h4>
        <h6>
          <Skeleton width={200} />
        </h6>
      </DetailsWrapper>
      <IconWrapper>
        <Skeleton circle height={18} width={18} />
      </IconWrapper>
    </Wrapper>
  </SkeletonTheme>
);

export default AttendeeSkeleton;
