import React from 'react';
import { LightHeader, Spinner } from '../../base';
import { useSetPageTitle } from '../../../utils/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { PATH_SEGMENT, WAYFINDER_PATH } from '../../../constants';
import { useMenuItems } from '../../../api/queries/menu/useMenuItems';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { isElectron } from '../../../utils/electron';
import { generateFloorPath, joinPaths } from '../../../router/utils';
import { ConciergeMenuLoader, ConciergeMenuWrapper } from './styled';
import { ConciergeMenuError } from './ConciergeMenuError';
import { ConciergeMenuCarousel } from './ConciergeMenuCarousel';

export const ConciergeMenu = () => {
  const { data, isLoading, isError } = useMenuItems();
  const { t } = useTranslation();
  const { heatmapKiosk } = useSelector(tenantSelectors.getKioskConfig);
  const currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);
  const isKiosk = isElectron();

  useSetPageTitle(t('documentTitles.conciergeMenu'));

  const menuItems = data ?? [];

  const notEnoughMenuItems = isKiosk ? menuItems?.length < 1 : menuItems?.length < 3;

  const renderContent = () => {
    if (isLoading) {
      return (
        <ConciergeMenuLoader data-testid="concierge-menu-loader">
          <Spinner />
        </ConciergeMenuLoader>
      );
    }

    if (isError) {
      return <ConciergeMenuError />;
    }

    if (notEnoughMenuItems) {
      if (heatmapKiosk && isElectron()) {
        return (
          <Navigate
            replace
            to={joinPaths(generateFloorPath(currentFloorId), PATH_SEGMENT.HEATMAP)}
          />
        );
      }

      return <Navigate replace to={WAYFINDER_PATH} />;
    }

    return <ConciergeMenuCarousel menuItems={menuItems} />;
  };

  return (
    <ConciergeMenuWrapper>
      <LightHeader />
      {renderContent()}
    </ConciergeMenuWrapper>
  );
};
