import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from '@engage-web/components/base';
import { FloorsListWrapper } from './styled';

const FloorListSkeleton = () => (
  <SkeletonTheme>
    <FloorsListWrapper data-testid="floors-list-skeleton">
      <Skeleton width={200} height={15} />
      <Skeleton width={150} height={15} />
    </FloorsListWrapper>
  </SkeletonTheme>
);

export default FloorListSkeleton;
