import { AZURE_TENANTS } from './azureConfig';

const getIdToken = (searchQuery: string, queryParam: string): string | null => {
  if (!searchQuery) return null;
  const query = new URLSearchParams(searchQuery);
  return query.get(queryParam) ?? null;
};

export const getIsAzureTenant = (
  tenantId: string | undefined,
  search: string | undefined,
): { isAzureTenant: boolean; idToken: string | null } => {
  if (!tenantId || !search) {
    return { isAzureTenant: false, idToken: null };
  }
  const isAzureTenant = Object.keys(AZURE_TENANTS).includes(tenantId);
  const queryParam = AZURE_TENANTS[tenantId]?.queryParam;
  if (queryParam && isAzureTenant) {
    const idToken = getIdToken(search, queryParam);
    return { isAzureTenant, idToken };
  }
  return { isAzureTenant: false, idToken: null };
};
