import { NO_DAY_SELECTED } from '@engage-shared/constants/deskReservationConstants';
import {
  BookingsFailedMessages,
  MultiDayBookingMutationData,
  BookingsSuccessMessages,
  SaveBookingParams,
  SaveMultiDayParams,
  BookingMutationData,
} from '@engage-shared/api/bookings/interfaces';
import { saveBooking } from '@engage-shared/api/bookings/mutations/utils/saveBooking';
import {
  handleFailedBookingMessages,
  handleSuccessBookingMessages,
} from '@engage-shared/api/bookings/mutations/utils/bookingMessages';
import { safelyInvokeCallback } from '@engage-shared/api/bookings/mutations/utils/callback';
import { AxiosError } from 'axios';
import { LanguageTypes } from '@engage-shared/constants/languages';

type HandleDayBookingParams = SaveBookingParams & {
  bookingsSuccessMessages: BookingsSuccessMessages;
  bookingsFailedMessages: BookingsFailedMessages;
  selectedDay: Date;
  locale: LanguageTypes;
};
type HandleDayBooking = (
  params: HandleDayBookingParams,
) => Promise<BookingMutationData | undefined>;
const handleDayBooking: HandleDayBooking = async ({
  tenantId,
  spaceItem,
  duration,
  allDayDuration,
  filter,
  email,
  summaryText,
  reserveeId,
  localTimeZone,
  fetchBuildingQuery,
  hasBuildingLimitPer15Minutes,
  isUpdate,
  bookingInitialSpaceId,
  meetingId,
  selectedDay,
  successCallback,
  errorCallback,
  bookingsSuccessMessages,
  bookingsFailedMessages,
  locale,
}) => {
  try {
    const newReservationData = await saveBooking({
      tenantId,
      spaceItem,
      duration,
      allDayDuration,
      filter,
      email,
      summaryText,
      reserveeId,
      localTimeZone,
      fetchBuildingQuery,
      hasBuildingLimitPer15Minutes,
      isUpdate,
      bookingInitialSpaceId,
      meetingId,
      selectedDay,
    });
    // flag that at least one reservation was successful
    handleSuccessBookingMessages({
      selectedDay,
      timeZone: newReservationData.startTimeZone,
      bookingsSuccessMessages,
      locale,
    });
    bookingsSuccessMessages.successfullyReserved = true;
    safelyInvokeCallback(successCallback, newReservationData);
    return newReservationData;
  } catch (error) {
    const { buildingId } = spaceItem;
    const building = await fetchBuildingQuery(buildingId);
    const timeZone = building?.timeZone || localTimeZone;
    handleFailedBookingMessages({
      error: error as AxiosError,
      selectedDay,
      timeZone,
      bookingsFailedMessages,
      locale,
    });
    safelyInvokeCallback(errorCallback, error as AxiosError, selectedDay);
  }
};

export type SaveMultiDayBooking = (
  params: SaveMultiDayParams,
) => Promise<MultiDayBookingMutationData>;
/**
 * Creates multiple bookings and handle the error for the ones that failed.
 * @param tenantId
 * @param spaceItem
 * @param duration
 * @param allDayDuration
 * @param filter
 * @param email
 * @param summaryText
 * @param reserveeId
 * @param localTimeZone
 * @param successCallback
 * @param errorCallback
 * @param fetchBuildingQuery
 * @param hasBuildingLimitPer15Minutes
 * @param isUpdate
 * @param bookingInitialSpaceId
 * @param meetingId
 * @param selectedDays
 * @param locale
 */
export const saveMultiDayBooking: SaveMultiDayBooking = async ({
  tenantId,
  spaceItem,
  duration,
  allDayDuration,
  filter,
  email,
  summaryText,
  reserveeId,
  localTimeZone,
  successCallback,
  errorCallback,
  fetchBuildingQuery,
  hasBuildingLimitPer15Minutes,
  isUpdate,
  bookingInitialSpaceId,
  meetingId,
  selectedDays,
  locale,
}) => {
  const bookingsSuccessMessages = {
    // flag which is true if at least one reservation was successful, used for displaying the success message
    successfullyReserved: false,
    // string list of days for which reservation was successful
    successfullyReservedDaysMessage: '',
  };
  const bookingsFailedMessages = {
    // string list of days for which reservation creation failed
    failedDaysMessage: '',
    // string list of days for which reservation creation failed due to concurrent limit
    concurrentDaysMessage: '',
    // string list of days for which reservation creation failed due to concurrent limit per 15 minutes
    limitPerIntervalMessage: '',
    // string list of days for which reservation creation failed due to concurrent limit per building
    limitPerBuildingMessage: '',
  };

  if (selectedDays?.length === 0) {
    return Promise.resolve({
      ...bookingsSuccessMessages,
      ...bookingsFailedMessages,
      successfullyReserved: false,
      failedDaysString: NO_DAY_SELECTED,
      bookedDays: [],
    });
  }

  const bookedDays = await Promise.all(
    selectedDays.map(async selectedDay => {
      return await handleDayBooking({
        tenantId,
        spaceItem,
        duration,
        allDayDuration,
        filter,
        email,
        summaryText,
        reserveeId,
        localTimeZone,
        fetchBuildingQuery,
        hasBuildingLimitPer15Minutes,
        isUpdate,
        bookingInitialSpaceId,
        meetingId,
        selectedDay,
        successCallback,
        errorCallback,
        bookingsSuccessMessages,
        bookingsFailedMessages,
        locale,
      });
    }),
  );
  return Promise.resolve({
    ...bookingsSuccessMessages,
    ...bookingsFailedMessages,
    bookedDays: bookedDays.filter(Boolean), // remove all 'undefined' days
  });
};
