import styled from 'styled-components';

const activeColor = '#81DC44';

export const IntervalContainer = styled.div<{ $isBooked?: boolean }>`
  min-width: 4px;
  height: 7px;
  background-color: ${({ theme, $isBooked }) => ($isBooked ? theme.color2 : activeColor)};

  & + & {
    margin-left: 1px;
  }
`;

export const GroupWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 1px;

  & {
    .fix-size-availability-bar__segment:first-of-type {
      ${IntervalContainer}:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  & {
    .fix-size-availability-bar__segment:last-of-type {
      ${IntervalContainer}:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
`;

export const LabelContainer = styled.div`
  font-size: 10px;
  opacity: 0.5;
  text-align: center;
  margin-bottom: 5px;
`;
