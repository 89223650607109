import {
  PERSON_OF_INTEREST,
  PERSON_OF_INTEREST_TRANSLATION_MAPPING,
} from '@engage-shared/constants/personOfInterest';
import { TFunction } from '@engage-shared/utils/types';

interface PersonOfInterest {
  name: string;
  icon: 'officer-medical' | 'officer-fire';
  type: string;
}

export const getPersonOfInterestLabels = ({
  isFireWarden,
  isFirstAidOfficer,
  t,
}: {
  isFireWarden: boolean;
  isFirstAidOfficer: boolean;
  t: TFunction<any>;
}): PersonOfInterest[] => {
  const personOfInterestLabels: PersonOfInterest[] = [];

  if (isFirstAidOfficer) {
    personOfInterestLabels.push({
      name: t(PERSON_OF_INTEREST_TRANSLATION_MAPPING[PERSON_OF_INTEREST.MEDICAL_OFFICER]),
      icon: 'officer-medical',
      type: PERSON_OF_INTEREST.MEDICAL_OFFICER,
    });
  }

  if (isFireWarden) {
    personOfInterestLabels.push({
      name: t(PERSON_OF_INTEREST_TRANSLATION_MAPPING[PERSON_OF_INTEREST.FIRE_OFFICER]),
      icon: 'officer-fire',
      type: PERSON_OF_INTEREST.FIRE_OFFICER,
    });
  }

  return personOfInterestLabels;
};
