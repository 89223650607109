import { Api, getApiUrl } from '@engage-shared/api/instance';
import { LanguageTypes } from '@engage-shared/constants/languages';
import {
  FetchSpaceAttributesParams,
  SpaceAttributesData,
} from '@engage-shared/api/spaces/interfaces';
import { sortAttributesBySequenceAndId } from '@engage-shared/api/spaces/utils';

type FetchSpaceAttributes = (params: FetchSpaceAttributesParams) => Promise<SpaceAttributesData>;

/**
 * Retrieves space attributes.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | found space attributes |
 * | 404 | space not found |
 * @param {number} id Space id
 * @param {string} [locale] User locale
 */
export const fetchSpaceAttributes: FetchSpaceAttributes = async ({ id, locale }) => {
  const url = new URL(`${getApiUrl()}/spaces/${id}/space_attributes`);
  url.searchParams.set('locale', locale || LanguageTypes.enUS);
  const response = await Api.get(url.toString());
  const attributes = response.data?.data;
  if (attributes) {
    attributes.sort(sortAttributesBySequenceAndId);
  }
  return attributes;
};
