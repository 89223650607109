import styled from 'styled-components';

export const DEFAULT_SIZE = 40;

export const ProgressText = styled.span<{ color: string }>`
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  color: ${({ color }) => color};
  opacity: 0.5;
`;

export const Wrapper = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;
