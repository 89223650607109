import { SENSOR_STATUS } from '@engage-shared/constants/space';
import { Nullable } from '@engage-shared/utils/types';

interface PresentStatusForPersonGetter {
  demoSensorStatus?: Nullable<SENSOR_STATUS>;
  sensorStatus: Nullable<SENSOR_STATUS>;
  isTimeFilterSetToNow: boolean;
  isDeskReserved: boolean;
}

export const getPresenceSensorStatusForPerson = ({
  demoSensorStatus = null,
  sensorStatus,
  isTimeFilterSetToNow,
  isDeskReserved,
}: PresentStatusForPersonGetter): Nullable<SENSOR_STATUS> => {
  const statusFromAPI = demoSensorStatus || sensorStatus;
  if (!statusFromAPI) {
    return null;
  }
  if (!isTimeFilterSetToNow || !isDeskReserved) {
    return null;
  }
  return statusFromAPI;
};
