import {
  EngageOptOutData,
  OptOutQueryOptions,
  PersonId,
  SetOptOutMutationOptions,
} from '@engage-shared/api/people/interfaces';
import { PERSON_OPT_OUT_QUERY_KEY } from '@engage-shared/api/people/constants';
import { usePersonOptOutQuery } from '@engage-shared/api/people/queries/usePersonOptOutQuery';
import { useQueryClient } from 'react-query';
import { useOptOutStatus } from '@engage-shared/api/people/hooks/useSetOptOutStatus';
import { TenantId } from '@engage-shared/api/tenant/interfaces';

type UseOptOutParams = {
  tenantId: TenantId;
  personId: PersonId;
  isDebouncedApiCall?: boolean;
  setOptOutOptions?: SetOptOutMutationOptions;
  fetchOptOutOptions?: OptOutQueryOptions;
};

type UseOptOut = (params: UseOptOutParams) => {
  setOptOutChange: (engageOptOut: boolean) => void;
  isPersonOptOut: boolean | undefined;
  isPersonOptOutLoading: boolean;
};

export const useOptOut: UseOptOut = ({
  tenantId,
  personId,
  isDebouncedApiCall = true,
  setOptOutOptions = {},
  fetchOptOutOptions = {},
}: UseOptOutParams) => {
  const queryClient = useQueryClient();
  const { data, isLoading: isPersonLoading } = usePersonOptOutQuery({
    tenantId,
    personId,
    options: fetchOptOutOptions,
  });
  const { mutateOptOutStatus } = useOptOutStatus({
    tenantId,
    isDebouncedApiCall,
    onMutate: async optOutStatus => {
      const previousOptOutStatus =
        queryClient.getQueryData<EngageOptOutData>(PERSON_OPT_OUT_QUERY_KEY);

      queryClient.setQueryData<EngageOptOutData>(PERSON_OPT_OUT_QUERY_KEY, prevData => ({
        ...prevData,
        engageOptOut: optOutStatus.engageOptOut,
      }));

      return { previousOptOutStatus };
    },
    onError: async (_error, _variables, ctx) => {
      queryClient.setQueryData(PERSON_OPT_OUT_QUERY_KEY, ctx?.previousOptOutStatus);
    },
    ...setOptOutOptions,
  });

  const previousOptOutStatus = queryClient.getQueryData<EngageOptOutData>(PERSON_OPT_OUT_QUERY_KEY);

  return {
    setOptOutChange: mutateOptOutStatus,
    isPersonOptOut: previousOptOutStatus?.engageOptOut ?? data?.engageOptOut,
    isPersonOptOutLoading: isPersonLoading,
  };
};
