import styled from 'styled-components';

export const Wrapper = styled.div<{ $isErrorFloorplan: boolean }>`
  width: 100%;
  margin: ${({ $isErrorFloorplan }) => ($isErrorFloorplan ? '0 0 40px 0' : '100px 0')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Children = styled.div`
  margin: 10px 20px 0;
  align-items: center;
`;
