import { Coordinate, Space } from '@floorplan/api';

export const svLivePersonLocationToShape = (personLocation: Coordinate): Space | null => {
  if (!personLocation) return null;

  return {
    shape: { isPolygon: false, coordinates: [personLocation] },
    shapes: [{ coordinates: [personLocation] }],
  } as Space;
};
