import { BookingFilter } from '../../../api/bookings';
import {
  getReservationEndDate,
  getReservationStartDate,
  getUtcIsoString,
} from '../../../utils/dates';
import { ISODateString } from '../../../utils/types';

type GetReservationDatesFromFilterProps = {
  filter: BookingFilter;
  timeZone: string;
  selectedDay?: Date;
  duration?: number;
  allDayDuration?: boolean;
  showDaysSelector: boolean;
  showDurationSelection: boolean;
};
type GetReservationDatesFromFilter = (props: GetReservationDatesFromFilterProps) => {
  reservationStartDate: ISODateString;
  reservationEndDate: ISODateString;
};

export const getReservationDatesFromFilter: GetReservationDatesFromFilter = ({
  filter,
  timeZone,
  selectedDay,
  duration,
  allDayDuration,
  showDaysSelector,
  showDurationSelection,
}) => {
  const { dateStart, dateEnd, allDay, bookingIntervalSize } = filter;

  const dateS = getReservationStartDate({
    selectedDay,
    dateStart,
    bookingIntervalSize,
    allDay: allDay || allDayDuration,
    timeZone,
  });

  const dateE = getReservationEndDate({
    selectedDay,
    dateStart: dateS,
    dateEnd,
    allDay: allDay || allDayDuration,
    duration,
    bookingIntervalSize,
    showDaysSelector,
    showDurationSelection,
    timeZone,
  });

  const reservationStartDate = getUtcIsoString(dateS);
  const reservationEndDate = getUtcIsoString(dateE);

  return { reservationStartDate, reservationEndDate };
};
