import React, { memo } from 'react';
import { isToday } from 'date-fns';
import { Day, DayOfWeek, Wrapper } from './styled';
import { DateFormat, formatLocalizedDate } from '@engage-shared/utils';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from '@engage-web/store';

interface AgendaDayProps {
  day: Date;
}
const AgendaDay = ({ day }: AgendaDayProps) => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const dayDate = day.getDate();
  const dayFormat = formatLocalizedDate(day, {
    locale,
    format: DateFormat.weekDayShort,
  });
  const _isToday = isToday(day);

  return (
    <Wrapper>
      <Day $highlight={_isToday} aria-current={_isToday ? 'date' : undefined}>
        {dayDate}
      </Day>
      <DayOfWeek $highlight={_isToday}>{dayFormat}</DayOfWeek>
    </Wrapper>
  );
};

export default memo(AgendaDay);
