import React, { useMemo, useState } from 'react';
import { KioskRemoteConfig } from '@engage-web/api/queries';
import {
  groupConfigsByBuildingAndFloor,
  KioskLocationMetadata,
} from '@engage-web/components/kiosk/AvailableKiosks/utils';
import {
  BuildingName,
  BuildingsListWrapper,
  BuildingWrapper,
} from '@engage-web/components/layouts/LocationSelector/components/styled';
import { Nullable } from '@engage-shared/utils/types';
import AvailableKioskItems from '@engage-web/components/kiosk/AvailableKiosks/AvailableKioskFloors/AvailableKioskItems';

type AvailableKioskFloorsProps = {
  data: KioskRemoteConfig[];
};

const AvailableKioskFloors = ({ data }: AvailableKioskFloorsProps) => {
  const [openedId, setOpenedId] = useState<Nullable<number>>(null);

  const floors = useMemo(
    () =>
      groupConfigsByBuildingAndFloor(data, ({ floorId, floorName }) => ({
        id: floorId,
        name: floorName,
      })),
    [data],
  );

  const renderFloor = ({ id, name, kioskOriginalData }: KioskLocationMetadata) => {
    const isOpened = id === openedId;

    return (
      <BuildingWrapper key={`${id}-${name}`}>
        <BuildingName
          data-testid="floor-list-item"
          $isActive={isOpened}
          onClick={() => setOpenedId(isOpened ? null : id)}
        >
          {name}
        </BuildingName>
        {isOpened && <AvailableKioskItems data={kioskOriginalData} />}
      </BuildingWrapper>
    );
  };

  return <BuildingsListWrapper>{floors.map(renderFloor)}</BuildingsListWrapper>;
};

export default AvailableKioskFloors;
