import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { globalSearchSelectors } from '@engage-web/store/globalSearch';
import { TFunction, useTranslation } from 'react-i18next';
import { RESTRICT_TO_VALUES } from '@engage-shared/constants';
import { GlobalSearchNoData as StyledContainer } from './styled';
import { useRestrictTo } from '@engage-web/utils';
import { Nullable } from '@engage-shared/utils/types';

type GlobalSearchNoDataProps = {
  htmlId: string;
};
type GetListEmptyTextProps = {
  searchTerm?: string;
  t: TFunction;
  restrictTo?: Nullable<'Desk' | 'Space' | 'Person' | 'Team'>;
};
type GetListEmptyText = (props: GetListEmptyTextProps) => string;

const getListEmptyText: GetListEmptyText = ({ searchTerm, t, restrictTo }) =>
  searchTerm ? getNoSearchedResultsText(t, restrictTo) : getNoResultsText(t, restrictTo);

type getTextFnProps = (
  t: TFunction,
  restrictTo?: Nullable<'Desk' | 'Space' | 'Person' | 'Team'>,
) => string;

const getNoSearchedResultsText: getTextFnProps = (t, restrictTo) => {
  const genericText = t('layout.globalSearch.noResultsGeneric');
  const emptyText = {
    Person: t('layout.globalSearch.noResultsPerson'),
    Space: t('layout.globalSearch.noResultsSpace'),
    Desk: t('layout.globalSearch.noResultsDesk'),
    Team: t('layout.globalSearch.noResultsTeam'),
  };

  return emptyText[restrictTo as RESTRICT_TO_VALUES] || genericText;
};

const getNoResultsText: getTextFnProps = (t, restrictTo) => {
  const genericText = t('layout.globalSearch.noResultsFound');
  const emptyText = {
    Person: t('layout.globalSearch.noPeopleFound'),
    Space: t('layout.globalSearch.noSpacesFound'),
    Desk: t('layout.globalSearch.noDesksFound'),
    Team: t('layout.globalSearch.noTeamsFound'),
  };

  return emptyText[restrictTo as RESTRICT_TO_VALUES] || genericText;
};

const GlobalSearchNoData = ({ htmlId }: GlobalSearchNoDataProps) => {
  const { t } = useTranslation();

  const searchTerm = useSelector(globalSearchSelectors.getGlobalSearchValue);
  const restrictTo = useRestrictTo();

  const listEmptySearchText = getListEmptyText({ t, restrictTo, searchTerm });

  return (
    <StyledContainer id={htmlId} aria-live="polite" aria-atomic="true" data-testid="search-no-data">
      <ul style={{ padding: 0, margin: 0 }}>
        <p role="listitem">
          {listEmptySearchText}
          {!!searchTerm && <b>{` "${searchTerm}"`}</b>}
        </p>
      </ul>
    </StyledContainer>
  );
};

export default memo(GlobalSearchNoData);
