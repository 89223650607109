import * as React from 'react';
import { FES } from './FES';

type FloorplanProviderProps = {
  fes: FES;
  children?: React.ReactNode;
};

const FESContext = React.createContext<FES | undefined>(undefined);

export const useFESContext = () => {
  const fes = React.useContext(FESContext);
  if (!fes) {
    throw new Error('useFESContext must be used within a FESContext');
  }
  return fes;
};

export const FloorplanProvider = ({ fes, children }: FloorplanProviderProps) => {
  return <FESContext.Provider value={fes}>{children}</FESContext.Provider>;
};
