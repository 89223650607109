import {
  add,
  addDays,
  addMinutes,
  getHours,
  getMilliseconds,
  getMinutes,
  getSeconds,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  parseISO,
  toDate,
} from 'date-fns';
import { DateISODate, ISODateString, Nullable, Timezone } from '../types';
import { RoundingDirection } from '../../constants';
import moment, { Moment } from 'moment-timezone';
import { getClosestNumber } from '../helpers';
import { getLogger } from '@engage-shared/utils/logger';

export const roundTimeToInterval = (
  date: Date,
  intervalSize: number = 1,
  direction?: RoundingDirection,
  timeZone?: Timezone,
): Date => {
  // use moment to apply the timezone and obtain the offset
  const momentDate = moment(date);
  if (timeZone) {
    momentDate.tz(timeZone);
  }

  const coeff = 1000 * 60 * intervalSize; //milliseconds

  const offset = momentDate.utcOffset() * 60 * 1000; //milliseconds
  const currentTimeWithOffset = momentDate.valueOf() + offset;

  switch (direction) {
    case RoundingDirection.UP:
      return new Date(Math.ceil(currentTimeWithOffset / coeff) * coeff - offset);

    case RoundingDirection.DOWN:
      return new Date(Math.floor(currentTimeWithOffset / coeff) * coeff - offset);

    default:
      return new Date(Math.round(currentTimeWithOffset / coeff) * coeff - offset);
  }
};

/**
 * @deprecated Use roundTimeToInterval instead, which takes seconds into consideration.
 */
export const roundToInterval = (
  date: Date,
  intervalSize: number = 1,
  direction?: RoundingDirection,
): Date => {
  // clone before changing it
  const roundedDate = toDate(date);

  const minutes = getMinutes(date);
  let roundTo;
  switch (direction) {
    case RoundingDirection.UP:
      roundTo = Math.ceil(minutes / intervalSize);
      break;
    case RoundingDirection.DOWN:
      roundTo = Math.floor(minutes / intervalSize);
      break;
    default:
      roundTo = Math.round(minutes / intervalSize);
      break;
  }
  const roundMinutes = roundTo * intervalSize;

  roundedDate.setMinutes(roundMinutes, 0, 0);

  return roundedDate;
};

export const checkOrParse = (date: DateISODate): Date => {
  if (date instanceof Date) {
    return date;
  }
  // parsed date in the local time zone
  return parseISO(date);
};

/**
 * Get date's ISO string. If no date date or invalid date is provided, it returns the current date's ISO string.
 * @param date Date object
 * @return string
 */
export const getUtcIsoString = (date: Date | null): ISODateString =>
  date && isValid(date)
    ? (date.toISOString() as ISODateString)
    : (new Date().toISOString() as ISODateString);
/**
 * Get date's ISO string. If no date date or invalid date is provided, it returns null.
 * @param date Date object
 * @return String or null
 */
export const getISODate = (date: Date): string | null => {
  if (date && isValid(date)) {
    return date.toISOString();
  }

  return null;
};

/**
 * Get the number of milliseconds since the Unix Epoch.
 * @param date
 * @return number of milliseconds or NaN
 */
export const getTime = (date: DateISODate): number => checkOrParse(date).getTime();

/**
 * Check if date is in the current interval, meaning 'Now' for timeline.
 */
export const isTimeNow = (date?: Nullable<Date>): boolean =>
  !date ||
  isWithinInterval(date, {
    start: roundTimeToInterval(new Date(), 15, RoundingDirection.DOWN),
    end: roundTimeToInterval(new Date(), 15, RoundingDirection.UP),
  });

export const copyTimeFromDateToDate = (sourceDate: Date, destinationDate: Date): void => {
  if (isValid(sourceDate) && isValid(destinationDate)) {
    destinationDate.setHours(
      getHours(sourceDate),
      getMinutes(sourceDate),
      getSeconds(sourceDate),
      getMilliseconds(sourceDate),
    );
  }
};

export const copyTimeFromMomentToMoment = (sourceDate: Moment, destinationDate: Moment): void => {
  if (sourceDate.isValid() && destinationDate.isValid()) {
    destinationDate.hours(sourceDate.hours());
    destinationDate.minutes(sourceDate.minutes());
    destinationDate.seconds(sourceDate.seconds());
    destinationDate.milliseconds(sourceDate.milliseconds());
  }
};

/**
 * Adds a minute to the date string in ISO format
 * @param isoDateString
 * @returns {string} new ISO string or empty string in case of invalid argument
 */
export const addMinute = (isoDateString: ISODateString): string => {
  let date = new Date(isoDateString);
  if (isValid(date)) {
    date = add(date, { minutes: 1 });
    return date.toISOString();
  }

  getLogger().warn(`Invalid argument for addMinute:  ${isoDateString}`);
  return '';
};

/**
 * Always round up to closest minute from array.
 * @param date
 * @param minutesArr
 */
export const getClosestTime = (date: Date, minutesArr: number[]): Date => {
  const roundedDate = new Date(date.setSeconds(0, 0));
  let outTime = new Date(roundedDate.getTime());
  const closestMinutes = getClosestNumber(getMinutes(outTime), minutesArr);
  if (isBefore(addMinutes(outTime, closestMinutes - getMinutes(outTime)), outTime)) {
    // assuming we always start from 0, use next step to add extra minutes
    // this is in case steps change in the future (ex. step = 10min)
    outTime = addMinutes(outTime, closestMinutes + minutesArr[1] - getMinutes(outTime));
  } else {
    outTime = addMinutes(outTime, closestMinutes - getMinutes(outTime));
  }
  return outTime;
};

/**
 * Filter out from array the dates which are not in the week that starts with firstDay.
 * @param datesArray
 * @param firstDay
 */
export const filterDateArrayByWeek = ({
  datesArray,
  firstDay,
}: {
  datesArray: Date[];
  firstDay: Date;
}): Date[] => {
  if (firstDay && isValid(firstDay) && datesArray) {
    const lastDayDisplayed = addDays(firstDay, 6);
    return datesArray.filter(selectedDay => {
      return (
        !isBefore(toDate(selectedDay), firstDay) && !isAfter(toDate(selectedDay), lastDayDisplayed)
      );
    });
  }
  getLogger().warn(`Invalid input for filterDateArrayByWeek: `, firstDay, datesArray);
  return datesArray;
};
