import React, { CSSProperties, FC, memo, MemoExoticComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { humanizeNumber, replaceLineFeedWithSpace } from '@engage-web/utils';
import { fontClassesHeights, fontTagHeights } from '@engage-web/styles';
import { useTheme } from 'styled-components';
import { SearchResponseData } from '@engage-shared/api/search';
import { Theme } from '@engage-shared/theme';
import AvatarImage, { AvatarImageProps } from '@engage-web/components/base/Avatar/AvatarImage';
import AvatarBadge from '@engage-web/components/base/Avatar/AvatarBadge';
import { PersonLocation } from '@engage-shared/api';
import {
  LIST_ITEM_PADDING_VERTICAL,
  ListItemOuterWrapper,
  ListItemWrapper,
  Subtitle,
  SubtitleWrapper,
  TextWrapper,
  Title,
} from './styled';
import SizeRecognitionWrapper from './SizeRecognitionWrapper';
import { getBadgeA11yLabel } from './utils';

export type DeskItemProps = {
  item: SearchResponseData | PersonLocation;
  overrideText?: {
    title: string;
    subtitle: string;
  };
  onPress?: (item: SearchResponseData | PersonLocation) => void;
  showBadge?: boolean;
  style?: CSSProperties;
  onSizeFound?: (size: DOMRect, index: number) => void;
  index: number;
  as?: 'div';
  renderAvatar?: (avatarProps: AvatarImageProps) => ReactNode;
};

const isSearchResponse = (item: SearchResponseData | PersonLocation): item is SearchResponseData =>
  (item as SearchResponseData).name !== undefined;

const DeskItem = ({
  item,
  overrideText,
  onPress,
  showBadge = true,
  style,
  onSizeFound,
  index,
  as,
  renderAvatar,
}: DeskItemProps) => {
  const { t } = useTranslation();
  const { status2, status3 } = useTheme() as Theme;
  const level = item?.location?.level;

  const floorName = level ? `${humanizeNumber(level, t)} ${t('common.floor')}` : '';

  let name = '';
  let description = '';
  let available;

  if (isSearchResponse(item)) {
    name = item.name;
    description = item.description;
    available = !!item.available;
  }

  let title = replaceLineFeedWithSpace(name);
  let subtitle = description;

  const badgeVariant = showBadge ? 'dot' : 'standard';
  const badgeStyle = { backgroundColor: available ? status2 : status3 };
  const badgeA11yLabel = showBadge ? getBadgeA11yLabel({ t, available }) : '';

  if (overrideText) {
    title = overrideText.title;
    subtitle = overrideText.subtitle;
  }

  const avatarProps: AvatarImageProps = {
    icon: 'desk',
    size: 'small',
    style: { background: 'transparent' },
  };

  const onItemPress = () => onPress?.(item);
  return (
    <ListItemOuterWrapper {...(!!as && { as })} $style={style}>
      <SizeRecognitionWrapper onSizeFound={onSizeFound} index={index}>
        <ListItemWrapper
          aria-label={`${t('accessibilityLabels.viewDeskItem')} ${title}`}
          onClick={onItemPress}
        >
          <AvatarBadge
            variant={badgeVariant}
            overlap="circle"
            style={badgeStyle}
            badgeA11yLabel={badgeA11yLabel}
            size="small"
          >
            {renderAvatar ? renderAvatar(avatarProps) : <AvatarImage {...avatarProps} />}
          </AvatarBadge>
          <TextWrapper>
            <Title>{title}</Title>
            <SubtitleWrapper>
              <Subtitle className="btn Subtitle">
                {floorName}
                <span>{subtitle}</span>
              </Subtitle>
            </SubtitleWrapper>
          </TextWrapper>
        </ListItemWrapper>
      </SizeRecognitionWrapper>
    </ListItemOuterWrapper>
  );
};

const MemoDeskItem = memo(DeskItem) as MemoExoticComponent<FC<DeskItemProps>> & {
  getSize: () => number;
};

MemoDeskItem.getSize = () =>
  LIST_ITEM_PADDING_VERTICAL * 2 +
  // @ts-ignore
  fontTagHeights.h6.withMargins +
  // @ts-ignore
  fontClassesHeights.btn.withMargins;

export default MemoDeskItem;
