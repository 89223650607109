import { RESTRICT_TO_VALUES } from '@engage-shared/constants/restrictToValues';
import { FavouriteType } from '../interfaces';

type SpaceTypes = 'Desk' | 'Space' | 'Person' | 'Team';

const MapRestrictToFavouriteType: Record<string, FavouriteType> = {
  [RESTRICT_TO_VALUES.DESK]: FavouriteType.desk,
  [RESTRICT_TO_VALUES.TEAM]: FavouriteType.team,
  [RESTRICT_TO_VALUES.PERSON]: FavouriteType.person,
  [RESTRICT_TO_VALUES.SPACE]: FavouriteType.space,
};

export const mapRestrictToFavouriteType = (restrictTo: SpaceTypes | null): FavouriteType =>
  restrictTo ? MapRestrictToFavouriteType[restrictTo] : FavouriteType.space;
