import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getDayEndTime, hashString } from '@engage-shared/utils';
import { Acknowledgement } from '../../components/layouts';
import Analytics from '../../utils/analytics';
import { ROOT_PATH } from '../../constants';
import { useAppLocation } from '../../router/hooks';
import { useSetUserConfig } from '@engage-shared/api';

interface OnAcceptOrganizationPolicyParams {
  acknowledgementUrl: string;
  acknowledgementMessage: string;
  acknowledgementTitle: string;
}

export const AcknowledgementRouter = () => {
  const navigate = useNavigate();
  const { search } = useAppLocation();
  const { setUserConfig } = useSetUserConfig({
    onSettled: () => {
      navigate({
        pathname: ROOT_PATH,
        search,
      });
    },
  });

  const onAcceptOrganizationPolicy = ({
    acknowledgementMessage,
    acknowledgementUrl,
    acknowledgementTitle,
  }: OnAcceptOrganizationPolicyParams) => {
    setUserConfig({
      acknowledgementContentHash: hashString(
        JSON.stringify({
          acknowledgementMessage,
          acknowledgementUrl,
          acknowledgementTitle,
        }),
      ),
      acknowledgementConfirmedAt: getDayEndTime({
        date: new Date(),
      }).toString(),
    });

    Analytics.track('EVT-085');
  };

  return <Acknowledgement onAccept={onAcceptOrganizationPolicy} />;
};
