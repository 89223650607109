import { getLatestPresenceEvent } from '@engage-shared/utils/sv-live/getLatestPresenceEvent';
import { PresenceStatus } from '@engage-shared/constants/person';
import { getSVLivePresenceStatus } from '@engage-shared/utils/sv-live/getSVLivePresenceStatus';
import { Nullable } from '@engage-shared/utils/types';
import { PersonData } from '@engage-shared/api/people/interfaces';
import { SpaceItem } from '@engage-shared/api/spaces';
import { BaseSVLivePresence } from '@engage-shared/api/people/interfaces';

type GetSVLiveSpaceOccupiedByPresenceStatus = (
  presenceEvents: BaseSVLivePresence[],
  presentEventNoOlderThanInMinutes?: number,
) => {
  isOccupied: boolean;
  isRemote: boolean;
  presenceEvent: Nullable<BaseSVLivePresence>;
};

type GetSpaceLiveSpaceOccupiedByPresenceStatus = (
  space: SpaceItem,
  presentEventNoOlderThanInMinutes?: number,
) => {
  isOccupied: boolean;
  isRemote: boolean;
  presenceEvent: Nullable<BaseSVLivePresence>;
};

/**
 * Returns presence event if not older than in 60 minutes (default) and event status logic.
 * @param presenceEvents list of presence events
 * @param presentEventNotOlderThanInMinutes
 * @returns {{isOccupied:boolean, isRemote: boolean, presenceEvent:<Nullable<BaseSVLivePresence>>}}
 */
const getSVLivePresenceEventOccupancy: GetSVLiveSpaceOccupiedByPresenceStatus = (
  presenceEvents,
  presentEventNotOlderThanInMinutes = 60,
): {
  isOccupied: boolean;
  isRemote: boolean;
  presenceEvent: Nullable<BaseSVLivePresence>;
} => {
  let isOccupied = false;
  let isRemote = false;
  let presenceEvent = null;
  const latestPresenceEvent = getLatestPresenceEvent(presenceEvents);

  if (latestPresenceEvent) {
    const presenceStatus = getSVLivePresenceStatus(
      latestPresenceEvent,
      presentEventNotOlderThanInMinutes,
    );
    if (presenceStatus) {
      isOccupied =
        presenceStatus === PresenceStatus.ACTIVE || presenceStatus === PresenceStatus.AWAY;
      isRemote = presenceStatus === PresenceStatus.REMOTE;
      presenceEvent = latestPresenceEvent;
    }
  }
  return { isOccupied, isRemote, presenceEvent };
};

/**
 * Returns space presence event if not older than in 60 minutes (default) and event status logic.
 * @param space
 * @param presentEventNotOlderThanInMinutes
 */
export const getSpaceSVLivePresenceEventOccupancy: GetSpaceLiveSpaceOccupiedByPresenceStatus = (
  space,
  presentEventNotOlderThanInMinutes = 60,
) => {
  const presenceEvents = space?.presenceEvents || [];
  return getSVLivePresenceEventOccupancy(presenceEvents, presentEventNotOlderThanInMinutes);
};

type GetPersonLiveSpaceOccupiedByPresenceStatus = (
  person: PersonData,
  presentEventNoOlderThanInMinutes?: number,
) => {
  isOccupied: boolean;
  isRemote: boolean;
  presenceEvent: Nullable<BaseSVLivePresence>;
};
/**
 * Returns person presence event if not older than in 60 minutes (default) and event status logic.
 * @param person
 * @param presentEventNotOlderThanInMinutes
 */
export const getPersonSVLivePresenceEventOccupancy: GetPersonLiveSpaceOccupiedByPresenceStatus = (
  person,
  presentEventNotOlderThanInMinutes = 60,
) => {
  const presenceEvents = person?.lastSeenLocations || [];

  return getSVLivePresenceEventOccupancy(presenceEvents, presentEventNotOlderThanInMinutes);
};
