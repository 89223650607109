import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailability } from '@engage-web/api/queries/useAvailability';
import { useTheme } from 'styled-components';
import { Icon } from '../../Icons/Icon';
import { InfoTextWrap, InfoText } from './styled';
import AvailabilityInfoSkeleton from './AvailabilityInfoSkeleton';
import { getUtcIsoString } from '@engage-shared/utils';
import { Theme } from '@engage-shared/theme';

type AvailabilityInfoProps = { dateStart: Date };

const AvailabilityInfo = ({ dateStart }: AvailabilityInfoProps) => {
  const availabilityQuery = useAvailability({
    startTime: getUtcIsoString(dateStart),
  });

  const { typo2 } = useTheme() as Theme;
  const { t } = useTranslation();
  const reservationsLeft = t('scenes.dateTimePicker.reservationsLeft');
  const { existingReservations, allowedReservations } = availabilityQuery.data || {
    existingReservations: 0,
    allowedReservations: 0,
  };
  const availableReservations =
    allowedReservations - existingReservations > 0 ? allowedReservations - existingReservations : 0;

  const renderAvailabilityText = () => {
    if (availabilityQuery.isSuccess) {
      return (
        <>
          {allowedReservations > 0 && (
            <InfoTextWrap>
              <Icon name="question" size={26} style={{ color: typo2 }} />
              <InfoText>
                {availableReservations} {reservationsLeft}
              </InfoText>
            </InfoTextWrap>
          )}
        </>
      );
    }

    if (availabilityQuery.isLoading) {
      return <AvailabilityInfoSkeleton />;
    }

    return null;
  };
  return renderAvailabilityText();
};

export default AvailabilityInfo;
