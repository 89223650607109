import { assembleReservation } from '@engage-shared/utils/bookings';
import { ComposeBookingParams, Reservation } from '@engage-shared/api/bookings/interfaces';

type ComposeBooking = (params: ComposeBookingParams) => Promise<Reservation>;
export const composeBooking: ComposeBooking = async ({
  spaceItem,
  duration,
  allDayDuration,
  filter,
  localTimeZone,
  email,
  summaryText,
  reserveeId,
  isUpdate,
  bookingInitialSpaceId,
  fetchBuildingQuery,
  hasBuildingLimitPer15Minutes,
  selectedDay,
}) => {
  const { id: spaceId, buildingId, isDesk, showDaysSelector, showDurationSelection } = spaceItem;
  const { dateStart, dateEnd, allDay, bookingIntervalSize, enforceAllDayDeskReservations } = filter;
  const building = await fetchBuildingQuery(buildingId);
  let hasLimitPer15Minutes = false;
  if (isDesk && dateStart) {
    hasLimitPer15Minutes = await hasBuildingLimitPer15Minutes({
      buildingId,
      dateStart,
    });
  }
  const timeZone = building?.timeZone || localTimeZone;
  const allDayValue = allDay || (isDesk && enforceAllDayDeskReservations) || allDayDuration;
  return assembleReservation({
    // required
    spaceId,
    isDesk,
    showDaysSelector,
    showDurationSelection,
    allDay: allDayValue,
    duration,
    bookingIntervalSize,
    hasLimitPer15Minutes,
    timeZone,
    isUpdate,
    // optional
    selectedDay,
    dateStart,
    dateEnd,
    email,
    reserveeId,
    summary: summaryText,
    bookingInitialSpaceId,
  });
};
