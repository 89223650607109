/**
 * Check-in statuses:
 * 'none' : unknown or before listing (listing occurs 2 hours before check-in open) or check-in is no longer enabled (config enableDeskCheckIn false)
 * 'beforeCheckIn': check-in window is going to open within the next two hours
 * 'checkInOpen': check-in window is open
 * 'checkedIn': desk has been successfully checked-in
 * 'checkInClosed': desk check-in window has closed and the user did not check-in
 */

export enum CHECK_IN_STATUS {
  NONE = 'none',
  BEFORE_CHECK_IN = 'beforeCheckIn',
  CHECK_IN_OPEN = 'checkInOpen',
  CHECKED_IN = 'checkedIn',
  CHECK_IN_CLOSED = 'checkInClosed',
}
