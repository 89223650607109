import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetTenant } from '@engage-web/api/mutations/useSetTenant';
import { useLogout } from '@engage-web/api/mutations/useLogout';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppVersion, Icon, Mask } from '@engage-web/components/base';
import { useLongPress } from 'use-long-press';
import {
  navigationActions,
  tenantActions,
  tenantSelectors,
  uiActions,
  uiSelectors,
} from '@engage-web/store';
import {
  DATE_TIME_DEEP_LINK_PARAMS,
  DRAWER_DEEP_LINK_PARAMS,
  LOGIN_PATH,
  MODAL_DEEP_LINK_PARAMS,
  SEARCH_QUERY,
  WORKSPACE_PATH,
} from '@engage-web/constants';
import Analytics from '@engage-web/utils/analytics';
import { useBypassSSO, useDialog, useSetPageTitle } from '@engage-web/utils';
import { useAppLocation } from '@engage-web/router/hooks';
import { hasValidParamInSearchQuery } from '@engage-web/router/utils';
import { AppAccessRestrictedDialog } from '@engage-web/utils/hooks/useAppAccess';
import { TenantInput } from './TenantInput/TenantInput';
import {
  Actions,
  ButtonContents,
  Error,
  Form,
  IconContainer,
  StyledButton,
  SubTitle,
  Title,
  TitleContainer,
  Wrapper,
} from './styled';
import { PastTenants } from './PastTenants/PastTenants';
import { Logo } from '@engage-web/components/base/Logo';

interface MutationError {
  httpStatus: number;
}

interface ErrorMessageParams {
  error: MutationError;
  tenantId: string;
  t: TFunction;
}

const getErrorMessage = ({ error, tenantId, t }: ErrorMessageParams): string => {
  const status = error.httpStatus;
  const message = t('scenes.tenant.errors.forbidden', { tenantId });
  const defaultErrorMessage = t('scenes.tenant.errors.default');

  return status === 403 ? message : defaultErrorMessage;
};

const SSO_OVERRIDE = 5000; /* SSO Override press and hold time */

interface ErrorMessages {
  REQUIRED: string;
  NOT_VALID: string;
}

interface IsWorkspaceValidReturn {
  success: boolean;
  error: string | null;
}

const isWorkspaceValid = (
  tenantId: string,
  errorMessages: ErrorMessages,
): IsWorkspaceValidReturn => {
  const workspaceRe = /^[a-zA-Z0-9\\.-]*$/;
  const workspace = tenantId.trim();

  let success = true;
  let error: string | null = null;

  if (!workspace) {
    success = false;
    error = errorMessages.REQUIRED;
    return { success, error };
  }

  if (!workspaceRe.test(workspace)) {
    success = false;
    error = errorMessages.NOT_VALID;
    return { success, error };
  }

  return { success, error };
};

const Tenant = () => {
  const { activateBypassSSO } = useBypassSSO();
  const { search } = useAppLocation();
  const tenantId = new URLSearchParams(search).get(SEARCH_QUERY.WORKSPACE);

  const [tenantValue, setTenantValue] = React.useState<string>(tenantId ?? '');
  const navigate = useNavigate();
  const currentTenant = useSelector(tenantSelectors.getTenantId);
  const appAccessDialogVisible = useSelector(uiSelectors.getIsAppAccessDialogVisible);

  const [error, setError] = React.useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const { mutate: logout, isLoading: isLogoutLoading } = useLogout({
    onError: () => {
      // Anytime an error occurs on logout we need to clear existing tenant data.
      // This handles case when a user that is not authenticated and revokeTokens
      // is called (user navigates back  using the browser navigation from the login page).
      dispatch(tenantActions.clear());
    },
  });

  const workspaceErrorMessages = {
    REQUIRED: t('scenes.tenant.errors.workspaceRequired'),
    NOT_VALID: t('scenes.tenant.errors.workspaceNotValid'),
  };

  const {
    mutate: doSetTenant,
    isLoading: isSetTenantLoading,
    isError: isSetTenantError,
  } = useSetTenant({
    onSuccess: () => {
      const hasDeepLink = hasValidParamInSearchQuery(search, {
        ...DRAWER_DEEP_LINK_PARAMS,
        ...MODAL_DEEP_LINK_PARAMS,
        ...DATE_TIME_DEEP_LINK_PARAMS,
      });

      if (hasDeepLink) {
        dispatch(navigationActions.setDeepLinkQueryString(search));
      }

      navigate({
        pathname: LOGIN_PATH,
        search,
      });
    },
    onError: (error: MutationError, tenantId: string) => {
      const errorMessage = getErrorMessage({
        error,
        tenantId,
        t,
      });
      setError(errorMessage);
    },
  });

  const hasTenantChanged = Boolean(currentTenant && tenantId && currentTenant !== tenantId);

  const hasWorkspacePathInstance = Boolean(tenantId && !isSetTenantError);

  useEffect(() => {
    if (hasWorkspacePathInstance && !!tenantId) {
      doSetTenant(tenantId);
    }
  }, [hasWorkspacePathInstance, tenantId, doSetTenant]);

  useEffect(() => {
    if (hasTenantChanged) {
      dispatch(tenantActions.setNextTenantId({ nextTenantId: tenantId }));
      logout({ tenantId: currentTenant });
    }
  }, [currentTenant, dispatch, hasTenantChanged, logout, tenantId]);

  useEffect(() => {
    if (appAccessDialogVisible) {
      openDialog(<AppAccessRestrictedDialog />);
      dispatch(uiActions.hideAppAccessDialog());
    }
  }, [appAccessDialogVisible, dispatch, openDialog]);

  useSetPageTitle(t('documentTitles.tenantLogin'));

  const onInputChange = (evt: React.FormEvent<HTMLInputElement>) => {
    const value = evt.currentTarget.value;
    if (error) {
      setError('');
    }

    if (tenantId) {
      navigate(WORKSPACE_PATH, { replace: true });
    }

    setTenantValue(value);
  };

  const onTenantClick = (evt?: React.MouseEvent<HTMLButtonElement>) => {
    if (evt && evt.preventDefault) evt.preventDefault();
    const result = isWorkspaceValid(tenantValue, workspaceErrorMessages);
    if (result.success) {
      Analytics.track('EVT-004');
      doSetTenant(tenantValue.toLowerCase());
    } else if (result.error) {
      setError(result.error);
    }
  };

  const onTenantButtonClick = (tenantId: string) => {
    Analytics.track('EVT-004');
    doSetTenant(tenantId.toLowerCase());
  };

  const longPressHandlers = useLongPress(
    () => {
      activateBypassSSO();
      Analytics.track('EVT-003');
      onTenantClick();
    },
    { threshold: SSO_OVERRIDE },
  );

  // show loading indicator until the tenant is set
  if (hasWorkspacePathInstance) {
    return <Mask isLoading />;
  }

  return (
    <>
      <Mask isLoading={isSetTenantLoading || isLogoutLoading} />
      <Wrapper>
        <button {...longPressHandlers}>
          <Logo />
        </button>
        <TitleContainer>
          <Title>{t('scenes.tenant.title')}</Title>
          <SubTitle>{t('scenes.tenant.subtitle')}</SubTitle>
        </TitleContainer>
        <Form>
          <TenantInput onChange={onInputChange} value={tenantValue} />
          <PastTenants onItemClick={onTenantButtonClick} />
          <Actions>
            <StyledButton onClick={onTenantClick} type="submit">
              <ButtonContents>
                {t('common.continue')}
                <IconContainer>
                  <Icon shouldRotateOnRtl name="disclosure" size={10} />
                </IconContainer>
              </ButtonContents>
            </StyledButton>
          </Actions>
        </Form>
        {error ? <Error data-testid="tenant-screen-error-message">{error}</Error> : null}
        <AppVersion />
      </Wrapper>
    </>
  );
};

export default Tenant;
