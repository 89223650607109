import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  margin-bottom: 10px;
  text-align: center;
  font-size: 22px;
  color: ${({ color, theme }) => color || theme.typo2};
`;

export const Subtitle = styled.span`
  margin-bottom: 30px;
  text-align: center;
  font-size: 17px;
  color: ${({ color, theme }) => color || theme.typo2};
`;
