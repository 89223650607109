import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { filtersActions, floorplanActions, teamReservationsActions } from '@engage-web/store';
import { FocusId, useModal } from '@engage-web/utils';
import { bookingDetailsButtonStatuses as STATUSES } from '@engage-web/constants';
import { Button } from '../../../Button';
import { useInvalidateFloorplanSpaces } from '@floorplan/api';
import { TeamButtonStatus } from '@engage-shared/utils';
import { Theme } from '@engage-shared/theme';

type StartCheckInProps = {
  meetingId: number;
  startDate: Date;
  endDate: Date;
  status: TeamButtonStatus;
  onPress?: () => void;
};
export function StartCheckIn({
  meetingId,
  status,
  startDate,
  endDate,
  onPress,
}: StartCheckInProps) {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { color6, color1 } = useTheme() as Theme;
  const { invalidateFloorplanSpaces } = useInvalidateFloorplanSpaces();

  const { DISPLAY, DISABLED } = STATUSES;
  const { [DISPLAY]: display, [DISABLED]: disabled } = status;

  const onStartCheckInMode = () => {
    closeModal();

    dispatch(teamReservationsActions.setTeamBookingId(meetingId));
    invalidateFloorplanSpaces();
    dispatch(floorplanActions.setFocusedSpace(null));
    dispatch(
      filtersActions.setFilterValues({
        dateStart: startDate,
        dateEnd: endDate,
      }),
    );

    onPress?.();
  };

  return display ? (
    <Button
      id={FocusId.BookingCheckInButton}
      onClick={onStartCheckInMode}
      disabled={disabled}
      opacity={disabled ? 0.4 : 1}
      text={t('common.checkIn')}
      color={color6}
      backgroundColor={color1}
    />
  ) : null;
}
