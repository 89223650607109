import { compareAsc, differenceInDays } from 'date-fns';
import moment, { Moment } from 'moment-timezone';

import { checkOrParse } from './dateHelpers';
import { LanguageTypes } from '../../constants/languages';
import { DateFormat } from './constants';
import { AnyDate, DateISODate, Nullable } from '../types';

type ParseDate = (date: AnyDate) => Date;
const parseDate: ParseDate = (date: AnyDate) => {
  const momentDate = date as Moment;
  const dateISODate = date as DateISODate;
  if (date instanceof moment) {
    return momentDate.toDate();
  }
  return checkOrParse(dateISODate);
};

interface FormatterOptions {
  /**
   * Client locale.
   * @default LanguageTypes.en
   */
  locale?: LanguageTypes;
  /**
   * Date time format options.
   */
  format?: Omit<Intl.DateTimeFormatOptions, 'timeZone' | 'hour12'>;
  /**
   * Timezone, applied only when is defined.
   * @default null
   */
  timeZone?: Nullable<string>;
  /**
   * Apply 12h format, applied only when is defined.
   */
  hour12?: Intl.DateTimeFormatOptions['hour12'];
}

type Formatter = (
  /**
   * Locale to format date.
   * @default LanguageTypes.en
   */
  locale: LanguageTypes,
  /**
   * Formatter options.
   * @type FormatterOptions
   */
  options: Omit<FormatterOptions, 'locale'>,
) => Intl.DateTimeFormat;
const formatter: Formatter = (locale = LanguageTypes.en, { format, timeZone, hour12 }) => {
  const dateTimeFormatOptions = {
    ...format,
  } as Intl.DateTimeFormatOptions;
  if (typeof timeZone !== 'undefined' && timeZone) {
    dateTimeFormatOptions.timeZone = timeZone;
  }
  if (typeof hour12 !== 'undefined') {
    dateTimeFormatOptions.hour12 = hour12;
  }
  return new Intl.DateTimeFormat(locale, dateTimeFormatOptions);
};
type FormatLocalizedDate = (
  /**
   * Date to format
   * @type AnyDate
   */
  date: AnyDate,
  /**
   * Formatter options.
   * @type FormatterOptions
   */
  options?: FormatterOptions,
) => string;
/**
 * Formats a date using DateTimeFormat options
 */
const formatLocalizedDate: FormatLocalizedDate = (date, options = {}) => {
  const { locale = LanguageTypes.en, ...otherOptions } = options;
  const dateTimeFormat = formatter(locale, otherOptions);
  return dateTimeFormat.format(parseDate(date));
};

type FormatLocalizedTime = (
  /**
   * Date to format.
   */
  date: AnyDate,
  /**
   * Date time options.
   */
  options?: FormatterOptions,
) => string;
/**
 * Returns formatted time.
 */
const formatLocalizedTime: FormatLocalizedTime = (date, options = {}) => {
  const { locale, timeZone, format = DateFormat.time2Digit } = options;
  return formatLocalizedDate(date, { locale, timeZone, format });
};

type FormatWeekDayLongWithMonthAndDay = (
  /**
   * Date to format.
   */
  date: AnyDate,
  /**
   * Optional locale and timezone.
   */
  options?: Pick<FormatterOptions, 'locale' | 'timeZone'>,
) => string;
/**
 * Return date in format long week day with month and date.
 * Optional locale and timezone can be applied.
 * Default format: 'Friday, Sep 18'
 */
const formatWeekDayLongWithMonthAndDay: FormatWeekDayLongWithMonthAndDay = (date, options = {}) => {
  const { timeZone, locale } = options;
  return formatLocalizedDate(date, {
    format: DateFormat.weekDayLongWithMonthAndDay,
    locale,
    timeZone,
  });
};

type FormatWeekdayShortWithMonthAndDay = (
  /**
   * Date to format.
   */
  date: AnyDate,
  /**
   * Optional locale and timezone.
   */
  options?: Pick<FormatterOptions, 'locale' | 'timeZone'>,
) => string;
/**
 * Returns date in format DateFormat.shortWeekDayWithMonthAndDate (Thu, Sep 17).
 * Default format: 'Fri, Sep 18'
 */
const formatWeekdayShortWithMonthAndDay: FormatWeekdayShortWithMonthAndDay = (
  date,
  options = {},
) => {
  const { timeZone, locale } = options;
  return formatLocalizedDate(date, {
    format: DateFormat.weekdayShortWithMonthAndDay,
    locale,
    timeZone,
  });
};

type FormatISODate = (
  /**
   * Date to format.
   */
  date: AnyDate,
  /**
   * Optional timezone to be applied.
   */
  options?: Pick<FormatterOptions, 'timeZone'>,
) => string;
/**
 * Returns a date format in `ISO 8601 yyyy-mm-dd` for a specific date.
 * Default format: '2020-09-17'.
 */
const formatISODate: FormatISODate = (date, options = {}) => {
  const { timeZone } = options;
  const dateDelimiter = '-';
  const mapParts: Partial<Record<Intl.DateTimeFormatPartTypes, string>> = {};
  new Intl.DateTimeFormat([], {
    ...DateFormat.YearISO,
    // apply timeZone only if defined
    ...(timeZone && { timeZone }),
  })
    .formatToParts(parseDate(date))
    .forEach(({ type, value }) => {
      mapParts[type] = value;
    });
  return `${mapParts.year}${dateDelimiter}${mapParts.month}${dateDelimiter}${mapParts.day}`;
};

type IsLocalTimeFormatH12 = (locale: LanguageTypes) => Intl.DateTimeFormatOptions['hour12'];
/**
 * Returns `true` if a locale has 12H format.
 */
const isLocalTimeFormatH12: IsLocalTimeFormatH12 = locale =>
  new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
  }).resolvedOptions().hour12;

/**
 * Returns false if dates are not consecutive and true if they are consecutive,
 * example [2021-06-28T04:00:00.000Z, 2021-06-29T04:00:00.000Z, 2021-06-30T04:00:00.000Z]  -> true;
 * [2021-06-27T04:00:00.000Z, 2021-06-29T04:00:00.000Z, 2021-06-30T04:00:00.000Z]  -> false;
 * @param sortedDates Array of sorted Date objects
 * @returns boolean
 */
const isDateRangeConsecutive = (sortedDates: Date[]): boolean => {
  for (let index = 0; index < sortedDates.length; index++) {
    const dateDifference = Math.abs(differenceInDays(sortedDates[index], sortedDates[index + 1]));
    if (dateDifference > 1) {
      return false;
    }
  }
  return true;
};

/**
 * Returns formatted selected days string,
 * @param selectedDays Array of Date objects
 * @param timeZone
 * @param locale i18n compatible locale string
 * @returns formatted selected dates string (Consecutive selected days (Wed - Tues) Apr 21 - 27;
 * Consecutive selected days with different month Jun 30 - Jul 6);
 * Non-consecutive selected days Jun 28, 29, 30;
 * Non-consecutive selected days with different month Jun 28, 29, 30, Jul 1, 2;)
 */
const formatConsecutiveSelectedDatesString = (
  selectedDays: Date[],
  timeZone: string,
  locale: LanguageTypes,
): string => {
  const sortedDates = [...selectedDays].sort(compareAsc);
  const isDatesConsecutive = isDateRangeConsecutive(sortedDates);

  if (sortedDates.length === 1) {
    return formatWeekdayShortWithMonthAndDay(sortedDates[0], {
      timeZone,
      locale,
    });
  }

  let firstPickedDateMonth = formatLocalizedDate(sortedDates[0], {
    locale,
    format: DateFormat.monthShort,
    timeZone,
  });

  const lastPickedDateMonth = formatLocalizedDate(sortedDates[sortedDates.length - 1], {
    locale,
    format: DateFormat.monthShort,
    timeZone,
  });

  if (isDatesConsecutive) {
    return `${formatLocalizedDate(sortedDates[0], {
      locale,
      format: DateFormat.monthShortWithDay,
      timeZone,
    })} - ${formatLocalizedDate(sortedDates[sortedDates.length - 1], {
      locale,
      format:
        lastPickedDateMonth !== firstPickedDateMonth
          ? DateFormat.monthShortWithDay
          : DateFormat.dayNumeric,
      timeZone,
    })}`;
  }

  return sortedDates
    .map((date: AnyDate, index: number) => {
      const currentMonth = formatLocalizedDate(date, {
        locale,
        format: DateFormat.monthShort,
        timeZone,
      });
      const switchMonths = currentMonth !== firstPickedDateMonth;
      firstPickedDateMonth = switchMonths ? currentMonth : firstPickedDateMonth;
      return `${formatLocalizedDate(date, {
        locale,
        timeZone,
        format: switchMonths || index === 0 ? DateFormat.monthShortWithDay : DateFormat.dayNumeric,
      })}`;
    })
    .join(', ');
};

export {
  formatLocalizedDate,
  isLocalTimeFormatH12,
  formatISODate,
  formatLocalizedTime,
  formatWeekDayLongWithMonthAndDay,
  formatWeekdayShortWithMonthAndDay,
  isDateRangeConsecutive,
  formatConsecutiveSelectedDatesString,
};
