import React, { memo, useEffect, useState } from 'react';
import { useLongPress } from 'use-long-press';
import { DateS, TimeS, Wrapper } from './CurrentDateTime.styles';
import { LanguageTypes } from '@engage-shared/constants';
import { formatLocalizedDate, formatLocalizedTime } from '@engage-shared/utils';

type CurrentDateTimeProps = {
  locale?: LanguageTypes;
  timeZone?: string;
  textColor?: string;
  onAdminPress: () => void;
};

const LONG_PRESS_THRESHOLD = 5000;

const CurrentDateTime = ({ locale, timeZone, textColor, onAdminPress }: CurrentDateTimeProps) => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setDateTime(new Date()), 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const dateLabel = formatLocalizedDate(dateTime, {
    format: {
      year: 'numeric',
      weekday: 'short',
      month: 'long',
      day: 'numeric',
    },
    locale,
    timeZone,
  });

  const timeLabel = formatLocalizedTime(dateTime, { locale, timeZone });

  const longPressHandlers = useLongPress(() => onAdminPress(), {
    threshold: LONG_PRESS_THRESHOLD,
  });

  return (
    <Wrapper {...longPressHandlers} data-testid="current-date-time">
      <TimeS $textColor={textColor}>{timeLabel}</TimeS>
      <DateS $textColor={textColor}>{dateLabel}</DateS>
    </Wrapper>
  );
};

export default memo(CurrentDateTime);
