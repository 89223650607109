import { PERSON_OF_INTEREST_MAPPING, PERSON_OF_INTEREST_TYPE } from './types';

export const PERSON_OF_INTEREST: PERSON_OF_INTEREST_TYPE = {
  MEDICAL_OFFICER: 'firstAidOfficer',
  FIRE_OFFICER: 'fireWarden',
};

export const PERSON_OF_INTEREST_SEARCH_FILTERS: PERSON_OF_INTEREST_MAPPING = {
  [PERSON_OF_INTEREST.MEDICAL_OFFICER]: 'isFirstAidOfficer',
  [PERSON_OF_INTEREST.FIRE_OFFICER]: 'isFireWarden',
};

export const PERSON_OF_INTEREST_TRANSLATION_MAPPING: PERSON_OF_INTEREST_MAPPING = {
  [PERSON_OF_INTEREST.MEDICAL_OFFICER]: 'layout.personFilters.medicalOfficer',
  [PERSON_OF_INTEREST.FIRE_OFFICER]: 'layout.personFilters.fireOfficer',
};

export const PERSON_OF_INTEREST_ICON_MAPPING: PERSON_OF_INTEREST_MAPPING = {
  [PERSON_OF_INTEREST.MEDICAL_OFFICER]: 'officer-medical',
  [PERSON_OF_INTEREST.FIRE_OFFICER]: 'officer-fire',
};
