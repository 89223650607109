import { Api, getApiUrl } from '@engage-shared/api/instance';
import { getTimeQueryString, getFetchEmployeeQueryString } from '@engage-shared/api/utils';
import { getClosestTime, QUARTER_HOUR_VALUES } from '@engage-shared/utils/dates';
import { addMinutes, isValid } from 'date-fns';
import {
  FetchEmployeeParams,
  FetchOptOutStatusApiParams,
  FetchPersonParams,
  OptOutStatusResponse,
  Person,
  PersonAndPresenceResponse,
  PersonData,
} from './interfaces';
import {
  parsePersonData,
  getIsOccupiedBySVLive,
  getIsRemoteBySVLive,
  getIsWiredConnection,
  getIsWifiConnection,
} from '@engage-shared/api/people/utils';
import { getLatestPresenceEvent, getSVLivePresenceStatus } from '@engage-shared/utils/sv-live';
import { fetchSpace } from '@engage-shared/api/spaces';
import { DateISODate, Nullable } from '@engage-shared/utils/types';

type FetchPerson = (param: FetchPersonParams) => Promise<Person>;

type FetchPersonAndPresence = (
  param: FetchPersonParams & {
    spaceStartTime?: DateISODate;
    spaceEndTime?: Nullable<DateISODate>;
  },
) => Promise<PersonAndPresenceResponse>;

export const fetchPersonAndPresence: FetchPersonAndPresence = async ({
  id,
  dateStart,
  dateEnd,
  searchSpaceId,
  spaceStartTime,
  spaceEndTime,
  currentFloorId,
}) => {
  const personResponse = await fetchPerson({
    id,
    dateStart,
    dateEnd,
    searchSpaceId,
    currentFloorId,
  });
  const teamId = personResponse?.primaryLocation?.teamId;
  let spaceName = '';
  let sensorStatus = null;
  // if searchSpaceId is not supplied then use the space id from the first location
  // if it exists

  const locationSpaceId = searchSpaceId ?? personResponse?.primaryLocation?.spaceId;
  if (locationSpaceId && teamId !== locationSpaceId) {
    const space = await fetchSpace({
      id: locationSpaceId,
      startTime: spaceStartTime,
      endTime: spaceEndTime,
    });
    spaceName = space?.name ?? '';
    sensorStatus = space?.sensorStatus ?? null;
  }

  const presenceEvents = personResponse?.lastSeenLocations ?? [];
  const latestPresenceEvent = getLatestPresenceEvent(presenceEvents);

  const isOccupiedBySVLive = getIsOccupiedBySVLive(latestPresenceEvent);
  const isRemoteBySVLive = getIsRemoteBySVLive(latestPresenceEvent);
  const isWiredConnection = getIsWiredConnection(latestPresenceEvent);
  const isWifiConnection = getIsWifiConnection(latestPresenceEvent);
  const presenceStatus = getSVLivePresenceStatus(latestPresenceEvent);

  const personAndPresenceResponse: PersonAndPresenceResponse = {
    ...personResponse,
    presence: presenceEvents,
    latestPresenceEvent,
    isOccupiedBySVLive,
    isRemoteBySVLive,
    isWiredConnection,
    isWifiConnection,
    presenceStatus,
    spaceName,
    sensorStatus,
  };

  return personAndPresenceResponse;
};
/**
 * Retrieves information about a person.
 *
 */
export const fetchPerson: FetchPerson = async ({
  id,
  dateStart,
  dateEnd,
  searchSpaceId,
  currentFloorId,
}) => {
  const startTime = addMinutes(
    dateStart && isValid(dateStart) ? dateStart : getClosestTime(new Date(), QUARTER_HOUR_VALUES),
    1,
  );

  const queryString = getTimeQueryString(startTime, dateEnd);
  const path = `people/${id}${queryString}`;
  const url = new URL(`${getApiUrl()}/${path}`);
  const response = await Api.get<{ data: PersonData }>(url.toString());
  return parsePersonData({
    person: response.data?.data,
    searchSpaceId,
    currentFloorId,
  });
};

type FetchEmployee = (param: FetchEmployeeParams) => Promise<PersonData[]>;
/**
 * Retrieves information about people.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | person found |
 * @param id
 * @param dateStart
 * @param dateEnd
 */
export const fetchEmployee: FetchEmployee = async ({ id, dateStart, dateEnd }) => {
  const startTime = addMinutes(
    dateStart && isValid(dateStart) ? dateStart : getClosestTime(new Date(), QUARTER_HOUR_VALUES),
    1,
  );
  const queryString = getFetchEmployeeQueryString({
    startTime,
    endTime: dateEnd,
    employeeId: id,
  });
  const path = `people/${queryString}`;
  const url = new URL(`${getApiUrl()}/${path}`);
  const response = await Api.get<{ data: PersonData[] }>(url.toString());
  return response.data?.data;
};

type FetchOptOutStatus = (param: FetchOptOutStatusApiParams) => Promise<OptOutStatusResponse>;
/**
 * Retrieves opt out status of the person.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | person found |
 * | 404 | person cannot found |
 * @param tenantId
 * @param personId
 */
export const fetchOptOutStatus: FetchOptOutStatus = async ({ tenantId, personId }) => {
  const path = new URL(`${getApiUrl(tenantId)}/people/${personId}/get_opt_out_status`);
  const response = await Api.get<{ data: OptOutStatusResponse }>(path.toString());
  return response.data?.data;
};

/**
 * Checks for a valid person by performing a HEAD request to the person endpoint
 * @param personId
 */
export const fetchPersonCheck = async (personId: number): Promise<boolean> => {
  const path = new URL(`${getApiUrl()}/people/${personId}`);
  try {
    const response = await Api.get(path.toString());
    return response.status === 200;
  } catch (ex: any) {
    return false;
  }
};
