import React from 'react';
import Analytics from '../../../utils/analytics';
import { CoverVideo } from '../../base';
import { useFetchImageURL } from '../../../api/queries/useFetchImageURL';
import { CONCIERGE_MENU_IMAGE_KEY } from '../../scenes/ConciergeMenu/constants';
import {
  ConciergeItem,
  ConciergeItemDescription,
  ConciergeItemText,
  ConciergeItemTitle,
  ConciergeMenuAction,
} from '../../scenes/ConciergeMenu/styled';
import { getActionFromEngageUrl } from '@engage-shared/api';
import { IMenuItem } from '@engage-web/api/queries/menu/transformMenuItems';

// HOTFIX for https://eptura.atlassian.net/browse/SER-7110
// replace v1 api links with v2 in order to bring back Concierge menu background images
const parseBgImagePath = (path: string | undefined) => {
  if (!path) return ''; // TODO: here to make Typescript happy
  if (path?.includes('engage_api')) {
    return path.replace('v1', 'v2');
  }
  return path;
};

interface ConciergeMenuItemProps {
  isActive: boolean;
  menuItem: IMenuItem;
}
export const ConciergeMenuItem = ({ isActive, menuItem }: ConciergeMenuItemProps) => {
  const { title, description, onMenuItemClick, backgroundImage, url } = menuItem;
  const backgroundImageId = backgroundImage?.id ? `${backgroundImage.id}` : '';
  const { data: imageFileUrl } = useFetchImageURL({
    url: parseBgImagePath(backgroundImage?.path),
    queryKey: [CONCIERGE_MENU_IMAGE_KEY, backgroundImageId, ''],
  });

  const hasAction = isActive && !!onMenuItemClick;

  const handleItemClick = () => {
    Analytics.track('EVT-014', {
      title,
      menuItemUrl: url,
      menuItemAction: getActionFromEngageUrl(url).action,
    });

    onMenuItemClick?.();
  };

  return (
    <ConciergeItem data-testid="concierge-menu-item-container" $backgroundImage={imageFileUrl}>
      {isActive && <CoverVideo coverImage={imageFileUrl} />}
      <ConciergeItemText>
        <ConciergeItemTitle data-testid="concierge-menu-item-title">{title}</ConciergeItemTitle>
        <ConciergeItemDescription data-testid="concierge-menu-item-description">
          {description}
        </ConciergeItemDescription>
      </ConciergeItemText>
      {hasAction && (
        <ConciergeMenuAction data-testid="concierge-menu-item-button" onClick={handleItemClick} />
      )}
    </ConciergeItem>
  );
};
