import { Icon } from '@engage-web/components/base';
import React from 'react';
import { FocusId } from '@engage-web/utils/hooks/useFocus';
import { useTranslation } from 'react-i18next';
import { testProps, useGenerateCurrentLocationPath } from '@engage-web/utils';
import { PATH_SEGMENT, ROOT_PATH } from '@engage-web/constants';
import { useAppLocation } from '@engage-web/router/hooks';
import { joinPaths } from '@engage-web/router/utils';
import { StyledButton } from './styled';
import { DrawerHeaderProps } from './DrawerHeader';

type CloseButtonProps = { isMoreMenuVisible: boolean } & Pick<
  DrawerHeaderProps,
  'isCloseIcon' | 'closeLinkPath' | 'onPress' | 'closeIconA11yLabel'
>;

export function CloseButton({
  isCloseIcon,
  closeLinkPath,
  onPress,
  isMoreMenuVisible,
  closeIconA11yLabel,
}: CloseButtonProps) {
  const { t } = useTranslation();
  const { state, search, pathname } = useAppLocation();
  const currentLocationPath = useGenerateCurrentLocationPath() || ROOT_PATH;

  const to = isCloseIcon
    ? state?.fromPath ?? closeLinkPath ?? currentLocationPath
    : joinPaths(currentLocationPath, PATH_SEGMENT.LOCATION_SWITCHER);
  const { props: a11yAndTestProps } = testProps(
    t,
    [closeIconA11yLabel ?? '', 'accessibilityLabels.changeLocation'],
    ([closeLayout, changeLocation]) => (isCloseIcon ? closeLayout : changeLocation),
  );

  return (
    <StyledButton
      id={FocusId.DrawerCloseButton}
      to={{
        pathname: to,
        search,
      }}
      state={{
        // right now we use fromPath only on wayfinder route
        // to get back to the correct route
        // !isCloseButton because only location selector has chevron icon (>)
        fromPath: !isCloseIcon ? pathname : null,
      }}
      onClick={onPress}
      {...a11yAndTestProps}
      $withoutBackground={isMoreMenuVisible}
    >
      <Icon shouldRotateOnRtl name={isCloseIcon ? 'close' : 'chevron-right'} size={11} />
    </StyledButton>
  );
}
