import React from 'react';
import { Text } from '@engage-web/components/base/Text/Text';
import { AvatarStyle } from '@engage-web/components/base/Avatar/Avatar.types';
import { InitialsWrapper } from './styled';

export type InitialsProps = {
  children: string | number;
  style?: AvatarStyle;
  textColor?: string;
  textSize?: number;
  className?: string;
};

const Initials = ({
  children,
  style,
  textColor = '#fff',
  textSize = 12,
  className = '',
}: InitialsProps) => (
  <InitialsWrapper
    style={style}
    $textColor={textColor}
    data-testid="avatarInitials"
    className={`${className} avatar__initials`}
  >
    <Text
      // @ts-ignore
      style={{
        color: textColor,
        fontSize: textSize,
        fontWeight: 'bold',
        textAlign: 'center',
      }}
      data-testid="avatarInitialsText"
    >
      {children}
    </Text>
  </InitialsWrapper>
);

export default Initials;
