import * as React from 'react';

import { Space } from '@floorplan/api';
import { Shape } from '../../../components';
import { getKey } from '../utils';
import { basicWallStyle, extendedWallStyle } from '../styles';
import { ElementsProps } from '../../../types';
import { defaultFloorplanOptions } from '../../../constants';

interface ZonesProps extends ElementsProps {
  zones: Space[];
  zoomTilesShown?: boolean;
}
export const Zones = ({
  zones,
  scale,
  zoomTilesShown = defaultFloorplanOptions.zoomTilesShown,
  onShapeClicked,
}: ZonesProps) => {
  const styleFunction = () => {
    if (zoomTilesShown) {
      return extendedWallStyle(zoomTilesShown);
    }
    return basicWallStyle();
  };
  return (
    <>
      {zones.map((zone, index) => (
        <Shape
          space={zone}
          shape={zone.shapes[0]}
          scale={scale}
          onShapeClicked={onShapeClicked}
          styleFunction={styleFunction}
          key={getKey(zone.id, index)}
        />
      ))}
    </>
  );
};
