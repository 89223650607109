import { Nullable } from '@engage-shared/utils/types';

interface MenuAction {
  action: Nullable<string>;
  paths: string[];
}

type PersonOfInterestVisibility = (action: MenuAction) => {
  isFirstAidOfficer: boolean;
  isFireWarden: boolean;
};

// TODO: possible duplicate from mobile project

/**
 * Gets the person of interest filter settings based on the menu URL path.
 * @param {Object} action
 * @returns {{isFirstAidOfficer: boolean, isFireWarden: boolean}}
 */
export const getPersonOfInterestVisibility: PersonOfInterestVisibility = action => {
  const result = { isFireWarden: true, isFirstAidOfficer: true };
  if (action?.paths?.length) {
    const [path] = action.paths;
    if (path === 'fire_warden') {
      result.isFirstAidOfficer = false;
    }
    if (path === 'first_aid_officer') {
      result.isFireWarden = false;
    }
  }
  return result;
};
