import React from 'react';
import styled from 'styled-components';
import { Z_INDEX } from '@engage-web/constants';
import { Spinner } from '../Spinner/Spinner';

interface MaskProps {
  isLoading: boolean;
}
const Mask = ({ isLoading, ...rest }: MaskProps) => (
  <StyledMask data-testid="loading-mask" $isLoading={isLoading} {...rest}>
    <Spinner />
  </StyledMask>
);

interface MaskStyleProps {
  $isLoading: boolean;
}

const StyledMask = styled.div<MaskStyleProps>`
  display: ${props => (props.$isLoading ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: black;
  z-index: ${Z_INDEX.MASK};
`;

export { Mask };
