import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { testProps } from '@engage-web/utils';
import { Theme } from '@engage-shared/theme';
import { Icon } from '../Icons/Icon';
import { DetailsContainer, DetailsText } from './styled';

type CapacityProps = {
  capacity: number;
  iconSize?: number;
  fontSize?: number;
  opacity?: number;
};

const Capacity = ({ capacity, iconSize = 14, fontSize = 14, opacity = 1 }: CapacityProps) => {
  const { t } = useTranslation();
  const { typo2 } = useTheme() as Theme;
  const { props: a11yTestProps } = testProps(
    t,
    'accessibilityLabels.spaceCapacity',
    ([spaceCapacity]) => `${spaceCapacity} ${capacity}`,
  );

  return (
    <DetailsContainer role="img" {...a11yTestProps}>
      <Icon name="person-fill" size={iconSize} style={{ color: typo2, opacity }} />
      <DetailsText data-testid="capacity" $fontSize={fontSize} $opacity={opacity}>
        {capacity}
      </DetailsText>
    </DetailsContainer>
  );
};

export default Capacity;
