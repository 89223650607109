import { spaceActions } from '@engage-web/components/floorplan/store/actions/space';
import { SPACE_TYPE_IDS } from '@engage-web/components/floorplan/constants/SpaceTypes';
import {
  getUpdatedSpaces,
  getRefreshedSpaces,
  getFilteredSpaces,
  disableSpaces,
} from './utils/space';

const STATE_MODE = {
  DEFAULT: 'DEFAULT',
  TEAM_BOOKING_AVAILABILITY: 'TEAM_BOOKING_AVAILABILITY',
};

const initialState = {
  mode: STATE_MODE.DEFAULT,
};

export default (state = initialState, action) => {
  // prevent other async actions rewriting team booking availability state
  if (
    state.mode === STATE_MODE.TEAM_BOOKING_AVAILABILITY &&
    action.type !== spaceActions.RESET_MODE &&
    action.type !== spaceActions.SET_TEAM_BOOKING_AVAILABILITY_DATA
  ) {
    return state;
  }

  let updatedSpaces;
  const { floorId } = action;

  switch (action.type) {
    case spaceActions.SET_REFRESHED_SPACE_DATA:
      updatedSpaces = getRefreshedSpaces(state[floorId].spaces, action.payload.spaces);
      return {
        ...state,
        [floorId]: {
          spaces: updatedSpaces,
          filteredSpaces: getFilteredSpaces(updatedSpaces),
        },
      };
    case spaceActions.SET_SPACE_DATA:
      return {
        ...state,
        [floorId]: {
          spaces: action.payload.spaces,
          filteredSpaces: getFilteredSpaces(action.payload.spaces),
        },
      };
    case spaceActions.UPDATE_SPACE_DATA:
    case spaceActions.SET_TEAM_BOOKING_AVAILABILITY_DATA:
      updatedSpaces = getUpdatedSpaces(state[floorId].spaces, action.payload.spaces);
      return {
        ...state,
        [floorId]: {
          spaces: updatedSpaces,
          filteredSpaces: getFilteredSpaces(updatedSpaces),
        },
      };

    case spaceActions.SET_TEAM_BOOKING_AVAILABILITY_MODE:
      updatedSpaces = disableSpaces(state[floorId].spaces);
      return {
        ...state,
        // setting mode can be moved to a separate action if needed
        // for now since it's only applicable for one particular scenario
        // keeping it here
        mode: STATE_MODE.TEAM_BOOKING_AVAILABILITY,
        [floorId]: {
          spaces: updatedSpaces,
          filteredSpaces: getFilteredSpaces(updatedSpaces),
        },
      };

    case spaceActions.RESET_MODE:
      return {
        ...state,
        mode: STATE_MODE.DEFAULT,
      };

    case spaceActions.CLEAR_SPACE_DATA:
      return {
        ...state,
        [floorId]: {
          spaces: [],
          filteredSpaces: {
            zones: [],
            occupantPoints: [],
            nonOccupantPoints: [],
            rooms: [],
            occupantRoomPoints: [],
            special: {
              [SPACE_TYPE_IDS.TOILET_WASHROOM]: [],
              [SPACE_TYPE_IDS.LIFTS]: [],
              [SPACE_TYPE_IDS.FIRE_STAIRS]: [],
            },
          },
        },
      };
    case spaceActions.TEMPORARY_SET_SPACE_DATA:
      return {
        ...state,
        [floorId]: action.payload,
      };
    default:
      return state;
  }
};
