import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { teamReservationsSelectors } from '@engage-web/store';
import { useTeamBookingDetails } from '@engage-web/api/queries';
import { EmptyCard } from '@engage-web/components/base';
import {
  useFocusSpace,
  useSpaceMenu,
  useStartCheckIn,
  useTeamBookingStartDate,
} from '@engage-web/utils';
import useSpaceCheck from '@engage-web/components/scenes/drawer/hooks/useSpaceCheck';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useReserveRouteData } from '../Reserve/hooks/useReserveRouteData';
import { useReserveQueries } from '../Reserve/hooks/useReserveQueries';
import ReserveCardSkeleton from '../Reserve/Card/ReserveCardSkeleton';
import { useTeamBookingDetailsCallbacks, useTeamDeskCheckIn } from './hooks';
import { DeskCheckIn } from './DeskCheckIn';
import { getIsTeamBookingDeskAvailable } from '@engage-shared/utils';

export function DeskCheckInContainer() {
  const { t } = useTranslation();
  const teamBookingId = useSelector(teamReservationsSelectors.getTeamBookingId);
  const reserveRouteData = useReserveRouteData();

  const { id: spaceId, closeLinkPath, isDesk } = reserveRouteData ?? {};
  const { isLoading, spaceItem, bookingsData, spaceAttributes } = useReserveQueries(spaceId);

  const menu = useSpaceMenu({
    spaceId,
    spaceAttributes,
    isDesk,
    isFavourite: spaceItem?.isFavorite,
  });

  const teamBookingDetailsCallbacks = useTeamBookingDetailsCallbacks();

  const { data: teamBookingDetails, isLoading: isTeamBookingLoading } = useTeamBookingDetails(
    teamBookingId,
    teamBookingDetailsCallbacks,
  );

  const { checkIn, isLoading: isCheckingIn } = useTeamDeskCheckIn({
    teamBookingDetails: teamBookingDetails ?? null,
    spaceId: Number(spaceId),
  });

  const startCheckIn = useStartCheckIn(checkIn);

  const teamBookingStartDate = useTeamBookingStartDate();
  const isAvailable = getIsTeamBookingDeskAvailable(
    teamBookingId,
    Number(spaceId),
    bookingsData?.bookings,
  );

  useSpaceCheck({ id: spaceId, isDesk });
  useFocusSpace(spaceId);

  const closeIconA11yLabel = 'accessibilityLabels.closeLayout_deskCheckInContainer';

  if (isLoading || isTeamBookingLoading) {
    return (
      <>
        <DrawerHeader
          closeLinkPath={closeLinkPath}
          isCloseIcon
          closeIconA11yLabel={closeIconA11yLabel}
        />
        <ReserveCardSkeleton />
      </>
    );
  }

  if (!reserveRouteData || !spaceItem || !teamBookingDetails) {
    return (
      <>
        <DrawerHeader
          closeLinkPath={closeLinkPath}
          isCloseIcon
          closeIconA11yLabel={closeIconA11yLabel}
        />
        <EmptyCard iconName="warning">{t('common.networkError')}</EmptyCard>
      </>
    );
  }

  return (
    <>
      <DrawerHeader
        closeLinkPath={closeLinkPath}
        menu={menu}
        isCloseIcon
        closeIconA11yLabel={closeIconA11yLabel}
      />
      <DeskCheckIn
        spaceItem={spaceItem}
        startDate={teamBookingStartDate}
        isAvailable={isAvailable}
        isCheckingIn={isCheckingIn}
        onCheckIn={startCheckIn}
      />
    </>
  );
}
