import { Moment } from 'moment-timezone';
import { QueryFunction, QueryKey, QueryOptions } from 'react-query/types';

export type ISODateString =
  | `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`
  | `${number}-${number}-${number}T${number}:${number}:${number}Z`;

export type AnyDate = ISODateString | Date | Moment;

export type DateISODate = Date | ISODateString;

export type DateTimeZone<D extends AnyDate = DateISODate> = {
  date: D;
  timeZone?: string;
};

export type FormatDateParams = DateTimeZone<AnyDate> & {
  pattern?: string;
  locale?: string;
  is12HourFormat?: boolean;
};

export type TimeString = `${number}:${number}`;

export type Timezone = string;

export type DemoCheckIn = {
  checkInOpen: ISODateString;
  checkInClose: ISODateString;
  isCheckedIn?: boolean;
};

export type Nullable<T> = T | null;

export type TFunction<T> = (t?: T) => T | Promise<T>;

/**
 * Attempt to extract arguments type from provided type.
 * In case if T is a function type – use built-in Parameters util to retrieve arguments type.
 * Otherwise the resulting type is never.
 */
export type OptionalArguments<T> = T extends (...args: any[]) => any ? Parameters<T> : never;

export enum HttpStatus {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  AMBIGUOUS = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
}

export type GetQuery<QFnResponse = unknown, QOptions = object> = {
  queryKey: QueryKey;
  queryOptions: QueryOptions & QOptions;
  queryFn: QueryFunction<QFnResponse>;
};

export interface ChatLink {
  chatUrl: string;
  chatType: string;
}
