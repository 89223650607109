import { LocalizedColor, localizedColors } from '@engage-shared/theme';

export const getInitials = (name: string): string => {
  let initials = '';
  if (name && name.trim()) {
    const [first, last] = name.match(/\S+/g) as string[];
    if (first) {
      initials = first[0];
    }
    if (last) {
      initials += last[0];
    }
  }
  return initials.toUpperCase();
};

export const getCountryColorByNumber = (num: number, country?: string): LocalizedColor => {
  const defaultCountryColor = {
    backgroundColor: '#34495e',
    textColor: 'white',
  };

  const countryColorSet = country ? localizedColors[country] : localizedColors.default;
  const colorIndex = num % countryColorSet.length;
  const colorSet = countryColorSet[colorIndex || 1];
  return colorSet || defaultCountryColor;
};

export const getCountryColorByInitials = (initials: string, country?: string): LocalizedColor => {
  const characterNum = [...initials].reduce(
    (acc, initial) => acc + (initial.charCodeAt(0) - 65),
    0,
  );

  return getCountryColorByNumber(characterNum, country);
};
