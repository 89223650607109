import { useReducer, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPerson, fetchSpace } from '@engage-shared/api';
import { navigationActions, navigationSelectors, tenantSelectors } from '@engage-web/store';
import logger from '@engage-web/utils/logger/logger';
import { useAppLocation } from '@engage-web/router/hooks/useAppLocation';
import { SEARCH_QUERY, WAYFINDER_PATH } from '@engage-web/constants';
import { hasTokens } from '@engage-web/utils/config/tokenConfig';
import { deepLinkingReducer, setDeepLinkData } from './reducer';
import {
  getDeepLinkSearchParams,
  getDeepLinkString,
  getEmployeePath,
  getIsChecking,
  getPersonPath,
  getSpaceOrDeskPath,
  stripDeepLinkSearchParams,
} from './utils';
import { getUserFloorLocation } from '@engage-shared/utils';
import { generateFloorPath } from '@engage-web/router/utils';

export const useDeepLinking = () => {
  const { search, pathname } = useAppLocation();
  const reduxDispatch = useDispatch();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const deepLinkPath = useSelector(navigationSelectors.getDeepLinkPath);
  const deepLinkSearch = useSelector(navigationSelectors.getDeepLinkQueryString);
  const [deepLinkState, dispatch] = useReducer(deepLinkingReducer, {
    isChecking: getIsChecking(deepLinkSearch, deepLinkPath, search),
    to: null,
  });

  const {
    spaceId,
    deskId,
    bookingSpaceId,
    employeeId,
    personId,
    teamBookingId,
    date,
    time,
    floorId,
  } = getDeepLinkSearchParams(deepLinkSearch);

  const spaceTypeId = spaceId || deskId || bookingSpaceId;
  const hasDeskOrSpaceId = !!(spaceId || deskId);
  const hasSpaceOrDeskDeepLink = !!spaceTypeId;

  const setDeepLinkString = useCallback(
    (path: string, search?: string) => {
      dispatch(
        setDeepLinkData({
          isChecking: false,
          to: getDeepLinkString(path, search),
        }),
      );
    },
    [dispatch],
  );

  const checkSpaceDeepLink = useCallback(async () => {
    // prevent setting final deep link based on search query if deepLinkPath exists
    if (deepLinkPath) return;

    let data = null;

    try {
      data = await fetchSpace({
        // @ts-ignore
        id: spaceTypeId,
      });
    } catch (e) {
      logger.error(e);
    }

    setDeepLinkString(
      // @ts-ignore
      getSpaceOrDeskPath(data, hasDeskOrSpaceId, !!deskId),
      stripDeepLinkSearchParams(deepLinkSearch, [SEARCH_QUERY.FLOOR]),
    );
  }, [setDeepLinkString, hasDeskOrSpaceId, deskId, deepLinkSearch, spaceTypeId, deepLinkPath]);

  const checkFloorDeepLink = useCallback(async () => {
    // prevent setting final deep link based on search query if deepLinkPath exists
    if (deepLinkPath || !floorId) return;

    try {
      await getUserFloorLocation(parseInt(floorId, 10));
    } catch (e) {
      logger.error(e);
    }

    setDeepLinkString(generateFloorPath(floorId));
  }, [personId, setDeepLinkString, deepLinkPath, deepLinkSearch]);

  const checkPersonDeepLink = useCallback(async () => {
    // prevent setting final deep link based on search query if deepLinkPath exists
    if (deepLinkPath) return;

    let data;

    try {
      data = await fetchPerson({ id: +personId! });
    } catch (e) {
      logger.error(e);
    }

    setDeepLinkString(
      getPersonPath(data),
      stripDeepLinkSearchParams(deepLinkSearch, [SEARCH_QUERY.FLOOR]),
    );
  }, [personId, setDeepLinkString, deepLinkPath, deepLinkSearch]);

  const setEmployeeDeepLink = useCallback(() => {
    setDeepLinkString(getEmployeePath(employeeId!), deepLinkSearch);
  }, [employeeId, setDeepLinkString, deepLinkSearch]);

  const setNonDrawerDeepLink = useCallback(() => {
    setDeepLinkString(deepLinkPath ?? WAYFINDER_PATH, deepLinkSearch);
  }, [setDeepLinkString, deepLinkPath, deepLinkSearch]);

  const setNewDeepLink = useCallback(() => {
    setDeepLinkString(deepLinkPath, deepLinkSearch);
  }, [setDeepLinkString, deepLinkPath, deepLinkSearch]);

  const clearDeepLink = () => {
    reduxDispatch(navigationActions.clearDeepLinkQueryString());
    reduxDispatch(navigationActions.clearDeepLinkPath());
    dispatch(setDeepLinkData({ to: null, isChecking: false }));
  };

  const isAuthenticated = hasTokens();

  // set old deep link based on search query
  useEffect(() => {
    if (!tenantId || !isAuthenticated) return;

    if (hasSpaceOrDeskDeepLink) {
      checkSpaceDeepLink();
    } else if (personId) {
      checkPersonDeepLink();
    } else if (employeeId) {
      setEmployeeDeepLink();
    } else if (date || time || teamBookingId) {
      setNonDrawerDeepLink();
    } else if (floorId) {
      checkFloorDeepLink();
    }
  }, [
    teamBookingId,
    employeeId,
    personId,
    date,
    time,
    tenantId,
    checkSpaceDeepLink,
    setEmployeeDeepLink,
    setNonDrawerDeepLink,
    hasSpaceOrDeskDeepLink,
    checkPersonDeepLink,
    isAuthenticated,
    pathname,
  ]);

  // set new deep link based on pathname and search query
  useEffect(() => {
    if (deepLinkPath) {
      setNewDeepLink();
    }
  }, [setNewDeepLink, setDeepLinkString, deepLinkPath]);

  return {
    ...deepLinkState,
    clearDeepLink,
  };
};
