import { Location, useLocation } from 'react-router-dom';
import { Nullable } from '@engage-shared/utils/types';
import { Person } from '@engage-shared/api/people';
import { ParsedSpaceItem } from '@engage-shared/api/spaces';
import { Team } from '@engage-shared/api/teams';
import { SpaceTypeOrAttribute } from '@engage-shared/api/spaces';
import { SearchResponseData } from '@engage-shared/api/search';
import { FavouritePerson } from '@engage-web/api/queries/useFavouritePeople';

type AppLocationState = {
  /**
   * Previous pathname from the stack.
   * Useful if there is a need to come back to the previous route and pop (`navigate(-1)`)
   * action cannot be used for some reason.
   * Currently, used in location selector to get back to keep restrict to filter.
   */
  fromPath: string;
  /**
   * Flag which signifies that the person location should not be changed any further.
   * This is used in person card's `useEffect` which checks if the person's default
   * location is different from the current one, and if it is – navigates to that location.
   * If this flag is set to `true` – prevents navigation to the default location.
   */
  isFinalLocation?: boolean;
  person?: Person & SearchResponseData;
  desk?: ParsedSpaceItem & SearchResponseData;
  space?: ParsedSpaceItem & SearchResponseData;
  team?: Team & SearchResponseData;
  favPeople: FavouritePerson[];
  spaceAttributes?: SpaceTypeOrAttribute;
  /**
   * The spaceId returned by the search api and selected on the person list
   * or send from the ReserveCard when selecting occupant.
   * @see PersonLocations for more info.
   * @see ReserveCard for more info.
   */
  searchSpaceId?: number;
};

type AppLocation = Location & {
  state: Nullable<AppLocationState>;
};

export const useAppLocation = () => useLocation() as AppLocation;
