import React, { CSSProperties, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlatList } from '../../base';
import { useTranslation } from 'react-i18next';
import { notificationsActions, notificationsSelectors } from '../../../store';
import { useBookingModal } from '../../../utils/hooks';
import { EmptyList, ListWrapper, Loader, NotificationItemWrapper } from './styled';
import NotificationItem, { INotificationItem } from './NotificationItem';
import { getSize } from './Notification/Notification';
import NotificationSkeleton from './Notification/NotificationSkeleton';

const itemSize = getSize();

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show: showBookingModal } = useBookingModal();

  const notifications = useSelector(notificationsSelectors.getSortedNotifications);

  const onSelectNotification = ({ item }: { item: INotificationItem }) => {
    const { event } = item;
    dispatch(notificationsActions.updateNotification(item));
    showBookingModal(event);
  };

  const renderLoader = ({ style }: { style: CSSProperties }) => (
    <Loader style={style}>
      <NotificationSkeleton />
    </Loader>
  );

  const renderItem = ({
    index,
    item,
    style,
  }: {
    index: number;
    item: INotificationItem;
    style: CSSProperties;
  }) => {
    return (
      <NotificationItemWrapper style={style} data-testid={`Notification-${index}`}>
        <NotificationItem item={item} onSelectNotification={onSelectNotification} />
      </NotificationItemWrapper>
    );
  };

  if (!notifications || notifications.length <= 0) {
    return <EmptyList>{t('layout.notifications.noNotifications')}</EmptyList>;
  }

  return (
    <ListWrapper>
      <FlatList
        data={notifications}
        itemCount={notifications.length}
        renderItem={renderItem}
        renderLoader={renderLoader}
        itemSize={itemSize}
      />
    </ListWrapper>
  );
};

export default memo(Notifications);
