import { useDispatch } from 'react-redux';
import { inAppNotificationsActions } from '@engage-web/store';
import { useState } from 'react';
import { getIsBookingsAllowed } from '@engage-shared/utils';
import { useUser } from '@engage-web/api/queries/useUser';
import { useConfig } from './useConfig';

const useBookingsAllowedNotification = ({ notificationAllowed }) => {
  const config = useConfig();
  const [notificationShowed, setNotificationShowed] = useState(false);
  const dispatch = useDispatch();
  const { data: userData, isSuccess } = useUser();

  const { bookingsAllowed, bookingDeniedMessage } = getIsBookingsAllowed({
    config,
    userData,
  });

  if (!isSuccess || !notificationAllowed) return;

  if (!bookingsAllowed && !notificationShowed) {
    dispatch(
      inAppNotificationsActions.addWarningNotification({
        message: bookingDeniedMessage,
      }),
    );
    setNotificationShowed(true);
  }
};

export default useBookingsAllowedNotification;
