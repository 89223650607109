import React, { useCallback, useContext } from 'react';
import { PersonButton } from '@engage-web/components/base';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useRestrictTo } from '@engage-web/utils';
import { PersonButtonWrapper } from '../../styled';
import { ReserveCardContext } from '../ReserveCardContext';

type BookForPersonButtonProps = {
  disabled?: boolean;
};

export const BookForPersonButton = ({ disabled }: BookForPersonButtonProps) => {
  const { spaceItem, user, durationObj, reservee, isOccupied, isTeam } =
    useContext(ReserveCardContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const shouldDisableBookFor = disabled || (isTeam ? false : isOccupied);

  const bookFor = reservee?.id ? reservee : user;

  const restrictTo = useRestrictTo();
  const dataKey = restrictTo?.toLowerCase() ?? '';

  const showReservees = useCallback(() => {
    navigate(PATH_SEGMENT.RESERVEE, {
      state: {
        [dataKey]: {
          spaceItem,
          user,
          ...durationObj,
        },
      },
    });
  }, [dataKey, navigate, spaceItem, user, durationObj]);

  return (
    <PersonButtonWrapper>
      <PersonButton
        title={`${t('layout.reserve.desk.bookFor')} :`}
        {...bookFor}
        onClick={showReservees}
        disabled={shouldDisableBookFor}
      />
    </PersonButtonWrapper>
  );
};
