import React from 'react';
import SearchItemSkeleton from '@engage-web/components/layouts/GlobalSearch/SearchItemSkeleton';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from '@engage-web/components/base/Skeleton';
import { TeamMembersHeader } from './TeamMembersHeader';
import { TeamMembersSearch } from './TeamMembersSearch';
import { TeamMembersListWrapper, TeamMembersListSkeleton } from './TeamMembers.style';

export function TeamMembersSkeleton() {
  return (
    <>
      <TeamMembersHeader />
      <TeamMembersSearch />
      <TeamMembersListWrapper>
        <TeamMembersListSkeleton>
          <SearchItemSkeleton />
          <SearchItemSkeleton />
          <SearchItemSkeleton />
          <SearchItemSkeleton />
          <SearchItemSkeleton />
          <SearchItemSkeleton />
        </TeamMembersListSkeleton>
      </TeamMembersListWrapper>
      <SkeletonTheme>
        <Skeleton width="100%" height={80} />
      </SkeletonTheme>
    </>
  );
}
