import React, { memo, useEffect, useState } from 'react';
import Select from '../Select/Select';
import { useSelectedStylesTimeSelect } from './styled';
import { TimeObject } from '../DateTime/TimePicker/utils';

interface TimeSelectProps {
  data: TimeObject[];
  type: string;
  selectedId: string;
  isTimeFormatH12?: boolean;
  isPickedTimeInPast?: boolean;
  onChange: (value: TimeObject | null) => void;
}

const TimeSelect = ({
  data,
  onChange,
  selectedId,
  type,
  isTimeFormatH12 = false,
  isPickedTimeInPast = false,
}: TimeSelectProps) => {
  const [activeID, setActiveID] = useState(selectedId);
  const getTimeState = data.find(obj => obj.value === activeID);
  const [time, setTime] = useState(getTimeState);

  useEffect(() => {
    if (isPickedTimeInPast) {
      const firstAvailableValue = data.find(obj => !obj.isDisabled);
      onChange(firstAvailableValue ?? null);
    }

    if (activeID !== selectedId) {
      setActiveID(selectedId);
    }

    setTime(getTimeState);
  }, [activeID, data, onChange, isPickedTimeInPast, selectedId, getTimeState]);

  const styles = useSelectedStylesTimeSelect(isTimeFormatH12);
  return (
    <Select
      options={data}
      styles={styles}
      classNamePrefix={`time-select-${type}`}
      maxMenuHeight={160}
      isSearchable={false}
      value={time}
      defaultValue={data[0]}
      isOptionDisabled={option => option.isDisabled}
      onChange={onChange}
      id={`time-select-${type}`}
      focusLabel={type}
      menuPlacement="auto"
    />
  );
};

export default memo(TimeSelect);
