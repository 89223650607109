import { getShouldShowHealthDeclarationOnBooking } from '@engage-shared/utils';
import { useContext, useEffect } from 'react';
import {
  useBookingMutation,
  useHourlyTeamBookingMutation,
  useMultiDayBookingMutation,
  useMultiDayTeamBookingMutation,
} from '@engage-web/api/mutations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useConfig, useModal } from '@engage-web/utils';
import {
  filtersSelectors,
  inAppNotificationsActions,
  teamReservationsSelectors,
} from '@engage-web/store';
import { MODALS } from '@engage-web/constants';
import useReservationFilters, { FilterProps } from '@engage-web/utils/hooks/useReservationFilters';
import { DurationObj } from '../ReserveCard/types';
import { getFailedDaysFromSelected } from '../reserveCardHelpers';
import { useTeamReserveCallbacks } from './useTeamReserveCallbacks';
import { useReserveCallbacks } from './useReserveCallbacks';
import { ReserveCardContext } from '../ReserveCard/ReserveCardContext';

export const useMakeReservation = () => {
  const {
    item,
    spaceItem,
    isTeam,
    durationObj,
    setDurationObj,
    timeZone,
    user,
    reservee,
    setReserveDisabled,
  } = useContext(ReserveCardContext);

  const { isDesk } = spaceItem;
  const { duration, allDayDuration, selectedDays } = durationObj;

  // use reservee field if they reserve a desk for a different employee
  const reserveeId = reservee && user?.id !== spaceItem.id ? reservee.id : null;

  // Update reservation feature is currently disabled
  const isUpdate = false;

  const reservationFilter = useReservationFilters(isDesk);
  const { bookingIntervalSize, enforceAllDayDeskReservations } = reservationFilter;

  const config = useConfig(spaceItem?.buildingId);
  const { enableHealthDeclaration } = config;

  const teamReservationSelectedDesks = useSelector(
    teamReservationsSelectors.getTeamReservationSelectedDesks,
  );
  const teamSelectedMembersIds = useSelector(teamReservationsSelectors.getTeamSelectedMembersIds);

  // Days from Reservation assist
  const { dateStart, dateEnd, allDay }: FilterProps = useSelector(filtersSelectors.getFilterDates);

  const isMultiDayDeskReservation = isDesk && enforceAllDayDeskReservations && !isUpdate;
  const isMultiDayTeamReservation = isTeam && enforceAllDayDeskReservations;

  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const { openModal } = useModal();

  const { onSuccessBooking, onErrorBooking, onSuccessMultiDayBooking } = useReserveCallbacks({
    spaceItem,
  });
  const { onSuccessHourlyTeamBooking, onErrorTeamBooking, onSuccessMultiDayTeamBooking } =
    useTeamReserveCallbacks({
      spaceItem,
    });

  const bookingMutation = useBookingMutation({
    spaceItem,
    isDesk,
    user,
    filter: reservationFilter,
    reserveeId,
    duration,
    allDayDuration,
    isUpdate,
    onSuccess: onSuccessBooking,
    onError: onErrorBooking,
  });

  const genericOnErrorHandler = (error: Error) =>
    reduxDispatch(
      inAppNotificationsActions.addErrorNotification({
        message: t(`layout.reserve.desk.${error}`, '', {
          defaultValue: error, // in case we do not have translation for error just display in as it is
        }),
      }),
    );

  const multiDayBookingMutation = useMultiDayBookingMutation({
    spaceItem,
    isDesk,
    user,
    filter: reservationFilter,
    reserveeId,
    duration,
    allDayDuration,
    isUpdate,
    selectedDays,
    onSuccess: ({
      successfullyReserved,
      successfullyReservedDaysMessage,
      failedDaysMessage,
      concurrentDaysMessage,
      limitPerIntervalMessage,
      limitPerBuildingMessage,
      bookedDays,
    }) => {
      // display success UI notification if at least one successfully reservation was made
      // and display error message with the list of days which couldn't be reserved
      if (failedDaysMessage || concurrentDaysMessage) {
        setReserveDisabled(true);
      }

      const newSelectedDays = failedDaysMessage
        ? getFailedDaysFromSelected(selectedDays, failedDaysMessage)
        : [];
      setDurationObj((state: DurationObj) => ({
        ...state,
        selectedDays: newSelectedDays,
      }));

      onSuccessMultiDayBooking({
        successfullyReserved,
        successfullyReservedDaysMessage,
        failedDaysMessage,
        concurrentDaysMessage,
        limitPerIntervalMessage,
        limitPerBuildingMessage,
        bookedDays,
      });
    },
    onError: genericOnErrorHandler,
  });

  const teamBookingSummary = t('layout.reserve.team.teamBooking');

  const hourlyTeamBookingMutation = useHourlyTeamBookingMutation({
    // @ts-ignore
    teamId: item?.favPeopleTeam ? null : item?.id,
    summary: teamBookingSummary,
    filter: reservationFilter,
    duration,
    allDayDuration,
    timeZone,
    desks: teamReservationSelectedDesks,
    attendees: teamSelectedMembersIds,
    onSuccess: onSuccessHourlyTeamBooking,
    onError: onErrorTeamBooking,
  });

  const multiDayTeamBookingMutation = useMultiDayTeamBookingMutation({
    // @ts-ignore
    teamId: item?.favPeopleTeam ? null : item?.id,
    summary: teamBookingSummary,
    timeZone,
    desks: teamReservationSelectedDesks,
    attendees: teamSelectedMembersIds,
    filter: reservationFilter,
    selectedDays,
    onSuccess: onSuccessMultiDayTeamBooking,
    onError: genericOnErrorHandler,
  });

  const spaceBookingMutation = isMultiDayDeskReservation
    ? multiDayBookingMutation
    : bookingMutation;
  const teamBookingMutation = isMultiDayTeamReservation
    ? multiDayTeamBookingMutation
    : hourlyTeamBookingMutation;
  const { onBooking, isLoading, isError } = isTeam ? teamBookingMutation : spaceBookingMutation;

  const makeReservation = () => {
    const allDayValue = allDay || (isDesk && enforceAllDayDeskReservations) || allDayDuration;
    const shouldShowHealthDeclaration =
      !isTeam &&
      enableHealthDeclaration &&
      getShouldShowHealthDeclarationOnBooking({
        tenantConfig: config,
        isMultiDay: isMultiDayDeskReservation,
        // @ts-ignore TODO: fix Date type mismatch
        dateStart,
        // @ts-ignore
        dateEnd,
        allDay: allDayValue,
        duration,
        bookingIntervalSize,
        // @ts-ignore
        selectedDays,
        showDaysSelector: spaceItem.showDaysSelector,
        showDurationSelection: spaceItem.showDurationSelection,
        timeZone,
      });

    if (shouldShowHealthDeclaration) {
      openModal(MODALS.HEALTH_DECLARATION, { onConfirm: onBooking });
    } else {
      onBooking();
    }
  };

  useEffect(() => {
    if (isError) {
      setReserveDisabled(true);
    }
  }, [isError, setReserveDisabled]);

  return { makeReservation, isLoading };
};
