import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { testProps } from '@engage-web/utils';
import { tenantSelectors } from '@engage-web/store';
import { ContentOuterWrapper, ResizeHandle, Wrapper } from './styled';
import { AnimatedHeight } from './AnimatedHeight';

const Drawer = memo(() => {
  const { t } = useTranslation();
  const [minimized, setMinimized] = useState(false);
  const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
  const hasLocation = !!(building && floor);

  const toggleMinimized = () => setMinimized(!minimized);

  return (
    <Wrapper>
      <AnimatedHeight>
        <ContentOuterWrapper $minimized={minimized}>
          {hasLocation && (
            <ResizeHandle
              {...testProps(t, 'accessibilityLabels.resizeHandle').props}
              onClick={toggleMinimized}
            />
          )}
          {/* This is where drawer content is rendered */}
          <Outlet context={{ minimized, setMinimized }} />
        </ContentOuterWrapper>
      </AnimatedHeight>
    </Wrapper>
  );
});

export const useDrawerContext = () =>
  useOutletContext<{
    minimized: boolean;
    setMinimized: (value: boolean) => void;
  }>();

export default Drawer;
