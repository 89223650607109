import React, { memo, useEffect, useState } from 'react';
import { getYear } from 'date-fns';
import { useAgendaEvents } from '@engage-web/api/queries';
import { filtersSelectors, navigationSelectors } from '@engage-web/store';
import { useSelector } from 'react-redux';
import AgendaList from '@engage-web/components/layouts/Agenda/content/AgendaList';
import { Wrapper } from '@engage-web/components/layouts/Agenda/styled';
import Analytics from '@engage-web/utils/analytics';
import { DOCUMENT_TITLE_LEVEL } from '@engage-web/constants';
import { FocusId, useFocus, useSetPageTitle } from '@engage-web/utils/hooks';
import { useTranslation } from 'react-i18next';
import AgendaCalendar from './AgendaCalendar';
import { getDayStartTime, getMonth } from '@engage-shared/utils';

const Agenda = () => {
  const eventsFilter = useSelector(filtersSelectors.getAgendaEventsType);
  const { content } = useSelector(navigationSelectors.getModalLayout);
  const { t } = useTranslation();
  const startDate = content?.startDate;
  const currentDayStartTime = startDate || getDayStartTime({ date: new Date() });
  const [selectedDate, setSelectedDate] = useState(currentDayStartTime);

  // Derived state
  const currentMonthNumber = getMonth(selectedDate);
  const currentYear = getYear(selectedDate);

  const { data, isError, isLoading } = useAgendaEvents(
    currentMonthNumber,
    currentYear,
    eventsFilter,
  );

  const events = data ?? {};

  const renderAgendaList = () => {
    if (isError) {
      return 'Events can not be loaded';
    }
    return (
      <AgendaList selectedDate={selectedDate} agendaItems={events} isLoadingData={isLoading} />
    );
  };

  useSetPageTitle(t('documentTitles.agenda'), DOCUMENT_TITLE_LEVEL.MODAL);

  useEffect(() => {
    Analytics.track('EVT-077');
  }, []);

  useFocus({ returnFocusTo: FocusId.AgendaButton });

  return (
    <Wrapper data-testid="agenda">
      <AgendaCalendar
        agendaItems={events}
        selectedDate={selectedDate}
        onDateChange={setSelectedDate}
      />
      {renderAgendaList()}
    </Wrapper>
  );
};

export default memo(Agenda);
