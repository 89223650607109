import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eff2f6;
  border-radius: 50px;
  filter: grayscale(100%);
  height: 50px;
  width: 80%;
  margin-bottom: 10px;
  background: #eee; // this bg color to make sure that white parts of a logo are visible
  transition: filter 0.3s;

  &:hover {
    filter: none;
    cursor: pointer;
  }

  > div {
    justify-content: center;
    height: 80%;
  }

  @media screen and (min-width: 600px) {
    width: 200px;
    margin-top: 10px;

    & + & {
      margin-left: 10px;
    }
  }
`;

export const TenantName = styled.span`
  font-size: 14px;
  font-weight: bold;
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
