import { logout as doLogout, TokenManager, getAuthUrl } from '@engage-shared/api';
import { LogonResponse, LogonParams, LogoutParams, ExchangeParams } from './auth.types';

export const logon = async ({
  username,
  password,
  tenantId,
}: LogonParams): Promise<LogonResponse> => {
  const url = `${getAuthUrl(tenantId)}/basic`;
  const body = JSON.stringify({ userName: username, password });
  const response = await fetch(url, {
    method: 'POST',
    body,
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data?.message);
  }

  return data;
};

export const logout = async ({ tenantId }: LogoutParams) => {
  const { refreshToken } = await TokenManager.getTokens();
  return doLogout({ tenantId, refreshToken });
};

export const exchange = async ({ tenantId, idToken }: ExchangeParams): Promise<LogonResponse> => {
  try {
    const url = `https://${tenantId}.serraview.com/api/v1/engage_auth/exchange`;
    const body = JSON.stringify({ idToken });
    const response = await fetch(url, {
      method: 'POST',
      body,
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
    });
    return response.json();
  } catch (error) {
    // @ts-ignore
    throw new Error(error?.message);
  }
};
