import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_SEGMENT } from '@engage-web/constants';
import { teamReservationsActions, tenantSelectors } from '@engage-web/store';
import { useNavigate } from 'react-router-dom';
import { generatePersonPath } from '@engage-web/router/utils';
import { getLatestPresenceEvent, getSpaceSVLivePresenceEventOccupancy } from '@engage-shared/utils';
import BookedByButton from '../../BookedButton/BookedButton';
import { ReserveCardContext } from '../ReserveCardContext';

type OccupantButtonProps = {
  shouldShowGuest?: boolean;
};

export const OccupantButton = ({ shouldShowGuest }: OccupantButtonProps) => {
  const { spaceItem, item, isTeam, occupant, svLiveOccupant } = useContext(ReserveCardContext);

  const { id: spaceId } = spaceItem;

  const occupiedBy = {
    ...occupant,
    ...{
      ...svLiveOccupant,
      ...(svLiveOccupant && { id: svLiveOccupant?.personId }),
    },
  };

  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToTeamsList = () => {
    // Show teams lists
    navigate(PATH_SEGMENT.TEAMS, {
      state: {
        team: item,
      },
    });
    reduxDispatch(teamReservationsActions.clearSelection());
  };

  const { floor } = useSelector(tenantSelectors.getCurrentLocation);
  const { isOccupied: isOccupiedBySvLive } = getSpaceSVLivePresenceEventOccupancy(spaceItem);
  const latestPresenceEvent = getLatestPresenceEvent(spaceItem?.presenceEvents);

  const showOccupant = () => {
    if (shouldShowGuest) {
      return;
    }

    const pathname = generatePersonPath({
      floorId: floor.id,
      // @ts-ignore TODO: add correct check in case latestPresenceEvent or occupant is null
      personId: isOccupiedBySvLive ? latestPresenceEvent.personId : occupant.id,
    });

    navigate(pathname, {
      state: {
        searchSpaceId: spaceId,
      },
    });
  };

  return (
    <BookedByButton
      isSvLiveOccupant={!!svLiveOccupant}
      {...occupiedBy}
      onPress={isTeam ? navigateToTeamsList : showOccupant}
      forceToGuest={shouldShowGuest}
    />
  );
};
