import React, { KeyboardEvent } from 'react';
import { Tenant } from '@engage-web/store/tenant';
import ClientLogo from '../../../base/ClientLogo/ClientLogo';
import { ButtonWrapper, TenantName } from './styled';

type TenantButtonProps = {
  tenant: Tenant;
  onClick: () => void;
};

export const TenantButton = ({ tenant, onClick }: TenantButtonProps) => {
  const { name, logo, id } = tenant;

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <ButtonWrapper role="button" onKeyDown={handleKeyDown} tabIndex={0} onClick={onClick}>
      <ClientLogo
        id={id}
        logoURL={logo}
        title={name}
        fallback={<TenantName title={name}>{name}</TenantName>}
      />
    </ButtonWrapper>
  );
};
