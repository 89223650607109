import { Nullable } from '@engage-shared/utils/types';
import { SENSOR_STATUS } from '@engage-shared/constants/space';
import { PresenceStatus } from '@engage-shared/constants/person';
import { isTimeNow } from '@engage-shared/utils/dates';

type GetSpaceStatusInfoArgs = {
  sensorStatus: Nullable<SENSOR_STATUS>;
  svLiveStatus: Nullable<PresenceStatus>;
  filterDateStart: Nullable<Date>;
  isReserved: boolean;
  isOccupied: boolean;
  isDesk: boolean;
};

type GetSpaceStatusInfoResult = Nullable<{
  label: string;
  disableReservation?: boolean;
}>;

type GetSpaceStatusInfo = (args: GetSpaceStatusInfoArgs) => GetSpaceStatusInfoResult;

const sensorStatusInfoMap: Record<SENSOR_STATUS, GetSpaceStatusInfoResult> = {
  occupied: {
    label: 'components.spaceCard.occupied',
  },
  vacant: {
    label: 'components.spaceCard.available',
  },
  offline: null,
};

// TODO: get this value from tenant config when that will be available
let showSensorInfo = true;

/**
 * Get space status info object.
 *
 * 1. If SV Live status is available and not offline – desk will be considered occupied and we will disable
 * reservation controls.
 * 2. If desk is reserved – it's considered occupied.
 * 3. If desk is occupied (meaning someone assigned to it and it's not bookable) or no start date is set in timeline
 * selector or reservation assist, or sensor status is not available – we do not provide status information.
 * 4. Otherwise we use sensor's info to provide space status information.
 *
 * SV Live > reservation status > desk's occupancy/availability > sensor status
 *
 * @param {Object} options
 * @param {SENSOR_STATUS | null} options.sensorStatus - Space's sensor status (vacant, occupied, offline).
 * @param {PresenceStatus | null} options.svLiveStatus - Space's SV Live status (active, away, remote, offline).
 * @param {Date | null} options.filterDateStart - Currently selected start date filter.
 * @param {Boolean} options.isReserved - Reserved status.
 * @param {Boolean} options.isOccupied - Occupied status (at this moment – combination of bookable & available properties on the space object).
 * @param {Boolean} options.isDesk - True if it's a desk
 * @return {GetSpaceStatusInfoResult | null} - Object containing text and disableReservation (optional)
 * only in case if svLiveStatus is provided.
 */
export const getSpaceStatusInfo: GetSpaceStatusInfo = ({
  sensorStatus,
  svLiveStatus,
  filterDateStart,
  isReserved,
  isOccupied,
}) => {
  // SVLive data and sensor data do not apply for future dates
  const isNow = isTimeNow(filterDateStart);

  if (svLiveStatus && svLiveStatus !== PresenceStatus.OFFLINE && isNow) {
    return {
      label: 'components.spaceCard.occupied',
      disableReservation: true,
    };
  }

  if (isReserved) {
    return {
      label: 'components.spaceCard.reserved',
    };
  }

  if (isOccupied || !sensorStatus || !isNow || !showSensorInfo) {
    return null;
  }

  return sensorStatusInfoMap[sensorStatus];
};
