import React from 'react';
import { useTranslation } from 'react-i18next';
import { testProps } from '@engage-web/utils';
import { useClientLogo } from './useClientLogo';
import { calculateAspectRatio } from './svgUtils';
import { LogoFallback, LogoWrapper } from './styled';

type ClientLogoProps = {
  fallback?: JSX.Element;
  disableFallback?: boolean;
  logoURL?: string;
  title?: string;
  id?: string;
  textColor?: string;
};

const ClientLogo = ({
  fallback,
  disableFallback = false,
  logoURL = '',
  title = '',
  id = '',
  textColor,
}: ClientLogoProps) => {
  const { t } = useTranslation();
  const clientLogQuery = useClientLogo(logoURL, title, id);
  const data = clientLogQuery?.data || {};
  const { xml, props: svgProps, instanceTitle } = data;

  if (!xml) {
    if (disableFallback) return null;
    return (
      fallback || (
        <LogoFallback $textColor={textColor} data-testid="client-logo-fallback">
          {instanceTitle}
        </LogoFallback>
      )
    );
  }

  if (!svgProps) return null;

  if ('viewBox' in svgProps) {
    const { width, height } = calculateAspectRatio({
      viewBox: svgProps.viewBox,
    });
    return (
      <LogoWrapper
        $fillColor={textColor}
        {...testProps(t, 'accessibilityLabels.clientLogo').props}
        dangerouslySetInnerHTML={{ __html: xml }}
        $width={width}
        $height={height}
      />
    );
  }

  return null;
};

export default ClientLogo;
