import { useCallback } from 'react';
import { addWeeks, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedStartTime, getSelectedTimes } from '@engage-shared/utils';
import { filtersActions, filtersSelectors, navigationSelectors } from '@engage-web/store';
import { useCurrentTimeZone } from '@engage-web/utils';
import { useConfig } from './useConfig';

type UseDatePickerParams = {
  isDraft?: boolean;
};

/**
 * Date picker hook
 * @param isDraft - boolean that specifies should we use draft filters data or not
 * @param selectedDate - date selected on Calendar
 */
export default function useDatePicker(
  { isDraft }: UseDatePickerParams = {
    isDraft: false,
  },
) {
  const { dateStart, dateEnd, allDay } = useSelector(
    isDraft ? filtersSelectors.getDraftFilterDates : filtersSelectors.getFilterDates,
  );

  const showTimelineDatePicker = useSelector(navigationSelectors.getShowTimelineDatePicker);

  const dispatch = useDispatch();
  const timeZone = useCurrentTimeZone();
  const { futureBookingsLimit } = useConfig();
  const minDate = new Date();
  const maxDate = subDays(addWeeks(new Date(), futureBookingsLimit || 4), 1);

  // @ts-ignore
  const start = getSelectedStartTime(null);
  const { dateStart: defaultStartDate, dateEnd: defaultEndDate } = getSelectedTimes(
    dateStart || start,
    null,
  );

  /**
   * Updates `filters.filterValues.dateStart` and `filters.filterValues.dateEnd` values.
   * Start date will be set to provided value.
   * End date will be set to `null`.
   *
   * @param {Date} date
   * @returns void
   */
  const setTimelineDate = useCallback(
    (value: Date) => {
      const dateStart = getSelectedStartTime(value);
      dispatch(filtersActions.setFilterValues({ dateStart, dateEnd: null }));
    },
    [dispatch],
  );

  /**
   * Updates `filters.filterValues.dateStart` and `filters.filterValues.dateEnd` values.
   * Start date will be set to provided value.
   * End date will be set according to dateEnd if considerDateEnd is set to true,
   * otherwise it will set dateEnd to the provided date + 1 hour.
   *
   * @param {Date} date
   * @param {boolean} considerDateEnd
   * @returns void
   */
  const setDateStart = useCallback(
    (date: Date, considerDateEnd = false) => {
      const start = getSelectedStartTime(date);
      const changes = getSelectedTimes(start, considerDateEnd ? dateEnd : null);

      dispatch(filtersActions.setFilterValues({ ...changes, isDraft }));
    },
    [dateEnd, dispatch, isDraft],
  );

  /**
   * Updates `filters.filterValues.dateStart` and `filters.filterValues.dateEnd` values.
   * End date will be set to provided value.
   * Start date will be set to current `filters.filterValues.dateStart` value if provided `date` argument is after `dateStart`.
   * Otherwise, start date will be set to provided `date` - 1 hour.
   *
   * @param {Date} date
   * @returns void
   */
  const setDateEnd = useCallback(
    (date: Date) => {
      const changes = getSelectedTimes(dateStart, date);
      dispatch(filtersActions.setFilterValues({ ...changes, isDraft }));
    },
    [dateStart, dispatch, isDraft],
  );

  /**
   * Clears `filters.filterValues.dateStart` and `filters.filterValues.dateEnd` values (both set to `null`).
   *
   * @returns void
   */
  const onClear = useCallback(() => {
    dispatch(filtersActions.clearDates({ isDraft }));
  }, [dispatch, isDraft]);

  return {
    maxDate,
    minDate,
    timeZone,
    showTimelineDatePicker,
    allDay,
    onClear,
    dateStart,
    defaultStartDate,
    dateEnd,
    defaultEndDate,
    setDateStart,
    setDateEnd,
    setTimelineDate,
  };
}
