import styled from 'styled-components';

export const OverlayFloorplan = styled.div<{ $showOverlay: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  filter: ${({ $showOverlay }) => ($showOverlay ? 'blur(5px)' : 'unset')};
  will-change: auto;
  transition: filter 200ms;
  &:before {
    content: '';
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    transition: all 200ms;
    will-change: opacity;
    opacity: ${({ $showOverlay }) => ($showOverlay ? '1' : '0')};
    visibility: ${({ $showOverlay }) => ($showOverlay ? 'unset' : 'hidden')};
    background-color: rgba(3, 13, 48, 0.7);
  }
`;

export default OverlayFloorplan;
