import React, { useEffect, useMemo, useState } from 'react';
import OpenSeadragon from 'openseadragon';

import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { ZoomDescriptorViewer } from './ZoomDescriptor';
import { ShapesContainer } from './Shapes';
import {
  setDrawableMarkers,
  setFloorId,
  setSpacesData,
  setZoomDescriptor,
  updateOptionState,
  useFloorplanInitDataReducer,
  useFloorplanOptionsReducer,
  useKioskLocation,
} from '../hooks';
import { TeamReservationSelectionStateProvider } from './TeamReservationSelectionState';
import { ThemeManager } from '../theme';
import { Spinner } from '../components';
import styled from 'styled-components';

interface FloorplanHandlerProps {
  viewerOptions?: OpenSeadragon.Options;
  hidePeopleData?: boolean;
}

const defaultLayers = {
  showOccupants: true,
  showRooms: true,
  showDesks: true,
  all: true,
};

const SpacesLoader = styled.div`
  width: 100%;
  height: 100%;
  background: #e6e8eb;
  position: absolute;
  top: 0;
`;

export const FloorplanHandler = ({
  viewerOptions,
  hidePeopleData = false,
}: FloorplanHandlerProps) => {
  const fes = useFESContext();
  const { kioskLocation } = useKioskLocation();
  const [zoomDescriptorLoading, setZoomDescriptorLoading] = useState(false);
  const [options, dispatchOptions] = useFloorplanOptionsReducer();
  const [data, dispatchData] = useFloorplanInitDataReducer();
  const [layers, setLayers] = useState(defaultLayers);
  const [focusedSpaceId, setFocusedSpaceId] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const zoomDescriptor = data?.zoomDescriptor;
  const spaces = data?.spaces ?? [];
  const filteredSpaces = data?.filteredSpaces;
  const drawableMarkers = data?.drawableMarkers;
  const floorId = data?.floorId;

  const memoizedOptions = useMemo(() => viewerOptions, [viewerOptions]);
  const memoizedZoomDescriptor = useMemo(() => zoomDescriptor, [zoomDescriptor]);

  useEffect(() => {
    const handleInitData = (obj: any) => {
      if (obj.theme) {
        ThemeManager.setTheme(obj.theme);
      }
      // we need to be sure zoomDescriptor is always truthy in order to not to rerender #osd-viewer-{floorId} Node
      if (obj.zoomDescriptor) {
        dispatchData(setZoomDescriptor(obj.zoomDescriptor));
      }

      dispatchData(setSpacesData({ spaces: obj.spaces, filteredSpaces: obj.filteredSpaces }));
      dispatchData(setFloorId(obj.floorId));
    };
    const handleInitOptions = (obj: any) => {
      dispatchOptions(updateOptionState(obj));
    };
    const handleToggleView = (obj: any) => {
      if (fes.getViewer()?.getViewer()) {
        setLayers(obj);
      }
    };
    const handleSetFloorMarkers = (markers: any) => {
      if (markers) {
        dispatchData(setDrawableMarkers(markers));
      }
    };
    const handleSetFocusedSpace = ({ id }: any) => {
      setFocusedSpaceId(id);
    };
    const handleSetSelectedPerson = ({ person }: any) => {
      setSelectedPerson(person);
    };
    const handleSetAvailabilityDates = ({ startTime, endTime }: any) => {
      setStartTime(startTime);
      setEndTime(endTime);
    };
    const handleSetZoomDescriptorLoadingState = (isLoading: boolean) => {
      setZoomDescriptorLoading(isLoading);
    };

    fes.on(FES_EVENTS.INIT_DATA, handleInitData);
    fes.on(FES_EVENTS.INIT_OPTIONS, handleInitOptions);
    fes.on(FES_EVENTS.TOGGLE_VIEW, handleToggleView);
    fes.on(FES_EVENTS.SET_FLOOR_MARKERS, handleSetFloorMarkers);
    fes.on(FES_EVENTS.SET_FOCUSED_SPACE, handleSetFocusedSpace);
    fes.on(FES_EVENTS.SET_SELECTED_PERSON, handleSetSelectedPerson);
    fes.on(FES_EVENTS.SET_AVAILABILITY_DATE, handleSetAvailabilityDates);
    fes.on(FES_EVENTS.SET_ZOOM_DESCRIPTOR_LOADING_STATE, handleSetZoomDescriptorLoadingState);

    // !IMPORTANT this needs to be called before setting the fes listeners
    // * triggers the onMessage on the client side and the client sends the data back
    fes.trigger(FES_EVENTS.INIT, fes);

    return () => {
      fes.off(FES_EVENTS.INIT_DATA, handleInitData);
      fes.off(FES_EVENTS.INIT_OPTIONS, handleInitOptions);
      fes.off(FES_EVENTS.TOGGLE_VIEW, handleToggleView);
      fes.off(FES_EVENTS.SET_FLOOR_MARKERS, handleSetFloorMarkers);
      fes.off(FES_EVENTS.SET_FOCUSED_SPACE, handleSetFocusedSpace);
      fes.off(FES_EVENTS.SET_SELECTED_PERSON, handleSetSelectedPerson);
      fes.off(FES_EVENTS.SET_AVAILABILITY_DATE, handleSetAvailabilityDates);
      fes.off(FES_EVENTS.SET_ZOOM_DESCRIPTOR_LOADING_STATE, handleSetZoomDescriptorLoadingState);
      // this is only for mobile, we need to reset isFloorplanLoading state
      // for correct listeners function
      fes.trigger(FES_EVENTS.RE_INIT, fes);
    };
  }, [dispatchData, dispatchOptions, fes, floorId]);

  // without descriptor floorplan doesn't need to be rendered
  if (!zoomDescriptor || zoomDescriptorLoading) {
    return <Spinner />;
  }

  return (
    <ZoomDescriptorViewer zoomDescriptor={memoizedZoomDescriptor} viewerOptions={memoizedOptions}>
      <TeamReservationSelectionStateProvider>
        {!spaces || !filteredSpaces ? (
          <SpacesLoader>
            <Spinner />
          </SpacesLoader>
        ) : (
          <ShapesContainer
            data={{
              spaces,
              filteredSpaces,
              focusedSpaceId,
              selectedPerson,
              drawableMarkers: drawableMarkers ?? [],
              kioskLocation,
              hidePeopleData,
              layers,
              startTime,
              endTime,
            }}
            options={options}
          />
        )}
      </TeamReservationSelectionStateProvider>
    </ZoomDescriptorViewer>
  );
};
