import styled from 'styled-components';
import { COMMON_STYLES, Z_INDEX } from '@engage-web/constants';

const Wrapper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX.DRAWER};
  left: ${COMMON_STYLES.SPACE}px;
  bottom: ${COMMON_STYLES.SPACE}px;
  width: 100%;
  max-width: 400px;
  height: max-content;
  max-height: calc(100vh - 120px); // temporary fix
  background-color: #ffffff;
  box-shadow: 0 40px 60px rgba(10, 38, 50, 0.15);
  border-radius: 6px;
  overflow: auto;
  transition: width, height, min-width, min-height, bottom, left 350ms ease-in-out;
  @media (max-width: 450px) {
    bottom: 0;
    left: 0;
  }
  @media (min-width: 451px) and (max-width: 800px) {
    bottom: 20px;
    left: 20px;
  }

  @media (max-height: 900px) {
    max-height: calc(100vh - 70px);
  }
`;

type ContentOuterWrapperProps = {
  $minimized: boolean;
};

const ContentOuterWrapper = styled.div<ContentOuterWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  height: max-content;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  min-width: 100%;
  max-height: 100%;
  flex: 1;
`;

const ResizeHandle = styled.button`
  padding-top: 8px;
  padding-left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &::before {
    content: ' ';
    display: block;
    width: 36px;
    height: 5px;
    background-color: ${({ theme }) => theme.typo2};
    opacity: 0.5;
    border-radius: 5px;
  }

  &:hover::before {
    background-color: ${({ theme }) => theme.color9};
  }

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export { Wrapper, ContentOuterWrapper, ContentWrapper, ResizeHandle };
