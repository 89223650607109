import { roundTimeToInterval } from '@engage-shared/utils/dates';
import { RoundingDirection } from '@engage-shared/constants/roundingDirection';
import { addHours } from 'date-fns';
import { TenantId } from '@engage-shared/api/tenant/interfaces';
import { useTeamBookings } from './useTeamBookings';
import { ISODateString, Nullable } from '@engage-shared/utils/types';
import { UseQueryOptions } from 'react-query';
import { TeamBooking } from '@engage-shared/api/types';

const getRoundedISOTime = (time: Date): ISODateString => {
  const roundedTime = roundTimeToInterval(time, 15, RoundingDirection.DOWN);
  return roundedTime.toISOString() as ISODateString;
};

type UseHasTeamBookingsInIntervalParams = {
  tenantId: TenantId;
  teamId: number;
  filterDateStart?: Nullable<Date>;
  options?: UseQueryOptions<TeamBooking[]>;
};
type UseHasTeamBookingsInInterval = (params: UseHasTeamBookingsInIntervalParams) => boolean;

export const useHasTeamBookingsInInterval: UseHasTeamBookingsInInterval = ({
  tenantId,
  teamId,
  filterDateStart,
  options = {},
}) => {
  const startTime = filterDateStart ? new Date(filterDateStart) : new Date();
  const roundedStartTime = getRoundedISOTime(startTime);
  const roundedEndTime = getRoundedISOTime(addHours(startTime, 1));

  const teamBookingQuery = useTeamBookings({
    tenantId,
    id: teamId,
    startTime: roundedStartTime,
    endTime: roundedEndTime,
    options,
  });

  const teamBooking = teamBookingQuery?.data ?? [];

  return !!teamBooking.length;
};
