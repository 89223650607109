import { fetchFloorLocation, FloorLocation } from '../../api/floors';
import { fetchBuilding, fetchBuildingFloors } from '../../api/buildings';
import { Building } from '../../api/buildings/interfaces';
import { Floor, LocationType } from '../../api/floors/interfaces';
import { BuildingLocation, UserLocation } from '../../utils/location/interfaces';
import { Nullable } from '../../utils/types';
import { UserLocationData } from '../../api/users/interfaces';
import { getLogger } from '../../utils/logger';
import { FloorData } from '../../api/floors/interfaces';

type MapLocationBuildingData = (building: Building) => BuildingLocation;

export const mapLocationBuildingData: MapLocationBuildingData = (building: Building) => ({
  id: building.id,
  name: building.name,
  timeZone: building.timeZone,
  latitude: building.latitude,
  longitude: building.longitude,
});

type MapLocationFloorData = (floor: Floor) => FloorLocation;
export const mapLocationFloorData: MapLocationFloorData = (floor: Floor) => ({
  id: floor.id,
  name: floor.name,
  type: LocationType.Floor,
});

type FetchFloorBuilding = (floorId: number) => Promise<Nullable<BuildingLocation>>;
/**
 * Fetch floor building
 * @param floorId
 * @returns {Promise<MapLocationBuildingData | null>}
 */
const getFloorBuilding: FetchFloorBuilding = async floorId => {
  let buildingLocation = null;
  const regionsBranch = await fetchFloorLocation(floorId);
  if (regionsBranch) {
    // Last element is the building info
    const floorBuilding = regionsBranch.pop();
    if (floorBuilding) {
      const { id: buildingId } = floorBuilding;
      const building = await fetchBuilding(buildingId);
      if (building) {
        buildingLocation = mapLocationBuildingData(building);
      }
    }
  }
  return buildingLocation;
};

type GetLocation = (floorId: number) => Promise<Nullable<UserLocation>>;
/**
 * Fetch user floor location.
 * @param floorId
 * @returns {Promise<Location | null>}
 */
export const getUserFloorLocation: GetLocation = async floorId => {
  let location = null;
  try {
    const building = await getFloorBuilding(floorId);
    let floorData: FloorData[] = [];
    if (building) {
      floorData = await fetchBuildingFloors(building.id);
    }
    const [newFloor] = floorData?.filter(item => item.id === floorId) || [];
    if (building && newFloor) {
      location = {
        building,
        floor: {
          id: newFloor.id,
          name: newFloor.name,
          type: LocationType.Floor,
        },
      };
    }
  } catch (error) {
    // the error is already log by API
    getLogger().warn('Floor location cannot be fetched');
  }
  return location;
};

export type GetUserDefaultLocationParams = {
  location: UserLocationData[];
};

type GetUserDefaultLocation = (
  param: GetUserDefaultLocationParams,
) => Promise<Nullable<UserLocation>>;

/**
 * Fetches the users default location.
 * The user can be assigned to multiple locations.
 * We use the first location in the list of locations.
 * @returns {Promise<UserLocation | null} Users default location
 */
export const getUserDefaultLocation: GetUserDefaultLocation = async ({ location }) => {
  let defaultLocation = null;
  const [userFirstLocation] = location;
  if (userFirstLocation?.floorId) {
    const userFloorLocation = await getUserFloorLocation(userFirstLocation.floorId);
    if (userFloorLocation) {
      defaultLocation = userFloorLocation;
    }
  }

  return defaultLocation;
};
