import React, { memo, ReactElement, MouseEvent } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Avatar, EngageIcons } from '@engage-web/components/base';
import { fontClassesHeights, fontTagHeights } from '@engage-web/styles';
import {
  Actions,
  ACTIONS_VERTICAL_MARGIN,
  BUTTON_VERTICAL_PADDING,
  CheckInMessage,
  Content,
  ContentWrapper,
  Details,
  DetailsAction,
  LIST_ITEM_PADDING_VERTICAL,
  Message,
  Title,
  Wrapper,
} from './styled';
import { Nullable } from '@engage-shared/utils/types';

interface NotificationProps {
  icon: Nullable<EngageIcons | (() => ReactElement)>;
  title: string;
  localTime: string;
  location: string;
  checkInStatus: string;
  statusMessage: string;
  isRead: boolean;
  onDetails: (evt: MouseEvent) => void;
}

const Notification = ({
  icon,
  title,
  localTime,
  location,
  checkInStatus,
  statusMessage,
  isRead,
  onDetails,
}: NotificationProps) => {
  const { t } = useTranslation();
  const { color2 } = useTheme();
  const detailsStr = t('layout.notifications.reservationDetails');

  return (
    <Wrapper $isRead={isRead}>
      <Content>
        <Avatar icon={icon} size="small" iconStyle={{ color: color2, opacity: 0.5 }} />
        <ContentWrapper>
          <Details>
            <Title>{title}</Title>
            <Message>{localTime}</Message>
            <Message id={`${location}-${localTime}`}>{location}</Message>
          </Details>
          <Actions>
            <DetailsAction>
              <button
                id={`${detailsStr}-${location}-${localTime}`}
                onClick={onDetails}
                aria-labelledby={`${detailsStr}-${location}-${localTime} ${location}-${localTime}`}
              >
                {detailsStr}
              </button>
            </DetailsAction>
          </Actions>
          <CheckInMessage $status={checkInStatus}>{statusMessage}</CheckInMessage>
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
};

// @ts-ignore
const fontTagHeight = fontTagHeights.h6.withMargins * 2;
// @ts-ignore
const fontClassesHeight = fontClassesHeights.btn.withMargins;

export const getSize = () =>
  // list vertical padding
  LIST_ITEM_PADDING_VERTICAL * 2 +
  // title
  fontTagHeight +
  // localTime + location
  fontClassesHeight * 2 +
  // actions
  ACTIONS_VERTICAL_MARGIN +
  // details action
  BUTTON_VERTICAL_PADDING * 2 +
  fontClassesHeight +
  // status message
  ACTIONS_VERTICAL_MARGIN * 2 +
  fontClassesHeight;

export default memo(Notification);
