import { addHours, differenceInDays, differenceInHours } from 'date-fns';
import { Nullable } from '@engage-shared/utils/types';

export type FilterValues = {
  dateStart: Nullable<string | Date>;
  dateEnd: Nullable<string | Date>;
  allDay: boolean;
  selectedDates: Nullable<string | Date>[];
  spaceTypes: string[];
  spaceAttributes: string[];
  capacity: number;
};
/**
 * Calculation of applied spaces filters number
 * @param filters Object
 * @return {number} number of applied filters
 */
export const countAppliedSpaceFilters = (filters: FilterValues): number => {
  let appliedFiltersCount = 0;

  const { dateEnd, dateStart, selectedDates, spaceTypes, spaceAttributes, capacity, allDay } =
    filters;

  // Date end is counted as applied filter only if difference compared to start date is higher then 1 hour or 0 day
  // Because we have all day toggle in mobile we added checking for difference in days
  const startDate = dateStart ? new Date(dateStart) : new Date();
  const endDate = dateEnd ? new Date(dateEnd) : addHours(startDate, 1);

  if (differenceInHours(endDate, startDate) > 1 || differenceInDays(endDate, startDate) > 0) {
    appliedFiltersCount++;
  }

  // Selected dates appear only when allDay is true
  if (allDay && selectedDates.length > 1) {
    appliedFiltersCount++;
  }

  if (spaceTypes.length) {
    appliedFiltersCount += spaceTypes.length;
  }

  if (spaceAttributes.length) {
    appliedFiltersCount += spaceAttributes.length;
  }

  if (capacity > 1) {
    appliedFiltersCount++;
  }

  return appliedFiltersCount;
};
