export default {
  NO_LOCATION_BACKGROUND: 10,
  DRAWER: 12,
  FLOORPLAN_ERROR_PANE: 13,
  GRADIENT: 11,
  MASK: 100,
  MODAL: 100,
  HEADER: 101,
  KIOSK_HEADER: 105,
  MODALS: 102,
  DIALOG_OVERLAY: 103,
  DIALOG: 104,
};
