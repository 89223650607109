import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EngageIcons, Icon } from '@engage-web/components/base';
import { generateFloorPath, joinPaths } from '@engage-web/router/utils';
import { useAppParams } from '@engage-web/router/hooks';
import {
  GLOBAL_SEARCH_RESULT_ID,
  LanguageTypes,
  RESTRICT_TO_SEARCH_FILTER_ITEMS,
} from '@engage-web/constants';
import { filtersActions, userConfigSelectors } from '@engage-web/store';
import { Nullable } from '@engage-shared/utils/types';
import { RestrictToItem, RestrictToItemText } from './styled';

type RestrictToLinkProps = {
  /**
   * Current `restrictTo` value from `useRestrictTo` hook.
   */
  restrictTo: Nullable<'Desk' | 'Space' | 'Team' | 'Person'>;
  /**
   * `restrictTo` value of the link that is being rendered.
   */
  linkRestrictTo: 'Desk' | 'Space' | 'Team' | 'Person';
};

export function RestrictToLink({ restrictTo, linkRestrictTo }: RestrictToLinkProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useAppParams();

  const { iconName, text, path } = RESTRICT_TO_SEARCH_FILTER_ITEMS[linkRestrictTo];
  // restrict to filter will never be rendered if there is no floor selected
  const floorPath = generateFloorPath(params.floorId!);
  const absoluteTo = joinPaths(floorPath, path);
  const isActive = linkRestrictTo === restrictTo;
  // if the link is active we swap the path with the floorplan path to toggle it
  const to = isActive ? floorPath : absoluteTo;

  const handleClick = () => {
    dispatch(filtersActions.clearFilterValues(null));
  };

  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const frenchLocales = [LanguageTypes.fr, LanguageTypes.frFR, LanguageTypes.frCA];
  const isFrench = !!frenchLocales.find(lang => lang === locale);

  return (
    <RestrictToItem
      to={to}
      className={isActive ? 'active' : ''}
      onClick={handleClick}
      role="button"
      aria-expanded={isActive}
      aria-pressed={isActive}
      aria-controls={isActive ? `${GLOBAL_SEARCH_RESULT_ID}_${linkRestrictTo}` : undefined}
    >
      <Icon size={20} name={iconName as EngageIcons} />
      <RestrictToItemText $useSmallFont={isFrench}>{t(text)}</RestrictToItemText>
    </RestrictToItem>
  );
}
