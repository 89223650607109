import { DATE_TIME_PICKER_VARIANT, DOCUMENT_TITLE_SEPARATOR } from '../../../constants';
import { getBuildingAndFloorNames, LocationName } from '../../helpers';
import { TFunction } from 'react-i18next';

interface TitleParts {
  component: string | undefined;
  location: string | undefined;
  base: string | undefined;
}

export const getCurrentTitleValues = (title: string): TitleParts => {
  const parts: (string | undefined)[] = title.split(DOCUMENT_TITLE_SEPARATOR);

  if (parts.length === 1) parts.unshift(undefined, undefined);
  if (parts.length === 2) parts.unshift(undefined);

  return {
    component: parts[0],
    location: parts[1],
    base: parts[2],
  };
};

export const getTitleStringFromValues = (titleParts: TitleParts) =>
  Object.values(titleParts)
    .filter(part => !!part)
    .join(DOCUMENT_TITLE_SEPARATOR);

export const getLocationTitle = ({
  building,
  floor,
  t,
}: {
  building: LocationName;
  floor: LocationName;
  t: TFunction;
}) => {
  const { buildingName, floorName } = getBuildingAndFloorNames(building, floor, t);

  return `${buildingName} ${floorName}`.trim();
};

export const getInfoCardTitle = ({ title, subtitle }: { title: string; subtitle: string }) => {
  let result = title;
  if (subtitle) result += ` ${subtitle}`;
  return result;
};

export const getModalDateTimePickerTitle = (variant: string, t: TFunction) => {
  if (variant === DATE_TIME_PICKER_VARIANT.INTERVAL_START)
    return t('documentTitles.reservationStartDateSelector');
  if (variant === DATE_TIME_PICKER_VARIANT.INTERVAL_END)
    return t('documentTitles.reservationEndDateSelector');
  return t('documentTitles.timelineSelector');
};
