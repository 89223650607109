import { useCallback, useEffect } from 'react';
import { floorplanSelectors, uiActions, uiSelectors } from '@engage-web/store';
import { useDispatch, useSelector } from 'react-redux';
import { useFESContext } from '@floorplan/fes';

export const useOnClickOutsideHandlers = () => {
  const fes = useFESContext();
  const dispatch = useDispatch();
  const isDrawerMoreMenuVisible = useSelector(uiSelectors.getDrawerMenuVisibility);
  const floorplanIsLoaded = useSelector(floorplanSelectors.getIsLoaded);

  // Here we should add handlers provided to useOnClickOutside hook
  // because click on floorplan are not propagated and can be caught outside
  const callOnClickOutsideHandlers = useCallback(() => {
    if (isDrawerMoreMenuVisible) {
      dispatch(uiActions.setDrawerMenuVisibility(false));
    }
  }, [dispatch, isDrawerMoreMenuVisible]);

  useEffect(() => {
    if (!floorplanIsLoaded) return () => {};

    const viewer = fes.getViewer();

    if (!viewer) return () => {};

    const onCanvasClick = () => {
      callOnClickOutsideHandlers();
    };

    viewer.addHandler('canvas-click', onCanvasClick);
    return () => {
      viewer.removeHandler('canvas-click', onCanvasClick);
    };
  }, [callOnClickOutsideHandlers, fes, floorplanIsLoaded]);
};
