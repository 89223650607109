import { SearchInput } from '@engage-web/components/base/SearchInput';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { teamMembersSearchActions, teamMembersSearchSelectors } from '@engage-web/store';
import { SearchInputWrapper } from './TeamMembers.style';

export function TeamMembersSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultValue = useSelector(teamMembersSearchSelectors.getTeamMembersSearchValue);

  const searchPlaceholderText = t('layout.teamMembersList.findPeople');

  const setSearchValue = useCallback(
    (text: string) => dispatch(teamMembersSearchActions.setSearchValue(text)),
    [dispatch],
  );

  const clearSearchValue = useCallback(
    () => dispatch(teamMembersSearchActions.clearSearchValue()),
    [dispatch],
  );

  useEffect(
    () => () => {
      clearSearchValue();
    },
    [clearSearchValue],
  );

  return (
    <SearchInputWrapper>
      <SearchInput
        defaultValue={defaultValue}
        setSearchValue={setSearchValue}
        clearSearchValue={clearSearchValue}
        searchPlaceholderText={searchPlaceholderText}
      />
    </SearchInputWrapper>
  );
}
