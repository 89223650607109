import styled from 'styled-components';

const HEIGHT = '36px';
const MIN_WIDTH = '36px';
const MAX_WIDTH = '46px';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BookedWrapper = styled(Wrapper)`
  width: 100%;
  background-color: ${props => props.theme.color9};
  padding: 28px 10px 28px 45px;
  cursor: pointer;
`;

export const BookForWrapper = styled(Wrapper)`
  padding-bottom: 30px;
`;

export const BookedTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.typo1};
`;

export const BookForTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.typo2};
`;

export const BookedTeamWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${HEIGHT};
  cursor: pointer;
  overflow: hidden;
  max-width: 70%;
`;

export const BookForTeamWrapper = styled(BookedTeamWrapper)`
  && {
    position: relative;
    background-color: ${({ theme }) => theme.color9};
    border-radius: 20px;
    margin-left: 5px;
  }
`;

export const BookedTeamName = styled.div`
  color: ${props => props.theme.typo1};
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const BookForTeamName = styled.div`
  color: ${({ theme }) => theme.typo1};
  font-size: 14px;
  margin-left: 5px;
  padding: 10px ${MAX_WIDTH} 10px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const BookedTeamNumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.typo1};
  background-color: rgba(0, 0, 0, 0.45);
  min-width: ${MIN_WIDTH};
  max-width: ${MAX_WIDTH};
  height: ${HEIGHT};
  border-radius: ${MAX_WIDTH};
  margin: 0 5px;
`;

export const BookForTeamNumberWrapper = styled(BookedTeamNumberWrapper)`
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
`;

export const TeamNumber = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.typo1};
  padding: 0 5px;
  justify-content: center;
  align-items: center;
`;
