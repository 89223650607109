import { useTranslation } from 'react-i18next';
import { inAppNotificationsActions } from '@engage-web/store';
import { useDispatch } from 'react-redux';
import { FavouriteMutationBody } from '@engage-shared/api';

export type SpaceTypes = 'Desk' | 'Space' | 'Person' | 'Team';

const SpaceTypeToTranslation: Partial<Record<SpaceTypes, string>> = {
  Desk: 'favouriteReachedLimitDesks',
  Person: 'favouriteReachedLimitPeople',
  Space: 'favouriteReachedLimitSpaces',
};

export const useFavouritesMutationErrorHandler = (restrictTo: SpaceTypes | null) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onError = (_error: any, variables: FavouriteMutationBody) => {
    let message = '';

    // 403 status means favourites limit reached
    if (_error.response?.status === 403 && restrictTo) {
      const translationKey = SpaceTypeToTranslation[restrictTo];
      message = t(`layout.notifications.${translationKey}`);
    } else {
      message = t(
        `layout.notifications.${
          variables.isCurrentlyFavourite ? 'favouriteUnselectFailure' : 'favouriteSelectFailure'
        }`,
      );
    }

    dispatch(inAppNotificationsActions.addErrorNotification({ message }));
  };

  return { onError };
};
