import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRegions, Region as RegionType } from '@engage-shared/api';
import { tenantSelectors, floorplanActions } from '@engage-web/store';
import DrawerHeader from '@engage-web/components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { DrawerCardContent } from '@engage-web/components/scenes/drawer/DrawerCardContent';
import { UseQueryResult } from 'react-query';
import { LocationSelectorWrapper } from './components/styled';
import Region from './components/Region';
import RegionSkeleton from './components/RegionSkeleton';
import useLocationSelector from './components/useLocationSelector';

const LocationSelector = () => {
  const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, isLoading } = useRegions({
    tenantId,
    shouldSortRegions: true,
  }) as UseQueryResult<RegionType[]>;
  const [locationState, locationDispatch] = useLocationSelector();

  const hasLocation = !!(building && floor);
  const title = t('layout.locationSelector.title');
  const subtitle = t('layout.locationSelector.subtitle');
  const regions = data || [];

  useEffect(() => {
    if (hasLocation) {
      dispatch(floorplanActions.setShowOverlay(true));
    }

    return () => {
      dispatch(floorplanActions.setShowOverlay(false));
    };
  }, [dispatch, hasLocation]);

  if (isLoading) {
    return (
      <LocationSelectorWrapper>
        <RegionSkeleton />
        <RegionSkeleton />
        <RegionSkeleton />
        <RegionSkeleton />
      </LocationSelectorWrapper>
    );
  }

  return (
    <>
      {hasLocation && (
        <DrawerHeader
          title={title}
          subtitle={subtitle}
          hasButton={hasLocation}
          closeIconA11yLabel="accessibilityLabels.closeLayout_locationSelector"
          isCloseIcon
        />
      )}
      <DrawerCardContent>
        <LocationSelectorWrapper data-testid="location-selector">
          {regions.map(region => (
            <Region
              key={region.id}
              region={region}
              locationState={locationState}
              locationDispatch={locationDispatch}
            />
          ))}
        </LocationSelectorWrapper>
      </DrawerCardContent>
    </>
  );
};

export default memo(LocationSelector);
