import { Nullable } from '@engage-shared/utils/types';

const AUTH_TOKEN = '@engage-auth-token@';
const REFRESH_TOKEN = '@engage-refresh-token@';

const setTokens = ({
  token,
  refreshToken,
}: {
  token: string;
  refreshToken: string;
}): Promise<void> => {
  localStorage.setItem(AUTH_TOKEN, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
  return Promise.resolve();
};

const getTokens = (): Promise<{ token: Nullable<string>; refreshToken: Nullable<string> }> => {
  const token = localStorage.getItem(AUTH_TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return Promise.resolve({ token, refreshToken });
};

const removeTokens = (): Promise<void> => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  return Promise.resolve();
};

/**
 * The user is considered to be authenticated if a token and refresh token are persisted.
 */
const hasTokens = (): boolean => {
  const token = localStorage.getItem(AUTH_TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return !!token && !!refreshToken;
};

export { setTokens, getTokens, removeTokens, hasTokens };
