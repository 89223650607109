import React, { CSSProperties, FC, memo, MemoExoticComponent, useCallback } from 'react';
import DeskItem from '@engage-web/components/layouts/GlobalSearch/DeskItem';
import SelectableAvatar from '@engage-web/components/base/Avatar/SelectableAvatar';
import { SelectableItemWrapper } from '@engage-web/components/layouts/Team/TeamMembers/TeamMembers.style';
import { AvatarImageProps } from '@engage-web/components/base/Avatar/AvatarImage';
import { SearchResponseData } from '@engage-shared/api/search';

export type SelectableDeskItemProps = {
  item: SearchResponseData;
  overrideText?: {
    title: string;
    subtitle: string;
  };
  onPress?: (item: any) => void;
  showBadge?: boolean;
  style?: CSSProperties;
  onSizeFound?: (size: DOMRect, index: number) => void;
  index: number;
  as?: 'div';
  isSelected: boolean;
};

const SelectableDeskItem = ({ isSelected, item, style, ...rest }: SelectableDeskItemProps) => {
  const renderAvatar = useCallback(
    (avatarProps: AvatarImageProps) => (
      <SelectableAvatar a11LabelSuffix={item.name} isSelected={isSelected} {...avatarProps} />
    ),
    [isSelected, item.name],
  );

  return (
    <SelectableItemWrapper style={style} $isSelected={isSelected}>
      <DeskItem item={item} {...rest} renderAvatar={renderAvatar} showBadge={!isSelected} />
    </SelectableItemWrapper>
  );
};

const MemoSelectableDeskItem = memo(SelectableDeskItem) as MemoExoticComponent<
  FC<SelectableDeskItemProps>
> & {
  getSize: () => number;
};

MemoSelectableDeskItem.getSize = DeskItem.getSize;

export default MemoSelectableDeskItem;
