import { SpaceItem, SpaceItemAttributes } from '@engage-shared/api/spaces/interfaces';

type MapSpaceItemAttributes = (spaceItem: SpaceItem) => SpaceItemAttributes;

export const mapSpaceItemAttributes: MapSpaceItemAttributes = spaceItem => ({
  description: spaceItem.spaceType,
  type: spaceItem.isDesk ? 'Desk' : 'Space',
  capacity: spaceItem.seats,
  level: spaceItem.floorName,
  building: spaceItem.buildingName,
  available: spaceItem.availability,
});
