import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useSpaceImage } from '../../../../../api/queries/useSpaceImage';
import { ViewableAvatar, Capacity } from '../../../../base';
import { useSetPageTitle } from '../../../../../utils/hooks';
import {
  TextWrapper,
  TitleWrapper,
  CapacityWrapper,
  StyledDescription,
  Wrapper,
  StyledTitle,
} from './styled';
import SpaceCardStatus from './SpaceCardStatus';
import { RESTRICT_TO_VALUES } from '@engage-shared/constants';
import { Theme } from '@engage-shared/theme';

type SpaceCardProps = {
  id: number;
  name: string;
  description: string;
  type: 'Desk' | 'Space';
  isReserved: boolean;
  downloadableImageUrl: string;
  imageUrl: string;
  status: string;
  capacity: number;
};

function SpaceCard({
  id,
  name,
  description,
  type = RESTRICT_TO_VALUES.DESK,
  isReserved,
  downloadableImageUrl,
  imageUrl,
  status,
  capacity,
}: SpaceCardProps) {
  const { t } = useTranslation();
  const { gray4, color5 } = useTheme() as Theme;
  const isDesk = type === RESTRICT_TO_VALUES.DESK;
  const hasCapacity = !isDesk && Boolean(capacity);
  const title = name ?? id ?? '';
  const iconName = isDesk ? 'desk' : 'space';
  const iconStyle = {
    color: gray4,
  };

  const spaceImageQuery = useSpaceImage({
    spaceId: id,
    imageSrc: downloadableImageUrl,
    options: {
      // only start img download if the ready-to-use image URL is not provided
      enabled: !imageUrl,
    },
  });

  const downloadImageUrl = spaceImageQuery?.data;

  const documentTitle = type
    ? t(`documentTitles.${type.toLowerCase()}Card`, { name: title })
    : 'Card';

  useSetPageTitle(documentTitle);

  return (
    <Wrapper>
      <ViewableAvatar
        source={imageUrl || downloadImageUrl}
        size="giant"
        icon={iconName}
        iconStyle={iconStyle}
        alt={t(`accessibilityLabels.${type.toLowerCase()}Image`)}
        style={{
          borderWidth: 2,
          borderColor: color5,
          borderStyle: 'solid',
          background: 'transparent',
        }}
      />
      <SpaceCardStatus status={status} />
      <TextWrapper $isReserved={isReserved}>
        <TitleWrapper>
          <StyledTitle data-testid="space-title">{title}</StyledTitle>
          {hasCapacity && (
            <CapacityWrapper>
              <Capacity capacity={capacity} fontSize={13} iconSize={12} opacity={0.5} />
            </CapacityWrapper>
          )}
        </TitleWrapper>
        <StyledDescription>{description}</StyledDescription>
      </TextWrapper>
    </Wrapper>
  );
}

export default SpaceCard;
