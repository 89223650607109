import { ToggleSwitch } from '@engage-web/components/base/ToggleSwitch';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersSelectors, filtersActions } from '@engage-web/store';
import { useTranslation } from 'react-i18next';
import { PERSON_OF_INTEREST, PERSON_OF_INTEREST_TRANSLATION_MAPPING } from '@engage-web/constants';
import { GlobalSearchClear, SelectButton } from '@engage-web/components/base';
import { FocusId, useCloseFilters, useFocus } from '@engage-web/utils/hooks';
import { initialPersonFilterValues } from '@engage-web/store/filters';
import { PersonFilterWrapper, Wrapper, TitleWrapper } from './styled';
import { countAppliedPersonFilters } from '@engage-shared/utils';

const PersonFilter = () => {
  const personFilters = useSelector(filtersSelectors.getPersonFilters);
  const [filters, setFilters] = useState(personFilters);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeFilters = useCloseFilters();

  const filtersChanged = countAppliedPersonFilters(filters) > 0;

  const onShowClick = () => {
    dispatch(filtersActions.setPersonFilters(filters));
    closeFilters();
  };

  const onTogglePersonType = (pofType: string) => (value: boolean) => {
    setFilters((oldFilters: Record<string, string>) => ({
      ...oldFilters,
      [pofType]: value,
    }));
  };

  const onClearPress = useCallback(() => {
    setFilters(initialPersonFilterValues);
  }, []);

  useFocus({ returnFocusTo: FocusId.GlobalSearchFilterButton });

  return (
    <Wrapper data-testid="PersonFilterComponent">
      <PersonFilterWrapper>
        {Object.values(PERSON_OF_INTEREST).map(pof => (
          <TitleWrapper data-testid={`titleWrapper-${pof}`} key={`PersonFilter_switch_${pof}`}>
            {t(PERSON_OF_INTEREST_TRANSLATION_MAPPING[pof])}
            <ToggleSwitch
              onChange={onTogglePersonType(pof)}
              checked={filters[pof]}
              data-testid={`personFilterSwitch-${pof}`}
            />
          </TitleWrapper>
        ))}
      </PersonFilterWrapper>
      <GlobalSearchClear isFilterChanged={filtersChanged} onPress={onClearPress} />
      <SelectButton data-testid="firstRespondersShowButton" onClick={onShowClick}>
        {t('layout.personFilter.showResults')}
      </SelectButton>
    </Wrapper>
  );
};

export default memo(PersonFilter);
