import React, { PropsWithChildren } from 'react';
import { useDrawerContext } from '@engage-web/components/scenes';

type DrawerCardProps = PropsWithChildren<object>;

/**
 * Renders drawer's card content if drawer is not minimized.
 * Otherwise, renders nothing.
 */
export function DrawerCardContent({ children }: DrawerCardProps) {
  const { minimized } = useDrawerContext();
  return <>{minimized ? null : children}</>;
}
