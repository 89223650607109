// @ts-nocheck

import React from 'react';
import RSelect, { GroupBase, Props } from 'react-select';
import MenuList from './MenuList';
import { Wrapper } from './Select.style';
import Option from './Option';

type SelectProps<
  Option,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, isMulti, Group> & {
  id: string;
  focusLabel?: string;
};

const Select = <
  Option,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  id,
  focusLabel = '',
  ...selectProps
}: SelectProps<Option, isMulti, Group>) => {
  const [ariaFocusMessage, setAriaFocusMessage] = React.useState(focusLabel);
  const [keyDown, setKeyDown] = React.useState(false);

  const onFocus = ({ focused }: any) => {
    const msg = `${focusLabel} ${focused.label}`;
    setAriaFocusMessage(msg);
    return msg;
  };

  React.useEffect(() => {
    const handleMouseDown = () => {
      setKeyDown(false);
    };
    const handleKeyDown = () => {
      setKeyDown(true);
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Wrapper className={keyDown ? 'keyDown' : ''}>
      <RSelect
        aria-label={ariaFocusMessage}
        ariaLiveMessages={{
          onFocus,
        }}
        inputId={id}
        components={{ Option, MenuList }}
        {...selectProps}
      />
    </Wrapper>
  );
};

export default Select;
