import React, { memo } from 'react';
import { useConfig, useSetPageTitle } from '@engage-web/utils/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { navigationActions } from '@engage-web/store';
import { ClientLogo, MarkDownText } from '@engage-web/components/base';
import { useTheme } from 'styled-components';
import {
  Container,
  LogoContainer,
  Title,
  MessageContainer,
  ButtonContainer,
  ConfirmButtonWrapper,
  ConfirmButton,
  ConfirmButtonText,
  CancelButton,
} from './styled';

type HealthDeclarationProps = { onConfirm: () => void };

const HealthDeclaration = ({ onConfirm: onConfirmCallback = () => {} }: HealthDeclarationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { healthDeclaration } = useConfig();
  const title = t('layout.healthDeclaration.title');
  const cancelText = t('layout.healthDeclaration.cancel');
  const confirmText = t('layout.healthDeclaration.confirm');

  useSetPageTitle(t('documentTitles.healthDeclaration'));

  const onCancel = () => {
    dispatch(navigationActions.clearModal());
  };
  const onConfirm = () => {
    onConfirmCallback();
    dispatch(navigationActions.clearModal());
  };

  // @ts-ignore
  const { typo2 } = useTheme();
  const messageStyle = {
    fontSize: '16px',
    color: typo2,
  };

  return (
    <Container data-testid="health-declaration-container">
      <LogoContainer>
        <ClientLogo />
      </LogoContainer>
      <Title>{title}</Title>
      <MessageContainer>
        <MarkDownText style={messageStyle}>{healthDeclaration}</MarkDownText>
      </MessageContainer>
      <ButtonContainer>
        <CancelButton data-testid="health-declaration-cancel-button" onClick={onCancel}>
          {cancelText}
        </CancelButton>

        <ConfirmButtonWrapper>
          <ConfirmButton data-testid="health-declaration-confirm-button" onClick={onConfirm}>
            <ConfirmButtonText>{confirmText}</ConfirmButtonText>
          </ConfirmButton>
        </ConfirmButtonWrapper>
      </ButtonContainer>
    </Container>
  );
};

export default memo(HealthDeclaration);
