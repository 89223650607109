import * as React from 'react';

import { FilteredSpaces, Space } from '@floorplan/api';
import { Shapes } from './Shapes';
import {
  Labels,
  NonOccupantPoints,
  OccupantPoints,
  Rooms,
  SelectedSpaces,
  Special,
  SVLivePersonLocation,
  TeamReservation,
  Zones,
} from './elements';
import { DEFAULT_FLOORPLAN_SCALE, defaultFloorplanOptions } from '../../constants';
import { EventInfo, FloorplanOptions, Layers, PointXY } from '../../types';
import { SpaceType } from '../../constants/spaceTypes';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { getSpaceForID, isTeamReservationsEnabled, panToShape } from '../../utils';
import { DrawableFloorMarker } from '@engage-shared/api/floors/interfaces';
import { RemoteMarkerIcon } from '../../components/Markers/RemoteMarkerIcon';
import { HereMarker, TeamReservationDragSelection } from '../../components';
import { useHeatmap, useShowLabels, useTeamReservationSelectionState } from '../../hooks';
import { useShowSpace } from '../../hooks/useShowSpace';
import { useResetOrientation } from '../../hooks/useResetOrientation';
import { useShowFocusedSpace } from '../../hooks/useShowFocusedSpace';
import { useEffect } from 'react';
import { TemporaryOccupantPoints } from './elements/TemporaryOccupantPoints';

interface ShapesContainerProps {
  data: {
    spaces: Space[];
    filteredSpaces: FilteredSpaces;
    drawableMarkers: DrawableFloorMarker[];
    focusedSpaceId: number | null | number[];
    selectedPerson: any;
    kioskLocation: PointXY | null;
    hidePeopleData: boolean;
    layers: Layers;
    startTime: string;
    endTime: string;
  };
  options?: FloorplanOptions;
}

export const ShapesContainer = ({ data, options }: ShapesContainerProps) => {
  const { teamReservationSelectionMode } = useTeamReservationSelectionState();
  const { showLabels } = useShowLabels();
  const fes = useFESContext();

  const zoomTilesShown = options?.zoomTilesShown ?? defaultFloorplanOptions.zoomTilesShown;
  const reservedAlwaysEnabled =
    options?.reservedAlwaysEnabled ?? defaultFloorplanOptions.reservedAlwaysEnabled;
  const labelOptions = options?.labelOptions ?? defaultFloorplanOptions.labelOptions;
  const isTablet = !!options?.isTablet;

  const {
    filteredSpaces,
    spaces,
    focusedSpaceId,
    selectedPerson,
    drawableMarkers,
    kioskLocation,
    hidePeopleData,
    layers,
    startTime,
  } = data;
  const {
    zones,
    occupantPoints,
    nonOccupantPoints,
    rooms,
    occupantRoomPoints,
    temporaryOccupantRooms,
    temporaryOccupantPoints,
  } = filteredSpaces;

  const special = filteredSpaces.special as { [key in SpaceType]: Space[] };

  const [selectedSpaces, setSelectedSpaces] = React.useState<Space[]>([]);

  useEffect(() => {
    // hack to prevent the spaces from rendering with the incorrect scale.
    setTimeout(() => {
      fes.trigger(FES_EVENTS.READY_FOR_INTERACTION, true);
    }, 200);
    return () => {
      fes.trigger(FES_EVENTS.READY_FOR_INTERACTION, false);
    };
  }, [fes]);

  const setSelectedSpace = (space: Space | Space[] | null) => {
    const isCollection = space instanceof Array;

    let spaceList: Space[] = [];
    if (space) {
      spaceList = isCollection ? space : [space];
    }

    setSelectedSpaces(spaceList);
  };

  const _panToShape = (spaceId: number) => {
    const space = getSpaceForID(spaces, spaceId);
    const viewport = fes.getViewer()?.getViewer().viewport;
    if (space && viewport) {
      panToShape({ viewport, space, zoom: false, isTablet });
    }
  };

  const handleShapeClicked = (event: EventInfo) => {
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    const { space } = event;

    if (isTeamReservationsEnabled(teamReservationSelectionMode)) {
      return;
    }

    fes.trigger(FES_EVENTS.FLOORPLAN_CLICKED, event);

    setSelectedSpace(space);
    _panToShape(space.id);
  };

  const removeShapeSelected = (event?: EventInfo) => {
    if (event) {
      event.originalEvent.preventDefault();
      event.originalEvent.stopPropagation();
    }
    setSelectedSpace(null);
    //TODO: why do we need this call? It doesn't make any sense
    // fes.trigger(FES_EVENTS.SHOW_SPACE, null);
  };

  useHeatmap({ occupantPoints, nonOccupantPoints });
  useShowSpace({ removeShapeSelected, setSelectedSpace, spaces });
  useResetOrientation();
  // please keep it here, for now there are no mechanism to check if SHOW_SPACE handler already added,
  // so we need to call this hook AFTER useShowSpace for now
  useShowFocusedSpace(focusedSpaceId);

  const viewer = fes?.getViewer();

  useEffect(() => {
    const viewerDOM = viewer?.getViewer().canvas;
    // on ShapesContainer rerender we should remove all .floorplan-shapes and .floorplan-labels
    // they are Nodes added by Shapes component (see Shapes addViewerHandlers function)
    return () => {
      viewerDOM?.querySelectorAll('.floorplan-shapes').forEach(node => {
        node.remove();
      });
      viewerDOM?.querySelectorAll('.floorplan-labels').forEach(node => {
        node.remove();
      });
    };
  }, [viewer]);

  // TODO get scale from fes
  const scale = viewer?.getScale() ?? DEFAULT_FLOORPLAN_SCALE;
  const _showLabels = !hidePeopleData && showLabels;

  return (
    <div className="floorplan-shapes-container">
      <TeamReservationDragSelection
        viewer={viewer?.getViewer()}
        nonOccupantPoints={nonOccupantPoints}
      />
      <Shapes>
        <Zones
          zones={zones}
          scale={scale}
          zoomTilesShown={zoomTilesShown}
          onShapeClicked={removeShapeSelected}
        />
        <Rooms rooms={rooms} scale={scale} onShapeClicked={handleShapeClicked} />
        {drawableMarkers.map(marker => (
          <RemoteMarkerIcon key={marker.id} marker={marker} />
        ))}
      </Shapes>

      <Shapes>
        <OccupantPoints
          occupantPoints={occupantPoints}
          scale={scale}
          onShapeClicked={handleShapeClicked}
          layers={layers}
          reservedAlwaysEnabled={reservedAlwaysEnabled}
          startTime={startTime}
        />
        <TemporaryOccupantPoints
          temporaryOccupantPoints={temporaryOccupantPoints}
          scale={scale}
          onShapeClicked={handleShapeClicked}
          layers={layers}
          reservedAlwaysEnabled={reservedAlwaysEnabled}
          startTime={startTime}
        />
        <OccupantPoints
          occupantPoints={occupantRoomPoints}
          scale={scale}
          onShapeClicked={handleShapeClicked}
          layers={layers}
          reservedAlwaysEnabled={true}
          startTime={startTime}
        />
        <TemporaryOccupantPoints
          temporaryOccupantPoints={temporaryOccupantRooms}
          scale={scale}
          onShapeClicked={handleShapeClicked}
          layers={layers}
          reservedAlwaysEnabled={true}
          startTime={startTime}
        />
      </Shapes>

      <Shapes>
        <NonOccupantPoints
          nonOccupantPoints={nonOccupantPoints}
          scale={scale}
          onShapeClicked={handleShapeClicked}
        />
        <Special spaces={spaces} special={special} />
        <SVLivePersonLocation scale={scale} />
      </Shapes>
      <Shapes>
        <TeamReservation scale={scale} spaces={spaces} />
      </Shapes>
      <Shapes className="labels" show={_showLabels}>
        <Labels spaces={spaces} scale={scale} labelOptions={labelOptions} startTime={startTime} />
      </Shapes>
      <Shapes>
        <SelectedSpaces
          selectedSpaces={selectedSpaces}
          selectedPerson={selectedPerson}
          scale={scale}
          onShapeClicked={handleShapeClicked}
        />
        {kioskLocation && <HereMarker coordinate={kioskLocation} />}
      </Shapes>
    </div>
  );
};
