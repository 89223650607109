import {
  AvatarImageSize,
  AvatarImageVariant,
  AvatarStyle,
} from '@engage-web/components/base/Avatar/Avatar.types';
import { Nullable } from '@engage-shared/utils/types';
import { sizeDef, variantDef } from './config';

const getImageStyle = (
  variant: AvatarImageVariant,
  size: AvatarImageSize,
  style: Nullable<AvatarStyle> = {},
  placeHolderColor: string,
  errorStyles: Nullable<AvatarStyle> = {},
) => {
  const roundCoefficient =
    variantDef[variant] === undefined ? variantDef.circle : variantDef[variant];
  const dimensions = typeof size === 'number' ? size : sizeDef[size] || sizeDef.medium;
  const baseStyle = {
    width: dimensions,
    height: dimensions,
    backgroundColor: placeHolderColor,
    ...style,
    ...errorStyles,
  };
  const { height } = baseStyle;
  const borderRadius = roundCoefficient * height;
  return { borderRadius, ...baseStyle };
};

export { getImageStyle };
