import * as React from 'react';
import { ShapeProps } from './types';

type CalculateStyleProps = Omit<
  ShapeProps,
  'onShapeClicked' | 'pointSize' | 'scale' | 'isSpaceSelected'
>;

export const calculateStyle = <T>({
  isPoint,
  style,
  styleFunction,
  space,
  shape,
}: CalculateStyleProps) => {
  const colorStyle = {
    fill: null,
    stroke: null,
  };
  const requiredStyle = {
    pointerEvents: 'auto',
    strokeWidth: '7px',
    cursor: 'pointer',
  } as React.SVGAttributes<T>;
  const functionStyle = styleFunction ? styleFunction(space, shape) : {};
  const inlineStyle = style;

  if (!isPoint && shape?.holes) {
    requiredStyle.fillRule = 'evenodd';
  }

  let standardOpacityStyle = {};
  if (!shape?.isPolygon) {
    standardOpacityStyle = {
      fillOpacity: 1,
    };
  }

  return {
    ...colorStyle,
    ...requiredStyle,
    ...functionStyle,
    ...standardOpacityStyle,
    ...inlineStyle,
  } as T;
};
