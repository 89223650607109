import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  TeamBookingDetails,
  useTeamDeskReserve as useTeamDeskReserveMutation,
  checkInBooking,
} from '@engage-shared/api';
import { teamReservationsActions, tenantSelectors } from '@engage-web/store';
import { ConfirmationDialog } from '@engage-web/components/base';
import { FocusId, useDialog } from '@engage-web/utils/hooks';
import { useMutation } from 'react-query';
import { useRefreshTeamBooking } from '@engage-web/api/queries';
import { Nullable } from '@engage-shared/utils/types';

type UseTeamDeskCheckInArgs = {
  spaceId: number;
  teamBookingDetails: Nullable<TeamBookingDetails>;
};

/**
 * Reserve a desk and then check-in to that desk.
 */
export function useTeamDeskCheckIn({ spaceId, teamBookingDetails }: UseTeamDeskCheckInArgs) {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const { refresh: refreshTeamBooking } = useRefreshTeamBooking();

  const teamBookingId = teamBookingDetails?.meetingId;
  const deskBookingId = teamBookingDetails?.deskBookings.find(
    deskBooking => deskBooking.spaceId === spaceId,
  )?.id;

  const handleError = () => {
    openDialog(
      <ConfirmationDialog
        title={t('layout.floorplanError.errorOccurred')}
        message={t('layout.reserve.team.reserveDeskError')}
        key="team-desk-reserve-error-dialog"
        triggerId={FocusId.TeamDeskReserve}
        hasCancelButton={false}
      />,
    );
  };

  const handleSuccess = () => {
    dispatch(teamReservationsActions.clearTeamBookingId());

    openDialog(
      <ConfirmationDialog
        title={t('layout.bookingCard.checkInSuccessDialogTitle')}
        message={t('layout.bookingCard.checkInSuccessDialogMessage')}
        key="team-desk-reserve-success-dialog"
        triggerId={FocusId.TeamDeskReserve}
        hasCancelButton={false}
      />,
    );
  };

  const { mutate: onCheckInMutation, status: checkInMutationStatus } = useMutation(checkInBooking, {
    onSuccess: () => {
      handleSuccess();

      if (teamBookingId) {
        refreshTeamBooking({ id: teamBookingId });
      }
    },

    onError: handleError,
  });

  const startCheckIn = () =>
    onCheckInMutation({
      tenantId,
      spaceId,
      meetingId: deskBookingId as number,
    });

  const { mutate, ...mutation } = useTeamDeskReserveMutation({
    options: {
      onSuccess: () => startCheckIn(),
      onError: handleError,
    },
  });

  const reserveDesk = useCallback(
    () => (teamBookingId ? mutate({ tenantId, spaceId, teamBookingId }) : {}),
    [tenantId, spaceId, teamBookingId, mutate],
  );

  return {
    ...mutation,
    status: checkInMutationStatus,
    checkIn: reserveDesk,
  };
}
