import { FetchTeamMembersParams, TeamMember } from '@engage-shared/api/teams/interfaces';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { TEAM_MEMBERS_QUERY_KEY } from '@engage-shared/api/teams/constants';
import { fetchTeamMembers } from '../fetch';

type UseTeamMembersParams = FetchTeamMembersParams & {
  options?: UseQueryOptions<TeamMember[]>;
};
type UseTeamMembers = (params: UseTeamMembersParams) => UseQueryResult<TeamMember[]>;
export const useTeamMembers: UseTeamMembers = ({ tenantId, id, searchString, options = {} }) => {
  return useQuery<TeamMember[]>({
    queryKey: [TEAM_MEMBERS_QUERY_KEY, tenantId, id, searchString],
    queryFn: () => fetchTeamMembers({ tenantId, id, searchString }),
    enabled: !!tenantId && !!id,
    ...options,
  });
};
