import { getTimeQueryString } from '../utils';
import { Api, getApiUrl } from '../instance';
import { SpaceItem, SpaceItemParams } from './interfaces';

type FetchSpace = (param: SpaceItemParams) => Promise<SpaceItem>;
/**
 * Retrieves space information for a desk or room.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | space found |
 * | 404 | space not found  |
 *
 * @param tenantId
 * @param id
 * @param startTime
 * @param endTime
 */
export const fetchSpace: FetchSpace = async ({ id, startTime, endTime }) => {
  const queryString = getTimeQueryString(startTime, endTime || undefined);
  const response = await Api.get(`${getApiUrl(undefined, 'v2')}/spaces/${id}${queryString}`);
  return response.data?.data;
};
