import { AxiosError } from 'axios';
import { ErrorType } from '@engage-shared/constants/api';
import { HttpStatus } from '@engage-shared/utils/types';

type EnhanceErrorParams = {
  message: ErrorType;
  error: Error;
  status?: HttpStatus;
};
type EnhanceError = (params: EnhanceErrorParams) => CustomError;
/**
 * Extends AxiosError with custom message, httpStatus, name=CustomError, isHandled=true
 * @param message
 * @param error
 * @param status
 */
const enhanceError: EnhanceError = ({ message, error, status }) => {
  error.message = message;
  error.name = CUSTOM_ERROR_NAME;
  if (status) {
    (error as CustomError).httpStatus = status;
  }
  (error as CustomError).isHandled = true;
  return error as CustomError;
};

const CUSTOM_ERROR_NAME = 'CustomError';

export interface CustomError extends AxiosError {
  isHandled: boolean;
  httpStatus: HttpStatus;
}

type CustomErrors = {
  [key in HttpStatus]?: (error: Error, status: HttpStatus) => CustomError;
};

const customErrors: CustomErrors = {
  [HttpStatus.FORBIDDEN]: (error: Error, status: HttpStatus) =>
    enhanceError({ message: ErrorType.FORBIDDEN, error, status }),
  [HttpStatus.NOT_FOUND]: (error: Error, status: HttpStatus) =>
    enhanceError({ message: ErrorType.NOT_FOUND, error, status }),
  [HttpStatus.UNAUTHORIZED]: (error: Error, status: HttpStatus) =>
    enhanceError({ message: ErrorType.UNAUTHORIZED, error, status }),
  [HttpStatus.UNPROCESSABLE_ENTITY]: (error: Error, status: HttpStatus) =>
    enhanceError({ message: ErrorType.UNPROCESSABLE_ENTITY, error, status }),
  [HttpStatus.NOT_IMPLEMENTED]: (error: Error, status: HttpStatus) =>
    enhanceError({ message: ErrorType.NOT_IMPLEMENTED, error, status }),
  [HttpStatus.INTERNAL_SERVER_ERROR]: (error: Error, status: HttpStatus) =>
    enhanceError({ message: ErrorType.INTERNAL_SERVER_ERROR, error, status }),
};

/**
 * Create custom handled error.
 * @param error
 * @param status
 */
export const createError = (error: Error, status: HttpStatus): CustomError | Error => {
  const customError = customErrors[status];
  if (customError) {
    return customError(error, status);
  }
  return error;
};

/**
 * Create handled connection aborted error.
 * @param error
 */
export const connectionAborted = (error: Error): CustomError => {
  return enhanceError({ message: ErrorType.CONNECTION_ABORTED, error });
};

/**
 * Handle error message when response data exists.
 * @param error
 */
export const handleErrorMessage = (error: Error | any): string => {
  let errorMessage = error.message;
  const { response } = error;
  if (response && response.data) {
    const {
      data: { message },
    } = response;
    errorMessage = message;
  }
  return errorMessage;
};
