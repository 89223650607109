import React, { PropsWithChildren, ReactHTML } from 'react';
import { Icon } from '../Icons/Icon';
import { SelectedDatesWrapper, SelectedDatesText } from './styled';

type CalendarDateProps = PropsWithChildren<{ as?: keyof ReactHTML }>;

export function CalendarDate({ children, ...rest }: CalendarDateProps) {
  return (
    <SelectedDatesWrapper {...rest}>
      <SelectedDatesText>{children}</SelectedDatesText>
      <Icon name="calendar" size={20} />
    </SelectedDatesWrapper>
  );
}
