import { NavigateFunction } from 'react-router-dom';
import { getYellowboxUrl } from '@engage-shared/utils';
import { Dispatch } from '@reduxjs/toolkit';
import { isElectron } from '@engage-web/utils/electron';
import {
  goToAgendaList,
  goToHeatmaps,
  goToKeyboardDocs,
  goToPersonOfInterest,
  goToWayfinder,
  openExternalURLInApp,
  openMailAgent,
} from './conciergeMenuItemClickHandlers';
import { getActionFromEngageUrl } from '@engage-shared/api';
import { MENU_ACTION_TYPES } from '@engage-shared/constants';

type GetMenuItemClickHandlerArgs = {
  /**
   * Menu item url.
   */
  url: string;
  /**
   * Flag which shows whether the url should be opened in a new tab.
   */
  openWithExternalBrowser: boolean;
  /**
   * Return value of useDispatch hook.
   */
  dispatch: Dispatch;
  /**
   * Return value of useNavigate hook.
   */
  navigate: NavigateFunction;
  /**
   * Current floor id from tenantSelectors.getTenantId.
   */
  currentFloorId?: number;
  /**
   * The email of the current user.
   */
  email: string | undefined;
  /**
   * Force heatmap to be shown on kiosk
   */
  heatmapKiosk?: boolean;
};

type GetMenuItemClickHandlerReturnValue = (() => void) | undefined;

type GetMenuItemClickHandler = (
  args: GetMenuItemClickHandlerArgs,
) => GetMenuItemClickHandlerReturnValue;

/**
 * Parses `url` param to retrieve an onClick handler function for concierge menu item.
 * There are 2 scenarios depending on the value of the `url` parameter:
 * 1. `url` is nullable – early exit is performed and null is returned.
 * 2. `url` is an external link – `openExternalURLInApp` function is returned.
 * 3. `url` is an engage type link (e.g. `engage://bookings`) – dedicated function (or undefined) from `./conciergeMenuItemClickHandlers` is returned.
 */
export const getMenuItemClickHandler: GetMenuItemClickHandler = ({
  url,
  openWithExternalBrowser,
  dispatch,
  navigate,
  currentFloorId,
  email,
  heatmapKiosk,
}) => {
  if (!url) return undefined;

  const actionAndPaths = getActionFromEngageUrl(url);

  const engageURLMap = {
    [MENU_ACTION_TYPES.BROWSER]: () => openExternalURLInApp(url, navigate, openWithExternalBrowser),
    [MENU_ACTION_TYPES.YELLOWBOX]: () => {
      const yellowboxUrl = getYellowboxUrl(email);
      openExternalURLInApp(yellowboxUrl, navigate, openWithExternalBrowser);
    },
    [MENU_ACTION_TYPES.WAYFINDER]: () =>
      heatmapKiosk && isElectron()
        ? goToHeatmaps({ navigate, dispatch, currentFloorId })
        : goToWayfinder({ navigate, dispatch, currentFloorId }),
    [MENU_ACTION_TYPES.BOOKINGS]: () => goToAgendaList({ dispatch, navigate, currentFloorId }),
    [MENU_ACTION_TYPES.PERSON_OF_INTEREST]: () =>
      goToPersonOfInterest({
        dispatch,
        navigate,
        currentFloorId,
        action: actionAndPaths,
      }),
    [MENU_ACTION_TYPES.FIRST_RESPONDERS]: () =>
      goToPersonOfInterest({
        dispatch,
        navigate,
        currentFloorId,
        action: actionAndPaths,
      }),
    [MENU_ACTION_TYPES.MAIL_TO]: () => openMailAgent(url),
    [MENU_ACTION_TYPES.KEYBOARD_DOCS]: () => goToKeyboardDocs({ navigate, dispatch }),
    [MENU_ACTION_TYPES.HEATMAP]: () => goToHeatmaps({ navigate, dispatch, currentFloorId }),
  };

  // @ts-ignore TODO: why action is Nullable? It doesn't make sense to use null as key
  return engageURLMap[actionAndPaths.action];
};
