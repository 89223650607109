import React from 'react';
import { MenuListProps } from 'react-select';
import { UlMenuList } from './Select.style';

const MenuList = <Option,>(props: MenuListProps<Option>) => {
  const { children, innerProps, innerRef, getStyles } = props;

  const styles = getStyles('menuList', props);

  return (
    <UlMenuList
      css={styles}
      // @ts-ignore
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </UlMenuList>
  );
};

export default MenuList;
