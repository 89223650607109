import React from 'react';
import { EngageIcons, Icon } from '../Icons/Icon';
import { Wrapper } from './styled';

interface IconButtonProps {
  icon: EngageIcons;
  onPress: () => void;
  id?: string;
}

// TODO: Find out why we need an id here
const IconButton = ({ id = 'icon_btn', icon, onPress, ...rest }: IconButtonProps) => (
  <Wrapper id={id} onClick={onPress} {...rest}>
    <Icon name={icon} />
  </Wrapper>
);

IconButton.displayName = 'IconButton';

export default React.memo(IconButton);
