import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SEARCH_QUERY } from '@engage-web/constants';
import { testProps } from '@engage-web/utils';
import { Icon } from '../Icons/Icon';
import { IconWrapper, InfoWrapper, LineWrapper, LocatorAction, Wrapper } from './styled';

type LocationCardProps = {
  isDesk?: boolean;
  location?: { buildingName: string; floorName: string };
  desk?: string;
  meetingRoom?: string;
  onShowLocation?: () => void;
  disableButton?: boolean;
};
const LocationCard = ({
  isDesk,
  location,
  desk,
  meetingRoom,
  onShowLocation,
  disableButton,
}: LocationCardProps) => {
  const { t } = useTranslation();
  const showSpaceRow = (isDesk && !!desk) || (!isDesk && !!meetingRoom);
  const { props: a11yAndTestProps } = testProps(
    t,
    ['accessibilityLabels.deskGeoLocation', 'accessibilityLabels.spaceGeoLocation'],
    ([deskGeoLocation, spaceGeoLocation]) => (isDesk ? deskGeoLocation : spaceGeoLocation),
  );

  if (!location && !showSpaceRow) {
    return null;
  }

  return (
    <Wrapper>
      <InfoWrapper>
        {showSpaceRow && (
          <LineWrapper style={{ marginBottom: 20 }}>
            <IconWrapper>
              <Icon
                // @ts-ignore
                name={isDesk ? SEARCH_QUERY.DESK : SEARCH_QUERY.SPACE}
                size={20}
              />
            </IconWrapper>
            <span data-testid="locationCard_space_text">{isDesk ? desk : meetingRoom}</span>
          </LineWrapper>
        )}
        {location && (
          <LineWrapper>
            <IconWrapper>
              <Icon name="location-pin" size={20} />
            </IconWrapper>
            <span data-testid="locationCard_location_text">{`${location.buildingName}, ${location.floorName}`}</span>
          </LineWrapper>
        )}
      </InfoWrapper>
      {(!!desk || !!meetingRoom) && (
        <LocatorAction onClick={onShowLocation} {...a11yAndTestProps} disabled={disableButton}>
          <Icon name="locator" size={18} />
        </LocatorAction>
      )}
    </Wrapper>
  );
};

export default memo(LocationCard);
