import { getLocalizedDate } from '@engage-shared/utils';
import { getTimePickerValues } from '../TimePicker/utils';
import { useLocalTimeFormatH12 } from '@engage-web/utils';
import { usePickerTimeOptions } from './usePickerTimeOptions';

type UseTimePickerConfigParams = {
  minDate: Date;
  selectedDate: Date;
  timeZone: string;
};

export const useTimePickerConfig = ({
  minDate,
  selectedDate,
  timeZone,
}: UseTimePickerConfigParams) => {
  const isTimeFormatH12 = useLocalTimeFormatH12();
  const localizedMinDate = getLocalizedDate({ date: minDate, timeZone });

  const timePickerOptions = usePickerTimeOptions({
    date: selectedDate,
    minTime: localizedMinDate,
    timeZone,
  });

  const { roundedDate, timeValue } = getTimePickerValues({
    date: selectedDate,
    timeZone,
    isTimeFormatH12,
  });

  return { timePickerOptions, roundedDate, timeValue };
};
