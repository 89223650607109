import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tenantSelectors, tenantActions } from '@engage-web/store';
import { LOGIN_PATH } from '@engage-web/constants';
import { Mask } from '@engage-web/components/base';
import { changeLocationHref } from '@engage-web/utils';

const clientId = __CONF__.CLIENT_ID;

export const SsoLogoutRouter = () => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const dispatch = useDispatch();
  // this is used to avoid infinite rendering,
  // because tenantId turns from a string to null.
  const isRedirectedRef = useRef(false);

  React.useEffect(() => {
    if (!tenantId || isRedirectedRef.current) {
      return;
    }

    const returnUrl = `${window.location.origin}${LOGIN_PATH}`;

    dispatch(tenantActions.clear());

    isRedirectedRef.current = true;

    changeLocationHref(
      `https://${tenantId}.serraview.com/Default.aspx?SignOut=true&ClientId=${clientId}&ReturnUrl=${returnUrl}`,
    );
  }, [tenantId, dispatch]);

  return <Mask isLoading />;
};
