import { getTimeQueryString } from '@engage-shared/api/utils';
import { Api, getApiUrl } from '@engage-shared/api/instance';
import { EventsData, EventsParams, UserData } from './interfaces';
import { EVENTS_STATUS } from '@engage-shared/constants/eventsFilter';
import { UserId } from '@engage-shared/api/types';
import { API_VERSIONS } from '@engage-shared/constants';

type FetchUser = (param: { userId: UserId }) => Promise<UserData>;
/**
 * Retrieves information about a person by UserID.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | person found |
 * | 404 | person not found  |
 *
 * @param tenantId
 * @param userId
 */
export const fetchUser: FetchUser = async ({ userId }) => {
  const url = new URL(`${getApiUrl()}/users/${userId}/person`);
  const response = await Api.get(url.toString());
  return response.data?.data;
};

type FetchEvents = (params: EventsParams) => Promise<EventsData[]>;
/**
 * Retrieves schedule list of a users events for desks and spaces.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | events found |
 * | 404 | User not found  |
 *
 * @param userId
 * @param startTime
 * @param endTime
 * @param eventsFilter
 */
export const fetchEvents: FetchEvents = async ({ userId, startTime, endTime, eventsFilter }) => {
  const queryString = getTimeQueryString(startTime, endTime);
  let path = `users/${userId}/events${queryString}`;
  const showBookingsForOthers = eventsFilter === EVENTS_STATUS.ALL;
  if (showBookingsForOthers) {
    const op = startTime || endTime ? `&` : `?`;
    path = `${path}${op}showBookingsForOthers=true`;
  }
  const url = new URL(`${getApiUrl(undefined, API_VERSIONS.V2)}/${path}`);
  const response = await Api.get(url.toString());
  return response?.data;
};
