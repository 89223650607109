import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { RESTRICT_TO_SEARCH_FILTER_ITEMS, RESTRICT_TO_VALUES } from '@engage-web/constants';
import { useRestrictTo, useSetPageTitle } from '@engage-web/utils/hooks';
import { RestrictToSearchFilterWrapper } from './styled';
import { RestrictToLink } from './RestrictToLink';

const RestrictToSearchFilter = () => {
  const { hidePeopleData } = useSelector(tenantSelectors.getKioskConfig);
  const restrictTo = useRestrictTo();
  const { t } = useTranslation();

  useSetPageTitle(restrictTo ? t(RESTRICT_TO_SEARCH_FILTER_ITEMS[restrictTo].text) : '');

  const filterItems = Object.keys(RESTRICT_TO_SEARCH_FILTER_ITEMS);

  return (
    <RestrictToSearchFilterWrapper $hidePeopleData={hidePeopleData}>
      {filterItems.map(key => {
        if (
          hidePeopleData &&
          (key === RESTRICT_TO_VALUES.PERSON || key === RESTRICT_TO_VALUES.TEAM)
        )
          return null;

        return (
          <RestrictToLink
            key={`${key}-filter-item`}
            restrictTo={restrictTo}
            linkRestrictTo={key as RESTRICT_TO_VALUES}
          />
        );
      })}
    </RestrictToSearchFilterWrapper>
  );
};

export default RestrictToSearchFilter;
