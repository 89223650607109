import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { useEffect, useState } from 'react';
import { PointXY } from '../types';

export const useKioskLocation = () => {
  const fes = useFESContext();
  const [kioskLocation, setKioskLocation] = useState<PointXY | null>(null);

  useEffect(() => {
    const handleShowHereMarker = ({ kioskLocation }: { kioskLocation: PointXY }) => {
      setKioskLocation(kioskLocation);
    };

    const handleHideHereMarker = () => {
      setKioskLocation(null);
    };

    fes.on(FES_EVENTS.SHOW_HERE_MARKER, handleShowHereMarker);
    fes.on(FES_EVENTS.HIDE_HERE_MARKER, handleHideHereMarker);

    return () => {
      fes.off(FES_EVENTS.SHOW_HERE_MARKER, handleShowHereMarker);
      fes.off(FES_EVENTS.HIDE_HERE_MARKER, handleHideHereMarker);
    };
  }, [fes]);

  return { kioskLocation };
};
