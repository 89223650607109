import styled from 'styled-components';
import { TitleSecondRow } from '../../../scenes/drawer/header/DrawerHeader/styled';

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Subtitle = styled(TitleSecondRow)`
  margin-left: 10px;
`;
