import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONCIERGE_MENU_PATH } from '@engage-web/constants';
import { testProps } from '@engage-web/utils';
import { Icon } from '@engage-web/components/base';
import { MenuButtonWrapper } from './styled';

export function BackButton() {
  const { t } = useTranslation();

  return (
    <MenuButtonWrapper
      to={CONCIERGE_MENU_PATH}
      {...testProps(t, 'accessibilityLabels.viewConciergeMenu').props}
    >
      <Icon shouldRotateOnRtl name="arrow-left" />
    </MenuButtonWrapper>
  );
}
