import { Api, getApiUrl } from '@engage-shared/api/instance';
import { ReserveDeskParams } from './interfaces';

export type ReserveDeskFn = (params: ReserveDeskParams) => Promise<void>;
/**
 * Reserve a desk for team booking.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | desk reserved |
 * | 403 | not authorized |
 * | 404 | team booking not found |
 * | 500 | desk cannot be reserved |
 * @param spaceId
 * @param teamBookingId
 * @param tenantId
 */
export const reserveDesk: ReserveDeskFn = async ({ tenantId, teamBookingId, spaceId }) => {
  const url = new URL(
    `${getApiUrl(tenantId)}/team_bookings/${teamBookingId}/spaces/${spaceId}/reserve`,
  );

  await Api.put<void>(url.toString());
};
