import { TenantId } from '@engage-shared/api/tenant/interfaces';
import { useMarkers } from '@engage-shared/api/spaces';
import { useFloorMarkers } from '@engage-shared/api/floors/queries/useFloorMarkers';
import { DrawableFloorMarker } from '@engage-shared/api/floors/interfaces';
import { useMemo } from 'react';
import { Nullable } from '@engage-shared/utils/types';
import { mapDrawableFloorMarkers } from '@engage-shared/api/floors/utils';

type UseFloorDrawableMarkersParams = {
  floorId: number;
  tenantId: TenantId;
};

type UseFloorDrawableMarkers = (
  params: UseFloorDrawableMarkersParams,
) => Nullable<DrawableFloorMarker[]>;

export const useFloorDrawableMarkers: UseFloorDrawableMarkers = ({ floorId, tenantId }) => {
  const { data: allMarkers, isSuccess: allMarkersFetched } = useMarkers({
    tenantId,
  });
  const { data: floorMarkers, isSuccess: floorMarkersFetched } = useFloorMarkers({ floorId });

  return useMemo(() => {
    if (!allMarkersFetched || !floorMarkersFetched) {
      return null;
    }

    return mapDrawableFloorMarkers(floorMarkers, allMarkers);
  }, [allMarkers, allMarkersFetched, floorMarkers, floorMarkersFetched]);
};
