import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyCard } from '@engage-web/components/base/EmptyCard';
import { TeamMembersHeader } from './TeamMembersHeader';

export function TeamMembersError() {
  const { t } = useTranslation();

  return (
    <>
      <TeamMembersHeader />
      <EmptyCard iconName="warning">{t('common.networkError')}</EmptyCard>
    </>
  );
}
