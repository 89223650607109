import React, { CSSProperties, memo } from 'react';
import { TeamSearchResponseData } from '@engage-shared/api/search/interfaces';
import {
  ListItemOuterWrapper,
  ListItemWrapper,
  Subtitle,
  SubtitleWrapper,
  TextWrapper,
  Title,
} from './styled';
import SizeRecognitionWrapper from './SizeRecognitionWrapper';
import Avatar from '../../base/Avatar/AvatarImage';
import { useTranslation } from 'react-i18next';

type TeamItemProps = {
  item: TeamSearchResponseData;
  onPress: (item: TeamSearchResponseData) => void;
  onSizeFound: (size: DOMRect, index: number) => void;
  style?: CSSProperties;
  index: number;
};

const TeamItem = ({ item, onPress, onSizeFound, style, index }: TeamItemProps) => {
  const { id, name, people } = item;
  const { t } = useTranslation();

  return (
    <ListItemOuterWrapper style={style}>
      <SizeRecognitionWrapper onSizeFound={onSizeFound} index={index}>
        <ListItemWrapper
          aria-label={`${t('accessibilityLabels.viewTeamItem')} ${name}`}
          onClick={() => onPress(item)}
        >
          <Avatar icon="teams" size="small" id={id} />
          <TextWrapper>
            <Title>{name}</Title>
            <SubtitleWrapper>
              <Subtitle>{t('layout.teamsFilter.membersCount', { count: people })}</Subtitle>
            </SubtitleWrapper>
          </TextWrapper>
        </ListItemWrapper>
      </SizeRecognitionWrapper>
    </ListItemOuterWrapper>
  );
};

export default memo(TeamItem);
