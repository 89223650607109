import { GLOBAL_FILTERS } from './types';

export const GLOBAL_FILTER_TYPES: GLOBAL_FILTERS = {
  DATE_START: 'dateStart',
  DATE_END: 'dateEnd',
  ALL_DAY: 'allDay',
  SELECTED_DATES: 'selectedDates',
  SPACE_TYPES: 'spaceTypes',
  SPACE_ATTRIBUTES: 'spaceAttributes',
  CAPACITY: 'capacity',
};
