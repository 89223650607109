import { COMMON_STYLES } from '@engage-web/constants';
import React, { KeyboardEventHandler } from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ToggleSwitchLabelStyled, ToggleSwitchWrapperStyled } from './styled';
import { ThemeColors } from '@engage-shared/theme';

const ENTER_CODE = 'Enter';

type ToggleSwitchProps = {
  label?: string;
  ariaLabel?: string;
} & ReactSwitchProps;

// it's wrapper of react-switch component
const ToggleSwitch = ({
  id,
  checked = false,
  onChange,
  disabled = false,
  label,
  ariaLabel,
  ...options
}: ToggleSwitchProps) => {
  const { color9, gray4 } = useTheme() as ThemeColors;
  const { t } = useTranslation();

  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.code === ENTER_CODE) {
      // @ts-ignore - cannot convert event to SyntheticEvent
      onChange(!checked, event, id);
    }
  };

  return (
    <ToggleSwitchWrapperStyled disabled={disabled}>
      <Switch
        id={id}
        onChange={onChange}
        onKeyPress={onKeyPress}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow={COMMON_STYLES.SWITCH_BOX_SHADOW}
        activeBoxShadow={COMMON_STYLES.SWITCH_BOX_SHADOW}
        handleDiameter={20}
        onColor={color9}
        offColor={gray4}
        height={24}
        width={40}
        disabled={disabled}
        checked={checked}
        aria-label={ariaLabel ?? id}
        aria-checked={checked}
        aria-disabled={disabled}
        role="switch"
        name={t('accessibilityLabels.optOutFromSearch')}
        {...options}
      />
      {!!label && (
        <ToggleSwitchLabelStyled data-testid="switch-Label" htmlFor={id}>
          {label}
        </ToggleSwitchLabelStyled>
      )}
    </ToggleSwitchWrapperStyled>
  );
};

export default ToggleSwitch;
