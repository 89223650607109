import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { FloorData } from '@engage-shared/api/floors/interfaces';
import { fetchBuildingFloors } from '../fetch';
import { BUILDING_FLOORS_QUERY_KEY } from '../constants';

const STALE_TIME = 30 * 60 * 1000;
const queryOptions = {
  staleTime: STALE_TIME,
};

export const useBuildingFloors = (
  buildingId: number,
  options: UseQueryOptions<FloorData[], unknown, FloorData[]>,
) => {
  return useQuery<FloorData[], unknown, FloorData[]>(
    [BUILDING_FLOORS_QUERY_KEY, `${buildingId}`],
    () => fetchBuildingFloors(buildingId),
    {
      enabled: !!buildingId,
      ...queryOptions,
      ...options,
    },
  );
};

type FetchBuildingFloorsQuery = ({ id }: { id: number }) => Promise<FloorData[]>;

type UseBuildingFloorsFetch = () => {
  fetchBuildingFloorsQuery: FetchBuildingFloorsQuery;
};

export const useBuildingFloorsFetch: UseBuildingFloorsFetch = () => {
  const queryClient = useQueryClient();

  const query: FetchBuildingFloorsQuery = ({ id }: { id: number }) =>
    queryClient.fetchQuery([BUILDING_FLOORS_QUERY_KEY, id], () => fetchBuildingFloors(id), {
      ...queryOptions,
    });

  const fetchBuildingFloorsQuery = useCallback(query, [queryClient]);

  return { fetchBuildingFloorsQuery };
};
