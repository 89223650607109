import { Api, getApiUrl } from '@engage-shared/api/instance';
import { getSpaceTypeQueryString } from '@engage-shared/api/utils';
import { LanguageTypes } from '@engage-shared/constants/languages';
import { FetchSpaceTypesParams, SpaceTypesData } from '@engage-shared/api/spaces/interfaces';

type FetchSpaceTypes = (params: FetchSpaceTypesParams) => Promise<SpaceTypesData>;

/**
 * Retrieves all space type sets
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | space types found |
 *
 * @param {Object} options
 * @param {'Desk' | 'Space'} options.spaceType while query expects one of {'Desk', 'Room'} but getSpaceTypeQueryString solves it
 * @param {string} options.locale
 */
export const fetchSpaceTypes: FetchSpaceTypes = async ({ spaceType, locale }) => {
  const queryString = getSpaceTypeQueryString(spaceType);
  const op = queryString ? `?` : '';
  const path = `space_types/all_items${op}${queryString}`;
  const url = new URL(`${getApiUrl()}/${path}`);
  url.searchParams.set('locale', locale || LanguageTypes.enUS);

  const response = await Api.get(url.toString());
  return response.data?.data;
};
