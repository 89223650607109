import { Nullable } from '@engage-shared/utils/types';
import { useFetchImageURL } from '@engage-web/api/queries/useFetchImageURL';
import { UseQueryOptions } from 'react-query';

interface UseSpaceImageParams {
  spaceId: number;
  imageSrc: string;
  options?: UseQueryOptions<Nullable<string>, unknown, Nullable<string>, [string, string, string]>;
}

export function useSpaceImage({ spaceId, imageSrc, options = {} }: UseSpaceImageParams) {
  const { enabled = true, ...otherOptions } = options;

  return useFetchImageURL({
    url: imageSrc,
    queryKey: ['space', `${spaceId}`, 'image'],
    options: {
      enabled: enabled && !!spaceId,
      ...otherOptions,
    },
  });
}
