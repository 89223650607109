import { useBuildingFetch, useBuildingReservationLimit } from '@engage-shared/api/buildings';
import { useMutation } from 'react-query';
import {
  MultiDayBookingMutationOptions,
  MultiDayBookingResult,
  SaveMultiDayParams,
  MultiDayBookingMutationParams,
  MultiDayBookingMutationData,
} from '@engage-shared/api/bookings/interfaces';
import { saveMultiDayBooking as save } from '@engage-shared/api/bookings/mutations/utils';
import { LanguageTypes } from '@engage-shared/constants/languages';

type MultiDayBookingMutation = (
  params: MultiDayBookingMutationParams,
  options: MultiDayBookingMutationOptions,
) => MultiDayBookingResult;
/**
 * Returns mutation for create multiple bookings.
 * @param tenantId
 * @param email
 * @param spaceItem
 * @param reserveeId
 * @param duration
 * @param allDayDuration
 * @param isUpdate
 * @param summaryText
 * @param filter
 * @param bookingInitialSpaceId
 * @param meetingId
 * @param localTimeZone
 * @param selectedDays
 * @param locale
 * @param successCallback
 * @param errorCallback
 * @param options
 */
export const useMultiDayBookingMutation: MultiDayBookingMutation = (
  {
    tenantId,
    email,
    spaceItem,
    reserveeId,
    duration,
    allDayDuration,
    isUpdate,
    summaryText,
    //useReservationFilters
    filter,
    // booking
    bookingInitialSpaceId,
    meetingId,
    localTimeZone, //getLocalTimeZone
    selectedDays,
    locale = LanguageTypes.enUS,
    // callbacks
    successCallback,
    errorCallback,
  },
  options = {},
) => {
  const { fetchBuildingQuery } = useBuildingFetch();
  const { hasBuildingLimitPer15Minutes } = useBuildingReservationLimit();

  const bookingMutation = useMutation<MultiDayBookingMutationData, Error, SaveMultiDayParams>(
    save,
    options,
  );

  const saveMultiDayBooking: () => void = () => {
    return bookingMutation.mutate({
      tenantId,
      allDayDuration,
      duration,
      bookingInitialSpaceId,
      spaceItem,
      reserveeId,
      errorCallback,
      filter,
      email,
      isUpdate,
      summaryText,
      localTimeZone,
      successCallback,
      meetingId,
      fetchBuildingQuery,
      hasBuildingLimitPer15Minutes,
      selectedDays,
      locale,
    });
  };

  return { ...bookingMutation, saveMultiDayBooking };
};
