import React, { useEffect, useReducer, useRef } from 'react';
import ReactFocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  CommonMessage,
  Title,
  PrimaryButton,
  ButtonsRow,
  SecondaryButton,
} from './styled';

const CountdownText = styled(CommonMessage)`
  line-height: 36px;
  color: ${({ theme }) => theme.color9};
  margin-bottom: 28px;
  font-weight: 700;
  margin-top: 20px;
`;

const Message = styled(CommonMessage)`
  line-height: 25px;
  margin-top: 8px;
  font-weight: 400;
`;

type KioskCountdownDialogProps = {
  seconds?: number;
  onContinuePress: () => void;
  onTimeIsOver: () => void;
  onExitPress: () => void;
};

const KioskCountdownDialog = ({
  seconds = 20,
  onContinuePress,
  onTimeIsOver,
  onExitPress,
}: KioskCountdownDialogProps) => {
  const { t } = useTranslation();
  const intervalRef = useRef<any>();
  const [secondsLeft, decrementSecondsLeft] = useReducer(left => left - 1, seconds);

  useEffect(() => {
    intervalRef.current = setInterval(decrementSecondsLeft, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (secondsLeft === 0) {
      clearInterval(intervalRef.current);
      onTimeIsOver();
    }
  }, [onTimeIsOver, secondsLeft]);

  return (
    <ReactFocusLock>
      <Wrapper>
        <Title>{t('components.countdownDialog.title')}</Title>
        <Message>{t('components.countdownDialog.message')}</Message>
        <CountdownText>
          {t('components.countdownDialog.countdownMessage', { secondsLeft })}
        </CountdownText>
        <ButtonsRow>
          <SecondaryButton onClick={onExitPress}>{t('common.exit')}</SecondaryButton>
          <PrimaryButton onClick={onContinuePress}>{t('common.continue')}</PrimaryButton>
        </ButtonsRow>
      </Wrapper>
    </ReactFocusLock>
  );
};

export default KioskCountdownDialog;
