import { BookingsListResponse, OccupantData } from '@engage-shared/api/bookings/interfaces';
import { getOccupant } from '@engage-shared/api/bookings/queries/utils/getOccupant';
import { getSpaceAvailabilityFromBookings, calculateIsOccupied } from '@engage-shared/utils/dates';
import { ISODateString } from '@engage-shared/utils/types';
import { fetchBookings } from '../../fetch';
import { ParsedSpaceItem } from '@engage-shared/api/spaces/interfaces';
import { TenantId } from '@engage-shared/api/tenant/interfaces';

type GetBookingsFetcherParams = {
  tenantId: TenantId;
  spaceItem: ParsedSpaceItem | undefined;
  startTime: ISODateString;
  endTime: ISODateString | null;
  dateStart: Date;
  bookingIntervalSize: number;
  enforceAllDayDeskReservations?: boolean;
};

export type GetBookingsFetcherResponse = {
  bookings: BookingsListResponse[];
  isOccupied: boolean;
  bookedIntervals: number[];
  occupant: OccupantData;
};

export const getBookingsFetcher = async ({
  tenantId,
  spaceItem,
  startTime,
  endTime,
  dateStart,
  bookingIntervalSize,
  enforceAllDayDeskReservations,
}: GetBookingsFetcherParams): Promise<GetBookingsFetcherResponse> => {
  const bookings = await fetchBookings({
    tenantId,
    spaceId: spaceItem?.id,
    startTime,
    endTime,
  });
  // Get isOccupied status
  const isOccupied = calculateIsOccupied({
    spaceItem,
    filterDateStart: dateStart,
    bookingIntervalSize,
    bookings,
  });
  const bookedIntervals =
    spaceItem?.showDurationSelection && bookings?.length > 0
      ? getSpaceAvailabilityFromBookings(bookings, bookingIntervalSize)
      : [];

  const occupant = await getOccupant({
    spaceItem,
    dateStart,
    bookings,
    enforceAllDayDeskReservations,
  });

  return { bookings, isOccupied, bookedIntervals, occupant };
};
