import styled from 'styled-components';
import { Button } from '@engage-web/components/base';
import { CSSProperties } from 'react';

export const LIST_ITEM_PADDING_VERTICAL = 16;
export const LIST_WIDTH = 400;
export const GLOBAL_SEARCH_COLLAPSED_HEIGHT = 130;
export const DESK_LIST_HEIGHT = {
  DEFAULT: 280,
  '570': 370,
  '700': 500,
  '900': 600,
};

export const Wrapper = styled.div`
  margin: 10px 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ListItemOuterWrapper = styled.li<{ $style?: CSSProperties | {} }>`
  ${props => props.$style ?? {}}
  padding: 0 20px;
  cursor: pointer;
  width: 100%;
`;

export const ListItemWrapper = styled(Button)`
  display: flex;
  direction: ltr;
  padding: ${LIST_ITEM_PADDING_VERTICAL}px 4px;
  border-bottom: 1px solid #edeff0;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: start;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
  width: 80%;
`;

export const Title = styled.h3`
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 5px;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  width: 100%;
  color: ${props => props.theme.typo2};
  opacity: 0.7;
`;

export const Subtitle = styled.span`
  color: ${props => props.theme.typo2};
  font-weight: 700;
  span {
    font-weight: normal;
    margin-left: 6px;
  }
`;

export const IconWrapper = styled.div`
  padding-right: 30px;
  padding-left: 10px;
  color: ${({ theme }) => theme.color9};
`;

export const IconWrapperFavourites = styled.button`
  color: ${({ theme }) => theme.color9};
  padding: 10px;
  right: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
`;

export const GlobalSearchWrapper = styled.div<{ $showResults: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $showResults }) =>
    $showResults ? DESK_LIST_HEIGHT.DEFAULT : GLOBAL_SEARCH_COLLAPSED_HEIGHT}px;
  width: 100%;

  @media screen and (min-height: 570px) {
    height: ${({ $showResults }) =>
      $showResults ? DESK_LIST_HEIGHT['570'] : GLOBAL_SEARCH_COLLAPSED_HEIGHT}px;
  }

  @media screen and (min-height: 700px) {
    height: ${({ $showResults }) =>
      $showResults ? DESK_LIST_HEIGHT['700'] : GLOBAL_SEARCH_COLLAPSED_HEIGHT}px;
  }

  @media screen and (min-height: 900px) {
    height: ${({ $showResults }) =>
      $showResults ? DESK_LIST_HEIGHT['900'] : GLOBAL_SEARCH_COLLAPSED_HEIGHT}px;
  }
`;

const adjustGlobalSearchWrapperHeight = ({
  parentHeight,
  globalSearchFiltersHeight,
}: {
  parentHeight: number;
  globalSearchFiltersHeight: number;
}) => parentHeight - globalSearchFiltersHeight;

export const GlobalSearchOuterWrapper = styled.div<{ $isTeamFilter?: boolean }>`
  display: flex;
  height: ${DESK_LIST_HEIGHT.DEFAULT}px;
  width: 100%;
  overflow: hidden;

  @media screen and (min-height: 570px) {
    height: ${({ $isTeamFilter }) =>
      adjustGlobalSearchWrapperHeight({
        parentHeight: DESK_LIST_HEIGHT['570'],
        globalSearchFiltersHeight: $isTeamFilter ? 160 : 200,
      })}px;
  }

  @media screen and (min-height: 700px) {
    height: ${({ $isTeamFilter }) =>
      adjustGlobalSearchWrapperHeight({
        parentHeight: DESK_LIST_HEIGHT['700'],
        globalSearchFiltersHeight: $isTeamFilter ? 160 : 200,
      })}px;
  }

  @media screen and (min-height: 900px) {
    height: ${({ $isTeamFilter }) =>
      adjustGlobalSearchWrapperHeight({
        parentHeight: DESK_LIST_HEIGHT['900'],
        globalSearchFiltersHeight: $isTeamFilter ? 160 : 200,
      })}px;
  }
`;

export const GlobalSearchResultsWrapper = styled.div`
  flex: 1 1 auto;
`;

export const GlobalSearchNoData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: 15px;
  width: ${LIST_WIDTH}px;

  p {
    font-size: inherit;
    overflow-wrap: normal;
    padding: 10px 30px;
    text-align: center;
  }
`;
