import React, { memo, MouseEvent } from 'react';
import { useCheckInStatus } from '@engage-web/utils';
import Notification from './Notification/Notification';
import { AgendaItem } from '@engage-shared/api';

export interface INotificationItem {
  id: string;
  title: string;
  localTime: string;
  location: string;
  checkInStartDate: string;
  checkInCloseDate: string;
  expirationDate: string;
  fireDate: string;
  isRead: boolean;
  event: AgendaItem;
}

interface NotificationItemProps {
  item: INotificationItem;
  onSelectNotification: ({ item }: { item: INotificationItem }) => void;
}

const NotificationItem = ({ item, onSelectNotification }: NotificationItemProps) => {
  const { title, localTime, location, isRead } = item;
  const { event } = item;
  const { isDesk } = event;
  const avatarIcon = isDesk ? 'desk' : 'space';

  const { status: checkInStatus, statusMessage } = useCheckInStatus({
    event,
  });

  const _onSelectNotification = (e: MouseEvent) => {
    e.stopPropagation();
    onSelectNotification({ item });
  };

  return (
    <Notification
      icon={avatarIcon}
      title={title}
      localTime={localTime}
      location={location}
      checkInStatus={checkInStatus}
      statusMessage={statusMessage}
      isRead={isRead}
      onDetails={_onSelectNotification}
    />
  );
};

export default memo(NotificationItem);
