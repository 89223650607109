// this is the theme passed from the client to the floorplan,
// usually found in @serraview/engage-shared

import { Theme } from './types';

export type ThemeColorKey =
  | 'color1'
  | 'color2'
  | 'color3'
  | 'color4'
  | 'color5'
  | 'color6'
  | 'color7'
  | 'color8'
  | 'color9'
  | 'color10'
  | 'color11'
  | 'typo1'
  | 'typo2'
  | 'status1'
  | 'status2'
  | 'status3'
  | 'status4'
  | 'status5'
  | 'gray4'
  | 'gray5'
  | 'inactiveColor'
  | 'blue1'
  | 'lightBlue';

export type ThemeColors = Record<ThemeColorKey, string>;

export const defaultGlobalTheme = {
  color1: '#002E5D',
  color2: '#454B66',
  color3: '#DFE4EA',
  color4: '#EFF2F6',
  color5: '#EDEFF0',
  color6: '#FFFFFF',
  color7: '#F9FAFA',
  color8: '#F5F6F6',
  color9: '#0000FF',
  color10: '#0000FF',
  color11: '#F8403D',
  typo1: '#FFFFFF',
  typo2: '#18224C',
  status1: '#DFE3EA',
  status2: '#0B8A00',
  status3: '#A96600',
  status4: '#963838',
  status5: '#0000FF',
  gray4: '#898E97',
  gray5: '#73738C',
  inactiveColor: '#0000B3',
  blue1: '#5383ec',
  lightBlue: '#00FFD1',
};

// TODO: extract into shared libs
export const useTheme = () => defaultGlobalTheme;

export type ColorMap = `$${ThemeColorKey}`;

export interface GlobalTheme extends ThemeColors {
  globalStyles: Record<ColorMap, string>;
  floorplanTheme: Theme;
}
