import styled from 'styled-components';
import DatePicker from '@engage-web/components/base/DateTime/DatePicker/DatePicker';

type HighlightableProps = {
  $isHighlighted?: boolean;
};

const SearchFilterInputGroup = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const SearchFilterInputLabel = styled.div`
  min-width: 40px;
  margin-right: 15px;
`;

const StyledDatePickerButton = styled.button<HighlightableProps>`
  border: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? `2px solid ${theme.color9}` : `unset`};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 15px;
  width: 100%;
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.color9};

  &.focus-visible {
    border: 2px solid Highlight;
  }
`;

const StyledDatePickerButtonText = styled.span<HighlightableProps>`
  margin-right: 5px;
  font-weight: ${({ $isHighlighted }) => ($isHighlighted ? '700' : '600')};
  font-size: 14px;
`;

const StyledDatePicker = styled(DatePicker)`
  & .react-date-picker__calendar.react-date-picker__calendar--open {
    transform: translate(-97%, -6%);
  }
`;

export {
  SearchFilterInputGroup,
  SearchFilterInputLabel,
  StyledDatePickerButton,
  StyledDatePickerButtonText,
  StyledDatePicker,
};
