import React, { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import AnimateHeight from 'react-animate-height';
import { Nullable } from '@engage-shared/utils/types';

type AnimatedHeightProps = PropsWithChildren<{
  /**
   * Transition duration in ms.
   * Default value is 150.
   */
  duration?: number;
}>;

export const AnimatedHeight = ({ children, duration = 150 }: AnimatedHeightProps) => {
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const [height, setHeight] = useState<string | number>('auto');

  useLayoutEffect(() => {
    setHeight(containerRef.current?.getBoundingClientRect().height ?? 'auto');
  }, [containerRef]);

  useResizeObserver(containerRef, entry => setHeight(entry.contentRect.height));

  return (
    <AnimateHeight height={height} duration={duration}>
      <div id="animated-height-div" ref={containerRef}>
        {children}
      </div>
    </AnimateHeight>
  );
};
