import React, { memo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import {
  DateFormat,
  formatDate,
  formatLocalizedDate,
  getSelectedStartTime,
  isSameLocalizedDay,
} from '@engage-shared/utils';
import { AvailabilityInfo, Calendar, TimePicker } from '@engage-web/components/base';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from '@engage-web/store';
import { FocusId, useFocus } from '@engage-web/utils/hooks';
import { getDateFromTimeSegment, TimeObject } from '../TimePicker/utils';
import { DateISODate } from '@engage-shared/utils/types';
import {
  CalendarModal,
  CalendarModalInnerWrapper,
  DateTimePickerSelectButton,
  TimePickerLabel,
  TimePickerWrapper,
} from './styled';
import { useTimePickerConfig } from './useTimePickerConfig';

type DateTimePickerProps = {
  handleClose?: (selectedDate: Date, isToday: boolean) => void;
  initialDate?: Date;
  minDate: Date;
  maxDate: Date;
  showAvailabilityInfo?: boolean;
  timeZone?: string;
  isTimeFormatH12?: boolean;
  returnFocusTo?: FocusId;
};

function DateTimePicker({
  initialDate = new Date(),
  showAvailabilityInfo = false,
  minDate,
  maxDate,
  timeZone = '',
  handleClose,
  isTimeFormatH12 = false,
  returnFocusTo = FocusId.TimelineButton,
}: DateTimePickerProps) {
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const isTodayRef = useRef(false);

  const { timePickerOptions, roundedDate, timeValue } = useTimePickerConfig({
    minDate,
    selectedDate,
    timeZone,
  });

  const { t } = useTranslation();
  const uiLanguage = useSelector(userConfigSelectors.getUiLanguage);

  const _onDateSelected = (date: DateISODate) => {
    // get date part only because dateObject is in user's timeZone
    const _selectedDate = formatDate(date) as DateISODate;
    const newDate = moment.tz(_selectedDate, timeZone);

    const now = new Date();
    isTodayRef.current = isSameLocalizedDay(now, _selectedDate, timeZone);

    if (isTodayRef.current) {
      setSelectedDate(getSelectedStartTime(null));
      return;
    }

    newDate.hours(9);
    newDate.minutes(0);
    setSelectedDate(newDate.toDate());
  };

  const onTimeSelected = (timePickerSegment: TimeObject | null) => {
    const newDate = getDateFromTimeSegment(timePickerSegment, roundedDate, isTimeFormatH12);
    if (newDate) {
      isTodayRef.current = false;
      setSelectedDate(newDate);
    }
  };

  // can not use getLocalizedDate, because we need Date object for Calendar
  const toLocalizedDate = (date: Date, timeZone: string) => {
    // TODO: get rid of this args mess
    const args = ['en-US'];
    if (timeZone) {
      // @ts-ignore
      args.push({ timeZone });
    }
    return new Date(date.toLocaleString(...args));
  };

  const onSelectClick = () => {
    handleClose?.(selectedDate, isTodayRef.current);
  };

  const calendarDateValue = toLocalizedDate(selectedDate, timeZone);

  const calendarNavigationAriaLabel = formatLocalizedDate(calendarDateValue, {
    locale: uiLanguage,
    format: DateFormat.monthLong,
  });

  useFocus({ returnFocusTo });

  return (
    <CalendarModal onClick={(e: any) => e.stopPropagation()}>
      <CalendarModalInnerWrapper>
        <Calendar
          locale={uiLanguage}
          // @ts-ignore
          onChange={_onDateSelected}
          value={calendarDateValue}
          minDate={toLocalizedDate(minDate, timeZone)}
          maxDate={toLocalizedDate(maxDate, timeZone)}
          navigationAriaLabel={calendarNavigationAriaLabel}
          prevAriaLabel={t('accessibilityLabels.timelineCalendarNavPrevMonth')}
          nextAriaLabel={t('accessibilityLabels.timelineCalendarNavNextMonth')}
        />
        <TimePickerWrapper>
          <TimePickerLabel>{t('components.dateButton.timePickerLabel')}</TimePickerLabel>
          <TimePicker
            onChange={onTimeSelected}
            value={timeValue}
            isTimeFormatH12={isTimeFormatH12}
            timeOptions={timePickerOptions}
          />
        </TimePickerWrapper>
        {showAvailabilityInfo && <AvailabilityInfo dateStart={selectedDate} />}
      </CalendarModalInnerWrapper>
      <DateTimePickerSelectButton
        id={FocusId.TimelineSelectButton}
        data-testid="modal-date-time-picker-select-btn"
        onClick={onSelectClick}
      >
        {t('common.select')}
      </DateTimePickerSelectButton>
    </CalendarModal>
  );
}

export default memo(DateTimePicker);
