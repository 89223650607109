import Api from '../instance/api';
import { getApiUrl } from '@engage-shared/api/instance';
import { TenantId } from '@engage-shared/api/tenant/interfaces';
import {
  TeamMember,
  Team,
  FetchTeamBookingDetails,
  FetchTeamMembersParams,
  FetchTeamBookingsParams,
  ParsedTeam,
  TeamBookingParams,
} from './interfaces';
import { getTimeQueryString } from '@engage-shared/api/utils';
import { SpaceType } from '@engage-shared/api/spaces/interfaces';
import { TeamBooking } from '@engage-shared/api/types';

type FetchTeamParams = { tenantId: TenantId; id: number };
type FetchTeam = (param: FetchTeamParams) => Promise<Team>;

/**
 * Get team information
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | results found |
 */
export const fetchTeam: FetchTeam = async ({ tenantId, id }) => {
  const url = new URL(`${getApiUrl(tenantId)}/teams/${id}`);
  const response = await Api.get(url.toString());
  return response.data?.data;
};

type FetchAndParseTeam = (param: FetchTeamParams) => Promise<ParsedTeam>;
export const fetchAndParseTeam: FetchAndParseTeam = async ({ tenantId, id }) => {
  const team = await fetchTeam({ tenantId, id });

  return {
    ...team,
    showDurationSelection: true,
    showDateCard: false,
    showDaysSelector: false,
    type: SpaceType.TEAM,
  };
};

type FetchTeamMembers = (param: FetchTeamMembersParams) => Promise<TeamMember[]>;
/**
 * Search for the members of a team.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | results found |
 */
export const fetchTeamMembers: FetchTeamMembers = async ({ tenantId, id, searchString }) => {
  const queryString = searchString ? `?searchString=${searchString}` : '';
  const url = new URL(`${getApiUrl(tenantId)}/teams/${id}/members${queryString}`);

  const response = await Api.get(url.toString());
  return response.data?.data;
};

type FetchTeamBookings = (param: FetchTeamBookingsParams) => Promise<TeamBooking[]>;
/**
 * Team bookings for a team over a given time period.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | results found |
 */
export const fetchTeamBookings: FetchTeamBookings = async ({
  tenantId,
  id,
  startTime,
  endTime,
}) => {
  const queryString = getTimeQueryString(startTime, endTime);
  const url = new URL(`${getApiUrl(tenantId)}/teams/${id}/bookings${queryString}`);

  const response = await Api.get(url.toString());
  return response.data?.data;
};

type FetchTeamBooking = (param: TeamBookingParams) => Promise<FetchTeamBookingDetails>;
/**
 * Retrieve details about a team booking.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | results found |
 */
export const fetchTeamBooking: FetchTeamBooking = async ({ tenantId, id }) => {
  const url = new URL(`${getApiUrl(tenantId)}/team_bookings/${id}`);

  const response = await Api.get(url.toString());
  return response.data?.data;
};

/**
 * Ability for the current user to cancel a team booking.
 */
export const cancelTeamBooking: FetchTeamBooking = async ({ tenantId, id }) => {
  const url = new URL(`${getApiUrl(tenantId)}/team_bookings/${id}`);
  const response = await Api.delete(url.toString());
  return response.data?.data;
};

type AcceptTeamBooking = (params: TeamBookingParams) => void;
/**
 * Ability for the current user to accept a team booking.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | booking was successfully accepted |
 */
export const acceptTeamBooking: AcceptTeamBooking = async ({ tenantId, id }) => {
  const url = new URL(`${getApiUrl(tenantId)}/team_bookings/${id}/accept`);
  await Api.put(url.toString());
};

type DeclineTeamBooking = (params: TeamBookingParams) => void;
/**
 * Ability for the current user to decline a team booking.
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | booking was successfully declined |
 */
export const declineTeamBooking: DeclineTeamBooking = async ({ tenantId, id }) => {
  const url = new URL(`${getApiUrl(tenantId)}/team_bookings/${id}/decline`);
  await Api.put(url.toString());
};
