import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInMilliseconds } from 'date-fns';
import ms from 'ms';
import { useIdleTimer } from 'react-idle-timer';
import { useSearchParams } from 'react-router-dom';
import { useLogout } from '@engage-web/api/mutations/useLogout';
import { sessionTimeoutActions, sessionTimeoutSelectors, tenantSelectors } from '@engage-web/store';

const SESSION_TIMEOUT_MS = ms('2 hrs');

type SessionTimeoutMiddlewareProps = {
  children: any;
};

export const SessionTimeoutMiddleware = ({ children }: SessionTimeoutMiddlewareProps) => {
  const [query] = useSearchParams();
  const testTenant = query.get('sessionTimeoutTestTenant');
  const testTimeout = query.get('sessionTimeoutTestTimeout');
  const dispatch = useDispatch();
  // selectors
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const sessionTimeoutAffectedTenants = useSelector(sessionTimeoutSelectors.getAffectedTenants);
  const lastActiveAt = useSelector(sessionTimeoutSelectors.getLastActiveAt);
  // state
  const [isIdle, setIsIdle] = useState(false);
  // queries
  const { mutate: logout } = useLogout();

  const tenant = testTenant || tenantId;
  const timeout = parseInt(testTimeout as string, 10) || SESSION_TIMEOUT_MS;

  const onTimeout = () => {
    if (!sessionTimeoutAffectedTenants.includes(tenant)) return;
    logout({ tenantId });
  };
  // check last active time after user come back to the app
  useEffect(() => {
    const msDiff = differenceInMilliseconds(Date.now(), lastActiveAt);

    if (msDiff > timeout) {
      onTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActiveAt]);
  // call on timeout after user is idle for SESSION_TIMEOUT time
  useEffect(() => {
    let timer: any = null;

    if (isIdle) {
      timer = setTimeout(() => {
        onTimeout();
      }, timeout);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = () => {
    setIsIdle(false);
  };

  const onAction = () => {
    dispatch(sessionTimeoutActions.setLastActiveAt(Date.now()));
  };

  useIdleTimer({
    timeout: 10000,
    onIdle,
    onActive,
    onAction,
    debounce: 500,
  });

  return children;
};
