import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  @media (max-height: 600px) {
    height: calc(100vh - 218px);
    overflow-y: auto;
  }
`;

export { Wrapper };
