import { CSSProperties } from 'react';
import ReactFocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { Z_INDEX } from '@engage-web/constants';

const POSITION = 40;

const Overlay = styled.div`
  position: fixed;
  z-index: ${Z_INDEX.MODAL};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(3, 13, 48, 0.7);
  backdrop-filter: blur(5px);
`;

const Content = styled.div<{ $style?: CSSProperties | {} }>`
  position: absolute;
  top: ${POSITION}px;
  left: ${POSITION}px;
  right: ${POSITION}px;
  bottom: ${POSITION}px;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0 40px 60px rgba(10, 38, 50, 0.15);
  border-radius: 6px;
  outline: none;
  ${props => props.$style ?? {}}
`;

const CloseButton = styled.button`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
`;

export const StyledFocusLock = styled(ReactFocusLock)`
  width: 100%;
  height: 100%;
`;

export { Overlay, Content, CloseButton };
