import { AxiosError } from 'axios';
import { NO_DAY_SELECTED } from '@engage-shared/constants/deskReservationConstants';
import { BookingsSuccessMessages } from '@engage-shared/api/bookings';
import { handleFailedBookingMessages } from './teamBookingMessages';
import { safelyInvokeCallback } from '@engage-shared/api/bookings/mutations/utils/callback';
import { LanguageTypes } from '@engage-shared/constants/languages';
import {
  TeamBookingsFailedMessages,
  MultiDayTeamBookingMutationData,
  MultiDayTeamBookingMutationParams,
} from '../interfaces';
import { createTeamBooking } from '../create';
import { handleSuccessBookingMessages } from '@engage-shared/api/bookings/mutations/utils/bookingMessages';
import { getReservationDatesFromFilter } from '@engage-shared/api/teams/utils/getReservationDatesFromFilter';

type HandleDayBookingParams = Omit<MultiDayTeamBookingMutationParams, 'selectedDays'> & {
  bookingsSuccessMessages: BookingsSuccessMessages;
  bookingsFailedMessages: TeamBookingsFailedMessages;
  selectedDay: Date;
  locale: LanguageTypes;
};
type HandleDayBooking = (params: HandleDayBookingParams) => Promise<void>;
const handleDayBooking: HandleDayBooking = async ({
  tenantId,
  teamId,
  summary,
  timeZone,
  attendees,
  desks,
  selectedDay,
  filter,
  successCallback,
  errorCallback,
  bookingsSuccessMessages,
  bookingsFailedMessages,
  locale,
}) => {
  const { reservationStartDate, reservationEndDate } = getReservationDatesFromFilter({
    filter,
    timeZone,
    selectedDay,
    showDaysSelector: true,
    showDurationSelection: false,
  });

  const reservation = {
    teamId,
    summary,
    start: reservationStartDate,
    end: reservationEndDate,
    startTimeZone: timeZone,
    endTimeZone: timeZone,
    isAllDayBooking: true,
    attendees,
    desks,
  };

  try {
    const newReservationData = await createTeamBooking({
      tenantId,
      reservation,
    });
    // flag that at least one reservation was successful
    handleSuccessBookingMessages({
      selectedDay,
      timeZone: timeZone,
      bookingsSuccessMessages,
      locale,
    });
    bookingsSuccessMessages.successfullyReserved = true;
    safelyInvokeCallback(successCallback, newReservationData);
  } catch (error) {
    handleFailedBookingMessages({
      error: error as AxiosError,
      selectedDay,
      timeZone: timeZone,
      bookingsFailedMessages,
      locale,
    });
    safelyInvokeCallback(errorCallback, error as AxiosError, selectedDay);
  }
};

export type SaveMultiDayTeamBooking = (
  params: MultiDayTeamBookingMutationParams,
) => Promise<MultiDayTeamBookingMutationData>;
/**
 * Creates multiple bookings and handle the error for the ones that failed.
 */
export const saveMultiDayTeamBooking: SaveMultiDayTeamBooking = async ({
  tenantId,
  teamId,
  summary,
  timeZone,
  attendees,
  desks,
  selectedDays = [],
  filter,
  successCallback,
  errorCallback,
  locale,
}) => {
  const bookingsSuccessMessages = {
    // flag that is true if at least one reservation was successful, to display success message
    successfullyReserved: false,
    // string list of days for which reservation was successful
    successfullyReservedDaysMessage: '',
  };
  const bookingsFailedMessages = {
    // string list of days for which reservation creation failed
    failedDaysMessage: '',
  };

  if (selectedDays?.length === 0) {
    return Promise.resolve({
      ...bookingsSuccessMessages,
      ...bookingsFailedMessages,
      successfullyReserved: false,
      failedDaysString: NO_DAY_SELECTED,
    });
  }

  await Promise.all(
    selectedDays.map(async selectedDay => {
      await handleDayBooking({
        tenantId,
        teamId,
        summary,
        timeZone,
        attendees,
        desks,
        selectedDay,
        filter: { ...filter, allDay: true },
        locale,
        successCallback,
        errorCallback,
        bookingsSuccessMessages,
        bookingsFailedMessages,
      });
    }),
  );
  return Promise.resolve({
    ...bookingsSuccessMessages,
    ...bookingsFailedMessages,
  });
};
