import { Nullable } from '@engage-shared/utils/types';
import { MENU_ACTION_TYPES } from '@engage-shared/constants/menuActionTypes';
import { isValidEmail } from '@engage-shared/utils/validators';

const engageRegex = /engage:\/\/([^\s]+[^/])/;
const browserRegex = /http[s]?:\/\//;
const mailToRegex = /mailto:|\?.*/gm;

type IsUrlFn = (url?: Nullable<string>) => boolean;

/**
 * Returns true if the url is of the form engage://
 * The concierge menu urls using the form engage:// are associated with specific app actions
 * @param {String} url
 * @returns {boolean} True if the url form is engage://
 */
export const isEngageUrl: IsUrlFn = (url = null) => !!url && engageRegex.test(url);

export const isBrowserUrl: IsUrlFn = (url = null) => !!url && browserRegex.test(url);

/**
 * Returns true in case of valid url passed.
 * Examples of valid urls:
 *     - mailto:test@engage.com
 *     - mailto:test@engage.com?subject=Some subject
 * @param {string} url - passed url for check
 */
export const isValidEmailUrl: IsUrlFn = (url = null) => {
  const urlPrefix = 'mailto:';

  if (!url?.startsWith(urlPrefix)) {
    return false;
  }

  const [email] = url.split(mailToRegex).filter(item => item.length);

  return isValidEmail(email);
};

type ActionAndPaths = {
  action: Nullable<string>;
  paths: string[];
};

type GetActionFromEngageUrl = (url: Nullable<string>) => ActionAndPaths;

export const getActionFromEngageUrl: GetActionFromEngageUrl = (url = null) => {
  const isEngage = isEngageUrl(url);

  if (isEngage) {
    const result = url?.match(engageRegex);

    if (result) {
      // get Action
      const actions = result[1];
      const [action, ...paths] = actions.split('/');

      return { action, paths };
    }
  }

  const isBrowser = isBrowserUrl(url);
  if (isBrowser) {
    return { action: MENU_ACTION_TYPES.BROWSER, paths: [] };
  }

  if (isValidEmailUrl(url)) {
    return { action: MENU_ACTION_TYPES.MAIL_TO, paths: [] };
  }

  return { action: null, paths: [] };
};
