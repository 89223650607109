import { LIST_ITEM_EXTRA_HEIGHT } from './styled';
import moment from 'moment-timezone';
import { addDays, getDaysInMonth, setDate } from 'date-fns';
import { formatDate } from '@engage-shared/utils';
import { AgendaItem, AgendaItems } from '@engage-shared/api';

// TODO: Check that this returns the correct items

export interface AgendaDayItem {
  day: Date;
  events: AgendaItem[];
}

const getAgendaItemsForDay = (day: Date, data: AgendaItems): AgendaDayItem => {
  const items = data[formatDate(day)] ?? [];
  return { events: items, day };
};

export const parseAgendaItems = ({
  selectedDate,
  agendaItems,
}: {
  selectedDate: Date;
  agendaItems: AgendaItems;
}): AgendaDayItem[] => {
  const numberOfDaysInMonth = getDaysInMonth(selectedDate);
  let iterator = setDate(selectedDate, 1);
  let items: AgendaDayItem[] = [];
  for (let i = 0; i < numberOfDaysInMonth; i++) {
    const item = getAgendaItemsForDay(iterator, agendaItems);
    if (item) {
      items = items.concat(item);
    }
    iterator = addDays(iterator, 1);
  }
  return items;
};

export const getEventExtraHeight = (event: AgendaItem) => {
  const { isBookedForOther, isAllDayBooking, timeZone } = event;
  const localTimeZoneString = moment.tz.guess(true);

  const isLocalTimeSameAsBuilding = localTimeZoneString === timeZone;

  let extraHeight = isBookedForOther ? LIST_ITEM_EXTRA_HEIGHT : 0;
  if (!isAllDayBooking && !isLocalTimeSameAsBuilding) {
    // when Cancel button is displayed, the local time is on two lines (one with timezone string)
    extraHeight += LIST_ITEM_EXTRA_HEIGHT * 2;
  }

  return extraHeight;
};
