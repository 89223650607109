import { Icon } from '@engage-web/components/base';
import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

type DeskItemProps = {
  clickable: boolean;
};

export const DeskItem = styled.li<DeskItemProps>`
  padding: 0 13px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color4};
  height: 56px;
  font-weight: 600;
  color: ${({ theme }) => theme.typo2};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

export const DeskIcon = styled(Icon)`
  color: rgb(223, 228, 234);
  margin-right: 26px;
`;

export const Summary = styled.summary`
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  h2 {
    margin-bottom: 0;
  }

  svg {
    margin-left: 8px;
    margin-top: 2px;
    transition: transform 0.25s;
  }
  &::-webkit-details-marker {
    display: none;
  }
`;

export const Details = styled.details`
  &[open] summary svg {
    transform: rotate(90deg);
  }
`;
