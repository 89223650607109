import React, { PropsWithChildren } from 'react';
import { useAppLocation } from '@engage-web/router/hooks';
import { ORGANIZATION_POLICY_PATH, CALLBACK_PATH, ROOT_PATH } from '@engage-web/constants';
import { hasTokens } from '@engage-web/utils/config/tokenConfig';
import { useDeepLinking } from './hooks/useDeepLinking/useDeepLinking';
import { NavigateToDeepLink } from './NavigateToDeepLink';
import { CheckingMask } from './DeepLinkChecker.style';

/**
 * Route paths which shouldn't be skipped by deep link.
 * Acknowledgement policy should be displayed regardless of deep link presence.
 * Callback and root routes are essential for login flow to work properly.
 */
const NOT_SKIPPABLE_ROUTES = [ORGANIZATION_POLICY_PATH, CALLBACK_PATH, ROOT_PATH];

type DeepLinkCheckerProps = PropsWithChildren<object>;

export function DeepLinkChecker({ children }: DeepLinkCheckerProps) {
  const { pathname } = useAppLocation();
  const { isChecking, to: deepLink, clearDeepLink } = useDeepLinking();

  const isAuthenticated = hasTokens();
  const shouldDisplayLoader = isChecking && isAuthenticated;
  /**
   * Navigate to deep link path if all the following conditions met:
   * 1. deep link path exists
   * 2. user is authenticated
   * 3. user is not on a route which should not be skipped
   */
  const shouldNavigateToDeepLink =
    !!deepLink && isAuthenticated && NOT_SKIPPABLE_ROUTES.every(route => route !== pathname);

  if (shouldNavigateToDeepLink) {
    return <NavigateToDeepLink clearDeepLink={clearDeepLink} to={deepLink} />;
  }

  return (
    <>
      {shouldDisplayLoader ? <CheckingMask isLoading /> : null}
      {children}
    </>
  );
}
