import React, { useEffect, useState } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { Shape } from '../../../../components/Shape';
import { MarkerIcon } from '../MarkerIcon';
import { ThemeKey, ThemeManager } from '../../../../theme';
import { Coordinate, Space, type Shape as ShapeType } from '@floorplan/api';
import { svLivePersonLocationToShape } from './utils';
import { panToShape } from '../../../../utils';

const POINT_SIZE = 200;
const getPointSize = () => POINT_SIZE;

export type SVLivePersonLocationProps = {
  scale: number;
};

const SVLivePersonLocation = ({ scale }: SVLivePersonLocationProps) => {
  const [locationShape, setLocationShape] = useState<Space | null>(null);
  const fes = useFESContext();

  const handleShowSVLivePersonLocation = (location: Coordinate) => {
    const personLocationShape = svLivePersonLocationToShape(location);
    fes.trigger(FES_EVENTS.CLEAR_SHOW_SPACE);
    setLocationShape(personLocationShape);
    const viewport = fes.getViewer()?.getViewer().viewport;
    if (viewport && personLocationShape) {
      panToShape({
        viewport,
        space: personLocationShape,
      });
    }
  };

  const handleHideSVLivePersonLocation = () => {
    setLocationShape(null);
  };

  useEffect(() => {
    fes.on(FES_EVENTS.SHOW_SV_LIVE_PERSON_LOCATION, handleShowSVLivePersonLocation);
    return () => {
      fes.off(FES_EVENTS.SHOW_SV_LIVE_PERSON_LOCATION, handleShowSVLivePersonLocation);
    };
  }, [fes]);

  useEffect(() => {
    fes.on(FES_EVENTS.HIDE_SV_LIVE_PERSON_LOCATION, handleHideSVLivePersonLocation);
    return () => {
      fes.off(FES_EVENTS.HIDE_SV_LIVE_PERSON_LOCATION, handleHideSVLivePersonLocation);
    };
  }, [fes]);

  if (!locationShape) {
    return null;
  }

  return (
    <>
      <Shape
        isPoint
        space={locationShape}
        shape={locationShape.shape as ShapeType}
        style={ThemeManager.getStyle(ThemeKey.unAvailableSpace)}
        getPointSize={getPointSize}
        scale={scale}
      />
      <MarkerIcon space={locationShape} scale={scale} />
    </>
  );
};

export default SVLivePersonLocation;
