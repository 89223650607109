import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { EngageIcons, Icon } from '../Icons/Icon';
import { Wrapper, Children } from './styled';

interface EmptyCardProps {
  iconName: EngageIcons;
  iconSize?: number;
  children: React.ReactNode;
  isErrorFloorplan?: boolean;
}

const EmptyCard = ({
  iconName,
  iconSize = 24,
  children,
  isErrorFloorplan = false,
}: EmptyCardProps) => {
  const { color9 } = useTheme();
  return (
    <Wrapper data-testid="empty-card" $isErrorFloorplan={isErrorFloorplan}>
      {iconName && <Icon name={iconName} size={iconSize} style={{ color: color9 }} />}
      <Children>{children}</Children>
    </Wrapper>
  );
};

export default memo(EmptyCard);
