import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import {
  inAppNotificationsActions,
  navigationActions,
  navigationSelectors,
} from '@engage-web/store';
import { DATE_TIME_PICKER_VARIANT, DOCUMENT_TITLE_LEVEL } from '@engage-web/constants';
import {
  FocusId,
  getModalDateTimePickerTitle,
  useFocus,
  useLocalTimeFormatH12,
  useSetPageTitle,
} from '@engage-web/utils/hooks';
import { useDTPConfig } from '@engage-web/components/base/DateTime/DateTimePicker/useDTPConfig';
import DateTimePicker from './DateTimePicker';

/**
 * DateTimePicker to be used in components/scenes/modals/content/Content.js
 * @returns {JSX.Element}
 */
export function ModalDateTimePicker() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isTimeFormatH12 = useLocalTimeFormatH12();

  const {
    content: { variant, dateStart, returnFocusTo = FocusId.TimelineButton },
  } = useSelector(navigationSelectors.getModalLayout);

  const { selectedDate, onDateSelected, onClear, minDate, maxDate, timeZone } = useDTPConfig({
    variant,
  });

  useFocus({
    focusOn: FocusId.TimelineSelectButton,
    returnFocusTo,
  });

  useSetPageTitle(getModalDateTimePickerTitle(variant, t), DOCUMENT_TITLE_LEVEL.MODAL);

  const showAvailabilityInfo = variant !== DATE_TIME_PICKER_VARIANT.INTERVAL_END;

  const handleClose = useCallback(
    (selectedDate: Date, isToday: boolean) => {
      // validate To date to be after From date
      if (
        variant === DATE_TIME_PICKER_VARIANT.INTERVAL_END &&
        dateStart &&
        !isAfter(selectedDate, dateStart)
      ) {
        dispatch(
          inAppNotificationsActions.addErrorNotification({
            message: t('layout.notifications.datePickerInvalidToDateError'),
          }),
        );

        return;
      }

      if (isToday) {
        onClear();
      } else {
        onDateSelected(selectedDate);
      }
      dispatch(navigationActions.clearModal());
    },
    [dispatch, dateStart, onClear, onDateSelected, variant, t],
  );

  return (
    <DateTimePicker
      initialDate={selectedDate}
      showAvailabilityInfo={showAvailabilityInfo}
      minDate={minDate}
      maxDate={maxDate}
      isTimeFormatH12={isTimeFormatH12}
      handleClose={handleClose}
      timeZone={timeZone}
      returnFocusTo={returnFocusTo}
    />
  );
}
