import React, { memo } from 'react';
import { ClientLogo } from '@engage-web/components/base';
import { KioskCurrentDateTime } from '@engage-web/components/kiosk';
import { useDialog } from '@engage-web/utils';
import { FloorplanViewsIcon } from '@engage-web/components/scenes/modals/components/FloorplanViewsIcon';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from '@engage-web/store';
import { UserContainer } from './UserContainer';
import { AgendaButton } from './AgendaButton';
import { BackButton } from './BackButton';
import { ExitDeskCheckInButton } from './ExitDeskCheckInButton';
import { DateTimeSelector } from './DateTimeSelector';
import { TeamBookingStartDate } from './TeamBookingStartDate';
import { Center, FloorplanViewsWrapper, Left, Right, SecondLineWrapper, Wrapper } from './styled';

export type HeaderProps = {
  isDeskCheckInOn?: boolean;
  conciergeMenuAllowed?: boolean;
  hasDateTime?: boolean;
  hasUserButton?: boolean;
  hasAgendaButton?: boolean;
  isKiosk?: boolean;
};

function Header({
  hasDateTime = true,
  hasUserButton = true,
  hasAgendaButton = true,
  isDeskCheckInOn = false,
  conciergeMenuAllowed = false,
  isKiosk = false,
}: HeaderProps) {
  const { isOpen } = useDialog();

  const hasFloorplanViews = useSelector(userConfigSelectors.getEnableFloorplanViews);

  const getLeftComponent = () => {
    if (isKiosk) {
      if (!conciergeMenuAllowed) return null;
      return <BackButton />;
    }
    if (isDeskCheckInOn) {
      return <ExitDeskCheckInButton />;
    }
    return conciergeMenuAllowed ? <BackButton /> : <ClientLogo disableFallback />;
  };

  const getCenterComponent = () => {
    if (isDeskCheckInOn) return <TeamBookingStartDate />;

    return hasDateTime ? <DateTimeSelector /> : null;
  };

  const getRightComponent = () => {
    if (isKiosk) {
      return <KioskCurrentDateTime />;
    }
    return [
      hasAgendaButton ? <AgendaButton key="agenda-button" /> : null,
      hasUserButton ? <UserContainer key="user-container" /> : null,
    ];
  };

  const getSecondLine = () => {
    if (hasFloorplanViews) {
      return (
        <SecondLineWrapper>
          <FloorplanViewsWrapper>
            <FloorplanViewsIcon />
          </FloorplanViewsWrapper>
        </SecondLineWrapper>
      );
    }
    return null;
  };

  return (
    <>
      <Wrapper $isDialogOpen={isOpen} $isKiosk={isKiosk} data-testid="header">
        <Left>{getLeftComponent()}</Left>
        <Center>{getCenterComponent()}</Center>
        <Right>{getRightComponent()}</Right>
      </Wrapper>
      {getSecondLine()}
    </>
  );
}

export default memo(Header);
