import {
  DurationButtonInput,
  DurationButtonLabel,
} from '@engage-web/components/base/Duration/styled';
import styled from 'styled-components';

export const DurationSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${DurationButtonInput} {
    margin-right: 0;
  }
`;

export const SelectorButton = styled.button`
  height: 20px;
  width: 20px;
  margin: 9px 0;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color2};

  &:hover {
    cursor: pointer;
  }

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.color2};
  font-size: 14px;
  padding: 0 4px;
`;

export const DurationSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${DurationButtonLabel} {
    margin: 0;
    &:hover {
      cursor: default;
    }
  }
`;

export const NumberInput = styled.input<any>`
  // styles to remove number field arrows
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  color: ${({ theme }) => theme.color1};
  border: 2px solid ${({ theme }) => theme.color9};
  background-color: ${({ theme }) => theme.color4};
  font-size: ${({ theme }) => (theme.isLayoutRTL ? '12px' : '14px')};
  font-weight: bold;
  height: 38px;
  border-radius: 19px;
  text-align: center;
  width: 60px;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
