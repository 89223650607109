import { createElement } from 'react';
import React from 'react';

type ElementType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';

interface TextProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  as?: ElementType;
}
export const Text = (props: TextProps) => {
  const { as, children, ...rest } = props;

  const elementType = as ?? 'div';

  return createElement(elementType, rest, children);
};
