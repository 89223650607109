import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyCard } from '@engage-web/components/base';
import { useTeamBookingDetails } from '@engage-web/api/queries';
import { useOnNavigateBack } from '@engage-web/utils/hooks/useOnNavigateBack';
import { BookingCard } from './Card/BookingCard';
import BookingCardSkeleton from './Card/BookingCardSkeleton';
import { useTeamBookingCallbacks, useGoBackFromBooking } from './hooks';
import { PersonId, TeamBookingId } from '@engage-shared/api';

type TeamBookingProps = {
  event: {
    meetingId: TeamBookingId;
    attendeeId: PersonId;
  };
};

const TeamBooking = ({ event }: TeamBookingProps) => {
  const { t } = useTranslation();
  const teamBookingCallbacks = useTeamBookingCallbacks();

  const { meetingId, attendeeId } = event || {};

  const {
    data: booking,
    isLoading,
    isError,
    isIdle,
  } = useTeamBookingDetails(meetingId, teamBookingCallbacks);

  const { goBack } = useGoBackFromBooking({ booking });
  useOnNavigateBack(goBack);

  if (isLoading || isIdle) {
    return <BookingCardSkeleton />;
  }

  if (isError || !booking) {
    return <EmptyCard iconName="info">{t('common.networkError')}</EmptyCard>;
  }

  return <BookingCard booking={booking} onBack={goBack} attendeeId={attendeeId} />;
};

export default memo(TeamBooking);
