import { useQuery, UseQueryOptions } from 'react-query';
import { downloadImage } from '@engage-web/api/spaces';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { Nullable } from '@engage-shared/utils/types';

const cacheTime = 1000 * 60 * 30; // 30 min
const staleTime = 1000 * 60 * 10; // 10 min

interface UseFetchImageUrlParams {
  url: string;
  queryKey: [string, string, string];
  options?: UseQueryOptions<Nullable<string>, unknown, Nullable<string>, [string, string, string]>;
}

export function useFetchImageURL({ url, queryKey, options = {} }: UseFetchImageUrlParams) {
  const { enabled = true, ...otherOptions } = options;
  const tenantId = useSelector(tenantSelectors.getTenantId);

  return useQuery<Nullable<string>, unknown, Nullable<string>, [string, string, string]>(
    queryKey,
    () => downloadImage(url, tenantId),
    {
      enabled: enabled && !!url && !!tenantId,
      cacheTime,
      staleTime,
      retry: false,
      ...otherOptions,
    },
  );
}
