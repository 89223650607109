import { useEffect } from 'react';
import { Api } from '@floorplan/api';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { default as sharedApi } from '@engage-shared/api/instance/api';

export const useConfigApi = () => {
  const tenantId = useSelector(tenantSelectors.getTenantId);

  useEffect(() => {
    Api.setAxios(sharedApi);
    Api.setBaseUrl(`https://${tenantId}.serraview.com`);
  }, [tenantId]);
};
