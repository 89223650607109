import { useMemo } from 'react';
import { useUser } from '@engage-web/api/queries/useUser';
import { useSearch } from './useSearch';
import { PersonData } from '@engage-shared/api';
import { RESTRICT_TO_VALUES } from '@engage-shared/constants';

export type FavouritePerson = PersonData & { name: string };
type UseFavouritePeopleParams = {
  enabled?: boolean;
  shouldIncludeCurrentUser?: boolean;
};

type UseFavouritePeople = (params?: UseFavouritePeopleParams) => {
  people?: FavouritePerson[];
  peopleCount?: number;
  isLoading: boolean;
};

// @ts-ignore
export const useFavouritePeople: UseFavouritePeople = ({
  enabled,
  shouldIncludeCurrentUser = true,
} = {}) => {
  const { result: searchResult } = useSearch({
    restrictTo: RESTRICT_TO_VALUES.PERSON,
    disableSearchString: true,
    enabled,
  });

  const { data, isLoading } = searchResult;

  const { isLoading: isUserLoading, data: user } = useUser({
    enabled: enabled && shouldIncludeCurrentUser,
  });

  const people = useMemo(() => {
    const _people = data?.pages.flatMap(page => page.data).filter(page => page.isFavourite);

    if (user && shouldIncludeCurrentUser && _people?.length) {
      const containsCurrentUser = _people?.some(({ id }) => id === user.id);
      if (!containsCurrentUser) {
        // @ts-ignore
        _people?.push(user);
      }
    }

    return _people;
  }, [data?.pages, shouldIncludeCurrentUser, user]);

  const peopleCount = people?.reduce(prev => prev + 1, 0);

  return { people, peopleCount, isLoading: isUserLoading || isLoading };
};
