import styled from 'styled-components';
import { LIST_ITEM_PADDING_VERTICAL } from '@engage-web/components/layouts/GlobalSearch/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${LIST_ITEM_PADDING_VERTICAL}px 5px;
  border-bottom: 1px solid ${({ theme }) => theme.color5};
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  direction: ltr;
`;

interface BadgeWrapperProps {
  iconBackgroundColor: string;
  iconColor?: string;
}
export const BadgeWrapper = styled.div<BadgeWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.color6};
  width: 16px;
  height: 16px;
  padding: 4px;
  background-color: ${props => props.iconBackgroundColor};
  color: ${props => props.iconColor};
`;

export const OrganizerBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.color9};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
`;

export const Title = styled.h6`
  color: ${({ theme }) => theme.color1};
`;

export const SubtitleWrapper = styled.div`
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.typo2};
  opacity: 0.8;
`;

export const Subtitle = styled.div`
  color: ${props => props.theme.typo2};
`;

export const IconWrapper = styled.div`
  margin-left: auto;
  padding-right: 10px;
  color: ${({ theme }) => theme.color3};
`;
