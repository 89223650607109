import { AxiosInstance, ResponseType } from 'axios';
import { default as sharedApi } from '@engage-shared/api/instance/api';

type ApiHeaders = Record<string, string>;

interface FetchOptions {
  url: string;
  method: RequestInit['method'];
  headers?: ApiHeaders;
  pureResponse?: boolean;
  responseType?: ResponseType;
}

export const Api = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  } as ApiHeaders,
  baseURL: 'http://localhost:3000',
  axiosApi: null as AxiosInstance | null,

  setToken(token: string) {
    this.headers.Authorization = `Bearer ${token}`;
  },
  setBaseUrl(baseURL: string) {
    this.baseURL = baseURL;
  },
  setAxios(api: AxiosInstance) {
    Api.axiosApi = api;
  },
  fetch(options: FetchOptions) {
    if (!Api.axiosApi) {
      Api.setAxios(sharedApi);
      if (!Api.axiosApi) {
        console.log('Api.axiosApi is not set');
        return;
      }
    }
    const { url, method, headers, responseType } = options;
    const axiosUrl = new URL(url, this.baseURL).toString();

    return Api.axiosApi({
      url: axiosUrl,
      method,
      headers: {
        ...this.headers,
        ...headers,
      },
      responseType,
    })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
};
