import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 80px 30px 30px 30px;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.typo1};
`;

export const Title = styled.h2`
  margin-bottom: 30px;
`;

export const InstructionsContainer = styled.p`
  line-height: 30px;
  margin-top: 10px;
`;

export const InstructionTitle = styled.span`
  font-weight: 600;
`;
