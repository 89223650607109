import { createSelector, createSlice } from '@reduxjs/toolkit';

export const TEAM_RESERVATION_DESKS_SELECTION_MODE = {
  PAINT: 'paint',
  ERASE: 'erase',
  DISABLED: 'disabled',
};

export const initialState = {
  teamDesksSelectionMode: TEAM_RESERVATION_DESKS_SELECTION_MODE.DISABLED,
  selectedDesks: [],
  selectedTeamMemberIds: [],
  teamSize: 0,
  isSelectionComplete: false,
  shouldClearDesksSelection: false,
  teamBookingId: null,
};

const teamReservations = createSlice({
  name: 'teamReservations',
  initialState,
  reducers: {
    setSelectedTeamMembersIds(state, action) {
      state.selectedTeamMemberIds = action.payload;
    },
    setTeamDesksSelectionMode(state, action) {
      state.teamDesksSelectionMode = action.payload;
    },
    addDesksToTeamReservation(state, action) {
      state.selectedDesks = action.payload;
      if (state.selectedDesks.length === state.teamSize) {
        state.isSelectionComplete = true;
        state.teamDesksSelectionMode = TEAM_RESERVATION_DESKS_SELECTION_MODE.ERASE;
      } else {
        state.isSelectionComplete = false;
        state.teamDesksSelectionMode = TEAM_RESERVATION_DESKS_SELECTION_MODE.PAINT;
      }
      state.shouldClearDesksSelection = false;
    },
    setTeamSize(state, action) {
      state.teamSize = action.payload;
    },
    startTeamReservationDesksSelection(state, action) {
      state.teamSize = action.payload.selectedTeamMemberIds.length;
      state.selectedTeamMemberIds = action.payload.selectedTeamMemberIds;
      state.teamDesksSelectionMode = TEAM_RESERVATION_DESKS_SELECTION_MODE.PAINT;
    },
    setTeamBookingId(state, action) {
      state.teamBookingId = action.payload;
    },
    clearTeamBookingId(state) {
      state.teamBookingId = null;
    },
    clear() {
      return initialState;
    },
    clearSelection: state => {
      state.shouldClearDesksSelection = true;
      // after clearing the floorplan, clear() is also called which initializes state, see webs/Floorplan.js
    },
  },
});

export const isTeamReservationsEnabled = mode =>
  mode !== TEAM_RESERVATION_DESKS_SELECTION_MODE.DISABLED;

const getTeamReservations = state => state.teamReservations;

const getTeamReservationSelectedDesks = createSelector(
  getTeamReservations,
  state => state.selectedDesks,
);
const getTeamSelectedMembersIds = createSelector(
  getTeamReservations,
  state => state.selectedTeamMemberIds,
);
const getTeamReservationDesksSelectionMode = createSelector(
  getTeamReservations,
  state => state.teamDesksSelectionMode,
);
const getTeamSize = createSelector(getTeamReservations, state => state.teamSize);
const getIsSelectionComplete = createSelector(
  getTeamReservations,
  state => state.isSelectionComplete,
);
const getShouldClearDesksSelection = createSelector(
  getTeamReservations,
  state => state.shouldClearDesksSelection,
);
const getTeamBookingId = createSelector(getTeamReservations, state => state.teamBookingId);
const getIsInDeskCheckInMode = createSelector(getTeamBookingId, id => id !== null);

export const teamReservationsSelectors = {
  getTeamReservations,
  getTeamReservationSelectedDesks,
  getTeamReservationDesksSelectionMode,
  getTeamSize,
  getIsSelectionComplete,
  getShouldClearDesksSelection,
  getTeamBookingId,
  getIsInDeskCheckInMode,
  getTeamSelectedMembersIds,
};

export default teamReservations;
