import { createSlice, createSelector } from '@reduxjs/toolkit';
import { TenantState, Tenant } from '@engage-web/store/tenant/types';

const initialState: TenantState = {
  kioskConfig: {
    userLocation: {},
    kioskLocation: {},
    isLoaded: false,
  },
  tenantId: null,
  nextTenantId: null,
  config: {},
  theme: {},
  userLocation: {},
  userId: null,
  pastTenants: {},
};

const tenant = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenant(state, action) {
      state.tenantId = action.payload.tenantId;
      state.config = action.payload.config;
      state.theme = action.payload.theme;
    },
    setCurrentLocation(state, action) {
      state.userLocation = action.payload;
    },
    setKioskConfig(state, action) {
      state.kioskConfig = action.payload;
      state.userLocation = action.payload.userLocation;
    },
    resetKioskConfig(state) {
      state.kioskConfig = initialState.kioskConfig;
      state.userLocation = initialState.userLocation;
    },
    loadCurrentLocationFromKioskConfig(state) {
      state.userLocation = state.kioskConfig.userLocation;
    },
    setUser(state, action) {
      state.userId = action.payload;
    },
    clear(state) {
      state.tenantId = null;
      state.config = {};
      state.theme = {};
      state.userLocation = {};
      state.userId = null;
      state.showSocialDistancingInfo = false;
      state.kioskConfig = initialState.kioskConfig;
    },
    addTenant(state, action) {
      state.pastTenants[action.payload.id] = action.payload;
    },
    setNextTenantId(state, action) {
      state.nextTenantId = action.payload.nextTenantId;
    },
    clearNextTenantId(state) {
      state.nextTenantId = null;
    },
  },
});

const getTenant = (state: TenantState) => state.tenant;

const getConfig = createSelector(getTenant, tenant => tenant.config);

const getCurrentLocation = createSelector(getTenant, tenant => tenant.userLocation);

const getKioskConfig = createSelector(getTenant, tenant => tenant.kioskConfig);

const getKioskId = createSelector(getKioskConfig, ({ spaceId }) => spaceId);

const getCurrentFloorId = createSelector(getCurrentLocation, location => location.floor?.id || 0);

const getUserId = createSelector(getTenant, tenant => tenant.userId);

const getCurrentTimezone = createSelector(
  getCurrentLocation,
  currentLocation => currentLocation?.building?.timeZone || null,
);

const getPastTenants = createSelector(
  getTenant,
  tenant => Object.values(tenant.pastTenants) as Tenant[],
);

export const tenantSelectors = {
  getTenantId: (state: TenantState) => state.tenant.tenantId,
  getTenantIdToLogin: (state: TenantState) => state.tenant.nextTenantId || state.tenant.tenantId,
  getTheme: (state: TenantState) => state.tenant.theme,
  getConfig,
  getCurrentLocation,
  getCurrentFloorId,
  getUserId,
  getCurrentTimezone,
  getPastTenants,
  getKioskConfig,
  getKioskId,
};

export default tenant;
