import React, { CSSProperties, ReactNode } from 'react';
import { replaceLineFeedWithSpace } from '../../../../utils';
import { fontClassesHeights, fontTagHeights } from '../../../../styles';
import SizeRecognitionWrapper from '../SizeRecognitionWrapper';
import { SearchResponseData } from '@engage-shared/api/search';
import { ParsedTeamMember } from '../../../layouts/Team/TeamMembers/TeamMembersList';
import { useTranslation } from 'react-i18next';
import AvatarImage, { AvatarImageProps } from '../../../base/Avatar/AvatarImage';
import {
  LIST_ITEM_PADDING_VERTICAL,
  ListItemOuterWrapper,
  ListItemWrapper,
  TextWrapper,
  Title,
  SubtitleWrapper,
} from '../styled';
import PersonDescription from './PersonDescription/PersonDescription';

export type PersonItemData = (SearchResponseData & { imageUrl: string }) | ParsedTeamMember;

export type PersonItemItem = {
  id: number;
  name: string;
  email?: string;
  description: string;
  imageUrl?: string;
  initials: string;
  isFavourite?: boolean;
  isFireWarden?: boolean;
  isFirstAidOfficer?: boolean;
};

export type PersonItemProps = {
  style?: CSSProperties;
  item: PersonItemItem;
  onClick?: (item: PersonItemData) => void;
  onSizeFound?: (size: DOMRect, index: number) => void;
  index: number;
  as?: 'div';
  shouldShowGuest?: boolean;
  renderAvatar?: (avatarProps: AvatarImageProps) => ReactNode;
};

export const PersonItem = ({
  style,
  item,
  onClick,
  onSizeFound,
  index,
  as,
  shouldShowGuest,
  renderAvatar,
}: PersonItemProps) => {
  const { t } = useTranslation();
  const { name, imageUrl, initials } = item;

  const displayName = shouldShowGuest ? t('layout.reserve.guest') : replaceLineFeedWithSpace(name);

  const avatarProps: AvatarImageProps = {
    initials,
    name,
    source: shouldShowGuest ? undefined : imageUrl,
    icon: shouldShowGuest ? 'personNew' : null,
    size: 'small',
    style: { background: 'transparent' },
  };

  return (
    <ListItemOuterWrapper {...(!!as && { as })} $style={style}>
      <SizeRecognitionWrapper onSizeFound={onSizeFound} index={index}>
        <ListItemWrapper
          aria-label={`${t('accessibilityLabels.viewPersonItem')} ${displayName}`}
          onClick={() => onClick?.(item)}
        >
          {renderAvatar ? renderAvatar(avatarProps) : <AvatarImage {...avatarProps} />}
          <TextWrapper>
            <Title>{displayName}</Title>
            <SubtitleWrapper>
              <PersonDescription item={item} />
            </SubtitleWrapper>
          </TextWrapper>
        </ListItemWrapper>
      </SizeRecognitionWrapper>
    </ListItemOuterWrapper>
  );
};

// TODO: make generic function don't attach to PersonItem
PersonItem.getSize = () =>
  LIST_ITEM_PADDING_VERTICAL * 2 +
  // @ts-ignore
  fontTagHeights.h6.withMargins +
  // @ts-ignore
  fontClassesHeights.btn.withMargins;

export default PersonItem;
