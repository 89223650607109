import { toDate } from 'date-fns';
import { VISIBILITY } from '@engage-web/constants';
import { TFunction } from 'react-i18next';

export interface LocationName {
  name: string;
}

/**
 * Checks if a string can be converted to a valid number
 */
const isStringNumeric = (value: unknown) => {
  if (typeof value === 'string' && value.trim() === '') {
    return false;
  }
  //
  if (typeof value === 'string' && value.startsWith('0')) {
    return false;
  }

  const number = Number(value);
  if (number === Infinity || number === -Infinity) {
    return false;
  }

  // eslint-disable-next-line eqeqeq
  return value == number;
};

// it is possible from BE side to set floor name to be a string
// if it is not a "just number" we just display it as it is
// if it is "just number" we will go format it to be 1st, 2nd, 4th
function humanizeNumber(string: string, translate: TFunction) {
  if (!isStringNumeric(string)) return string;
  const sufficeOne = translate('plurals.one');
  const sufficeTwo = translate('plurals.two');
  const sufficeFew = translate('plurals.few');
  const sufficeMany = translate('plurals.many');

  const number = parseInt(string, 10);
  if (number % 100 >= 11 && number % 100 <= 13) return `${number}${sufficeMany}`;
  switch (number % 10) {
    case 1:
      return `${number}${sufficeOne}`;
    case 2:
      return `${number}${sufficeTwo}`;
    case 3:
      return `${number}${sufficeFew}`;
    default:
      return `${number}${sufficeMany}`;
  }
}

/**
 * Check a value is boolean
 */
const isBoolean = (value: unknown) =>
  typeof value === 'boolean' || value === 'false' || value === 'true';

const replaceLineFeedWithSpace = (text: string) => (text ? text.replace('\x0A', ' ') : '');

const getFileNameFromUrl = (url: string) =>
  url ? url.substring(url.lastIndexOf('/') + 1, url.indexOf('?')) : null;

const getFileExtension = (fileName: string) => {
  const lastIndex = fileName.lastIndexOf('.');
  return lastIndex > 0 ? fileName.substr(lastIndex + 1).toLowerCase() : null;
};

const isObjectEmpty = (obj: any) => Object.keys(obj).length === 0 && obj.constructor === Object;

const getDateFromDateObject = (date: Date) => {
  const dateCopy = toDate(date);
  dateCopy.setHours(0, 0, 0, 0);
  return dateCopy;
};

const isDateInArray = (date: Date, array: Date[]) =>
  array.some(
    arrayDate =>
      getDateFromDateObject(arrayDate).getTime() === getDateFromDateObject(date).getTime(),
  );

/**
 * Allows to sequentially call multiple functions with the same dataset
 * Each function takes the output of the previous one
 * @example
 * const join = x => x.join(' ');
 * const toUpper = x => x.toUpperCase();
 * pipe(join, toUpper)(['hello', 'world']) // 'HELLO WORLD'
 */
const pipe =
  <T>(...fns: Array<(arg: T) => T>) =>
  (value: T) =>
    fns.reduce((acc, fn) => fn(acc), value);

/**
 * Allow to apply map function to object
 */
const mapObject = (obj: any, mapFn: any) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const newValue = mapFn(key, value);
      return [key, newValue];
    }),
  );

const getClosestNumber = (num: number, arr: number[]) =>
  arr.reduce((a, b) => (Math.abs(b - num) < Math.abs(a - num) ? b : a));

/**
 * Check if a passed number is in a given range or is Nan, used for NumberInputCounter
 */
const isInputValid = (number: number, maxValue: number, minValue: number) =>
  Number.isNaN(number) || (number <= maxValue && number >= minValue); // When input filed is empty we got Nan

const sortByAlphaNum = (a: string, b: string) => {
  if (typeof a !== 'string' || typeof b !== 'string') {
    return 1;
  }
  return a.localeCompare(b, undefined, { numeric: true });
};

const getLocalTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const getIsVisible = (visibility: string) => visibility === VISIBILITY.SHOW;

const firstLetterCapitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const getBuildingAndFloorNames = (building: LocationName, floor: LocationName, t: TFunction) => {
  const buildingName = building?.name ?? '';
  const floorName = floor?.name ? `${humanizeNumber(floor.name, t)} ${t('common.floor')}` : '';

  return {
    buildingName,
    floorName,
  };
};

const focusElementById = (id: string) => {
  const element = document.getElementById(id);
  element?.focus();
};

const changeLocationHref = (href: string) => {
  window.location.href = encodeURI(href);
};

export {
  isStringNumeric,
  isBoolean,
  humanizeNumber,
  replaceLineFeedWithSpace,
  getFileExtension,
  getFileNameFromUrl,
  isObjectEmpty,
  isDateInArray,
  pipe,
  mapObject,
  getClosestNumber,
  isInputValid,
  sortByAlphaNum,
  getLocalTimeZone,
  getIsVisible,
  firstLetterCapitalize,
  getBuildingAndFloorNames,
  focusElementById,
  changeLocationHref,
};
