import { useEffect } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { useSelector } from 'react-redux';
import { floorplanSelectors } from '@engage-web/store';

export const useSetFocusedSpace = () => {
  const fes = useFESContext();
  const focusedSpace = useSelector(floorplanSelectors.getFocusedSpace);
  const floorplanIsLoaded = useSelector(floorplanSelectors.getIsLoaded);

  useEffect(() => {
    if (!floorplanIsLoaded) return;
    if (focusedSpace) {
      fes.trigger(FES_EVENTS.SET_FOCUSED_SPACE, {
        id: focusedSpace.id,
      });
    } else {
      fes.trigger(FES_EVENTS.SET_FOCUSED_SPACE, {
        id: null,
      });
    }
  }, [focusedSpace, fes, floorplanIsLoaded]);
};
