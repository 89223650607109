import React, { memo, PropsWithChildren } from 'react';
import { AutoRotateIcon, FilterSelectorButton as Button } from './styled';

interface FilterSelectorButtonProps {
  type: 'spaceTypes' | 'spaceAttributes' | null;
  selectedType: 'spaceTypes' | 'spaceAttributes' | null;
  spaceType: 'Space' | 'Desk' | 'Team' | 'Person';
  onClick: (type: 'spaceTypes' | 'spaceAttributes' | null) => void;
}
const FilterSelectorButton = ({
  onClick,
  type,
  selectedType,
  children,
  spaceType,
}: PropsWithChildren<FilterSelectorButtonProps>) => {
  const expanded = selectedType === type;
  const buttonName = type ?? '';
  return (
    <Button
      aria-expanded={expanded}
      aria-controls={expanded ? `${type}${spaceType}` : undefined}
      onClick={() => onClick(type)}
      data-testid="filter-selector-button"
      role="button"
      name={buttonName}
    >
      <AutoRotateIcon
        $active={selectedType === type}
        name="chevron-right"
        size={11}
        data-testid="icon-arrow"
      />
      <span>{children}</span>
    </Button>
  );
};

export default memo(FilterSelectorButton);
