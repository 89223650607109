import { useToggleFavouriteMenuItem } from '@engage-web/utils/hooks/useToggleFavouriteMenuItem';
import { Nullable } from '@engage-shared/utils/types';
import { MenuProps } from '@engage-web/components/scenes/drawer/header/DrawerMenu/DrawerMenu';
import { FavouriteType, SpaceType } from '@engage-shared/api';

type UsePersonMenu = (props: { personId?: string; isFavourite?: boolean }) => Nullable<MenuProps>;

export const usePersonMenu: UsePersonMenu = ({ personId, isFavourite = false }) => {
  const toggleFavouriteMenuItem = useToggleFavouriteMenuItem({
    isFavourite,
    itemId: personId,
    itemType: FavouriteType.person,
    itemOriginalType: SpaceType.PERSON,
  });

  if (!personId) return null;

  return toggleFavouriteMenuItem ? [toggleFavouriteMenuItem] : null;
};
