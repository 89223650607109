import { RESERVATION_ANALYTICS_TYPES } from './types';

export const RESERVATION_ANALYTICS_TYPE: RESERVATION_ANALYTICS_TYPES = {
  START: 'start',
  SUCCESS: 'success',
  FAIL: 'fail',
  DURATION_CHANGED: 'duration_changed',
  ADMIN_BUTTON_TAP: 'admin_button_tap',
  RESERVEE_SELECTED: 'reservee_selected',
};
