import { GlobalFilters } from './TypesAndAttributesFilter';

export const getFilterDefaultType = (
  filter: GlobalFilters,
): 'spaceTypes' | 'spaceAttributes' | null => {
  if (filter.spaceTypes.length > 0) return 'spaceTypes';
  if (filter.spaceAttributes.length > 0) return 'spaceAttributes';
  return null;
};

export const toggleFilterItem = ({
  id,
  checked,
  selected,
}: {
  id: number;
  checked: boolean;
  selected: number[];
}) => (checked ? [...selected, id] : selected.filter(selectedId => selectedId !== id));
