import AuthApi from './authApi';
import { getAuthUrl } from './url';
import { handleErrorMessage } from './errors';
import { Tokens } from './interfaces';
import { getActiveTenantId } from './token-manager/token';
import { Nullable } from '@engage-shared/utils/types';

type Refresh = ({ refreshToken }: { refreshToken: Nullable<string> }) => Promise<Tokens>;

export const refresh: Refresh = async ({ refreshToken }) => {
  try {
    const tenantId = getActiveTenantId();
    if (tenantId) {
      const url = `${getAuthUrl(tenantId)}/refresh`;
      const response = await AuthApi.post<Tokens>(url, {
        refreshToken,
      });
      return {
        token: response.data.token,
        refreshToken: response.data.refreshToken,
      };
    }
    throw new Error(handleErrorMessage('invalid token'));
  } catch (error) {
    throw new Error(handleErrorMessage(error));
  }
};
