import React, { CSSProperties, memo, ReactNode } from 'react';
import { Icon } from '@engage-web/components/base';

import { SearchResponseData } from '@engage-shared/api/search';
import { useTranslation } from 'react-i18next';
import { IconWrapperFavourites } from './styled';

type FavouriteItemProps = {
  item: SearchResponseData;
  children: ReactNode;
  style?: CSSProperties;
  showToggleFavourite: boolean;
  onToggleFavourites: (item: SearchResponseData) => void;
};

const FavouriteItem = memo(
  ({ item, children, style, showToggleFavourite, onToggleFavourites }: FavouriteItemProps) => {
    const { t } = useTranslation();
    const { isFavourite, name } = item;

    const accessibilityTranslationKey = `accessibilityLabels.${
      isFavourite ? 'removeFromFavourites' : 'addToFavorites'
    }`;

    const onFavouritePress = () => onToggleFavourites(item);
    return (
      <li data-is-favourite={isFavourite ? true : undefined} style={style}>
        {children}
        {showToggleFavourite && (
          <IconWrapperFavourites
            aria-label={t(accessibilityTranslationKey, {
              name,
            })}
            onClick={onFavouritePress}
          >
            <Icon name={isFavourite ? 'minus' : 'plus'} size={16} />
          </IconWrapperFavourites>
        )}
      </li>
    );
  },
);

export default FavouriteItem;
