import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { DURATION_TYPES } from '@engage-web/constants';
import { DurationButton } from './DurationButton';
import { durationItems, IDurationItem } from './durationItems';
import { OrLabel, Label, DurationContainer, DurationButtonFieldset } from './styled';

const addLabelToDurationItems = (durationItems: IDurationItem[], t: TFunction) =>
  durationItems.map(item => {
    if (!item.display) return item;

    if (item.type === 'h') {
      return { ...item, text: `${item.display}${t('plurals.hoursShort')}` };
    }

    return item;
  });

interface DurationProps {
  type: 'MINUTES' | 'HOURS';
  onChange?: (value: number) => void;
  value: number;
  canDeselect: boolean;
  allDaySelected?: boolean;
  allDayDisabled?: boolean;
  onAllDayToggle?: (value: boolean) => void;
  renderOtherComponent?: () => void;
  onDurationOtherClick?: () => void;
  disabled: boolean;
}

export const Duration = ({
  type,
  value,
  canDeselect,
  onChange,
  renderOtherComponent,
  allDaySelected = false,
  allDayDisabled = false,
  onAllDayToggle,
  disabled,
  onDurationOtherClick,
}: DurationProps) => {
  const [isOther, setIsOther] = useState(false);
  const { t } = useTranslation();

  const label = t('components.duration.bookingDuration');
  const optionsLabel = t('components.duration.other');
  const orLabel = t('components.duration.or');
  const allDayLabel = t('components.duration.allDay');

  useEffect(() => {
    // if exists preselected other value for space meeting duration
    if (
      type === DURATION_TYPES.MINUTES &&
      value > 0 &&
      value !== 15 &&
      value !== 30 &&
      value !== 60 &&
      !isOther
    ) {
      setIsOther(true);
    }
  }, [type, value, isOther]);

  const onDurationButtonClick = (buttonValue: number) => {
    let newValue = buttonValue;

    if (type === DURATION_TYPES.MINUTES && buttonValue <= 60) {
      setIsOther(false);
    }

    if (canDeselect && buttonValue === value) {
      // Toggle the selected index if the item is already selected.
      newValue = 0;
    }

    onChange?.(newValue);
  };

  const onDurationOtherButtonClick = (buttonValue: number) => {
    if (!renderOtherComponent) {
      onChange?.(0);
    }

    onDurationButtonClick(buttonValue);
    setIsOther(true);
    onDurationOtherClick?.();
  };

  const getDurationMinutes = () => (
    <DurationContainer>
      {addLabelToDurationItems(durationItems[type], t).map(({ id, text, value: buttonValue }) => (
        <DurationButton
          key={id}
          text={text}
          value={buttonValue}
          onClick={onDurationButtonClick}
          selected={!isOther && value === buttonValue}
          disabled={disabled}
        />
      ))}

      <OrLabel>{orLabel}</OrLabel>

      <>
        {!!renderOtherComponent && (
          <DurationButton
            text={optionsLabel}
            value={0}
            selected={isOther}
            isOther={isOther}
            onClick={onDurationOtherButtonClick}
            disabled={disabled}
          />
        )}
        {isOther && renderOtherComponent?.()}
      </>
    </DurationContainer>
  );

  const getDurationHours = () => (
    <DurationContainer>
      <DurationButton
        text={allDayLabel}
        value={0}
        selected={allDaySelected}
        onClick={() => onAllDayToggle?.(true)}
        disabled={disabled || allDayDisabled}
      />

      <OrLabel>{orLabel}</OrLabel>

      {addLabelToDurationItems(durationItems[type], t).map(({ id, text, value: buttonValue }) => (
        <DurationButton
          key={id}
          text={text}
          value={buttonValue}
          onClick={onDurationButtonClick}
          selected={value === buttonValue}
          disabled={disabled}
        />
      ))}
    </DurationContainer>
  );

  return (
    <DurationButtonFieldset>
      <Label data-testid="durationLabel">{label}</Label>
      {type === DURATION_TYPES.MINUTES ? getDurationMinutes() : getDurationHours()}
    </DurationButtonFieldset>
  );
};
