import { generatePath, matchPath } from 'react-router-dom';
import {
  FLOOR_PATH,
  LOGIN_PATH,
  PERSON_PATH,
  ROOT_PATH,
  TEAM_PATH,
  WORKSPACE_PATH,
} from '@engage-web/constants';

/**
 * Generates an absolute floor path based on a floorId.
 */
type GenerateFloorPath = (floorId: string) => string;

export const generateFloorPath: GenerateFloorPath = floorId =>
  generatePath(FLOOR_PATH, { floorId });

/**
 * Generates an absolute path to the person based on floorId and personId.
 */
type GeneratePersonPath = (args: { personId: string; floorId: string }) => string;

export const generatePersonPath: GeneratePersonPath = params =>
  generatePath(`${FLOOR_PATH}${PERSON_PATH}`, params);

/**
 * Generates an absolute path to the team based on floorId and teamId.
 */
type GenerateTeamPath = (args: { teamId: string; floorId: string }) => string;

export const generateTeamPath: GenerateTeamPath = params =>
  generatePath(`${FLOOR_PATH}${TEAM_PATH}`, params);

/**
 * Joins provided path pieces ensuring that there are no `null` or `undefined`
 * elements and no redundant slashes.
 */
type JoinPaths = (...paths: (string | null | undefined)[]) => string;

export const joinPaths: JoinPaths = (...paths) =>
  paths
    .filter(p => !!p)
    .join('/')
    .replace(/\/+/g, '/');

/**
 * Returns redirect path.
 *
 * If there is tenant id in the store and
 *   a) user is authenticated – path will be /
 *   b) user is not authenticated – path will be /login
 * If no tenant id is stored – /workspace path is returned.
 */
type GetRedirectPath = (args: { isAuthenticated: boolean; hasTenantId: boolean }) => string;

export const getRedirectPath: GetRedirectPath = ({ isAuthenticated, hasTenantId }) => {
  if (hasTenantId) {
    return isAuthenticated ? ROOT_PATH : LOGIN_PATH;
  }

  return WORKSPACE_PATH;
};

/**
 * Returns whether we should redirect the user or not.
 */
type GetShouldRedirect = (args: {
  isAuthenticated: boolean;
  hasTenantId: boolean;
  path: string;
}) => boolean;

export const getShouldRedirect: GetShouldRedirect = ({ isAuthenticated, hasTenantId, path }) =>
  (isAuthenticated && (path === LOGIN_PATH || path === WORKSPACE_PATH)) ||
  (!isAuthenticated && !hasTenantId && path === LOGIN_PATH);

/**
 * Returns whether provided string is a valid deep link.
 */
type GetIsValidDeeplink = (maybeDeepLinkPath: string) => boolean;

export const getIsValidDeepLink: GetIsValidDeeplink = maybeDeepLinkPath => {
  if (!maybeDeepLinkPath) return false;

  const matchFloorRouter = matchPath(maybeDeepLinkPath, FLOOR_PATH);

  return !!matchFloorRouter;
};

/**
 * Removes a path segment from a provided pathname.
 */
type RemoveSegmentFromPath = (segment: string, path: string) => string;

export const removeSegmentFromPath: RemoveSegmentFromPath = (segment, path) =>
  path.replace(RegExp(`/${segment}`), '');

/**
 * Check search query string for valid deep linking params.
 */
type HasValidParamInSearchQuery = (searchQuery: string, params: Record<string, string>) => boolean;

export const hasValidParamInSearchQuery: HasValidParamInSearchQuery = (searchQuery, params) => {
  if (!searchQuery) return false;

  const query = new URLSearchParams(searchQuery);

  return Object.values(params).some(param => query.has(param));
};
