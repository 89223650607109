import { createSlice } from '@reduxjs/toolkit';
import { FLOORPLAN_VIEWS } from '@engage-web/constants';

const initialState = {
  drawerMenu: {
    visible: false,
  },
  heatmapVisible: false,
  appAccessDialogVisible: false,
  floorplanView: FLOORPLAN_VIEWS.FLOORPLAN,
};

export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setDrawerMenuVisibility(state, { payload }) {
      state.drawerMenu.visible = payload;
    },
    showAppAccessDialog(state) {
      state.appAccessDialogVisible = true;
    },
    hideAppAccessDialog(state) {
      state.appAccessDialogVisible = false;
    },
    setFloorplanView(state, { payload }) {
      state.floorplanView = payload;
    },
  },
});

export const uiSelectors = {
  getDrawerMenuVisibility: state => state.ui.drawerMenu.visible,
  getIsAppAccessDialogVisible: state => state.ui.appAccessDialogVisible,
  getFloorplanView: state => state.ui.floorplanView,
};
