import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Nullable } from '@engage-shared/utils/types';
import { DialogContext } from '@engage-web/utils/hooks/useDialog';

const CLOSE_DIALOG_EVENT = 'closeDialog';

export const DialogProvider = ({ children }: PropsWithChildren<object>) => {
  const [isOpen, setIsOpen] = useState(false);
  const dialogRef = useRef<Nullable<ReactElement>>(null);
  const closeListenerRef = useRef<any>();

  const getCloseDialogEventListener = useCallback((resolve: (value: any) => void) => {
    const listener = (event: CustomEvent<any>) => resolve(event.detail);
    closeListenerRef.current = listener;
    return listener;
  }, []);

  const openDialog = useCallback(
    async (dialog: ReactElement) => {
      dialogRef.current = dialog;
      setIsOpen(true);

      return new Promise<any>(resolve => {
        // @ts-ignore
        document.addEventListener<CustomEvent<boolean>>(
          CLOSE_DIALOG_EVENT,
          getCloseDialogEventListener(resolve),
        );
      }).finally(() => {
        document.removeEventListener(CLOSE_DIALOG_EVENT, closeListenerRef.current);
        closeListenerRef.current = null;
      });
    },
    [getCloseDialogEventListener],
  );

  const closeDialog = useCallback((response: any) => {
    dialogRef.current = null;
    setIsOpen(false);

    document.dispatchEvent(new CustomEvent(CLOSE_DIALOG_EVENT, { detail: response }));
  }, []);

  const dialog = useMemo(() => {
    const dialogRoot = document.getElementById('dialog-root');

    return isOpen && dialogRoot ? createPortal(dialogRef.current, dialogRoot) : null;
  }, [isOpen]);

  const context = useMemo(
    () => ({ isOpen, openDialog, closeDialog }),
    [closeDialog, isOpen, openDialog],
  );

  return (
    <DialogContext.Provider value={context}>
      {children}
      {dialog}
    </DialogContext.Provider>
  );
};
