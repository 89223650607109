import { useMutation, useQueryClient } from 'react-query';
import { cancelBooking } from '@engage-shared/api';
import {
  useRefreshAgendaEvents,
  useRefreshBookings,
  useRefreshSearch,
} from '@engage-web/api/queries';
import { useDispatch } from 'react-redux';
import log from '@engage-web/utils/logger';
import { useTranslation } from 'react-i18next';
import { inAppNotificationsActions, notificationsActions } from '@engage-web/store';
import { useNavigate } from 'react-router-dom';
import { useInvalidateFloorplanSpaces } from '@floorplan/api';
import { formatISODate, getLocalizedDate } from '@engage-shared/utils';

const useCancelBooking = booking => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { refresh: refreshSearch } = useRefreshSearch();
  const { refresh: refreshAgendaEvents, getQueryKey: getAgendaEventsQueryKey } =
    useRefreshAgendaEvents();
  const { refresh: refreshBookings } = useRefreshBookings();
  const { invalidateFloorplanSpaces } = useInvalidateFloorplanSpaces();

  const spaceId = booking?.spaceId;
  // use localised date because there is a problem with last day of the month booking
  // if today is Nov 30 but the booking was made for Dec 1
  // query key will have November (10th) month instead of December (11th)
  const localizedStartDate = getLocalizedDate({
    date: booking?.startDate,
    timeZone: booking?.localTimeZone,
  });
  // booking.meetingId comes as a number, but we need a string for comparison
  const meetingId = `${booking?.meetingId}`;
  const agendaEventsQueryKey = getAgendaEventsQueryKey(localizedStartDate);

  return useMutation(cancelBooking, {
    onMutate: async () => {
      const previousAgendaEvents = queryClient.getQueryData(agendaEventsQueryKey);

      // if there are no agenda events in the cache that means
      // that the booking was opened using deeplink,
      // hence, we don't need to do optimistic update then
      if (!previousAgendaEvents) return;

      await queryClient.cancelQueries(agendaEventsQueryKey);

      const selectedDate = formatISODate(localizedStartDate);

      queryClient.setQueryData(agendaEventsQueryKey, agendaEvents => {
        const nextEventsForSelectedDate = agendaEvents[selectedDate].filter(
          event => event.meetingId !== meetingId,
        );
        const nextAgendaEvents = { ...agendaEvents };
        nextAgendaEvents[selectedDate] = nextEventsForSelectedDate;

        return nextAgendaEvents;
      });

      // eslint-disable-next-line consistent-return
      return {
        previousAgendaEvents,
      };
    },
    onSuccess: () => {
      dispatch(
        inAppNotificationsActions.addSuccessNotification({
          message: t('layout.agendaList.cancelSuccessful'),
        }),
      );

      // refresh space bookings
      refreshBookings({ spaceItem: { id: spaceId } });

      // back-end sets end reservation time to 1 minute after current time, so refresh needs to happen after one minute
      setTimeout(() => {
        // refresh search list to update desk availability
        refreshSearch();
        // refresh floorplan space data
        invalidateFloorplanSpaces();
      }, 60000);
      // cancel notification
      dispatch(notificationsActions.cancelNotification({ id: `${booking.meetingId}` }));

      // since this modal uses history we can just go back
      // modal layout is cleared inside of useModal
      navigate(-1);
    },
    onError: (error, _, context) => {
      dispatch(
        inAppNotificationsActions.addErrorNotification({
          message: t('layout.agendaList.cancelError', {
            error: error.message,
          }),
        }),
      );

      log.error(error)();

      // revert previous data in case of error
      queryClient.setQueryData(agendaEventsQueryKey, context.previousAgendaEvents);
    },
    onSettled: () => {
      // refresh agenda events
      refreshAgendaEvents({ selectedDate: localizedStartDate });
    },
  });
};

export { useCancelBooking };
