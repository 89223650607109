import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenInfo, Icon } from '@engage-web/components/base';
import { AgendaEvent, useModal } from '@engage-web/utils';
import { MODALS } from '@engage-web/constants';
import { isElectron } from '@engage-web/utils/electron';

type BookingLocationUnavailableProps = { event: AgendaEvent };

const BookingLocationUnavailable = ({ event }: BookingLocationUnavailableProps) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const onClose = () => {
    if (isElectron()) {
      openModal(MODALS.USER_PROFILE);
    } else {
      openModal(MODALS.AGENDA_ITEM, {
        event: { ...event, locationUnavailableWasDisplayed: true },
      });
    }
  };

  const title = t('components.bookingLocationUnavailable.title');
  const message = t('components.bookingLocationUnavailable.message');
  const pageTitle = t('documentTitles.bookingLocationUnavailable');

  const renderIcon = () => <Icon name="warning" size={100} />;

  return (
    <FullScreenInfo
      title={title}
      message={message}
      pageTitle={pageTitle}
      onClose={onClose}
      iconButtonAriaLabelKey="components.bookingLocationUnavailable.a11yWarningIconLabel"
      iconComponent={renderIcon()}
    />
  );
};

export default memo(BookingLocationUnavailable);
