import React, { useCallback } from 'react';
import { QueryOptions, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { filtersSelectors, tenantSelectors } from '@engage-web/store';
import { AGENDA_EVENTS_KEY, useAgendaEvents as useAgendaEventsHook } from '@engage-shared/api';
import { useUser } from '@engage-web/api/queries/useUser';
import { getLocalTimeZone } from '@engage-web/utils';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment-timezone';
import { EVENTS_STATUS } from '@engage-shared/constants';

export const useAgendaEvents = (
  month: number,
  year: number,
  eventsFilter: EVENTS_STATUS,
  options: QueryOptions = {},
) => {
  const { t } = useTranslation();

  const { data } = useUser();
  const { personId, userId } = data || {};

  const userTimeZone = getLocalTimeZone();

  return useAgendaEventsHook({
    month,
    year,
    eventsFilter,
    // @ts-ignore
    personId,
    // @ts-ignore
    userId,
    userTimeZone,
    translationFn: t,
    // @ts-ignore
    options,
  });
};

export const useRefreshAgendaEvents = () => {
  const queryClient = useQueryClient();
  const agendaEventsType = useSelector(filtersSelectors.getAgendaEventsType);
  const userId = useSelector(tenantSelectors.getUserId);

  const getQueryKey = useCallback(
    (selectedDate: Moment) => {
      // selectedDate can be null if the bookings is still loading

      const month = selectedDate?.month();
      const year = selectedDate?.year();

      return [AGENDA_EVENTS_KEY, userId, month, year, agendaEventsType];
    },
    [userId, agendaEventsType],
  );

  const invalidateQuery = ({ selectedDate }: { selectedDate: Moment }) =>
    queryClient.invalidateQueries(getQueryKey(selectedDate));

  const refresh = React.useCallback(invalidateQuery, [queryClient, getQueryKey]);

  return { refresh, getQueryKey };
};
