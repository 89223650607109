import { getMenuItemClickHandler } from '@engage-web/components/scenes/ConciergeMenu/utils/getMenuItemClickHandler';
import { ConciergeFetchMenuItem } from '@engage-shared/api/conciergeMenu/interfaces';

import { isElectron } from '@engage-web/utils/electron';
import { getIsMenuItemHidden } from '@engage-web/components/scenes/ConciergeMenu/utils/getIsMenuItemHidden';

type TransformMenuItemParams = {
  menuItems: ConciergeFetchMenuItem[];
  dispatch: any;
  navigate: any;
  currentFloorId: number;
  email: string | undefined;
  heatmapKiosk?: boolean;
};

export const sortBySequenceAsc = (itemA: ConciergeFetchMenuItem, itemB: ConciergeFetchMenuItem) =>
  itemA.sequence - itemB.sequence;

export interface IMenuItem extends ConciergeFetchMenuItem {
  onMenuItemClick?: () => void;
}

export const transformMenuItems = ({
  menuItems,
  dispatch,
  navigate,
  currentFloorId,
  email,
  heatmapKiosk,
}: TransformMenuItemParams): IMenuItem[] =>
  menuItems
    .map(item => ({
      ...item,
      onMenuItemClick: getMenuItemClickHandler({
        url: item.url,
        openWithExternalBrowser: isElectron() ? false : item.openWithExternalBrowser,
        dispatch,
        navigate,
        currentFloorId,
        email,
        heatmapKiosk,
      }),
    }))
    .filter(
      item =>
        !getIsMenuItemHidden(
          item.url,
          item.hiddenForWeb,
          item.hiddenForKiosk,
          process.env.NODE_ENV,
        ),
    )
    .sort(sortBySequenceAsc);
