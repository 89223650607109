import React, { useCallback, useEffect } from 'react';
import { useAppLocation, useAppParams } from '@engage-web/router/hooks';
import { useTenantFetch } from '@engage-shared/api';
import { navigationActions, tenantActions } from '@engage-web/store';
import { useDispatch } from 'react-redux';
import {
  DATE_TIME_DEEP_LINK_PARAMS,
  DRAWER_DEEP_LINK_PARAMS,
  LanguageTypes,
  LOGIN_PATH,
  MODAL_DEEP_LINK_PARAMS,
  ROOT_PATH,
  WORKSPACE_PATH,
} from '@engage-web/constants';
import { useNavigate } from 'react-router-dom';
import { hasValidParamInSearchQuery } from '@engage-web/router/utils';
import { Mask } from '../../base';
import { useAzureAuth } from '../../../utils/hooks/useAzureAuth';
import { getIsAzureTenant } from './utils';
import { getQueryParamForTenant } from './azureConfig';

/**
 * Added to debug CIBC routing issues.
 * @param tenantId
 * @param message
 */

const isWorkspaceValid = (workspaceId: string): boolean => {
  const workspaceRe = /^[a-zA-Z0-9\\.-]*$/;
  return !!workspaceId && workspaceRe.test(workspaceId);
};

const TenantWorkspace = () => {
  const { tenant } = useAppParams();
  const { fetchTenantQuery } = useTenantFetch();
  const { search } = useAppLocation();

  const { azureAuth } = useAzureAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasDeepLink = hasValidParamInSearchQuery(search, {
    ...DRAWER_DEEP_LINK_PARAMS,
    ...MODAL_DEEP_LINK_PARAMS,
    ...DATE_TIME_DEEP_LINK_PARAMS,
  });

  const navigateToWorkspace = useCallback(
    () =>
      navigate({
        pathname: WORKSPACE_PATH,
      }),
    [navigate],
  );

  useEffect(() => {
    const fetchTenantConfig = async (workspaceId: string) => {
      try {
        const { config, theme } = await fetchTenantQuery(workspaceId, LanguageTypes.enUS);
        dispatch(
          tenantActions.setTenant({
            tenantId: tenant,
            config,
            theme,
          }),
        );

        const searchParams = new URLSearchParams(search);

        let success = false;

        const { isAzureTenant, idToken } = getIsAzureTenant(tenant, search);

        if (isAzureTenant && idToken && tenant) {
          const [azureSuccess, error] = await azureAuth({ tenantId: tenant, idToken });
          if (error) {
            console.log(error);
          }
          success = azureSuccess;
          const tokenQueryParam = getQueryParamForTenant(tenant);
          searchParams.delete(tokenQueryParam);
        }

        if (hasDeepLink) {
          dispatch(navigationActions.setDeepLinkQueryString(search));
        }

        navigate({
          pathname: success ? ROOT_PATH : LOGIN_PATH,
          search: searchParams.toString(),
        });
      } catch (ex) {
        // @ts-ignore
        console.log(ex.toString());
        navigateToWorkspace();
      }
    };

    // Check if the tenant is in valid format
    const workspaceId = tenant ? tenant.toLowerCase().trim() : '';
    const isValid = isWorkspaceValid(workspaceId);
    if (isValid) {
      fetchTenantConfig(workspaceId);
    } else {
      navigateToWorkspace();
    }
  }, [tenant, fetchTenantQuery, dispatch, navigate, hasDeepLink, search, navigateToWorkspace]);

  return <Mask isLoading />;
};

export default TenantWorkspace;
