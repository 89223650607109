import React, { useEffect } from 'react';

export function useOnClickOutside({
  ref,
  handler,
  disallowList = [''],
}: {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  handler: (event: Event) => void;
  disallowList?: string[];
}) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      // @ts-ignore
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      const targetInDisallowList = disallowList.some(item => {
        const elem = document.getElementById(item);
        return elem?.contains(event.target);
      });

      if (targetInDisallowList) {
        return;
      }

      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, disallowList]);
}
