import React, { CSSProperties, memo } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { StyledFixedList, StyledVariableList } from './styled';

interface ListProps {
  fixed?: boolean;
  itemCount: number;
  isNextPageLoading?: boolean;
  data: any[];
  loadNextPage?: (params: any) => Promise<any>;
  renderLoader?: ({ index, style }: { index: number; style: any }) => JSX.Element;
  renderItem: ({
    index,
    item,
    style,
  }: {
    index: number;
    item: any;
    style: any;
  }) => JSX.Element | null;
  itemSize: number | ((index: number) => number);
  listRef?: any;
  hasNextPage?: boolean;
}

const List = ({
  fixed = true,
  itemCount,
  isNextPageLoading,
  data,
  loadNextPage,
  renderLoader,
  renderItem,
  itemSize,
  listRef,
  hasNextPage = false,
}: ListProps) => {
  // TODO: messy way to get rid of the undefined method
  const emptyFn = () => {
    /* empty */
  };
  const loadNextPageFn = loadNextPage ?? emptyFn;
  const loadMoreItems = !!isNextPageLoading || !hasNextPage ? () => {} : loadNextPageFn;

  const isItemLoaded = (index: number) => index < data.length && data[index] !== null;

  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    if (data[index]) {
      return renderItem({ index, item: data[index], style });
    } else if (renderLoader) {
      return renderLoader({ index, style });
    }
    return null;
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <AutoSizer ref={ref}>
          {({ height, width }: Size) =>
            fixed ? (
              <StyledFixedList
                innerElementType="ul"
                height={height}
                width={width}
                itemCount={itemCount}
                itemSize={itemSize as number}
                onItemsRendered={onItemsRendered}
                ref={listRef}
              >
                {Item}
              </StyledFixedList>
            ) : (
              <StyledVariableList
                innerElementType="ul"
                height={height}
                width={width}
                itemCount={itemCount}
                itemSize={itemSize as (index: number) => number}
                onItemsRendered={onItemsRendered}
                ref={listRef}
              >
                {Item}
              </StyledVariableList>
            )
          }
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

export default memo(List);
