import { ISODateString, Nullable } from '@engage-shared/utils/types';
import { TenantId } from '@engage-shared/api/tenant/interfaces';

export type FetchParams = {
  tenantId: TenantId;
};

export type FetchBuildingParams = {
  buildingId: number;
};

export enum GlobalConfigValues {
  GLOBAL = 3602,
  OFF = 3599,
  ON = 3601,
}

export interface BuildingData {
  id: number;
  name: string;
  address: string;
  longitude: number;
  latitude: number;
  timezone: Nullable<string>;
  timezoneLookupError: Nullable<string>;
  allowedReservationPer15Minutes: number;
  allowedTotalReservations: number;
  bookSafe: GlobalConfigValues;
  bookSafeDistance: Nullable<number>;
  allDayDeskReservations: GlobalConfigValues;
  enableDeskCheckIn: GlobalConfigValues;
  deskCheckInOpenWindow: number;
  deskCheckInDuration: number;
  allDayStartTime: string;
  autoCancelDeskReservations: boolean;
  enableHealthDeclaration: GlobalConfigValues;
  healthDeclaration: Nullable<string>;
  enforceBookingsAllowed: GlobalConfigValues;
  bookingDeniedMessage: Nullable<string>;
  availableCount: Nullable<number>;
  bookableCount: Nullable<number>;
  availablePercentage: Nullable<number>;
}

// TODO: use single timezone property
export interface Building extends BuildingData {
  timeZone: Nullable<string>;
}

export type FetchAvailabilityParams = FetchBuildingParams & {
  startTime?: ISODateString;
  startTimeZone?: string;
};

export interface BuildingAvailability {
  id: number;
  localTimeFrom: string;
  localTimeTo: string;
  allowedReservations: number;
  existingReservations: number;
}

export type FetchBuildingQuery = (id: number) => Promise<Building>;

type UseBuildingReservationParams = {
  buildingId: number;
  dateStart?: Date;
};

export type HasBuildingLimitPer15Minutes = ({
  buildingId,
  dateStart,
}: UseBuildingReservationParams) => Promise<boolean>;
