import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useBuildingFetch, useFloorFetch } from '@engage-shared/api';
import { tenantActions } from '@engage-web/store';
import { KioskRemoteConfig } from '@engage-web/api/queries';

export const useLoadKioskConfigDetails = () => {
  const dispatch = useDispatch();
  const { fetchBuildingQuery } = useBuildingFetch();
  const { fetchFloor } = useFloorFetch();

  const loadConfigDetails = useCallback(
    async (data: KioskRemoteConfig) => {
      const { floorId, buildingId, coordinates, id: spaceId, ...rest } = data;

      const [{ id, name, timeZone, latitude, longitude }, floor] = await Promise.all([
        fetchBuildingQuery(buildingId),
        fetchFloor({ floorId, buildingId }),
      ]);

      const userLocation = {
        building: {
          id,
          name,
          timeZone,
          latitude,
          longitude,
        },
        floor,
      };

      dispatch(
        tenantActions.setKioskConfig({
          userLocation,
          kioskLocation: coordinates,
          isLoaded: true,
          spaceId,
          ...rest,
        }),
      );
    },
    [dispatch, fetchBuildingQuery, fetchFloor],
  );

  return { loadConfigDetails };
};
