import { FavouriteMutationBody } from '@engage-shared/api/favourites/interfaces';
import { Api, getApiUrl } from '@engage-shared/api/instance';

export type AddFavouriteItem = (params: FavouriteMutationBody) => any;

/**
 * Adds item to user favourites
 *
 * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | created favourite successful |
 * | 404 | ID not found |
 * | 422 | Incorrect favourite type |
 * @param params
 */
export const addFavouriteItem: AddFavouriteItem = async params => {
  const url = new URL(`${getApiUrl()}/favourites`);
  const response = await Api.post(url.toString(), params);
  return response?.data;
};
