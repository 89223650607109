import { APP_NAMES } from '@engage-shared/constants/api';
import { Nullable, TFunction } from '@engage-shared/utils/types';
import { AxiosRequestConfig } from 'axios';

type ApiRequestFn = Nullable<(error: AxiosRequestConfig) => boolean>;
type ApiResponseErrorFn = Nullable<TFunction<any>>;

type ApiConfig = {
  isRequestValid?: ApiRequestFn;
  onResponseError?: ApiResponseErrorFn;
  onRefreshError?: ApiResponseErrorFn;
};

type ClientConfig = {
  appName: string;
  appVersion?: string;
  api: ApiConfig;
};

const defaultOptions: ClientConfig = {
  appName: APP_NAMES.ENGAGE_MOBILE,
  appVersion: '0.0.1',
  api: {
    isRequestValid: null,
    onResponseError: null,
    onRefreshError: null,
  },
};

class EngageConfig {
  private static _instance: EngageConfig;

  /* eslint-disable @typescript-eslint/no-empty-function */
  private constructor() {}

  private _config: ClientConfig = defaultOptions;

  get config(): ClientConfig {
    return this._config;
  }

  set config(config: ClientConfig) {
    if (!config.appName) {
      throw new Error('Expected appName ("engage-web" or "engage-mobile") to be defined');
    }
    this._config = config;
  }

  static getInstance(): EngageConfig {
    if (EngageConfig._instance) {
      return this._instance;
    }
    return (this._instance = new EngageConfig());
  }

  callApiRequestValid(config: AxiosRequestConfig): boolean {
    const fn = this._config.api.isRequestValid;
    if (fn && typeof fn === 'function') {
      return fn(config);
    }
    return true;
  }

  async callApiErrorResponse(error: any): Promise<void> {
    const fn = this._config.api.onResponseError;
    if (fn && typeof fn === 'function') {
      await fn(error);
    }
  }

  async callApiRefreshError(error: any): Promise<void> {
    const fn = this._config.api.onRefreshError;
    if (fn && typeof fn === 'function') {
      await fn(error);
    }
  }
}

export const getConfig = (): EngageConfig => EngageConfig.getInstance();
