export enum bookingDetailsButtons {
  DELETE = 'delete',
  UPDATE = 'update',
  CHECK_IN = 'check-in',
  CHECK_OUT = 'check-out',
}

export enum bookingDetailsButtonStatuses {
  DISPLAY = 'display',
  DISABLED = 'disabled',
  LOADING = 'loading',
}

export enum teamBookingDetailsButtons {
  DELETE = 'delete',
  CHECK_IN = 'check-in',
  ACCEPT = 'accept',
  DECLINE = 'decline',
}

export enum teamBookingDetailsButtonStatuses {
  DISPLAY = 'display',
  DISABLED = 'disabled',
}
