import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.typo2};

  span {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  margin-right: 5px;
`;

export const LocatorAction = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color9};
  cursor: pointer;
  color: ${({ theme }) => theme.typo1};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
