import styled from 'styled-components';

interface FavouritesButtonProps {
  readonly $favouritesActive: boolean;
}

export const FavouritesButton = styled.button<FavouritesButtonProps>`
  position: relative;
  height: 38px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ theme, $favouritesActive }) =>
    $favouritesActive ? theme.color9 : theme.color4};
  border-color: ${({ theme, $favouritesActive }) =>
    $favouritesActive ? theme.color9 : theme.color4};
  border-radius: 19px;
  border-width: 2px;
  color: ${({ theme, $favouritesActive }) => ($favouritesActive ? theme.color6 : theme.color1)};
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
