import { FloorplanOptions } from '../types';

export const defaultFloorplanOptions: Required<FloorplanOptions> = {
  zoomTilesShown: false,
  reservedAlwaysEnabled: false,
  labelOptions: {
    showOccupantLabels: true,
    showSpaceNameLabels: true,
    showSpaceTypeLabels: false,
    labelFontSize: 10,
  },
  isTablet: false,
} as const;
