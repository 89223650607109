import { addMinutes, isValid, subMinutes } from 'date-fns';
import { copyTimeFromMomentToMoment, getUtcIsoString, roundTimeToInterval } from './dateHelpers';
import {
  getDayEndTime,
  getDayStartTime,
  getLocalizedDate,
  isLaterToday,
  setLocalizedHoursAndMinutes,
} from './timezoneHelpers';
import { DemoCheckIn, ISODateString, Nullable, TimeString } from '../types';
import { RoundingDirection, TENANT_CONFIG_DEFAULTS } from '../../constants';
import moment, { Moment } from 'moment-timezone';
import { CheckInConfiguration } from '@engage-shared/utils/bookings/getCheckInStatus';

export const getTimeValues = ({
  dateStart,
  dateEnd,
  allDay,
  bookingIntervalSize,
  timeZone,
}: {
  dateStart: Date;
  dateEnd: Date;
  allDay: boolean;
  bookingIntervalSize?: number;
  timeZone?: string;
}): { startTime: ISODateString | null; endTime: ISODateString | null } => {
  let dateS;
  let dateE;

  if (allDay) {
    dateS = getDayStartTime({
      date: dateStart || new Date(),
      bookingIntervalSize,
      timeZone,
    });
    dateE = getDayEndTime({
      // if dateEnd is not defined, then we should take dateStart for processing
      // in order to make sure dateEnd is always non less then dateStart
      date: dateEnd || dateS,
      timeZone,
    });
  } else if (dateStart) {
    dateS = dateStart;
  }

  if (!allDay && dateEnd) {
    dateE = dateEnd;
  }

  // TODO: remove when SER-1278 is fixed and startTime and endTime have default values
  if (!dateS && bookingIntervalSize) {
    dateS = roundTimeToInterval(new Date(), bookingIntervalSize, RoundingDirection.DOWN, timeZone);
  }
  // when startTime and endTime don't have values in API calls, they are defaulted to next 8 hrs.
  const MINUTES_FOR_8_HRS = 480;
  if (dateS && !dateE && bookingIntervalSize) {
    dateE = roundTimeToInterval(
      addMinutes(dateS, MINUTES_FOR_8_HRS),
      bookingIntervalSize,
      RoundingDirection.UP,
      timeZone,
    );
  }
  // end of TODO

  let startTime: ISODateString | null = null;
  let endTime: ISODateString | null = null;

  if (dateS) {
    //@ts-ignore getUtcIsoString returns a string matching ISODateString
    startTime = getUtcIsoString(dateS);
  }
  if (dateE) {
    //@ts-ignore getUtcIsoString returns a string matching ISODateString
    endTime = getUtcIsoString(dateE);
  }
  return { startTime, endTime };
};

const getDefaultAllDayStartTime = (allDayStartTime?: string): TimeString => {
  const timeStringRe = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  if (typeof allDayStartTime === 'string' && timeStringRe.test(allDayStartTime))
    return allDayStartTime as `${number}:${number}`;

  return TENANT_CONFIG_DEFAULTS.allDayReservationCheckInStartTime as `${number}:${number}`;
};

export const getCheckInCloseDate = ({
  checkInOpenDate,
  deskCheckInWindowDuration,
}: {
  checkInOpenDate: Moment | null;
  deskCheckInWindowDuration: number;
}): Moment | null => {
  return checkInOpenDate ? checkInOpenDate.clone().add(deskCheckInWindowDuration, 'minutes') : null;
};

/**
 * Calculate check-in window for desks.
 *
 * For full day reservations (enforced full day reservations config is true)
 * use all day reservations start time config (in building's timezone) and offset desk check-in open and close values to obtain window start and end times.
 *
 * For interval reservations (enforced full day reservations config is false)
 * use reservation start date and offset desk check-in open and close values to obtain window start and end times.
 */
export const getDeskCheckInWindowDates = ({
  tenantConfig,
  reservationStartDate,
  timeZone,
  isAllDayBooking,
}: {
  tenantConfig: CheckInConfiguration;
  reservationStartDate: ISODateString | Date;
  timeZone: string;
  isAllDayBooking: boolean;
}): { checkInOpenDate: Moment | null; checkInCloseDate: Moment | null } => {
  const { deskCheckInOpenWindow, deskCheckInWindowDuration, allDayReservationCheckInStartTime } =
    tenantConfig;

  const allDayCheckInStartTime = getDefaultAllDayStartTime(allDayReservationCheckInStartTime);

  const calculatedStartDate = setLocalizedHoursAndMinutes({
    date: reservationStartDate,
    timeZone,
    time: allDayCheckInStartTime,
  });
  const localizedReservationStartDate = getLocalizedDate({
    date: reservationStartDate,
    timeZone,
  });

  const startDate = isAllDayBooking ? calculatedStartDate : localizedReservationStartDate;

  const checkInOpenDate = startDate
    ? startDate?.clone().subtract(deskCheckInOpenWindow, 'minutes')
    : null;

  const checkInCloseDate = getCheckInCloseDate({
    checkInOpenDate,
    deskCheckInWindowDuration,
  });

  return {
    checkInOpenDate,
    checkInCloseDate,
  };
};

// results need to be moment objects as the results from getDeskCheckInWindowDates
export const getDemoCheckInWindowDates = ({
  demoObject,
}: {
  demoObject: DemoCheckIn;
}): {
  checkInOpenDate: Moment;
  checkInCloseDate: Moment;
} => {
  const checkInOpenDate = moment(demoObject.checkInOpen, moment.ISO_8601);
  const checkInCloseDate = moment(demoObject.checkInClose, moment.ISO_8601);

  return {
    checkInOpenDate,
    checkInCloseDate,
  };
};

type GetReservationStartDate = (params: {
  dateStart?: Nullable<Date>;
  selectedDay?: Nullable<Date>;
  bookingIntervalSize?: number;
  hasLimitPer15Minutes?: boolean;
  allDay?: boolean;
  timeZone?: string;
}) => Date;
/**
 *  Calculates reservation start date using rules from
 *  https://serraview.atlassian.net/wiki/spaces/PC/pages/1886618770/Reservation+dates+calculations
 *
 * Use time from filter date or current time and apply it to selected days from multi-days selector.
 * @param dateStart filter start date
 * @param selectedDay selected day from multi-days selector
 * @param bookingIntervalSize config value
 * @param hasLimitPer15Minutes true if building has a value for 'Allowed reservations per 15 minutes'
 * @param allDay true if it is a full day reservation (selected full day duration or enforced full day)
 * @param timeZone reservation's timezone
 * @returns {*|Date} reservation start date
 */
export const getReservationStartDate: GetReservationStartDate = ({
  dateStart,
  selectedDay,
  bookingIntervalSize = 15,
  hasLimitPer15Minutes,
  allDay,
  timeZone,
}) => {
  const hasSelectedTime = !!dateStart && isValid(dateStart);
  let dateS = hasSelectedTime ? dateStart : new Date();

  // case 1 : multi-day selector
  if (selectedDay && isValid(selectedDay)) {
    const localizedSelectedDay = getLocalizedDate({
      date: selectedDay,
      timeZone,
    });
    // when building has 15 min interval restrictions, the start date needs to be precise,
    // cannot use start of day. Selected day has localized start of day value.
    if (hasLimitPer15Minutes) {
      // if dateS is in the same day as the selected one, its value is already fine,
      // else keep day from selected date and copy time from dateS (now or explicitly selected from timeline)
      const localizedDateS = getLocalizedDate({ date: dateS, timeZone });
      const isSameDay = localizedSelectedDay?.isSame(localizedDateS as Moment, 'day');
      if (!isSameDay && localizedDateS && localizedSelectedDay) {
        copyTimeFromMomentToMoment(localizedDateS, localizedSelectedDay);
        dateS = localizedSelectedDay.toDate();
      }
    } else {
      // For current date use current time
      const localizedToday = getLocalizedDate({
        date: new Date(),
        timeZone,
      });
      const isSelectedDayToday = localizedSelectedDay?.isSame(localizedToday as Moment, 'day');
      const isTimelineDateLaterToday = isLaterToday(dateStart, timeZone);

      // later today: selected day is today and there is a timeline date that is later today
      if (isSelectedDayToday && isTimelineDateLaterToday) {
        // if dateStart is null or invalid function isLaterToday would return false, so it's safe to use ! here
        dateS = dateStart!;
      } else if (isSelectedDayToday) {
        // get exactly the current time, no roundings
        dateS = getDayStartTime({
          date: dateS,
          bookingIntervalSize,
          timeZone,
          noRounding: true,
        });
      } else {
        // use the start of day 00:00
        dateS = getDayStartTime({
          date: selectedDay,
          bookingIntervalSize,
          timeZone,
        });
      }
    }

    return dateS;
  }
  // case 2: duration selector
  // when hasLimitPer15Minutes use the timeline date (dateStart) or rounded current time
  if (allDay && !hasLimitPer15Minutes) {
    const isTimelineDateLaterToday = isLaterToday(dateStart, timeZone);
    if (isTimelineDateLaterToday) {
      // For later today use selected time; if dateStart is null or invalid function isLaterToday would return false, so it's safe to use ! here
      dateS = dateStart!;
    } else {
      // For current date all day selection starts from current time,
      // while for other dates is starts at 0:00
      dateS = getDayStartTime({
        date: dateS,
        bookingIntervalSize,
        timeZone,
        noRounding: true,
      });
    }
  }
  return dateS;
};

type GetReservationEndDate = (params: {
  dateStart?: Nullable<Date>;
  dateEnd?: Nullable<Date>;
  allDay?: boolean;
  duration?: number;
  bookingIntervalSize?: number;
  selectedDay?: Nullable<Date | ISODateString>;
  showDaysSelector?: boolean;
  showDurationSelection?: boolean;
  timeZone?: string;
}) => Date;
/**
 * Calculate end time as end of day for full days or (dateStart + duration) - 1 minute for non-full days,
 * with cleared seconds and milliseconds. When selectedDay is not provided
 * when showDaysSelector is true, or if dateStart is not provided when showDurationSelection is true,
 * then it defaults to the current date.
 * Deduction of 1 min for non-allDay reservations are done for back-to-back reservations support
 * @param dateStart filter date start
 * @param dateEnd filter date start
 * @param allDay enforced full day or entire day duration
 * @param duration
 * @param bookingIntervalSize
 * @param selectedDay
 * @param showDaysSelector
 * @param showDurationSelection
 * @param timeZone
 */
export const getReservationEndDate: GetReservationEndDate = ({
  dateStart,
  dateEnd,
  allDay,
  duration = 0,
  bookingIntervalSize = 15,
  selectedDay,
  showDaysSelector,
  showDurationSelection,
  timeZone,
}) => {
  if (showDaysSelector) {
    return getDayEndTime({
      date: selectedDay && isValid(selectedDay) ? selectedDay : new Date(),
      timeZone,
    });
  }
  if (showDurationSelection) {
    const dateS = dateStart && isValid(dateStart) ? dateStart : new Date();
    if (allDay) {
      return getDayEndTime({ date: dateS, timeZone });
    }
    return subMinutes(
      roundTimeToInterval(
        addMinutes(dateS, duration),
        bookingIntervalSize,
        RoundingDirection.UP,
        timeZone,
      ),
      1,
    );
  }
  return dateEnd && isValid(dateEnd) ? dateEnd : getDayEndTime({ date: new Date(), timeZone });
};
