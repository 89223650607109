import { useSelector } from 'react-redux';
import { filtersSelectors } from '@engage-web/store';
import {
  PersonAndPresenceResponse,
  usePersonFetch as usePersonFetchHook,
  usePersonQuery,
} from '@engage-shared/api';
import { UseQueryOptions, UseQueryResult } from 'react-query';

type UsePersonParams = {
  id: number;
  searchSpaceId?: number;
  currentFloorId?: number;
  options?: UseQueryOptions<
    PersonAndPresenceResponse | undefined,
    unknown,
    PersonAndPresenceResponse,
    string[]
  >;
};

const usePerson = ({
  id,
  searchSpaceId,
  currentFloorId,
  options = {},
}: UsePersonParams): UseQueryResult<PersonAndPresenceResponse> => {
  const { dateStart, dateEnd } = useSelector(filtersSelectors.getFilterDates);

  return usePersonQuery({
    id,
    dateStart,
    dateEnd,
    searchSpaceId,
    currentFloorId,
    options,
  });
};

const usePersonFetch = () => {
  const { dateStart, dateEnd } = useSelector(filtersSelectors.getFilterDates);

  return usePersonFetchHook({ dateStart, dateEnd });
};

export { usePerson, usePersonFetch };
