import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const SearchFilterWrapper = styled.div`
  flex: 1;
  padding: 0 30px;
`;

export const Content = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color6};
`;

export const SearchFilterHeader = styled.h3`
  margin-bottom: 35px;
  font-size: 14px;
  font-weight: 700;
`;

export const SearchFilterInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
`;

export const SearchFilterInputGroup = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;
