import { fetchSearch, TransformData } from '@engage-shared/api/search/fetch';
import {
  SearchData,
  SearchResponse,
  SearchResponseMeta,
} from '@engage-shared/api/search/interfaces';
import { useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryResult } from 'react-query/types/react/types';
import { SEARCH_QUERY_KEY } from '@engage-shared/api/search/constants';

type UseSearchQueryParams<Data extends SearchData = SearchData> = {
  queryKey: unknown[];
  fetchData: Data;
  isRefetchEnabled?: boolean;
  timeout?: number;
};

type Meta = {
  meta: SearchResponseMeta;
};

type UseSearchQuery = <RData extends Meta = SearchResponse>(
  params: UseSearchQueryParams,
  transform: TransformData<RData>,
) => UseInfiniteQueryResult<SearchResponse>;

export const useSearchQuery: UseSearchQuery = (
  { queryKey, fetchData, isRefetchEnabled = true, timeout },
  transformSearchResponse,
) =>
  useInfiniteQuery(
    [SEARCH_QUERY_KEY, ...queryKey],
    ({ pageParam = 1 }) =>
      fetchSearch(
        {
          data: {
            ...fetchData,
            page: pageParam,
          },
          timeout,
        },
        transformSearchResponse,
      ),
    {
      getNextPageParam: ({ meta }) => {
        const { hasNextPage, currentPage } = meta;
        return hasNextPage ? currentPage + 1 : false;
      },
      enabled: isRefetchEnabled,
    },
  );
