import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
`;

export const AvatarWrapper = styled.div`
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const NameWrapper = styled.div`
  margin: 10px 0;
  padding: 0 26px;
`;

export const Name = styled.h3`
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${props => props.theme.typo2};
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.span`
  font-weight: 500;
  color: ${props => props.theme.color2};
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 20px;
  opacity: 0.8;
`;

export const TeamText = styled(Text)`
  text-align: center;
  font-size: 15px;
`;

export const Location = styled(Text)`
  color: ${props => props.theme.color1};
  margin: 0 0 10px 0;

  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

/*
export const Separator = styled.div`
  width: ${fontTagHeights.h6.clean}px;
  height: 2px;
  background-color: ${(props) => `${props.theme.color2}1A`}; // alpha 10%
  transform: rotate(90deg);
`;

 */

export const OfficerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`;

export const OfficerIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background-color: #eaeaea;
  color: ${({ theme }) => theme.typo2};
`;

export const Officer = styled(Text)`
  color: ${props => props.theme.color9};
  margin: 0 0 0 10px;
`;

export const MoreLocations = styled.button`
  border: none;
  background-color: ${props => props.theme.color9};
  color: #fff;
  padding: 7px 12px;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 20px;
`;

export const SensorStatusIconWrapper = styled.div`
  position: absolute;
  top: 3px;
  left: 5px;
  border: 3px solid #fff;
  border-radius: 22px;
  width: 28px;
  height: 28px;
`;
