import { useEffect } from 'react';
import { focusElementById } from '../helpers';

export enum FocusId {
  GlobalSearchFilterButton = 'global-search-filter-button',
  DrawerCloseButton = 'drawer-close-button',
  DrawerMenuButton = 'drawer-menu-button',
  TimelineButton = 'timeline-button',
  TimelineSelectButton = 'modal-date-time-picker-select-btn',
  DurationSelectorHourUpButton = 'duration-selector-hour-up-button',
  TeamDeskReserve = 'team-desk-reserve',
  TeamBookingAccept = 'team-booking-accept',
  TeamBookingDecline = 'team-booking-decline',
  TeamBookingDelete = 'team-booking-delete',
  BookingDeleteButton = 'booking-delete-button',
  BookingCheckInButton = 'booking-check-in-button',
  SpaceAvailability = 'space-availability',
  SpaceAttributes = 'space-attributes',
  SearchFiltersApplyButton = 'search-filters-apply',
  ReserveSelectedDatesButton = 'reserve-selected-dates',
  AgendaButton = 'agenda-button',
  FloorplanViewsButton = 'floorplan-views-button',
  UserProfileButton = 'user-profile',
  UserSettingsButton = 'user-settings',
}

type UseFocusArgs = {
  /**
   * Element that should be focused when the hook mounts.
   */
  focusOn?: FocusId;
  /**
   * Element that should be focused when the hook unmounts.
   */
  returnFocusTo?: FocusId;
};

type UseFocus = (args: UseFocusArgs) => void;

export const useFocus: UseFocus = ({ focusOn, returnFocusTo }) => {
  useEffect(() => {
    if (focusOn) {
      focusElementById(focusOn);
    }

    return () => {
      if (!returnFocusTo) return;
      focusElementById(returnFocusTo);
    };
  }, [focusOn, returnFocusTo]);
};
