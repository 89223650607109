import { isBefore, isWithinInterval, parseISO } from 'date-fns';
import { fetchPerson } from '@engage-shared/api/people';
import { PersonData } from '@engage-shared/api/people/interfaces';
import { getLogger } from '@engage-shared/utils/logger';
import { ParsedSpaceItem } from '@engage-shared/api/spaces/interfaces';
import { UserData } from '@engage-shared/api/users/interfaces';
import { Nullable } from '@engage-shared/utils/types';
import { BookingsListResponse } from '@engage-shared/api/bookings/interfaces';
import { getDayStartTime, isTimeNow } from '@engage-shared/utils/dates';
import { getSpaceSVLivePresenceEventOccupancy } from '@engage-shared/utils/sv-live';

export type GetOccupantParams = {
  spaceItem: ParsedSpaceItem | undefined;
  dateStart: Date;
  bookings: BookingsListResponse[];
  enforceAllDayDeskReservations?: boolean;
};

type GetOccupant = (params: GetOccupantParams) => Promise<Nullable<UserData | PersonData>>;

export const getOccupant: GetOccupant = async ({
  spaceItem,
  dateStart,
  bookings,
  enforceAllDayDeskReservations,
}) => {
  const spaceId = spaceItem?.id;
  try {
    if (!spaceId) {
      return null;
    }

    const latestPresenceEvent = getSpaceSVLivePresenceEventOccupancy(spaceItem);
    const isNow = isTimeNow(dateStart);
    // use occupant indicated by SVLive data only if it is not for a future date
    if (latestPresenceEvent && isNow) {
      const { isOccupied, isRemote, presenceEvent } = latestPresenceEvent;
      if (isOccupied || isRemote) {
        const personId = presenceEvent?.personId;
        if (personId) {
          return await fetchPerson({ id: personId });
        }
      }
    }

    if (!bookings) {
      return null;
    }

    const activeBooking = bookings.find(booking => {
      const { start, end, startTimeZone } = booking;
      let startTime = parseISO(start);

      //when booking is all day booking and enforcedFullDayDeskReservation, get occupant even if the booking starts later
      if (booking.isAllDayBooking && enforceAllDayDeskReservations) {
        startTime = getDayStartTime({
          date: startTime,
          timeZone: startTimeZone,
        }); //00:00
      }

      const bookingEnded = isBefore(parseISO(end), new Date()); // booking ended before current time, or was cancelled.

      return (
        isWithinInterval(dateStart || new Date(), {
          start: startTime,
          end: parseISO(end),
        }) && !bookingEnded
      );
    });

    const reserveeId = activeBooking?.reservee?.reserveeId;
    if (reserveeId) {
      return await fetchPerson({ id: reserveeId });
    }

    const organizerId = activeBooking?.organizer?.organizerId;
    if (organizerId) {
      return await fetchPerson({ id: organizerId });
    }

    return null;
  } catch (error) {
    getLogger().error(error);
    return null;
  }
};
