import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { humanizeNumber } from '@engage-web/utils';
import { useTranslation } from 'react-i18next';

const SectionHeaderWrapper = styled.li`
  padding: 20px 20px 0 20px;
  color: ${({ theme }) => theme.typo2};
  & .title {
    font-size: 20px;
    font-weight: bold;
  }
  & .subtitle {
    font-size: 16px;
  }
`;

const StatusTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.typo2};
  opacity: 0.5;
`;

type SectionHeaderProps = {
  item: {
    title?: string;
    subtitle?: string;
    statusTitle?: string;
  };
  style?: CSSProperties;
};

const SectionHeader = ({ item = {}, style }: SectionHeaderProps) => {
  const { statusTitle, title, subtitle } = item;
  const { t } = useTranslation();
  return (
    <SectionHeaderWrapper style={style}>
      {!!statusTitle && <StatusTitle>{t(statusTitle)}</StatusTitle>}
      {!!title && <div className="title">{title}</div>}
      {!!subtitle && (
        <div className="subtitle">{`${humanizeNumber(subtitle, t)} ${t('common.floor')}`}</div>
      )}
    </SectionHeaderWrapper>
  );
};

// if there is building name set size to 90 if not - to 75
SectionHeader.getSize = (item: SectionHeaderProps['item']) => (item.title ? 90 : 75);

export default SectionHeader;
