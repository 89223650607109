import { useEmployeeFetch } from '@engage-shared/api';
import { useSelector } from 'react-redux';
import { filtersSelectors } from '@engage-web/store';
import { useCallback } from 'react';

const errorMessages = {
  INVALID_EMPLOYEE: 'router.location.invalidEmployee',
};

const useEmployeeCheck = () => {
  type UseEmployeeFetchParams = { dateStart: Date; dateEnd: Date };
  const { dateStart, dateEnd }: UseEmployeeFetchParams = useSelector(
    filtersSelectors.getFilterDates,
  );
  const { fetchEmployeeQuery } = useEmployeeFetch({
    dateStart,
    dateEnd,
  });

  const checkEmployeeQuery = useCallback(
    async (id: string) => {
      try {
        const data = await fetchEmployeeQuery(id);
        // @ts-ignore  TODO: check response object shape and update fetchEmployeeQuery accordingly
        const [employeeData] = data;

        if (employeeData) {
          return { data: employeeData, errorMessage: null };
        }

        return { errorMessage: errorMessages.INVALID_EMPLOYEE };
      } catch (e) {
        return {
          errorMessage: errorMessages.INVALID_EMPLOYEE,
        };
      }
    },
    [fetchEmployeeQuery],
  );

  return { checkEmployeeQuery };
};

export default useEmployeeCheck;
