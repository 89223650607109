import React from 'react';
import { useQRCode } from 'next-qrcode';
import { QRCodeOptions } from 'next-qrcode/dist/useQRCode';

type QRCodeProps = { text: string; options: QRCodeOptions };

export const QRCode = ({ text, options }: QRCodeProps) => {
  const { Image } = useQRCode();

  return <Image text={text} options={options} />;
};
