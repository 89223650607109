import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigationSelectors, tenantSelectors } from '../../../store';
import { DOCUMENT_TITLE_LEVEL } from '../../../constants';
import { getCurrentTitleValues, getLocationTitle, getTitleStringFromValues } from './utils';

/**
 * Updates current document title by prepending provided string separated by " | ".
 *
 * We also need to specify `level` parameter if the component is rendered using `Modals` component.
 * The reason why this is needed is when we open a modal which sets a new title,
 * any other component may be updated at any time,
 * and if the updated component also can set document's title it will override title set by the modal.
 * But since we have `level` it will not happen because we check whether the proposed title update
 * has level `DOCUMENT_TITLE_LEVEL.PAGE` and if we have modal opened we're blocking title update.
 *
 * Now because of this, normally if we close the modal we will not have correct title
 * which should've been set by "that" component, but because we have `modalLayout` in `useEffect`
 * dependency array – title update will be performed as expected.
 *
 * @param {string} title Title that will be prepended to the current document title.
 * @param {number} [level=DOCUMENT_TITLE_LEVEL.PAGE] Identifier that is required to properly update document title when the modal is closed.
 */
export function useSetPageTitle(title: string, level = DOCUMENT_TITLE_LEVEL.PAGE) {
  const { t } = useTranslation();
  const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
  const { layout: modalLayout } = useSelector(navigationSelectors.getModalLayout);
  const locationTitle = getLocationTitle({ building, floor, t });

  useEffect(() => {
    const currentTitle = document.title;
    const titleValues = getCurrentTitleValues(currentTitle);

    /**
     * Determine whether we should update title or not.
     * 1. If level equals to PAGE (default) then we should check if modalLayout exists
     *   1. If modalLayout truthy (modal is visible on the screen)
     *      we don't want to update title, because title set by modal is more important
     *      modalLayout = MODALS.AGENDA
     *      !modalLayout = false
     *      shouldUpdateTitle = false
     *   2. If modalLayout is falsy (there is no modal open) we always want to update the title
     *      modalLayout = undefined
     *      !modalLayout = true
     *      shouldUpdateTitle = true
     * 2. If level doesn't equal to PAGE (for now only MODAL)
     *    we always want to update the title, because that means that other modal was opened
     *    shouldUpdateTitle = true
     * @type {boolean}
     */
    const shouldUpdateTitle = level === DOCUMENT_TITLE_LEVEL.PAGE ? !modalLayout : true;

    if (locationTitle) {
      titleValues.location = locationTitle;
    }

    if (title && shouldUpdateTitle) {
      titleValues.component = title;
    }

    document.title = getTitleStringFromValues(titleValues);

    return () => {
      document.title = getTitleStringFromValues({
        ...titleValues,
        component: undefined,
      });
    };
  }, [title, level, locationTitle, modalLayout]);
}
