import { Api, getApiUrl } from '@engage-shared/api/instance';
import { Marker } from './interfaces';

type FetchMarkers = (containerTypeId?: number) => Promise<Marker[]>;

/**
 * Fetched available floorplan markers
 *  * Responses:
 * | Code      | Description |
 * | :---------| :---------- |
 * | 200 | markers types found |
 * | 404 | incorrect parameter or not found |
 *
 * @param containerTypeId (optional) - id of parent container
 */
export const fetchMarkers: FetchMarkers = async containerTypeId => {
  const path = 'space_types/markers';
  const url = new URL(`${getApiUrl()}/${path}`);
  if (containerTypeId) {
    url.searchParams.set('container_type_id', containerTypeId.toString());
  }
  const { data } = await Api.get(url.toString());
  return data.data;
};
