import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from '@engage-web/store';
import { MODALS } from '@engage-web/constants';
import { getIsVisible, useModal } from '@engage-web/utils';

export function useShowSocialDistancingInfo() {
  const { openModal, layout } = useModal();
  const showSocialDistancingInfo = useSelector(userConfigSelectors.getShowSocialDistancingInfo);

  useEffect(() => {
    if (getIsVisible(showSocialDistancingInfo) && layout !== MODALS.SOCIAL_DISTANCING) {
      openModal(MODALS.SOCIAL_DISTANCING);
    }
  }, [showSocialDistancingInfo, openModal, layout]);
}
