export enum SENSOR_STATUS {
  OCCUPIED = 'occupied',
  VACANT = 'vacant',
  OFFLINE = 'offline',
}

export const enum SPACE_ASSETS_FILTER {
  TYPE = 'spaceTypes',
  ATTRIBUTE = 'spaceAttributes',
}
