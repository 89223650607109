import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Nullable } from '@engage-shared/utils/types';

import { uiActions, uiSelectors } from '@engage-web/store';
import { useOnClickOutside } from '@engage-web/utils/hooks/useOnClickOutside';
import { FocusId } from '@engage-web/utils/hooks';
import { MenuCard, MenuCardAction, MenuCardItem, MenuList } from './DrawerMenu.styles';

export type MenuItem = {
  name: string;
  onPress: () => void;
};

export type MenuProps = Nullable<MenuItem[]>;

type DrawerMenuProps = {
  menu?: MenuProps;
};

export const DrawerMenu = ({ menu }: DrawerMenuProps) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useSelector(uiSelectors.getDrawerMenuVisibility);

  const closeMenu = useCallback(() => {
    dispatch(uiActions.setDrawerMenuVisibility(false));
  }, [dispatch]);

  useOnClickOutside({
    ref,
    handler: closeMenu,
    disallowList: [FocusId.DrawerMenuButton, FocusId.DrawerCloseButton],
  });

  return (
    <CSSTransition in={isVisible} classNames="drawer-menu" timeout={300}>
      <MenuCard data-testid="drawer-menu-wrapper" data-test-is-visible={isVisible} ref={ref}>
        <MenuList role="menu">
          {menu?.map(({ name, onPress }) => (
            <MenuCardItem role="menuitem" key={name}>
              <MenuCardAction
                aria-label={name}
                data-testid={name}
                onClick={() => {
                  onPress();
                  closeMenu();
                }}
              >
                {name}
              </MenuCardAction>
            </MenuCardItem>
          ))}
        </MenuList>
      </MenuCard>
    </CSSTransition>
  );
};
