import React from 'react';
import { OptionProps } from 'react-select';
import { LiOption } from './Select.style';

const Option = <Option,>(props: OptionProps<Option>) => {
  const {
    children,
    label,
    isDisabled,
    innerRef,
    getValue,
    innerProps,
    getStyles,
    cx,
    className,
    isFocused,
    isSelected,
  } = props;

  // @ts-ignore
  const [{ id = label } = {}] = getValue();

  const styles = getStyles('option', props);

  return (
    <LiOption
      css={styles}
      // @ts-ignore
      ref={innerRef}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      key={id}
      {...innerProps}
      aria-disabled={isDisabled}
      aria-label={label}
    >
      {children}
    </LiOption>
  );
};

export default Option;
