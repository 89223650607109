import React, { memo } from 'react';
import { FES, FloorplanProvider } from '@floorplan/fes';
import { GlobalStyles } from '@floorplan/base';
import { FloorplanContainer } from './FloorplanContainer';

export const FloorplanHandler = memo(() => {
  const fes = new FES({
    logger: console,
  });
  return (
    <FloorplanProvider fes={fes}>
      <GlobalStyles />
      <FloorplanContainer />
    </FloorplanProvider>
  );
});
