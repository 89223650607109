import { FesEvents } from './constants';
import { defaultLogger, Logger } from './logger';
import { Viewer } from './viewer';
import { Events, MessageType, OnMessage } from './types';

interface FESConfig {
  logger?: Logger;
  onMessage?: OnMessage;
}

export class FES {
  private readonly events: Events;
  private logger: Logger;
  private viewer: Viewer | null;
  private onMessage: (data: MessageType) => void;

  constructor(config: FESConfig) {
    this.events = {} as Events;
    this.onMessage = config.onMessage || noop;
    this.logger = config.logger || defaultLogger;
    this.viewer = null;
  }

  on(key: keyof FesEvents, func: any): void {
    if (!this.events.hasOwnProperty(key)) {
      // @ts-ignore
      this.events[key] = [func];
    } else {
      // @ts-ignore
      this.events[key] = [...this.events[key], func];
    }
  }

  off(key: keyof FesEvents, func: any): void {
    if (this.events.hasOwnProperty(key)) {
      const index = this.events[key].indexOf(func);
      if (index !== -1) {
        this.events[key].splice(index, 1);
      }
    }
  }

  trigger(key: keyof FesEvents, data: any = null): void {
    if (this.events.hasOwnProperty(key)) {
      const dataObj = data;
      this.events[key].forEach(handler => {
        handler(dataObj);
      });
    }
    const message = { type: key, message: data } as MessageType;
    this._sendMessage(message);
  }

  setViewer(viewer: Viewer | null): void {
    this.viewer = viewer;
  }

  getViewer(): Viewer | null {
    return this.viewer;
  }

  getViewerViewport() {
    return this.viewer?.getViewer()?.viewport;
  }

  getLogger(): Logger {
    return this.logger;
  }

  setLogger(_logger: Logger) {
    this.logger = _logger;
  }

  setMessageHandler(func: OnMessage): void {
    this.onMessage = func;
  }

  _sendMessage(message: MessageType): void {
    this._sendMessageToNative(message);
    if (this.onMessage) {
      this.onMessage(message);
    }
  }

  _sendMessageToNative(message: MessageType): void {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(message, stringifyReplacer));
    }
  }
}

function stringifyReplacer(key: string, value: any) {
  // Filtering out viewer property, since it has cycle deps
  if (key === 'viewer') {
    return null;
  }
  return value;
}

function noop() {}
