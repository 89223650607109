import { QueryClient, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { FloorPermissions } from '@engage-shared/api/floors/interfaces';
import { fetchFloorPermissions } from '@engage-shared/api/floors/fetchFloorPermissions';

const QUERY_STALE_TIME = 1000 * 60 * 10; // 10 minutes
const FLOOR_PERMISSIONS_KEY = 'floorPermissions';

type UseFloorPermissionsParams = {
  floorId: number;
  options?: UseQueryOptions<FloorPermissions>;
};

type UseFloorPermissions = (params: UseFloorPermissionsParams) => UseQueryResult<FloorPermissions>;

export const useFloorPermissions: UseFloorPermissions = ({ floorId, options = {} }) => {
  return useQuery({
    queryKey: [FLOOR_PERMISSIONS_KEY, floorId],
    queryFn: () => fetchFloorPermissions(floorId),
    staleTime: QUERY_STALE_TIME,
    cacheTime: Infinity,
    ...options,
  });
};

type PrefetchFloorPermissionsParams = {
  floorId: number;
  queryClient: QueryClient;
  options?: UseQueryOptions<FloorPermissions>;
};
type PrefetchFloorPermissions = (params: PrefetchFloorPermissionsParams) => Promise<void>;

export const prefetchFloorPermissions: PrefetchFloorPermissions = ({
  queryClient,
  floorId,
  options = {},
}) => {
  return queryClient.prefetchQuery(
    [FLOOR_PERMISSIONS_KEY, floorId],
    () => fetchFloorPermissions(floorId),
    { ...options },
  );
};
