import { useSelector, useDispatch } from 'react-redux';
import { filtersActions, filtersSelectors } from '@engage-web/store';
import { countAppliedFilters } from '@engage-shared/utils';

export default function useGlobalSearchFilters() {
  const dispatch = useDispatch();
  const searchFilters = useSelector(filtersSelectors.getFilters);

  const numberOfAppliedFilters = countAppliedFilters({
    searchFilters,
  });

  const isFilterChanged = numberOfAppliedFilters > 0;

  const resetAllFilters = () => {
    if (isFilterChanged) {
      dispatch(filtersActions.clearFilterValues());
    }
  };

  return {
    filtersChanged: isFilterChanged,
    resetAllFilters,
    numberOfAppliedFilters,
  };
}
