import { RESTRICT_TO_VALUES } from '@engage-shared/constants';
import { PATH_SEGMENT } from './router';

type RestrictToSearchItem = {
  iconName: string;
  text: string;
  path: string;
};

const RESTRICT_TO_SEARCH_FILTER_ITEMS: Record<RESTRICT_TO_VALUES, RestrictToSearchItem> = {
  [RESTRICT_TO_VALUES.SPACE]: {
    iconName: 'space',
    text: 'layout.floorplanViewSelector.spaces',
    path: PATH_SEGMENT.SPACES,
  },
  [RESTRICT_TO_VALUES.DESK]: {
    iconName: 'desk',
    text: 'layout.floorplanViewSelector.desks',
    path: PATH_SEGMENT.DESKS,
  },
  [RESTRICT_TO_VALUES.PERSON]: {
    iconName: 'people',
    text: 'layout.floorplanViewSelector.people',
    path: PATH_SEGMENT.PEOPLE,
  },
  [RESTRICT_TO_VALUES.TEAM]: {
    iconName: 'teams',
    text: 'layout.floorplanViewSelector.teams',
    path: PATH_SEGMENT.TEAMS,
  },
};

export { RESTRICT_TO_SEARCH_FILTER_ITEMS };
