import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ParsedTeam } from '@engage-shared/api/teams';
import { useHasTeamBookingsInInterval } from '@engage-shared/api';
import { useReservationFilters } from '@engage-web/utils/hooks';
import { tenantSelectors } from '@engage-web/store';
import { TeamReservationButton } from './TeamMembers.style';

type TeamReserveButtonProps = {
  onPress: () => void;
  team: ParsedTeam;
};

export function TeamReserveButton({ team, onPress }: TeamReserveButtonProps) {
  const { t } = useTranslation();
  const { id, favPeopleTeam } = team;

  const tenantId = useSelector(tenantSelectors.getTenantId);

  const { dateStart: filterDateStart } = useReservationFilters(true);

  const hasTeamBookings = useHasTeamBookingsInInterval({
    filterDateStart,
    teamId: id,
    tenantId,
    options: {
      enabled: !favPeopleTeam,
    },
  });

  const shouldDisableReserveButton = team.people <= 0 || hasTeamBookings;

  const reservationButtonTitle = shouldDisableReserveButton
    ? t('layout.teamMembersList.reservationExists')
    : t('layout.teamMembersList.teamReservation');

  const [showButton, setShowButton] = useState(true);

  const onReservationButtonClick = () => {
    onPress();
    setShowButton(false);
  };

  if (!showButton) return null;

  return (
    <TeamReservationButton onClick={onReservationButtonClick} disabled={shouldDisableReserveButton}>
      {reservationButtonTitle}
    </TeamReservationButton>
  );
}
