import { LanguageTypes } from '@engage-web/constants';
import { getSupportedLocale } from '@engage-shared/utils';

const browserLanguage = getSupportedLocale(navigator.language, Object.values(LanguageTypes));

type ApplyLayoutDirection = (isLanguageRTL: string) => void;

/**
 * Applying dir attribute with the corresponding value.
 * @param {boolean} isLanguageRTL
 * @returns void
 */
const applyLayoutDirection: ApplyLayoutDirection = isLanguageRTL =>
  document.getElementsByTagName('body')[0].setAttribute('dir', isLanguageRTL ? 'rtl' : 'ltr');

/**
 * Check if body tag have dir attribute with 'rtl' value
 * @returns void
 */
const isLayoutRtlDirection = () =>
  document.getElementsByTagName('body')[0].getAttribute('dir') === 'rtl';

export { browserLanguage, applyLayoutDirection, isLayoutRtlDirection };
