import { useParams } from 'react-router-dom';

type AppParams = Partial<{
  employeeId: string;
  spaceId: string;
  deskId: string;
  personId: string;
  teamId: string;
  floorId: string;
  tenant: string;
}>;

export const useAppParams = () => useParams() as AppParams;
