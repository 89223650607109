import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGenerateCurrentLocationPath } from '@engage-web/utils';
import { joinPaths } from '@engage-web/router/utils';

type DrawerRedirectProps = {
  path?: string;
};

export function DrawerRedirect({ path }: DrawerRedirectProps) {
  const currentLocationPath = useGenerateCurrentLocationPath();

  return <Navigate replace to={joinPaths(currentLocationPath, path)} />;
}
