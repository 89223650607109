import { createBooking } from '@engage-shared/api/bookings/create';
import { updateBooking } from '@engage-shared/api/bookings/update';
import { BookingMutationData, SaveBookingParams } from '@engage-shared/api/bookings/interfaces';
import { composeBooking } from '@engage-shared/api/bookings/mutations/utils/composeBooking';
import { safelyInvokeCallback } from '@engage-shared/api/bookings/mutations/utils/callback';

export type SaveBooking = (params: SaveBookingParams) => Promise<BookingMutationData>;
/**
 * Returns created or updated booking.
 * @param tenantId
 * @param spaceItem
 * @param duration
 * @param allDayDuration
 * @param filter
 * @param email
 * @param summaryText
 * @param reserveeId
 * @param localTimeZone
 * @param successCallback
 * @param errorCallback
 * @param fetchBuildingQuery
 * @param hasBuildingLimitPer15Minutes
 * @param isUpdate
 * @param bookingInitialSpaceId
 * @param meetingId
 * @param selectedDay
 */
export const saveBooking: SaveBooking = async ({
  tenantId,
  spaceItem,
  duration,
  allDayDuration,
  filter,
  email,
  summaryText,
  reserveeId,
  localTimeZone,
  successCallback,
  errorCallback,
  fetchBuildingQuery,
  hasBuildingLimitPer15Minutes,
  isUpdate,
  bookingInitialSpaceId,
  meetingId,
  selectedDay,
}) => {
  const { id: spaceId } = spaceItem;
  try {
    const reservation = await composeBooking({
      spaceItem,
      duration,
      allDayDuration,
      filter,
      email,
      summaryText,
      reserveeId,
      localTimeZone,
      fetchBuildingQuery,
      hasBuildingLimitPer15Minutes,
      isUpdate,
      bookingInitialSpaceId,
      selectedDay,
    });

    if (isUpdate && meetingId && bookingInitialSpaceId) {
      const updatedReservationData = await updateBooking({
        tenantId,
        spaceId: bookingInitialSpaceId,
        meetingId,
        reservation,
      });
      safelyInvokeCallback(successCallback, updatedReservationData);
      return updatedReservationData;
    }
    const newReservationData = await createBooking({
      tenantId,
      spaceId,
      reservation,
    });
    safelyInvokeCallback(successCallback, newReservationData);
    return newReservationData;
  } catch (error) {
    // error logged by api
    safelyInvokeCallback(errorCallback, error as Error);
    throw error;
  }
};
