import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from '@engage-web/components/base';
import {
  Subtitle,
  SubtitleWrapper,
} from '@engage-web/components/layouts/GlobalSearch/SearchItemSkeleton.style';
import { IconWrapper, ListItemOuterWrapper, ListItemWrapper, TextWrapper } from './styled';

const SearchItemSkeleton = () => (
  <SkeletonTheme style={{ width: '100%' }}>
    <ListItemOuterWrapper>
      <ListItemWrapper>
        <Skeleton circle height={36} width={36} />
        <TextWrapper>
          {/* Title */}
          <h6>
            <Skeleton width={206} />
          </h6>
          {/* Subtitle */}
          <SubtitleWrapper>
            <Subtitle>
              <Skeleton width={100} />
            </Subtitle>
            <Subtitle>
              <Skeleton width={100} />
            </Subtitle>
          </SubtitleWrapper>
        </TextWrapper>
        <IconWrapper>
          <Skeleton circle height={26} width={26} />
        </IconWrapper>
      </ListItemWrapper>
    </ListItemOuterWrapper>
  </SkeletonTheme>
);

export default SearchItemSkeleton;
