export const USER_CONFIG_KEYS = {
  SOCIAL_DISTANCING: 'sdi',
  LANDING_PAGE: 'lp',
  ACKNOWLEDGEMENT_POLICY: 'ap',
  UI_LANGUAGE: 'uil',
  ENABLE_VIEWS: 'ev',
};

export const VISIBILITY = {
  SHOW: 'show',
  HIDE: 'hide',
};

export const GLOBAL_SEARCH_RESULT_ID = 'id_global_search_result';

export const DATE_TIME_PICKER_VARIANT = {
  TIMELINE: 'timeline',
  INTERVAL_START: 'start',
  INTERVAL_END: 'end',
};

export const DOCUMENT_TITLE_SEPARATOR = ' | ';
export const DOCUMENT_TITLE_LEVEL = {
  PAGE: 0,
  MODAL: 1,
};

export const UNKNOWN_PERSON = 'Unknown Person';

export const OPT_OUT_DISPLAY_NAME = 'User has opted-out';
