import React from 'react';
import { useQueryClient } from 'react-query';
import { TenantId } from '@engage-shared/api/tenant/interfaces';
import {
  FetchBookingQuery,
  FetchBookingResponse,
  MeetingParams,
} from '@engage-shared/api/bookings/interfaces';
import { fetchBooking } from '../fetch';
import { BOOKING_DETAILS_QUERY_KEY, BOOKING_KEY } from '../constants';

type UseBookingFetchParams = {
  tenantId: TenantId;
  calculateCheckInStatus?: boolean;
};
type UseBookingFetch = (params: UseBookingFetchParams) => {
  fetchBookingQuery: FetchBookingQuery;
};

export const useBookingFetch: UseBookingFetch = ({ tenantId, calculateCheckInStatus = false }) => {
  const queryClient = useQueryClient();

  const query: FetchBookingQuery = ({ spaceId, meetingId, options = {} }) => {
    const queryKey = [BOOKING_KEY, tenantId, `${spaceId}`, `${meetingId}`];
    if (calculateCheckInStatus) {
      queryKey.push('calculateCheckInStatus');
    }
    return queryClient.fetchQuery<FetchBookingResponse>(
      queryKey,
      () => fetchBooking({ tenantId, spaceId, meetingId: `${meetingId}` }),
      options,
    );
  };

  const fetchBookingQuery = React.useCallback(query, [
    calculateCheckInStatus,
    queryClient,
    tenantId,
  ]);

  return { fetchBookingQuery };
};

type InvalidateQuery = (params: MeetingParams) => Promise<void>;
type UseRefreshBooking = () => {
  refresh: ({ spaceId, meetingId }: MeetingParams) => Promise<void>;
};
export const useRefreshBooking: UseRefreshBooking = () => {
  const queryClient = useQueryClient();

  const invalidateQuery: InvalidateQuery = ({ spaceId, meetingId }) =>
    queryClient.invalidateQueries([BOOKING_DETAILS_QUERY_KEY, `${spaceId}`, `${meetingId}`]);

  const refresh = React.useCallback(invalidateQuery, [queryClient]);

  return { refresh };
};
