import { useQuery, useQueryClient, UseQueryResult, UseQueryOptions } from 'react-query';
import { FloorplanData } from './types';
import { fetchZoomDescriptor } from './zoomDescriptor';
import { fetchSpaces } from './space';
import { useCallback } from 'react';

type FloorDataParams = {
  floorId: number;
  teamBookingId?: number | null;
  startTime?: string;
  endTime?: string;
};

type FetchFloorplanData = (params: FloorDataParams) => Promise<FloorplanData>;

const fetchFloorplanData: FetchFloorplanData = async ({
  floorId,
  teamBookingId,
  startTime,
  endTime,
}) => {
  const [zoomDescriptor, floorplanSpaces] = await Promise.all([
    fetchZoomDescriptor({ floorId }),
    fetchSpaces({
      floorId,
      teamBookingId,
      startTime,
      endTime,
    }),
  ]);

  return {
    spaces: floorplanSpaces?.spaces,
    filteredSpaces: floorplanSpaces?.filteredSpaces,
    zoomDescriptor,
    floorId,
  };
};

export const useZoomDescriptorData = ({ floorId }: { floorId: number }) => {
  return useQuery({
    queryKey: ['floorplan-zoom-descriptor', floorId],
    queryFn: () => fetchZoomDescriptor({ floorId }),
    enabled: !!floorId,
  });
};

export const useFloorplanSpacesData = ({
  floorId,
  teamBookingId,
  startTime,
  endTime,
  roomsStartTime,
  roomsEndTime,
}: any) => {
  return useQuery({
    queryKey: ['floorplan-spaces-data', floorId, startTime, endTime, teamBookingId],
    queryFn: () =>
      fetchSpaces({ floorId, startTime, endTime, teamBookingId, roomsStartTime, roomsEndTime }),
    enabled: !!floorId,
  });
};

export const useFloorplanData = ({
  floorId,
  teamBookingId,
  startTime,
  endTime,
  options,
}: FloorDataParams & {
  options?: UseQueryOptions<FloorplanData>;
}): UseQueryResult<FloorplanData> => {
  return useQuery({
    queryKey: ['floorplan-data', floorId, startTime, endTime, teamBookingId],
    queryFn: () => fetchFloorplanData({ floorId, teamBookingId, startTime, endTime }),
    enabled: !!floorId,
    ...options,
  });
};

export const useInvalidateFloorplanSpaces = () => {
  const queryClient = useQueryClient();

  const invalidateFloorplanSpaces = useCallback(() => {
    queryClient.invalidateQueries('floorplan-spaces-data');
  }, [queryClient]);

  return { invalidateFloorplanSpaces };
};
