import { BaseSVLivePresence } from '@engage-shared/api/people/interfaces';
import { PresenceStatus } from '@engage-shared/constants/person';
import { checkOrParse } from '@engage-shared/utils/dates';
import { differenceInMinutes } from 'date-fns';
import { Nullable } from '@engage-shared/utils/types';

/**
 * Returns presence status
 * @param event
 * @param eventNotOlderThanInMinutes
 */
export const getSVLivePresenceStatus = (
  event: Nullable<BaseSVLivePresence>,
  eventNotOlderThanInMinutes = 60,
): PresenceStatus | null => {
  if (!event) return null;

  const { presenceStatus, lastSeenTime } = event;
  const minutesDifference = differenceInMinutes(
    new Date(),
    // lastSeenTime is UTC, so we need to parse date in the local time zone
    new Date(checkOrParse(lastSeenTime)),
  );

  if (minutesDifference > eventNotOlderThanInMinutes) {
    return null;
  }

  return presenceStatus;
};
