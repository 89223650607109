import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { isToday } from 'date-fns';
import { AgendaEventWrapper } from '@engage-web/components/layouts/Agenda/content/Items/styled';
import AgendaEvent from '@engage-web/components/layouts/Agenda/content/Items/AgendaEvent';
import Separator from '@engage-web/components/layouts/Agenda/content/Separator/Separator';
import { getTime } from '@engage-shared/utils';
import { AgendaItem } from '@engage-shared/api';

interface AgendaItemProps {
  item: {
    day: Date;
    events: AgendaItem[];
  };
  onSelectEvent: ({ event }: { event: AgendaItem }) => void;
}

interface IAgendaEvent extends AgendaItem {
  type?: 'separator';
}

const AgendaItem = ({ item, onSelectEvent }: AgendaItemProps) => {
  const { color9 } = useTheme();
  const { day, events } = item;

  const _isToday = isToday(day);
  // Cloning the array here is required to prevent the separator from being duplicated.
  // The Agenda list renders the items multiple times.
  const _dayEvents: IAgendaEvent[] = _isToday ? [...events] : events;
  if (_isToday) {
    const _now = new Date();
    // Hack to make the separator type match
    _dayEvents.push({
      isInInvalidBuilding: false,
      isInInvalidFloor: false,
      isInInvalidSpace: false,
      isLocalTimeZone: false,
      type: 'separator',
      start: _now,
      end: _now,
      bookingType: 'Desk',
      buildingId: 0,
      isAllDayBooking: false,
      isBookedForOther: false,
      isDesk: false,
      isInInactiveLocation: false,
      meetingId: -1, // TODO: verify that -1 is a good default value
      name: '',
      reserveeName: '',
      spaceId: -1,
      summary: '',
      timeZone: '',
      reservee: -1,
    });
  }

  return (
    <AgendaEventWrapper>
      {_dayEvents
        .sort((a, b) => getTime(a.start) - getTime(b.start))
        .map(event => {
          const { type, meetingId } = event;
          if (type === 'separator') {
            return <Separator key="separator" color={color9} />;
          }
          return <AgendaEvent key={`${meetingId}`} event={event} onSelectEvent={onSelectEvent} />;
        })}
    </AgendaEventWrapper>
  );
};

export default memo(AgendaItem);
