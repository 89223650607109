import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, inAppNotificationsActions, userConfigSelectors } from '@engage-web/store';
import { useTranslation } from 'react-i18next';
import { DaysSelector, Icon } from '@engage-web/components/base';
import {
  useConfig,
  useDatePicker,
  useModal,
  useReservationFilters,
  useRestrictTo,
} from '@engage-web/utils/hooks';
import { isSameDay } from 'date-fns';
import { DATE_TIME_PICKER_VARIANT, MODALS, RESTRICT_TO_VALUES } from '@engage-web/constants';
import { testProps, useLocalTimeFormatH12 } from '@engage-web/utils';
import { isElectron } from '@engage-web/utils/electron';
import {
  SearchFilterInputGroup,
  SearchFilterInputLabel,
  StyledDatePickerButton,
  StyledDatePickerButtonText,
} from './styled';
import {
  DateFormat,
  formatLocalizedTime,
  getAppliedFilterDates,
  getDayStartTime,
  getDisabledDaysFromBookingLimit,
} from '@engage-shared/utils';

const DateInterval = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const restrictTo = useRestrictTo();
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const { futureBookingsLimit, kioskRestrictBookingToCurrentDay } = useConfig();
  const isDesk = restrictTo === RESTRICT_TO_VALUES.DESK;
  const isKiosk = isElectron();

  const { selectedDates, enforceAllDayDeskReservations } = useReservationFilters(isDesk, true);

  const setFilterValues = useCallback(
    (values: Record<string, any>) =>
      dispatch(filtersActions.setFilterValues({ ...values, isDraft: true })),
    [dispatch],
  );

  const { dateStart, dateEnd, allDay, defaultEndDate, defaultStartDate, timeZone } = useDatePicker({
    isDraft: true,
  });

  const showDaysSelector = enforceAllDayDeskReservations && isDesk;
  const labelText = showDaysSelector ? t('layout.searchFilter.forLabel') : `${t('common.to')}:`;

  useEffect(() => {
    if (isDesk && enforceAllDayDeskReservations && !allDay) {
      setFilterValues({ allDay: true });
    }
  }, [isDesk, allDay, dispatch, enforceAllDayDeskReservations, setFilterValues]);

  const setSelectedDates = useCallback(
    (dates: Date[]) => setFilterValues({ selectedDates: dates }),
    [setFilterValues],
  );

  const { dateStartApplied, dateEndApplied } = getAppliedFilterDates({
    dateStart,
    dateEnd,
    setFilterValues,
    defaultStartDate,
    defaultEndDate,
  });

  const showBookingIsRestrictedToNowNotification = () => {
    dispatch(
      inAppNotificationsActions.addWarningNotification({
        message: t('layout.notifications.kioskCurrentDayBookingOnly'),
      }),
    );
  };

  const toggleStartDatePicker = () => {
    if (isKiosk && kioskRestrictBookingToCurrentDay) {
      showBookingIsRestrictedToNowNotification();
      return;
    }

    openModal(MODALS.DATE_TIME_PICKER, {
      variant: DATE_TIME_PICKER_VARIANT.INTERVAL_START,
    });
  };

  const toggleEndDatePicker = () => {
    if (isKiosk && kioskRestrictBookingToCurrentDay) {
      showBookingIsRestrictedToNowNotification();
      return;
    }

    openModal(MODALS.DATE_TIME_PICKER, {
      variant: DATE_TIME_PICKER_VARIANT.INTERVAL_END,
      dateStart: dateStartApplied,
    });
  };

  // mark current(first) date as selected
  useEffect(() => {
    if (isDesk && enforceAllDayDeskReservations) {
      const dateStartLocalized = getDayStartTime({
        date: dateStart,
        timeZone,
      });

      // prevents infinite loop
      if (isSameDay(selectedDates[0], dateStartLocalized)) {
        return;
      }

      setSelectedDates([dateStartLocalized, ...selectedDates.slice(1)]);
    }
  }, [isDesk, dateStart, selectedDates, setSelectedDates, timeZone, enforceAllDayDeskReservations]);

  const displayTime = !enforceAllDayDeskReservations || !isDesk;
  const isTimeFormatH12 = useLocalTimeFormatH12();
  const dateText = (date: Date) =>
    `${formatLocalizedTime(date, {
      timeZone,
      locale,
      format: displayTime
        ? DateFormat.weekdayShortWithMonthAndDayAndTime
        : DateFormat.weekdayShortWithMonthAndDay,
      hour12: isTimeFormatH12,
    })}`;

  const disabledDaysFromLimit = getDisabledDaysFromBookingLimit({
    filterDateStart: dateStart || new Date(),
    futureBookingsLimit,
    timeZone,
  });

  return (
    <>
      <SearchFilterInputGroup>
        <SearchFilterInputLabel>{t('layout.searchFilter.fromLabel')}</SearchFilterInputLabel>
        <StyledDatePickerButton
          onClick={toggleStartDatePicker}
          $isHighlighted
          {...testProps(t, 'accessibilityLabels.selectDateIntervalFrom').props}
        >
          <StyledDatePickerButtonText $isHighlighted>
            {dateText(dateStartApplied)}
          </StyledDatePickerButtonText>
          <Icon name="calendar" size={18} />
        </StyledDatePickerButton>
      </SearchFilterInputGroup>
      <SearchFilterInputGroup>
        <SearchFilterInputLabel>{labelText}</SearchFilterInputLabel>
        {showDaysSelector ? (
          <DaysSelector
            selectedDays={selectedDates}
            disabledDays={disabledDaysFromLimit}
            onDaySelected={setSelectedDates}
            dateStart={dateStartApplied}
            timeZone={timeZone}
            currentDayAlwaysSelected
          />
        ) : (
          <>
            <StyledDatePickerButton
              onClick={toggleEndDatePicker}
              {...testProps(t, 'accessibilityLabels.selectDateIntervalTo').props}
            >
              <StyledDatePickerButtonText>{dateText(dateEndApplied)}</StyledDatePickerButtonText>
              <Icon name="calendar" size={18} />
            </StyledDatePickerButton>
          </>
        )}
      </SearchFilterInputGroup>
    </>
  );
};

export default memo(DateInterval);
