import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { Item } from './Item';
import { ErrorWrapper, ListWrapper, StyledFixedSizeList } from './styled';

const ITEM_HEIGHT = 32;
const ITEMS_COUNT = 6;

interface FilterListProps {
  query: any; // Query function type
  onToggle: ({ id, checked }: { id: number; checked: boolean }) => void;
  selected: number[];
  spaceType: 'Space' | 'Desk' | 'Person' | 'Team';
  filterType: 'spaceTypes' | 'spaceAttributes';
}

const FilterList = ({ query, onToggle, selected, spaceType, filterType }: FilterListProps) => {
  const { t } = useTranslation();
  const { data, isError } = query({ spaceType });

  if (isError) {
    return <ErrorWrapper data-testid="filter-error">{t('components.toggle.error')}</ErrorWrapper>;
  }

  return (
    <ListWrapper data-testid="filter-list" id={`${filterType}${spaceType}`}>
      <AutoSizer>
        {({ width, height }: Size) => (
          <StyledFixedSizeList
            height={height}
            itemCount={data?.length ?? ITEMS_COUNT}
            itemSize={ITEM_HEIGHT}
            width={width}
            itemData={{ items: data, selected, onToggle }}
          >
            {Item}
          </StyledFixedSizeList>
        )}
      </AutoSizer>
    </ListWrapper>
  );
};

export default memo(FilterList);
