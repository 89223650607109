import React from 'react';
import { useTranslation } from 'react-i18next';
import { testProps, useTeamBookingStartDate } from '@engage-web/utils';
import { DateButtonView } from '@engage-web/components/base/DateTime/DateButton/DateButtonView';
import { TeamBookingStartDateWrapper } from './styled';

export function TeamBookingStartDate() {
  const { t } = useTranslation();
  const startDate = useTeamBookingStartDate();

  return (
    <TeamBookingStartDateWrapper>
      <DateButtonView
        disabled
        isNow={false}
        {...testProps(t, 'accessibilityLabels.teamBookingStartDate').props}
        clearButtonProps={{ disabled: true }}
      >
        {startDate}
      </DateButtonView>
    </TeamBookingStartDateWrapper>
  );
}
