import { useSelector } from 'react-redux';
import { filtersSelectors } from '@engage-web/store';
import { formatISODate, useFilterTimes } from '@engage-shared/utils';
import { useCurrentTimeZone } from './useCurrentTimeZone';
import { useConfig } from './useConfig';
import { SpaceType } from '@engage-shared/api/spaces';

export type FilterProps = {
  dateStart: Date;
  dateEnd: Date;
  allDay: boolean;
  selectedDates: Date[];
};

const useReservationFilters = (isDesk: boolean, isDraft?: boolean) => {
  const { dateStart, dateEnd, allDay, selectedDates }: FilterProps = useSelector(
    isDraft ? filtersSelectors.getDraftFilterDates : filtersSelectors.getFilterDates,
  );

  const { deskBookingInterval, meetingRoomBookingInterval, enforceAllDayDeskReservations } =
    useConfig();

  const timeZone = useCurrentTimeZone();

  const { startTime, endTime, roomsStartTime, roomsEndTime } = useFilterTimes({
    dateStart,
    dateEnd,
    timeZone,
    enforceAllDayDeskReservations,
    restrictTo: isDesk ? SpaceType.DESK : SpaceType.SPACE,
  });

  const bookingIntervalSize: number = isDesk ? deskBookingInterval : meetingRoomBookingInterval;

  const filterDates = selectedDates.length
    ? selectedDates.map(selectedDate =>
        formatISODate(selectedDate, {
          timeZone,
        }),
      )
    : null;

  return {
    dateStart,
    dateEnd,
    allDay,
    selectedDates,
    startTime,
    endTime,
    roomsStartTime,
    roomsEndTime,
    filterDates,
    bookingIntervalSize,
    enforceAllDayDeskReservations,
  };
};

export default useReservationFilters;
