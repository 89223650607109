import * as React from 'react';
import { Coordinate } from '@floorplan/api';
import { baseStyle, RadarStyle } from './RadarPing.styles';
import { DEFAULT_FLOORPLAN_SCALE } from '../../constants';
import { setAlpha } from '../../utils';

interface RadarPingProps {
  coordinate: Coordinate;
  pointSize: number;
  fill: React.CSSProperties['fill'];
  repeat?: string;
  scale?: number;
}
export const RadarPing = ({
  coordinate,
  scale = DEFAULT_FLOORPLAN_SCALE,
  pointSize,
  fill = '#162d44',
  repeat = '1000',
}: RadarPingProps) => {
  const { x, y } = coordinate;

  const maxR = Math.ceil((pointSize * DEFAULT_FLOORPLAN_SCALE) / scale) * 6;
  const { fillOpacity, stroke, strokeWidth } = baseStyle;

  const gradientStartStyle = {
    stopColor: setAlpha(fill, fillOpacity / 6),
    stopOpacity: 0,
  };
  const gradientStopStyle = {
    // setting this to transparent will make
    // ping animation without a background color
    // also looks OK
    stopColor: setAlpha(fill, fillOpacity),
    stopOpacity: 1,
  };

  const circleProps = {
    cx: '50%',
    cy: '50%',
    r: '0%',
    stroke,
    strokeWidth,
    fill: 'url(#grad1)',
    style: { animationIterationCount: parseInt(repeat, 10) || repeat },
  };

  return (
    <RadarStyle height={maxR} width={maxR} x={x - maxR / 2} y={y - maxR / 2}>
      <defs>
        <radialGradient id="grad1" cx="50%" cy="50%" r="100%" fx="50%" fy="50%">
          <stop offset="0%" style={gradientStartStyle} />
          <stop offset="100%" style={gradientStopStyle} />
        </radialGradient>
      </defs>
      <circle className="innerCircle" {...circleProps} />
      <circle className="innerCircle2" {...circleProps} />
      <circle className="innerCircle3" {...circleProps} />
    </RadarStyle>
  );
};
