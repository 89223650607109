import { Reducer, useReducer } from 'react';

import { FloorplanData } from '@floorplan/api';

const initialState = {
  spaces: undefined,
  filteredSpaces: undefined,
  drawableMarkers: undefined,
  zoomDescriptor: undefined,
  floorId: 0,
} as FloorplanData;

const Action = {
  SET_DATA: 'setData',
  SET_ZOOM_DESCRIPTOR: 'setZoomDescriptor',
  SET_SPACES_DATA: 'setSpacesData',
  SET_DRAWABLE_MARKERS: 'setDrawableMarkers',
  SET_FLOOR_ID: 'floorId',
};

interface DataAction {
  type: (typeof Action)[keyof typeof Action];
  payload: any;
}

const reducer = (state: FloorplanData, action: DataAction) => {
  switch (action.type) {
    case Action.SET_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case Action.SET_ZOOM_DESCRIPTOR:
      return {
        ...state,
        zoomDescriptor: action.payload,
      };

    case Action.SET_SPACES_DATA:
      return {
        ...state,
        spaces: action.payload?.spaces,
        filteredSpaces: action.payload?.filteredSpaces,
      };

    case Action.SET_DRAWABLE_MARKERS:
      return {
        ...state,
        drawableMarkers: action.payload,
      };
    case Action.SET_FLOOR_ID:
      return {
        ...state,
        floorId: action.payload,
      };

    default:
      return state;
  }
};

export const updateDataState = (payload: FloorplanData) => ({
  type: Action.SET_DATA,
  payload,
});
export const setZoomDescriptor = (payload: any) => ({
  type: Action.SET_ZOOM_DESCRIPTOR,
  payload,
});
export const setSpacesData = (payload: any) => ({
  type: Action.SET_SPACES_DATA,
  payload,
});
export const setDrawableMarkers = (payload: any) => ({
  type: Action.SET_DRAWABLE_MARKERS,
  payload,
});
export const setFloorId = (payload: any) => ({
  type: Action.SET_FLOOR_ID,
  payload,
});

export const useFloorplanInitDataReducer = () =>
  useReducer<Reducer<FloorplanData, DataAction>>(reducer, initialState);
