import * as React from 'react';
import OpenSeadragon from 'openseadragon';

import { ZoomDescriptor } from '@floorplan/api';
import { monkeyPatchOpenSeaDragon } from '../../utils';
import { ZoomDescriptorContainer } from './ZoomDescriptorContainer';
import { ViewerContainer } from './ZoomDescriptorViewer.styles';

monkeyPatchOpenSeaDragon();

type FloorplanViewerProps = React.PropsWithChildren<{
  zoomDescriptor?: ZoomDescriptor;
  showZoomTiles?: boolean;
  viewerOptions?: OpenSeadragon.Options;
}>;
export const ZoomDescriptorViewer = ({
  showZoomTiles = true,
  viewerOptions = {},
  zoomDescriptor,
  children,
}: FloorplanViewerProps) => {
  return (
    <ViewerContainer>
      <ZoomDescriptorContainer
        zoomDescriptor={zoomDescriptor}
        showZoomTiles={showZoomTiles}
        viewerOptions={viewerOptions}
      >
        {children}
      </ZoomDescriptorContainer>
    </ViewerContainer>
  );
};
