import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBuildingFloors } from '@engage-shared/api';
import Floor from './Floor';
import {
  BuildingName,
  BuildingWrapper,
  FloorsListWrapper,
  LabelsContainer,
  LabelFloorAvailability,
} from './styled';
import FloorListSkeleton from './FloorListSkeleton';
import { SET_ACTIVE_BUILDING } from './useLocationSelector';

const FLOORS_REFETCH_TIME = 5 * 60 * 1000;

interface BuildingProps {
  building: {
    id: number;
    name: string;
  };
  locationState: any;
  locationDispatch: any; // TODO: fix types after typing the store
}

const Building = ({ building, locationState, locationDispatch }: BuildingProps) => {
  const { t } = useTranslation();
  const { activeBuilding } = locationState;

  const open = activeBuilding && activeBuilding.id === building.id;

  const {
    data: floors,
    isLoading,
    isError,
    refetch,
  } = useBuildingFloors(building.id, {
    enabled: !!open,
    refetchInterval: FLOORS_REFETCH_TIME,
  });

  // re-fetch floors availability info on building opening
  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const renderFloorsList = () => {
    if (!open) {
      return null;
    }
    if (isLoading) {
      return <FloorListSkeleton />;
    }
    if (isError) {
      return (
        <FloorsListWrapper>
          <div style={{ maxWidth: 200 }}>{t('layout.locationSelector.floorsError')}</div>
        </FloorsListWrapper>
      );
    }
    return (
      <FloorsListWrapper>
        {floors?.length && (
          <LabelsContainer>
            <LabelFloorAvailability>{t('common.floor')}</LabelFloorAvailability>
            <LabelFloorAvailability>{t('common.availability')}</LabelFloorAvailability>
          </LabelsContainer>
        )}
        {floors?.map(floor => (
          <Floor key={floor.id} floor={floor} locationState={locationState} />
        ))}
      </FloorsListWrapper>
    );
  };

  const openBuilding = () => {
    locationDispatch({ type: SET_ACTIVE_BUILDING, payload: building });
  };

  const closeBuilding = () => {
    locationDispatch({ type: SET_ACTIVE_BUILDING, payload: null });
  };

  return (
    <BuildingWrapper>
      <BuildingName
        data-testid="building-list-item"
        $isActive={open}
        onClick={() => (open ? closeBuilding() : openBuilding())}
      >
        {building.name}
      </BuildingName>
      {renderFloorsList()}
    </BuildingWrapper>
  );
};

export default Building;
