import { endOfMonth, isBefore } from 'date-fns';
import { ErrorType } from '@engage-shared/constants/api';
import {
  AgendaEventsMonthParams,
  AgendaEventsTimeParams,
  Event,
  EventsData,
} from '@engage-shared/api/users/interfaces';
import { FetchBuildingQuery } from '@engage-shared/api/buildings/interfaces';
import { TFunction as TranslateFunction } from 'i18next';
import { ISODateString } from '@engage-shared/utils/types';
import { PersonId } from '@engage-shared/api/people/interfaces';

type GetMonthBoundaryDates = (params: AgendaEventsMonthParams) => AgendaEventsTimeParams;
/**
 * Get start and end dates for the selected month.
 * @param year
 * @param month
 * {startTime, endTime}
 */
export const getMonthBoundaryDates: GetMonthBoundaryDates = ({ year, month }) => {
  const startDate = new Date(year, month, 1, 0, 0, 0);
  const endDate = endOfMonth(startDate);

  const startTime = startDate.toISOString() as ISODateString;
  const endTime = endDate.toISOString() as ISODateString;

  return { startTime, endTime };
};

type AddTimeZoneToEventsProps = {
  events: PreParsedEvent[];
  fetchBuildingQuery: FetchBuildingQuery;
  userTimeZone: string;
  translationFn: TranslateFunction;
};
type AddTimeZoneToEvents = ({
  events,
  fetchBuildingQuery,
  userTimeZone,
  translationFn,
}: AddTimeZoneToEventsProps) => Promise<Event[]>;

export const addTimeZoneToEvents: AddTimeZoneToEvents = async ({
  events,
  fetchBuildingQuery,
  userTimeZone,
  translationFn,
}: AddTimeZoneToEventsProps) => {
  return Promise.all(
    events.map(async event => {
      const eventWithoutBuilding = {
        ...event,
        timeZone: userTimeZone,
        isLocalTimeZone: true,
      } as Event;

      if (event.isInInvalidFloor || event.isInInvalidBuilding || event.isInInvalidSpace) {
        eventWithoutBuilding.name = translationFn('common.attentionRequired');
        eventWithoutBuilding.isInInactiveLocation = true;
      }

      let building;
      try {
        // go to catch block to avoid unnecessary API call
        // since we already know that building is not available
        if (eventWithoutBuilding.isInInactiveLocation) {
          throw new Error(ErrorType.NOT_FOUND);
        }
        building = await fetchBuildingQuery(event.buildingId);
      } catch (error: any) {
        // if building is no longer displayed in Wayfinding, show 'Attention Required' instead of name (P2-2596)
        if (error?.message === ErrorType.NOT_FOUND) {
          // don't display 'Attention Required' for past bookings because they can't be deleted
          const hasFinished = isBefore(new Date(event.end), new Date());
          if (hasFinished) {
            eventWithoutBuilding.name = event.name;
          }
        }

        return eventWithoutBuilding;
      }

      if (building) {
        const { timeZone, name } = building;
        return {
          ...event,
          timeZone,
          isLocalTimeZone: timeZone === userTimeZone,
          buildingName: name,
          building,
        } as Event;
      }
      return eventWithoutBuilding;
    }),
  );
};

type PreParsedEvent = Omit<Event, 'timeZone' | 'isLocalTimeZone'>;
type MapEventItemAttributes = (events: EventsData[], personId?: PersonId) => PreParsedEvent[];

export const mapEventItemAttributes: MapEventItemAttributes = (events, personId) => {
  return events.map(event => {
    const {
      spaceId,
      preferredName,
      containerType,
      startTime,
      endTime,
      buildingId,
      isDesk,
      deskName,
      isAllDayBooking,
      reservee,
      reserveeName,
      meetingId,
      bookingType,
      teamName,
      isInInvalidBuilding,
      isInInvalidFloor,
      isInInvalidSpace,
      isInInactiveLocation,
    } = event;

    const isBookedForOther = !!personId && !!reservee && `${personId}` !== `${reservee}`;

    const name =
      (bookingType === 'Space' || bookingType === 'Desk') && `${preferredName}` !== `${spaceId}`
        ? preferredName
        : deskName;

    return {
      spaceId,
      meetingId,
      summary: containerType,
      start: startTime,
      end: endTime,
      isAllDayBooking,
      buildingId,
      isDesk,
      name,
      reservee,
      reserveeName,
      isBookedForOther,
      bookingType,
      teamName,
      isInInvalidBuilding,
      isInInvalidFloor,
      isInInvalidSpace,
      isInInactiveLocation,
    };
  });
};
