import { addHours, isAfter, isBefore, isEqual, isValid, toDate } from 'date-fns';
import { getDayEndTime, getDayStartTime, isToday } from './timezoneHelpers';
import { getClosestTime, roundTimeToInterval } from './dateHelpers';
import { QUARTER_HOUR_VALUES } from './constants';
import { FilterValues } from '@engage-shared/utils/search';
import { Nullable } from '@engage-shared/utils/types';
import { RoundingDirection } from '@engage-shared/constants/roundingDirection';

/**
 * Check if date is valid and return it, or return current date rounded up to closest interval.
 * @param date
 */
export const getValidOrCurrentDate = (date: Nullable<Date> | undefined): Date =>
  date && isValid(date) ? date : getClosestTime(new Date(), QUARTER_HOUR_VALUES);

/**
 * Check if selected time is valid or initialize with current time rounded up.
 * @param startDate selected date
 * @return { Date }
 */
export const getSelectedStartTime = (startDate: Nullable<Date>): Date => {
  return getValidOrCurrentDate(startDate);
};

/**
 * Returns last interval of the selected date, localized 23:45.
 * @param date
 * @param timeZone
 */
export const getLastIntervalOfTheDay = (date: Date, timeZone: string): Date => {
  const endOfDay = getDayEndTime({ date, timeZone });
  endOfDay.setMinutes(45, 0, 0);
  return endOfDay;
};

/**
 * Returns start date + 1 hr unless that would be past last interval of the day 23:45, in which case it returns the last interval.
 * @param start
 * @param timeZone
 */
export const getDefaultEndDate = (start: Date, timeZone: string): Date => {
  const endDate = addHours(start, 1);
  const lastInterval = getLastIntervalOfTheDay(start, timeZone);
  if (isAfter(endDate, lastInterval)) {
    return lastInterval;
  }
  return endDate;
};

/**
 * Adjust start and end date so that date start is before date end.
 * @param dateStart selected start date
 * @param dateEnd selected end date
 * @return {Object}:{dateStart, dateEnd}
 */
export const getSelectedTimes = (
  dateStart: Nullable<Date | undefined>,
  dateEnd: Nullable<Date>,
): {
  dateStart: Date;
  dateEnd: Date;
} => {
  const newDateStart = dateStart ? toDate(dateStart) : getValidOrCurrentDate(dateStart);

  const isDateEndValid = dateEnd && isValid(dateEnd);
  let newDateEnd = isDateEndValid ? toDate(dateEnd) : addHours(newDateStart, 1);

  // keep dateStart before dateEnd
  if (!isAfter(newDateEnd, newDateStart)) {
    // update date end to be after date start
    newDateEnd = addHours(newDateStart, 1);
  }

  return {
    dateStart: newDateStart,
    dateEnd: newDateEnd,
  };
};

/**
 * When enforced full day desk reservations, use start of day, for hourly reservation use current time or selected time.
 *
 * Implemented according to rules from https://serraview.atlassian.net/wiki/spaces/PC/pages/1911980729/Floorplan+time+calculations
 * @param startDate selected date
 * @param timeZone
 * @param enforceAllDayDeskReservations
 * @return {Date} startTime
 */
export const getFilterStartTime = (
  startDate: Date | null,
  timeZone: string,
  enforceAllDayDeskReservations: boolean,
): Date => {
  const date =
    startDate && isValid(startDate)
      ? startDate
      : roundTimeToInterval(new Date(), 1, RoundingDirection.UP);

  return enforceAllDayDeskReservations && !isToday(date, timeZone)
    ? getDayStartTime({ date, timeZone })
    : date;
};

/**
 * Get date+1 hour unless end of day is in less than one hour,
 * in which case return the end of day, never go into the next day.
 */

export const getNextHourOrEndOfDay = (date: Date, timeZone: string): Date => {
  const endTime = addHours(date, 1);

  const endOfDay = getDayEndTime({ date, timeZone });

  return isAfter(endTime, endOfDay) ? endOfDay : endTime;
};

/**
 * When enforced full day desk reservations, use end of day, for hourly reservation use start time + 1hr without passing into the next day.
 *
 * Implemented according to rules from https://serraview.atlassian.net/wiki/spaces/PC/pages/1911980729/Floorplan+time+calculations
 * @param startDate
 * @param endDate selected end date
 * @param timeZone
 * @param enforceAllDayDeskReservations
 * @return {Date} endTime
 */
export const getFilterEndTime = (
  startDate: Date,
  endDate: Nullable<Date>,
  timeZone: string,
  enforceAllDayDeskReservations: boolean,
): Date => {
  // if an explicit end date was selected use it for hourly reservations
  if (endDate && isValid(endDate) && !enforceAllDayDeskReservations) {
    return endDate;
  }

  // else calculate end date from startDate or current date
  const date = getValidOrCurrentDate(startDate);

  return enforceAllDayDeskReservations
    ? getDayEndTime({ date, timeZone })
    : getNextHourOrEndOfDay(date, timeZone);
};

/**
 * Get values to be displayed in Reservations Assist.
 */
export const getAppliedFilterDates = ({
  dateStart,
  dateEnd,
  setFilterValues,
  defaultStartDate,
  defaultEndDate,
}: {
  dateStart: Nullable<Date>;
  dateEnd: Nullable<Date>;
  setFilterValues: (filters: FilterValues) => void;
  defaultStartDate: Date;
  defaultEndDate: Date;
}): { dateStartApplied: Date; dateEndApplied: Date } => {
  const dateEndApplied = dateEnd || defaultEndDate;

  const dateStartApplied =
    dateStart && isBefore(dateStart, dateEndApplied) ? dateStart : defaultStartDate;

  let newValues: { dateStart?: Date; dateEnd?: Date } = {};
  if (!isEqual(dateStartApplied, dateStart || defaultStartDate)) {
    newValues = { dateStart: dateStartApplied };
  }
  if (!dateEnd || !isEqual(dateEndApplied, dateEnd)) {
    newValues = { ...newValues, dateEnd: dateEndApplied };
  }
  // check (dateStart || dateEnd) to make sure values were not just cleared and avoid bringing them back
  if ((dateStart || dateEnd) && (newValues.dateStart || newValues.dateEnd)) {
    //@ts-ignore
    setFilterValues(newValues);
  }

  return { dateStartApplied, dateEndApplied };
};
