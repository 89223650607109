import { css } from 'styled-components';

export default css`
  .floorplan {
    background: #e6e8eb !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    * {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .openseadragon-canvas:focus {
    outline: none;
  }

  .spinner-container {
    z-index: 3;
    text-align: center;
    margin: 20px 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner {
    margin: 60px auto;
    font-size: 4px;
    position: relative;
    text-indent: -9999em;
    border-top: 2em solid rgba(80, 80, 80, 0.2);
    border-right: 2em solid rgba(80, 80, 80, 0.2);
    border-bottom: 2em solid rgba(80, 80, 80, 0.2);
    border-left: 2em solid #505050;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spinnerAnimation 1.1s infinite linear;
    animation: spinnerAnimation 1.1s infinite linear;
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  .spinner::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @keyframes spinnerAnimation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .floorplan-shapes {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
    pointer-events: none;
  }

  .floorplan-labels {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
    pointer-events: none;

    rect {
      pointer-events: none;
    }
    text {
      pointer-events: none;
    }
  }
`;
