import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { inAppNotificationsActions } from '@engage-web/store';
import { useRefreshBookings } from '@engage-web/api/queries';
import { ParsedSpaceItem } from '@engage-shared/api/spaces';
import {
  getMultiDayBookingFailedMessage,
  getMultiDayBookingSuccessMessage,
} from '@engage-web/components/layouts/Reserve/Card/hooks/useReserveCallbacks';

type UseTeamReserveCallbacksProps = { spaceItem: ParsedSpaceItem };
export const useTeamReserveCallbacks = ({ spaceItem }: UseTeamReserveCallbacksProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { refresh: refreshBookings } = useRefreshBookings();

  const onSuccessHourlyTeamBooking = () => {
    // refresh bookings
    refreshBookings({ spaceItem });

    dispatch(
      inAppNotificationsActions.addSuccessNotification({
        message: t(`layout.reserve.team.successful`),
      }),
    );
  };

  const onErrorTeamBooking = (error: any) => {
    //custom error for reservations outside operating hours
    const status = error?.response?.status;
    let errorMsg = error?.response?.data?.error;
    if (Array.isArray(errorMsg) && errorMsg.length > 0) {
      errorMsg = errorMsg[0];
    }
    if (status === 500 && errorMsg?.contains('The building is not open for reservations')) {
      dispatch(
        inAppNotificationsActions.addErrorNotification({
          message: t(`layout.reserve.team.buildingHours`),
        }),
      );
      return;
    }

    dispatch(
      inAppNotificationsActions.addErrorNotification({
        message: t(`layout.reserve.team.errorOccurred`),
      }),
    );
  };

  type OnSuccessMultiDayTeamBookingProps = {
    successfullyReserved: boolean;
    successfullyReservedDaysMessage: string;
    failedDaysMessage: string;
  };
  // display success UI notification if at least one successfully reservation was made
  // and display error message with the list of days which couldn't be reserved
  const onSuccessMultiDayTeamBooking = ({
    successfullyReserved,
    successfullyReservedDaysMessage,
    failedDaysMessage,
  }: OnSuccessMultiDayTeamBookingProps) => {
    // means there are at least one successful reservation
    if (successfullyReserved) {
      dispatch(
        inAppNotificationsActions.addSuccessNotification({
          message: getMultiDayBookingSuccessMessage({
            t,
            successfullyReservedDaysMessage,
          }),
        }),
      );
    }

    // means there are days failed to reserve
    if (failedDaysMessage) {
      dispatch(
        inAppNotificationsActions.addErrorNotification({
          message: getMultiDayBookingFailedMessage({
            t,
            failedDaysMessage,
          }),
        }),
      );
    }

    // refresh bookings
    refreshBookings({ spaceItem });
  };

  return {
    onSuccessHourlyTeamBooking,
    onErrorTeamBooking,
    onSuccessMultiDayTeamBooking,
  };
};
