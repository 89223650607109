import { CHECK_IN_STATUS } from '@engage-shared/constants/checkInStatuses';
import moment, { Moment } from 'moment-timezone';
import { LanguageTypes } from '@engage-shared/constants/languages';
import { Nullable } from '../types';
import { formatLocalizedTime, formatWeekDayLongWithMonthAndDay } from '../dates';

type GetStatusMessageParams = {
  status: CHECK_IN_STATUS;
  hours?: number;
  minutes?: number;
  date?: Nullable<Moment>;
  timeZone: string;
  locale: LanguageTypes;
  isTimeFormatH12: boolean;
};
type MessageOptions = {
  date?: string;
  time?: string;
  hours?: number;
  minutes?: number;
};
type StatusMessageResponse = [message: string, options?: MessageOptions];
type GetStatusMessage = (params: GetStatusMessageParams) => Nullable<StatusMessageResponse>;

export const getStatusMessage: GetStatusMessage = ({
  status,
  hours,
  minutes,
  date,
  timeZone,
  locale,
  isTimeFormatH12,
}) => {
  if (!status || CHECK_IN_STATUS.NONE === status) return null;

  switch (status) {
    case CHECK_IN_STATUS.BEFORE_CHECK_IN: {
      if (hours === undefined || minutes === undefined) return null;

      const now = moment();
      const duration = moment.duration(date?.diff(now));

      if (!!date && (hours > 12 || duration.days() > 0)) {
        const formattedDate = formatWeekDayLongWithMonthAndDay(date, {
          timeZone,
          locale,
        });

        const formattedTime = formatLocalizedTime(date, {
          timeZone,
          hour12: isTimeFormatH12,
        });

        return [
          'checkIn.oneDayBeforeCheckIn',
          {
            date: formattedDate,
            time: formattedTime,
          },
        ];
      }
      if (hours > 0) {
        return [
          'checkIn.hoursBeforeCheckIn',
          {
            hours,
            minutes,
          },
        ];
      }

      if (minutes > 0) {
        return [
          'checkIn.minutesBeforeCheckIn',
          {
            hours,
            minutes,
          },
        ];
      }

      return ['checkIn.secondsBeforeCheckIn'];
    }
    case CHECK_IN_STATUS.CHECK_IN_OPEN: {
      if (hours === undefined || minutes === undefined) return null;

      if (hours > 0) {
        return [
          'checkIn.hoursCheckInCloses',
          {
            hours,
            minutes,
          },
        ];
      }

      if (minutes > 0) {
        return [
          'checkIn.minutesCheckInCloses',
          {
            hours,
            minutes,
          },
        ];
      }

      return ['checkIn.secondsCheckInCloses'];
    }
    case CHECK_IN_STATUS.CHECKED_IN: {
      return ['checkIn.checkedIn'];
    }
    case CHECK_IN_STATUS.CHECK_IN_CLOSED: {
      return ['checkIn.checkInClose'];
    }
    default: {
      return null;
    }
  }
};
