import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Avatar } from '@engage-web/components/base';
import { useTheme } from 'styled-components';
import { Nullable } from '@engage-shared/utils/types';
import { UserWrapper, UserAvatar } from './styled';
import { UserData } from '@engage-shared/api';
import { Theme } from '@engage-shared/theme';

type UserProps = {
  user: Nullable<UserData>;
  hasUnreadNotifications: boolean;
  onClick: () => void;
  isLoading: boolean;
  isError: boolean;
  id?: string;
};

const User = ({ user, hasUnreadNotifications, isLoading, isError, onClick, id }: UserProps) => {
  const { color9 } = useTheme() as Theme;

  if (isLoading) {
    return <Skeleton circle height={48} width={48} />;
  }

  if (isError) {
    return <Avatar />;
  }

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const imageUrl = user?.imageUrl;

  return (
    <UserWrapper id={id} data-testid="user" onClick={onClick}>
      <UserAvatar
        source={imageUrl}
        name={`${firstName} ${lastName}`}
        status={color9}
        badgeVariant={hasUnreadNotifications ? 'dot' : 'standard'}
      />
    </UserWrapper>
  );
};

export default memo(User);
