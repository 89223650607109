import { CustomError } from '@engage-shared/api/instance/errors';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { joinPaths } from '@engage-web/router/utils';
import { inAppNotificationsActions } from '@engage-web/store';
import { useGenerateCurrentLocationPath } from '@engage-web/utils';

type UseTeamBookingDetailsCallbacksReturnValue = {
  onError: (error: unknown) => void;
};

export function useTeamBookingDetailsCallbacks(): UseTeamBookingDetailsCallbacksReturnValue {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLocationPath = useGenerateCurrentLocationPath();

  return {
    onError: error => {
      if ((error as CustomError).httpStatus === 404) {
        dispatch(
          inAppNotificationsActions.addWarningNotification({
            message: t('router.location.invalidTeamBooking'),
          }),
        );

        navigate(joinPaths(currentLocationPath, PATH_SEGMENT.TEAMS));
      }
    },
  };
}
