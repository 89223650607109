import { TeamBookingDeskAvailability, TeamDeskBooking } from '../interfaces/team.type';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ISODateString, Nullable } from '@engage-shared/utils/types';
import { TEAM_BOOKING_DESKS_AVAILABILITIES_QUERY_KEY } from '../constants';
import { TeamBookingDetails } from '../interfaces';
import { fetchFloorBookings } from '@engage-shared/api/floors/fetchBookings';
import { FetchFloorBookingsParams, FetchParams, FloorBooking } from '../../floors/interfaces';
import { fetchFloorDeskAvailabilities } from '@engage-shared/api/floors';

type GetDesksAvailabilities = (
  params: FetchFloorBookingsParams,
) => Promise<TeamBookingDeskAvailability[]>;
const getDesksAvailabilities: GetDesksAvailabilities = async ({
  tenantId,
  floorId,
  spaces,
  startTime,
  endTime,
}) => {
  const floorBookings = await fetchFloorBookings({
    tenantId,
    floorId,
    spaces,
    startTime,
    endTime,
  });

  const deskAvailabilities = await fetchFloorDeskAvailabilities({
    floorId: floorId!,
    startTime,
    endTime,
  });

  const getBookable = ({ spaceId }: FloorBooking): boolean => {
    const deskAvailability = deskAvailabilities?.find(({ id }) => id === spaceId);
    return deskAvailability?.bookable ?? true;
  };

  return floorBookings.map(floorBooking => ({
    id: floorBooking.spaceId,
    available: floorBooking.reservee.displayName === 'Team Booking',
    bookable: getBookable(floorBooking),
  }));
};

type UseTeamBookingDesksAvailabilitiesParams = FetchParams & {
  teamBookingDetails?: Nullable<TeamBookingDetails>;
  options?: UseQueryOptions<TeamBookingDeskAvailability[]>;
};
type UseTeamBookingDesksAvailabilities = (
  params: UseTeamBookingDesksAvailabilitiesParams,
) => UseQueryResult<TeamBookingDeskAvailability[]>;
export const useTeamBookingDesksAvailabilities: UseTeamBookingDesksAvailabilities = ({
  tenantId,
  teamBookingDetails,
  options = {},
}) => {
  const {
    meetingId: teamBookingId,
    deskBookings,
    startDate,
    endDate,
    location,
  } = teamBookingDetails || {};

  const floorId = location?.floorId;

  let enabled = !!tenantId && !!teamBookingId && !!floorId;
  if (enabled && options && Object.prototype.hasOwnProperty.call(options, 'enabled')) {
    enabled = enabled && !!options.enabled;
  }
  const spaces = deskBookings?.map((desk: TeamDeskBooking) => desk.spaceId);
  const startTime = startDate?.toISOString() as ISODateString;
  const endTime = endDate?.toISOString() as ISODateString;

  return useQuery<TeamBookingDeskAvailability[]>({
    queryKey: [TEAM_BOOKING_DESKS_AVAILABILITIES_QUERY_KEY, tenantId, teamBookingId, floorId],
    queryFn: () =>
      getDesksAvailabilities({
        tenantId,
        floorId,
        spaces,
        startTime,
        endTime,
      }),
    ...options,
    enabled,
  });
};
