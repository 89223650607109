import React, { useEffect, useRef, useState } from 'react';
import { DrawableFloorMarker } from '@engage-shared/api/floors/interfaces';
import { getTransformForCoordinate, getRotationString } from '../../../utils';
import { IconWrapper } from './RemoteMarkerIcon.styled';
import { useRotation } from '../../../hooks';

type RemoteMarkerIconProps = {
  marker: DrawableFloorMarker;
};

const RemoteMarkerIcon = ({
  marker: { icon, xCoordinate, yCoordinate },
}: RemoteMarkerIconProps) => {
  const [width, setWidth] = useState(23);
  const [height, setHeight] = useState(23);
  const rotation = useRotation();

  const gRef = useRef<SVGGElement>(null);

  useEffect(() => {
    if (gRef.current) {
      // determine real svg size, s we can draw it in accurate place
      const { height: gHeight, width: gWidth, x, y } = gRef.current.getBBox();
      setWidth((gWidth + x) / 2);
      setHeight((gHeight + y) / 2);
    }
  }, [icon]);

  if (!icon) {
    return null;
  }

  const style = getTransformForCoordinate({
    coordinate: {
      x: xCoordinate,
      y: yCoordinate,
    },
    width,
    height,
    scale: 0.9,
  });

  return (
    <g style={style}>
      <IconWrapper
        fill="white"
        ref={gRef}
        dangerouslySetInnerHTML={{
          // we need to add circle inside of rotating element, in order fot circle to rotate with icon
          __html: `
          <circle cx="40" cy="40" r="45" fill="black" />
          ${icon}
        `,
        }}
        // @ts-ignore
        style={getRotationString({ width, rotation, height })}
      />
    </g>
  );
};

RemoteMarkerIcon.displayName = 'RemoteMarkerIcon';
export { RemoteMarkerIcon };
