import React, { Dispatch } from 'react';
import { Icon } from '../../../base';
import {
  useRegionsBuildings,
  useRegionsChildren,
  Region as RegionType,
  BuildingData,
} from '@engage-shared/api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { UseQueryResult } from 'react-query';
import Building from './Building';
import { BuildingsListWrapper, RegionHeader, RegionWrapper } from './styled';
import BuildingListSkeleton from './BuildingListSkeleton';
import { ADD_REGION_TO_PATH, LocationState, LocationAction } from './useLocationSelector';

type RegionProps = {
  region: RegionType;
  locationState: LocationState;
  locationDispatch: Dispatch<LocationAction>;
  subRegion?: boolean;
  parentId?: number;
};

const Region = ({
  region,
  parentId,
  locationState,
  locationDispatch,
  subRegion = false,
}: RegionProps) => {
  const { t } = useTranslation();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { regionPath } = locationState;

  const open = regionPath.includes(region.id);

  const {
    data: childRegions,
    isLoading: isChildRegionsLoading,
    isIdle: isChildRegionsIdle,
    isError: isChildRegionsError,
  } = useRegionsChildren({
    tenantId,
    regionId: region.id,
    shouldSortRegions: true,
    options: {
      enabled: open && !region.isLeafNode,
    },
  }) as UseQueryResult<RegionType[]>;

  const {
    data: buildings,
    isLoading: isBuildingsLoading,
    isError: isBuildingsError,
  } = useRegionsBuildings({
    tenantId,
    regionId: region.id,
    sortBuildings: true,
    options: {
      enabled: open && isChildRegionsIdle,
    },
  }) as UseQueryResult<BuildingData[]>;

  const renderRegionsOrBuildingsList = () => {
    if (!open) {
      return null;
    }

    if (isChildRegionsLoading || isBuildingsLoading) {
      return <BuildingListSkeleton />;
    }

    if (isChildRegionsError || isBuildingsError) {
      return (
        <BuildingsListWrapper>
          <div style={{ maxWidth: 250 }}>
            {t('layout.locationSelector.subregionOrBuildingError')}
          </div>
        </BuildingsListWrapper>
      );
    }
    if (childRegions && childRegions?.length > 0) {
      return childRegions.map(childRegion => (
        <Region
          key={childRegion.id}
          region={childRegion}
          parentId={region.id}
          locationState={locationState}
          locationDispatch={locationDispatch}
          subRegion
        />
      ));
    }

    if (!buildings) {
      return null;
    }

    return (
      <BuildingsListWrapper>
        {buildings.map(building => (
          <Building
            key={building.id}
            building={building}
            locationState={locationState}
            locationDispatch={locationDispatch}
          />
        ))}
      </BuildingsListWrapper>
    );
  };

  const openRegion = () => {
    locationDispatch({
      type: ADD_REGION_TO_PATH,
      payload: { region, parentId },
    });
  };

  const closeRegion = () => {
    locationDispatch({
      type: 'removeRegionFromPath',
      payload: region,
    });
  };

  return (
    <RegionWrapper>
      <RegionHeader $isActive={open} onClick={() => (open ? closeRegion() : openRegion())}>
        <div
          className="region-name"
          data-testid={`${subRegion ? 'child' : 'top-level'}-region-name`}
        >
          {region.name}
        </div>
        <div className="region-icon">
          <Icon name="chevron-right" size={14} />
        </div>
      </RegionHeader>
      {renderRegionsOrBuildingsList()}
    </RegionWrapper>
  );
};

export default Region;
