import { useDispatch, useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store/tenant';
import { getKioskConfigQueryKey } from '@engage-web/api/queries';
import { useQueryClient } from 'react-query';
import logger from '@engage-web/utils/logger/logger';
import { tenantActions } from '@engage-web/store';

export const useResetKioskConfig = () => {
  const spaceId = useSelector(tenantSelectors.getKioskId);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const resetKioskConfig = async (clearStore = true) => {
    if (!spaceId) {
      return;
    }

    try {
      const kioskConfigQueryKey = getKioskConfigQueryKey(spaceId);
      await queryClient.cancelQueries(kioskConfigQueryKey);
    } catch (e) {
      logger.error(e)();
    } finally {
      if (clearStore) {
        dispatch(tenantActions.resetKioskConfig());
      }
    }
  };

  return { resetKioskConfig };
};
