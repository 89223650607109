import React from 'react';
import { isElectron } from '@engage-web/utils/electron';
import { Video } from './CoverVideo.styles';
import { getFileExtension } from '@engage-shared/utils';

const CoverVideo = ({ coverImage }: { coverImage?: string | null }) => {
  if (!coverImage) return null;

  // enable kiosk mode here
  const isKiosk = isElectron();

  const ext = getFileExtension(coverImage);
  const isVideo = coverImage && ext === 'mp4';

  if (!isKiosk || !isVideo) return null;

  return (
    <Video loop autoPlay muted playsInline>
      <source src={coverImage} type="video/mp4" />
      Your browser does not support the video tag.
    </Video>
  );
};

export default CoverVideo;
