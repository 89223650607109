import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarDate, SelectButton } from '@engage-web/components/base';
import { FocusId } from '@engage-web/utils';
import SpaceCard from '../Reserve/Card/SpaceCard/SpaceCard';
import { Wrapper } from './styled';
import { ParsedSpaceItem } from '@engage-shared/api';

type DeskCheckInProps = {
  spaceItem: ParsedSpaceItem & { downloadableImageUrl: string };
  startDate: string;
  isCheckingIn: boolean;
  isAvailable: boolean;
  onCheckIn: () => void;
};

export function DeskCheckIn({
  spaceItem,
  startDate,
  isCheckingIn,
  isAvailable,
  onCheckIn,
}: DeskCheckInProps) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {/* @ts-ignore type property is incompatible */}
      <SpaceCard {...spaceItem} isReserved={false} status="" />
      <CalendarDate aria-label={t('accessibilityLabels.teamBookingStartDate')}>
        {startDate}
      </CalendarDate>
      <SelectButton
        id={FocusId.TeamDeskReserve}
        disabled={!spaceItem.bookable || !isAvailable || isCheckingIn}
        onClick={onCheckIn}
      >
        {t('common.checkIn')}
      </SelectButton>
    </Wrapper>
  );
}
