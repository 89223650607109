import styled from 'styled-components';
import { Z_INDEX } from '@engage-web/constants';

export const Wrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.typo2};
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: ${Z_INDEX.DIALOG};
  background-color: #fff;
  border-radius: 8px;
  padding: 45px 48px;
  box-shadow: 0px 40px 60px 0px #00000026;
  width: 100%;
  max-width: 425px;

  @media screen and (min-width: 430px) {
    min-width: 425px;
  }
`;

export const Message = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: 12px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.8;
  text-align: center;
`;

export const Button = styled.button`
  border-radius: 30px;
  font-size: 12px;
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.color3};
  color: ${({ theme }) => theme.typo2};
  min-width: 152px;
  cursor: pointer;
  transition: transform 0.25s;

  &.focus-visible {
    outline: 1px auto Highlight;
  }

  &:active {
    transform: scale(0.96);
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.color1};
  color: ${({ theme }) => theme.typo1};
`;

type ActionsProps = {
  hasCancelButton: boolean;
};

export const Actions = styled.div<ActionsProps>`
  display: grid;
  grid-gap: 24px;
  margin-top: 37px;

  @media screen and (min-width: 430px) {
    grid-template-columns: repeat(${({ hasCancelButton }) => (hasCancelButton ? 2 : 1)}, 1fr);
  }
`;
