import { Token } from '@engage-shared/api/instance/interfaces';
import { AuthApi, getAuthUrl, handleErrorMessage } from '@engage-shared/api/instance';
import { AxiosResponse } from 'axios';
import { TenantId } from '@engage-shared/api/tenant/interfaces';

type LogoutParams = {
  tenantId: TenantId;
  refreshToken: Token;
};

type Logout = (params: LogoutParams) => Promise<AxiosResponse>;
/**
 * Revoke tokens.
 *
 * | Code      | Description |
 * | :---------| :---------- |
 * | 204 | no-content |
 * | 400 | bad request - Invalid token |
 * @param tenantId
 * @param refreshToken
 */
export const logout: Logout = async ({ tenantId, refreshToken }) => {
  try {
    const url = `${getAuthUrl(tenantId)}/revoke`;
    return await AuthApi.post(url, {
      refreshToken,
    });
  } catch (error) {
    throw new Error(handleErrorMessage(error));
  }
};
