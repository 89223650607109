import { useTranslation } from 'react-i18next';
import IconButton from '@engage-web/components/base/IconButton/IconButton';
import React from 'react';
import { FocusId, testProps, useGenerateCurrentLocationPath } from '@engage-web/utils';
import { useNavigate } from 'react-router-dom';
import { joinPaths } from '@engage-web/router/utils';
import { PATH_SEGMENT } from '@engage-web/constants';

export function FloorplanViewsIcon() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLocationPath = useGenerateCurrentLocationPath();

  const toggleFloorplanViewsModal = () => {
    navigate(joinPaths(currentLocationPath, PATH_SEGMENT.FLOORPLAN_VIEWS));
  };

  return (
    <IconButton
      id={FocusId.FloorplanViewsButton}
      icon="floorplan-views"
      onPress={toggleFloorplanViewsModal}
      {...testProps(t, 'accessibilityLabels.floorplanViewsButton').props}
    />
  );
}
