import { Theme } from '@engage-shared/theme';

export type TenantId = string;

export type StringOrBoolean = string | boolean;

export enum ConfigMethod {
  isCleverSocialDistancingActive = 'isCleverSocialDistancingActive',
  enforceAllDayDeskReservations = 'enforceAllDayDeskReservations',
  allDayReservationCheckInStartTime = 'allDayReservationCheckInStartTime',
  concurrentDeskReservations = 'concurrentDeskReservations',
  usePasscode = 'usePasscode',
  highlightReservedDesks = 'highlightReservedDesks',
  disableWebGeneralAccess = 'disableWebGeneralAccess',
  disableAppGeneralAccess = 'disableAppGeneralAccess',
  hideContactInformation = 'hideContactInformation',
  welcomeTitle = 'welcomeTitle',
  welcomeMessage = 'welcomeMessage',
  enableDeskCheckIn = 'enableDeskCheckIn',
  deskCheckInOpenWindow = 'deskCheckInOpenWindow',
  deskCheckInWindowDuration = 'deskCheckInWindowDuration',
  enableSpaceCheckIn = 'enableSpaceCheckIn',
  spaceCheckInOpenWindow = 'spaceCheckInOpenWindow',
  spaceCheckInCloseWindow = 'spaceCheckInCloseWindow',
  futureBookingsLimit = 'futureBookingsLimit',
  instanceTitle = 'instanceTitle',
  poiFireWarden = 'poiFireWarden',
  poiFirstAidOfficer = 'poiFirstAidOfficer',
  sendConfirmationEmails = 'sendConfirmationEmails',
  deskBookingInterval = 'deskBookingInterval',
  meetingRoomBookingInterval = 'meetingRoomBookingInterval',
  logo = 'logo',
  coverImage = 'coverImage',
  supportEmail = 'supportEmail',
  supportPhoneNumber = 'supportPhoneNumber',
  supportUrl = 'supportUrl',
  brandColor = 'brandColor',
  ssoEnabled = 'ssoEnabled',
  useZoomTiles = 'useZoomTiles',
  useFloorPlanAvailableColor = 'useFloorPlanAvailableColor',
  floorPlanAvailableColor = 'floorPlanAvailableColor',
  acknowledgementValue = 'acknowledgementValue',
  acknowledgementTitle = 'acknowledgementTitle',
  acknowledgementMessage = 'acknowledgementMessage',
  acknowledgementUrl = 'acknowledgementUrl',
  enableHealthDeclaration = 'enableHealthDeclaration',
  healthDeclaration = 'healthDeclaration',
  availableLanguages = 'availableLanguages',
  allowUsersToOptOut = 'allowUsersToOptOut',
  enforceBookingsAllowed = 'enforceBookingsAllowed',
  bookingDeniedMessage = 'bookingDeniedMessage',
  hideCalendarReservationControls = 'hideCalendarReservationControls',
  kioskRestrictBookingToCurrentDay = 'kioskRestrictBookingToCurrentDay',
}

type TenantConfigDefaultValue = StringOrBoolean | number | [] | null | object;
type TenantConfigValue = {
  configMethod: ConfigMethod;
  defaultValue?: TenantConfigDefaultValue;
  getValue(value: TenantConfigDefaultValue | undefined): any;
};
export type TenantConfig = {
  [index: string]: TenantConfigValue;
  '5141': TenantConfigValue;
  '5142': TenantConfigValue;
  '5143': TenantConfigValue;
  '4871': TenantConfigValue;
  '9269': TenantConfigValue;
  '9260': TenantConfigValue;
  '9280': TenantConfigValue;
  '9263': TenantConfigValue;
  '9264': TenantConfigValue;
  '9261': TenantConfigValue;
  '9262': TenantConfigValue;
  '5137': TenantConfigValue;
  '5138': TenantConfigValue;
  '9933': TenantConfigValue;
  '9256': TenantConfigValue;
  '9255': TenantConfigValue;
  '4881': TenantConfigValue;
  '9932': TenantConfigValue;
  '5135': TenantConfigValue;
  '5146': TenantConfigValue;
  '6621': TenantConfigValue;
  '5136': TenantConfigValue;
  '9259': TenantConfigValue;
  '7592': TenantConfigValue;
  '7591': TenantConfigValue;
  '1523': TenantConfigValue;
  '4875': TenantConfigValue;
  '4876': TenantConfigValue;
  '4877': TenantConfigValue;
  '3603': TenantConfigValue;
  '3604': TenantConfigValue;
  '3605': TenantConfigValue;
  '4337': TenantConfigValue;
  '4892': TenantConfigValue;
  '4345': TenantConfigValue;
  '6098': TenantConfigValue;
};
export type TenantParsedData = { [p: string]: any };

export interface TenantData {
  tenantId: string;
  config: TenantParsedData;
  theme: Theme;
}

export type ServerData = {
  id: number;
  name: string;
  value: string;
  sequence?: number;
};
