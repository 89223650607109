import { useTeam as useTeamQuery } from '@engage-shared/api';
import { useSelector } from 'react-redux';
import { tenantSelectors } from '@engage-web/store';
import { useFavouritePeople } from '@engage-web/api/queries/useFavouritePeople';
import { Team } from '@engage-shared/api/teams';
import { SearchResponseData } from '@engage-shared/api/search';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useTranslation } from 'react-i18next';
import { RESTRICT_TO_VALUES } from '@engage-shared/constants';

export const useTeam = ({
  id,
  teamFromState,
}: {
  id?: string;
  teamFromState?: Team & SearchResponseData;
}) => {
  const { t } = useTranslation();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const isFavPeopleTeam = id === PATH_SEGMENT.FAV_PEOPLE_TEAM;

  const {
    data: teamQueryData,
    isLoading: teamQueryLoading,
    isError,
  } = useTeamQuery({
    id: +id!,
    tenantId,
    options: {
      enabled: !isFavPeopleTeam && !teamFromState,
    },
  });

  const { peopleCount, isLoading: favPeopleIsLoading } = useFavouritePeople({
    enabled: isFavPeopleTeam,
  });

  if (isFavPeopleTeam) {
    return {
      data: {
        id: PATH_SEGMENT.FAV_PEOPLE_TEAM,
        name: t('common.favouritePeopleTeamName'),
        people: peopleCount,
        type: RESTRICT_TO_VALUES.TEAM,
        favPeopleTeam: true,
      },
      isLoading: favPeopleIsLoading,
    };
  }

  return { data: teamQueryData, isLoading: teamQueryLoading, isError };
};
