/**
 * Main entry point of the app
 * Apply global wrappers here
 */

import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { GlobalStyles, ThemeProvider } from '@engage-web/styles';
import store, { persistor } from '@engage-web/store';
import { Routing } from '@engage-web/router';
import { QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { initializeSentry } from '@engage-web/utils/analytics/sentry';
import { NotificationController } from '@engage-web/components/layouts';
import smoothScroll from 'smoothscroll-polyfill';
import queryClient from '@engage-web/utils/config/queryConfig';
import './utils/config/engageConfig';
import { TokenManager } from '@engage-shared/api';
import { Dialog, DialogProvider, IconsSprite } from '@engage-web/components/base';
import { SessionTimeoutMiddleware } from '@engage-web/components/middlewares/SessionTimeoutMiddleware';
import { DeepLinkChecker } from '@engage-web/router/components/DeepLinkChecker/DeepLinkChecker';
// polyfill for Safari focus-visible support
import 'focus-visible/dist/focus-visible.min';
import { isElectron } from '@engage-web/utils/electron';
import { ErrorBoundary } from '@engage-web/components/base/ErrorBoundary';
import { KioskConfigLoader } from '@engage-web/components/kiosk';
import * as storage from './utils/config/tokenConfig';

// polyfill for .scroll functionality which are not implemented in MS Edge v < 79
smoothScroll.polyfill();

initializeSentry();

const Router = isElectron() ? HashRouter : BrowserRouter;

function App() {
  React.useEffect(() => {
    TokenManager.config({
      storage,
      reduxStore: store,
    });
  }, []);

  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <NotificationController>
            <GlobalStyles />
            <ThemeProvider>
              <ErrorBoundary>
                <Router>
                  <SessionTimeoutMiddleware>
                    <DeepLinkChecker>
                      <DialogProvider>
                        <Routing />
                        <Dialog />
                        {isElectron() && <KioskConfigLoader />}
                      </DialogProvider>
                    </DeepLinkChecker>
                  </SessionTimeoutMiddleware>
                </Router>
              </ErrorBoundary>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </NotificationController>
        </QueryClientProvider>
      </PersistGate>
      <IconsSprite />
    </ReduxProvider>
  );
}

export default App;
