import { useEffect } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import {
  floorplanSelectors,
  teamReservationsActions,
  teamReservationsSelectors,
} from '@engage-web/store';
import { useDispatch, useSelector } from 'react-redux';

export const useTeamReservationHandlers = () => {
  const fes = useFESContext();
  const dispatch = useDispatch();
  const teamReservationDesksSelectionMode = useSelector(
    teamReservationsSelectors.getTeamReservationDesksSelectionMode,
  );
  const teamSize = useSelector(teamReservationsSelectors.getTeamSize);
  const teamReservationSelectedDesks = useSelector(
    teamReservationsSelectors.getTeamReservationSelectedDesks,
  );
  const shouldClearDesksSelection = useSelector(
    teamReservationsSelectors.getShouldClearDesksSelection,
  );
  const isFloorplanLoaded = useSelector(floorplanSelectors.getIsLoaded);

  useEffect(() => {
    if (!isFloorplanLoaded) {
      return;
    }
    fes.trigger(
      FES_EVENTS.SET_TEAM_RESERVATION_DESKS_SELECTION_MODE,
      teamReservationDesksSelectionMode,
    );
  }, [fes, teamReservationDesksSelectionMode, isFloorplanLoaded]);

  useEffect(() => {
    if (!isFloorplanLoaded) {
      return;
    }

    fes.trigger(FES_EVENTS.SET_TEAM_RESERVATION_TEAM_SIZE, teamSize);
  }, [fes, teamSize, isFloorplanLoaded]);

  useEffect(() => {
    if (shouldClearDesksSelection) {
      fes.trigger(FES_EVENTS.CLEAR_TEAM_RESERVATION_DESKS_SELECTION);
      dispatch(teamReservationsActions.clear());
    }
  }, [shouldClearDesksSelection, dispatch, fes]);

  useEffect(() => {
    fes.trigger(FES_EVENTS.TEAM_RESERVATION_SELECTED_DESKS_CHANGED, teamReservationSelectedDesks);
  }, [fes, teamReservationSelectedDesks]);
};
