import React from 'react';
import { serraviewLogo } from '@engage-web/assets/images';
import { useTranslation } from 'react-i18next';

export const LOGO_WIDTH = 250;

export const Logo = () => {
  const { t } = useTranslation();

  return (
    <img src={serraviewLogo} alt={t('scenes.login.logoAlt')} draggable={false} width={LOGO_WIDTH} />
  );
};
