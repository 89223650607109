import { useMutation } from 'react-query';
import {
  MultiDayTeamBookingMutationData,
  MultiDayTeamBookingMutationOptions,
  MultiDayTeamBookingMutationParams,
  MultiDayTeamBookingResult,
} from '../interfaces';
import { saveMultiDayTeamBooking } from '@engage-shared/api/teams/utils/saveMultiDayTeamBooking';
import { LanguageTypes } from '@engage-shared/constants/languages';

type MultiDayTeamBookingMutation = (
  params: MultiDayTeamBookingMutationParams,
  options?: MultiDayTeamBookingMutationOptions,
) => MultiDayTeamBookingResult;
/**
 * Returns mutation for create multiple bookings.
 */
export const useMultiDayTeamBookingMutation: MultiDayTeamBookingMutation = (
  {
    tenantId,
    teamId,
    summary,
    timeZone,
    attendees,
    desks,
    filter,
    selectedDays = [],
    successCallback,
    errorCallback,
    locale = LanguageTypes.enUS,
  },
  options = {},
) => {
  const teamBookingMutation = useMutation<
    MultiDayTeamBookingMutationData,
    Error,
    MultiDayTeamBookingMutationParams
  >(saveMultiDayTeamBooking, options);

  const { mutate, ...otherMutationProps } = teamBookingMutation;

  const onBooking: () => void = () => {
    return mutate({
      tenantId,
      teamId,
      summary,
      timeZone,
      attendees,
      desks,
      selectedDays,
      filter,
      successCallback,
      errorCallback,
      locale,
    });
  };

  return { ...otherMutationProps, onBooking };
};
