import React, { ReactNode, useEffect, useRef } from 'react';

type SizeRecognitionWrapperProps = {
  children: ReactNode;
  onSizeFound?: (size: DOMRect, index: number) => void;
  index: number;
};

const SizeRecognitionWrapper = ({ children, index, onSizeFound }: SizeRecognitionWrapperProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const size = elementRef.current?.getBoundingClientRect();
    if (size) {
      onSizeFound?.(size, index);
    }
  }, [index, onSizeFound]);

  return <div ref={elementRef}>{children}</div>;
};

export default SizeRecognitionWrapper;
