import styled from 'styled-components';

export const AttendeeList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const AttendeeItemWrapper = styled.li<{ $style?: any }>`
  ${props => props.$style || {}}
  display: flex;
  flex-direction: column;
`;
