import React, { CSSProperties, memo, useCallback } from 'react';
import { FlatList, Mask } from '@engage-web/components/base';
import { useDispatch, useSelector } from 'react-redux';
import {
  teamMembersSearchSelectors,
  teamReservationsActions,
  tenantSelectors,
} from '@engage-web/store';
import {
  GlobalSearchResultsWrapper,
  LIST_ITEM_PADDING_VERTICAL,
} from '@engage-web/components/layouts/GlobalSearch/styled';
import { useTranslation } from 'react-i18next';
import { A11yHiddenLabel, fontClassesHeights, fontTagHeights } from '@engage-web/styles';
import { useNavigate } from 'react-router-dom';
import { useGenerateCurrentLocationPath } from '@engage-web/utils';
import { joinPaths } from '@engage-web/router/utils';
import { PATH_SEGMENT } from '@engage-web/constants';
import { useAppLocation } from '@engage-web/router/hooks';
import { useUser } from '@engage-web/api/queries';
import SelectablePersonItem from '@engage-web/components/layouts/Team/TeamMembers/SelectablePersonItem';
import { useTeamMembers } from '@engage-web/api/queries/useTeamMembers';
import { TeamReserveButton } from './TeamReserveButton';
import {
  EmptyList,
  TeamMembersListWrapper,
  TeamMembersSearchOuterWrapper,
} from './TeamMembers.style';
import { ParsedTeam, TeamMember, useFloorPermissions } from '@engage-shared/api';

export type ParsedTeamMember = {
  id: TeamMember['id'];
  name: TeamMember['displayName'];
  email?: TeamMember['email'];
  description: TeamMember['jobTitle'];
  imageUrl?: string;
  initials: string;
  isFavourite?: boolean;
};

type TeamMemberListProps = {
  team: ParsedTeam;
  selectedIds: number[];
  toggleSelectedId: (id: number) => void;
};

// @ts-ignore
const fontTagHeightsH6WithMargins = fontTagHeights.h6.withMargins;
// @ts-ignore
const fontClassesHeightsBthWithMargins = fontClassesHeights.btn.withMargins;

const itemSize = () =>
  LIST_ITEM_PADDING_VERTICAL * 2 + fontTagHeightsH6WithMargins + fontClassesHeightsBthWithMargins;

const TeamMembersList = ({ team, selectedIds, toggleSelectedId }: TeamMemberListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useAppLocation();
  const currentLocationPath = useGenerateCurrentLocationPath();
  const { t } = useTranslation();
  const searchValue = useSelector(teamMembersSearchSelectors.getTeamMembersSearchValue);
  const { data: userData } = useUser();

  const a11yTeamMembersList = t('layout.teamMembersList.a11yTeamMembersList');

  const { data: teamMembers, isLoading } = useTeamMembers({
    teamId: team?.id,
    searchString: searchValue.trim(),
  });

  const isMyTeam = !!teamMembers?.find(member => member.id === userData?.personId);

  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const { data: permissions } = useFloorPermissions({
    floorId: floorId || 0,
    options: { enabled: !!floorId },
  });
  const teamBookingPermission = permissions?.teamBookingPermission;

  const isUserAllowedToBook =
    teamBookingPermission &&
    (userData?.canReserveForAnyTeam || (userData?.canReserveForMyTeam && isMyTeam));

  const onPersonSelect = useCallback(
    (person: ParsedTeamMember) => {
      const personPath = joinPaths(currentLocationPath, PATH_SEGMENT.PEOPLE, `${person.id}`);

      navigate(personPath, {
        state: {
          person,
          fromPath: pathname,
        },
      });
    },
    [currentLocationPath, navigate, pathname],
  );

  const onTeamReserveButtonPress = () => {
    const teamMembersIds = teamMembers?.map(({ id }) => id) || [];
    dispatch(
      teamReservationsActions.startTeamReservationDesksSelection({
        selectedTeamMemberIds: selectedIds.length ? selectedIds : teamMembersIds,
      }),
    );
  };

  type RenderItemProps = {
    index: number;
    item: TeamMember;
    style: CSSProperties;
  };
  const renderItem = ({ item, index, style }: RenderItemProps) => {
    // eslint-disable-next-line react/prop-types
    const isSelected = selectedIds.includes(item.id);

    return (
      <SelectablePersonItem
        isSelected={isSelected}
        onSelect={isUserAllowedToBook ? toggleSelectedId : undefined}
        key={item.id}
        item={item}
        onClick={onPersonSelect}
        data-testid={`team_member_${index}`}
        style={style}
        index={index}
      />
    );
  };

  const renderEmptyList = () => {
    if (isLoading) return null;

    return (
      <EmptyList aria-live="polite" aria-atomic="true">
        <p>{t('layout.teamMembersList.emptyList')}</p>
      </EmptyList>
    );
  };

  return (
    <>
      <TeamMembersListWrapper>
        <A11yHiddenLabel>{a11yTeamMembersList}</A11yHiddenLabel>
        <Mask isLoading={isLoading} />
        <TeamMembersSearchOuterWrapper
          id="team_members_list_wrapper"
          aria-live="polite"
          aria-atomic="true"
        >
          {teamMembers && teamMembers.length > 0 ? (
            <GlobalSearchResultsWrapper>
              <FlatList
                fixed={false}
                data={teamMembers}
                itemCount={teamMembers.length}
                renderItem={renderItem}
                itemSize={itemSize}
              />
            </GlobalSearchResultsWrapper>
          ) : (
            renderEmptyList()
          )}
        </TeamMembersSearchOuterWrapper>
        {isUserAllowedToBook && (
          <TeamReserveButton onPress={onTeamReserveButtonPress} team={team} />
        )}
      </TeamMembersListWrapper>
    </>
  );
};
export default memo(TeamMembersList);
