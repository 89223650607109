import styled from 'styled-components';
import { Z_INDEX } from '@engage-web/constants';
import { CONCIERGE_MENU_BREAKPOINTS } from '@engage-web/components/scenes/ConciergeMenu/constants';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  margin: 60px 40px 0 40px;
  z-index: ${Z_INDEX.HEADER};

  @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL}px) {
    margin-left: 65px;
  }
`;
