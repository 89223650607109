import React from 'react';
import { SkeletonTheme } from '@engage-web/components/base';
import Skeleton from 'react-loading-skeleton';
import LocationCardSkeleton from '@engage-web/components/base/LocationCard/LocationCardSkeleton';
import AttendeeSkeleton from '@engage-web/components/layouts//Booking/AttendeesList/Attendee/AttendeeSkeleton';
import { Content, Header, LineWrapper, Section, Wrapper } from './styled';

const BookingCardSkeleton = () => (
  <SkeletonTheme style={{ width: '100%' }}>
    <Wrapper data-testid="booking-card-skeleton">
      <Header>
        <h2>
          <Skeleton width={200} />
        </h2>
        <h5>
          <Skeleton width={200} />
        </h5>
      </Header>
      <Content>
        <Section>
          <LocationCardSkeleton />
          <LineWrapper>
            <Skeleton circle height={36} width={36} />
            <Skeleton height={36} width={90} />
            <Skeleton height={36} width={90} />
          </LineWrapper>
        </Section>
        <Section>
          <h6>
            <Skeleton width={200} />
          </h6>
          <Skeleton width="90%" height={80} />
        </Section>
        <Section>
          <h6>
            <Skeleton width={200} />
          </h6>
        </Section>
        <AttendeeSkeleton />
      </Content>
    </Wrapper>
  </SkeletonTheme>
);

export default BookingCardSkeleton;
