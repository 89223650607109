import { useQuery, UseQueryResult } from 'react-query';
import { SpaceType, SpaceTypesData } from '@engage-shared/api/spaces/interfaces';
import { SPACE_TYPE_ATTRIBUTES_QUERY_KEY } from '@engage-shared/api/spaces/constants';
import { LanguageTypes } from '@engage-shared/constants/languages';
import { UseQueryOptions } from 'react-query/types/react/types';
import { fetchAllSpaceAttributes } from '@engage-shared/api/spaces/fetchAllSpaceAttributes';

type UseSpaceTypeAttributesParams = {
  spaceType: SpaceType;
  locale?: LanguageTypes;
  options?: UseQueryOptions<SpaceTypesData>;
};

type UseSpaceTypeAttributesQuery = (
  params: UseSpaceTypeAttributesParams,
) => UseQueryResult<SpaceTypesData>;

export const useSpaceTypeAttributesQuery: UseSpaceTypeAttributesQuery = ({
  spaceType,
  locale,
  options = { enabled: true },
}) =>
  useQuery({
    queryKey: [SPACE_TYPE_ATTRIBUTES_QUERY_KEY, spaceType, locale],
    queryFn: () => fetchAllSpaceAttributes({ spaceType, locale }),
    ...options,
    enabled: options?.enabled && !!spaceType,
  });
