import styled from 'styled-components';
import { Text } from '../../base/Text/Text';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  position: absolute;
  padding: 0 4px 0 4px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  border-radius: 15px;
  border: 2px solid white;
  font-size: 12px;
  color: ${({ theme }) => theme.color6};
  background-color: ${({ theme }) => theme.color1};
  height: 30px;
  width: 120px;
`;

export const LoadingText = styled(Text)`
  margin-left: 6px;
`;
